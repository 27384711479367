@import '@reach/icons/dist/main-icons/style.css';
@import '@reach/icons/dist/node-subtypes/style.css';
.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
  background-color: var(--mat-ripple-color, rgba(0, 0, 0, 0.1));
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-drag-preview .mat-ripple-element, .cdk-drag-placeholder .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}
.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}
.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

.mat-app-background {
  background-color: var(--mat-app-background-color, transparent);
  color: var(--mat-app-text-color, inherit);
}

.mat-elevation-z0, .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: var(--mat-app-elevation-shadow-level-0, none);
}

.mat-elevation-z1, .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: var(--mat-app-elevation-shadow-level-1, none);
}

.mat-elevation-z2, .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: var(--mat-app-elevation-shadow-level-2, none);
}

.mat-elevation-z3, .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: var(--mat-app-elevation-shadow-level-3, none);
}

.mat-elevation-z4, .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: var(--mat-app-elevation-shadow-level-4, none);
}

.mat-elevation-z5, .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: var(--mat-app-elevation-shadow-level-5, none);
}

.mat-elevation-z6, .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: var(--mat-app-elevation-shadow-level-6, none);
}

.mat-elevation-z7, .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: var(--mat-app-elevation-shadow-level-7, none);
}

.mat-elevation-z8, .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: var(--mat-app-elevation-shadow-level-8, none);
}

.mat-elevation-z9, .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: var(--mat-app-elevation-shadow-level-9, none);
}

.mat-elevation-z10, .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: var(--mat-app-elevation-shadow-level-10, none);
}

.mat-elevation-z11, .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: var(--mat-app-elevation-shadow-level-11, none);
}

.mat-elevation-z12, .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: var(--mat-app-elevation-shadow-level-12, none);
}

.mat-elevation-z13, .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: var(--mat-app-elevation-shadow-level-13, none);
}

.mat-elevation-z14, .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: var(--mat-app-elevation-shadow-level-14, none);
}

.mat-elevation-z15, .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: var(--mat-app-elevation-shadow-level-15, none);
}

.mat-elevation-z16, .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: var(--mat-app-elevation-shadow-level-16, none);
}

.mat-elevation-z17, .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: var(--mat-app-elevation-shadow-level-17, none);
}

.mat-elevation-z18, .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: var(--mat-app-elevation-shadow-level-18, none);
}

.mat-elevation-z19, .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: var(--mat-app-elevation-shadow-level-19, none);
}

.mat-elevation-z20, .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: var(--mat-app-elevation-shadow-level-20, none);
}

.mat-elevation-z21, .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: var(--mat-app-elevation-shadow-level-21, none);
}

.mat-elevation-z22, .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: var(--mat-app-elevation-shadow-level-22, none);
}

.mat-elevation-z23, .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: var(--mat-app-elevation-shadow-level-23, none);
}

.mat-elevation-z24, .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: var(--mat-app-elevation-shadow-level-24, none);
}

.mat-theme-loaded-marker {
  display: none;
}

html {
  --mat-ripple-color: rgba(0, 0, 0, 0.1);
}

html {
  --mat-option-selected-state-label-text-color: #48a1ee;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

.mat-accent {
  --mat-option-selected-state-label-text-color: #8bc34a;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

.mat-warn {
  --mat-option-selected-state-label-text-color: #f44336;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

html {
  --mat-optgroup-label-text-color: rgba(0, 0, 0, 0.87);
}

.mat-primary {
  --mat-full-pseudo-checkbox-selected-icon-color: #48a1ee;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #48a1ee;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

html {
  --mat-full-pseudo-checkbox-selected-icon-color: #8bc34a;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #8bc34a;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

.mat-accent {
  --mat-full-pseudo-checkbox-selected-icon-color: #8bc34a;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #8bc34a;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

.mat-warn {
  --mat-full-pseudo-checkbox-selected-icon-color: #f44336;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #f44336;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

html {
  --mat-app-background-color: #fafafa;
  --mat-app-text-color: rgba(0, 0, 0, 0.87);
  --mat-app-elevation-shadow-level-0: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-1: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-2: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-3: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-4: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-5: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-6: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-7: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-8: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-9: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-10: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-11: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-12: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-13: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-14: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-15: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-16: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-17: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-18: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-19: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-20: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-21: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-22: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-23: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-24: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

html {
  --mat-option-label-text-font: Roboto, sans-serif;
  --mat-option-label-text-line-height: 24px;
  --mat-option-label-text-size: 16px;
  --mat-option-label-text-tracking: 0.03125em;
  --mat-option-label-text-weight: 400;
}

html {
  --mat-optgroup-label-text-font: Roboto, sans-serif;
  --mat-optgroup-label-text-line-height: 24px;
  --mat-optgroup-label-text-size: 16px;
  --mat-optgroup-label-text-tracking: 0.03125em;
  --mat-optgroup-label-text-weight: 400;
}

html {
  --mdc-elevated-card-container-shape: 4px;
  --mdc-outlined-card-container-shape: 4px;
  --mdc-outlined-card-outline-width: 1px;
}

html {
  --mdc-elevated-card-container-color: white;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: white;
  --mdc-outlined-card-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-card-subtitle-text-color: rgba(0, 0, 0, 0.54);
}

html {
  --mat-card-title-text-font: Roboto, sans-serif;
  --mat-card-title-text-line-height: 32px;
  --mat-card-title-text-size: 20px;
  --mat-card-title-text-tracking: 0.0125em;
  --mat-card-title-text-weight: 500;
  --mat-card-subtitle-text-font: Roboto, sans-serif;
  --mat-card-subtitle-text-line-height: 22px;
  --mat-card-subtitle-text-size: 14px;
  --mat-card-subtitle-text-tracking: 0.0071428571em;
  --mat-card-subtitle-text-weight: 500;
}

html {
  --mdc-linear-progress-active-indicator-height: 4px;
  --mdc-linear-progress-track-height: 4px;
  --mdc-linear-progress-track-shape: 0;
}

.mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #48a1ee;
  --mdc-linear-progress-track-color: rgba(72, 161, 238, 0.25);
}
.mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #8bc34a;
  --mdc-linear-progress-track-color: rgba(139, 195, 74, 0.25);
}
.mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #f44336;
  --mdc-linear-progress-track-color: rgba(244, 67, 54, 0.25);
}

html {
  --mdc-plain-tooltip-container-shape: 4px;
  --mdc-plain-tooltip-supporting-text-line-height: 16px;
}

html {
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: #fff;
}

html {
  --mdc-plain-tooltip-supporting-text-font: Roboto, sans-serif;
  --mdc-plain-tooltip-supporting-text-size: 12px;
  --mdc-plain-tooltip-supporting-text-weight: 400;
  --mdc-plain-tooltip-supporting-text-tracking: 0.0333333333em;
}

html {
  --mdc-filled-text-field-active-indicator-height: 1px;
  --mdc-filled-text-field-focus-active-indicator-height: 2px;
  --mdc-filled-text-field-container-shape: 4px;
  --mdc-outlined-text-field-outline-width: 1px;
  --mdc-outlined-text-field-focus-outline-width: 2px;
  --mdc-outlined-text-field-container-shape: 4px;
}

html {
  --mdc-filled-text-field-caret-color: #48a1ee;
  --mdc-filled-text-field-focus-active-indicator-color: #48a1ee;
  --mdc-filled-text-field-focus-label-text-color: rgba(72, 161, 238, 0.87);
  --mdc-filled-text-field-container-color: whitesmoke;
  --mdc-filled-text-field-disabled-container-color: #fafafa;
  --mdc-filled-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-error-hover-label-text-color: #f44336;
  --mdc-filled-text-field-error-focus-label-text-color: #f44336;
  --mdc-filled-text-field-error-label-text-color: #f44336;
  --mdc-filled-text-field-error-caret-color: #f44336;
  --mdc-filled-text-field-active-indicator-color: rgba(0, 0, 0, 0.42);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(0, 0, 0, 0.06);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-error-active-indicator-color: #f44336;
  --mdc-filled-text-field-error-focus-active-indicator-color: #f44336;
  --mdc-filled-text-field-error-hover-active-indicator-color: #f44336;
  --mdc-outlined-text-field-caret-color: #48a1ee;
  --mdc-outlined-text-field-focus-outline-color: #48a1ee;
  --mdc-outlined-text-field-focus-label-text-color: rgba(72, 161, 238, 0.87);
  --mdc-outlined-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-error-caret-color: #f44336;
  --mdc-outlined-text-field-error-focus-label-text-color: #f44336;
  --mdc-outlined-text-field-error-label-text-color: #f44336;
  --mdc-outlined-text-field-error-hover-label-text-color: #f44336;
  --mdc-outlined-text-field-outline-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-disabled-outline-color: rgba(0, 0, 0, 0.06);
  --mdc-outlined-text-field-hover-outline-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-error-focus-outline-color: #f44336;
  --mdc-outlined-text-field-error-hover-outline-color: #f44336;
  --mdc-outlined-text-field-error-outline-color: #f44336;
  --mat-form-field-focus-select-arrow-color: rgba(72, 161, 238, 0.87);
  --mat-form-field-disabled-input-text-placeholder-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-state-layer-color: rgba(0, 0, 0, 0.87);
  --mat-form-field-error-text-color: #f44336;
  --mat-form-field-select-option-text-color: inherit;
  --mat-form-field-select-disabled-option-text-color: GrayText;
  --mat-form-field-leading-icon-color: unset;
  --mat-form-field-disabled-leading-icon-color: unset;
  --mat-form-field-trailing-icon-color: unset;
  --mat-form-field-disabled-trailing-icon-color: unset;
  --mat-form-field-error-focus-trailing-icon-color: unset;
  --mat-form-field-error-hover-trailing-icon-color: unset;
  --mat-form-field-error-trailing-icon-color: unset;
  --mat-form-field-enabled-select-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-form-field-disabled-select-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-hover-state-layer-opacity: 0.04;
  --mat-form-field-focus-state-layer-opacity: 0.08;
}

.mat-mdc-form-field.mat-accent {
  --mdc-filled-text-field-caret-color: #8bc34a;
  --mdc-filled-text-field-focus-active-indicator-color: #8bc34a;
  --mdc-filled-text-field-focus-label-text-color: rgba(139, 195, 74, 0.87);
  --mdc-outlined-text-field-caret-color: #8bc34a;
  --mdc-outlined-text-field-focus-outline-color: #8bc34a;
  --mdc-outlined-text-field-focus-label-text-color: rgba(139, 195, 74, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(139, 195, 74, 0.87);
}

.mat-mdc-form-field.mat-warn {
  --mdc-filled-text-field-caret-color: #f44336;
  --mdc-filled-text-field-focus-active-indicator-color: #f44336;
  --mdc-filled-text-field-focus-label-text-color: rgba(244, 67, 54, 0.87);
  --mdc-outlined-text-field-caret-color: #f44336;
  --mdc-outlined-text-field-focus-outline-color: #f44336;
  --mdc-outlined-text-field-focus-label-text-color: rgba(244, 67, 54, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(244, 67, 54, 0.87);
}

html {
  --mat-form-field-container-height: 56px;
  --mat-form-field-filled-label-display: block;
  --mat-form-field-container-vertical-padding: 16px;
  --mat-form-field-filled-with-label-container-padding-top: 24px;
  --mat-form-field-filled-with-label-container-padding-bottom: 8px;
}

html {
  --mdc-filled-text-field-label-text-font: Roboto, sans-serif;
  --mdc-filled-text-field-label-text-size: 16px;
  --mdc-filled-text-field-label-text-tracking: 0.03125em;
  --mdc-filled-text-field-label-text-weight: 400;
  --mdc-outlined-text-field-label-text-font: Roboto, sans-serif;
  --mdc-outlined-text-field-label-text-size: 16px;
  --mdc-outlined-text-field-label-text-tracking: 0.03125em;
  --mdc-outlined-text-field-label-text-weight: 400;
  --mat-form-field-container-text-font: Roboto, sans-serif;
  --mat-form-field-container-text-line-height: 24px;
  --mat-form-field-container-text-size: 16px;
  --mat-form-field-container-text-tracking: 0.03125em;
  --mat-form-field-container-text-weight: 400;
  --mat-form-field-outlined-label-text-populated-size: 16px;
  --mat-form-field-subscript-text-font: Roboto, sans-serif;
  --mat-form-field-subscript-text-line-height: 20px;
  --mat-form-field-subscript-text-size: 12px;
  --mat-form-field-subscript-text-tracking: 0.0333333333em;
  --mat-form-field-subscript-text-weight: 400;
}

html {
  --mat-select-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

html {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(72, 161, 238, 0.87);
  --mat-select-invalid-arrow-color: rgba(244, 67, 54, 0.87);
}
html .mat-mdc-form-field.mat-accent {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(139, 195, 74, 0.87);
  --mat-select-invalid-arrow-color: rgba(244, 67, 54, 0.87);
}
html .mat-mdc-form-field.mat-warn {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(244, 67, 54, 0.87);
  --mat-select-invalid-arrow-color: rgba(244, 67, 54, 0.87);
}

html {
  --mat-select-arrow-transform: translateY(-8px);
}

html {
  --mat-select-trigger-text-font: Roboto, sans-serif;
  --mat-select-trigger-text-line-height: 24px;
  --mat-select-trigger-text-size: 16px;
  --mat-select-trigger-text-tracking: 0.03125em;
  --mat-select-trigger-text-weight: 400;
}

html {
  --mat-autocomplete-container-shape: 4px;
  --mat-autocomplete-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

html {
  --mat-autocomplete-background-color: white;
}

html {
  --mdc-dialog-container-shape: 4px;
  --mat-dialog-container-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mat-dialog-container-max-width: 80vw;
  --mat-dialog-container-small-max-width: 80vw;
  --mat-dialog-container-min-width: 0;
  --mat-dialog-actions-alignment: start;
  --mat-dialog-actions-padding: 8px;
  --mat-dialog-content-padding: 20px 24px;
  --mat-dialog-with-actions-content-padding: 20px 24px;
  --mat-dialog-headline-padding: 0 24px 9px;
}

html {
  --mdc-dialog-container-color: white;
  --mdc-dialog-subhead-color: rgba(0, 0, 0, 0.87);
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.6);
}

html {
  --mdc-dialog-subhead-font: Roboto, sans-serif;
  --mdc-dialog-subhead-line-height: 32px;
  --mdc-dialog-subhead-size: 20px;
  --mdc-dialog-subhead-weight: 500;
  --mdc-dialog-subhead-tracking: 0.0125em;
  --mdc-dialog-supporting-text-font: Roboto, sans-serif;
  --mdc-dialog-supporting-text-line-height: 24px;
  --mdc-dialog-supporting-text-size: 16px;
  --mdc-dialog-supporting-text-weight: 400;
  --mdc-dialog-supporting-text-tracking: 0.03125em;
}

.mat-mdc-standard-chip {
  --mdc-chip-container-shape-radius: 16px;
  --mdc-chip-with-avatar-avatar-shape-radius: 14px;
  --mdc-chip-with-avatar-avatar-size: 28px;
  --mdc-chip-with-icon-icon-size: 18px;
  --mdc-chip-outline-width: 0;
  --mdc-chip-outline-color: transparent;
  --mdc-chip-disabled-outline-color: transparent;
  --mdc-chip-focus-outline-color: transparent;
  --mdc-chip-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-avatar-disabled-avatar-opacity: 1;
  --mdc-chip-flat-selected-outline-width: 0;
  --mdc-chip-selected-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-opacity: 1;
  --mdc-chip-with-icon-disabled-icon-opacity: 1;
  --mat-chip-disabled-container-opacity: 0.4;
  --mat-chip-trailing-action-opacity: 0.54;
  --mat-chip-trailing-action-focus-opacity: 1;
  --mat-chip-trailing-action-state-layer-color: transparent;
  --mat-chip-selected-trailing-action-state-layer-color: transparent;
  --mat-chip-trailing-action-hover-state-layer-opacity: 0;
  --mat-chip-trailing-action-focus-state-layer-opacity: 0;
}

.mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-chip-elevated-selected-container-color: #e0e0e0;
  --mdc-chip-elevated-disabled-container-color: #e0e0e0;
  --mdc-chip-flat-disabled-selected-container-color: #e0e0e0;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-selected-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
  --mat-chip-selected-disabled-trailing-icon-color: #212121;
  --mat-chip-selected-trailing-icon-color: #212121;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: var(--color-text-800);
  --mdc-chip-elevated-container-color: #48a1ee;
  --mdc-chip-elevated-selected-container-color: #48a1ee;
  --mdc-chip-elevated-disabled-container-color: #48a1ee;
  --mdc-chip-flat-disabled-selected-container-color: #48a1ee;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: var(--color-text-800);
  --mdc-chip-selected-label-text-color: var(--color-text-800);
  --mdc-chip-with-icon-icon-color: var(--color-text-800);
  --mdc-chip-with-icon-disabled-icon-color: var(--color-text-800);
  --mdc-chip-with-icon-selected-icon-color: var(--color-text-800);
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: var(--color-text-800);
  --mdc-chip-with-trailing-icon-trailing-icon-color: var(--color-text-800);
  --mat-chip-selected-disabled-trailing-icon-color: var(--color-text-800);
  --mat-chip-selected-trailing-icon-color: var(--color-text-800);
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-elevated-container-color: #8bc34a;
  --mdc-chip-elevated-selected-container-color: #8bc34a;
  --mdc-chip-elevated-disabled-container-color: #8bc34a;
  --mdc-chip-flat-disabled-selected-container-color: #8bc34a;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-selected-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-with-icon-icon-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-with-icon-disabled-icon-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-with-icon-selected-icon-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-with-trailing-icon-trailing-icon-color: rgba(0, 0, 0, 0.87);
  --mat-chip-selected-disabled-trailing-icon-color: rgba(0, 0, 0, 0.87);
  --mat-chip-selected-trailing-icon-color: rgba(0, 0, 0, 0.87);
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #f44336;
  --mdc-chip-elevated-selected-container-color: #f44336;
  --mdc-chip-elevated-disabled-container-color: #f44336;
  --mdc-chip-flat-disabled-selected-container-color: #f44336;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}

.mat-mdc-chip.mat-mdc-standard-chip {
  --mdc-chip-container-height: 32px;
}

.mat-mdc-standard-chip {
  --mdc-chip-label-text-font: Roboto, sans-serif;
  --mdc-chip-label-text-line-height: 20px;
  --mdc-chip-label-text-size: 14px;
  --mdc-chip-label-text-tracking: 0.0178571429em;
  --mdc-chip-label-text-weight: 400;
}

html {
  --mdc-switch-disabled-selected-icon-opacity: 0.38;
  --mdc-switch-disabled-track-opacity: 0.12;
  --mdc-switch-disabled-unselected-icon-opacity: 0.38;
  --mdc-switch-handle-height: 20px;
  --mdc-switch-handle-shape: 10px;
  --mdc-switch-handle-width: 20px;
  --mdc-switch-selected-icon-size: 18px;
  --mdc-switch-track-height: 14px;
  --mdc-switch-track-shape: 7px;
  --mdc-switch-track-width: 36px;
  --mdc-switch-unselected-icon-size: 18px;
  --mdc-switch-selected-focus-state-layer-opacity: 0.12;
  --mdc-switch-selected-hover-state-layer-opacity: 0.04;
  --mdc-switch-selected-pressed-state-layer-opacity: 0.1;
  --mdc-switch-unselected-focus-state-layer-opacity: 0.12;
  --mdc-switch-unselected-hover-state-layer-opacity: 0.04;
  --mdc-switch-unselected-pressed-state-layer-opacity: 0.1;
}
html .mat-mdc-slide-toggle {
  --mat-switch-disabled-selected-handle-opacity: 0.38;
  --mat-switch-disabled-unselected-handle-opacity: 0.38;
  --mat-switch-unselected-handle-size: 20px;
  --mat-switch-selected-handle-size: 20px;
  --mat-switch-pressed-handle-size: 20px;
  --mat-switch-with-icon-handle-size: 20px;
  --mat-switch-selected-handle-horizontal-margin: 0;
  --mat-switch-selected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-selected-pressed-handle-horizontal-margin: 0;
  --mat-switch-unselected-handle-horizontal-margin: 0;
  --mat-switch-unselected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-unselected-pressed-handle-horizontal-margin: 0;
  --mat-switch-visible-track-opacity: 1;
  --mat-switch-hidden-track-opacity: 1;
  --mat-switch-visible-track-transition: transform 75ms 0ms cubic-bezier(0, 0, 0.2, 1);
  --mat-switch-hidden-track-transition: transform 75ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  --mat-switch-track-outline-width: 1px;
  --mat-switch-track-outline-color: transparent;
  --mat-switch-selected-track-outline-width: 1px;
  --mat-switch-selected-track-outline-color: transparent;
  --mat-switch-disabled-unselected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-color: transparent;
}

html {
  --mdc-switch-selected-focus-state-layer-color: #48a1ee;
  --mdc-switch-selected-handle-color: #48a1ee;
  --mdc-switch-selected-hover-state-layer-color: #48a1ee;
  --mdc-switch-selected-pressed-state-layer-color: #48a1ee;
  --mdc-switch-selected-focus-handle-color: #48a1ee;
  --mdc-switch-selected-hover-handle-color: #48a1ee;
  --mdc-switch-selected-pressed-handle-color: #48a1ee;
  --mdc-switch-selected-focus-track-color: #48a1ee;
  --mdc-switch-selected-hover-track-color: #48a1ee;
  --mdc-switch-selected-pressed-track-color: #48a1ee;
  --mdc-switch-selected-track-color: #48a1ee;
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-handle-surface-color: #fff;
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-icon-color: #fff;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-disabled-label-text-color: rgba(0, 0, 0, 0.38);
}
html .mat-mdc-slide-toggle {
  --mat-switch-label-text-color: rgba(0, 0, 0, 0.87);
}
html .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #7cb342;
  --mdc-switch-selected-handle-color: #7cb342;
  --mdc-switch-selected-hover-state-layer-color: #7cb342;
  --mdc-switch-selected-pressed-state-layer-color: #7cb342;
  --mdc-switch-selected-focus-handle-color: #33691e;
  --mdc-switch-selected-hover-handle-color: #33691e;
  --mdc-switch-selected-pressed-handle-color: #33691e;
  --mdc-switch-selected-focus-track-color: #aed581;
  --mdc-switch-selected-hover-track-color: #aed581;
  --mdc-switch-selected-pressed-track-color: #aed581;
  --mdc-switch-selected-track-color: #aed581;
}
html .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #e53935;
  --mdc-switch-selected-handle-color: #e53935;
  --mdc-switch-selected-hover-state-layer-color: #e53935;
  --mdc-switch-selected-pressed-state-layer-color: #e53935;
  --mdc-switch-selected-focus-handle-color: #b71c1c;
  --mdc-switch-selected-hover-handle-color: #b71c1c;
  --mdc-switch-selected-pressed-handle-color: #b71c1c;
  --mdc-switch-selected-focus-track-color: #e57373;
  --mdc-switch-selected-hover-track-color: #e57373;
  --mdc-switch-selected-pressed-track-color: #e57373;
  --mdc-switch-selected-track-color: #e57373;
}

html {
  --mdc-switch-state-layer-size: 40px;
}
html .mat-mdc-slide-toggle {
  --mat-switch-label-text-font: Roboto, sans-serif;
  --mat-switch-label-text-line-height: 20px;
  --mat-switch-label-text-size: 14px;
  --mat-switch-label-text-tracking: 0.0178571429em;
  --mat-switch-label-text-weight: 400;
}

html {
  --mdc-radio-disabled-selected-icon-opacity: 0.38;
  --mdc-radio-disabled-unselected-icon-opacity: 0.38;
  --mdc-radio-state-layer-size: 40px;
}

.mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #48a1ee;
  --mdc-radio-selected-hover-icon-color: #48a1ee;
  --mdc-radio-selected-icon-color: #48a1ee;
  --mdc-radio-selected-pressed-icon-color: #48a1ee;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #48a1ee;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-radio-label-text-color: rgba(0, 0, 0, 0.87);
}
.mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #8bc34a;
  --mdc-radio-selected-hover-icon-color: #8bc34a;
  --mdc-radio-selected-icon-color: #8bc34a;
  --mdc-radio-selected-pressed-icon-color: #8bc34a;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #8bc34a;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-radio-label-text-color: rgba(0, 0, 0, 0.87);
}
.mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #f44336;
  --mdc-radio-selected-hover-icon-color: #f44336;
  --mdc-radio-selected-icon-color: #f44336;
  --mdc-radio-selected-pressed-icon-color: #f44336;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #f44336;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-radio-label-text-color: rgba(0, 0, 0, 0.87);
}

html {
  --mdc-radio-state-layer-size: 40px;
  --mat-radio-touch-target-display: block;
}

html {
  --mat-radio-label-text-font: Roboto, sans-serif;
  --mat-radio-label-text-line-height: 20px;
  --mat-radio-label-text-size: 14px;
  --mat-radio-label-text-tracking: 0.0178571429em;
  --mat-radio-label-text-weight: 400;
}

html {
  --mdc-slider-active-track-height: 6px;
  --mdc-slider-active-track-shape: 9999px;
  --mdc-slider-handle-height: 20px;
  --mdc-slider-handle-shape: 50%;
  --mdc-slider-handle-width: 20px;
  --mdc-slider-inactive-track-height: 4px;
  --mdc-slider-inactive-track-shape: 9999px;
  --mdc-slider-with-overlap-handle-outline-width: 1px;
  --mdc-slider-with-tick-marks-active-container-opacity: 0.6;
  --mdc-slider-with-tick-marks-container-shape: 50%;
  --mdc-slider-with-tick-marks-container-size: 2px;
  --mdc-slider-with-tick-marks-inactive-container-opacity: 0.6;
  --mdc-slider-handle-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-slider-value-indicator-width: auto;
  --mat-slider-value-indicator-height: 32px;
  --mat-slider-value-indicator-caret-display: block;
  --mat-slider-value-indicator-border-radius: 4px;
  --mat-slider-value-indicator-padding: 0 12px;
  --mat-slider-value-indicator-text-transform: none;
  --mat-slider-value-indicator-container-transform: translateX(-50%);
}

html {
  --mdc-slider-handle-color: #48a1ee;
  --mdc-slider-focus-handle-color: #48a1ee;
  --mdc-slider-hover-handle-color: #48a1ee;
  --mdc-slider-active-track-color: #48a1ee;
  --mdc-slider-inactive-track-color: #48a1ee;
  --mdc-slider-with-tick-marks-inactive-container-color: #48a1ee;
  --mdc-slider-with-tick-marks-active-container-color: var(--color-text-800);
  --mdc-slider-disabled-active-track-color: #000;
  --mdc-slider-disabled-handle-color: #000;
  --mdc-slider-disabled-inactive-track-color: #000;
  --mdc-slider-label-container-color: #000;
  --mdc-slider-label-label-text-color: #fff;
  --mdc-slider-with-overlap-handle-outline-color: #fff;
  --mdc-slider-with-tick-marks-disabled-container-color: #000;
  --mat-slider-ripple-color: #48a1ee;
  --mat-slider-hover-state-layer-color: rgba(72, 161, 238, 0.05);
  --mat-slider-focus-state-layer-color: rgba(72, 161, 238, 0.2);
  --mat-slider-value-indicator-opacity: 0.6;
}
html .mat-accent {
  --mdc-slider-handle-color: #8bc34a;
  --mdc-slider-focus-handle-color: #8bc34a;
  --mdc-slider-hover-handle-color: #8bc34a;
  --mdc-slider-active-track-color: #8bc34a;
  --mdc-slider-inactive-track-color: #8bc34a;
  --mdc-slider-with-tick-marks-inactive-container-color: #8bc34a;
  --mdc-slider-with-tick-marks-active-container-color: rgba(0, 0, 0, 0.87);
  --mat-slider-ripple-color: #8bc34a;
  --mat-slider-hover-state-layer-color: rgba(139, 195, 74, 0.05);
  --mat-slider-focus-state-layer-color: rgba(139, 195, 74, 0.2);
}
html .mat-warn {
  --mdc-slider-handle-color: #f44336;
  --mdc-slider-focus-handle-color: #f44336;
  --mdc-slider-hover-handle-color: #f44336;
  --mdc-slider-active-track-color: #f44336;
  --mdc-slider-inactive-track-color: #f44336;
  --mdc-slider-with-tick-marks-inactive-container-color: #f44336;
  --mdc-slider-with-tick-marks-active-container-color: white;
  --mat-slider-ripple-color: #f44336;
  --mat-slider-hover-state-layer-color: rgba(244, 67, 54, 0.05);
  --mat-slider-focus-state-layer-color: rgba(244, 67, 54, 0.2);
}

html {
  --mdc-slider-label-label-text-font: Roboto, sans-serif;
  --mdc-slider-label-label-text-size: 14px;
  --mdc-slider-label-label-text-line-height: 22px;
  --mdc-slider-label-label-text-tracking: 0.0071428571em;
  --mdc-slider-label-label-text-weight: 500;
}

html {
  --mat-menu-container-shape: 4px;
  --mat-menu-divider-bottom-spacing: 0;
  --mat-menu-divider-top-spacing: 0;
  --mat-menu-item-spacing: 16px;
  --mat-menu-item-icon-size: 24px;
  --mat-menu-item-leading-spacing: 16px;
  --mat-menu-item-trailing-spacing: 16px;
  --mat-menu-item-with-icon-leading-spacing: 16px;
  --mat-menu-item-with-icon-trailing-spacing: 16px;
  --mat-menu-base-elevation-level: 8;
}

html {
  --mat-menu-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-icon-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-item-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-container-color: white;
  --mat-menu-divider-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-menu-item-label-text-font: Roboto, sans-serif;
  --mat-menu-item-label-text-size: 16px;
  --mat-menu-item-label-text-tracking: 0.03125em;
  --mat-menu-item-label-text-line-height: 24px;
  --mat-menu-item-label-text-weight: 400;
}

html {
  --mdc-list-list-item-container-shape: 0;
  --mdc-list-list-item-leading-avatar-shape: 50%;
  --mdc-list-list-item-container-color: transparent;
  --mdc-list-list-item-selected-container-color: transparent;
  --mdc-list-list-item-leading-avatar-color: transparent;
  --mdc-list-list-item-leading-icon-size: 24px;
  --mdc-list-list-item-leading-avatar-size: 40px;
  --mdc-list-list-item-trailing-icon-size: 24px;
  --mdc-list-list-item-disabled-state-layer-color: transparent;
  --mdc-list-list-item-disabled-state-layer-opacity: 0;
  --mdc-list-list-item-disabled-label-text-opacity: 0.38;
  --mdc-list-list-item-disabled-leading-icon-opacity: 0.38;
  --mdc-list-list-item-disabled-trailing-icon-opacity: 0.38;
  --mat-list-active-indicator-color: transparent;
  --mat-list-active-indicator-shape: 4px;
}

html {
  --mdc-list-list-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-supporting-text-color: rgba(0, 0, 0, 0.54);
  --mdc-list-list-item-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-supporting-text-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-disabled-label-text-color: black;
  --mdc-list-list-item-disabled-leading-icon-color: black;
  --mdc-list-list-item-disabled-trailing-icon-color: black;
  --mdc-list-list-item-hover-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-focus-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-state-layer-color: black;
  --mdc-list-list-item-hover-state-layer-opacity: 0.04;
  --mdc-list-list-item-focus-state-layer-color: black;
  --mdc-list-list-item-focus-state-layer-opacity: 0.12;
}

.mdc-list-item__start,
.mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #48a1ee;
  --mdc-radio-selected-hover-icon-color: #48a1ee;
  --mdc-radio-selected-icon-color: #48a1ee;
  --mdc-radio-selected-pressed-icon-color: #48a1ee;
}

.mat-accent .mdc-list-item__start,
.mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #8bc34a;
  --mdc-radio-selected-hover-icon-color: #8bc34a;
  --mdc-radio-selected-icon-color: #8bc34a;
  --mdc-radio-selected-pressed-icon-color: #8bc34a;
}

.mat-warn .mdc-list-item__start,
.mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #f44336;
  --mdc-radio-selected-hover-icon-color: #f44336;
  --mdc-radio-selected-icon-color: #f44336;
  --mdc-radio-selected-pressed-icon-color: #f44336;
}

.mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: var(--color-text-800);
  --mdc-checkbox-selected-focus-icon-color: #48a1ee;
  --mdc-checkbox-selected-hover-icon-color: #48a1ee;
  --mdc-checkbox-selected-icon-color: #48a1ee;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #48a1ee;
  --mdc-checkbox-selected-hover-state-layer-color: #48a1ee;
  --mdc-checkbox-selected-pressed-state-layer-color: #48a1ee;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: black;
  --mdc-checkbox-selected-focus-icon-color: #8bc34a;
  --mdc-checkbox-selected-hover-icon-color: #8bc34a;
  --mdc-checkbox-selected-icon-color: #8bc34a;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #8bc34a;
  --mdc-checkbox-selected-hover-state-layer-color: #8bc34a;
  --mdc-checkbox-selected-pressed-state-layer-color: #8bc34a;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #f44336;
  --mdc-checkbox-selected-hover-icon-color: #f44336;
  --mdc-checkbox-selected-icon-color: #f44336;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f44336;
  --mdc-checkbox-selected-hover-state-layer-color: #f44336;
  --mdc-checkbox-selected-pressed-state-layer-color: #f44336;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__start,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__start {
  color: #48a1ee;
}

.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}

html {
  --mdc-list-list-item-one-line-container-height: 48px;
  --mdc-list-list-item-two-line-container-height: 64px;
  --mdc-list-list-item-three-line-container-height: 88px;
  --mat-list-list-item-leading-icon-start-space: 16px;
  --mat-list-list-item-leading-icon-end-space: 32px;
}

.mdc-list-item__start,
.mdc-list-item__end {
  --mdc-radio-state-layer-size: 40px;
}

.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-one-line {
  height: 56px;
}
.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines {
  height: 72px;
}

html {
  --mdc-list-list-item-label-text-font: Roboto, sans-serif;
  --mdc-list-list-item-label-text-line-height: 24px;
  --mdc-list-list-item-label-text-size: 16px;
  --mdc-list-list-item-label-text-tracking: 0.03125em;
  --mdc-list-list-item-label-text-weight: 400;
  --mdc-list-list-item-supporting-text-font: Roboto, sans-serif;
  --mdc-list-list-item-supporting-text-line-height: 20px;
  --mdc-list-list-item-supporting-text-size: 14px;
  --mdc-list-list-item-supporting-text-tracking: 0.0178571429em;
  --mdc-list-list-item-supporting-text-weight: 400;
  --mdc-list-list-item-trailing-supporting-text-font: Roboto, sans-serif;
  --mdc-list-list-item-trailing-supporting-text-line-height: 20px;
  --mdc-list-list-item-trailing-supporting-text-size: 12px;
  --mdc-list-list-item-trailing-supporting-text-tracking: 0.0333333333em;
  --mdc-list-list-item-trailing-supporting-text-weight: 400;
}

.mdc-list-group__subheader {
  font: 400 16px / 28px Roboto, sans-serif;
  letter-spacing: 0.009375em;
}

html {
  --mat-paginator-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-paginator-container-background-color: white;
  --mat-paginator-enabled-icon-color: rgba(0, 0, 0, 0.54);
  --mat-paginator-disabled-icon-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-paginator-container-size: 56px;
  --mat-paginator-form-field-container-height: 40px;
  --mat-paginator-form-field-container-vertical-padding: 8px;
  --mat-paginator-touch-target-display: block;
}

html {
  --mat-paginator-container-text-font: Roboto, sans-serif;
  --mat-paginator-container-text-line-height: 20px;
  --mat-paginator-container-text-size: 12px;
  --mat-paginator-container-text-tracking: 0.0333333333em;
  --mat-paginator-container-text-weight: 400;
  --mat-paginator-select-trigger-text-size: 12px;
}

html {
  --mdc-secondary-navigation-tab-container-height: 48px;
  --mdc-tab-indicator-active-indicator-height: 2px;
  --mdc-tab-indicator-active-indicator-shape: 0;
  --mat-tab-header-divider-color: transparent;
  --mat-tab-header-divider-height: 0;
}

.mat-mdc-tab-group, .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #48a1ee;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #48a1ee;
  --mat-tab-header-active-ripple-color: #48a1ee;
  --mat-tab-header-inactive-ripple-color: #48a1ee;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #48a1ee;
  --mat-tab-header-active-hover-label-text-color: #48a1ee;
  --mat-tab-header-active-focus-indicator-color: #48a1ee;
  --mat-tab-header-active-hover-indicator-color: #48a1ee;
}
.mat-mdc-tab-group.mat-accent, .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #8bc34a;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #8bc34a;
  --mat-tab-header-active-ripple-color: #8bc34a;
  --mat-tab-header-inactive-ripple-color: #8bc34a;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #8bc34a;
  --mat-tab-header-active-hover-label-text-color: #8bc34a;
  --mat-tab-header-active-focus-indicator-color: #8bc34a;
  --mat-tab-header-active-hover-indicator-color: #8bc34a;
}
.mat-mdc-tab-group.mat-warn, .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #f44336;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #f44336;
  --mat-tab-header-active-ripple-color: #f44336;
  --mat-tab-header-inactive-ripple-color: #f44336;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #f44336;
  --mat-tab-header-active-hover-label-text-color: #f44336;
  --mat-tab-header-active-focus-indicator-color: #f44336;
  --mat-tab-header-active-hover-indicator-color: #f44336;
}
.mat-mdc-tab-group.mat-background-primary, .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: #48a1ee;
  --mat-tab-header-with-background-foreground-color: var(--color-text-800);
}
.mat-mdc-tab-group.mat-background-accent, .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #8bc34a;
  --mat-tab-header-with-background-foreground-color: rgba(0, 0, 0, 0.87);
}
.mat-mdc-tab-group.mat-background-warn, .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #f44336;
  --mat-tab-header-with-background-foreground-color: white;
}

.mat-mdc-tab-header {
  --mdc-secondary-navigation-tab-container-height: 48px;
}

.mat-mdc-tab-header {
  --mat-tab-header-label-text-font: Roboto, sans-serif;
  --mat-tab-header-label-text-size: 14px;
  --mat-tab-header-label-text-tracking: 0.0892857143em;
  --mat-tab-header-label-text-line-height: 36px;
  --mat-tab-header-label-text-weight: 500;
}

html {
  --mdc-checkbox-disabled-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-selected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-selected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-unselected-pressed-state-layer-opacity: 0.16;
}

html {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: black;
  --mdc-checkbox-selected-focus-icon-color: #8bc34a;
  --mdc-checkbox-selected-hover-icon-color: #8bc34a;
  --mdc-checkbox-selected-icon-color: #8bc34a;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #8bc34a;
  --mdc-checkbox-selected-hover-state-layer-color: #8bc34a;
  --mdc-checkbox-selected-pressed-state-layer-color: #8bc34a;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
  --mat-checkbox-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-checkbox-label-text-color: rgba(0, 0, 0, 0.87);
}

.mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: var(--color-text-800);
  --mdc-checkbox-selected-focus-icon-color: #48a1ee;
  --mdc-checkbox-selected-hover-icon-color: #48a1ee;
  --mdc-checkbox-selected-icon-color: #48a1ee;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #48a1ee;
  --mdc-checkbox-selected-hover-state-layer-color: #48a1ee;
  --mdc-checkbox-selected-pressed-state-layer-color: #48a1ee;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #f44336;
  --mdc-checkbox-selected-hover-icon-color: #f44336;
  --mdc-checkbox-selected-icon-color: #f44336;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f44336;
  --mdc-checkbox-selected-hover-state-layer-color: #f44336;
  --mdc-checkbox-selected-pressed-state-layer-color: #f44336;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

html {
  --mdc-checkbox-state-layer-size: 40px;
  --mat-checkbox-touch-target-display: block;
}

html {
  --mat-checkbox-label-text-font: Roboto, sans-serif;
  --mat-checkbox-label-text-line-height: 20px;
  --mat-checkbox-label-text-size: 14px;
  --mat-checkbox-label-text-tracking: 0.0178571429em;
  --mat-checkbox-label-text-weight: 400;
}

html {
  --mdc-text-button-container-shape: 4px;
  --mdc-text-button-keep-touch-target: false;
  --mdc-filled-button-container-shape: 4px;
  --mdc-filled-button-keep-touch-target: false;
  --mdc-protected-button-container-shape: 4px;
  --mdc-protected-button-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-container-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-focus-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-hover-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-pressed-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-keep-touch-target: false;
  --mdc-outlined-button-outline-width: 1px;
  --mdc-outlined-button-container-shape: 4px;
  --mat-text-button-horizontal-padding: 8px;
  --mat-text-button-with-icon-horizontal-padding: 8px;
  --mat-text-button-icon-spacing: 8px;
  --mat-text-button-icon-offset: 0;
  --mat-filled-button-horizontal-padding: 16px;
  --mat-filled-button-icon-spacing: 8px;
  --mat-filled-button-icon-offset: -4px;
  --mat-protected-button-horizontal-padding: 16px;
  --mat-protected-button-icon-spacing: 8px;
  --mat-protected-button-icon-offset: -4px;
  --mat-outlined-button-horizontal-padding: 15px;
  --mat-outlined-button-icon-spacing: 8px;
  --mat-outlined-button-icon-offset: -4px;
}

html {
  --mdc-text-button-label-text-color: black;
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-text-button-state-layer-color: black;
  --mat-text-button-disabled-state-layer-color: black;
  --mat-text-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-text-button-hover-state-layer-opacity: 0.04;
  --mat-text-button-focus-state-layer-opacity: 0.12;
  --mat-text-button-pressed-state-layer-opacity: 0.12;
  --mdc-filled-button-container-color: white;
  --mdc-filled-button-label-text-color: black;
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-filled-button-state-layer-color: black;
  --mat-filled-button-disabled-state-layer-color: black;
  --mat-filled-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-filled-button-hover-state-layer-opacity: 0.04;
  --mat-filled-button-focus-state-layer-opacity: 0.12;
  --mat-filled-button-pressed-state-layer-opacity: 0.12;
  --mdc-protected-button-container-color: white;
  --mdc-protected-button-label-text-color: black;
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-protected-button-state-layer-color: black;
  --mat-protected-button-disabled-state-layer-color: black;
  --mat-protected-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-protected-button-hover-state-layer-opacity: 0.04;
  --mat-protected-button-focus-state-layer-opacity: 0.12;
  --mat-protected-button-pressed-state-layer-opacity: 0.12;
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-label-text-color: black;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: black;
  --mat-outlined-button-disabled-state-layer-color: black;
  --mat-outlined-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-outlined-button-hover-state-layer-opacity: 0.04;
  --mat-outlined-button-focus-state-layer-opacity: 0.12;
  --mat-outlined-button-pressed-state-layer-opacity: 0.12;
}

.mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #48a1ee;
  --mat-text-button-state-layer-color: #48a1ee;
  --mat-text-button-ripple-color: rgba(72, 161, 238, 0.1);
}
.mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #8bc34a;
  --mat-text-button-state-layer-color: #8bc34a;
  --mat-text-button-ripple-color: rgba(139, 195, 74, 0.1);
}
.mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #f44336;
  --mat-text-button-state-layer-color: #f44336;
  --mat-text-button-ripple-color: rgba(244, 67, 54, 0.1);
}

.mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #48a1ee;
  --mdc-filled-button-label-text-color: var(--color-text-800);
  --mat-filled-button-state-layer-color: #000;
  --mat-filled-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #8bc34a;
  --mdc-filled-button-label-text-color: black;
  --mat-filled-button-state-layer-color: black;
  --mat-filled-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #f44336;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}

.mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #48a1ee;
  --mdc-protected-button-label-text-color: var(--color-text-800);
  --mat-protected-button-state-layer-color: #000;
  --mat-protected-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #8bc34a;
  --mdc-protected-button-label-text-color: black;
  --mat-protected-button-state-layer-color: black;
  --mat-protected-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #f44336;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}

.mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #48a1ee;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #48a1ee;
  --mat-outlined-button-ripple-color: rgba(72, 161, 238, 0.1);
}
.mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #8bc34a;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #8bc34a;
  --mat-outlined-button-ripple-color: rgba(139, 195, 74, 0.1);
}
.mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #f44336;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #f44336;
  --mat-outlined-button-ripple-color: rgba(244, 67, 54, 0.1);
}

html {
  --mdc-text-button-container-height: 36px;
  --mdc-filled-button-container-height: 36px;
  --mdc-protected-button-container-height: 36px;
  --mdc-outlined-button-container-height: 36px;
  --mat-text-button-touch-target-display: block;
  --mat-filled-button-touch-target-display: block;
  --mat-protected-button-touch-target-display: block;
  --mat-outlined-button-touch-target-display: block;
}

html {
  --mdc-text-button-label-text-font: Roboto, sans-serif;
  --mdc-text-button-label-text-size: 14px;
  --mdc-text-button-label-text-tracking: 0.0892857143em;
  --mdc-text-button-label-text-weight: 500;
  --mdc-text-button-label-text-transform: none;
  --mdc-filled-button-label-text-font: Roboto, sans-serif;
  --mdc-filled-button-label-text-size: 14px;
  --mdc-filled-button-label-text-tracking: 0.0892857143em;
  --mdc-filled-button-label-text-weight: 500;
  --mdc-filled-button-label-text-transform: none;
  --mdc-protected-button-label-text-font: Roboto, sans-serif;
  --mdc-protected-button-label-text-size: 14px;
  --mdc-protected-button-label-text-tracking: 0.0892857143em;
  --mdc-protected-button-label-text-weight: 500;
  --mdc-protected-button-label-text-transform: none;
  --mdc-outlined-button-label-text-font: Roboto, sans-serif;
  --mdc-outlined-button-label-text-size: 14px;
  --mdc-outlined-button-label-text-tracking: 0.0892857143em;
  --mdc-outlined-button-label-text-weight: 500;
  --mdc-outlined-button-label-text-transform: none;
}

html {
  --mdc-icon-button-icon-size: 24px;
}

html {
  --mdc-icon-button-icon-color: inherit;
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
  --mat-icon-button-state-layer-color: black;
  --mat-icon-button-disabled-state-layer-color: black;
  --mat-icon-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-icon-button-hover-state-layer-opacity: 0.04;
  --mat-icon-button-focus-state-layer-opacity: 0.12;
  --mat-icon-button-pressed-state-layer-opacity: 0.12;
}
html .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #48a1ee;
  --mat-icon-button-state-layer-color: #48a1ee;
  --mat-icon-button-ripple-color: rgba(72, 161, 238, 0.1);
}
html .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #8bc34a;
  --mat-icon-button-state-layer-color: #8bc34a;
  --mat-icon-button-ripple-color: rgba(139, 195, 74, 0.1);
}
html .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #f44336;
  --mat-icon-button-state-layer-color: #f44336;
  --mat-icon-button-ripple-color: rgba(244, 67, 54, 0.1);
}

html {
  --mat-icon-button-touch-target-display: block;
}

.mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 48px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 12px;
}

html {
  --mdc-fab-container-shape: 50%;
  --mdc-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-container-shape: 50%;
  --mdc-fab-small-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-container-height: 48px;
  --mdc-extended-fab-container-shape: 24px;
  --mdc-extended-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

html {
  --mdc-fab-container-color: white;
  --mat-fab-foreground-color: black;
  --mat-fab-state-layer-color: black;
  --mat-fab-disabled-state-layer-color: black;
  --mat-fab-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-fab-hover-state-layer-opacity: 0.04;
  --mat-fab-focus-state-layer-opacity: 0.12;
  --mat-fab-pressed-state-layer-opacity: 0.12;
  --mat-fab-disabled-state-container-color: rgba(0, 0, 0, 0.12);
  --mat-fab-disabled-state-foreground-color: rgba(0, 0, 0, 0.38);
  --mdc-fab-small-container-color: white;
  --mat-fab-small-foreground-color: black;
  --mat-fab-small-state-layer-color: black;
  --mat-fab-small-disabled-state-layer-color: black;
  --mat-fab-small-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-fab-small-hover-state-layer-opacity: 0.04;
  --mat-fab-small-focus-state-layer-opacity: 0.12;
  --mat-fab-small-pressed-state-layer-opacity: 0.12;
  --mat-fab-small-disabled-state-container-color: rgba(0, 0, 0, 0.12);
  --mat-fab-small-disabled-state-foreground-color: rgba(0, 0, 0, 0.38);
}
html .mat-mdc-fab.mat-primary {
  --mdc-fab-container-color: #48a1ee;
  --mat-fab-foreground-color: #000;
  --mat-fab-state-layer-color: #000;
  --mat-fab-ripple-color: rgba(0, 0, 0, 0.1);
}
html .mat-mdc-fab.mat-accent {
  --mdc-fab-container-color: #8bc34a;
  --mat-fab-foreground-color: black;
  --mat-fab-state-layer-color: black;
  --mat-fab-ripple-color: rgba(0, 0, 0, 0.1);
}
html .mat-mdc-fab.mat-warn {
  --mdc-fab-container-color: #f44336;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
html .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-small-container-color: #48a1ee;
  --mat-fab-small-foreground-color: #000;
  --mat-fab-small-state-layer-color: #000;
  --mat-fab-small-ripple-color: rgba(0, 0, 0, 0.1);
}
html .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-small-container-color: #8bc34a;
  --mat-fab-small-foreground-color: black;
  --mat-fab-small-state-layer-color: black;
  --mat-fab-small-ripple-color: rgba(0, 0, 0, 0.1);
}
html .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-small-container-color: #f44336;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}

html {
  --mat-fab-touch-target-display: block;
  --mat-fab-small-touch-target-display: block;
}

html {
  --mdc-extended-fab-label-text-font: Roboto, sans-serif;
  --mdc-extended-fab-label-text-size: 14px;
  --mdc-extended-fab-label-text-tracking: 0.0892857143em;
  --mdc-extended-fab-label-text-weight: 500;
}

html {
  --mdc-snackbar-container-shape: 4px;
}

html {
  --mdc-snackbar-container-color: #333333;
  --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87);
  --mat-snack-bar-button-color: #8bc34a;
}

html {
  --mdc-snackbar-supporting-text-font: Roboto, sans-serif;
  --mdc-snackbar-supporting-text-line-height: 20px;
  --mdc-snackbar-supporting-text-size: 14px;
  --mdc-snackbar-supporting-text-weight: 400;
}

html {
  --mat-table-row-item-outline-width: 1px;
}

html {
  --mat-table-background-color: white;
  --mat-table-header-headline-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-outline-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-table-header-container-height: 56px;
  --mat-table-footer-container-height: 52px;
  --mat-table-row-item-container-height: 52px;
}

html {
  --mat-table-header-headline-font: Roboto, sans-serif;
  --mat-table-header-headline-line-height: 22px;
  --mat-table-header-headline-size: 14px;
  --mat-table-header-headline-weight: 500;
  --mat-table-header-headline-tracking: 0.0071428571em;
  --mat-table-row-item-label-text-font: Roboto, sans-serif;
  --mat-table-row-item-label-text-line-height: 20px;
  --mat-table-row-item-label-text-size: 14px;
  --mat-table-row-item-label-text-weight: 400;
  --mat-table-row-item-label-text-tracking: 0.0178571429em;
  --mat-table-footer-supporting-text-font: Roboto, sans-serif;
  --mat-table-footer-supporting-text-line-height: 20px;
  --mat-table-footer-supporting-text-size: 14px;
  --mat-table-footer-supporting-text-weight: 400;
  --mat-table-footer-supporting-text-tracking: 0.0178571429em;
}

html {
  --mdc-circular-progress-active-indicator-width: 4px;
  --mdc-circular-progress-size: 48px;
}

html {
  --mdc-circular-progress-active-indicator-color: #48a1ee;
}
html .mat-accent {
  --mdc-circular-progress-active-indicator-color: #8bc34a;
}
html .mat-warn {
  --mdc-circular-progress-active-indicator-color: #f44336;
}

html {
  --mat-badge-container-shape: 50%;
  --mat-badge-container-size: unset;
  --mat-badge-small-size-container-size: unset;
  --mat-badge-large-size-container-size: unset;
  --mat-badge-legacy-container-size: 22px;
  --mat-badge-legacy-small-size-container-size: 16px;
  --mat-badge-legacy-large-size-container-size: 28px;
  --mat-badge-container-offset: -11px 0;
  --mat-badge-small-size-container-offset: -8px 0;
  --mat-badge-large-size-container-offset: -14px 0;
  --mat-badge-container-overlap-offset: -11px;
  --mat-badge-small-size-container-overlap-offset: -8px;
  --mat-badge-large-size-container-overlap-offset: -14px;
  --mat-badge-container-padding: 0;
  --mat-badge-small-size-container-padding: 0;
  --mat-badge-large-size-container-padding: 0;
}

html {
  --mat-badge-background-color: #48a1ee;
  --mat-badge-text-color: var(--color-text-800);
  --mat-badge-disabled-state-background-color: #b9b9b9;
  --mat-badge-disabled-state-text-color: rgba(0, 0, 0, 0.38);
}

.mat-badge-accent {
  --mat-badge-background-color: #8bc34a;
  --mat-badge-text-color: rgba(0, 0, 0, 0.87);
}

.mat-badge-warn {
  --mat-badge-background-color: #f44336;
  --mat-badge-text-color: white;
}

html {
  --mat-badge-text-font: Roboto, sans-serif;
  --mat-badge-text-size: 12px;
  --mat-badge-text-weight: 600;
  --mat-badge-small-size-text-size: 9px;
  --mat-badge-large-size-text-size: 24px;
}

html {
  --mat-bottom-sheet-container-shape: 4px;
}

html {
  --mat-bottom-sheet-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-bottom-sheet-container-background-color: white;
}

html {
  --mat-bottom-sheet-container-text-font: Roboto, sans-serif;
  --mat-bottom-sheet-container-text-line-height: 20px;
  --mat-bottom-sheet-container-text-size: 14px;
  --mat-bottom-sheet-container-text-tracking: 0.0178571429em;
  --mat-bottom-sheet-container-text-weight: 400;
}

html {
  --mat-legacy-button-toggle-height: 36px;
  --mat-legacy-button-toggle-shape: 2px;
  --mat-legacy-button-toggle-focus-state-layer-opacity: 1;
  --mat-standard-button-toggle-shape: 4px;
  --mat-standard-button-toggle-hover-state-layer-opacity: 0.04;
  --mat-standard-button-toggle-focus-state-layer-opacity: 0.12;
}

html {
  --mat-legacy-button-toggle-text-color: rgba(0, 0, 0, 0.38);
  --mat-legacy-button-toggle-state-layer-color: rgba(0, 0, 0, 0.12);
  --mat-legacy-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.54);
  --mat-legacy-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-legacy-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-legacy-button-toggle-disabled-state-background-color: #eeeeee;
  --mat-legacy-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-background-color: white;
  --mat-standard-button-toggle-state-layer-color: black;
  --mat-standard-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-standard-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-standard-button-toggle-disabled-state-background-color: white;
  --mat-standard-button-toggle-disabled-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-divider-color: #e0e0e0;
}

html {
  --mat-standard-button-toggle-height: 48px;
}

html {
  --mat-legacy-button-toggle-label-text-font: Roboto, sans-serif;
  --mat-legacy-button-toggle-label-text-line-height: 24px;
  --mat-legacy-button-toggle-label-text-size: 16px;
  --mat-legacy-button-toggle-label-text-tracking: 0.03125em;
  --mat-legacy-button-toggle-label-text-weight: 400;
  --mat-standard-button-toggle-label-text-font: Roboto, sans-serif;
  --mat-standard-button-toggle-label-text-line-height: 24px;
  --mat-standard-button-toggle-label-text-size: 16px;
  --mat-standard-button-toggle-label-text-tracking: 0.03125em;
  --mat-standard-button-toggle-label-text-weight: 400;
}

html {
  --mat-datepicker-calendar-container-shape: 4px;
  --mat-datepicker-calendar-container-touch-shape: 4px;
  --mat-datepicker-calendar-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-container-touch-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

html {
  --mat-datepicker-calendar-date-selected-state-text-color: var(--color-text-800);
  --mat-datepicker-calendar-date-selected-state-background-color: #48a1ee;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(72, 161, 238, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: var(--color-text-800);
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(72, 161, 238, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(72, 161, 238, 0.3);
  --mat-datepicker-toggle-active-state-icon-color: #48a1ee;
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(72, 161, 238, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  --mat-datepicker-toggle-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-body-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-period-button-text-color: black;
  --mat-datepicker-calendar-period-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-navigation-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-header-divider-color: rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-header-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-date-today-outline-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(0, 0, 0, 0.18);
  --mat-datepicker-calendar-date-text-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-preview-state-outline-color: rgba(0, 0, 0, 0.24);
  --mat-datepicker-range-input-separator-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-range-input-disabled-state-separator-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-range-input-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-container-background-color: white;
  --mat-datepicker-calendar-container-text-color: rgba(0, 0, 0, 0.87);
}

.mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-calendar-date-selected-state-background-color: #8bc34a;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(139, 195, 74, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(139, 195, 74, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(139, 195, 74, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(139, 195, 74, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #f44336;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(244, 67, 54, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(244, 67, 54, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(244, 67, 54, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(244, 67, 54, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}

.mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: #8bc34a;
}
.mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: #f44336;
}

.mat-calendar-controls {
  --mat-icon-button-touch-target-display: none;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 40px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 8px;
}

html {
  --mat-datepicker-calendar-text-font: Roboto, sans-serif;
  --mat-datepicker-calendar-text-size: 13px;
  --mat-datepicker-calendar-body-label-text-size: 14px;
  --mat-datepicker-calendar-body-label-text-weight: 500;
  --mat-datepicker-calendar-period-button-text-size: 14px;
  --mat-datepicker-calendar-period-button-text-weight: 500;
  --mat-datepicker-calendar-header-text-size: 11px;
  --mat-datepicker-calendar-header-text-weight: 400;
}

html {
  --mat-divider-width: 1px;
}

html {
  --mat-divider-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-expansion-container-shape: 4px;
  --mat-expansion-legacy-header-indicator-display: inline-block;
  --mat-expansion-header-indicator-display: none;
}

html {
  --mat-expansion-container-background-color: white;
  --mat-expansion-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-actions-divider-color: rgba(0, 0, 0, 0.12);
  --mat-expansion-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-expansion-header-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-header-description-color: rgba(0, 0, 0, 0.54);
  --mat-expansion-header-indicator-color: rgba(0, 0, 0, 0.54);
}

html {
  --mat-expansion-header-collapsed-state-height: 48px;
  --mat-expansion-header-expanded-state-height: 64px;
}

html {
  --mat-expansion-header-text-font: Roboto, sans-serif;
  --mat-expansion-header-text-size: 14px;
  --mat-expansion-header-text-weight: 500;
  --mat-expansion-header-text-line-height: inherit;
  --mat-expansion-header-text-tracking: inherit;
  --mat-expansion-container-text-font: Roboto, sans-serif;
  --mat-expansion-container-text-line-height: 20px;
  --mat-expansion-container-text-size: 14px;
  --mat-expansion-container-text-tracking: 0.0178571429em;
  --mat-expansion-container-text-weight: 400;
}

html {
  --mat-grid-list-tile-header-primary-text-size: 14px;
  --mat-grid-list-tile-header-secondary-text-size: 12px;
  --mat-grid-list-tile-footer-primary-text-size: 14px;
  --mat-grid-list-tile-footer-secondary-text-size: 12px;
}

html {
  --mat-icon-color: inherit;
}

.mat-icon.mat-primary {
  --mat-icon-color: #48a1ee;
}
.mat-icon.mat-accent {
  --mat-icon-color: #8bc34a;
}
.mat-icon.mat-warn {
  --mat-icon-color: #f44336;
}

html {
  --mat-sidenav-container-shape: 0;
  --mat-sidenav-container-elevation-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-width: auto;
}

html {
  --mat-sidenav-container-divider-color: rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-background-color: white;
  --mat-sidenav-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-content-background-color: #fafafa;
  --mat-sidenav-content-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-scrim-color: rgba(0, 0, 0, 0.6);
}

html {
  --mat-stepper-header-icon-foreground-color: var(--color-text-800);
  --mat-stepper-header-selected-state-icon-background-color: #48a1ee;
  --mat-stepper-header-selected-state-icon-foreground-color: var(--color-text-800);
  --mat-stepper-header-done-state-icon-background-color: #48a1ee;
  --mat-stepper-header-done-state-icon-foreground-color: var(--color-text-800);
  --mat-stepper-header-edit-state-icon-background-color: #48a1ee;
  --mat-stepper-header-edit-state-icon-foreground-color: var(--color-text-800);
  --mat-stepper-container-color: white;
  --mat-stepper-line-color: rgba(0, 0, 0, 0.12);
  --mat-stepper-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-optional-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-selected-state-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-stepper-header-error-state-label-text-color: #f44336;
  --mat-stepper-header-icon-background-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-error-state-icon-foreground-color: #f44336;
  --mat-stepper-header-error-state-icon-background-color: transparent;
}
html .mat-step-header.mat-accent {
  --mat-stepper-header-icon-foreground-color: rgba(0, 0, 0, 0.87);
  --mat-stepper-header-selected-state-icon-background-color: #8bc34a;
  --mat-stepper-header-selected-state-icon-foreground-color: rgba(0, 0, 0, 0.87);
  --mat-stepper-header-done-state-icon-background-color: #8bc34a;
  --mat-stepper-header-done-state-icon-foreground-color: rgba(0, 0, 0, 0.87);
  --mat-stepper-header-edit-state-icon-background-color: #8bc34a;
  --mat-stepper-header-edit-state-icon-foreground-color: rgba(0, 0, 0, 0.87);
}
html .mat-step-header.mat-warn {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #f44336;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #f44336;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #f44336;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}

html {
  --mat-stepper-header-height: 72px;
}

html {
  --mat-stepper-container-text-font: Roboto, sans-serif;
  --mat-stepper-header-label-text-font: Roboto, sans-serif;
  --mat-stepper-header-label-text-size: 14px;
  --mat-stepper-header-label-text-weight: 400;
  --mat-stepper-header-error-state-label-text-size: 16px;
  --mat-stepper-header-selected-state-label-text-size: 16px;
  --mat-stepper-header-selected-state-label-text-weight: 400;
}

html {
  --mat-sort-arrow-color: #757575;
}

html {
  --mat-toolbar-container-background-color: whitesmoke;
  --mat-toolbar-container-text-color: rgba(0, 0, 0, 0.87);
}

.mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: #48a1ee;
  --mat-toolbar-container-text-color: var(--color-text-800);
}
.mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color: #8bc34a;
  --mat-toolbar-container-text-color: rgba(0, 0, 0, 0.87);
}
.mat-toolbar.mat-warn {
  --mat-toolbar-container-background-color: #f44336;
  --mat-toolbar-container-text-color: white;
}

html {
  --mat-toolbar-standard-height: 64px;
  --mat-toolbar-mobile-height: 56px;
}

html {
  --mat-toolbar-title-text-font: Roboto, sans-serif;
  --mat-toolbar-title-text-line-height: 32px;
  --mat-toolbar-title-text-size: 20px;
  --mat-toolbar-title-text-tracking: 0.0125em;
  --mat-toolbar-title-text-weight: 500;
}

html {
  --mat-tree-container-background-color: white;
  --mat-tree-node-text-color: rgba(0, 0, 0, 0.87);
}

html {
  --mat-tree-node-min-height: 48px;
}

html {
  --mat-tree-node-text-font: Roboto, sans-serif;
  --mat-tree-node-text-size: 14px;
  --mat-tree-node-text-weight: 400;
}

:root {
  --mdc-theme-error: var(--color-theme-warn);
  --mat-sidenav-content-background-color: rgb(var(--color-bg-180-rgb));
  --mat-sidenav-container-background-color: rgb(var(--color-bg-180-rgb));
}

.mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-selected-checkmark-color: var(--color-text-white);
  --mdc-checkbox-unselected-icon-color: var(--color-text-400);
  --mdc-checkbox-unselected-focus-icon-color: var(--color-text-400);
  --mdc-checkbox-unselected-hover-icon-color: var(--color-text-400);
}

.mat-unthemed {
  --mdc-text-button-label-text-color: var(--color-text-800) !important;
}

.mat-datepicker-content,
.mat-mdc-datepicker-content {
  background-color: var(--color-bg-290) !important;
}

.mat-calendar-body-selected,
.mat-mdc-calendar-body-selected {
  background-color: var(--color-primary) !important;
}

.mat-calendar-table-header,
.mat-mdc-calendar-table-header,
.mat-calendar-body-label,
.mat-mdc-calendar-body-label,
.mat-calendar-body-cell-content,
.mat-mdc-calendar-body-cell-content {
  color: var(--color-text-800) !important;
}

.mat-calendar-body-cell-content.mat-calendar-body-selected {
  background-color: var(--color-primary) !important;
  color: var(--color-text-white) !important;
}

.mat-calendar-table-header span {
  color: var(--color-text-800) !important;
}

.mat-datepicker-toggle,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
  color: var(--color-primary);
}

.mat-calendar-arrow {
  fill: var(--color-primary);
}

.mat-calendar-table-header-divider::after {
  background: var(--color-line-230);
}

.mat-mdc-icon-button[disabled][disabled] {
  --mdc-icon-button-disabled-icon-color: var(--color-text-400) !important;
}

.mat-form-field.mat-mdc-focused .mat-form-field-ripple,
.mat-form-field.mat-mdc-focused .mat-mdc-form-field-ripple,
.mat-mdc-form-field.mat-mdc-focused .mat-form-field-ripple,
.mat-mdc-form-field.mat-mdc-focused .mat-mdc-form-field-ripple {
  background-color: var(--color-primary);
}

.mat-checkbox > label > .mat-checkbox-label,
.mat-checkbox > label > .mat-mdc-checkbox-label,
.mat-mdc-checkbox > label > .mat-checkbox-label,
.mat-mdc-checkbox > label > .mat-mdc-checkbox-label {
  white-space: normal;
}

.mat-checkbox-background,
.mat-mdc-checkbox-background,
.mat-checkbox-frame,
.mat-mdc-checkbox-frame {
  border-radius: 3px !important;
}

.mat-checkbox-checkmark,
.mat-mdc-checkbox-checkmark {
  fill: transparent !important;
}

.mat-checkbox-checkmark-path,
.mat-mdc-checkbox-checkmark-path {
  stroke: var(--color-text-white) !important;
  stroke-width: 3px !important;
  stroke-linecap: round !important;
  stroke-linejoin: round !important;
}

.mat-form-field.mat-mdc-form-field-invalid .mat-form-field-label,
.mat-form-field.mat-mdc-form-field-invalid .mat-mdc-form-field-label,
.mat-mdc-form-field.mat-mdc-form-field-invalid .mat-form-field-label,
.mat-mdc-form-field.mat-mdc-form-field-invalid .mat-mdc-form-field-label {
  color: var(--color-warn);
}

.mat-form-field .mat-form-field-subscript-wrapper,
.mat-form-field .mat-mdc-form-field-underline,
.mat-form-field .mat-form-field-subscript-wrapper,
.mat-form-field .mat-mdc-form-field-underline,
.mat-mdc-form-field .mat-form-field-subscript-wrapper,
.mat-mdc-form-field .mat-mdc-form-field-underline,
.mat-mdc-form-field .mat-form-field-subscript-wrapper,
.mat-mdc-form-field .mat-mdc-form-field-underline {
  position: initial;
}

.mat-form-field-required-marker::after,
.mat-mdc-form-field-required-marker::after {
  display: none !important;
}

.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
  color: var(--color-text-800);
}

.mat-mdc-text-field-wrapper,
.mat-mdc-form-field-flex,
.mat-mdc-form-field-focus-overlay {
  background-color: transparent !important;
}

.mat-mdc-form-field-error-wrapper,
.mat-mdc-text-field-wrapper {
  padding-left: 0px !important;
}

.mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: unset !important;
  bottom: 0px;
}

.mdc-text-field--filled .mdc-floating-label--float-above {
  transform: translateY(-1.5em) scale(0.75) !important;
}

.mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--color-text-400) !important;
}

.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--color-primary) !important;
}

.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--color-warn) !important;
}

.mat-mdc-form-field-icon-suffix {
  padding-top: 24px !important;
}

.mat-mdc-form-field:not(.mat-form-field-no-animations) .mdc-line-ripple::after,
.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before,
.mdc-text-field--filled:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--color-line-230);
}

.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after,
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--color-warn) !important;
}

.mdc-text-field--filled .mdc-line-ripple::after {
  border-bottom-color: var(--color-primary) !important;
}

.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background .mdc-checkbox__checkmark {
  color: var(--color-text-white);
}

.reach-tooltip div {
  background-color: var(--light-color-text-800);
  color: var(--color-text-white);
}

.spin-loader {
  border-radius: 80%;
  display: block;
  height: 50px;
  width: 50px;
  position: relative;
  animation: spin 0.675s linear 0s infinite normal;
  background: #26a69a;
}
.spin-loader:before, .spin-loader:after {
  content: "";
  display: block;
  position: absolute;
}
.spin-loader:before {
  border-radius: 0 90px 90px 0;
  height: 50px;
  width: 50%;
  top: 0;
  right: 0;
  z-index: 1;
  background: #005041;
  background-image: linear-gradient(#26a69a, #005041);
}
.spin-loader:after {
  border-radius: 80%;
  height: 40px;
  width: 40px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  background: #009688;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
.fl,
.flex {
  display: flex;
}
.fl-inline,
.flex-inline {
  display: inline-flex;
}
.fl[hidden],
.flex[hidden] {
  display: none;
}
.fl-fix, .fl-fixed,
.flex-fix,
.flex-fixed {
  flex: 0 0 auto;
}
.fl-adapt, .fl-flexible, .fl-adaptable,
.flex-adapt,
.flex-flexible,
.flex-adaptable {
  flex: 1 1 auto;
}
.fl-w, .fl-wrap,
.flex-w,
.flex-wrap {
  flex-wrap: wrap;
}
.fl-gap-0,
.flex-gap-0 {
  gap: calc(0 * var(--spacing));
}
.fl-gap-0q,
.flex-gap-0q {
  gap: calc((0 + 0.25) * var(--spacing));
}
.fl-gap-0h,
.flex-gap-0h {
  gap: calc((0 + 0.5) * var(--spacing));
}
.fl-gap-0t,
.flex-gap-0t {
  gap: calc((0 + 0.75) * var(--spacing));
}
.fl-gap-1,
.flex-gap-1 {
  gap: calc(1 * var(--spacing));
}
.fl-gap-1q,
.flex-gap-1q {
  gap: calc((1 + 0.25) * var(--spacing));
}
.fl-gap-1h,
.flex-gap-1h {
  gap: calc((1 + 0.5) * var(--spacing));
}
.fl-gap-1t,
.flex-gap-1t {
  gap: calc((1 + 0.75) * var(--spacing));
}
.fl-gap-2,
.flex-gap-2 {
  gap: calc(2 * var(--spacing));
}
.fl-gap-2q,
.flex-gap-2q {
  gap: calc((2 + 0.25) * var(--spacing));
}
.fl-gap-2h,
.flex-gap-2h {
  gap: calc((2 + 0.5) * var(--spacing));
}
.fl-gap-2t,
.flex-gap-2t {
  gap: calc((2 + 0.75) * var(--spacing));
}
.fl-gap-3,
.flex-gap-3 {
  gap: calc(3 * var(--spacing));
}
.fl-gap-3q,
.flex-gap-3q {
  gap: calc((3 + 0.25) * var(--spacing));
}
.fl-gap-3h,
.flex-gap-3h {
  gap: calc((3 + 0.5) * var(--spacing));
}
.fl-gap-3t,
.flex-gap-3t {
  gap: calc((3 + 0.75) * var(--spacing));
}
.fl-gap-4,
.flex-gap-4 {
  gap: calc(4 * var(--spacing));
}
.fl-gap-4q,
.flex-gap-4q {
  gap: calc((4 + 0.25) * var(--spacing));
}
.fl-gap-4h,
.flex-gap-4h {
  gap: calc((4 + 0.5) * var(--spacing));
}
.fl-gap-4t,
.flex-gap-4t {
  gap: calc((4 + 0.75) * var(--spacing));
}
.fl-gap-5,
.flex-gap-5 {
  gap: calc(5 * var(--spacing));
}
.fl-gap-5q,
.flex-gap-5q {
  gap: calc((5 + 0.25) * var(--spacing));
}
.fl-gap-5h,
.flex-gap-5h {
  gap: calc((5 + 0.5) * var(--spacing));
}
.fl-gap-5t,
.flex-gap-5t {
  gap: calc((5 + 0.75) * var(--spacing));
}
.fl-gap-6,
.flex-gap-6 {
  gap: calc(6 * var(--spacing));
}
.fl-gap-6q,
.flex-gap-6q {
  gap: calc((6 + 0.25) * var(--spacing));
}
.fl-gap-6h,
.flex-gap-6h {
  gap: calc((6 + 0.5) * var(--spacing));
}
.fl-gap-6t,
.flex-gap-6t {
  gap: calc((6 + 0.75) * var(--spacing));
}
.fl-dir-r, .fl-dir-row, .fl-dir-r, .fl-dir-row,
.flex-dir-r,
.flex-dir-row,
.flex-dir-r,
.flex-dir-row {
  flex-direction: row;
}
.fl-dir-r-r, .fl-dir-r-rev, .fl-dir-r-reverse, .fl-dir-row-r, .fl-dir-row-rev, .fl-dir-row-reverse, .fl-dir-r-r, .fl-dir-r-rev, .fl-dir-r-reverse, .fl-dir-row-r, .fl-dir-row-rev, .fl-dir-row-reverse,
.flex-dir-r-r,
.flex-dir-r-rev,
.flex-dir-r-reverse,
.flex-dir-row-r,
.flex-dir-row-rev,
.flex-dir-row-reverse,
.flex-dir-r-r,
.flex-dir-r-rev,
.flex-dir-r-reverse,
.flex-dir-row-r,
.flex-dir-row-rev,
.flex-dir-row-reverse {
  flex-direction: row-reverse;
}
.fl-dir-c, .fl-dir-col, .fl-dir-column, .fl-dir-c, .fl-dir-col, .fl-dir-column,
.flex-dir-c,
.flex-dir-col,
.flex-dir-column,
.flex-dir-c,
.flex-dir-col,
.flex-dir-column {
  flex-direction: column;
}
.fl-dir-c-r, .fl-dir-c-rev, .fl-dir-c-reverse, .fl-dir-col-r, .fl-dir-col-rev, .fl-dir-col-reverse, .fl-dir-column-r, .fl-dir-column-rev, .fl-dir-column-reverse, .fl-dir-c-r, .fl-dir-c-rev, .fl-dir-c-reverse, .fl-dir-col-r, .fl-dir-col-rev, .fl-dir-col-reverse, .fl-dir-column-r, .fl-dir-column-rev, .fl-dir-column-reverse,
.flex-dir-c-r,
.flex-dir-c-rev,
.flex-dir-c-reverse,
.flex-dir-col-r,
.flex-dir-col-rev,
.flex-dir-col-reverse,
.flex-dir-column-r,
.flex-dir-column-rev,
.flex-dir-column-reverse,
.flex-dir-c-r,
.flex-dir-c-rev,
.flex-dir-c-reverse,
.flex-dir-col-r,
.flex-dir-col-rev,
.flex-dir-col-reverse,
.flex-dir-column-r,
.flex-dir-column-rev,
.flex-dir-column-reverse {
  flex-direction: column-reverse;
}
.fl-ai-start, .fl-ai-fs,
.flex-ai-start,
.flex-ai-fs {
  align-items: flex-start;
}
.fl-ai-end, .fl-ai-fe,
.flex-ai-end,
.flex-ai-fe {
  align-items: flex-end;
}
.fl-ai-center, .fl-ai-c,
.flex-ai-center,
.flex-ai-c {
  align-items: center;
}
.fl-ai-stretch, .fl-ai-st,
.flex-ai-stretch,
.flex-ai-st {
  align-items: stretch;
}
.fl-ac-start, .fl-ac-fs,
.flex-ac-start,
.flex-ac-fs {
  align-content: flex-start;
}
.fl-ac-end, .fl-ac-fe,
.flex-ac-end,
.flex-ac-fe {
  align-content: flex-end;
}
.fl-ac-center, .fl-ac-c,
.flex-ac-center,
.flex-ac-c {
  align-content: center;
}
.fl-ac-stretch, .fl-ac-st,
.flex-ac-stretch,
.flex-ac-st {
  align-content: stretch;
}
.fl-aself-start, .fl-aself-fs,
.flex-aself-start,
.flex-aself-fs {
  align-self: flex-start;
}
.fl-aself-end, .fl-aself-fe,
.flex-aself-end,
.flex-aself-fe {
  align-self: flex-end;
}
.fl-aself-center, .fl-aself-c,
.flex-aself-center,
.flex-aself-c {
  align-self: center;
}
.fl-aself-stretch, .fl-aself-st,
.flex-aself-stretch,
.flex-aself-st {
  align-self: stretch;
}
.fl-jc-start, .fl-jc-fs,
.flex-jc-start,
.flex-jc-fs {
  justify-content: flex-start;
}
.fl-jc-end, .fl-jc-fe,
.flex-jc-end,
.flex-jc-fe {
  justify-content: flex-end;
}
.fl-jc-center, .fl-jc-c,
.flex-jc-center,
.flex-jc-c {
  justify-content: center;
}
.fl-jc-stretch, .fl-jc-st,
.flex-jc-stretch,
.flex-jc-st {
  justify-content: stretch;
}
.fl-jc-spa, .fl-jc-around,
.flex-jc-spa,
.flex-jc-around {
  justify-content: space-around;
}
.fl-jc-spb, .fl-jc-between,
.flex-jc-spb,
.flex-jc-between {
  justify-content: space-between;
}
.fl-jc-spe, .fl-jc-even, .fl-jc-evenly,
.flex-jc-spe,
.flex-jc-even,
.flex-jc-evenly {
  justify-content: space-evenly;
}
.fl-jself-start, .fl-jself-fs,
.flex-jself-start,
.flex-jself-fs {
  justify-self: flex-start;
}
.fl-jself-end, .fl-jself-fe,
.flex-jself-end,
.flex-jself-fe {
  justify-self: flex-end;
}
.fl-jself-center, .fl-jself-c,
.flex-jself-center,
.flex-jself-c {
  justify-self: center;
}
.fl-jself-stretch, .fl-jself-st,
.flex-jself-stretch,
.flex-jself-st {
  justify-self: stretch;
}
.fl-jself-spa, .fl-jself-around,
.flex-jself-spa,
.flex-jself-around {
  justify-self: space-around;
}
.fl-jself-spb, .fl-jself-between,
.flex-jself-spb,
.flex-jself-between {
  justify-self: space-between;
}
.fl-jself-spe, .fl-jself-even, .fl-jself-evenly,
.flex-jself-spe,
.flex-jself-even,
.flex-jself-evenly {
  justify-self: space-evenly;
}

:root {
  color-scheme: light dark;
  --z-index-max: 2147483647;
}

html,
body {
  position: fixed;
  width: 100%;
  height: 100%;
  margin: 0;
  overflow: hidden;
  touch-action: none !important;
  -webkit-overflow-scrolling: touch;
}

.uppercase {
  text-transform: uppercase;
}

.dis-b, .dis-block,
.display-b,
.display-block {
  display: block;
}
.dis-n, .dis-none,
.display-n,
.display-none {
  display: none;
}

.block {
  display: block;
}
.block-inline {
  display: inline-block;
}

.inline {
  display: inline-block;
}

.ov-h, .ov-hidden,
.overflow-h,
.overflow-hidden {
  overflow: hidden;
}
.ov-a, .ov-auto,
.overflow-a,
.overflow-auto {
  overflow: auto;
}
.ov-v, .ov-visible,
.overflow-v,
.overflow-visible {
  overflow: visible;
}
.ov-horizontal,
.overflow-horizontal {
  overflow-y: hidden;
  overflow-x: auto;
  scroll-behavior: smooth;
}
.ov-vertical,
.overflow-vertical {
  overflow-y: auto;
  overflow-x: hidden;
}

.scroll-hidden {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none;
}
.scroll-hidden::-webkit-scrollbar {
  display: none;
}

.without-focus,
.wo-focus {
  outline: none;
}

.unclickable {
  pointer-events: none;
}

.z-index-0 {
  z-index: 0;
}
.z-index-1 {
  z-index: 1;
}
.z-index-2 {
  z-index: 2;
}
.z-index-3 {
  z-index: 3;
}
.z-index-4 {
  z-index: 4;
}
.z-index-5 {
  z-index: 5;
}
.z-index-6 {
  z-index: 6;
}
.z-index-7 {
  z-index: 7;
}
.z-index-8 {
  z-index: 8;
}
.z-index-9 {
  z-index: 9;
}
.z-index-10 {
  z-index: 10;
}

.disable-text-selection {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.pointer-events-none,
.pe-none {
  pointer-events: none;
}
.pointer-events-all,
.pe-all {
  pointer-events: all;
}

.popup {
  border-radius: calc(2 * var(--spacing));
  box-shadow: var(--shadow-2);
  border: 1px solid var(--color-line-230);
  box-sizing: border-box;
}

.list-item-separator,
.li-separator {
  position: relative;
}
.list-item-separator::after,
.li-separator::after {
  --space: calc(1rem + 8px + var(--fs-icon) + 8px);
  content: "";
  position: absolute;
  bottom: 0;
  left: var(--space);
  width: calc(100% - var(--space));
  max-width: 100%;
  height: 1px;
  background: var(--color-line-250);
}

.list-item-separator-no-icon,
.li-separator-no-icon {
  position: relative;
}
.list-item-separator-no-icon::after,
.li-separator-no-icon::after {
  --space: calc(2 * var(--spacing));
  content: "";
  position: absolute;
  bottom: 0;
  left: var(--space);
  width: calc(100% - var(--space));
  max-width: 100%;
  height: 1px;
  background: var(--color-line-250);
}

.reach-section-header {
  width: 100%;
  background-color: var(--color-bg-modal-search);
  padding: 24px 16px 12px 16px;
  box-sizing: border-box;
  color: var(--color-text-400);
  font-size: var(--fs-xs);
  font-weight: var(--fw-bold);
  line-height: 1em;
  letter-spacing: 2px;
  text-transform: uppercase;
}
.reach-section-header.new-colors {
  background-color: var(--color-bg-370);
}
.reach-section-header.new-colors-290 {
  background-color: var(--color-bg-290);
}
.reach-section-header.transparent, .reach-section-header.alt-transparent {
  background-color: transparent;
}
.reach-section-header-alt {
  width: 100%;
  background-color: rgb(var(--color-bg-180-rgb));
  padding: 24px 16px 12px 16px;
  box-sizing: border-box;
  color: var(--color-text-400);
  font-size: var(--fs-s);
  font-weight: var(--fw-regular);
  line-height: var(--fs-m);
}

@media screen and (min-width: 720px) {
  .reach-section-header {
    font-size: var(--fs-xxs);
  }
  .reach-section-header.font-size-mobile {
    font-size: var(--fs-xs);
  }
}
mark,
.search-highlight {
  display: inline-block;
  height: 1em;
  color: var(--color-highlight-text);
  font-weight: var(--fw-bold);
  background-color: var(--color-highlight-background);
  border-radius: 4px;
  --__extra-space-x: 2px;
  --__extra-space-y: 0px;
  padding: var(--__extra-space-y) var(--__extra-space-x);
  margin: calc(-1 * var(--__extra-space-y)) calc(-1 * var(--__extra-space-x));
}

/*
	The first name should be `tr`, but since thinking that the
	first might be `tl`, I'm also adding that permutation as
	a safe measure.
*/
:root .pos-rel, :root .pos-relative {
  position: relative;
}
:root .pos-abs, :root .pos-absolute {
  position: absolute;
}
:root .pos-f, :root .pos-fixed {
  position: fixed;
}
:root .pos-s, :root .pos-sticky {
  position: sticky;
}
:root .pos-t0h, :root .pos-t-0h, :root .pos-top0h, :root .pos-top-0h {
  top: var(--spacing);
}
:root .pos-t-50, :root .pos-top-50 {
  top: 50%;
}
:root .pos-t-100, :root .pos-top-100 {
  top: 100%;
}
:root .pos-t-0, :root .pos-t-sp-0, :root .pos-top-0, :root .pos-top-sp-0 {
  top: calc((0 + 0) * var(--spacing));
}
:root .pos-t-0q, :root .pos-t-sp-0q, :root .pos-top-0q, :root .pos-top-sp-0q {
  top: calc((0 + 0.25) * var(--spacing));
}
:root .pos-t-0h, :root .pos-t-sp-0h, :root .pos-top-0h, :root .pos-top-sp-0h {
  top: calc((0 + 0.5) * var(--spacing));
}
:root .pos-t-0t, :root .pos-t-sp-0t, :root .pos-top-0t, :root .pos-top-sp-0t {
  top: calc((0 + 0.75) * var(--spacing));
}
:root .pos-t-1, :root .pos-t-sp-1, :root .pos-top-1, :root .pos-top-sp-1 {
  top: calc((1 + 0) * var(--spacing));
}
:root .pos-t-1q, :root .pos-t-sp-1q, :root .pos-top-1q, :root .pos-top-sp-1q {
  top: calc((1 + 0.25) * var(--spacing));
}
:root .pos-t-1h, :root .pos-t-sp-1h, :root .pos-top-1h, :root .pos-top-sp-1h {
  top: calc((1 + 0.5) * var(--spacing));
}
:root .pos-t-1t, :root .pos-t-sp-1t, :root .pos-top-1t, :root .pos-top-sp-1t {
  top: calc((1 + 0.75) * var(--spacing));
}
:root .pos-t-2, :root .pos-t-sp-2, :root .pos-top-2, :root .pos-top-sp-2 {
  top: calc((2 + 0) * var(--spacing));
}
:root .pos-t-2q, :root .pos-t-sp-2q, :root .pos-top-2q, :root .pos-top-sp-2q {
  top: calc((2 + 0.25) * var(--spacing));
}
:root .pos-t-2h, :root .pos-t-sp-2h, :root .pos-top-2h, :root .pos-top-sp-2h {
  top: calc((2 + 0.5) * var(--spacing));
}
:root .pos-t-2t, :root .pos-t-sp-2t, :root .pos-top-2t, :root .pos-top-sp-2t {
  top: calc((2 + 0.75) * var(--spacing));
}
:root .pos-t-3, :root .pos-t-sp-3, :root .pos-top-3, :root .pos-top-sp-3 {
  top: calc((3 + 0) * var(--spacing));
}
:root .pos-t-3q, :root .pos-t-sp-3q, :root .pos-top-3q, :root .pos-top-sp-3q {
  top: calc((3 + 0.25) * var(--spacing));
}
:root .pos-t-3h, :root .pos-t-sp-3h, :root .pos-top-3h, :root .pos-top-sp-3h {
  top: calc((3 + 0.5) * var(--spacing));
}
:root .pos-t-3t, :root .pos-t-sp-3t, :root .pos-top-3t, :root .pos-top-sp-3t {
  top: calc((3 + 0.75) * var(--spacing));
}
:root .pos-t-4, :root .pos-t-sp-4, :root .pos-top-4, :root .pos-top-sp-4 {
  top: calc((4 + 0) * var(--spacing));
}
:root .pos-t-4q, :root .pos-t-sp-4q, :root .pos-top-4q, :root .pos-top-sp-4q {
  top: calc((4 + 0.25) * var(--spacing));
}
:root .pos-t-4h, :root .pos-t-sp-4h, :root .pos-top-4h, :root .pos-top-sp-4h {
  top: calc((4 + 0.5) * var(--spacing));
}
:root .pos-t-4t, :root .pos-t-sp-4t, :root .pos-top-4t, :root .pos-top-sp-4t {
  top: calc((4 + 0.75) * var(--spacing));
}
:root .pos-r0h, :root .pos-r-0h, :root .pos-right0h, :root .pos-right-0h {
  right: var(--spacing);
}
:root .pos-r-50, :root .pos-right-50 {
  right: 50%;
}
:root .pos-r-100, :root .pos-right-100 {
  right: 100%;
}
:root .pos-r-0, :root .pos-r-sp-0, :root .pos-right-0, :root .pos-right-sp-0 {
  right: calc((0 + 0) * var(--spacing));
}
:root .pos-r-0q, :root .pos-r-sp-0q, :root .pos-right-0q, :root .pos-right-sp-0q {
  right: calc((0 + 0.25) * var(--spacing));
}
:root .pos-r-0h, :root .pos-r-sp-0h, :root .pos-right-0h, :root .pos-right-sp-0h {
  right: calc((0 + 0.5) * var(--spacing));
}
:root .pos-r-0t, :root .pos-r-sp-0t, :root .pos-right-0t, :root .pos-right-sp-0t {
  right: calc((0 + 0.75) * var(--spacing));
}
:root .pos-r-1, :root .pos-r-sp-1, :root .pos-right-1, :root .pos-right-sp-1 {
  right: calc((1 + 0) * var(--spacing));
}
:root .pos-r-1q, :root .pos-r-sp-1q, :root .pos-right-1q, :root .pos-right-sp-1q {
  right: calc((1 + 0.25) * var(--spacing));
}
:root .pos-r-1h, :root .pos-r-sp-1h, :root .pos-right-1h, :root .pos-right-sp-1h {
  right: calc((1 + 0.5) * var(--spacing));
}
:root .pos-r-1t, :root .pos-r-sp-1t, :root .pos-right-1t, :root .pos-right-sp-1t {
  right: calc((1 + 0.75) * var(--spacing));
}
:root .pos-r-2, :root .pos-r-sp-2, :root .pos-right-2, :root .pos-right-sp-2 {
  right: calc((2 + 0) * var(--spacing));
}
:root .pos-r-2q, :root .pos-r-sp-2q, :root .pos-right-2q, :root .pos-right-sp-2q {
  right: calc((2 + 0.25) * var(--spacing));
}
:root .pos-r-2h, :root .pos-r-sp-2h, :root .pos-right-2h, :root .pos-right-sp-2h {
  right: calc((2 + 0.5) * var(--spacing));
}
:root .pos-r-2t, :root .pos-r-sp-2t, :root .pos-right-2t, :root .pos-right-sp-2t {
  right: calc((2 + 0.75) * var(--spacing));
}
:root .pos-r-3, :root .pos-r-sp-3, :root .pos-right-3, :root .pos-right-sp-3 {
  right: calc((3 + 0) * var(--spacing));
}
:root .pos-r-3q, :root .pos-r-sp-3q, :root .pos-right-3q, :root .pos-right-sp-3q {
  right: calc((3 + 0.25) * var(--spacing));
}
:root .pos-r-3h, :root .pos-r-sp-3h, :root .pos-right-3h, :root .pos-right-sp-3h {
  right: calc((3 + 0.5) * var(--spacing));
}
:root .pos-r-3t, :root .pos-r-sp-3t, :root .pos-right-3t, :root .pos-right-sp-3t {
  right: calc((3 + 0.75) * var(--spacing));
}
:root .pos-r-4, :root .pos-r-sp-4, :root .pos-right-4, :root .pos-right-sp-4 {
  right: calc((4 + 0) * var(--spacing));
}
:root .pos-r-4q, :root .pos-r-sp-4q, :root .pos-right-4q, :root .pos-right-sp-4q {
  right: calc((4 + 0.25) * var(--spacing));
}
:root .pos-r-4h, :root .pos-r-sp-4h, :root .pos-right-4h, :root .pos-right-sp-4h {
  right: calc((4 + 0.5) * var(--spacing));
}
:root .pos-r-4t, :root .pos-r-sp-4t, :root .pos-right-4t, :root .pos-right-sp-4t {
  right: calc((4 + 0.75) * var(--spacing));
}
:root .pos-b0h, :root .pos-b-0h, :root .pos-bottom0h, :root .pos-bottom-0h {
  bottom: var(--spacing);
}
:root .pos-b-50, :root .pos-bottom-50 {
  bottom: 50%;
}
:root .pos-b-100, :root .pos-bottom-100 {
  bottom: 100%;
}
:root .pos-b-0, :root .pos-b-sp-0, :root .pos-bottom-0, :root .pos-bottom-sp-0 {
  bottom: calc((0 + 0) * var(--spacing));
}
:root .pos-b-0q, :root .pos-b-sp-0q, :root .pos-bottom-0q, :root .pos-bottom-sp-0q {
  bottom: calc((0 + 0.25) * var(--spacing));
}
:root .pos-b-0h, :root .pos-b-sp-0h, :root .pos-bottom-0h, :root .pos-bottom-sp-0h {
  bottom: calc((0 + 0.5) * var(--spacing));
}
:root .pos-b-0t, :root .pos-b-sp-0t, :root .pos-bottom-0t, :root .pos-bottom-sp-0t {
  bottom: calc((0 + 0.75) * var(--spacing));
}
:root .pos-b-1, :root .pos-b-sp-1, :root .pos-bottom-1, :root .pos-bottom-sp-1 {
  bottom: calc((1 + 0) * var(--spacing));
}
:root .pos-b-1q, :root .pos-b-sp-1q, :root .pos-bottom-1q, :root .pos-bottom-sp-1q {
  bottom: calc((1 + 0.25) * var(--spacing));
}
:root .pos-b-1h, :root .pos-b-sp-1h, :root .pos-bottom-1h, :root .pos-bottom-sp-1h {
  bottom: calc((1 + 0.5) * var(--spacing));
}
:root .pos-b-1t, :root .pos-b-sp-1t, :root .pos-bottom-1t, :root .pos-bottom-sp-1t {
  bottom: calc((1 + 0.75) * var(--spacing));
}
:root .pos-b-2, :root .pos-b-sp-2, :root .pos-bottom-2, :root .pos-bottom-sp-2 {
  bottom: calc((2 + 0) * var(--spacing));
}
:root .pos-b-2q, :root .pos-b-sp-2q, :root .pos-bottom-2q, :root .pos-bottom-sp-2q {
  bottom: calc((2 + 0.25) * var(--spacing));
}
:root .pos-b-2h, :root .pos-b-sp-2h, :root .pos-bottom-2h, :root .pos-bottom-sp-2h {
  bottom: calc((2 + 0.5) * var(--spacing));
}
:root .pos-b-2t, :root .pos-b-sp-2t, :root .pos-bottom-2t, :root .pos-bottom-sp-2t {
  bottom: calc((2 + 0.75) * var(--spacing));
}
:root .pos-b-3, :root .pos-b-sp-3, :root .pos-bottom-3, :root .pos-bottom-sp-3 {
  bottom: calc((3 + 0) * var(--spacing));
}
:root .pos-b-3q, :root .pos-b-sp-3q, :root .pos-bottom-3q, :root .pos-bottom-sp-3q {
  bottom: calc((3 + 0.25) * var(--spacing));
}
:root .pos-b-3h, :root .pos-b-sp-3h, :root .pos-bottom-3h, :root .pos-bottom-sp-3h {
  bottom: calc((3 + 0.5) * var(--spacing));
}
:root .pos-b-3t, :root .pos-b-sp-3t, :root .pos-bottom-3t, :root .pos-bottom-sp-3t {
  bottom: calc((3 + 0.75) * var(--spacing));
}
:root .pos-b-4, :root .pos-b-sp-4, :root .pos-bottom-4, :root .pos-bottom-sp-4 {
  bottom: calc((4 + 0) * var(--spacing));
}
:root .pos-b-4q, :root .pos-b-sp-4q, :root .pos-bottom-4q, :root .pos-bottom-sp-4q {
  bottom: calc((4 + 0.25) * var(--spacing));
}
:root .pos-b-4h, :root .pos-b-sp-4h, :root .pos-bottom-4h, :root .pos-bottom-sp-4h {
  bottom: calc((4 + 0.5) * var(--spacing));
}
:root .pos-b-4t, :root .pos-b-sp-4t, :root .pos-bottom-4t, :root .pos-bottom-sp-4t {
  bottom: calc((4 + 0.75) * var(--spacing));
}
:root .pos-l0h, :root .pos-l-0h, :root .pos-left0h, :root .pos-left-0h {
  left: var(--spacing);
}
:root .pos-l-50, :root .pos-left-50 {
  left: 50%;
}
:root .pos-l-100, :root .pos-left-100 {
  left: 100%;
}
:root .pos-l-0, :root .pos-l-sp-0, :root .pos-left-0, :root .pos-left-sp-0 {
  left: calc((0 + 0) * var(--spacing));
}
:root .pos-l-0q, :root .pos-l-sp-0q, :root .pos-left-0q, :root .pos-left-sp-0q {
  left: calc((0 + 0.25) * var(--spacing));
}
:root .pos-l-0h, :root .pos-l-sp-0h, :root .pos-left-0h, :root .pos-left-sp-0h {
  left: calc((0 + 0.5) * var(--spacing));
}
:root .pos-l-0t, :root .pos-l-sp-0t, :root .pos-left-0t, :root .pos-left-sp-0t {
  left: calc((0 + 0.75) * var(--spacing));
}
:root .pos-l-1, :root .pos-l-sp-1, :root .pos-left-1, :root .pos-left-sp-1 {
  left: calc((1 + 0) * var(--spacing));
}
:root .pos-l-1q, :root .pos-l-sp-1q, :root .pos-left-1q, :root .pos-left-sp-1q {
  left: calc((1 + 0.25) * var(--spacing));
}
:root .pos-l-1h, :root .pos-l-sp-1h, :root .pos-left-1h, :root .pos-left-sp-1h {
  left: calc((1 + 0.5) * var(--spacing));
}
:root .pos-l-1t, :root .pos-l-sp-1t, :root .pos-left-1t, :root .pos-left-sp-1t {
  left: calc((1 + 0.75) * var(--spacing));
}
:root .pos-l-2, :root .pos-l-sp-2, :root .pos-left-2, :root .pos-left-sp-2 {
  left: calc((2 + 0) * var(--spacing));
}
:root .pos-l-2q, :root .pos-l-sp-2q, :root .pos-left-2q, :root .pos-left-sp-2q {
  left: calc((2 + 0.25) * var(--spacing));
}
:root .pos-l-2h, :root .pos-l-sp-2h, :root .pos-left-2h, :root .pos-left-sp-2h {
  left: calc((2 + 0.5) * var(--spacing));
}
:root .pos-l-2t, :root .pos-l-sp-2t, :root .pos-left-2t, :root .pos-left-sp-2t {
  left: calc((2 + 0.75) * var(--spacing));
}
:root .pos-l-3, :root .pos-l-sp-3, :root .pos-left-3, :root .pos-left-sp-3 {
  left: calc((3 + 0) * var(--spacing));
}
:root .pos-l-3q, :root .pos-l-sp-3q, :root .pos-left-3q, :root .pos-left-sp-3q {
  left: calc((3 + 0.25) * var(--spacing));
}
:root .pos-l-3h, :root .pos-l-sp-3h, :root .pos-left-3h, :root .pos-left-sp-3h {
  left: calc((3 + 0.5) * var(--spacing));
}
:root .pos-l-3t, :root .pos-l-sp-3t, :root .pos-left-3t, :root .pos-left-sp-3t {
  left: calc((3 + 0.75) * var(--spacing));
}
:root .pos-l-4, :root .pos-l-sp-4, :root .pos-left-4, :root .pos-left-sp-4 {
  left: calc((4 + 0) * var(--spacing));
}
:root .pos-l-4q, :root .pos-l-sp-4q, :root .pos-left-4q, :root .pos-left-sp-4q {
  left: calc((4 + 0.25) * var(--spacing));
}
:root .pos-l-4h, :root .pos-l-sp-4h, :root .pos-left-4h, :root .pos-left-sp-4h {
  left: calc((4 + 0.5) * var(--spacing));
}
:root .pos-l-4t, :root .pos-l-sp-4t, :root .pos-left-4t, :root .pos-left-sp-4t {
  left: calc((4 + 0.75) * var(--spacing));
}

*,
*::before,
*::after {
  box-sizing: border-box; /* Set the sizing of an element to include it's border */
}

* {
  margin: 0; /* Set the default margin to 0 */
  padding: 0; /* Set the default padding to 0 */
  word-break: break-all;
  word-break: break-word;
}

html:focus-within {
  scroll-behavior: smooth; /* Make the scrolling inside of any scrollable element smooth */
}

a:not([class]) {
  text-decoration-skip-ink: auto; /* Makes link undelines look better */
}

/* Turns off animation for people who don't want to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
    transition: none;
  }
}
.card-separator {
  --__gap-size: var(--__gap, calc((2 * var(--spacing)) + var(--fs-icon) + (2 * var(--spacing))));
  margin-left: var(--__gap-size);
  width: calc(100% - var(--__gap-size));
  height: 1px;
  background-color: var(--color-line-230);
}
.card-separator.new-colors {
  background-color: var(--color-line-230);
}
.card-separator-no-icon {
  width: calc(100% - 1rem);
  margin-left: 1rem;
}
.card-separator.force {
  background-color: var(--color-line-230) !important;
}

.reach-card:last-of-type .card-separator {
  display: none;
  background-color: transparent;
}

.reach-card-new:last-of-type > .card-separator {
  display: none;
  background-color: transparent;
}

.separator {
  width: 100%;
  height: 1px;
  background-color: var(--color-line-230);
}

.settings-fixed-header {
  position: absolute;
  z-index: 1;
  top: var(--safe-area-inset-top);
}

.settings-scroll {
  position: absolute;
  top: calc(2rem + var(--fs-icon) + var(--safe-area-inset-top));
  bottom: var(--safe-area-inset-bottom);
  max-height: calc(100% - 2rem - var(--fs-icon));
  overflow: auto;
  width: 100%;
}

:root {
  font-size: 16px;
  --font-line-height: 1.2;
  line-height: var(--font-line-height);
  --fs-h1: calc((24 / 16) * 1rem);
  --font-size-h1: calc((24 / 16) * 1rem);
  --fs-xxl: calc((22 / 16) * 1rem);
  --font-size-xxl: calc((22 / 16) * 1rem);
  --fs-xl: calc((20 / 16) * 1rem);
  --font-size-xl: calc((20 / 16) * 1rem);
  --fs-l: calc((18 / 16) * 1rem);
  --font-size-l: calc((18 / 16) * 1rem);
  --fs-m: calc((16 / 16) * 1rem);
  --font-size-m: calc((16 / 16) * 1rem);
  --fs-s: calc((14 / 16) * 1rem);
  --font-size-s: calc((14 / 16) * 1rem);
  --fs-xs: calc((12 / 16) * 1rem);
  --font-size-xs: calc((12 / 16) * 1rem);
  --fs-xxs: calc((10 / 16) * 1rem);
  --font-size-xxs: calc((10 / 16) * 1rem);
  --fs-icon-s: calc((16 / 16) * 1rem);
  --font-size-icon-s: calc((16 / 16) * 1rem);
  --fs-icon-small: calc((16 / 16) * 1rem);
  --font-size-icon-small: calc((16 / 16) * 1rem);
  --fs-icon: calc((24 / 16) * 1rem);
  --font-size-icon: calc((24 / 16) * 1rem);
  --fs-icon-l: calc((28 / 16) * 1rem);
  --font-size-icon-l: calc((28 / 16) * 1rem);
  --fs-icon-2x: calc((48 / 16) * 1rem);
  --font-size-icon-2x: calc((48 / 16) * 1rem);
}
:root.screen-desktop {
  font-size: 14px;
  --fs-h1: calc((24 / 14) * 1rem);
  --font-size-h1: calc((24 / 14) * 1rem);
  --fs-xxl: calc((20 / 14) * 1rem);
  --font-size-xxl: calc((20 / 14) * 1rem);
  --fs-xl: calc((18 / 14) * 1rem);
  --font-size-xl: calc((18 / 14) * 1rem);
  --fs-l: calc((16 / 14) * 1rem);
  --font-size-l: calc((16 / 14) * 1rem);
  --fs-m: calc((14 / 14) * 1rem);
  --font-size-m: calc((14 / 14) * 1rem);
  --fs-s: calc((12 / 14) * 1rem);
  --font-size-s: calc((12 / 14) * 1rem);
  --fs-xs: calc((12 / 14) * 1rem);
  --font-size-xs: calc((12 / 14) * 1rem);
  --fs-xxs: calc((10 / 14) * 1rem);
  --font-size-xxs: calc((10 / 14) * 1rem);
  --fs-icon-s: calc((16 / 14) * 1rem);
  --font-size-icon-s: calc((16 / 14) * 1rem);
  --fs-icon-small: calc((16 / 14) * 1rem);
  --font-size-icon-small: calc((16 / 14) * 1rem);
  --fs-icon: calc((22 / 14) * 1rem);
  --font-size-icon: calc((22 / 14) * 1rem);
  --fs-icon-l: calc((24 / 14) * 1rem);
  --font-size-icon-l: calc((24 / 14) * 1rem);
  --fs-icon-2x: calc((44 / 14) * 1rem);
  --font-size-icon-2x: calc((44 / 14) * 1rem);
}
:root.screen-desktop [class^=icon-].fs-m,
:root.screen-desktop [class*=" icon-"].fs-m,
:root.screen-desktop [class^=iconff-].fs-m,
:root.screen-desktop [class*=" iconff-"].fs-m,
:root.screen-desktop [class^=ns-icon-].fs-m,
:root.screen-desktop [class*=" ns-icon-"].fs-m {
  font-size: var(--fs-l);
}

body {
  font-size: var(--fs-m);
}

[class^=icon-],
[class*=" icon-"],
[class^=iconff-],
[class*=" iconff-"],
[class^=ns-icon-],
[class*=" ns-icon-"] {
  font-size: var(--fs-icon);
}
[class^=icon-].fs-h1,
[class*=" icon-"].fs-h1,
[class^=iconff-].fs-h1,
[class*=" iconff-"].fs-h1,
[class^=ns-icon-].fs-h1,
[class*=" ns-icon-"].fs-h1 {
  font-size: var(--fs-h1);
}
[class^=icon-].fs-xxl,
[class*=" icon-"].fs-xxl,
[class^=iconff-].fs-xxl,
[class*=" iconff-"].fs-xxl,
[class^=ns-icon-].fs-xxl,
[class*=" ns-icon-"].fs-xxl {
  font-size: var(--fs-xxl);
}
[class^=icon-].fs-xl,
[class*=" icon-"].fs-xl,
[class^=iconff-].fs-xl,
[class*=" iconff-"].fs-xl,
[class^=ns-icon-].fs-xl,
[class*=" ns-icon-"].fs-xl {
  font-size: var(--fs-xl);
}
[class^=icon-].fs-l,
[class*=" icon-"].fs-l,
[class^=iconff-].fs-l,
[class*=" iconff-"].fs-l,
[class^=ns-icon-].fs-l,
[class*=" ns-icon-"].fs-l {
  font-size: var(--fs-l);
}
[class^=icon-].fs-m,
[class*=" icon-"].fs-m,
[class^=iconff-].fs-m,
[class*=" iconff-"].fs-m,
[class^=ns-icon-].fs-m,
[class*=" ns-icon-"].fs-m {
  font-size: var(--fs-m);
}
[class^=icon-].fs-s,
[class*=" icon-"].fs-s,
[class^=iconff-].fs-s,
[class*=" iconff-"].fs-s,
[class^=ns-icon-].fs-s,
[class*=" ns-icon-"].fs-s {
  font-size: var(--fs-s);
}
[class^=icon-].fs-xs,
[class*=" icon-"].fs-xs,
[class^=iconff-].fs-xs,
[class*=" iconff-"].fs-xs,
[class^=ns-icon-].fs-xs,
[class*=" ns-icon-"].fs-xs {
  font-size: var(--fs-xs);
}
[class^=icon-].fs-xxs,
[class*=" icon-"].fs-xxs,
[class^=iconff-].fs-xxs,
[class*=" iconff-"].fs-xxs,
[class^=ns-icon-].fs-xxs,
[class*=" ns-icon-"].fs-xxs {
  font-size: var(--fs-xxs);
}
[class^=icon-].fs-icon-s,
[class*=" icon-"].fs-icon-s,
[class^=iconff-].fs-icon-s,
[class*=" iconff-"].fs-icon-s,
[class^=ns-icon-].fs-icon-s,
[class*=" ns-icon-"].fs-icon-s {
  font-size: var(--fs-icon-s);
}
[class^=icon-].fs-icon-small,
[class*=" icon-"].fs-icon-small,
[class^=iconff-].fs-icon-small,
[class*=" iconff-"].fs-icon-small,
[class^=ns-icon-].fs-icon-small,
[class*=" ns-icon-"].fs-icon-small {
  font-size: var(--fs-icon-small);
}
[class^=icon-].fs-icon,
[class*=" icon-"].fs-icon,
[class^=iconff-].fs-icon,
[class*=" iconff-"].fs-icon,
[class^=ns-icon-].fs-icon,
[class*=" ns-icon-"].fs-icon {
  font-size: var(--fs-icon);
}
[class^=icon-].fs-icon-l,
[class*=" icon-"].fs-icon-l,
[class^=iconff-].fs-icon-l,
[class*=" iconff-"].fs-icon-l,
[class^=ns-icon-].fs-icon-l,
[class*=" ns-icon-"].fs-icon-l {
  font-size: var(--fs-icon-l);
}
[class^=icon-].fs-icon-2x,
[class*=" icon-"].fs-icon-2x,
[class^=iconff-].fs-icon-2x,
[class*=" iconff-"].fs-icon-2x,
[class^=ns-icon-].fs-icon-2x,
[class*=" ns-icon-"].fs-icon-2x {
  font-size: var(--fs-icon-2x);
}
[class^=icon-].fs-icon-2x,
[class*=" icon-"].fs-icon-2x,
[class^=iconff-].fs-icon-2x,
[class*=" iconff-"].fs-icon-2x,
[class^=ns-icon-].fs-icon-2x,
[class*=" ns-icon-"].fs-icon-2x {
  font-size: calc(2 * var(--fs-icon));
}
[class^=icon-].fs-icon-l,
[class*=" icon-"].fs-icon-l,
[class^=iconff-].fs-icon-l,
[class*=" iconff-"].fs-icon-l,
[class^=ns-icon-].fs-icon-l,
[class*=" ns-icon-"].fs-icon-l {
  font-size: var(--fs-icon-l);
}
[class^=icon-].fs-em,
[class*=" icon-"].fs-em,
[class^=iconff-].fs-em,
[class*=" iconff-"].fs-em,
[class^=ns-icon-].fs-em,
[class*=" ns-icon-"].fs-em {
  font-size: 1em;
}

.fs-h1 {
  font-size: var(--fs-h1);
}
.fs-xxl {
  font-size: var(--fs-xxl);
}
.fs-xl {
  font-size: var(--fs-xl);
}
.fs-l {
  font-size: var(--fs-l);
}
.fs-m {
  font-size: var(--fs-m);
}
.fs-s {
  font-size: var(--fs-s);
}
.fs-xs {
  font-size: var(--fs-xs);
}
.fs-xxs {
  font-size: var(--fs-xxs);
}
.fs-icon-s {
  font-size: var(--fs-icon-s);
}
.fs-icon-small {
  font-size: var(--fs-icon-small);
}
.fs-icon {
  font-size: var(--fs-icon);
}
.fs-icon-l {
  font-size: var(--fs-icon-l);
}
.fs-icon-2x {
  font-size: var(--fs-icon-2x);
}
.fs-sp-0 {
  font-size: calc((0 + 0) * var(--spacing));
}
.fs-sp-0q {
  font-size: calc((0.25 + 0) * var(--spacing));
}
.fs-sp-0h {
  font-size: calc((0.5 + 0) * var(--spacing));
}
.fs-sp-0t {
  font-size: calc((0.75 + 0) * var(--spacing));
}
.fs-sp-1 {
  font-size: calc((0 + 1) * var(--spacing));
}
.fs-sp-1q {
  font-size: calc((0.25 + 1) * var(--spacing));
}
.fs-sp-1h {
  font-size: calc((0.5 + 1) * var(--spacing));
}
.fs-sp-1t {
  font-size: calc((0.75 + 1) * var(--spacing));
}
.fs-sp-2 {
  font-size: calc((0 + 2) * var(--spacing));
}
.fs-sp-2q {
  font-size: calc((0.25 + 2) * var(--spacing));
}
.fs-sp-2h {
  font-size: calc((0.5 + 2) * var(--spacing));
}
.fs-sp-2t {
  font-size: calc((0.75 + 2) * var(--spacing));
}
.fs-sp-3 {
  font-size: calc((0 + 3) * var(--spacing));
}
.fs-sp-3q {
  font-size: calc((0.25 + 3) * var(--spacing));
}
.fs-sp-3h {
  font-size: calc((0.5 + 3) * var(--spacing));
}
.fs-sp-3t {
  font-size: calc((0.75 + 3) * var(--spacing));
}
.fs-sp-4 {
  font-size: calc((0 + 4) * var(--spacing));
}
.fs-sp-4q {
  font-size: calc((0.25 + 4) * var(--spacing));
}
.fs-sp-4h {
  font-size: calc((0.5 + 4) * var(--spacing));
}
.fs-sp-4t {
  font-size: calc((0.75 + 4) * var(--spacing));
}
.fs-sp-5 {
  font-size: calc((0 + 5) * var(--spacing));
}
.fs-sp-5q {
  font-size: calc((0.25 + 5) * var(--spacing));
}
.fs-sp-5h {
  font-size: calc((0.5 + 5) * var(--spacing));
}
.fs-sp-5t {
  font-size: calc((0.75 + 5) * var(--spacing));
}
.fs-sp-6 {
  font-size: calc((0 + 6) * var(--spacing));
}
.fs-sp-6q {
  font-size: calc((0.25 + 6) * var(--spacing));
}
.fs-sp-6h {
  font-size: calc((0.5 + 6) * var(--spacing));
}
.fs-sp-6t {
  font-size: calc((0.75 + 6) * var(--spacing));
}
.fs-sp-7 {
  font-size: calc((0 + 7) * var(--spacing));
}
.fs-sp-7q {
  font-size: calc((0.25 + 7) * var(--spacing));
}
.fs-sp-7h {
  font-size: calc((0.5 + 7) * var(--spacing));
}
.fs-sp-7t {
  font-size: calc((0.75 + 7) * var(--spacing));
}
.fs-sp-8 {
  font-size: calc((0 + 8) * var(--spacing));
}
.fs-sp-8q {
  font-size: calc((0.25 + 8) * var(--spacing));
}
.fs-sp-8h {
  font-size: calc((0.5 + 8) * var(--spacing));
}
.fs-sp-8t {
  font-size: calc((0.75 + 8) * var(--spacing));
}
.fs-sp-9 {
  font-size: calc((0 + 9) * var(--spacing));
}
.fs-sp-9q {
  font-size: calc((0.25 + 9) * var(--spacing));
}
.fs-sp-9h {
  font-size: calc((0.5 + 9) * var(--spacing));
}
.fs-sp-9t {
  font-size: calc((0.75 + 9) * var(--spacing));
}
.fs-sp-10 {
  font-size: calc((0 + 10) * var(--spacing));
}
.fs-sp-10q {
  font-size: calc((0.25 + 10) * var(--spacing));
}
.fs-sp-10h {
  font-size: calc((0.5 + 10) * var(--spacing));
}
.fs-sp-10t {
  font-size: calc((0.75 + 10) * var(--spacing));
}
.fs-em {
  font-size: 1em;
}

.ws-nw, .ws-nowrap, .ws-no-wrap,
.white-space-nw,
.white-space-nowrap,
.white-space-no-wrap {
  white-space: nowrap;
}
.ws-pl, .ws-pre-line,
.white-space-pl,
.white-space-pre-line {
  white-space: pre-line;
}

.wb-bw, .wb-break-word,
.word-break-bw,
.word-break-break-word {
  word-break: break-word;
}
.wbba, .wb-break-all,
.word-breakba,
.word-break-break-all {
  word-break: break-all;
}

.talign-start {
  text-align: start;
}
.talign-end {
  text-align: end;
}
.talign-l, .talign-left {
  text-align: left;
}
.talign-c, .talign-center {
  text-align: center;
}
.talign-r, .talign-right {
  text-align: right;
}

.l-h-regular,
.lh-regular,
.line-height-regular {
  line-height: var(--font-line-height);
}
.l-h-1, .l-h-em,
.lh-1,
.lh-em,
.line-height-1,
.line-height-em {
  line-height: 1;
}
.l-h-fs-h1,
.lh-fs-h1,
.line-height-fs-h1 {
  line-height: var(--fs-h1);
}
.l-h-fs-xxl,
.lh-fs-xxl,
.line-height-fs-xxl {
  line-height: var(--fs-xxl);
}
.l-h-fs-xl,
.lh-fs-xl,
.line-height-fs-xl {
  line-height: var(--fs-xl);
}
.l-h-fs-l,
.lh-fs-l,
.line-height-fs-l {
  line-height: var(--fs-l);
}
.l-h-fs-m,
.lh-fs-m,
.line-height-fs-m {
  line-height: var(--fs-m);
}
.l-h-fs-s,
.lh-fs-s,
.line-height-fs-s {
  line-height: var(--fs-s);
}
.l-h-fs-xs,
.lh-fs-xs,
.line-height-fs-xs {
  line-height: var(--fs-xs);
}
.l-h-fs-xxs,
.lh-fs-xxs,
.line-height-fs-xxs {
  line-height: var(--fs-xxs);
}
.l-h-fs-icon-s,
.lh-fs-icon-s,
.line-height-fs-icon-s {
  line-height: var(--fs-icon-s);
}
.l-h-fs-icon-small,
.lh-fs-icon-small,
.line-height-fs-icon-small {
  line-height: var(--fs-icon-small);
}
.l-h-fs-icon,
.lh-fs-icon,
.line-height-fs-icon {
  line-height: var(--fs-icon);
}
.l-h-fs-icon-l,
.lh-fs-icon-l,
.line-height-fs-icon-l {
  line-height: var(--fs-icon-l);
}
.l-h-fs-icon-2x,
.lh-fs-icon-2x,
.line-height-fs-icon-2x {
  line-height: var(--fs-icon-2x);
}

:root {
  --size-menu-xs: 200px;
  --size-menu-s: 320px;
  --size-content-s: 720px;
  --size-content: 800px;
  --size-content-l: 920px;
}

.min-w-0,
.min-width-0 {
  min-width: 0px;
}
.min-w-10,
.min-width-10 {
  min-width: calc(1 * 10%);
}
.min-w-20,
.min-width-20 {
  min-width: calc(2 * 10%);
}
.min-w-30,
.min-width-30 {
  min-width: calc(3 * 10%);
}
.min-w-40,
.min-width-40 {
  min-width: calc(4 * 10%);
}
.min-w-50,
.min-width-50 {
  min-width: calc(5 * 10%);
}
.min-w-60,
.min-width-60 {
  min-width: calc(6 * 10%);
}
.min-w-70,
.min-width-70 {
  min-width: calc(7 * 10%);
}
.min-w-80,
.min-width-80 {
  min-width: calc(8 * 10%);
}
.min-w-90,
.min-width-90 {
  min-width: calc(9 * 10%);
}
.min-w-100,
.min-width-100 {
  min-width: calc(10 * 10%);
}
.min-w-sp-0,
.min-width-sp-0 {
  min-width: calc((0 + 0) * var(--spacing));
}
.min-w-sp-0q,
.min-width-sp-0q {
  min-width: calc((0.25 + 0) * var(--spacing));
}
.min-w-sp-0h,
.min-width-sp-0h {
  min-width: calc((0.5 + 0) * var(--spacing));
}
.min-w-sp-0t,
.min-width-sp-0t {
  min-width: calc((0.75 + 0) * var(--spacing));
}
.min-w-sp-1,
.min-width-sp-1 {
  min-width: calc((0 + 1) * var(--spacing));
}
.min-w-sp-1q,
.min-width-sp-1q {
  min-width: calc((0.25 + 1) * var(--spacing));
}
.min-w-sp-1h,
.min-width-sp-1h {
  min-width: calc((0.5 + 1) * var(--spacing));
}
.min-w-sp-1t,
.min-width-sp-1t {
  min-width: calc((0.75 + 1) * var(--spacing));
}
.min-w-sp-2,
.min-width-sp-2 {
  min-width: calc((0 + 2) * var(--spacing));
}
.min-w-sp-2q,
.min-width-sp-2q {
  min-width: calc((0.25 + 2) * var(--spacing));
}
.min-w-sp-2h,
.min-width-sp-2h {
  min-width: calc((0.5 + 2) * var(--spacing));
}
.min-w-sp-2t,
.min-width-sp-2t {
  min-width: calc((0.75 + 2) * var(--spacing));
}
.min-w-sp-3,
.min-width-sp-3 {
  min-width: calc((0 + 3) * var(--spacing));
}
.min-w-sp-3q,
.min-width-sp-3q {
  min-width: calc((0.25 + 3) * var(--spacing));
}
.min-w-sp-3h,
.min-width-sp-3h {
  min-width: calc((0.5 + 3) * var(--spacing));
}
.min-w-sp-3t,
.min-width-sp-3t {
  min-width: calc((0.75 + 3) * var(--spacing));
}
.min-w-sp-4,
.min-width-sp-4 {
  min-width: calc((0 + 4) * var(--spacing));
}
.min-w-sp-4q,
.min-width-sp-4q {
  min-width: calc((0.25 + 4) * var(--spacing));
}
.min-w-sp-4h,
.min-width-sp-4h {
  min-width: calc((0.5 + 4) * var(--spacing));
}
.min-w-sp-4t,
.min-width-sp-4t {
  min-width: calc((0.75 + 4) * var(--spacing));
}
.min-w-sp-5,
.min-width-sp-5 {
  min-width: calc((0 + 5) * var(--spacing));
}
.min-w-sp-5q,
.min-width-sp-5q {
  min-width: calc((0.25 + 5) * var(--spacing));
}
.min-w-sp-5h,
.min-width-sp-5h {
  min-width: calc((0.5 + 5) * var(--spacing));
}
.min-w-sp-5t,
.min-width-sp-5t {
  min-width: calc((0.75 + 5) * var(--spacing));
}
.min-w-sp-6,
.min-width-sp-6 {
  min-width: calc((0 + 6) * var(--spacing));
}
.min-w-sp-6q,
.min-width-sp-6q {
  min-width: calc((0.25 + 6) * var(--spacing));
}
.min-w-sp-6h,
.min-width-sp-6h {
  min-width: calc((0.5 + 6) * var(--spacing));
}
.min-w-sp-6t,
.min-width-sp-6t {
  min-width: calc((0.75 + 6) * var(--spacing));
}
.min-w-sp-7,
.min-width-sp-7 {
  min-width: calc((0 + 7) * var(--spacing));
}
.min-w-sp-7q,
.min-width-sp-7q {
  min-width: calc((0.25 + 7) * var(--spacing));
}
.min-w-sp-7h,
.min-width-sp-7h {
  min-width: calc((0.5 + 7) * var(--spacing));
}
.min-w-sp-7t,
.min-width-sp-7t {
  min-width: calc((0.75 + 7) * var(--spacing));
}
.min-w-sp-8,
.min-width-sp-8 {
  min-width: calc((0 + 8) * var(--spacing));
}
.min-w-sp-8q,
.min-width-sp-8q {
  min-width: calc((0.25 + 8) * var(--spacing));
}
.min-w-sp-8h,
.min-width-sp-8h {
  min-width: calc((0.5 + 8) * var(--spacing));
}
.min-w-sp-8t,
.min-width-sp-8t {
  min-width: calc((0.75 + 8) * var(--spacing));
}
.min-w-sp-9,
.min-width-sp-9 {
  min-width: calc((0 + 9) * var(--spacing));
}
.min-w-sp-9q,
.min-width-sp-9q {
  min-width: calc((0.25 + 9) * var(--spacing));
}
.min-w-sp-9h,
.min-width-sp-9h {
  min-width: calc((0.5 + 9) * var(--spacing));
}
.min-w-sp-9t,
.min-width-sp-9t {
  min-width: calc((0.75 + 9) * var(--spacing));
}
.min-w-sp-10,
.min-width-sp-10 {
  min-width: calc((0 + 10) * var(--spacing));
}
.min-w-sp-10q,
.min-width-sp-10q {
  min-width: calc((0.25 + 10) * var(--spacing));
}
.min-w-sp-10h,
.min-width-sp-10h {
  min-width: calc((0.5 + 10) * var(--spacing));
}
.min-w-sp-10t,
.min-width-sp-10t {
  min-width: calc((0.75 + 10) * var(--spacing));
}
.min-w-px-1,
.min-width-px-1 {
  min-width: 1px;
}
.min-w-fs-h1,
.min-width-fs-h1 {
  min-width: var(--fs-h1);
}
.min-w-fs-xxl,
.min-width-fs-xxl {
  min-width: var(--fs-xxl);
}
.min-w-fs-xl,
.min-width-fs-xl {
  min-width: var(--fs-xl);
}
.min-w-fs-l,
.min-width-fs-l {
  min-width: var(--fs-l);
}
.min-w-fs-m,
.min-width-fs-m {
  min-width: var(--fs-m);
}
.min-w-fs-s,
.min-width-fs-s {
  min-width: var(--fs-s);
}
.min-w-fs-xs,
.min-width-fs-xs {
  min-width: var(--fs-xs);
}
.min-w-fs-xxs,
.min-width-fs-xxs {
  min-width: var(--fs-xxs);
}
.min-w-fs-icon-s,
.min-width-fs-icon-s {
  min-width: var(--fs-icon-s);
}
.min-w-fs-icon-small,
.min-width-fs-icon-small {
  min-width: var(--fs-icon-small);
}
.min-w-fs-icon,
.min-width-fs-icon {
  min-width: var(--fs-icon);
}
.min-w-fs-icon-l,
.min-width-fs-icon-l {
  min-width: var(--fs-icon-l);
}
.min-w-fs-icon-2x,
.min-width-fs-icon-2x {
  min-width: var(--fs-icon-2x);
}
.min-w-menu-xs,
.min-width-menu-xs {
  min-width: 200px;
}
.min-w-menu-s,
.min-width-menu-s {
  min-width: 320px;
}
.min-w-content-s,
.min-width-content-s {
  min-width: 720px;
}
.min-w-content,
.min-width-content {
  min-width: 800px;
}
.min-w-content-l,
.min-width-content-l {
  min-width: 920px;
}

.w-0,
.width-0 {
  width: 0px;
}
.w-10,
.width-10 {
  width: calc(1 * 10%);
}
.w-20,
.width-20 {
  width: calc(2 * 10%);
}
.w-30,
.width-30 {
  width: calc(3 * 10%);
}
.w-40,
.width-40 {
  width: calc(4 * 10%);
}
.w-50,
.width-50 {
  width: calc(5 * 10%);
}
.w-60,
.width-60 {
  width: calc(6 * 10%);
}
.w-70,
.width-70 {
  width: calc(7 * 10%);
}
.w-80,
.width-80 {
  width: calc(8 * 10%);
}
.w-90,
.width-90 {
  width: calc(9 * 10%);
}
.w-100,
.width-100 {
  width: calc(10 * 10%);
}
.w-sp-0,
.width-sp-0 {
  width: calc((0 + 0) * var(--spacing));
}
.w-sp-0q,
.width-sp-0q {
  width: calc((0.25 + 0) * var(--spacing));
}
.w-sp-0h,
.width-sp-0h {
  width: calc((0.5 + 0) * var(--spacing));
}
.w-sp-0t,
.width-sp-0t {
  width: calc((0.75 + 0) * var(--spacing));
}
.w-sp-1,
.width-sp-1 {
  width: calc((0 + 1) * var(--spacing));
}
.w-sp-1q,
.width-sp-1q {
  width: calc((0.25 + 1) * var(--spacing));
}
.w-sp-1h,
.width-sp-1h {
  width: calc((0.5 + 1) * var(--spacing));
}
.w-sp-1t,
.width-sp-1t {
  width: calc((0.75 + 1) * var(--spacing));
}
.w-sp-2,
.width-sp-2 {
  width: calc((0 + 2) * var(--spacing));
}
.w-sp-2q,
.width-sp-2q {
  width: calc((0.25 + 2) * var(--spacing));
}
.w-sp-2h,
.width-sp-2h {
  width: calc((0.5 + 2) * var(--spacing));
}
.w-sp-2t,
.width-sp-2t {
  width: calc((0.75 + 2) * var(--spacing));
}
.w-sp-3,
.width-sp-3 {
  width: calc((0 + 3) * var(--spacing));
}
.w-sp-3q,
.width-sp-3q {
  width: calc((0.25 + 3) * var(--spacing));
}
.w-sp-3h,
.width-sp-3h {
  width: calc((0.5 + 3) * var(--spacing));
}
.w-sp-3t,
.width-sp-3t {
  width: calc((0.75 + 3) * var(--spacing));
}
.w-sp-4,
.width-sp-4 {
  width: calc((0 + 4) * var(--spacing));
}
.w-sp-4q,
.width-sp-4q {
  width: calc((0.25 + 4) * var(--spacing));
}
.w-sp-4h,
.width-sp-4h {
  width: calc((0.5 + 4) * var(--spacing));
}
.w-sp-4t,
.width-sp-4t {
  width: calc((0.75 + 4) * var(--spacing));
}
.w-sp-5,
.width-sp-5 {
  width: calc((0 + 5) * var(--spacing));
}
.w-sp-5q,
.width-sp-5q {
  width: calc((0.25 + 5) * var(--spacing));
}
.w-sp-5h,
.width-sp-5h {
  width: calc((0.5 + 5) * var(--spacing));
}
.w-sp-5t,
.width-sp-5t {
  width: calc((0.75 + 5) * var(--spacing));
}
.w-sp-6,
.width-sp-6 {
  width: calc((0 + 6) * var(--spacing));
}
.w-sp-6q,
.width-sp-6q {
  width: calc((0.25 + 6) * var(--spacing));
}
.w-sp-6h,
.width-sp-6h {
  width: calc((0.5 + 6) * var(--spacing));
}
.w-sp-6t,
.width-sp-6t {
  width: calc((0.75 + 6) * var(--spacing));
}
.w-sp-7,
.width-sp-7 {
  width: calc((0 + 7) * var(--spacing));
}
.w-sp-7q,
.width-sp-7q {
  width: calc((0.25 + 7) * var(--spacing));
}
.w-sp-7h,
.width-sp-7h {
  width: calc((0.5 + 7) * var(--spacing));
}
.w-sp-7t,
.width-sp-7t {
  width: calc((0.75 + 7) * var(--spacing));
}
.w-sp-8,
.width-sp-8 {
  width: calc((0 + 8) * var(--spacing));
}
.w-sp-8q,
.width-sp-8q {
  width: calc((0.25 + 8) * var(--spacing));
}
.w-sp-8h,
.width-sp-8h {
  width: calc((0.5 + 8) * var(--spacing));
}
.w-sp-8t,
.width-sp-8t {
  width: calc((0.75 + 8) * var(--spacing));
}
.w-sp-9,
.width-sp-9 {
  width: calc((0 + 9) * var(--spacing));
}
.w-sp-9q,
.width-sp-9q {
  width: calc((0.25 + 9) * var(--spacing));
}
.w-sp-9h,
.width-sp-9h {
  width: calc((0.5 + 9) * var(--spacing));
}
.w-sp-9t,
.width-sp-9t {
  width: calc((0.75 + 9) * var(--spacing));
}
.w-sp-10,
.width-sp-10 {
  width: calc((0 + 10) * var(--spacing));
}
.w-sp-10q,
.width-sp-10q {
  width: calc((0.25 + 10) * var(--spacing));
}
.w-sp-10h,
.width-sp-10h {
  width: calc((0.5 + 10) * var(--spacing));
}
.w-sp-10t,
.width-sp-10t {
  width: calc((0.75 + 10) * var(--spacing));
}
.w-px-1,
.width-px-1 {
  width: 1px;
}
.w-fs-h1,
.width-fs-h1 {
  width: var(--fs-h1);
}
.w-fs-xxl,
.width-fs-xxl {
  width: var(--fs-xxl);
}
.w-fs-xl,
.width-fs-xl {
  width: var(--fs-xl);
}
.w-fs-l,
.width-fs-l {
  width: var(--fs-l);
}
.w-fs-m,
.width-fs-m {
  width: var(--fs-m);
}
.w-fs-s,
.width-fs-s {
  width: var(--fs-s);
}
.w-fs-xs,
.width-fs-xs {
  width: var(--fs-xs);
}
.w-fs-xxs,
.width-fs-xxs {
  width: var(--fs-xxs);
}
.w-fs-icon-s,
.width-fs-icon-s {
  width: var(--fs-icon-s);
}
.w-fs-icon-small,
.width-fs-icon-small {
  width: var(--fs-icon-small);
}
.w-fs-icon,
.width-fs-icon {
  width: var(--fs-icon);
}
.w-fs-icon-l,
.width-fs-icon-l {
  width: var(--fs-icon-l);
}
.w-fs-icon-2x,
.width-fs-icon-2x {
  width: var(--fs-icon-2x);
}
.w-menu-xs,
.width-menu-xs {
  width: 200px;
}
.w-menu-s,
.width-menu-s {
  width: 320px;
}
.w-content-s,
.width-content-s {
  width: 720px;
}
.w-content,
.width-content {
  width: 800px;
}
.w-content-l,
.width-content-l {
  width: 920px;
}

.max-w-0,
.max-width-0 {
  max-width: 0px;
}
.max-w-10,
.max-width-10 {
  max-width: calc(1 * 10%);
}
.max-w-20,
.max-width-20 {
  max-width: calc(2 * 10%);
}
.max-w-30,
.max-width-30 {
  max-width: calc(3 * 10%);
}
.max-w-40,
.max-width-40 {
  max-width: calc(4 * 10%);
}
.max-w-50,
.max-width-50 {
  max-width: calc(5 * 10%);
}
.max-w-60,
.max-width-60 {
  max-width: calc(6 * 10%);
}
.max-w-70,
.max-width-70 {
  max-width: calc(7 * 10%);
}
.max-w-80,
.max-width-80 {
  max-width: calc(8 * 10%);
}
.max-w-90,
.max-width-90 {
  max-width: calc(9 * 10%);
}
.max-w-100,
.max-width-100 {
  max-width: calc(10 * 10%);
}
.max-w-sp-0,
.max-width-sp-0 {
  max-width: calc((0 + 0) * var(--spacing));
}
.max-w-sp-0q,
.max-width-sp-0q {
  max-width: calc((0.25 + 0) * var(--spacing));
}
.max-w-sp-0h,
.max-width-sp-0h {
  max-width: calc((0.5 + 0) * var(--spacing));
}
.max-w-sp-0t,
.max-width-sp-0t {
  max-width: calc((0.75 + 0) * var(--spacing));
}
.max-w-sp-1,
.max-width-sp-1 {
  max-width: calc((0 + 1) * var(--spacing));
}
.max-w-sp-1q,
.max-width-sp-1q {
  max-width: calc((0.25 + 1) * var(--spacing));
}
.max-w-sp-1h,
.max-width-sp-1h {
  max-width: calc((0.5 + 1) * var(--spacing));
}
.max-w-sp-1t,
.max-width-sp-1t {
  max-width: calc((0.75 + 1) * var(--spacing));
}
.max-w-sp-2,
.max-width-sp-2 {
  max-width: calc((0 + 2) * var(--spacing));
}
.max-w-sp-2q,
.max-width-sp-2q {
  max-width: calc((0.25 + 2) * var(--spacing));
}
.max-w-sp-2h,
.max-width-sp-2h {
  max-width: calc((0.5 + 2) * var(--spacing));
}
.max-w-sp-2t,
.max-width-sp-2t {
  max-width: calc((0.75 + 2) * var(--spacing));
}
.max-w-sp-3,
.max-width-sp-3 {
  max-width: calc((0 + 3) * var(--spacing));
}
.max-w-sp-3q,
.max-width-sp-3q {
  max-width: calc((0.25 + 3) * var(--spacing));
}
.max-w-sp-3h,
.max-width-sp-3h {
  max-width: calc((0.5 + 3) * var(--spacing));
}
.max-w-sp-3t,
.max-width-sp-3t {
  max-width: calc((0.75 + 3) * var(--spacing));
}
.max-w-sp-4,
.max-width-sp-4 {
  max-width: calc((0 + 4) * var(--spacing));
}
.max-w-sp-4q,
.max-width-sp-4q {
  max-width: calc((0.25 + 4) * var(--spacing));
}
.max-w-sp-4h,
.max-width-sp-4h {
  max-width: calc((0.5 + 4) * var(--spacing));
}
.max-w-sp-4t,
.max-width-sp-4t {
  max-width: calc((0.75 + 4) * var(--spacing));
}
.max-w-sp-5,
.max-width-sp-5 {
  max-width: calc((0 + 5) * var(--spacing));
}
.max-w-sp-5q,
.max-width-sp-5q {
  max-width: calc((0.25 + 5) * var(--spacing));
}
.max-w-sp-5h,
.max-width-sp-5h {
  max-width: calc((0.5 + 5) * var(--spacing));
}
.max-w-sp-5t,
.max-width-sp-5t {
  max-width: calc((0.75 + 5) * var(--spacing));
}
.max-w-sp-6,
.max-width-sp-6 {
  max-width: calc((0 + 6) * var(--spacing));
}
.max-w-sp-6q,
.max-width-sp-6q {
  max-width: calc((0.25 + 6) * var(--spacing));
}
.max-w-sp-6h,
.max-width-sp-6h {
  max-width: calc((0.5 + 6) * var(--spacing));
}
.max-w-sp-6t,
.max-width-sp-6t {
  max-width: calc((0.75 + 6) * var(--spacing));
}
.max-w-sp-7,
.max-width-sp-7 {
  max-width: calc((0 + 7) * var(--spacing));
}
.max-w-sp-7q,
.max-width-sp-7q {
  max-width: calc((0.25 + 7) * var(--spacing));
}
.max-w-sp-7h,
.max-width-sp-7h {
  max-width: calc((0.5 + 7) * var(--spacing));
}
.max-w-sp-7t,
.max-width-sp-7t {
  max-width: calc((0.75 + 7) * var(--spacing));
}
.max-w-sp-8,
.max-width-sp-8 {
  max-width: calc((0 + 8) * var(--spacing));
}
.max-w-sp-8q,
.max-width-sp-8q {
  max-width: calc((0.25 + 8) * var(--spacing));
}
.max-w-sp-8h,
.max-width-sp-8h {
  max-width: calc((0.5 + 8) * var(--spacing));
}
.max-w-sp-8t,
.max-width-sp-8t {
  max-width: calc((0.75 + 8) * var(--spacing));
}
.max-w-sp-9,
.max-width-sp-9 {
  max-width: calc((0 + 9) * var(--spacing));
}
.max-w-sp-9q,
.max-width-sp-9q {
  max-width: calc((0.25 + 9) * var(--spacing));
}
.max-w-sp-9h,
.max-width-sp-9h {
  max-width: calc((0.5 + 9) * var(--spacing));
}
.max-w-sp-9t,
.max-width-sp-9t {
  max-width: calc((0.75 + 9) * var(--spacing));
}
.max-w-sp-10,
.max-width-sp-10 {
  max-width: calc((0 + 10) * var(--spacing));
}
.max-w-sp-10q,
.max-width-sp-10q {
  max-width: calc((0.25 + 10) * var(--spacing));
}
.max-w-sp-10h,
.max-width-sp-10h {
  max-width: calc((0.5 + 10) * var(--spacing));
}
.max-w-sp-10t,
.max-width-sp-10t {
  max-width: calc((0.75 + 10) * var(--spacing));
}
.max-w-px-1,
.max-width-px-1 {
  max-width: 1px;
}
.max-w-fs-h1,
.max-width-fs-h1 {
  max-width: var(--fs-h1);
}
.max-w-fs-xxl,
.max-width-fs-xxl {
  max-width: var(--fs-xxl);
}
.max-w-fs-xl,
.max-width-fs-xl {
  max-width: var(--fs-xl);
}
.max-w-fs-l,
.max-width-fs-l {
  max-width: var(--fs-l);
}
.max-w-fs-m,
.max-width-fs-m {
  max-width: var(--fs-m);
}
.max-w-fs-s,
.max-width-fs-s {
  max-width: var(--fs-s);
}
.max-w-fs-xs,
.max-width-fs-xs {
  max-width: var(--fs-xs);
}
.max-w-fs-xxs,
.max-width-fs-xxs {
  max-width: var(--fs-xxs);
}
.max-w-fs-icon-s,
.max-width-fs-icon-s {
  max-width: var(--fs-icon-s);
}
.max-w-fs-icon-small,
.max-width-fs-icon-small {
  max-width: var(--fs-icon-small);
}
.max-w-fs-icon,
.max-width-fs-icon {
  max-width: var(--fs-icon);
}
.max-w-fs-icon-l,
.max-width-fs-icon-l {
  max-width: var(--fs-icon-l);
}
.max-w-fs-icon-2x,
.max-width-fs-icon-2x {
  max-width: var(--fs-icon-2x);
}
.max-w-menu-xs,
.max-width-menu-xs {
  max-width: 200px;
}
.max-w-menu-s,
.max-width-menu-s {
  max-width: 320px;
}
.max-w-content-s,
.max-width-content-s {
  max-width: 720px;
}
.max-w-content,
.max-width-content {
  max-width: 800px;
}
.max-w-content-l,
.max-width-content-l {
  max-width: 920px;
}

.min-h-0,
.min-height-0 {
  min-height: 0px;
}
.min-h-10,
.min-height-10 {
  min-height: calc(1 * 10%);
}
.min-h-20,
.min-height-20 {
  min-height: calc(2 * 10%);
}
.min-h-30,
.min-height-30 {
  min-height: calc(3 * 10%);
}
.min-h-40,
.min-height-40 {
  min-height: calc(4 * 10%);
}
.min-h-50,
.min-height-50 {
  min-height: calc(5 * 10%);
}
.min-h-60,
.min-height-60 {
  min-height: calc(6 * 10%);
}
.min-h-70,
.min-height-70 {
  min-height: calc(7 * 10%);
}
.min-h-80,
.min-height-80 {
  min-height: calc(8 * 10%);
}
.min-h-90,
.min-height-90 {
  min-height: calc(9 * 10%);
}
.min-h-100,
.min-height-100 {
  min-height: calc(10 * 10%);
}
.min-h-sp-0,
.min-height-sp-0 {
  min-height: calc((0 + 0) * var(--spacing));
}
.min-h-sp-0q,
.min-height-sp-0q {
  min-height: calc((0.25 + 0) * var(--spacing));
}
.min-h-sp-0h,
.min-height-sp-0h {
  min-height: calc((0.5 + 0) * var(--spacing));
}
.min-h-sp-0t,
.min-height-sp-0t {
  min-height: calc((0.75 + 0) * var(--spacing));
}
.min-h-sp-1,
.min-height-sp-1 {
  min-height: calc((0 + 1) * var(--spacing));
}
.min-h-sp-1q,
.min-height-sp-1q {
  min-height: calc((0.25 + 1) * var(--spacing));
}
.min-h-sp-1h,
.min-height-sp-1h {
  min-height: calc((0.5 + 1) * var(--spacing));
}
.min-h-sp-1t,
.min-height-sp-1t {
  min-height: calc((0.75 + 1) * var(--spacing));
}
.min-h-sp-2,
.min-height-sp-2 {
  min-height: calc((0 + 2) * var(--spacing));
}
.min-h-sp-2q,
.min-height-sp-2q {
  min-height: calc((0.25 + 2) * var(--spacing));
}
.min-h-sp-2h,
.min-height-sp-2h {
  min-height: calc((0.5 + 2) * var(--spacing));
}
.min-h-sp-2t,
.min-height-sp-2t {
  min-height: calc((0.75 + 2) * var(--spacing));
}
.min-h-sp-3,
.min-height-sp-3 {
  min-height: calc((0 + 3) * var(--spacing));
}
.min-h-sp-3q,
.min-height-sp-3q {
  min-height: calc((0.25 + 3) * var(--spacing));
}
.min-h-sp-3h,
.min-height-sp-3h {
  min-height: calc((0.5 + 3) * var(--spacing));
}
.min-h-sp-3t,
.min-height-sp-3t {
  min-height: calc((0.75 + 3) * var(--spacing));
}
.min-h-sp-4,
.min-height-sp-4 {
  min-height: calc((0 + 4) * var(--spacing));
}
.min-h-sp-4q,
.min-height-sp-4q {
  min-height: calc((0.25 + 4) * var(--spacing));
}
.min-h-sp-4h,
.min-height-sp-4h {
  min-height: calc((0.5 + 4) * var(--spacing));
}
.min-h-sp-4t,
.min-height-sp-4t {
  min-height: calc((0.75 + 4) * var(--spacing));
}
.min-h-sp-5,
.min-height-sp-5 {
  min-height: calc((0 + 5) * var(--spacing));
}
.min-h-sp-5q,
.min-height-sp-5q {
  min-height: calc((0.25 + 5) * var(--spacing));
}
.min-h-sp-5h,
.min-height-sp-5h {
  min-height: calc((0.5 + 5) * var(--spacing));
}
.min-h-sp-5t,
.min-height-sp-5t {
  min-height: calc((0.75 + 5) * var(--spacing));
}
.min-h-sp-6,
.min-height-sp-6 {
  min-height: calc((0 + 6) * var(--spacing));
}
.min-h-sp-6q,
.min-height-sp-6q {
  min-height: calc((0.25 + 6) * var(--spacing));
}
.min-h-sp-6h,
.min-height-sp-6h {
  min-height: calc((0.5 + 6) * var(--spacing));
}
.min-h-sp-6t,
.min-height-sp-6t {
  min-height: calc((0.75 + 6) * var(--spacing));
}
.min-h-sp-7,
.min-height-sp-7 {
  min-height: calc((0 + 7) * var(--spacing));
}
.min-h-sp-7q,
.min-height-sp-7q {
  min-height: calc((0.25 + 7) * var(--spacing));
}
.min-h-sp-7h,
.min-height-sp-7h {
  min-height: calc((0.5 + 7) * var(--spacing));
}
.min-h-sp-7t,
.min-height-sp-7t {
  min-height: calc((0.75 + 7) * var(--spacing));
}
.min-h-sp-8,
.min-height-sp-8 {
  min-height: calc((0 + 8) * var(--spacing));
}
.min-h-sp-8q,
.min-height-sp-8q {
  min-height: calc((0.25 + 8) * var(--spacing));
}
.min-h-sp-8h,
.min-height-sp-8h {
  min-height: calc((0.5 + 8) * var(--spacing));
}
.min-h-sp-8t,
.min-height-sp-8t {
  min-height: calc((0.75 + 8) * var(--spacing));
}
.min-h-sp-9,
.min-height-sp-9 {
  min-height: calc((0 + 9) * var(--spacing));
}
.min-h-sp-9q,
.min-height-sp-9q {
  min-height: calc((0.25 + 9) * var(--spacing));
}
.min-h-sp-9h,
.min-height-sp-9h {
  min-height: calc((0.5 + 9) * var(--spacing));
}
.min-h-sp-9t,
.min-height-sp-9t {
  min-height: calc((0.75 + 9) * var(--spacing));
}
.min-h-sp-10,
.min-height-sp-10 {
  min-height: calc((0 + 10) * var(--spacing));
}
.min-h-sp-10q,
.min-height-sp-10q {
  min-height: calc((0.25 + 10) * var(--spacing));
}
.min-h-sp-10h,
.min-height-sp-10h {
  min-height: calc((0.5 + 10) * var(--spacing));
}
.min-h-sp-10t,
.min-height-sp-10t {
  min-height: calc((0.75 + 10) * var(--spacing));
}
.min-h-px-1,
.min-height-px-1 {
  min-height: 1px;
}
.min-h-fs-h1,
.min-height-fs-h1 {
  min-height: var(--fs-h1);
}
.min-h-fs-xxl,
.min-height-fs-xxl {
  min-height: var(--fs-xxl);
}
.min-h-fs-xl,
.min-height-fs-xl {
  min-height: var(--fs-xl);
}
.min-h-fs-l,
.min-height-fs-l {
  min-height: var(--fs-l);
}
.min-h-fs-m,
.min-height-fs-m {
  min-height: var(--fs-m);
}
.min-h-fs-s,
.min-height-fs-s {
  min-height: var(--fs-s);
}
.min-h-fs-xs,
.min-height-fs-xs {
  min-height: var(--fs-xs);
}
.min-h-fs-xxs,
.min-height-fs-xxs {
  min-height: var(--fs-xxs);
}
.min-h-fs-icon-s,
.min-height-fs-icon-s {
  min-height: var(--fs-icon-s);
}
.min-h-fs-icon-small,
.min-height-fs-icon-small {
  min-height: var(--fs-icon-small);
}
.min-h-fs-icon,
.min-height-fs-icon {
  min-height: var(--fs-icon);
}
.min-h-fs-icon-l,
.min-height-fs-icon-l {
  min-height: var(--fs-icon-l);
}
.min-h-fs-icon-2x,
.min-height-fs-icon-2x {
  min-height: var(--fs-icon-2x);
}
.min-h-menu-xs,
.min-height-menu-xs {
  min-height: 200px;
}
.min-h-menu-s,
.min-height-menu-s {
  min-height: 320px;
}
.min-h-content-s,
.min-height-content-s {
  min-height: 720px;
}
.min-h-content,
.min-height-content {
  min-height: 800px;
}
.min-h-content-l,
.min-height-content-l {
  min-height: 920px;
}

.h-0,
.height-0 {
  height: 0px;
}
.h-10,
.height-10 {
  height: calc(1 * 10%);
}
.h-20,
.height-20 {
  height: calc(2 * 10%);
}
.h-30,
.height-30 {
  height: calc(3 * 10%);
}
.h-40,
.height-40 {
  height: calc(4 * 10%);
}
.h-50,
.height-50 {
  height: calc(5 * 10%);
}
.h-60,
.height-60 {
  height: calc(6 * 10%);
}
.h-70,
.height-70 {
  height: calc(7 * 10%);
}
.h-80,
.height-80 {
  height: calc(8 * 10%);
}
.h-90,
.height-90 {
  height: calc(9 * 10%);
}
.h-100,
.height-100 {
  height: calc(10 * 10%);
}
.h-sp-0,
.height-sp-0 {
  height: calc((0 + 0) * var(--spacing));
}
.h-sp-0q,
.height-sp-0q {
  height: calc((0.25 + 0) * var(--spacing));
}
.h-sp-0h,
.height-sp-0h {
  height: calc((0.5 + 0) * var(--spacing));
}
.h-sp-0t,
.height-sp-0t {
  height: calc((0.75 + 0) * var(--spacing));
}
.h-sp-1,
.height-sp-1 {
  height: calc((0 + 1) * var(--spacing));
}
.h-sp-1q,
.height-sp-1q {
  height: calc((0.25 + 1) * var(--spacing));
}
.h-sp-1h,
.height-sp-1h {
  height: calc((0.5 + 1) * var(--spacing));
}
.h-sp-1t,
.height-sp-1t {
  height: calc((0.75 + 1) * var(--spacing));
}
.h-sp-2,
.height-sp-2 {
  height: calc((0 + 2) * var(--spacing));
}
.h-sp-2q,
.height-sp-2q {
  height: calc((0.25 + 2) * var(--spacing));
}
.h-sp-2h,
.height-sp-2h {
  height: calc((0.5 + 2) * var(--spacing));
}
.h-sp-2t,
.height-sp-2t {
  height: calc((0.75 + 2) * var(--spacing));
}
.h-sp-3,
.height-sp-3 {
  height: calc((0 + 3) * var(--spacing));
}
.h-sp-3q,
.height-sp-3q {
  height: calc((0.25 + 3) * var(--spacing));
}
.h-sp-3h,
.height-sp-3h {
  height: calc((0.5 + 3) * var(--spacing));
}
.h-sp-3t,
.height-sp-3t {
  height: calc((0.75 + 3) * var(--spacing));
}
.h-sp-4,
.height-sp-4 {
  height: calc((0 + 4) * var(--spacing));
}
.h-sp-4q,
.height-sp-4q {
  height: calc((0.25 + 4) * var(--spacing));
}
.h-sp-4h,
.height-sp-4h {
  height: calc((0.5 + 4) * var(--spacing));
}
.h-sp-4t,
.height-sp-4t {
  height: calc((0.75 + 4) * var(--spacing));
}
.h-sp-5,
.height-sp-5 {
  height: calc((0 + 5) * var(--spacing));
}
.h-sp-5q,
.height-sp-5q {
  height: calc((0.25 + 5) * var(--spacing));
}
.h-sp-5h,
.height-sp-5h {
  height: calc((0.5 + 5) * var(--spacing));
}
.h-sp-5t,
.height-sp-5t {
  height: calc((0.75 + 5) * var(--spacing));
}
.h-sp-6,
.height-sp-6 {
  height: calc((0 + 6) * var(--spacing));
}
.h-sp-6q,
.height-sp-6q {
  height: calc((0.25 + 6) * var(--spacing));
}
.h-sp-6h,
.height-sp-6h {
  height: calc((0.5 + 6) * var(--spacing));
}
.h-sp-6t,
.height-sp-6t {
  height: calc((0.75 + 6) * var(--spacing));
}
.h-sp-7,
.height-sp-7 {
  height: calc((0 + 7) * var(--spacing));
}
.h-sp-7q,
.height-sp-7q {
  height: calc((0.25 + 7) * var(--spacing));
}
.h-sp-7h,
.height-sp-7h {
  height: calc((0.5 + 7) * var(--spacing));
}
.h-sp-7t,
.height-sp-7t {
  height: calc((0.75 + 7) * var(--spacing));
}
.h-sp-8,
.height-sp-8 {
  height: calc((0 + 8) * var(--spacing));
}
.h-sp-8q,
.height-sp-8q {
  height: calc((0.25 + 8) * var(--spacing));
}
.h-sp-8h,
.height-sp-8h {
  height: calc((0.5 + 8) * var(--spacing));
}
.h-sp-8t,
.height-sp-8t {
  height: calc((0.75 + 8) * var(--spacing));
}
.h-sp-9,
.height-sp-9 {
  height: calc((0 + 9) * var(--spacing));
}
.h-sp-9q,
.height-sp-9q {
  height: calc((0.25 + 9) * var(--spacing));
}
.h-sp-9h,
.height-sp-9h {
  height: calc((0.5 + 9) * var(--spacing));
}
.h-sp-9t,
.height-sp-9t {
  height: calc((0.75 + 9) * var(--spacing));
}
.h-sp-10,
.height-sp-10 {
  height: calc((0 + 10) * var(--spacing));
}
.h-sp-10q,
.height-sp-10q {
  height: calc((0.25 + 10) * var(--spacing));
}
.h-sp-10h,
.height-sp-10h {
  height: calc((0.5 + 10) * var(--spacing));
}
.h-sp-10t,
.height-sp-10t {
  height: calc((0.75 + 10) * var(--spacing));
}
.h-px-1,
.height-px-1 {
  height: 1px;
}
.h-fs-h1,
.height-fs-h1 {
  height: var(--fs-h1);
}
.h-fs-xxl,
.height-fs-xxl {
  height: var(--fs-xxl);
}
.h-fs-xl,
.height-fs-xl {
  height: var(--fs-xl);
}
.h-fs-l,
.height-fs-l {
  height: var(--fs-l);
}
.h-fs-m,
.height-fs-m {
  height: var(--fs-m);
}
.h-fs-s,
.height-fs-s {
  height: var(--fs-s);
}
.h-fs-xs,
.height-fs-xs {
  height: var(--fs-xs);
}
.h-fs-xxs,
.height-fs-xxs {
  height: var(--fs-xxs);
}
.h-fs-icon-s,
.height-fs-icon-s {
  height: var(--fs-icon-s);
}
.h-fs-icon-small,
.height-fs-icon-small {
  height: var(--fs-icon-small);
}
.h-fs-icon,
.height-fs-icon {
  height: var(--fs-icon);
}
.h-fs-icon-l,
.height-fs-icon-l {
  height: var(--fs-icon-l);
}
.h-fs-icon-2x,
.height-fs-icon-2x {
  height: var(--fs-icon-2x);
}
.h-menu-xs,
.height-menu-xs {
  height: 200px;
}
.h-menu-s,
.height-menu-s {
  height: 320px;
}
.h-content-s,
.height-content-s {
  height: 720px;
}
.h-content,
.height-content {
  height: 800px;
}
.h-content-l,
.height-content-l {
  height: 920px;
}

.max-h-0,
.max-height-0 {
  max-height: 0px;
}
.max-h-10,
.max-height-10 {
  max-height: calc(1 * 10%);
}
.max-h-20,
.max-height-20 {
  max-height: calc(2 * 10%);
}
.max-h-30,
.max-height-30 {
  max-height: calc(3 * 10%);
}
.max-h-40,
.max-height-40 {
  max-height: calc(4 * 10%);
}
.max-h-50,
.max-height-50 {
  max-height: calc(5 * 10%);
}
.max-h-60,
.max-height-60 {
  max-height: calc(6 * 10%);
}
.max-h-70,
.max-height-70 {
  max-height: calc(7 * 10%);
}
.max-h-80,
.max-height-80 {
  max-height: calc(8 * 10%);
}
.max-h-90,
.max-height-90 {
  max-height: calc(9 * 10%);
}
.max-h-100,
.max-height-100 {
  max-height: calc(10 * 10%);
}
.max-h-sp-0,
.max-height-sp-0 {
  max-height: calc((0 + 0) * var(--spacing));
}
.max-h-sp-0q,
.max-height-sp-0q {
  max-height: calc((0.25 + 0) * var(--spacing));
}
.max-h-sp-0h,
.max-height-sp-0h {
  max-height: calc((0.5 + 0) * var(--spacing));
}
.max-h-sp-0t,
.max-height-sp-0t {
  max-height: calc((0.75 + 0) * var(--spacing));
}
.max-h-sp-1,
.max-height-sp-1 {
  max-height: calc((0 + 1) * var(--spacing));
}
.max-h-sp-1q,
.max-height-sp-1q {
  max-height: calc((0.25 + 1) * var(--spacing));
}
.max-h-sp-1h,
.max-height-sp-1h {
  max-height: calc((0.5 + 1) * var(--spacing));
}
.max-h-sp-1t,
.max-height-sp-1t {
  max-height: calc((0.75 + 1) * var(--spacing));
}
.max-h-sp-2,
.max-height-sp-2 {
  max-height: calc((0 + 2) * var(--spacing));
}
.max-h-sp-2q,
.max-height-sp-2q {
  max-height: calc((0.25 + 2) * var(--spacing));
}
.max-h-sp-2h,
.max-height-sp-2h {
  max-height: calc((0.5 + 2) * var(--spacing));
}
.max-h-sp-2t,
.max-height-sp-2t {
  max-height: calc((0.75 + 2) * var(--spacing));
}
.max-h-sp-3,
.max-height-sp-3 {
  max-height: calc((0 + 3) * var(--spacing));
}
.max-h-sp-3q,
.max-height-sp-3q {
  max-height: calc((0.25 + 3) * var(--spacing));
}
.max-h-sp-3h,
.max-height-sp-3h {
  max-height: calc((0.5 + 3) * var(--spacing));
}
.max-h-sp-3t,
.max-height-sp-3t {
  max-height: calc((0.75 + 3) * var(--spacing));
}
.max-h-sp-4,
.max-height-sp-4 {
  max-height: calc((0 + 4) * var(--spacing));
}
.max-h-sp-4q,
.max-height-sp-4q {
  max-height: calc((0.25 + 4) * var(--spacing));
}
.max-h-sp-4h,
.max-height-sp-4h {
  max-height: calc((0.5 + 4) * var(--spacing));
}
.max-h-sp-4t,
.max-height-sp-4t {
  max-height: calc((0.75 + 4) * var(--spacing));
}
.max-h-sp-5,
.max-height-sp-5 {
  max-height: calc((0 + 5) * var(--spacing));
}
.max-h-sp-5q,
.max-height-sp-5q {
  max-height: calc((0.25 + 5) * var(--spacing));
}
.max-h-sp-5h,
.max-height-sp-5h {
  max-height: calc((0.5 + 5) * var(--spacing));
}
.max-h-sp-5t,
.max-height-sp-5t {
  max-height: calc((0.75 + 5) * var(--spacing));
}
.max-h-sp-6,
.max-height-sp-6 {
  max-height: calc((0 + 6) * var(--spacing));
}
.max-h-sp-6q,
.max-height-sp-6q {
  max-height: calc((0.25 + 6) * var(--spacing));
}
.max-h-sp-6h,
.max-height-sp-6h {
  max-height: calc((0.5 + 6) * var(--spacing));
}
.max-h-sp-6t,
.max-height-sp-6t {
  max-height: calc((0.75 + 6) * var(--spacing));
}
.max-h-sp-7,
.max-height-sp-7 {
  max-height: calc((0 + 7) * var(--spacing));
}
.max-h-sp-7q,
.max-height-sp-7q {
  max-height: calc((0.25 + 7) * var(--spacing));
}
.max-h-sp-7h,
.max-height-sp-7h {
  max-height: calc((0.5 + 7) * var(--spacing));
}
.max-h-sp-7t,
.max-height-sp-7t {
  max-height: calc((0.75 + 7) * var(--spacing));
}
.max-h-sp-8,
.max-height-sp-8 {
  max-height: calc((0 + 8) * var(--spacing));
}
.max-h-sp-8q,
.max-height-sp-8q {
  max-height: calc((0.25 + 8) * var(--spacing));
}
.max-h-sp-8h,
.max-height-sp-8h {
  max-height: calc((0.5 + 8) * var(--spacing));
}
.max-h-sp-8t,
.max-height-sp-8t {
  max-height: calc((0.75 + 8) * var(--spacing));
}
.max-h-sp-9,
.max-height-sp-9 {
  max-height: calc((0 + 9) * var(--spacing));
}
.max-h-sp-9q,
.max-height-sp-9q {
  max-height: calc((0.25 + 9) * var(--spacing));
}
.max-h-sp-9h,
.max-height-sp-9h {
  max-height: calc((0.5 + 9) * var(--spacing));
}
.max-h-sp-9t,
.max-height-sp-9t {
  max-height: calc((0.75 + 9) * var(--spacing));
}
.max-h-sp-10,
.max-height-sp-10 {
  max-height: calc((0 + 10) * var(--spacing));
}
.max-h-sp-10q,
.max-height-sp-10q {
  max-height: calc((0.25 + 10) * var(--spacing));
}
.max-h-sp-10h,
.max-height-sp-10h {
  max-height: calc((0.5 + 10) * var(--spacing));
}
.max-h-sp-10t,
.max-height-sp-10t {
  max-height: calc((0.75 + 10) * var(--spacing));
}
.max-h-px-1,
.max-height-px-1 {
  max-height: 1px;
}
.max-h-fs-h1,
.max-height-fs-h1 {
  max-height: var(--fs-h1);
}
.max-h-fs-xxl,
.max-height-fs-xxl {
  max-height: var(--fs-xxl);
}
.max-h-fs-xl,
.max-height-fs-xl {
  max-height: var(--fs-xl);
}
.max-h-fs-l,
.max-height-fs-l {
  max-height: var(--fs-l);
}
.max-h-fs-m,
.max-height-fs-m {
  max-height: var(--fs-m);
}
.max-h-fs-s,
.max-height-fs-s {
  max-height: var(--fs-s);
}
.max-h-fs-xs,
.max-height-fs-xs {
  max-height: var(--fs-xs);
}
.max-h-fs-xxs,
.max-height-fs-xxs {
  max-height: var(--fs-xxs);
}
.max-h-fs-icon-s,
.max-height-fs-icon-s {
  max-height: var(--fs-icon-s);
}
.max-h-fs-icon-small,
.max-height-fs-icon-small {
  max-height: var(--fs-icon-small);
}
.max-h-fs-icon,
.max-height-fs-icon {
  max-height: var(--fs-icon);
}
.max-h-fs-icon-l,
.max-height-fs-icon-l {
  max-height: var(--fs-icon-l);
}
.max-h-fs-icon-2x,
.max-height-fs-icon-2x {
  max-height: var(--fs-icon-2x);
}
.max-h-menu-xs,
.max-height-menu-xs {
  max-height: 200px;
}
.max-h-menu-s,
.max-height-menu-s {
  max-height: 320px;
}
.max-h-content-s,
.max-height-content-s {
  max-height: 720px;
}
.max-h-content,
.max-height-content {
  max-height: 800px;
}
.max-h-content-l,
.max-height-content-l {
  max-height: 920px;
}

:root {
  --client-size-desktop: 720px;
  --size-max-width-desktop: 760px;
  --small-max-width-desktop: 320px;
}
:root.screen-mobile .hide-on-mobile {
  display: none;
}
:root.screen-desktop .width-client-size-desktop {
  width: var(--client-size-desktop);
}
:root.screen-desktop .height-client-size-desktop {
  height: var(--client-size-desktop);
}
:root.screen-desktop .max-width-desktop {
  max-width: var(--size-max-width-desktop);
}
:root.screen-desktop .max-height-desktop {
  max-height: var(--size-max-width-desktop);
}
:root.screen-desktop .desktop-small-max-width {
  max-width: var(--small-max-width-desktop);
}

.reach-toggler {
  position: relative;
  display: inline-block;
  color: var(--color-text-400);
  --__space_px: 1;
  --__space: calc((var(--__space_px) / 8) * 1em);
  --__indicator-size: calc(1em - (var(--__space)));
  border-radius: calc(0.5 * var(--__indicator-size) + var(--__space));
  height: calc(var(--__indicator-size) + 2 * var(--__space));
  width: calc(2 * var(--__indicator-size) + 3 * var(--__space));
  background-color: currentColor;
}
.reach-toggler::after {
  content: "";
  position: absolute;
  top: 1px;
  left: 1px;
  aspect-ratio: 1;
  height: calc(1.1 * var(--__indicator-size));
  border-radius: calc(0.7 * var(--__indicator-size));
  background-color: var(--color-bg-190);
  transform: translateX(0px);
  transition: transform 300ms;
}
.reach-toggler.active {
  color: var(--color-primary);
}
.reach-toggler.active::after {
  transform: translateX(calc(var(--__indicator-size) + var(--__space)));
}

/*
	This is how it is structured:
		- key: Name of the class (e.g. `visible` -> `visible-on-hover`).
		- [0]: Property to be applied (e.g. `visibility`).
		- [1]: Default value
		- [2]: Value when on hover.
*/
/*
	This is how it is structured:
		- key: Name of the class (e.g. `hover` -> `hover-parent`).
		- values: CSS selectors that will trigger the class (e.g. `focus` -> `:focus` and `:focus-within`).
*/
/*
	This files generates classes like the following:

	```css
	.visible-on-hover {
		visibility: hidden;
	}

	.hover-parent:hover {
		.visible-on-hover {
			visibility: visible;
		}
	}

	.visible-on-focus {
		visibility: hidden;
	}

	.focus-parent:focus,
	.focus-parent:focus-within {
		.visible-on-focus {
			visibility: visible;
		}
	}
	```

	And the same for every other item in `$properties-map` and `$selectors`.
*/
/*
	This adds the required `@media` query for the hover (and not for the others).
*/
@media (hover: hover) {
  .visible-on-hover {
    visibility: hidden;
    /*
    	This example asumes that we are using `visible` and `hover`.

    	This prevents nested `.hover-parent` from activating all the children.

    	In other words: only the first parent will activate the children.

    	Example:

    	```html
    	<div id="1" class="hover-parent">
    		<div id="2" class="hover-parent">
    			<div id="3" class="visible-on-hover"></div>
    		</div>
    		<div id="4" class="visible-on-hover"></div>
    	</div>
    	```

    	- If we hover over `1`, only `4` will be visible.

    	- If we hover over `2`, both `3` and `4` will be visible (because we are also hovering over `1`).
    */
  }
  .hover-parent:hover .hover-parent:not(:hover) .visible-on-hover {
    visibility: hidden;
  }
  .hover-parent:hover .visible-on-hover {
    visibility: visible;
  }
  .visible-display-on-hover {
    display: none;
    /*
    	This example asumes that we are using `visible` and `hover`.

    	This prevents nested `.hover-parent` from activating all the children.

    	In other words: only the first parent will activate the children.

    	Example:

    	```html
    	<div id="1" class="hover-parent">
    		<div id="2" class="hover-parent">
    			<div id="3" class="visible-on-hover"></div>
    		</div>
    		<div id="4" class="visible-on-hover"></div>
    	</div>
    	```

    	- If we hover over `1`, only `4` will be visible.

    	- If we hover over `2`, both `3` and `4` will be visible (because we are also hovering over `1`).
    */
  }
  .hover-parent:hover .hover-parent:not(:hover) .visible-display-on-hover {
    display: none;
  }
  .hover-parent:hover .visible-display-on-hover {
    display: flex;
  }
  .visible-display-none-on-hover {
    display: flex;
    /*
    	This example asumes that we are using `visible` and `hover`.

    	This prevents nested `.hover-parent` from activating all the children.

    	In other words: only the first parent will activate the children.

    	Example:

    	```html
    	<div id="1" class="hover-parent">
    		<div id="2" class="hover-parent">
    			<div id="3" class="visible-on-hover"></div>
    		</div>
    		<div id="4" class="visible-on-hover"></div>
    	</div>
    	```

    	- If we hover over `1`, only `4` will be visible.

    	- If we hover over `2`, both `3` and `4` will be visible (because we are also hovering over `1`).
    */
  }
  .hover-parent:hover .hover-parent:not(:hover) .visible-display-none-on-hover {
    display: flex;
  }
  .hover-parent:hover .visible-display-none-on-hover {
    display: none;
  }
  .invisible-on-hover {
    visibility: visible;
    /*
    	This example asumes that we are using `visible` and `hover`.

    	This prevents nested `.hover-parent` from activating all the children.

    	In other words: only the first parent will activate the children.

    	Example:

    	```html
    	<div id="1" class="hover-parent">
    		<div id="2" class="hover-parent">
    			<div id="3" class="visible-on-hover"></div>
    		</div>
    		<div id="4" class="visible-on-hover"></div>
    	</div>
    	```

    	- If we hover over `1`, only `4` will be visible.

    	- If we hover over `2`, both `3` and `4` will be visible (because we are also hovering over `1`).
    */
  }
  .hover-parent:hover .hover-parent:not(:hover) .invisible-on-hover {
    visibility: visible;
  }
  .hover-parent:hover .invisible-on-hover {
    visibility: hidden;
  }
  .hide-on-hover {
    visibility: visible;
    /*
    	This example asumes that we are using `visible` and `hover`.

    	This prevents nested `.hover-parent` from activating all the children.

    	In other words: only the first parent will activate the children.

    	Example:

    	```html
    	<div id="1" class="hover-parent">
    		<div id="2" class="hover-parent">
    			<div id="3" class="visible-on-hover"></div>
    		</div>
    		<div id="4" class="visible-on-hover"></div>
    	</div>
    	```

    	- If we hover over `1`, only `4` will be visible.

    	- If we hover over `2`, both `3` and `4` will be visible (because we are also hovering over `1`).
    */
  }
  .hover-parent:hover .hover-parent:not(:hover) .hide-on-hover {
    visibility: visible;
  }
  .hover-parent:hover .hide-on-hover {
    visibility: hidden;
  }
}
.visible-on-focus {
  visibility: hidden;
  /*
  	This example asumes that we are using `visible` and `hover`.

  	This prevents nested `.hover-parent` from activating all the children.

  	In other words: only the first parent will activate the children.

  	Example:

  	```html
  	<div id="1" class="hover-parent">
  		<div id="2" class="hover-parent">
  			<div id="3" class="visible-on-hover"></div>
  		</div>
  		<div id="4" class="visible-on-hover"></div>
  	</div>
  	```

  	- If we hover over `1`, only `4` will be visible.

  	- If we hover over `2`, both `3` and `4` will be visible (because we are also hovering over `1`).
  */
  /*
  	This example asumes that we are using `visible` and `hover`.

  	This prevents nested `.hover-parent` from activating all the children.

  	In other words: only the first parent will activate the children.

  	Example:

  	```html
  	<div id="1" class="hover-parent">
  		<div id="2" class="hover-parent">
  			<div id="3" class="visible-on-hover"></div>
  		</div>
  		<div id="4" class="visible-on-hover"></div>
  	</div>
  	```

  	- If we hover over `1`, only `4` will be visible.

  	- If we hover over `2`, both `3` and `4` will be visible (because we are also hovering over `1`).
  */
}
.focus-parent:focus .focus-parent:not(:focus) .visible-on-focus {
  visibility: hidden;
}

.focus-parent:focus .visible-on-focus {
  visibility: visible;
}

.focus-parent:focus-within .focus-parent:not(:focus-within) .visible-on-focus {
  visibility: hidden;
}

.focus-parent:focus-within .visible-on-focus {
  visibility: visible;
}

.visible-display-on-focus {
  display: none;
  /*
  	This example asumes that we are using `visible` and `hover`.

  	This prevents nested `.hover-parent` from activating all the children.

  	In other words: only the first parent will activate the children.

  	Example:

  	```html
  	<div id="1" class="hover-parent">
  		<div id="2" class="hover-parent">
  			<div id="3" class="visible-on-hover"></div>
  		</div>
  		<div id="4" class="visible-on-hover"></div>
  	</div>
  	```

  	- If we hover over `1`, only `4` will be visible.

  	- If we hover over `2`, both `3` and `4` will be visible (because we are also hovering over `1`).
  */
  /*
  	This example asumes that we are using `visible` and `hover`.

  	This prevents nested `.hover-parent` from activating all the children.

  	In other words: only the first parent will activate the children.

  	Example:

  	```html
  	<div id="1" class="hover-parent">
  		<div id="2" class="hover-parent">
  			<div id="3" class="visible-on-hover"></div>
  		</div>
  		<div id="4" class="visible-on-hover"></div>
  	</div>
  	```

  	- If we hover over `1`, only `4` will be visible.

  	- If we hover over `2`, both `3` and `4` will be visible (because we are also hovering over `1`).
  */
}
.focus-parent:focus .focus-parent:not(:focus) .visible-display-on-focus {
  display: none;
}

.focus-parent:focus .visible-display-on-focus {
  display: flex;
}

.focus-parent:focus-within .focus-parent:not(:focus-within) .visible-display-on-focus {
  display: none;
}

.focus-parent:focus-within .visible-display-on-focus {
  display: flex;
}

.visible-display-none-on-focus {
  display: flex;
  /*
  	This example asumes that we are using `visible` and `hover`.

  	This prevents nested `.hover-parent` from activating all the children.

  	In other words: only the first parent will activate the children.

  	Example:

  	```html
  	<div id="1" class="hover-parent">
  		<div id="2" class="hover-parent">
  			<div id="3" class="visible-on-hover"></div>
  		</div>
  		<div id="4" class="visible-on-hover"></div>
  	</div>
  	```

  	- If we hover over `1`, only `4` will be visible.

  	- If we hover over `2`, both `3` and `4` will be visible (because we are also hovering over `1`).
  */
  /*
  	This example asumes that we are using `visible` and `hover`.

  	This prevents nested `.hover-parent` from activating all the children.

  	In other words: only the first parent will activate the children.

  	Example:

  	```html
  	<div id="1" class="hover-parent">
  		<div id="2" class="hover-parent">
  			<div id="3" class="visible-on-hover"></div>
  		</div>
  		<div id="4" class="visible-on-hover"></div>
  	</div>
  	```

  	- If we hover over `1`, only `4` will be visible.

  	- If we hover over `2`, both `3` and `4` will be visible (because we are also hovering over `1`).
  */
}
.focus-parent:focus .focus-parent:not(:focus) .visible-display-none-on-focus {
  display: flex;
}

.focus-parent:focus .visible-display-none-on-focus {
  display: none;
}

.focus-parent:focus-within .focus-parent:not(:focus-within) .visible-display-none-on-focus {
  display: flex;
}

.focus-parent:focus-within .visible-display-none-on-focus {
  display: none;
}

.invisible-on-focus {
  visibility: visible;
  /*
  	This example asumes that we are using `visible` and `hover`.

  	This prevents nested `.hover-parent` from activating all the children.

  	In other words: only the first parent will activate the children.

  	Example:

  	```html
  	<div id="1" class="hover-parent">
  		<div id="2" class="hover-parent">
  			<div id="3" class="visible-on-hover"></div>
  		</div>
  		<div id="4" class="visible-on-hover"></div>
  	</div>
  	```

  	- If we hover over `1`, only `4` will be visible.

  	- If we hover over `2`, both `3` and `4` will be visible (because we are also hovering over `1`).
  */
  /*
  	This example asumes that we are using `visible` and `hover`.

  	This prevents nested `.hover-parent` from activating all the children.

  	In other words: only the first parent will activate the children.

  	Example:

  	```html
  	<div id="1" class="hover-parent">
  		<div id="2" class="hover-parent">
  			<div id="3" class="visible-on-hover"></div>
  		</div>
  		<div id="4" class="visible-on-hover"></div>
  	</div>
  	```

  	- If we hover over `1`, only `4` will be visible.

  	- If we hover over `2`, both `3` and `4` will be visible (because we are also hovering over `1`).
  */
}
.focus-parent:focus .focus-parent:not(:focus) .invisible-on-focus {
  visibility: visible;
}

.focus-parent:focus .invisible-on-focus {
  visibility: hidden;
}

.focus-parent:focus-within .focus-parent:not(:focus-within) .invisible-on-focus {
  visibility: visible;
}

.focus-parent:focus-within .invisible-on-focus {
  visibility: hidden;
}

.hide-on-focus {
  visibility: visible;
  /*
  	This example asumes that we are using `visible` and `hover`.

  	This prevents nested `.hover-parent` from activating all the children.

  	In other words: only the first parent will activate the children.

  	Example:

  	```html
  	<div id="1" class="hover-parent">
  		<div id="2" class="hover-parent">
  			<div id="3" class="visible-on-hover"></div>
  		</div>
  		<div id="4" class="visible-on-hover"></div>
  	</div>
  	```

  	- If we hover over `1`, only `4` will be visible.

  	- If we hover over `2`, both `3` and `4` will be visible (because we are also hovering over `1`).
  */
  /*
  	This example asumes that we are using `visible` and `hover`.

  	This prevents nested `.hover-parent` from activating all the children.

  	In other words: only the first parent will activate the children.

  	Example:

  	```html
  	<div id="1" class="hover-parent">
  		<div id="2" class="hover-parent">
  			<div id="3" class="visible-on-hover"></div>
  		</div>
  		<div id="4" class="visible-on-hover"></div>
  	</div>
  	```

  	- If we hover over `1`, only `4` will be visible.

  	- If we hover over `2`, both `3` and `4` will be visible (because we are also hovering over `1`).
  */
}
.focus-parent:focus .focus-parent:not(:focus) .hide-on-focus {
  visibility: visible;
}

.focus-parent:focus .hide-on-focus {
  visibility: hidden;
}

.focus-parent:focus-within .focus-parent:not(:focus-within) .hide-on-focus {
  visibility: visible;
}

.focus-parent:focus-within .hide-on-focus {
  visibility: hidden;
}

@media not (hover: hover) {
  .invisible-on-mobile {
    visibility: hidden;
  }
  .hidden-on-mobile {
    display: none;
  }
}
.invisible {
  visibility: hidden;
}

.visible {
  visibility: visible;
}

.copy-button:not(:focus) .visible-on-active {
  display: none !important;
}
.copy-button:focus .hidden-on-active {
  display: none !important;
}

.active.visible-on-active {
  visibility: visible;
}

/* 
	Generates all the permutations that we have for colors
	that can have different values for light and dark themes.

	They follow this rule: <LIGHT_VALUE><DARK_VALUE>0.
*/
/* 
	Generates the hover classes.

	Example:
	```scss
	.bg-primary {
		@includes addHoverClasses(var(--color-primary-500), background-color);
	}
	```

	Generates the following classes:

	```css
	.bg-primary-on-hover:hover {
		background-color: var(--color-primary-500);
	}

	.hover-parent:hover .bg-primary-on-hover-parent {
		background-color: var(--color-primary-500);
	}
	```
*/
/* 
	Generates the alpha classes (from 0 to 95, steps of 5).

	Example:
	```scss
	.bg-primary {
		@includes addAlphaValues('--bg-primary-rgb', background-color);
	}
	```

	Generates the following classes:

	```css
	.bg-primary-0 {
		background-color: rgba(var(--bg-primary-rgb), 0);
	}

	.bg-primary-5 {
		background-color: rgba(var(--bg-primary-rgb), 0.05);
	}

	(...)

	.bg-primary-95 {
		background-color: rgba(var(--bg-primary-rgb), 0.95);
	}
	```
*/
/* 
	Sets the content when the theme is dark.
*/
/* 
	Generates the css variables given a map.

	It needs the function to generate the css variable name.
*/
/*
	This mixin is just a legacy to handle the variables `--<theme>-<var-name>`.

	This is legacy. We should refactor everything to remove this.
*/
/*
	Generates the css variables given a map with 'light' and (optionally) 'dark' keys.

	It needs the function to generate the css variable name.
*/
:root {
  --color-primary-rgb: 72, 161, 238;
  --color-primary: rgb(var(--color-primary-rgb));
  --color-primary-dark-rgb: 48, 107, 159;
  --color-primary-dark: rgb(var(--color-primary-dark-rgb));
  --color-primary-500-rgb: 72, 161, 238;
  --color-primary-500: rgb(var(--color-primary-500-rgb));
  --color-primary-700-rgb: 48, 107, 159;
  --color-primary-700: rgb(var(--color-primary-700-rgb));
  --color-warn-rgb: 208, 2, 27;
  --color-warn: rgb(var(--color-warn-rgb));
  --color-warn-dark-rgb: 208, 2, 27;
  --color-warn-dark: rgb(var(--color-warn-dark-rgb));
  --color-warn-500-rgb: 208, 2, 27;
  --color-warn-500: rgb(var(--color-warn-500-rgb));
  --color-warn-700-rgb: 208, 2, 27;
  --color-warn-700: rgb(var(--color-warn-700-rgb));
  --light-color-primary-rgb: 72, 161, 238;
  --light-color-primary: rgb(var(--light-color-primary-rgb));
  --light-color-primary-dark-rgb: 48, 107, 159;
  --light-color-primary-dark: rgb(var(--light-color-primary-dark-rgb));
  --light-color-primary-500-rgb: 72, 161, 238;
  --light-color-primary-500: rgb(var(--light-color-primary-500-rgb));
  --light-color-primary-700-rgb: 48, 107, 159;
  --light-color-primary-700: rgb(var(--light-color-primary-700-rgb));
  --light-color-warn-rgb: 208, 2, 27;
  --light-color-warn: rgb(var(--light-color-warn-rgb));
  --light-color-warn-dark-rgb: 208, 2, 27;
  --light-color-warn-dark: rgb(var(--light-color-warn-dark-rgb));
  --light-color-warn-500-rgb: 208, 2, 27;
  --light-color-warn-500: rgb(var(--light-color-warn-500-rgb));
  --light-color-warn-700-rgb: 208, 2, 27;
  --light-color-warn-700: rgb(var(--light-color-warn-700-rgb));
  --dark-color-primary-rgb: 72, 161, 238;
  --dark-color-primary: rgb(var(--dark-color-primary-rgb));
  --dark-color-primary-dark-rgb: 48, 107, 159;
  --dark-color-primary-dark: rgb(var(--dark-color-primary-dark-rgb));
  --dark-color-primary-500-rgb: 72, 161, 238;
  --dark-color-primary-500: rgb(var(--dark-color-primary-500-rgb));
  --dark-color-primary-700-rgb: 48, 107, 159;
  --dark-color-primary-700: rgb(var(--dark-color-primary-700-rgb));
  --dark-color-warn-rgb: 225, 82, 65;
  --dark-color-warn: rgb(var(--dark-color-warn-rgb));
  --dark-color-warn-dark-rgb: 208, 2, 27;
  --dark-color-warn-dark: rgb(var(--dark-color-warn-dark-rgb));
  --dark-color-warn-500-rgb: 225, 82, 65;
  --dark-color-warn-500: rgb(var(--dark-color-warn-500-rgb));
  --dark-color-warn-700-rgb: 208, 2, 27;
  --dark-color-warn-700: rgb(var(--dark-color-warn-700-rgb));
  --color-theme-primary-rgb: 72, 161, 238;
  --color-theme-primary: rgb(var(--color-theme-primary-rgb));
  --color-theme-primary-dark-rgb: 48, 107, 159;
  --color-theme-primary-dark: rgb(var(--color-theme-primary-dark-rgb));
  --color-theme-primary-500-rgb: 72, 161, 238;
  --color-theme-primary-500: rgb(var(--color-theme-primary-500-rgb));
  --color-theme-primary-700-rgb: 48, 107, 159;
  --color-theme-primary-700: rgb(var(--color-theme-primary-700-rgb));
  --color-theme-warn-rgb: 208, 2, 27;
  --color-theme-warn: rgb(var(--color-theme-warn-rgb));
  --color-theme-warn-dark-rgb: 208, 2, 27;
  --color-theme-warn-dark: rgb(var(--color-theme-warn-dark-rgb));
  --color-theme-warn-500-rgb: 208, 2, 27;
  --color-theme-warn-500: rgb(var(--color-theme-warn-500-rgb));
  --color-theme-warn-700-rgb: 208, 2, 27;
  --color-theme-warn-700: rgb(var(--color-theme-warn-700-rgb));
  --light-color-theme-primary-rgb: 72, 161, 238;
  --light-color-theme-primary: rgb(var(--light-color-theme-primary-rgb));
  --light-color-theme-primary-dark-rgb: 48, 107, 159;
  --light-color-theme-primary-dark: rgb(var(--light-color-theme-primary-dark-rgb));
  --light-color-theme-primary-500-rgb: 72, 161, 238;
  --light-color-theme-primary-500: rgb(var(--light-color-theme-primary-500-rgb));
  --light-color-theme-primary-700-rgb: 48, 107, 159;
  --light-color-theme-primary-700: rgb(var(--light-color-theme-primary-700-rgb));
  --light-color-theme-warn-rgb: 208, 2, 27;
  --light-color-theme-warn: rgb(var(--light-color-theme-warn-rgb));
  --light-color-theme-warn-dark-rgb: 208, 2, 27;
  --light-color-theme-warn-dark: rgb(var(--light-color-theme-warn-dark-rgb));
  --light-color-theme-warn-500-rgb: 208, 2, 27;
  --light-color-theme-warn-500: rgb(var(--light-color-theme-warn-500-rgb));
  --light-color-theme-warn-700-rgb: 208, 2, 27;
  --light-color-theme-warn-700: rgb(var(--light-color-theme-warn-700-rgb));
  --dark-color-theme-primary-rgb: 72, 161, 238;
  --dark-color-theme-primary: rgb(var(--dark-color-theme-primary-rgb));
  --dark-color-theme-primary-dark-rgb: 48, 107, 159;
  --dark-color-theme-primary-dark: rgb(var(--dark-color-theme-primary-dark-rgb));
  --dark-color-theme-primary-500-rgb: 72, 161, 238;
  --dark-color-theme-primary-500: rgb(var(--dark-color-theme-primary-500-rgb));
  --dark-color-theme-primary-700-rgb: 48, 107, 159;
  --dark-color-theme-primary-700: rgb(var(--dark-color-theme-primary-700-rgb));
  --dark-color-theme-warn-rgb: 225, 82, 65;
  --dark-color-theme-warn: rgb(var(--dark-color-theme-warn-rgb));
  --dark-color-theme-warn-dark-rgb: 208, 2, 27;
  --dark-color-theme-warn-dark: rgb(var(--dark-color-theme-warn-dark-rgb));
  --dark-color-theme-warn-500-rgb: 225, 82, 65;
  --dark-color-theme-warn-500: rgb(var(--dark-color-theme-warn-500-rgb));
  --dark-color-theme-warn-700-rgb: 208, 2, 27;
  --dark-color-theme-warn-700: rgb(var(--dark-color-theme-warn-700-rgb));
}
:root[app-theme=auto][client-theme=dark], :root[app-theme=dark] {
  --color-primary-rgb: 72, 161, 238;
  --color-primary: rgb(var(--color-primary-rgb));
  --color-primary-dark-rgb: 48, 107, 159;
  --color-primary-dark: rgb(var(--color-primary-dark-rgb));
  --color-primary-500-rgb: 72, 161, 238;
  --color-primary-500: rgb(var(--color-primary-500-rgb));
  --color-primary-700-rgb: 48, 107, 159;
  --color-primary-700: rgb(var(--color-primary-700-rgb));
  --color-warn-rgb: 225, 82, 65;
  --color-warn: rgb(var(--color-warn-rgb));
  --color-warn-dark-rgb: 208, 2, 27;
  --color-warn-dark: rgb(var(--color-warn-dark-rgb));
  --color-warn-500-rgb: 225, 82, 65;
  --color-warn-500: rgb(var(--color-warn-500-rgb));
  --color-warn-700-rgb: 208, 2, 27;
  --color-warn-700: rgb(var(--color-warn-700-rgb));
}
:root[app-theme=auto][client-theme=dark], :root[app-theme=dark] {
  --color-theme-primary-rgb: 72, 161, 238;
  --color-theme-primary: rgb(var(--color-theme-primary-rgb));
  --color-theme-primary-dark-rgb: 48, 107, 159;
  --color-theme-primary-dark: rgb(var(--color-theme-primary-dark-rgb));
  --color-theme-primary-500-rgb: 72, 161, 238;
  --color-theme-primary-500: rgb(var(--color-theme-primary-500-rgb));
  --color-theme-primary-700-rgb: 48, 107, 159;
  --color-theme-primary-700: rgb(var(--color-theme-primary-700-rgb));
  --color-theme-warn-rgb: 225, 82, 65;
  --color-theme-warn: rgb(var(--color-theme-warn-rgb));
  --color-theme-warn-dark-rgb: 208, 2, 27;
  --color-theme-warn-dark: rgb(var(--color-theme-warn-dark-rgb));
  --color-theme-warn-500-rgb: 225, 82, 65;
  --color-theme-warn-500: rgb(var(--color-theme-warn-500-rgb));
  --color-theme-warn-700-rgb: 208, 2, 27;
  --color-theme-warn-700: rgb(var(--color-theme-warn-700-rgb));
}

:root {
  --color-imp-bg-opacity: 0.3;
  --color-imp-bg-0-rgb: 0, 0, 0, 0;
  --color-imp-bg-0: rgb(var(--color-imp-bg-0-rgb));
  --color-imp-bg-1-rgb: 45, 112, 192;
  --color-imp-bg-1: rgb(var(--color-imp-bg-1-rgb));
  --color-imp-bg-2-rgb: 2, 162, 204;
  --color-imp-bg-2: rgb(var(--color-imp-bg-2-rgb));
  --color-imp-bg-3-rgb: 0, 191, 211;
  --color-imp-bg-3: rgb(var(--color-imp-bg-3-rgb));
  --color-imp-bg-4-rgb: 88, 193, 68;
  --color-imp-bg-4: rgb(var(--color-imp-bg-4-rgb));
  --color-imp-bg-5-rgb: 156, 200, 16;
  --color-imp-bg-5: rgb(var(--color-imp-bg-5-rgb));
  --color-imp-bg-6-rgb: 209, 202, 0;
  --color-imp-bg-6: rgb(var(--color-imp-bg-6-rgb));
  --color-imp-bg-7-rgb: 252, 198, 0;
  --color-imp-bg-7: rgb(var(--color-imp-bg-7-rgb));
  --color-imp-bg-8-rgb: 245, 143, 0;
  --color-imp-bg-8: rgb(var(--color-imp-bg-8-rgb));
  --color-imp-bg-9-rgb: 241, 89, 42;
  --color-imp-bg-9: rgb(var(--color-imp-bg-9-rgb));
  --color-imp-bg-10-rgb: 231, 36, 55;
  --color-imp-bg-10: rgb(var(--color-imp-bg-10-rgb));
}

.imp-0,
.importance-0 {
  --importance-color-rgb: var(--color-text-400-rgb);
  --importance-color: rgba(var(--color-text-400-rgb), 1);
}
.imp-1,
.importance-1 {
  --importance-color-rgb: var(--color-imp-bg-1-rgb);
  --importance-color: rgba(var(--importance-color-rgb), 1);
  --importance-color-bg: rgba(
  	var(--importance-color-rgb),
  	var(--color-imp-bg-opacity)
  );
}
.imp-2,
.importance-2 {
  --importance-color-rgb: var(--color-imp-bg-2-rgb);
  --importance-color: rgba(var(--importance-color-rgb), 1);
  --importance-color-bg: rgba(
  	var(--importance-color-rgb),
  	var(--color-imp-bg-opacity)
  );
}
.imp-3,
.importance-3 {
  --importance-color-rgb: var(--color-imp-bg-3-rgb);
  --importance-color: rgba(var(--importance-color-rgb), 1);
  --importance-color-bg: rgba(
  	var(--importance-color-rgb),
  	var(--color-imp-bg-opacity)
  );
}
.imp-4,
.importance-4 {
  --importance-color-rgb: var(--color-imp-bg-4-rgb);
  --importance-color: rgba(var(--importance-color-rgb), 1);
  --importance-color-bg: rgba(
  	var(--importance-color-rgb),
  	var(--color-imp-bg-opacity)
  );
}
.imp-5,
.importance-5 {
  --importance-color-rgb: var(--color-imp-bg-5-rgb);
  --importance-color: rgba(var(--importance-color-rgb), 1);
  --importance-color-bg: rgba(
  	var(--importance-color-rgb),
  	var(--color-imp-bg-opacity)
  );
}
.imp-6,
.importance-6 {
  --importance-color-rgb: var(--color-imp-bg-6-rgb);
  --importance-color: rgba(var(--importance-color-rgb), 1);
  --importance-color-bg: rgba(
  	var(--importance-color-rgb),
  	var(--color-imp-bg-opacity)
  );
}
.imp-7,
.importance-7 {
  --importance-color-rgb: var(--color-imp-bg-7-rgb);
  --importance-color: rgba(var(--importance-color-rgb), 1);
  --importance-color-bg: rgba(
  	var(--importance-color-rgb),
  	var(--color-imp-bg-opacity)
  );
}
.imp-8,
.importance-8 {
  --importance-color-rgb: var(--color-imp-bg-8-rgb);
  --importance-color: rgba(var(--importance-color-rgb), 1);
  --importance-color-bg: rgba(
  	var(--importance-color-rgb),
  	var(--color-imp-bg-opacity)
  );
}
.imp-9,
.importance-9 {
  --importance-color-rgb: var(--color-imp-bg-9-rgb);
  --importance-color: rgba(var(--importance-color-rgb), 1);
  --importance-color-bg: rgba(
  	var(--importance-color-rgb),
  	var(--color-imp-bg-opacity)
  );
}
.imp-10,
.importance-10 {
  --importance-color-rgb: var(--color-imp-bg-10-rgb);
  --importance-color: rgba(var(--importance-color-rgb), 1);
  --importance-color-bg: rgba(
  	var(--importance-color-rgb),
  	var(--color-imp-bg-opacity)
  );
}

:root .bg-importance {
  background-color: var(--importance-color-bg);
}
:root .bg-importance-0 {
  background-color: rgba(var(--importance-color-rgb), 0);
}
:root .bg-importance-5 {
  background-color: rgba(var(--importance-color-rgb), 0.05);
}
:root .bg-importance-10 {
  background-color: rgba(var(--importance-color-rgb), 0.1);
}
:root .bg-importance-15 {
  background-color: rgba(var(--importance-color-rgb), 0.15);
}
:root .bg-importance-20 {
  background-color: rgba(var(--importance-color-rgb), 0.2);
}
:root .bg-importance-25 {
  background-color: rgba(var(--importance-color-rgb), 0.25);
}
:root .bg-importance-30 {
  background-color: rgba(var(--importance-color-rgb), 0.3);
}
:root .bg-importance-35 {
  background-color: rgba(var(--importance-color-rgb), 0.35);
}
:root .bg-importance-40 {
  background-color: rgba(var(--importance-color-rgb), 0.4);
}
:root .bg-importance-45 {
  background-color: rgba(var(--importance-color-rgb), 0.45);
}
:root .bg-importance-50 {
  background-color: rgba(var(--importance-color-rgb), 0.5);
}
:root .bg-importance-55 {
  background-color: rgba(var(--importance-color-rgb), 0.55);
}
:root .bg-importance-60 {
  background-color: rgba(var(--importance-color-rgb), 0.6);
}
:root .bg-importance-65 {
  background-color: rgba(var(--importance-color-rgb), 0.65);
}
:root .bg-importance-70 {
  background-color: rgba(var(--importance-color-rgb), 0.7);
}
:root .bg-importance-75 {
  background-color: rgba(var(--importance-color-rgb), 0.75);
}
:root .bg-importance-80 {
  background-color: rgba(var(--importance-color-rgb), 0.8);
}
:root .bg-importance-85 {
  background-color: rgba(var(--importance-color-rgb), 0.85);
}
:root .bg-importance-90 {
  background-color: rgba(var(--importance-color-rgb), 0.9);
}
:root .bg-importance-95 {
  background-color: rgba(var(--importance-color-rgb), 0.95);
}
:root .bg-importance-100 {
  background-color: rgba(var(--importance-color-rgb), 1);
}
:root .bg-importance-on-hover:hover {
  background-color: var(--importance-color-bg);
}
:root .fc-importance {
  color: var(--importance-color);
}
:root .fc-importance-as-bg {
  background-color: var(--importance-color);
}

/* 
	This is a helper map to generate all the permutations that we have
	for the background colors.

	The names of the bg colors are: <LIGHT_VALUE><DARK_VALUE>0.
*/
:root {
  --code-code-bg-rgb: 44, 44, 50;
  --code-code-bg: rgb(var(--code-code-bg-rgb));
  --code-code-text-rgb: 255, 255, 255;
  --code-code-text: rgb(var(--code-code-text-rgb));
  --color-bg-menu-backdrop-opacity: 0.2;
  --color-bg-160-rgb: 255, 255, 255;
  --color-bg-160: rgb(var(--color-bg-160-rgb));
  --color-bg-170-rgb: 255, 255, 255;
  --color-bg-170: rgb(var(--color-bg-170-rgb));
  --color-bg-180-rgb: 255, 255, 255;
  --color-bg-180: rgb(var(--color-bg-180-rgb));
  --color-bg-190-rgb: 255, 255, 255;
  --color-bg-190: rgb(var(--color-bg-190-rgb));
  --color-bg-260-rgb: 244, 245, 247;
  --color-bg-260: rgb(var(--color-bg-260-rgb));
  --color-bg-270-rgb: 244, 245, 247;
  --color-bg-270: rgb(var(--color-bg-270-rgb));
  --color-bg-280-rgb: 244, 245, 247;
  --color-bg-280: rgb(var(--color-bg-280-rgb));
  --color-bg-290-rgb: 244, 245, 247;
  --color-bg-290: rgb(var(--color-bg-290-rgb));
  --color-bg-360-rgb: 242, 241, 246;
  --color-bg-360: rgb(var(--color-bg-360-rgb));
  --color-bg-370-rgb: 242, 241, 246;
  --color-bg-370: rgb(var(--color-bg-370-rgb));
  --color-bg-380-rgb: 242, 241, 246;
  --color-bg-380: rgb(var(--color-bg-380-rgb));
  --color-bg-390-rgb: 242, 241, 246;
  --color-bg-390: rgb(var(--color-bg-390-rgb));
  --color-bg-460-rgb: 230, 230, 235;
  --color-bg-460: rgb(var(--color-bg-460-rgb));
  --color-bg-470-rgb: 230, 230, 235;
  --color-bg-470: rgb(var(--color-bg-470-rgb));
  --color-bg-480-rgb: 230, 230, 235;
  --color-bg-480: rgb(var(--color-bg-480-rgb));
  --color-bg-490-rgb: 230, 230, 235;
  --color-bg-490: rgb(var(--color-bg-490-rgb));
  --color-bg-560-rgb: 208, 212, 221;
  --color-bg-560: rgb(var(--color-bg-560-rgb));
  --color-bg-570-rgb: 208, 212, 221;
  --color-bg-570: rgb(var(--color-bg-570-rgb));
  --color-bg-580-rgb: 208, 212, 221;
  --color-bg-580: rgb(var(--color-bg-580-rgb));
  --color-bg-590-rgb: 208, 212, 221;
  --color-bg-590: rgb(var(--color-bg-590-rgb));
  --light-color-bg-160-rgb: 255, 255, 255;
  --light-color-bg-160: rgb(var(--light-color-bg-160-rgb));
  --light-color-bg-170-rgb: 255, 255, 255;
  --light-color-bg-170: rgb(var(--light-color-bg-170-rgb));
  --light-color-bg-180-rgb: 255, 255, 255;
  --light-color-bg-180: rgb(var(--light-color-bg-180-rgb));
  --light-color-bg-190-rgb: 255, 255, 255;
  --light-color-bg-190: rgb(var(--light-color-bg-190-rgb));
  --light-color-bg-260-rgb: 244, 245, 247;
  --light-color-bg-260: rgb(var(--light-color-bg-260-rgb));
  --light-color-bg-270-rgb: 244, 245, 247;
  --light-color-bg-270: rgb(var(--light-color-bg-270-rgb));
  --light-color-bg-280-rgb: 244, 245, 247;
  --light-color-bg-280: rgb(var(--light-color-bg-280-rgb));
  --light-color-bg-290-rgb: 244, 245, 247;
  --light-color-bg-290: rgb(var(--light-color-bg-290-rgb));
  --light-color-bg-360-rgb: 242, 241, 246;
  --light-color-bg-360: rgb(var(--light-color-bg-360-rgb));
  --light-color-bg-370-rgb: 242, 241, 246;
  --light-color-bg-370: rgb(var(--light-color-bg-370-rgb));
  --light-color-bg-380-rgb: 242, 241, 246;
  --light-color-bg-380: rgb(var(--light-color-bg-380-rgb));
  --light-color-bg-390-rgb: 242, 241, 246;
  --light-color-bg-390: rgb(var(--light-color-bg-390-rgb));
  --light-color-bg-460-rgb: 230, 230, 235;
  --light-color-bg-460: rgb(var(--light-color-bg-460-rgb));
  --light-color-bg-470-rgb: 230, 230, 235;
  --light-color-bg-470: rgb(var(--light-color-bg-470-rgb));
  --light-color-bg-480-rgb: 230, 230, 235;
  --light-color-bg-480: rgb(var(--light-color-bg-480-rgb));
  --light-color-bg-490-rgb: 230, 230, 235;
  --light-color-bg-490: rgb(var(--light-color-bg-490-rgb));
  --light-color-bg-560-rgb: 208, 212, 221;
  --light-color-bg-560: rgb(var(--light-color-bg-560-rgb));
  --light-color-bg-570-rgb: 208, 212, 221;
  --light-color-bg-570: rgb(var(--light-color-bg-570-rgb));
  --light-color-bg-580-rgb: 208, 212, 221;
  --light-color-bg-580: rgb(var(--light-color-bg-580-rgb));
  --light-color-bg-590-rgb: 208, 212, 221;
  --light-color-bg-590: rgb(var(--light-color-bg-590-rgb));
  --dark-color-bg-160-rgb: 62, 62, 68;
  --dark-color-bg-160: rgb(var(--dark-color-bg-160-rgb));
  --dark-color-bg-170-rgb: 44, 44, 50;
  --dark-color-bg-170: rgb(var(--dark-color-bg-170-rgb));
  --dark-color-bg-180-rgb: 28, 28, 34;
  --dark-color-bg-180: rgb(var(--dark-color-bg-180-rgb));
  --dark-color-bg-190-rgb: 10, 10, 16;
  --dark-color-bg-190: rgb(var(--dark-color-bg-190-rgb));
  --dark-color-bg-260-rgb: 62, 62, 68;
  --dark-color-bg-260: rgb(var(--dark-color-bg-260-rgb));
  --dark-color-bg-270-rgb: 44, 44, 50;
  --dark-color-bg-270: rgb(var(--dark-color-bg-270-rgb));
  --dark-color-bg-280-rgb: 28, 28, 34;
  --dark-color-bg-280: rgb(var(--dark-color-bg-280-rgb));
  --dark-color-bg-290-rgb: 10, 10, 16;
  --dark-color-bg-290: rgb(var(--dark-color-bg-290-rgb));
  --dark-color-bg-360-rgb: 62, 62, 68;
  --dark-color-bg-360: rgb(var(--dark-color-bg-360-rgb));
  --dark-color-bg-370-rgb: 44, 44, 50;
  --dark-color-bg-370: rgb(var(--dark-color-bg-370-rgb));
  --dark-color-bg-380-rgb: 28, 28, 34;
  --dark-color-bg-380: rgb(var(--dark-color-bg-380-rgb));
  --dark-color-bg-390-rgb: 10, 10, 16;
  --dark-color-bg-390: rgb(var(--dark-color-bg-390-rgb));
  --dark-color-bg-460-rgb: 62, 62, 68;
  --dark-color-bg-460: rgb(var(--dark-color-bg-460-rgb));
  --dark-color-bg-470-rgb: 44, 44, 50;
  --dark-color-bg-470: rgb(var(--dark-color-bg-470-rgb));
  --dark-color-bg-480-rgb: 28, 28, 34;
  --dark-color-bg-480: rgb(var(--dark-color-bg-480-rgb));
  --dark-color-bg-490-rgb: 10, 10, 16;
  --dark-color-bg-490: rgb(var(--dark-color-bg-490-rgb));
  --dark-color-bg-560-rgb: 62, 62, 68;
  --dark-color-bg-560: rgb(var(--dark-color-bg-560-rgb));
  --dark-color-bg-570-rgb: 44, 44, 50;
  --dark-color-bg-570: rgb(var(--dark-color-bg-570-rgb));
  --dark-color-bg-580-rgb: 28, 28, 34;
  --dark-color-bg-580: rgb(var(--dark-color-bg-580-rgb));
  --dark-color-bg-590-rgb: 10, 10, 16;
  --dark-color-bg-590: rgb(var(--dark-color-bg-590-rgb));
  --color-bg-standard-hover-rgb: 27, 42, 74, 0.04;
  --color-bg-standard-hover: rgb(var(--color-bg-standard-hover-rgb));
  --light-color-bg-standard-hover-rgb: 27, 42, 74, 0.04;
  --light-color-bg-standard-hover: rgb(var(--light-color-bg-standard-hover-rgb));
  --dark-color-bg-standard-hover-rgb: 227, 227, 227, 0.04;
  --dark-color-bg-standard-hover: rgb(var(--dark-color-bg-standard-hover-rgb));
}
:root[app-theme=auto][client-theme=dark], :root[app-theme=dark] {
  --color-bg-menu-backdrop-opacity: 0.5;
}
:root[app-theme=auto][client-theme=dark], :root[app-theme=dark] {
  --color-bg-160-rgb: 62, 62, 68;
  --color-bg-160: rgb(var(--color-bg-160-rgb));
  --color-bg-170-rgb: 44, 44, 50;
  --color-bg-170: rgb(var(--color-bg-170-rgb));
  --color-bg-180-rgb: 28, 28, 34;
  --color-bg-180: rgb(var(--color-bg-180-rgb));
  --color-bg-190-rgb: 10, 10, 16;
  --color-bg-190: rgb(var(--color-bg-190-rgb));
  --color-bg-260-rgb: 62, 62, 68;
  --color-bg-260: rgb(var(--color-bg-260-rgb));
  --color-bg-270-rgb: 44, 44, 50;
  --color-bg-270: rgb(var(--color-bg-270-rgb));
  --color-bg-280-rgb: 28, 28, 34;
  --color-bg-280: rgb(var(--color-bg-280-rgb));
  --color-bg-290-rgb: 10, 10, 16;
  --color-bg-290: rgb(var(--color-bg-290-rgb));
  --color-bg-360-rgb: 62, 62, 68;
  --color-bg-360: rgb(var(--color-bg-360-rgb));
  --color-bg-370-rgb: 44, 44, 50;
  --color-bg-370: rgb(var(--color-bg-370-rgb));
  --color-bg-380-rgb: 28, 28, 34;
  --color-bg-380: rgb(var(--color-bg-380-rgb));
  --color-bg-390-rgb: 10, 10, 16;
  --color-bg-390: rgb(var(--color-bg-390-rgb));
  --color-bg-460-rgb: 62, 62, 68;
  --color-bg-460: rgb(var(--color-bg-460-rgb));
  --color-bg-470-rgb: 44, 44, 50;
  --color-bg-470: rgb(var(--color-bg-470-rgb));
  --color-bg-480-rgb: 28, 28, 34;
  --color-bg-480: rgb(var(--color-bg-480-rgb));
  --color-bg-490-rgb: 10, 10, 16;
  --color-bg-490: rgb(var(--color-bg-490-rgb));
  --color-bg-560-rgb: 62, 62, 68;
  --color-bg-560: rgb(var(--color-bg-560-rgb));
  --color-bg-570-rgb: 44, 44, 50;
  --color-bg-570: rgb(var(--color-bg-570-rgb));
  --color-bg-580-rgb: 28, 28, 34;
  --color-bg-580: rgb(var(--color-bg-580-rgb));
  --color-bg-590-rgb: 10, 10, 16;
  --color-bg-590: rgb(var(--color-bg-590-rgb));
}
:root[app-theme=auto][client-theme=dark], :root[app-theme=dark] {
  --color-bg-standard-hover-rgb: 227, 227, 227, 0.04;
  --color-bg-standard-hover: rgb(var(--color-bg-standard-hover-rgb));
}

.bg-backdrop {
  background-color: rgba(0, 0, 0, var(--color-bg-menu-backdrop-opacity));
}
@supports (backdrop-filter: blur(8px)) or (--webkit-backdrop-filter: blur(8px)) {
  .bg-backdrop {
    backdrop-filter: blur(4px);
  }
}
@media (hover: hover) {
  :root .bg-backdrop-on-hover:hover {
    background-color: rgba(0, 0, 0, var(--color-bg-menu-backdrop-opacity));
  }
  :root .hover-parent:hover .bg-backdrop-on-hover-parent {
    background-color: rgba(0, 0, 0, var(--color-bg-menu-backdrop-opacity));
  }
}
.bg-current-color {
  background-color: currentColor;
}
.bg-white {
  background-color: rgb(255, 255, 255);
}
:root .bg-160 {
  background-color: rgb(var(--color-bg-160-rgb));
}
:root .bg-160-0 {
  background-color: rgba(var(--color-bg-160-rgb), 0);
}
@media (hover: hover) {
  :root .bg-160-0-on-hover:hover {
    background-color: rgba(var(--color-bg-160-rgb), 0);
  }
  :root .hover-parent:hover .bg-160-0-on-hover-parent {
    background-color: rgba(var(--color-bg-160-rgb), 0);
  }
}
:root .bg-160-10 {
  background-color: rgba(var(--color-bg-160-rgb), 0.1);
}
@media (hover: hover) {
  :root .bg-160-10-on-hover:hover {
    background-color: rgba(var(--color-bg-160-rgb), 0.1);
  }
  :root .hover-parent:hover .bg-160-10-on-hover-parent {
    background-color: rgba(var(--color-bg-160-rgb), 0.1);
  }
}
:root .bg-160-20 {
  background-color: rgba(var(--color-bg-160-rgb), 0.2);
}
@media (hover: hover) {
  :root .bg-160-20-on-hover:hover {
    background-color: rgba(var(--color-bg-160-rgb), 0.2);
  }
  :root .hover-parent:hover .bg-160-20-on-hover-parent {
    background-color: rgba(var(--color-bg-160-rgb), 0.2);
  }
}
:root .bg-160-30 {
  background-color: rgba(var(--color-bg-160-rgb), 0.3);
}
@media (hover: hover) {
  :root .bg-160-30-on-hover:hover {
    background-color: rgba(var(--color-bg-160-rgb), 0.3);
  }
  :root .hover-parent:hover .bg-160-30-on-hover-parent {
    background-color: rgba(var(--color-bg-160-rgb), 0.3);
  }
}
:root .bg-160-40 {
  background-color: rgba(var(--color-bg-160-rgb), 0.4);
}
@media (hover: hover) {
  :root .bg-160-40-on-hover:hover {
    background-color: rgba(var(--color-bg-160-rgb), 0.4);
  }
  :root .hover-parent:hover .bg-160-40-on-hover-parent {
    background-color: rgba(var(--color-bg-160-rgb), 0.4);
  }
}
:root .bg-160-50 {
  background-color: rgba(var(--color-bg-160-rgb), 0.5);
}
@media (hover: hover) {
  :root .bg-160-50-on-hover:hover {
    background-color: rgba(var(--color-bg-160-rgb), 0.5);
  }
  :root .hover-parent:hover .bg-160-50-on-hover-parent {
    background-color: rgba(var(--color-bg-160-rgb), 0.5);
  }
}
:root .bg-160-60 {
  background-color: rgba(var(--color-bg-160-rgb), 0.6);
}
@media (hover: hover) {
  :root .bg-160-60-on-hover:hover {
    background-color: rgba(var(--color-bg-160-rgb), 0.6);
  }
  :root .hover-parent:hover .bg-160-60-on-hover-parent {
    background-color: rgba(var(--color-bg-160-rgb), 0.6);
  }
}
:root .bg-160-70 {
  background-color: rgba(var(--color-bg-160-rgb), 0.7);
}
@media (hover: hover) {
  :root .bg-160-70-on-hover:hover {
    background-color: rgba(var(--color-bg-160-rgb), 0.7);
  }
  :root .hover-parent:hover .bg-160-70-on-hover-parent {
    background-color: rgba(var(--color-bg-160-rgb), 0.7);
  }
}
:root .bg-160-80 {
  background-color: rgba(var(--color-bg-160-rgb), 0.8);
}
@media (hover: hover) {
  :root .bg-160-80-on-hover:hover {
    background-color: rgba(var(--color-bg-160-rgb), 0.8);
  }
  :root .hover-parent:hover .bg-160-80-on-hover-parent {
    background-color: rgba(var(--color-bg-160-rgb), 0.8);
  }
}
:root .bg-160-90 {
  background-color: rgba(var(--color-bg-160-rgb), 0.9);
}
@media (hover: hover) {
  :root .bg-160-90-on-hover:hover {
    background-color: rgba(var(--color-bg-160-rgb), 0.9);
  }
  :root .hover-parent:hover .bg-160-90-on-hover-parent {
    background-color: rgba(var(--color-bg-160-rgb), 0.9);
  }
}
:root .bg-160-100 {
  background-color: rgba(var(--color-bg-160-rgb), 1);
}
@media (hover: hover) {
  :root .bg-160-100-on-hover:hover {
    background-color: rgba(var(--color-bg-160-rgb), 1);
  }
  :root .hover-parent:hover .bg-160-100-on-hover-parent {
    background-color: rgba(var(--color-bg-160-rgb), 1);
  }
}
:root .bg-160-as-fc {
  color: rgb(var(--color-bg-160-rgb));
}
:root .bg-160-as-border, :root .bg-160-as-line {
  border-color: rgb(var(--color-bg-160-rgb));
}

@media (hover: hover) {
  :root .bg-160-on-hover:hover {
    background-color: rgb(var(--color-bg-160-rgb));
  }
  :root .hover-parent:hover .bg-160-on-hover-parent {
    background-color: rgb(var(--color-bg-160-rgb));
  }
}
:root .bg-170 {
  background-color: rgb(var(--color-bg-170-rgb));
}
:root .bg-170-0 {
  background-color: rgba(var(--color-bg-170-rgb), 0);
}
@media (hover: hover) {
  :root .bg-170-0-on-hover:hover {
    background-color: rgba(var(--color-bg-170-rgb), 0);
  }
  :root .hover-parent:hover .bg-170-0-on-hover-parent {
    background-color: rgba(var(--color-bg-170-rgb), 0);
  }
}
:root .bg-170-10 {
  background-color: rgba(var(--color-bg-170-rgb), 0.1);
}
@media (hover: hover) {
  :root .bg-170-10-on-hover:hover {
    background-color: rgba(var(--color-bg-170-rgb), 0.1);
  }
  :root .hover-parent:hover .bg-170-10-on-hover-parent {
    background-color: rgba(var(--color-bg-170-rgb), 0.1);
  }
}
:root .bg-170-20 {
  background-color: rgba(var(--color-bg-170-rgb), 0.2);
}
@media (hover: hover) {
  :root .bg-170-20-on-hover:hover {
    background-color: rgba(var(--color-bg-170-rgb), 0.2);
  }
  :root .hover-parent:hover .bg-170-20-on-hover-parent {
    background-color: rgba(var(--color-bg-170-rgb), 0.2);
  }
}
:root .bg-170-30 {
  background-color: rgba(var(--color-bg-170-rgb), 0.3);
}
@media (hover: hover) {
  :root .bg-170-30-on-hover:hover {
    background-color: rgba(var(--color-bg-170-rgb), 0.3);
  }
  :root .hover-parent:hover .bg-170-30-on-hover-parent {
    background-color: rgba(var(--color-bg-170-rgb), 0.3);
  }
}
:root .bg-170-40 {
  background-color: rgba(var(--color-bg-170-rgb), 0.4);
}
@media (hover: hover) {
  :root .bg-170-40-on-hover:hover {
    background-color: rgba(var(--color-bg-170-rgb), 0.4);
  }
  :root .hover-parent:hover .bg-170-40-on-hover-parent {
    background-color: rgba(var(--color-bg-170-rgb), 0.4);
  }
}
:root .bg-170-50 {
  background-color: rgba(var(--color-bg-170-rgb), 0.5);
}
@media (hover: hover) {
  :root .bg-170-50-on-hover:hover {
    background-color: rgba(var(--color-bg-170-rgb), 0.5);
  }
  :root .hover-parent:hover .bg-170-50-on-hover-parent {
    background-color: rgba(var(--color-bg-170-rgb), 0.5);
  }
}
:root .bg-170-60 {
  background-color: rgba(var(--color-bg-170-rgb), 0.6);
}
@media (hover: hover) {
  :root .bg-170-60-on-hover:hover {
    background-color: rgba(var(--color-bg-170-rgb), 0.6);
  }
  :root .hover-parent:hover .bg-170-60-on-hover-parent {
    background-color: rgba(var(--color-bg-170-rgb), 0.6);
  }
}
:root .bg-170-70 {
  background-color: rgba(var(--color-bg-170-rgb), 0.7);
}
@media (hover: hover) {
  :root .bg-170-70-on-hover:hover {
    background-color: rgba(var(--color-bg-170-rgb), 0.7);
  }
  :root .hover-parent:hover .bg-170-70-on-hover-parent {
    background-color: rgba(var(--color-bg-170-rgb), 0.7);
  }
}
:root .bg-170-80 {
  background-color: rgba(var(--color-bg-170-rgb), 0.8);
}
@media (hover: hover) {
  :root .bg-170-80-on-hover:hover {
    background-color: rgba(var(--color-bg-170-rgb), 0.8);
  }
  :root .hover-parent:hover .bg-170-80-on-hover-parent {
    background-color: rgba(var(--color-bg-170-rgb), 0.8);
  }
}
:root .bg-170-90 {
  background-color: rgba(var(--color-bg-170-rgb), 0.9);
}
@media (hover: hover) {
  :root .bg-170-90-on-hover:hover {
    background-color: rgba(var(--color-bg-170-rgb), 0.9);
  }
  :root .hover-parent:hover .bg-170-90-on-hover-parent {
    background-color: rgba(var(--color-bg-170-rgb), 0.9);
  }
}
:root .bg-170-100 {
  background-color: rgba(var(--color-bg-170-rgb), 1);
}
@media (hover: hover) {
  :root .bg-170-100-on-hover:hover {
    background-color: rgba(var(--color-bg-170-rgb), 1);
  }
  :root .hover-parent:hover .bg-170-100-on-hover-parent {
    background-color: rgba(var(--color-bg-170-rgb), 1);
  }
}
:root .bg-170-as-fc {
  color: rgb(var(--color-bg-170-rgb));
}
:root .bg-170-as-border, :root .bg-170-as-line {
  border-color: rgb(var(--color-bg-170-rgb));
}

@media (hover: hover) {
  :root .bg-170-on-hover:hover {
    background-color: rgb(var(--color-bg-170-rgb));
  }
  :root .hover-parent:hover .bg-170-on-hover-parent {
    background-color: rgb(var(--color-bg-170-rgb));
  }
}
:root .bg-180 {
  background-color: rgb(var(--color-bg-180-rgb));
}
:root .bg-180-0 {
  background-color: rgba(var(--color-bg-180-rgb), 0);
}
@media (hover: hover) {
  :root .bg-180-0-on-hover:hover {
    background-color: rgba(var(--color-bg-180-rgb), 0);
  }
  :root .hover-parent:hover .bg-180-0-on-hover-parent {
    background-color: rgba(var(--color-bg-180-rgb), 0);
  }
}
:root .bg-180-10 {
  background-color: rgba(var(--color-bg-180-rgb), 0.1);
}
@media (hover: hover) {
  :root .bg-180-10-on-hover:hover {
    background-color: rgba(var(--color-bg-180-rgb), 0.1);
  }
  :root .hover-parent:hover .bg-180-10-on-hover-parent {
    background-color: rgba(var(--color-bg-180-rgb), 0.1);
  }
}
:root .bg-180-20 {
  background-color: rgba(var(--color-bg-180-rgb), 0.2);
}
@media (hover: hover) {
  :root .bg-180-20-on-hover:hover {
    background-color: rgba(var(--color-bg-180-rgb), 0.2);
  }
  :root .hover-parent:hover .bg-180-20-on-hover-parent {
    background-color: rgba(var(--color-bg-180-rgb), 0.2);
  }
}
:root .bg-180-30 {
  background-color: rgba(var(--color-bg-180-rgb), 0.3);
}
@media (hover: hover) {
  :root .bg-180-30-on-hover:hover {
    background-color: rgba(var(--color-bg-180-rgb), 0.3);
  }
  :root .hover-parent:hover .bg-180-30-on-hover-parent {
    background-color: rgba(var(--color-bg-180-rgb), 0.3);
  }
}
:root .bg-180-40 {
  background-color: rgba(var(--color-bg-180-rgb), 0.4);
}
@media (hover: hover) {
  :root .bg-180-40-on-hover:hover {
    background-color: rgba(var(--color-bg-180-rgb), 0.4);
  }
  :root .hover-parent:hover .bg-180-40-on-hover-parent {
    background-color: rgba(var(--color-bg-180-rgb), 0.4);
  }
}
:root .bg-180-50 {
  background-color: rgba(var(--color-bg-180-rgb), 0.5);
}
@media (hover: hover) {
  :root .bg-180-50-on-hover:hover {
    background-color: rgba(var(--color-bg-180-rgb), 0.5);
  }
  :root .hover-parent:hover .bg-180-50-on-hover-parent {
    background-color: rgba(var(--color-bg-180-rgb), 0.5);
  }
}
:root .bg-180-60 {
  background-color: rgba(var(--color-bg-180-rgb), 0.6);
}
@media (hover: hover) {
  :root .bg-180-60-on-hover:hover {
    background-color: rgba(var(--color-bg-180-rgb), 0.6);
  }
  :root .hover-parent:hover .bg-180-60-on-hover-parent {
    background-color: rgba(var(--color-bg-180-rgb), 0.6);
  }
}
:root .bg-180-70 {
  background-color: rgba(var(--color-bg-180-rgb), 0.7);
}
@media (hover: hover) {
  :root .bg-180-70-on-hover:hover {
    background-color: rgba(var(--color-bg-180-rgb), 0.7);
  }
  :root .hover-parent:hover .bg-180-70-on-hover-parent {
    background-color: rgba(var(--color-bg-180-rgb), 0.7);
  }
}
:root .bg-180-80 {
  background-color: rgba(var(--color-bg-180-rgb), 0.8);
}
@media (hover: hover) {
  :root .bg-180-80-on-hover:hover {
    background-color: rgba(var(--color-bg-180-rgb), 0.8);
  }
  :root .hover-parent:hover .bg-180-80-on-hover-parent {
    background-color: rgba(var(--color-bg-180-rgb), 0.8);
  }
}
:root .bg-180-90 {
  background-color: rgba(var(--color-bg-180-rgb), 0.9);
}
@media (hover: hover) {
  :root .bg-180-90-on-hover:hover {
    background-color: rgba(var(--color-bg-180-rgb), 0.9);
  }
  :root .hover-parent:hover .bg-180-90-on-hover-parent {
    background-color: rgba(var(--color-bg-180-rgb), 0.9);
  }
}
:root .bg-180-100 {
  background-color: rgba(var(--color-bg-180-rgb), 1);
}
@media (hover: hover) {
  :root .bg-180-100-on-hover:hover {
    background-color: rgba(var(--color-bg-180-rgb), 1);
  }
  :root .hover-parent:hover .bg-180-100-on-hover-parent {
    background-color: rgba(var(--color-bg-180-rgb), 1);
  }
}
:root .bg-180-as-fc {
  color: rgb(var(--color-bg-180-rgb));
}
:root .bg-180-as-border, :root .bg-180-as-line {
  border-color: rgb(var(--color-bg-180-rgb));
}

@media (hover: hover) {
  :root .bg-180-on-hover:hover {
    background-color: rgb(var(--color-bg-180-rgb));
  }
  :root .hover-parent:hover .bg-180-on-hover-parent {
    background-color: rgb(var(--color-bg-180-rgb));
  }
}
:root .bg-190 {
  background-color: rgb(var(--color-bg-190-rgb));
}
:root .bg-190-0 {
  background-color: rgba(var(--color-bg-190-rgb), 0);
}
@media (hover: hover) {
  :root .bg-190-0-on-hover:hover {
    background-color: rgba(var(--color-bg-190-rgb), 0);
  }
  :root .hover-parent:hover .bg-190-0-on-hover-parent {
    background-color: rgba(var(--color-bg-190-rgb), 0);
  }
}
:root .bg-190-10 {
  background-color: rgba(var(--color-bg-190-rgb), 0.1);
}
@media (hover: hover) {
  :root .bg-190-10-on-hover:hover {
    background-color: rgba(var(--color-bg-190-rgb), 0.1);
  }
  :root .hover-parent:hover .bg-190-10-on-hover-parent {
    background-color: rgba(var(--color-bg-190-rgb), 0.1);
  }
}
:root .bg-190-20 {
  background-color: rgba(var(--color-bg-190-rgb), 0.2);
}
@media (hover: hover) {
  :root .bg-190-20-on-hover:hover {
    background-color: rgba(var(--color-bg-190-rgb), 0.2);
  }
  :root .hover-parent:hover .bg-190-20-on-hover-parent {
    background-color: rgba(var(--color-bg-190-rgb), 0.2);
  }
}
:root .bg-190-30 {
  background-color: rgba(var(--color-bg-190-rgb), 0.3);
}
@media (hover: hover) {
  :root .bg-190-30-on-hover:hover {
    background-color: rgba(var(--color-bg-190-rgb), 0.3);
  }
  :root .hover-parent:hover .bg-190-30-on-hover-parent {
    background-color: rgba(var(--color-bg-190-rgb), 0.3);
  }
}
:root .bg-190-40 {
  background-color: rgba(var(--color-bg-190-rgb), 0.4);
}
@media (hover: hover) {
  :root .bg-190-40-on-hover:hover {
    background-color: rgba(var(--color-bg-190-rgb), 0.4);
  }
  :root .hover-parent:hover .bg-190-40-on-hover-parent {
    background-color: rgba(var(--color-bg-190-rgb), 0.4);
  }
}
:root .bg-190-50 {
  background-color: rgba(var(--color-bg-190-rgb), 0.5);
}
@media (hover: hover) {
  :root .bg-190-50-on-hover:hover {
    background-color: rgba(var(--color-bg-190-rgb), 0.5);
  }
  :root .hover-parent:hover .bg-190-50-on-hover-parent {
    background-color: rgba(var(--color-bg-190-rgb), 0.5);
  }
}
:root .bg-190-60 {
  background-color: rgba(var(--color-bg-190-rgb), 0.6);
}
@media (hover: hover) {
  :root .bg-190-60-on-hover:hover {
    background-color: rgba(var(--color-bg-190-rgb), 0.6);
  }
  :root .hover-parent:hover .bg-190-60-on-hover-parent {
    background-color: rgba(var(--color-bg-190-rgb), 0.6);
  }
}
:root .bg-190-70 {
  background-color: rgba(var(--color-bg-190-rgb), 0.7);
}
@media (hover: hover) {
  :root .bg-190-70-on-hover:hover {
    background-color: rgba(var(--color-bg-190-rgb), 0.7);
  }
  :root .hover-parent:hover .bg-190-70-on-hover-parent {
    background-color: rgba(var(--color-bg-190-rgb), 0.7);
  }
}
:root .bg-190-80 {
  background-color: rgba(var(--color-bg-190-rgb), 0.8);
}
@media (hover: hover) {
  :root .bg-190-80-on-hover:hover {
    background-color: rgba(var(--color-bg-190-rgb), 0.8);
  }
  :root .hover-parent:hover .bg-190-80-on-hover-parent {
    background-color: rgba(var(--color-bg-190-rgb), 0.8);
  }
}
:root .bg-190-90 {
  background-color: rgba(var(--color-bg-190-rgb), 0.9);
}
@media (hover: hover) {
  :root .bg-190-90-on-hover:hover {
    background-color: rgba(var(--color-bg-190-rgb), 0.9);
  }
  :root .hover-parent:hover .bg-190-90-on-hover-parent {
    background-color: rgba(var(--color-bg-190-rgb), 0.9);
  }
}
:root .bg-190-100 {
  background-color: rgba(var(--color-bg-190-rgb), 1);
}
@media (hover: hover) {
  :root .bg-190-100-on-hover:hover {
    background-color: rgba(var(--color-bg-190-rgb), 1);
  }
  :root .hover-parent:hover .bg-190-100-on-hover-parent {
    background-color: rgba(var(--color-bg-190-rgb), 1);
  }
}
:root .bg-190-as-fc {
  color: rgb(var(--color-bg-190-rgb));
}
:root .bg-190-as-border, :root .bg-190-as-line {
  border-color: rgb(var(--color-bg-190-rgb));
}

@media (hover: hover) {
  :root .bg-190-on-hover:hover {
    background-color: rgb(var(--color-bg-190-rgb));
  }
  :root .hover-parent:hover .bg-190-on-hover-parent {
    background-color: rgb(var(--color-bg-190-rgb));
  }
}
:root .bg-260 {
  background-color: rgb(var(--color-bg-260-rgb));
}
:root .bg-260-0 {
  background-color: rgba(var(--color-bg-260-rgb), 0);
}
@media (hover: hover) {
  :root .bg-260-0-on-hover:hover {
    background-color: rgba(var(--color-bg-260-rgb), 0);
  }
  :root .hover-parent:hover .bg-260-0-on-hover-parent {
    background-color: rgba(var(--color-bg-260-rgb), 0);
  }
}
:root .bg-260-10 {
  background-color: rgba(var(--color-bg-260-rgb), 0.1);
}
@media (hover: hover) {
  :root .bg-260-10-on-hover:hover {
    background-color: rgba(var(--color-bg-260-rgb), 0.1);
  }
  :root .hover-parent:hover .bg-260-10-on-hover-parent {
    background-color: rgba(var(--color-bg-260-rgb), 0.1);
  }
}
:root .bg-260-20 {
  background-color: rgba(var(--color-bg-260-rgb), 0.2);
}
@media (hover: hover) {
  :root .bg-260-20-on-hover:hover {
    background-color: rgba(var(--color-bg-260-rgb), 0.2);
  }
  :root .hover-parent:hover .bg-260-20-on-hover-parent {
    background-color: rgba(var(--color-bg-260-rgb), 0.2);
  }
}
:root .bg-260-30 {
  background-color: rgba(var(--color-bg-260-rgb), 0.3);
}
@media (hover: hover) {
  :root .bg-260-30-on-hover:hover {
    background-color: rgba(var(--color-bg-260-rgb), 0.3);
  }
  :root .hover-parent:hover .bg-260-30-on-hover-parent {
    background-color: rgba(var(--color-bg-260-rgb), 0.3);
  }
}
:root .bg-260-40 {
  background-color: rgba(var(--color-bg-260-rgb), 0.4);
}
@media (hover: hover) {
  :root .bg-260-40-on-hover:hover {
    background-color: rgba(var(--color-bg-260-rgb), 0.4);
  }
  :root .hover-parent:hover .bg-260-40-on-hover-parent {
    background-color: rgba(var(--color-bg-260-rgb), 0.4);
  }
}
:root .bg-260-50 {
  background-color: rgba(var(--color-bg-260-rgb), 0.5);
}
@media (hover: hover) {
  :root .bg-260-50-on-hover:hover {
    background-color: rgba(var(--color-bg-260-rgb), 0.5);
  }
  :root .hover-parent:hover .bg-260-50-on-hover-parent {
    background-color: rgba(var(--color-bg-260-rgb), 0.5);
  }
}
:root .bg-260-60 {
  background-color: rgba(var(--color-bg-260-rgb), 0.6);
}
@media (hover: hover) {
  :root .bg-260-60-on-hover:hover {
    background-color: rgba(var(--color-bg-260-rgb), 0.6);
  }
  :root .hover-parent:hover .bg-260-60-on-hover-parent {
    background-color: rgba(var(--color-bg-260-rgb), 0.6);
  }
}
:root .bg-260-70 {
  background-color: rgba(var(--color-bg-260-rgb), 0.7);
}
@media (hover: hover) {
  :root .bg-260-70-on-hover:hover {
    background-color: rgba(var(--color-bg-260-rgb), 0.7);
  }
  :root .hover-parent:hover .bg-260-70-on-hover-parent {
    background-color: rgba(var(--color-bg-260-rgb), 0.7);
  }
}
:root .bg-260-80 {
  background-color: rgba(var(--color-bg-260-rgb), 0.8);
}
@media (hover: hover) {
  :root .bg-260-80-on-hover:hover {
    background-color: rgba(var(--color-bg-260-rgb), 0.8);
  }
  :root .hover-parent:hover .bg-260-80-on-hover-parent {
    background-color: rgba(var(--color-bg-260-rgb), 0.8);
  }
}
:root .bg-260-90 {
  background-color: rgba(var(--color-bg-260-rgb), 0.9);
}
@media (hover: hover) {
  :root .bg-260-90-on-hover:hover {
    background-color: rgba(var(--color-bg-260-rgb), 0.9);
  }
  :root .hover-parent:hover .bg-260-90-on-hover-parent {
    background-color: rgba(var(--color-bg-260-rgb), 0.9);
  }
}
:root .bg-260-100 {
  background-color: rgba(var(--color-bg-260-rgb), 1);
}
@media (hover: hover) {
  :root .bg-260-100-on-hover:hover {
    background-color: rgba(var(--color-bg-260-rgb), 1);
  }
  :root .hover-parent:hover .bg-260-100-on-hover-parent {
    background-color: rgba(var(--color-bg-260-rgb), 1);
  }
}
:root .bg-260-as-fc {
  color: rgb(var(--color-bg-260-rgb));
}
:root .bg-260-as-border, :root .bg-260-as-line {
  border-color: rgb(var(--color-bg-260-rgb));
}

@media (hover: hover) {
  :root .bg-260-on-hover:hover {
    background-color: rgb(var(--color-bg-260-rgb));
  }
  :root .hover-parent:hover .bg-260-on-hover-parent {
    background-color: rgb(var(--color-bg-260-rgb));
  }
}
:root .bg-270 {
  background-color: rgb(var(--color-bg-270-rgb));
}
:root .bg-270-0 {
  background-color: rgba(var(--color-bg-270-rgb), 0);
}
@media (hover: hover) {
  :root .bg-270-0-on-hover:hover {
    background-color: rgba(var(--color-bg-270-rgb), 0);
  }
  :root .hover-parent:hover .bg-270-0-on-hover-parent {
    background-color: rgba(var(--color-bg-270-rgb), 0);
  }
}
:root .bg-270-10 {
  background-color: rgba(var(--color-bg-270-rgb), 0.1);
}
@media (hover: hover) {
  :root .bg-270-10-on-hover:hover {
    background-color: rgba(var(--color-bg-270-rgb), 0.1);
  }
  :root .hover-parent:hover .bg-270-10-on-hover-parent {
    background-color: rgba(var(--color-bg-270-rgb), 0.1);
  }
}
:root .bg-270-20 {
  background-color: rgba(var(--color-bg-270-rgb), 0.2);
}
@media (hover: hover) {
  :root .bg-270-20-on-hover:hover {
    background-color: rgba(var(--color-bg-270-rgb), 0.2);
  }
  :root .hover-parent:hover .bg-270-20-on-hover-parent {
    background-color: rgba(var(--color-bg-270-rgb), 0.2);
  }
}
:root .bg-270-30 {
  background-color: rgba(var(--color-bg-270-rgb), 0.3);
}
@media (hover: hover) {
  :root .bg-270-30-on-hover:hover {
    background-color: rgba(var(--color-bg-270-rgb), 0.3);
  }
  :root .hover-parent:hover .bg-270-30-on-hover-parent {
    background-color: rgba(var(--color-bg-270-rgb), 0.3);
  }
}
:root .bg-270-40 {
  background-color: rgba(var(--color-bg-270-rgb), 0.4);
}
@media (hover: hover) {
  :root .bg-270-40-on-hover:hover {
    background-color: rgba(var(--color-bg-270-rgb), 0.4);
  }
  :root .hover-parent:hover .bg-270-40-on-hover-parent {
    background-color: rgba(var(--color-bg-270-rgb), 0.4);
  }
}
:root .bg-270-50 {
  background-color: rgba(var(--color-bg-270-rgb), 0.5);
}
@media (hover: hover) {
  :root .bg-270-50-on-hover:hover {
    background-color: rgba(var(--color-bg-270-rgb), 0.5);
  }
  :root .hover-parent:hover .bg-270-50-on-hover-parent {
    background-color: rgba(var(--color-bg-270-rgb), 0.5);
  }
}
:root .bg-270-60 {
  background-color: rgba(var(--color-bg-270-rgb), 0.6);
}
@media (hover: hover) {
  :root .bg-270-60-on-hover:hover {
    background-color: rgba(var(--color-bg-270-rgb), 0.6);
  }
  :root .hover-parent:hover .bg-270-60-on-hover-parent {
    background-color: rgba(var(--color-bg-270-rgb), 0.6);
  }
}
:root .bg-270-70 {
  background-color: rgba(var(--color-bg-270-rgb), 0.7);
}
@media (hover: hover) {
  :root .bg-270-70-on-hover:hover {
    background-color: rgba(var(--color-bg-270-rgb), 0.7);
  }
  :root .hover-parent:hover .bg-270-70-on-hover-parent {
    background-color: rgba(var(--color-bg-270-rgb), 0.7);
  }
}
:root .bg-270-80 {
  background-color: rgba(var(--color-bg-270-rgb), 0.8);
}
@media (hover: hover) {
  :root .bg-270-80-on-hover:hover {
    background-color: rgba(var(--color-bg-270-rgb), 0.8);
  }
  :root .hover-parent:hover .bg-270-80-on-hover-parent {
    background-color: rgba(var(--color-bg-270-rgb), 0.8);
  }
}
:root .bg-270-90 {
  background-color: rgba(var(--color-bg-270-rgb), 0.9);
}
@media (hover: hover) {
  :root .bg-270-90-on-hover:hover {
    background-color: rgba(var(--color-bg-270-rgb), 0.9);
  }
  :root .hover-parent:hover .bg-270-90-on-hover-parent {
    background-color: rgba(var(--color-bg-270-rgb), 0.9);
  }
}
:root .bg-270-100 {
  background-color: rgba(var(--color-bg-270-rgb), 1);
}
@media (hover: hover) {
  :root .bg-270-100-on-hover:hover {
    background-color: rgba(var(--color-bg-270-rgb), 1);
  }
  :root .hover-parent:hover .bg-270-100-on-hover-parent {
    background-color: rgba(var(--color-bg-270-rgb), 1);
  }
}
:root .bg-270-as-fc {
  color: rgb(var(--color-bg-270-rgb));
}
:root .bg-270-as-border, :root .bg-270-as-line {
  border-color: rgb(var(--color-bg-270-rgb));
}

@media (hover: hover) {
  :root .bg-270-on-hover:hover {
    background-color: rgb(var(--color-bg-270-rgb));
  }
  :root .hover-parent:hover .bg-270-on-hover-parent {
    background-color: rgb(var(--color-bg-270-rgb));
  }
}
:root .bg-280 {
  background-color: rgb(var(--color-bg-280-rgb));
}
:root .bg-280-0 {
  background-color: rgba(var(--color-bg-280-rgb), 0);
}
@media (hover: hover) {
  :root .bg-280-0-on-hover:hover {
    background-color: rgba(var(--color-bg-280-rgb), 0);
  }
  :root .hover-parent:hover .bg-280-0-on-hover-parent {
    background-color: rgba(var(--color-bg-280-rgb), 0);
  }
}
:root .bg-280-10 {
  background-color: rgba(var(--color-bg-280-rgb), 0.1);
}
@media (hover: hover) {
  :root .bg-280-10-on-hover:hover {
    background-color: rgba(var(--color-bg-280-rgb), 0.1);
  }
  :root .hover-parent:hover .bg-280-10-on-hover-parent {
    background-color: rgba(var(--color-bg-280-rgb), 0.1);
  }
}
:root .bg-280-20 {
  background-color: rgba(var(--color-bg-280-rgb), 0.2);
}
@media (hover: hover) {
  :root .bg-280-20-on-hover:hover {
    background-color: rgba(var(--color-bg-280-rgb), 0.2);
  }
  :root .hover-parent:hover .bg-280-20-on-hover-parent {
    background-color: rgba(var(--color-bg-280-rgb), 0.2);
  }
}
:root .bg-280-30 {
  background-color: rgba(var(--color-bg-280-rgb), 0.3);
}
@media (hover: hover) {
  :root .bg-280-30-on-hover:hover {
    background-color: rgba(var(--color-bg-280-rgb), 0.3);
  }
  :root .hover-parent:hover .bg-280-30-on-hover-parent {
    background-color: rgba(var(--color-bg-280-rgb), 0.3);
  }
}
:root .bg-280-40 {
  background-color: rgba(var(--color-bg-280-rgb), 0.4);
}
@media (hover: hover) {
  :root .bg-280-40-on-hover:hover {
    background-color: rgba(var(--color-bg-280-rgb), 0.4);
  }
  :root .hover-parent:hover .bg-280-40-on-hover-parent {
    background-color: rgba(var(--color-bg-280-rgb), 0.4);
  }
}
:root .bg-280-50 {
  background-color: rgba(var(--color-bg-280-rgb), 0.5);
}
@media (hover: hover) {
  :root .bg-280-50-on-hover:hover {
    background-color: rgba(var(--color-bg-280-rgb), 0.5);
  }
  :root .hover-parent:hover .bg-280-50-on-hover-parent {
    background-color: rgba(var(--color-bg-280-rgb), 0.5);
  }
}
:root .bg-280-60 {
  background-color: rgba(var(--color-bg-280-rgb), 0.6);
}
@media (hover: hover) {
  :root .bg-280-60-on-hover:hover {
    background-color: rgba(var(--color-bg-280-rgb), 0.6);
  }
  :root .hover-parent:hover .bg-280-60-on-hover-parent {
    background-color: rgba(var(--color-bg-280-rgb), 0.6);
  }
}
:root .bg-280-70 {
  background-color: rgba(var(--color-bg-280-rgb), 0.7);
}
@media (hover: hover) {
  :root .bg-280-70-on-hover:hover {
    background-color: rgba(var(--color-bg-280-rgb), 0.7);
  }
  :root .hover-parent:hover .bg-280-70-on-hover-parent {
    background-color: rgba(var(--color-bg-280-rgb), 0.7);
  }
}
:root .bg-280-80 {
  background-color: rgba(var(--color-bg-280-rgb), 0.8);
}
@media (hover: hover) {
  :root .bg-280-80-on-hover:hover {
    background-color: rgba(var(--color-bg-280-rgb), 0.8);
  }
  :root .hover-parent:hover .bg-280-80-on-hover-parent {
    background-color: rgba(var(--color-bg-280-rgb), 0.8);
  }
}
:root .bg-280-90 {
  background-color: rgba(var(--color-bg-280-rgb), 0.9);
}
@media (hover: hover) {
  :root .bg-280-90-on-hover:hover {
    background-color: rgba(var(--color-bg-280-rgb), 0.9);
  }
  :root .hover-parent:hover .bg-280-90-on-hover-parent {
    background-color: rgba(var(--color-bg-280-rgb), 0.9);
  }
}
:root .bg-280-100 {
  background-color: rgba(var(--color-bg-280-rgb), 1);
}
@media (hover: hover) {
  :root .bg-280-100-on-hover:hover {
    background-color: rgba(var(--color-bg-280-rgb), 1);
  }
  :root .hover-parent:hover .bg-280-100-on-hover-parent {
    background-color: rgba(var(--color-bg-280-rgb), 1);
  }
}
:root .bg-280-as-fc {
  color: rgb(var(--color-bg-280-rgb));
}
:root .bg-280-as-border, :root .bg-280-as-line {
  border-color: rgb(var(--color-bg-280-rgb));
}

@media (hover: hover) {
  :root .bg-280-on-hover:hover {
    background-color: rgb(var(--color-bg-280-rgb));
  }
  :root .hover-parent:hover .bg-280-on-hover-parent {
    background-color: rgb(var(--color-bg-280-rgb));
  }
}
:root .bg-290 {
  background-color: rgb(var(--color-bg-290-rgb));
}
:root .bg-290-0 {
  background-color: rgba(var(--color-bg-290-rgb), 0);
}
@media (hover: hover) {
  :root .bg-290-0-on-hover:hover {
    background-color: rgba(var(--color-bg-290-rgb), 0);
  }
  :root .hover-parent:hover .bg-290-0-on-hover-parent {
    background-color: rgba(var(--color-bg-290-rgb), 0);
  }
}
:root .bg-290-10 {
  background-color: rgba(var(--color-bg-290-rgb), 0.1);
}
@media (hover: hover) {
  :root .bg-290-10-on-hover:hover {
    background-color: rgba(var(--color-bg-290-rgb), 0.1);
  }
  :root .hover-parent:hover .bg-290-10-on-hover-parent {
    background-color: rgba(var(--color-bg-290-rgb), 0.1);
  }
}
:root .bg-290-20 {
  background-color: rgba(var(--color-bg-290-rgb), 0.2);
}
@media (hover: hover) {
  :root .bg-290-20-on-hover:hover {
    background-color: rgba(var(--color-bg-290-rgb), 0.2);
  }
  :root .hover-parent:hover .bg-290-20-on-hover-parent {
    background-color: rgba(var(--color-bg-290-rgb), 0.2);
  }
}
:root .bg-290-30 {
  background-color: rgba(var(--color-bg-290-rgb), 0.3);
}
@media (hover: hover) {
  :root .bg-290-30-on-hover:hover {
    background-color: rgba(var(--color-bg-290-rgb), 0.3);
  }
  :root .hover-parent:hover .bg-290-30-on-hover-parent {
    background-color: rgba(var(--color-bg-290-rgb), 0.3);
  }
}
:root .bg-290-40 {
  background-color: rgba(var(--color-bg-290-rgb), 0.4);
}
@media (hover: hover) {
  :root .bg-290-40-on-hover:hover {
    background-color: rgba(var(--color-bg-290-rgb), 0.4);
  }
  :root .hover-parent:hover .bg-290-40-on-hover-parent {
    background-color: rgba(var(--color-bg-290-rgb), 0.4);
  }
}
:root .bg-290-50 {
  background-color: rgba(var(--color-bg-290-rgb), 0.5);
}
@media (hover: hover) {
  :root .bg-290-50-on-hover:hover {
    background-color: rgba(var(--color-bg-290-rgb), 0.5);
  }
  :root .hover-parent:hover .bg-290-50-on-hover-parent {
    background-color: rgba(var(--color-bg-290-rgb), 0.5);
  }
}
:root .bg-290-60 {
  background-color: rgba(var(--color-bg-290-rgb), 0.6);
}
@media (hover: hover) {
  :root .bg-290-60-on-hover:hover {
    background-color: rgba(var(--color-bg-290-rgb), 0.6);
  }
  :root .hover-parent:hover .bg-290-60-on-hover-parent {
    background-color: rgba(var(--color-bg-290-rgb), 0.6);
  }
}
:root .bg-290-70 {
  background-color: rgba(var(--color-bg-290-rgb), 0.7);
}
@media (hover: hover) {
  :root .bg-290-70-on-hover:hover {
    background-color: rgba(var(--color-bg-290-rgb), 0.7);
  }
  :root .hover-parent:hover .bg-290-70-on-hover-parent {
    background-color: rgba(var(--color-bg-290-rgb), 0.7);
  }
}
:root .bg-290-80 {
  background-color: rgba(var(--color-bg-290-rgb), 0.8);
}
@media (hover: hover) {
  :root .bg-290-80-on-hover:hover {
    background-color: rgba(var(--color-bg-290-rgb), 0.8);
  }
  :root .hover-parent:hover .bg-290-80-on-hover-parent {
    background-color: rgba(var(--color-bg-290-rgb), 0.8);
  }
}
:root .bg-290-90 {
  background-color: rgba(var(--color-bg-290-rgb), 0.9);
}
@media (hover: hover) {
  :root .bg-290-90-on-hover:hover {
    background-color: rgba(var(--color-bg-290-rgb), 0.9);
  }
  :root .hover-parent:hover .bg-290-90-on-hover-parent {
    background-color: rgba(var(--color-bg-290-rgb), 0.9);
  }
}
:root .bg-290-100 {
  background-color: rgba(var(--color-bg-290-rgb), 1);
}
@media (hover: hover) {
  :root .bg-290-100-on-hover:hover {
    background-color: rgba(var(--color-bg-290-rgb), 1);
  }
  :root .hover-parent:hover .bg-290-100-on-hover-parent {
    background-color: rgba(var(--color-bg-290-rgb), 1);
  }
}
:root .bg-290-as-fc {
  color: rgb(var(--color-bg-290-rgb));
}
:root .bg-290-as-border, :root .bg-290-as-line {
  border-color: rgb(var(--color-bg-290-rgb));
}

@media (hover: hover) {
  :root .bg-290-on-hover:hover {
    background-color: rgb(var(--color-bg-290-rgb));
  }
  :root .hover-parent:hover .bg-290-on-hover-parent {
    background-color: rgb(var(--color-bg-290-rgb));
  }
}
:root .bg-360 {
  background-color: rgb(var(--color-bg-360-rgb));
}
:root .bg-360-0 {
  background-color: rgba(var(--color-bg-360-rgb), 0);
}
@media (hover: hover) {
  :root .bg-360-0-on-hover:hover {
    background-color: rgba(var(--color-bg-360-rgb), 0);
  }
  :root .hover-parent:hover .bg-360-0-on-hover-parent {
    background-color: rgba(var(--color-bg-360-rgb), 0);
  }
}
:root .bg-360-10 {
  background-color: rgba(var(--color-bg-360-rgb), 0.1);
}
@media (hover: hover) {
  :root .bg-360-10-on-hover:hover {
    background-color: rgba(var(--color-bg-360-rgb), 0.1);
  }
  :root .hover-parent:hover .bg-360-10-on-hover-parent {
    background-color: rgba(var(--color-bg-360-rgb), 0.1);
  }
}
:root .bg-360-20 {
  background-color: rgba(var(--color-bg-360-rgb), 0.2);
}
@media (hover: hover) {
  :root .bg-360-20-on-hover:hover {
    background-color: rgba(var(--color-bg-360-rgb), 0.2);
  }
  :root .hover-parent:hover .bg-360-20-on-hover-parent {
    background-color: rgba(var(--color-bg-360-rgb), 0.2);
  }
}
:root .bg-360-30 {
  background-color: rgba(var(--color-bg-360-rgb), 0.3);
}
@media (hover: hover) {
  :root .bg-360-30-on-hover:hover {
    background-color: rgba(var(--color-bg-360-rgb), 0.3);
  }
  :root .hover-parent:hover .bg-360-30-on-hover-parent {
    background-color: rgba(var(--color-bg-360-rgb), 0.3);
  }
}
:root .bg-360-40 {
  background-color: rgba(var(--color-bg-360-rgb), 0.4);
}
@media (hover: hover) {
  :root .bg-360-40-on-hover:hover {
    background-color: rgba(var(--color-bg-360-rgb), 0.4);
  }
  :root .hover-parent:hover .bg-360-40-on-hover-parent {
    background-color: rgba(var(--color-bg-360-rgb), 0.4);
  }
}
:root .bg-360-50 {
  background-color: rgba(var(--color-bg-360-rgb), 0.5);
}
@media (hover: hover) {
  :root .bg-360-50-on-hover:hover {
    background-color: rgba(var(--color-bg-360-rgb), 0.5);
  }
  :root .hover-parent:hover .bg-360-50-on-hover-parent {
    background-color: rgba(var(--color-bg-360-rgb), 0.5);
  }
}
:root .bg-360-60 {
  background-color: rgba(var(--color-bg-360-rgb), 0.6);
}
@media (hover: hover) {
  :root .bg-360-60-on-hover:hover {
    background-color: rgba(var(--color-bg-360-rgb), 0.6);
  }
  :root .hover-parent:hover .bg-360-60-on-hover-parent {
    background-color: rgba(var(--color-bg-360-rgb), 0.6);
  }
}
:root .bg-360-70 {
  background-color: rgba(var(--color-bg-360-rgb), 0.7);
}
@media (hover: hover) {
  :root .bg-360-70-on-hover:hover {
    background-color: rgba(var(--color-bg-360-rgb), 0.7);
  }
  :root .hover-parent:hover .bg-360-70-on-hover-parent {
    background-color: rgba(var(--color-bg-360-rgb), 0.7);
  }
}
:root .bg-360-80 {
  background-color: rgba(var(--color-bg-360-rgb), 0.8);
}
@media (hover: hover) {
  :root .bg-360-80-on-hover:hover {
    background-color: rgba(var(--color-bg-360-rgb), 0.8);
  }
  :root .hover-parent:hover .bg-360-80-on-hover-parent {
    background-color: rgba(var(--color-bg-360-rgb), 0.8);
  }
}
:root .bg-360-90 {
  background-color: rgba(var(--color-bg-360-rgb), 0.9);
}
@media (hover: hover) {
  :root .bg-360-90-on-hover:hover {
    background-color: rgba(var(--color-bg-360-rgb), 0.9);
  }
  :root .hover-parent:hover .bg-360-90-on-hover-parent {
    background-color: rgba(var(--color-bg-360-rgb), 0.9);
  }
}
:root .bg-360-100 {
  background-color: rgba(var(--color-bg-360-rgb), 1);
}
@media (hover: hover) {
  :root .bg-360-100-on-hover:hover {
    background-color: rgba(var(--color-bg-360-rgb), 1);
  }
  :root .hover-parent:hover .bg-360-100-on-hover-parent {
    background-color: rgba(var(--color-bg-360-rgb), 1);
  }
}
:root .bg-360-as-fc {
  color: rgb(var(--color-bg-360-rgb));
}
:root .bg-360-as-border, :root .bg-360-as-line {
  border-color: rgb(var(--color-bg-360-rgb));
}

@media (hover: hover) {
  :root .bg-360-on-hover:hover {
    background-color: rgb(var(--color-bg-360-rgb));
  }
  :root .hover-parent:hover .bg-360-on-hover-parent {
    background-color: rgb(var(--color-bg-360-rgb));
  }
}
:root .bg-370 {
  background-color: rgb(var(--color-bg-370-rgb));
}
:root .bg-370-0 {
  background-color: rgba(var(--color-bg-370-rgb), 0);
}
@media (hover: hover) {
  :root .bg-370-0-on-hover:hover {
    background-color: rgba(var(--color-bg-370-rgb), 0);
  }
  :root .hover-parent:hover .bg-370-0-on-hover-parent {
    background-color: rgba(var(--color-bg-370-rgb), 0);
  }
}
:root .bg-370-10 {
  background-color: rgba(var(--color-bg-370-rgb), 0.1);
}
@media (hover: hover) {
  :root .bg-370-10-on-hover:hover {
    background-color: rgba(var(--color-bg-370-rgb), 0.1);
  }
  :root .hover-parent:hover .bg-370-10-on-hover-parent {
    background-color: rgba(var(--color-bg-370-rgb), 0.1);
  }
}
:root .bg-370-20 {
  background-color: rgba(var(--color-bg-370-rgb), 0.2);
}
@media (hover: hover) {
  :root .bg-370-20-on-hover:hover {
    background-color: rgba(var(--color-bg-370-rgb), 0.2);
  }
  :root .hover-parent:hover .bg-370-20-on-hover-parent {
    background-color: rgba(var(--color-bg-370-rgb), 0.2);
  }
}
:root .bg-370-30 {
  background-color: rgba(var(--color-bg-370-rgb), 0.3);
}
@media (hover: hover) {
  :root .bg-370-30-on-hover:hover {
    background-color: rgba(var(--color-bg-370-rgb), 0.3);
  }
  :root .hover-parent:hover .bg-370-30-on-hover-parent {
    background-color: rgba(var(--color-bg-370-rgb), 0.3);
  }
}
:root .bg-370-40 {
  background-color: rgba(var(--color-bg-370-rgb), 0.4);
}
@media (hover: hover) {
  :root .bg-370-40-on-hover:hover {
    background-color: rgba(var(--color-bg-370-rgb), 0.4);
  }
  :root .hover-parent:hover .bg-370-40-on-hover-parent {
    background-color: rgba(var(--color-bg-370-rgb), 0.4);
  }
}
:root .bg-370-50 {
  background-color: rgba(var(--color-bg-370-rgb), 0.5);
}
@media (hover: hover) {
  :root .bg-370-50-on-hover:hover {
    background-color: rgba(var(--color-bg-370-rgb), 0.5);
  }
  :root .hover-parent:hover .bg-370-50-on-hover-parent {
    background-color: rgba(var(--color-bg-370-rgb), 0.5);
  }
}
:root .bg-370-60 {
  background-color: rgba(var(--color-bg-370-rgb), 0.6);
}
@media (hover: hover) {
  :root .bg-370-60-on-hover:hover {
    background-color: rgba(var(--color-bg-370-rgb), 0.6);
  }
  :root .hover-parent:hover .bg-370-60-on-hover-parent {
    background-color: rgba(var(--color-bg-370-rgb), 0.6);
  }
}
:root .bg-370-70 {
  background-color: rgba(var(--color-bg-370-rgb), 0.7);
}
@media (hover: hover) {
  :root .bg-370-70-on-hover:hover {
    background-color: rgba(var(--color-bg-370-rgb), 0.7);
  }
  :root .hover-parent:hover .bg-370-70-on-hover-parent {
    background-color: rgba(var(--color-bg-370-rgb), 0.7);
  }
}
:root .bg-370-80 {
  background-color: rgba(var(--color-bg-370-rgb), 0.8);
}
@media (hover: hover) {
  :root .bg-370-80-on-hover:hover {
    background-color: rgba(var(--color-bg-370-rgb), 0.8);
  }
  :root .hover-parent:hover .bg-370-80-on-hover-parent {
    background-color: rgba(var(--color-bg-370-rgb), 0.8);
  }
}
:root .bg-370-90 {
  background-color: rgba(var(--color-bg-370-rgb), 0.9);
}
@media (hover: hover) {
  :root .bg-370-90-on-hover:hover {
    background-color: rgba(var(--color-bg-370-rgb), 0.9);
  }
  :root .hover-parent:hover .bg-370-90-on-hover-parent {
    background-color: rgba(var(--color-bg-370-rgb), 0.9);
  }
}
:root .bg-370-100 {
  background-color: rgba(var(--color-bg-370-rgb), 1);
}
@media (hover: hover) {
  :root .bg-370-100-on-hover:hover {
    background-color: rgba(var(--color-bg-370-rgb), 1);
  }
  :root .hover-parent:hover .bg-370-100-on-hover-parent {
    background-color: rgba(var(--color-bg-370-rgb), 1);
  }
}
:root .bg-370-as-fc {
  color: rgb(var(--color-bg-370-rgb));
}
:root .bg-370-as-border, :root .bg-370-as-line {
  border-color: rgb(var(--color-bg-370-rgb));
}

@media (hover: hover) {
  :root .bg-370-on-hover:hover {
    background-color: rgb(var(--color-bg-370-rgb));
  }
  :root .hover-parent:hover .bg-370-on-hover-parent {
    background-color: rgb(var(--color-bg-370-rgb));
  }
}
:root .bg-380 {
  background-color: rgb(var(--color-bg-380-rgb));
}
:root .bg-380-0 {
  background-color: rgba(var(--color-bg-380-rgb), 0);
}
@media (hover: hover) {
  :root .bg-380-0-on-hover:hover {
    background-color: rgba(var(--color-bg-380-rgb), 0);
  }
  :root .hover-parent:hover .bg-380-0-on-hover-parent {
    background-color: rgba(var(--color-bg-380-rgb), 0);
  }
}
:root .bg-380-10 {
  background-color: rgba(var(--color-bg-380-rgb), 0.1);
}
@media (hover: hover) {
  :root .bg-380-10-on-hover:hover {
    background-color: rgba(var(--color-bg-380-rgb), 0.1);
  }
  :root .hover-parent:hover .bg-380-10-on-hover-parent {
    background-color: rgba(var(--color-bg-380-rgb), 0.1);
  }
}
:root .bg-380-20 {
  background-color: rgba(var(--color-bg-380-rgb), 0.2);
}
@media (hover: hover) {
  :root .bg-380-20-on-hover:hover {
    background-color: rgba(var(--color-bg-380-rgb), 0.2);
  }
  :root .hover-parent:hover .bg-380-20-on-hover-parent {
    background-color: rgba(var(--color-bg-380-rgb), 0.2);
  }
}
:root .bg-380-30 {
  background-color: rgba(var(--color-bg-380-rgb), 0.3);
}
@media (hover: hover) {
  :root .bg-380-30-on-hover:hover {
    background-color: rgba(var(--color-bg-380-rgb), 0.3);
  }
  :root .hover-parent:hover .bg-380-30-on-hover-parent {
    background-color: rgba(var(--color-bg-380-rgb), 0.3);
  }
}
:root .bg-380-40 {
  background-color: rgba(var(--color-bg-380-rgb), 0.4);
}
@media (hover: hover) {
  :root .bg-380-40-on-hover:hover {
    background-color: rgba(var(--color-bg-380-rgb), 0.4);
  }
  :root .hover-parent:hover .bg-380-40-on-hover-parent {
    background-color: rgba(var(--color-bg-380-rgb), 0.4);
  }
}
:root .bg-380-50 {
  background-color: rgba(var(--color-bg-380-rgb), 0.5);
}
@media (hover: hover) {
  :root .bg-380-50-on-hover:hover {
    background-color: rgba(var(--color-bg-380-rgb), 0.5);
  }
  :root .hover-parent:hover .bg-380-50-on-hover-parent {
    background-color: rgba(var(--color-bg-380-rgb), 0.5);
  }
}
:root .bg-380-60 {
  background-color: rgba(var(--color-bg-380-rgb), 0.6);
}
@media (hover: hover) {
  :root .bg-380-60-on-hover:hover {
    background-color: rgba(var(--color-bg-380-rgb), 0.6);
  }
  :root .hover-parent:hover .bg-380-60-on-hover-parent {
    background-color: rgba(var(--color-bg-380-rgb), 0.6);
  }
}
:root .bg-380-70 {
  background-color: rgba(var(--color-bg-380-rgb), 0.7);
}
@media (hover: hover) {
  :root .bg-380-70-on-hover:hover {
    background-color: rgba(var(--color-bg-380-rgb), 0.7);
  }
  :root .hover-parent:hover .bg-380-70-on-hover-parent {
    background-color: rgba(var(--color-bg-380-rgb), 0.7);
  }
}
:root .bg-380-80 {
  background-color: rgba(var(--color-bg-380-rgb), 0.8);
}
@media (hover: hover) {
  :root .bg-380-80-on-hover:hover {
    background-color: rgba(var(--color-bg-380-rgb), 0.8);
  }
  :root .hover-parent:hover .bg-380-80-on-hover-parent {
    background-color: rgba(var(--color-bg-380-rgb), 0.8);
  }
}
:root .bg-380-90 {
  background-color: rgba(var(--color-bg-380-rgb), 0.9);
}
@media (hover: hover) {
  :root .bg-380-90-on-hover:hover {
    background-color: rgba(var(--color-bg-380-rgb), 0.9);
  }
  :root .hover-parent:hover .bg-380-90-on-hover-parent {
    background-color: rgba(var(--color-bg-380-rgb), 0.9);
  }
}
:root .bg-380-100 {
  background-color: rgba(var(--color-bg-380-rgb), 1);
}
@media (hover: hover) {
  :root .bg-380-100-on-hover:hover {
    background-color: rgba(var(--color-bg-380-rgb), 1);
  }
  :root .hover-parent:hover .bg-380-100-on-hover-parent {
    background-color: rgba(var(--color-bg-380-rgb), 1);
  }
}
:root .bg-380-as-fc {
  color: rgb(var(--color-bg-380-rgb));
}
:root .bg-380-as-border, :root .bg-380-as-line {
  border-color: rgb(var(--color-bg-380-rgb));
}

@media (hover: hover) {
  :root .bg-380-on-hover:hover {
    background-color: rgb(var(--color-bg-380-rgb));
  }
  :root .hover-parent:hover .bg-380-on-hover-parent {
    background-color: rgb(var(--color-bg-380-rgb));
  }
}
:root .bg-390 {
  background-color: rgb(var(--color-bg-390-rgb));
}
:root .bg-390-0 {
  background-color: rgba(var(--color-bg-390-rgb), 0);
}
@media (hover: hover) {
  :root .bg-390-0-on-hover:hover {
    background-color: rgba(var(--color-bg-390-rgb), 0);
  }
  :root .hover-parent:hover .bg-390-0-on-hover-parent {
    background-color: rgba(var(--color-bg-390-rgb), 0);
  }
}
:root .bg-390-10 {
  background-color: rgba(var(--color-bg-390-rgb), 0.1);
}
@media (hover: hover) {
  :root .bg-390-10-on-hover:hover {
    background-color: rgba(var(--color-bg-390-rgb), 0.1);
  }
  :root .hover-parent:hover .bg-390-10-on-hover-parent {
    background-color: rgba(var(--color-bg-390-rgb), 0.1);
  }
}
:root .bg-390-20 {
  background-color: rgba(var(--color-bg-390-rgb), 0.2);
}
@media (hover: hover) {
  :root .bg-390-20-on-hover:hover {
    background-color: rgba(var(--color-bg-390-rgb), 0.2);
  }
  :root .hover-parent:hover .bg-390-20-on-hover-parent {
    background-color: rgba(var(--color-bg-390-rgb), 0.2);
  }
}
:root .bg-390-30 {
  background-color: rgba(var(--color-bg-390-rgb), 0.3);
}
@media (hover: hover) {
  :root .bg-390-30-on-hover:hover {
    background-color: rgba(var(--color-bg-390-rgb), 0.3);
  }
  :root .hover-parent:hover .bg-390-30-on-hover-parent {
    background-color: rgba(var(--color-bg-390-rgb), 0.3);
  }
}
:root .bg-390-40 {
  background-color: rgba(var(--color-bg-390-rgb), 0.4);
}
@media (hover: hover) {
  :root .bg-390-40-on-hover:hover {
    background-color: rgba(var(--color-bg-390-rgb), 0.4);
  }
  :root .hover-parent:hover .bg-390-40-on-hover-parent {
    background-color: rgba(var(--color-bg-390-rgb), 0.4);
  }
}
:root .bg-390-50 {
  background-color: rgba(var(--color-bg-390-rgb), 0.5);
}
@media (hover: hover) {
  :root .bg-390-50-on-hover:hover {
    background-color: rgba(var(--color-bg-390-rgb), 0.5);
  }
  :root .hover-parent:hover .bg-390-50-on-hover-parent {
    background-color: rgba(var(--color-bg-390-rgb), 0.5);
  }
}
:root .bg-390-60 {
  background-color: rgba(var(--color-bg-390-rgb), 0.6);
}
@media (hover: hover) {
  :root .bg-390-60-on-hover:hover {
    background-color: rgba(var(--color-bg-390-rgb), 0.6);
  }
  :root .hover-parent:hover .bg-390-60-on-hover-parent {
    background-color: rgba(var(--color-bg-390-rgb), 0.6);
  }
}
:root .bg-390-70 {
  background-color: rgba(var(--color-bg-390-rgb), 0.7);
}
@media (hover: hover) {
  :root .bg-390-70-on-hover:hover {
    background-color: rgba(var(--color-bg-390-rgb), 0.7);
  }
  :root .hover-parent:hover .bg-390-70-on-hover-parent {
    background-color: rgba(var(--color-bg-390-rgb), 0.7);
  }
}
:root .bg-390-80 {
  background-color: rgba(var(--color-bg-390-rgb), 0.8);
}
@media (hover: hover) {
  :root .bg-390-80-on-hover:hover {
    background-color: rgba(var(--color-bg-390-rgb), 0.8);
  }
  :root .hover-parent:hover .bg-390-80-on-hover-parent {
    background-color: rgba(var(--color-bg-390-rgb), 0.8);
  }
}
:root .bg-390-90 {
  background-color: rgba(var(--color-bg-390-rgb), 0.9);
}
@media (hover: hover) {
  :root .bg-390-90-on-hover:hover {
    background-color: rgba(var(--color-bg-390-rgb), 0.9);
  }
  :root .hover-parent:hover .bg-390-90-on-hover-parent {
    background-color: rgba(var(--color-bg-390-rgb), 0.9);
  }
}
:root .bg-390-100 {
  background-color: rgba(var(--color-bg-390-rgb), 1);
}
@media (hover: hover) {
  :root .bg-390-100-on-hover:hover {
    background-color: rgba(var(--color-bg-390-rgb), 1);
  }
  :root .hover-parent:hover .bg-390-100-on-hover-parent {
    background-color: rgba(var(--color-bg-390-rgb), 1);
  }
}
:root .bg-390-as-fc {
  color: rgb(var(--color-bg-390-rgb));
}
:root .bg-390-as-border, :root .bg-390-as-line {
  border-color: rgb(var(--color-bg-390-rgb));
}

@media (hover: hover) {
  :root .bg-390-on-hover:hover {
    background-color: rgb(var(--color-bg-390-rgb));
  }
  :root .hover-parent:hover .bg-390-on-hover-parent {
    background-color: rgb(var(--color-bg-390-rgb));
  }
}
:root .bg-460 {
  background-color: rgb(var(--color-bg-460-rgb));
}
:root .bg-460-0 {
  background-color: rgba(var(--color-bg-460-rgb), 0);
}
@media (hover: hover) {
  :root .bg-460-0-on-hover:hover {
    background-color: rgba(var(--color-bg-460-rgb), 0);
  }
  :root .hover-parent:hover .bg-460-0-on-hover-parent {
    background-color: rgba(var(--color-bg-460-rgb), 0);
  }
}
:root .bg-460-10 {
  background-color: rgba(var(--color-bg-460-rgb), 0.1);
}
@media (hover: hover) {
  :root .bg-460-10-on-hover:hover {
    background-color: rgba(var(--color-bg-460-rgb), 0.1);
  }
  :root .hover-parent:hover .bg-460-10-on-hover-parent {
    background-color: rgba(var(--color-bg-460-rgb), 0.1);
  }
}
:root .bg-460-20 {
  background-color: rgba(var(--color-bg-460-rgb), 0.2);
}
@media (hover: hover) {
  :root .bg-460-20-on-hover:hover {
    background-color: rgba(var(--color-bg-460-rgb), 0.2);
  }
  :root .hover-parent:hover .bg-460-20-on-hover-parent {
    background-color: rgba(var(--color-bg-460-rgb), 0.2);
  }
}
:root .bg-460-30 {
  background-color: rgba(var(--color-bg-460-rgb), 0.3);
}
@media (hover: hover) {
  :root .bg-460-30-on-hover:hover {
    background-color: rgba(var(--color-bg-460-rgb), 0.3);
  }
  :root .hover-parent:hover .bg-460-30-on-hover-parent {
    background-color: rgba(var(--color-bg-460-rgb), 0.3);
  }
}
:root .bg-460-40 {
  background-color: rgba(var(--color-bg-460-rgb), 0.4);
}
@media (hover: hover) {
  :root .bg-460-40-on-hover:hover {
    background-color: rgba(var(--color-bg-460-rgb), 0.4);
  }
  :root .hover-parent:hover .bg-460-40-on-hover-parent {
    background-color: rgba(var(--color-bg-460-rgb), 0.4);
  }
}
:root .bg-460-50 {
  background-color: rgba(var(--color-bg-460-rgb), 0.5);
}
@media (hover: hover) {
  :root .bg-460-50-on-hover:hover {
    background-color: rgba(var(--color-bg-460-rgb), 0.5);
  }
  :root .hover-parent:hover .bg-460-50-on-hover-parent {
    background-color: rgba(var(--color-bg-460-rgb), 0.5);
  }
}
:root .bg-460-60 {
  background-color: rgba(var(--color-bg-460-rgb), 0.6);
}
@media (hover: hover) {
  :root .bg-460-60-on-hover:hover {
    background-color: rgba(var(--color-bg-460-rgb), 0.6);
  }
  :root .hover-parent:hover .bg-460-60-on-hover-parent {
    background-color: rgba(var(--color-bg-460-rgb), 0.6);
  }
}
:root .bg-460-70 {
  background-color: rgba(var(--color-bg-460-rgb), 0.7);
}
@media (hover: hover) {
  :root .bg-460-70-on-hover:hover {
    background-color: rgba(var(--color-bg-460-rgb), 0.7);
  }
  :root .hover-parent:hover .bg-460-70-on-hover-parent {
    background-color: rgba(var(--color-bg-460-rgb), 0.7);
  }
}
:root .bg-460-80 {
  background-color: rgba(var(--color-bg-460-rgb), 0.8);
}
@media (hover: hover) {
  :root .bg-460-80-on-hover:hover {
    background-color: rgba(var(--color-bg-460-rgb), 0.8);
  }
  :root .hover-parent:hover .bg-460-80-on-hover-parent {
    background-color: rgba(var(--color-bg-460-rgb), 0.8);
  }
}
:root .bg-460-90 {
  background-color: rgba(var(--color-bg-460-rgb), 0.9);
}
@media (hover: hover) {
  :root .bg-460-90-on-hover:hover {
    background-color: rgba(var(--color-bg-460-rgb), 0.9);
  }
  :root .hover-parent:hover .bg-460-90-on-hover-parent {
    background-color: rgba(var(--color-bg-460-rgb), 0.9);
  }
}
:root .bg-460-100 {
  background-color: rgba(var(--color-bg-460-rgb), 1);
}
@media (hover: hover) {
  :root .bg-460-100-on-hover:hover {
    background-color: rgba(var(--color-bg-460-rgb), 1);
  }
  :root .hover-parent:hover .bg-460-100-on-hover-parent {
    background-color: rgba(var(--color-bg-460-rgb), 1);
  }
}
:root .bg-460-as-fc {
  color: rgb(var(--color-bg-460-rgb));
}
:root .bg-460-as-border, :root .bg-460-as-line {
  border-color: rgb(var(--color-bg-460-rgb));
}

@media (hover: hover) {
  :root .bg-460-on-hover:hover {
    background-color: rgb(var(--color-bg-460-rgb));
  }
  :root .hover-parent:hover .bg-460-on-hover-parent {
    background-color: rgb(var(--color-bg-460-rgb));
  }
}
:root .bg-470 {
  background-color: rgb(var(--color-bg-470-rgb));
}
:root .bg-470-0 {
  background-color: rgba(var(--color-bg-470-rgb), 0);
}
@media (hover: hover) {
  :root .bg-470-0-on-hover:hover {
    background-color: rgba(var(--color-bg-470-rgb), 0);
  }
  :root .hover-parent:hover .bg-470-0-on-hover-parent {
    background-color: rgba(var(--color-bg-470-rgb), 0);
  }
}
:root .bg-470-10 {
  background-color: rgba(var(--color-bg-470-rgb), 0.1);
}
@media (hover: hover) {
  :root .bg-470-10-on-hover:hover {
    background-color: rgba(var(--color-bg-470-rgb), 0.1);
  }
  :root .hover-parent:hover .bg-470-10-on-hover-parent {
    background-color: rgba(var(--color-bg-470-rgb), 0.1);
  }
}
:root .bg-470-20 {
  background-color: rgba(var(--color-bg-470-rgb), 0.2);
}
@media (hover: hover) {
  :root .bg-470-20-on-hover:hover {
    background-color: rgba(var(--color-bg-470-rgb), 0.2);
  }
  :root .hover-parent:hover .bg-470-20-on-hover-parent {
    background-color: rgba(var(--color-bg-470-rgb), 0.2);
  }
}
:root .bg-470-30 {
  background-color: rgba(var(--color-bg-470-rgb), 0.3);
}
@media (hover: hover) {
  :root .bg-470-30-on-hover:hover {
    background-color: rgba(var(--color-bg-470-rgb), 0.3);
  }
  :root .hover-parent:hover .bg-470-30-on-hover-parent {
    background-color: rgba(var(--color-bg-470-rgb), 0.3);
  }
}
:root .bg-470-40 {
  background-color: rgba(var(--color-bg-470-rgb), 0.4);
}
@media (hover: hover) {
  :root .bg-470-40-on-hover:hover {
    background-color: rgba(var(--color-bg-470-rgb), 0.4);
  }
  :root .hover-parent:hover .bg-470-40-on-hover-parent {
    background-color: rgba(var(--color-bg-470-rgb), 0.4);
  }
}
:root .bg-470-50 {
  background-color: rgba(var(--color-bg-470-rgb), 0.5);
}
@media (hover: hover) {
  :root .bg-470-50-on-hover:hover {
    background-color: rgba(var(--color-bg-470-rgb), 0.5);
  }
  :root .hover-parent:hover .bg-470-50-on-hover-parent {
    background-color: rgba(var(--color-bg-470-rgb), 0.5);
  }
}
:root .bg-470-60 {
  background-color: rgba(var(--color-bg-470-rgb), 0.6);
}
@media (hover: hover) {
  :root .bg-470-60-on-hover:hover {
    background-color: rgba(var(--color-bg-470-rgb), 0.6);
  }
  :root .hover-parent:hover .bg-470-60-on-hover-parent {
    background-color: rgba(var(--color-bg-470-rgb), 0.6);
  }
}
:root .bg-470-70 {
  background-color: rgba(var(--color-bg-470-rgb), 0.7);
}
@media (hover: hover) {
  :root .bg-470-70-on-hover:hover {
    background-color: rgba(var(--color-bg-470-rgb), 0.7);
  }
  :root .hover-parent:hover .bg-470-70-on-hover-parent {
    background-color: rgba(var(--color-bg-470-rgb), 0.7);
  }
}
:root .bg-470-80 {
  background-color: rgba(var(--color-bg-470-rgb), 0.8);
}
@media (hover: hover) {
  :root .bg-470-80-on-hover:hover {
    background-color: rgba(var(--color-bg-470-rgb), 0.8);
  }
  :root .hover-parent:hover .bg-470-80-on-hover-parent {
    background-color: rgba(var(--color-bg-470-rgb), 0.8);
  }
}
:root .bg-470-90 {
  background-color: rgba(var(--color-bg-470-rgb), 0.9);
}
@media (hover: hover) {
  :root .bg-470-90-on-hover:hover {
    background-color: rgba(var(--color-bg-470-rgb), 0.9);
  }
  :root .hover-parent:hover .bg-470-90-on-hover-parent {
    background-color: rgba(var(--color-bg-470-rgb), 0.9);
  }
}
:root .bg-470-100 {
  background-color: rgba(var(--color-bg-470-rgb), 1);
}
@media (hover: hover) {
  :root .bg-470-100-on-hover:hover {
    background-color: rgba(var(--color-bg-470-rgb), 1);
  }
  :root .hover-parent:hover .bg-470-100-on-hover-parent {
    background-color: rgba(var(--color-bg-470-rgb), 1);
  }
}
:root .bg-470-as-fc {
  color: rgb(var(--color-bg-470-rgb));
}
:root .bg-470-as-border, :root .bg-470-as-line {
  border-color: rgb(var(--color-bg-470-rgb));
}

@media (hover: hover) {
  :root .bg-470-on-hover:hover {
    background-color: rgb(var(--color-bg-470-rgb));
  }
  :root .hover-parent:hover .bg-470-on-hover-parent {
    background-color: rgb(var(--color-bg-470-rgb));
  }
}
:root .bg-480 {
  background-color: rgb(var(--color-bg-480-rgb));
}
:root .bg-480-0 {
  background-color: rgba(var(--color-bg-480-rgb), 0);
}
@media (hover: hover) {
  :root .bg-480-0-on-hover:hover {
    background-color: rgba(var(--color-bg-480-rgb), 0);
  }
  :root .hover-parent:hover .bg-480-0-on-hover-parent {
    background-color: rgba(var(--color-bg-480-rgb), 0);
  }
}
:root .bg-480-10 {
  background-color: rgba(var(--color-bg-480-rgb), 0.1);
}
@media (hover: hover) {
  :root .bg-480-10-on-hover:hover {
    background-color: rgba(var(--color-bg-480-rgb), 0.1);
  }
  :root .hover-parent:hover .bg-480-10-on-hover-parent {
    background-color: rgba(var(--color-bg-480-rgb), 0.1);
  }
}
:root .bg-480-20 {
  background-color: rgba(var(--color-bg-480-rgb), 0.2);
}
@media (hover: hover) {
  :root .bg-480-20-on-hover:hover {
    background-color: rgba(var(--color-bg-480-rgb), 0.2);
  }
  :root .hover-parent:hover .bg-480-20-on-hover-parent {
    background-color: rgba(var(--color-bg-480-rgb), 0.2);
  }
}
:root .bg-480-30 {
  background-color: rgba(var(--color-bg-480-rgb), 0.3);
}
@media (hover: hover) {
  :root .bg-480-30-on-hover:hover {
    background-color: rgba(var(--color-bg-480-rgb), 0.3);
  }
  :root .hover-parent:hover .bg-480-30-on-hover-parent {
    background-color: rgba(var(--color-bg-480-rgb), 0.3);
  }
}
:root .bg-480-40 {
  background-color: rgba(var(--color-bg-480-rgb), 0.4);
}
@media (hover: hover) {
  :root .bg-480-40-on-hover:hover {
    background-color: rgba(var(--color-bg-480-rgb), 0.4);
  }
  :root .hover-parent:hover .bg-480-40-on-hover-parent {
    background-color: rgba(var(--color-bg-480-rgb), 0.4);
  }
}
:root .bg-480-50 {
  background-color: rgba(var(--color-bg-480-rgb), 0.5);
}
@media (hover: hover) {
  :root .bg-480-50-on-hover:hover {
    background-color: rgba(var(--color-bg-480-rgb), 0.5);
  }
  :root .hover-parent:hover .bg-480-50-on-hover-parent {
    background-color: rgba(var(--color-bg-480-rgb), 0.5);
  }
}
:root .bg-480-60 {
  background-color: rgba(var(--color-bg-480-rgb), 0.6);
}
@media (hover: hover) {
  :root .bg-480-60-on-hover:hover {
    background-color: rgba(var(--color-bg-480-rgb), 0.6);
  }
  :root .hover-parent:hover .bg-480-60-on-hover-parent {
    background-color: rgba(var(--color-bg-480-rgb), 0.6);
  }
}
:root .bg-480-70 {
  background-color: rgba(var(--color-bg-480-rgb), 0.7);
}
@media (hover: hover) {
  :root .bg-480-70-on-hover:hover {
    background-color: rgba(var(--color-bg-480-rgb), 0.7);
  }
  :root .hover-parent:hover .bg-480-70-on-hover-parent {
    background-color: rgba(var(--color-bg-480-rgb), 0.7);
  }
}
:root .bg-480-80 {
  background-color: rgba(var(--color-bg-480-rgb), 0.8);
}
@media (hover: hover) {
  :root .bg-480-80-on-hover:hover {
    background-color: rgba(var(--color-bg-480-rgb), 0.8);
  }
  :root .hover-parent:hover .bg-480-80-on-hover-parent {
    background-color: rgba(var(--color-bg-480-rgb), 0.8);
  }
}
:root .bg-480-90 {
  background-color: rgba(var(--color-bg-480-rgb), 0.9);
}
@media (hover: hover) {
  :root .bg-480-90-on-hover:hover {
    background-color: rgba(var(--color-bg-480-rgb), 0.9);
  }
  :root .hover-parent:hover .bg-480-90-on-hover-parent {
    background-color: rgba(var(--color-bg-480-rgb), 0.9);
  }
}
:root .bg-480-100 {
  background-color: rgba(var(--color-bg-480-rgb), 1);
}
@media (hover: hover) {
  :root .bg-480-100-on-hover:hover {
    background-color: rgba(var(--color-bg-480-rgb), 1);
  }
  :root .hover-parent:hover .bg-480-100-on-hover-parent {
    background-color: rgba(var(--color-bg-480-rgb), 1);
  }
}
:root .bg-480-as-fc {
  color: rgb(var(--color-bg-480-rgb));
}
:root .bg-480-as-border, :root .bg-480-as-line {
  border-color: rgb(var(--color-bg-480-rgb));
}

@media (hover: hover) {
  :root .bg-480-on-hover:hover {
    background-color: rgb(var(--color-bg-480-rgb));
  }
  :root .hover-parent:hover .bg-480-on-hover-parent {
    background-color: rgb(var(--color-bg-480-rgb));
  }
}
:root .bg-490 {
  background-color: rgb(var(--color-bg-490-rgb));
}
:root .bg-490-0 {
  background-color: rgba(var(--color-bg-490-rgb), 0);
}
@media (hover: hover) {
  :root .bg-490-0-on-hover:hover {
    background-color: rgba(var(--color-bg-490-rgb), 0);
  }
  :root .hover-parent:hover .bg-490-0-on-hover-parent {
    background-color: rgba(var(--color-bg-490-rgb), 0);
  }
}
:root .bg-490-10 {
  background-color: rgba(var(--color-bg-490-rgb), 0.1);
}
@media (hover: hover) {
  :root .bg-490-10-on-hover:hover {
    background-color: rgba(var(--color-bg-490-rgb), 0.1);
  }
  :root .hover-parent:hover .bg-490-10-on-hover-parent {
    background-color: rgba(var(--color-bg-490-rgb), 0.1);
  }
}
:root .bg-490-20 {
  background-color: rgba(var(--color-bg-490-rgb), 0.2);
}
@media (hover: hover) {
  :root .bg-490-20-on-hover:hover {
    background-color: rgba(var(--color-bg-490-rgb), 0.2);
  }
  :root .hover-parent:hover .bg-490-20-on-hover-parent {
    background-color: rgba(var(--color-bg-490-rgb), 0.2);
  }
}
:root .bg-490-30 {
  background-color: rgba(var(--color-bg-490-rgb), 0.3);
}
@media (hover: hover) {
  :root .bg-490-30-on-hover:hover {
    background-color: rgba(var(--color-bg-490-rgb), 0.3);
  }
  :root .hover-parent:hover .bg-490-30-on-hover-parent {
    background-color: rgba(var(--color-bg-490-rgb), 0.3);
  }
}
:root .bg-490-40 {
  background-color: rgba(var(--color-bg-490-rgb), 0.4);
}
@media (hover: hover) {
  :root .bg-490-40-on-hover:hover {
    background-color: rgba(var(--color-bg-490-rgb), 0.4);
  }
  :root .hover-parent:hover .bg-490-40-on-hover-parent {
    background-color: rgba(var(--color-bg-490-rgb), 0.4);
  }
}
:root .bg-490-50 {
  background-color: rgba(var(--color-bg-490-rgb), 0.5);
}
@media (hover: hover) {
  :root .bg-490-50-on-hover:hover {
    background-color: rgba(var(--color-bg-490-rgb), 0.5);
  }
  :root .hover-parent:hover .bg-490-50-on-hover-parent {
    background-color: rgba(var(--color-bg-490-rgb), 0.5);
  }
}
:root .bg-490-60 {
  background-color: rgba(var(--color-bg-490-rgb), 0.6);
}
@media (hover: hover) {
  :root .bg-490-60-on-hover:hover {
    background-color: rgba(var(--color-bg-490-rgb), 0.6);
  }
  :root .hover-parent:hover .bg-490-60-on-hover-parent {
    background-color: rgba(var(--color-bg-490-rgb), 0.6);
  }
}
:root .bg-490-70 {
  background-color: rgba(var(--color-bg-490-rgb), 0.7);
}
@media (hover: hover) {
  :root .bg-490-70-on-hover:hover {
    background-color: rgba(var(--color-bg-490-rgb), 0.7);
  }
  :root .hover-parent:hover .bg-490-70-on-hover-parent {
    background-color: rgba(var(--color-bg-490-rgb), 0.7);
  }
}
:root .bg-490-80 {
  background-color: rgba(var(--color-bg-490-rgb), 0.8);
}
@media (hover: hover) {
  :root .bg-490-80-on-hover:hover {
    background-color: rgba(var(--color-bg-490-rgb), 0.8);
  }
  :root .hover-parent:hover .bg-490-80-on-hover-parent {
    background-color: rgba(var(--color-bg-490-rgb), 0.8);
  }
}
:root .bg-490-90 {
  background-color: rgba(var(--color-bg-490-rgb), 0.9);
}
@media (hover: hover) {
  :root .bg-490-90-on-hover:hover {
    background-color: rgba(var(--color-bg-490-rgb), 0.9);
  }
  :root .hover-parent:hover .bg-490-90-on-hover-parent {
    background-color: rgba(var(--color-bg-490-rgb), 0.9);
  }
}
:root .bg-490-100 {
  background-color: rgba(var(--color-bg-490-rgb), 1);
}
@media (hover: hover) {
  :root .bg-490-100-on-hover:hover {
    background-color: rgba(var(--color-bg-490-rgb), 1);
  }
  :root .hover-parent:hover .bg-490-100-on-hover-parent {
    background-color: rgba(var(--color-bg-490-rgb), 1);
  }
}
:root .bg-490-as-fc {
  color: rgb(var(--color-bg-490-rgb));
}
:root .bg-490-as-border, :root .bg-490-as-line {
  border-color: rgb(var(--color-bg-490-rgb));
}

@media (hover: hover) {
  :root .bg-490-on-hover:hover {
    background-color: rgb(var(--color-bg-490-rgb));
  }
  :root .hover-parent:hover .bg-490-on-hover-parent {
    background-color: rgb(var(--color-bg-490-rgb));
  }
}
:root .bg-560 {
  background-color: rgb(var(--color-bg-560-rgb));
}
:root .bg-560-0 {
  background-color: rgba(var(--color-bg-560-rgb), 0);
}
@media (hover: hover) {
  :root .bg-560-0-on-hover:hover {
    background-color: rgba(var(--color-bg-560-rgb), 0);
  }
  :root .hover-parent:hover .bg-560-0-on-hover-parent {
    background-color: rgba(var(--color-bg-560-rgb), 0);
  }
}
:root .bg-560-10 {
  background-color: rgba(var(--color-bg-560-rgb), 0.1);
}
@media (hover: hover) {
  :root .bg-560-10-on-hover:hover {
    background-color: rgba(var(--color-bg-560-rgb), 0.1);
  }
  :root .hover-parent:hover .bg-560-10-on-hover-parent {
    background-color: rgba(var(--color-bg-560-rgb), 0.1);
  }
}
:root .bg-560-20 {
  background-color: rgba(var(--color-bg-560-rgb), 0.2);
}
@media (hover: hover) {
  :root .bg-560-20-on-hover:hover {
    background-color: rgba(var(--color-bg-560-rgb), 0.2);
  }
  :root .hover-parent:hover .bg-560-20-on-hover-parent {
    background-color: rgba(var(--color-bg-560-rgb), 0.2);
  }
}
:root .bg-560-30 {
  background-color: rgba(var(--color-bg-560-rgb), 0.3);
}
@media (hover: hover) {
  :root .bg-560-30-on-hover:hover {
    background-color: rgba(var(--color-bg-560-rgb), 0.3);
  }
  :root .hover-parent:hover .bg-560-30-on-hover-parent {
    background-color: rgba(var(--color-bg-560-rgb), 0.3);
  }
}
:root .bg-560-40 {
  background-color: rgba(var(--color-bg-560-rgb), 0.4);
}
@media (hover: hover) {
  :root .bg-560-40-on-hover:hover {
    background-color: rgba(var(--color-bg-560-rgb), 0.4);
  }
  :root .hover-parent:hover .bg-560-40-on-hover-parent {
    background-color: rgba(var(--color-bg-560-rgb), 0.4);
  }
}
:root .bg-560-50 {
  background-color: rgba(var(--color-bg-560-rgb), 0.5);
}
@media (hover: hover) {
  :root .bg-560-50-on-hover:hover {
    background-color: rgba(var(--color-bg-560-rgb), 0.5);
  }
  :root .hover-parent:hover .bg-560-50-on-hover-parent {
    background-color: rgba(var(--color-bg-560-rgb), 0.5);
  }
}
:root .bg-560-60 {
  background-color: rgba(var(--color-bg-560-rgb), 0.6);
}
@media (hover: hover) {
  :root .bg-560-60-on-hover:hover {
    background-color: rgba(var(--color-bg-560-rgb), 0.6);
  }
  :root .hover-parent:hover .bg-560-60-on-hover-parent {
    background-color: rgba(var(--color-bg-560-rgb), 0.6);
  }
}
:root .bg-560-70 {
  background-color: rgba(var(--color-bg-560-rgb), 0.7);
}
@media (hover: hover) {
  :root .bg-560-70-on-hover:hover {
    background-color: rgba(var(--color-bg-560-rgb), 0.7);
  }
  :root .hover-parent:hover .bg-560-70-on-hover-parent {
    background-color: rgba(var(--color-bg-560-rgb), 0.7);
  }
}
:root .bg-560-80 {
  background-color: rgba(var(--color-bg-560-rgb), 0.8);
}
@media (hover: hover) {
  :root .bg-560-80-on-hover:hover {
    background-color: rgba(var(--color-bg-560-rgb), 0.8);
  }
  :root .hover-parent:hover .bg-560-80-on-hover-parent {
    background-color: rgba(var(--color-bg-560-rgb), 0.8);
  }
}
:root .bg-560-90 {
  background-color: rgba(var(--color-bg-560-rgb), 0.9);
}
@media (hover: hover) {
  :root .bg-560-90-on-hover:hover {
    background-color: rgba(var(--color-bg-560-rgb), 0.9);
  }
  :root .hover-parent:hover .bg-560-90-on-hover-parent {
    background-color: rgba(var(--color-bg-560-rgb), 0.9);
  }
}
:root .bg-560-100 {
  background-color: rgba(var(--color-bg-560-rgb), 1);
}
@media (hover: hover) {
  :root .bg-560-100-on-hover:hover {
    background-color: rgba(var(--color-bg-560-rgb), 1);
  }
  :root .hover-parent:hover .bg-560-100-on-hover-parent {
    background-color: rgba(var(--color-bg-560-rgb), 1);
  }
}
:root .bg-560-as-fc {
  color: rgb(var(--color-bg-560-rgb));
}
:root .bg-560-as-border, :root .bg-560-as-line {
  border-color: rgb(var(--color-bg-560-rgb));
}

@media (hover: hover) {
  :root .bg-560-on-hover:hover {
    background-color: rgb(var(--color-bg-560-rgb));
  }
  :root .hover-parent:hover .bg-560-on-hover-parent {
    background-color: rgb(var(--color-bg-560-rgb));
  }
}
:root .bg-570 {
  background-color: rgb(var(--color-bg-570-rgb));
}
:root .bg-570-0 {
  background-color: rgba(var(--color-bg-570-rgb), 0);
}
@media (hover: hover) {
  :root .bg-570-0-on-hover:hover {
    background-color: rgba(var(--color-bg-570-rgb), 0);
  }
  :root .hover-parent:hover .bg-570-0-on-hover-parent {
    background-color: rgba(var(--color-bg-570-rgb), 0);
  }
}
:root .bg-570-10 {
  background-color: rgba(var(--color-bg-570-rgb), 0.1);
}
@media (hover: hover) {
  :root .bg-570-10-on-hover:hover {
    background-color: rgba(var(--color-bg-570-rgb), 0.1);
  }
  :root .hover-parent:hover .bg-570-10-on-hover-parent {
    background-color: rgba(var(--color-bg-570-rgb), 0.1);
  }
}
:root .bg-570-20 {
  background-color: rgba(var(--color-bg-570-rgb), 0.2);
}
@media (hover: hover) {
  :root .bg-570-20-on-hover:hover {
    background-color: rgba(var(--color-bg-570-rgb), 0.2);
  }
  :root .hover-parent:hover .bg-570-20-on-hover-parent {
    background-color: rgba(var(--color-bg-570-rgb), 0.2);
  }
}
:root .bg-570-30 {
  background-color: rgba(var(--color-bg-570-rgb), 0.3);
}
@media (hover: hover) {
  :root .bg-570-30-on-hover:hover {
    background-color: rgba(var(--color-bg-570-rgb), 0.3);
  }
  :root .hover-parent:hover .bg-570-30-on-hover-parent {
    background-color: rgba(var(--color-bg-570-rgb), 0.3);
  }
}
:root .bg-570-40 {
  background-color: rgba(var(--color-bg-570-rgb), 0.4);
}
@media (hover: hover) {
  :root .bg-570-40-on-hover:hover {
    background-color: rgba(var(--color-bg-570-rgb), 0.4);
  }
  :root .hover-parent:hover .bg-570-40-on-hover-parent {
    background-color: rgba(var(--color-bg-570-rgb), 0.4);
  }
}
:root .bg-570-50 {
  background-color: rgba(var(--color-bg-570-rgb), 0.5);
}
@media (hover: hover) {
  :root .bg-570-50-on-hover:hover {
    background-color: rgba(var(--color-bg-570-rgb), 0.5);
  }
  :root .hover-parent:hover .bg-570-50-on-hover-parent {
    background-color: rgba(var(--color-bg-570-rgb), 0.5);
  }
}
:root .bg-570-60 {
  background-color: rgba(var(--color-bg-570-rgb), 0.6);
}
@media (hover: hover) {
  :root .bg-570-60-on-hover:hover {
    background-color: rgba(var(--color-bg-570-rgb), 0.6);
  }
  :root .hover-parent:hover .bg-570-60-on-hover-parent {
    background-color: rgba(var(--color-bg-570-rgb), 0.6);
  }
}
:root .bg-570-70 {
  background-color: rgba(var(--color-bg-570-rgb), 0.7);
}
@media (hover: hover) {
  :root .bg-570-70-on-hover:hover {
    background-color: rgba(var(--color-bg-570-rgb), 0.7);
  }
  :root .hover-parent:hover .bg-570-70-on-hover-parent {
    background-color: rgba(var(--color-bg-570-rgb), 0.7);
  }
}
:root .bg-570-80 {
  background-color: rgba(var(--color-bg-570-rgb), 0.8);
}
@media (hover: hover) {
  :root .bg-570-80-on-hover:hover {
    background-color: rgba(var(--color-bg-570-rgb), 0.8);
  }
  :root .hover-parent:hover .bg-570-80-on-hover-parent {
    background-color: rgba(var(--color-bg-570-rgb), 0.8);
  }
}
:root .bg-570-90 {
  background-color: rgba(var(--color-bg-570-rgb), 0.9);
}
@media (hover: hover) {
  :root .bg-570-90-on-hover:hover {
    background-color: rgba(var(--color-bg-570-rgb), 0.9);
  }
  :root .hover-parent:hover .bg-570-90-on-hover-parent {
    background-color: rgba(var(--color-bg-570-rgb), 0.9);
  }
}
:root .bg-570-100 {
  background-color: rgba(var(--color-bg-570-rgb), 1);
}
@media (hover: hover) {
  :root .bg-570-100-on-hover:hover {
    background-color: rgba(var(--color-bg-570-rgb), 1);
  }
  :root .hover-parent:hover .bg-570-100-on-hover-parent {
    background-color: rgba(var(--color-bg-570-rgb), 1);
  }
}
:root .bg-570-as-fc {
  color: rgb(var(--color-bg-570-rgb));
}
:root .bg-570-as-border, :root .bg-570-as-line {
  border-color: rgb(var(--color-bg-570-rgb));
}

@media (hover: hover) {
  :root .bg-570-on-hover:hover {
    background-color: rgb(var(--color-bg-570-rgb));
  }
  :root .hover-parent:hover .bg-570-on-hover-parent {
    background-color: rgb(var(--color-bg-570-rgb));
  }
}
:root .bg-580 {
  background-color: rgb(var(--color-bg-580-rgb));
}
:root .bg-580-0 {
  background-color: rgba(var(--color-bg-580-rgb), 0);
}
@media (hover: hover) {
  :root .bg-580-0-on-hover:hover {
    background-color: rgba(var(--color-bg-580-rgb), 0);
  }
  :root .hover-parent:hover .bg-580-0-on-hover-parent {
    background-color: rgba(var(--color-bg-580-rgb), 0);
  }
}
:root .bg-580-10 {
  background-color: rgba(var(--color-bg-580-rgb), 0.1);
}
@media (hover: hover) {
  :root .bg-580-10-on-hover:hover {
    background-color: rgba(var(--color-bg-580-rgb), 0.1);
  }
  :root .hover-parent:hover .bg-580-10-on-hover-parent {
    background-color: rgba(var(--color-bg-580-rgb), 0.1);
  }
}
:root .bg-580-20 {
  background-color: rgba(var(--color-bg-580-rgb), 0.2);
}
@media (hover: hover) {
  :root .bg-580-20-on-hover:hover {
    background-color: rgba(var(--color-bg-580-rgb), 0.2);
  }
  :root .hover-parent:hover .bg-580-20-on-hover-parent {
    background-color: rgba(var(--color-bg-580-rgb), 0.2);
  }
}
:root .bg-580-30 {
  background-color: rgba(var(--color-bg-580-rgb), 0.3);
}
@media (hover: hover) {
  :root .bg-580-30-on-hover:hover {
    background-color: rgba(var(--color-bg-580-rgb), 0.3);
  }
  :root .hover-parent:hover .bg-580-30-on-hover-parent {
    background-color: rgba(var(--color-bg-580-rgb), 0.3);
  }
}
:root .bg-580-40 {
  background-color: rgba(var(--color-bg-580-rgb), 0.4);
}
@media (hover: hover) {
  :root .bg-580-40-on-hover:hover {
    background-color: rgba(var(--color-bg-580-rgb), 0.4);
  }
  :root .hover-parent:hover .bg-580-40-on-hover-parent {
    background-color: rgba(var(--color-bg-580-rgb), 0.4);
  }
}
:root .bg-580-50 {
  background-color: rgba(var(--color-bg-580-rgb), 0.5);
}
@media (hover: hover) {
  :root .bg-580-50-on-hover:hover {
    background-color: rgba(var(--color-bg-580-rgb), 0.5);
  }
  :root .hover-parent:hover .bg-580-50-on-hover-parent {
    background-color: rgba(var(--color-bg-580-rgb), 0.5);
  }
}
:root .bg-580-60 {
  background-color: rgba(var(--color-bg-580-rgb), 0.6);
}
@media (hover: hover) {
  :root .bg-580-60-on-hover:hover {
    background-color: rgba(var(--color-bg-580-rgb), 0.6);
  }
  :root .hover-parent:hover .bg-580-60-on-hover-parent {
    background-color: rgba(var(--color-bg-580-rgb), 0.6);
  }
}
:root .bg-580-70 {
  background-color: rgba(var(--color-bg-580-rgb), 0.7);
}
@media (hover: hover) {
  :root .bg-580-70-on-hover:hover {
    background-color: rgba(var(--color-bg-580-rgb), 0.7);
  }
  :root .hover-parent:hover .bg-580-70-on-hover-parent {
    background-color: rgba(var(--color-bg-580-rgb), 0.7);
  }
}
:root .bg-580-80 {
  background-color: rgba(var(--color-bg-580-rgb), 0.8);
}
@media (hover: hover) {
  :root .bg-580-80-on-hover:hover {
    background-color: rgba(var(--color-bg-580-rgb), 0.8);
  }
  :root .hover-parent:hover .bg-580-80-on-hover-parent {
    background-color: rgba(var(--color-bg-580-rgb), 0.8);
  }
}
:root .bg-580-90 {
  background-color: rgba(var(--color-bg-580-rgb), 0.9);
}
@media (hover: hover) {
  :root .bg-580-90-on-hover:hover {
    background-color: rgba(var(--color-bg-580-rgb), 0.9);
  }
  :root .hover-parent:hover .bg-580-90-on-hover-parent {
    background-color: rgba(var(--color-bg-580-rgb), 0.9);
  }
}
:root .bg-580-100 {
  background-color: rgba(var(--color-bg-580-rgb), 1);
}
@media (hover: hover) {
  :root .bg-580-100-on-hover:hover {
    background-color: rgba(var(--color-bg-580-rgb), 1);
  }
  :root .hover-parent:hover .bg-580-100-on-hover-parent {
    background-color: rgba(var(--color-bg-580-rgb), 1);
  }
}
:root .bg-580-as-fc {
  color: rgb(var(--color-bg-580-rgb));
}
:root .bg-580-as-border, :root .bg-580-as-line {
  border-color: rgb(var(--color-bg-580-rgb));
}

@media (hover: hover) {
  :root .bg-580-on-hover:hover {
    background-color: rgb(var(--color-bg-580-rgb));
  }
  :root .hover-parent:hover .bg-580-on-hover-parent {
    background-color: rgb(var(--color-bg-580-rgb));
  }
}
:root .bg-590 {
  background-color: rgb(var(--color-bg-590-rgb));
}
:root .bg-590-0 {
  background-color: rgba(var(--color-bg-590-rgb), 0);
}
@media (hover: hover) {
  :root .bg-590-0-on-hover:hover {
    background-color: rgba(var(--color-bg-590-rgb), 0);
  }
  :root .hover-parent:hover .bg-590-0-on-hover-parent {
    background-color: rgba(var(--color-bg-590-rgb), 0);
  }
}
:root .bg-590-10 {
  background-color: rgba(var(--color-bg-590-rgb), 0.1);
}
@media (hover: hover) {
  :root .bg-590-10-on-hover:hover {
    background-color: rgba(var(--color-bg-590-rgb), 0.1);
  }
  :root .hover-parent:hover .bg-590-10-on-hover-parent {
    background-color: rgba(var(--color-bg-590-rgb), 0.1);
  }
}
:root .bg-590-20 {
  background-color: rgba(var(--color-bg-590-rgb), 0.2);
}
@media (hover: hover) {
  :root .bg-590-20-on-hover:hover {
    background-color: rgba(var(--color-bg-590-rgb), 0.2);
  }
  :root .hover-parent:hover .bg-590-20-on-hover-parent {
    background-color: rgba(var(--color-bg-590-rgb), 0.2);
  }
}
:root .bg-590-30 {
  background-color: rgba(var(--color-bg-590-rgb), 0.3);
}
@media (hover: hover) {
  :root .bg-590-30-on-hover:hover {
    background-color: rgba(var(--color-bg-590-rgb), 0.3);
  }
  :root .hover-parent:hover .bg-590-30-on-hover-parent {
    background-color: rgba(var(--color-bg-590-rgb), 0.3);
  }
}
:root .bg-590-40 {
  background-color: rgba(var(--color-bg-590-rgb), 0.4);
}
@media (hover: hover) {
  :root .bg-590-40-on-hover:hover {
    background-color: rgba(var(--color-bg-590-rgb), 0.4);
  }
  :root .hover-parent:hover .bg-590-40-on-hover-parent {
    background-color: rgba(var(--color-bg-590-rgb), 0.4);
  }
}
:root .bg-590-50 {
  background-color: rgba(var(--color-bg-590-rgb), 0.5);
}
@media (hover: hover) {
  :root .bg-590-50-on-hover:hover {
    background-color: rgba(var(--color-bg-590-rgb), 0.5);
  }
  :root .hover-parent:hover .bg-590-50-on-hover-parent {
    background-color: rgba(var(--color-bg-590-rgb), 0.5);
  }
}
:root .bg-590-60 {
  background-color: rgba(var(--color-bg-590-rgb), 0.6);
}
@media (hover: hover) {
  :root .bg-590-60-on-hover:hover {
    background-color: rgba(var(--color-bg-590-rgb), 0.6);
  }
  :root .hover-parent:hover .bg-590-60-on-hover-parent {
    background-color: rgba(var(--color-bg-590-rgb), 0.6);
  }
}
:root .bg-590-70 {
  background-color: rgba(var(--color-bg-590-rgb), 0.7);
}
@media (hover: hover) {
  :root .bg-590-70-on-hover:hover {
    background-color: rgba(var(--color-bg-590-rgb), 0.7);
  }
  :root .hover-parent:hover .bg-590-70-on-hover-parent {
    background-color: rgba(var(--color-bg-590-rgb), 0.7);
  }
}
:root .bg-590-80 {
  background-color: rgba(var(--color-bg-590-rgb), 0.8);
}
@media (hover: hover) {
  :root .bg-590-80-on-hover:hover {
    background-color: rgba(var(--color-bg-590-rgb), 0.8);
  }
  :root .hover-parent:hover .bg-590-80-on-hover-parent {
    background-color: rgba(var(--color-bg-590-rgb), 0.8);
  }
}
:root .bg-590-90 {
  background-color: rgba(var(--color-bg-590-rgb), 0.9);
}
@media (hover: hover) {
  :root .bg-590-90-on-hover:hover {
    background-color: rgba(var(--color-bg-590-rgb), 0.9);
  }
  :root .hover-parent:hover .bg-590-90-on-hover-parent {
    background-color: rgba(var(--color-bg-590-rgb), 0.9);
  }
}
:root .bg-590-100 {
  background-color: rgba(var(--color-bg-590-rgb), 1);
}
@media (hover: hover) {
  :root .bg-590-100-on-hover:hover {
    background-color: rgba(var(--color-bg-590-rgb), 1);
  }
  :root .hover-parent:hover .bg-590-100-on-hover-parent {
    background-color: rgba(var(--color-bg-590-rgb), 1);
  }
}
:root .bg-590-as-fc {
  color: rgb(var(--color-bg-590-rgb));
}
:root .bg-590-as-border, :root .bg-590-as-line {
  border-color: rgb(var(--color-bg-590-rgb));
}

@media (hover: hover) {
  :root .bg-590-on-hover:hover {
    background-color: rgb(var(--color-bg-590-rgb));
  }
  :root .hover-parent:hover .bg-590-on-hover-parent {
    background-color: rgb(var(--color-bg-590-rgb));
  }
}
:root .bg-standard-hover {
  background-color: rgba(var(--color-bg-standard-hover-rgb));
}

@media (hover: hover) {
  :root .bg-standard-hover-on-hover:hover {
    background-color: rgba(var(--color-bg-standard-hover-rgb));
  }
  :root .hover-parent:hover .bg-standard-hover-on-hover-parent {
    background-color: rgba(var(--color-bg-standard-hover-rgb));
  }
}
.bg-standard-hover-over-160-on-hover:hover, .bg-standard-hover-over-160 {
  background: linear-gradient(0deg, rgba(var(--color-bg-standard-hover-rgb)), rgba(var(--color-bg-standard-hover-rgb))), rgb(var(--color-bg-160-rgb));
}
.bg-standard-hover-over-170-on-hover:hover, .bg-standard-hover-over-170 {
  background: linear-gradient(0deg, rgba(var(--color-bg-standard-hover-rgb)), rgba(var(--color-bg-standard-hover-rgb))), rgb(var(--color-bg-170-rgb));
}
.bg-standard-hover-over-180-on-hover:hover, .bg-standard-hover-over-180 {
  background: linear-gradient(0deg, rgba(var(--color-bg-standard-hover-rgb)), rgba(var(--color-bg-standard-hover-rgb))), rgb(var(--color-bg-180-rgb));
}
.bg-standard-hover-over-190-on-hover:hover, .bg-standard-hover-over-190 {
  background: linear-gradient(0deg, rgba(var(--color-bg-standard-hover-rgb)), rgba(var(--color-bg-standard-hover-rgb))), rgb(var(--color-bg-190-rgb));
}
.bg-standard-hover-over-260-on-hover:hover, .bg-standard-hover-over-260 {
  background: linear-gradient(0deg, rgba(var(--color-bg-standard-hover-rgb)), rgba(var(--color-bg-standard-hover-rgb))), rgb(var(--color-bg-260-rgb));
}
.bg-standard-hover-over-270-on-hover:hover, .bg-standard-hover-over-270 {
  background: linear-gradient(0deg, rgba(var(--color-bg-standard-hover-rgb)), rgba(var(--color-bg-standard-hover-rgb))), rgb(var(--color-bg-270-rgb));
}
.bg-standard-hover-over-280-on-hover:hover, .bg-standard-hover-over-280 {
  background: linear-gradient(0deg, rgba(var(--color-bg-standard-hover-rgb)), rgba(var(--color-bg-standard-hover-rgb))), rgb(var(--color-bg-280-rgb));
}
.bg-standard-hover-over-290-on-hover:hover, .bg-standard-hover-over-290 {
  background: linear-gradient(0deg, rgba(var(--color-bg-standard-hover-rgb)), rgba(var(--color-bg-standard-hover-rgb))), rgb(var(--color-bg-290-rgb));
}
.bg-standard-hover-over-360-on-hover:hover, .bg-standard-hover-over-360 {
  background: linear-gradient(0deg, rgba(var(--color-bg-standard-hover-rgb)), rgba(var(--color-bg-standard-hover-rgb))), rgb(var(--color-bg-360-rgb));
}
.bg-standard-hover-over-370-on-hover:hover, .bg-standard-hover-over-370 {
  background: linear-gradient(0deg, rgba(var(--color-bg-standard-hover-rgb)), rgba(var(--color-bg-standard-hover-rgb))), rgb(var(--color-bg-370-rgb));
}
.bg-standard-hover-over-380-on-hover:hover, .bg-standard-hover-over-380 {
  background: linear-gradient(0deg, rgba(var(--color-bg-standard-hover-rgb)), rgba(var(--color-bg-standard-hover-rgb))), rgb(var(--color-bg-380-rgb));
}
.bg-standard-hover-over-390-on-hover:hover, .bg-standard-hover-over-390 {
  background: linear-gradient(0deg, rgba(var(--color-bg-standard-hover-rgb)), rgba(var(--color-bg-standard-hover-rgb))), rgb(var(--color-bg-390-rgb));
}
.bg-standard-hover-over-460-on-hover:hover, .bg-standard-hover-over-460 {
  background: linear-gradient(0deg, rgba(var(--color-bg-standard-hover-rgb)), rgba(var(--color-bg-standard-hover-rgb))), rgb(var(--color-bg-460-rgb));
}
.bg-standard-hover-over-470-on-hover:hover, .bg-standard-hover-over-470 {
  background: linear-gradient(0deg, rgba(var(--color-bg-standard-hover-rgb)), rgba(var(--color-bg-standard-hover-rgb))), rgb(var(--color-bg-470-rgb));
}
.bg-standard-hover-over-480-on-hover:hover, .bg-standard-hover-over-480 {
  background: linear-gradient(0deg, rgba(var(--color-bg-standard-hover-rgb)), rgba(var(--color-bg-standard-hover-rgb))), rgb(var(--color-bg-480-rgb));
}
.bg-standard-hover-over-490-on-hover:hover, .bg-standard-hover-over-490 {
  background: linear-gradient(0deg, rgba(var(--color-bg-standard-hover-rgb)), rgba(var(--color-bg-standard-hover-rgb))), rgb(var(--color-bg-490-rgb));
}
.bg-standard-hover-over-560-on-hover:hover, .bg-standard-hover-over-560 {
  background: linear-gradient(0deg, rgba(var(--color-bg-standard-hover-rgb)), rgba(var(--color-bg-standard-hover-rgb))), rgb(var(--color-bg-560-rgb));
}
.bg-standard-hover-over-570-on-hover:hover, .bg-standard-hover-over-570 {
  background: linear-gradient(0deg, rgba(var(--color-bg-standard-hover-rgb)), rgba(var(--color-bg-standard-hover-rgb))), rgb(var(--color-bg-570-rgb));
}
.bg-standard-hover-over-580-on-hover:hover, .bg-standard-hover-over-580 {
  background: linear-gradient(0deg, rgba(var(--color-bg-standard-hover-rgb)), rgba(var(--color-bg-standard-hover-rgb))), rgb(var(--color-bg-580-rgb));
}
.bg-standard-hover-over-590-on-hover:hover, .bg-standard-hover-over-590 {
  background: linear-gradient(0deg, rgba(var(--color-bg-standard-hover-rgb)), rgba(var(--color-bg-standard-hover-rgb))), rgb(var(--color-bg-590-rgb));
}
:root .bg-primary, :root .bg-theme-primary {
  background-color: rgb(var(--color-primary-rgb));
}
:root .bg-primary-0, :root .bg-theme-primary-0 {
  background-color: rgba(var(--color-primary-rgb), 0);
}
@media (hover: hover) {
  :root .bg-primary-0-on-hover:hover, :root .bg-theme-primary-0-on-hover:hover {
    background-color: rgba(var(--color-primary-rgb), 0);
  }
  :root .hover-parent:hover .bg-primary-0-on-hover-parent, :root .hover-parent:hover .bg-theme-primary-0-on-hover-parent {
    background-color: rgba(var(--color-primary-rgb), 0);
  }
}
:root .bg-primary-10, :root .bg-theme-primary-10 {
  background-color: rgba(var(--color-primary-rgb), 0.1);
}
@media (hover: hover) {
  :root .bg-primary-10-on-hover:hover, :root .bg-theme-primary-10-on-hover:hover {
    background-color: rgba(var(--color-primary-rgb), 0.1);
  }
  :root .hover-parent:hover .bg-primary-10-on-hover-parent, :root .hover-parent:hover .bg-theme-primary-10-on-hover-parent {
    background-color: rgba(var(--color-primary-rgb), 0.1);
  }
}
:root .bg-primary-20, :root .bg-theme-primary-20 {
  background-color: rgba(var(--color-primary-rgb), 0.2);
}
@media (hover: hover) {
  :root .bg-primary-20-on-hover:hover, :root .bg-theme-primary-20-on-hover:hover {
    background-color: rgba(var(--color-primary-rgb), 0.2);
  }
  :root .hover-parent:hover .bg-primary-20-on-hover-parent, :root .hover-parent:hover .bg-theme-primary-20-on-hover-parent {
    background-color: rgba(var(--color-primary-rgb), 0.2);
  }
}
:root .bg-primary-30, :root .bg-theme-primary-30 {
  background-color: rgba(var(--color-primary-rgb), 0.3);
}
@media (hover: hover) {
  :root .bg-primary-30-on-hover:hover, :root .bg-theme-primary-30-on-hover:hover {
    background-color: rgba(var(--color-primary-rgb), 0.3);
  }
  :root .hover-parent:hover .bg-primary-30-on-hover-parent, :root .hover-parent:hover .bg-theme-primary-30-on-hover-parent {
    background-color: rgba(var(--color-primary-rgb), 0.3);
  }
}
:root .bg-primary-40, :root .bg-theme-primary-40 {
  background-color: rgba(var(--color-primary-rgb), 0.4);
}
@media (hover: hover) {
  :root .bg-primary-40-on-hover:hover, :root .bg-theme-primary-40-on-hover:hover {
    background-color: rgba(var(--color-primary-rgb), 0.4);
  }
  :root .hover-parent:hover .bg-primary-40-on-hover-parent, :root .hover-parent:hover .bg-theme-primary-40-on-hover-parent {
    background-color: rgba(var(--color-primary-rgb), 0.4);
  }
}
:root .bg-primary-50, :root .bg-theme-primary-50 {
  background-color: rgba(var(--color-primary-rgb), 0.5);
}
@media (hover: hover) {
  :root .bg-primary-50-on-hover:hover, :root .bg-theme-primary-50-on-hover:hover {
    background-color: rgba(var(--color-primary-rgb), 0.5);
  }
  :root .hover-parent:hover .bg-primary-50-on-hover-parent, :root .hover-parent:hover .bg-theme-primary-50-on-hover-parent {
    background-color: rgba(var(--color-primary-rgb), 0.5);
  }
}
:root .bg-primary-60, :root .bg-theme-primary-60 {
  background-color: rgba(var(--color-primary-rgb), 0.6);
}
@media (hover: hover) {
  :root .bg-primary-60-on-hover:hover, :root .bg-theme-primary-60-on-hover:hover {
    background-color: rgba(var(--color-primary-rgb), 0.6);
  }
  :root .hover-parent:hover .bg-primary-60-on-hover-parent, :root .hover-parent:hover .bg-theme-primary-60-on-hover-parent {
    background-color: rgba(var(--color-primary-rgb), 0.6);
  }
}
:root .bg-primary-70, :root .bg-theme-primary-70 {
  background-color: rgba(var(--color-primary-rgb), 0.7);
}
@media (hover: hover) {
  :root .bg-primary-70-on-hover:hover, :root .bg-theme-primary-70-on-hover:hover {
    background-color: rgba(var(--color-primary-rgb), 0.7);
  }
  :root .hover-parent:hover .bg-primary-70-on-hover-parent, :root .hover-parent:hover .bg-theme-primary-70-on-hover-parent {
    background-color: rgba(var(--color-primary-rgb), 0.7);
  }
}
:root .bg-primary-80, :root .bg-theme-primary-80 {
  background-color: rgba(var(--color-primary-rgb), 0.8);
}
@media (hover: hover) {
  :root .bg-primary-80-on-hover:hover, :root .bg-theme-primary-80-on-hover:hover {
    background-color: rgba(var(--color-primary-rgb), 0.8);
  }
  :root .hover-parent:hover .bg-primary-80-on-hover-parent, :root .hover-parent:hover .bg-theme-primary-80-on-hover-parent {
    background-color: rgba(var(--color-primary-rgb), 0.8);
  }
}
:root .bg-primary-90, :root .bg-theme-primary-90 {
  background-color: rgba(var(--color-primary-rgb), 0.9);
}
@media (hover: hover) {
  :root .bg-primary-90-on-hover:hover, :root .bg-theme-primary-90-on-hover:hover {
    background-color: rgba(var(--color-primary-rgb), 0.9);
  }
  :root .hover-parent:hover .bg-primary-90-on-hover-parent, :root .hover-parent:hover .bg-theme-primary-90-on-hover-parent {
    background-color: rgba(var(--color-primary-rgb), 0.9);
  }
}
:root .bg-primary-100, :root .bg-theme-primary-100 {
  background-color: rgba(var(--color-primary-rgb), 1);
}
@media (hover: hover) {
  :root .bg-primary-100-on-hover:hover, :root .bg-theme-primary-100-on-hover:hover {
    background-color: rgba(var(--color-primary-rgb), 1);
  }
  :root .hover-parent:hover .bg-primary-100-on-hover-parent, :root .hover-parent:hover .bg-theme-primary-100-on-hover-parent {
    background-color: rgba(var(--color-primary-rgb), 1);
  }
}

@media (hover: hover) {
  :root .bg-primary-on-hover:hover, :root .bg-theme-primary-on-hover:hover {
    background-color: rgb(var(--color-primary-rgb));
  }
  :root .hover-parent:hover .bg-primary-on-hover-parent, :root .hover-parent:hover .bg-theme-primary-on-hover-parent {
    background-color: rgb(var(--color-primary-rgb));
  }
}
:root .bg-primary-dark, :root .bg-theme-primary-dark {
  background-color: rgb(var(--color-primary-dark-rgb));
}
:root .bg-primary-dark-0, :root .bg-theme-primary-dark-0 {
  background-color: rgba(var(--color-primary-dark-rgb), 0);
}
@media (hover: hover) {
  :root .bg-primary-dark-0-on-hover:hover, :root .bg-theme-primary-dark-0-on-hover:hover {
    background-color: rgba(var(--color-primary-dark-rgb), 0);
  }
  :root .hover-parent:hover .bg-primary-dark-0-on-hover-parent, :root .hover-parent:hover .bg-theme-primary-dark-0-on-hover-parent {
    background-color: rgba(var(--color-primary-dark-rgb), 0);
  }
}
:root .bg-primary-dark-10, :root .bg-theme-primary-dark-10 {
  background-color: rgba(var(--color-primary-dark-rgb), 0.1);
}
@media (hover: hover) {
  :root .bg-primary-dark-10-on-hover:hover, :root .bg-theme-primary-dark-10-on-hover:hover {
    background-color: rgba(var(--color-primary-dark-rgb), 0.1);
  }
  :root .hover-parent:hover .bg-primary-dark-10-on-hover-parent, :root .hover-parent:hover .bg-theme-primary-dark-10-on-hover-parent {
    background-color: rgba(var(--color-primary-dark-rgb), 0.1);
  }
}
:root .bg-primary-dark-20, :root .bg-theme-primary-dark-20 {
  background-color: rgba(var(--color-primary-dark-rgb), 0.2);
}
@media (hover: hover) {
  :root .bg-primary-dark-20-on-hover:hover, :root .bg-theme-primary-dark-20-on-hover:hover {
    background-color: rgba(var(--color-primary-dark-rgb), 0.2);
  }
  :root .hover-parent:hover .bg-primary-dark-20-on-hover-parent, :root .hover-parent:hover .bg-theme-primary-dark-20-on-hover-parent {
    background-color: rgba(var(--color-primary-dark-rgb), 0.2);
  }
}
:root .bg-primary-dark-30, :root .bg-theme-primary-dark-30 {
  background-color: rgba(var(--color-primary-dark-rgb), 0.3);
}
@media (hover: hover) {
  :root .bg-primary-dark-30-on-hover:hover, :root .bg-theme-primary-dark-30-on-hover:hover {
    background-color: rgba(var(--color-primary-dark-rgb), 0.3);
  }
  :root .hover-parent:hover .bg-primary-dark-30-on-hover-parent, :root .hover-parent:hover .bg-theme-primary-dark-30-on-hover-parent {
    background-color: rgba(var(--color-primary-dark-rgb), 0.3);
  }
}
:root .bg-primary-dark-40, :root .bg-theme-primary-dark-40 {
  background-color: rgba(var(--color-primary-dark-rgb), 0.4);
}
@media (hover: hover) {
  :root .bg-primary-dark-40-on-hover:hover, :root .bg-theme-primary-dark-40-on-hover:hover {
    background-color: rgba(var(--color-primary-dark-rgb), 0.4);
  }
  :root .hover-parent:hover .bg-primary-dark-40-on-hover-parent, :root .hover-parent:hover .bg-theme-primary-dark-40-on-hover-parent {
    background-color: rgba(var(--color-primary-dark-rgb), 0.4);
  }
}
:root .bg-primary-dark-50, :root .bg-theme-primary-dark-50 {
  background-color: rgba(var(--color-primary-dark-rgb), 0.5);
}
@media (hover: hover) {
  :root .bg-primary-dark-50-on-hover:hover, :root .bg-theme-primary-dark-50-on-hover:hover {
    background-color: rgba(var(--color-primary-dark-rgb), 0.5);
  }
  :root .hover-parent:hover .bg-primary-dark-50-on-hover-parent, :root .hover-parent:hover .bg-theme-primary-dark-50-on-hover-parent {
    background-color: rgba(var(--color-primary-dark-rgb), 0.5);
  }
}
:root .bg-primary-dark-60, :root .bg-theme-primary-dark-60 {
  background-color: rgba(var(--color-primary-dark-rgb), 0.6);
}
@media (hover: hover) {
  :root .bg-primary-dark-60-on-hover:hover, :root .bg-theme-primary-dark-60-on-hover:hover {
    background-color: rgba(var(--color-primary-dark-rgb), 0.6);
  }
  :root .hover-parent:hover .bg-primary-dark-60-on-hover-parent, :root .hover-parent:hover .bg-theme-primary-dark-60-on-hover-parent {
    background-color: rgba(var(--color-primary-dark-rgb), 0.6);
  }
}
:root .bg-primary-dark-70, :root .bg-theme-primary-dark-70 {
  background-color: rgba(var(--color-primary-dark-rgb), 0.7);
}
@media (hover: hover) {
  :root .bg-primary-dark-70-on-hover:hover, :root .bg-theme-primary-dark-70-on-hover:hover {
    background-color: rgba(var(--color-primary-dark-rgb), 0.7);
  }
  :root .hover-parent:hover .bg-primary-dark-70-on-hover-parent, :root .hover-parent:hover .bg-theme-primary-dark-70-on-hover-parent {
    background-color: rgba(var(--color-primary-dark-rgb), 0.7);
  }
}
:root .bg-primary-dark-80, :root .bg-theme-primary-dark-80 {
  background-color: rgba(var(--color-primary-dark-rgb), 0.8);
}
@media (hover: hover) {
  :root .bg-primary-dark-80-on-hover:hover, :root .bg-theme-primary-dark-80-on-hover:hover {
    background-color: rgba(var(--color-primary-dark-rgb), 0.8);
  }
  :root .hover-parent:hover .bg-primary-dark-80-on-hover-parent, :root .hover-parent:hover .bg-theme-primary-dark-80-on-hover-parent {
    background-color: rgba(var(--color-primary-dark-rgb), 0.8);
  }
}
:root .bg-primary-dark-90, :root .bg-theme-primary-dark-90 {
  background-color: rgba(var(--color-primary-dark-rgb), 0.9);
}
@media (hover: hover) {
  :root .bg-primary-dark-90-on-hover:hover, :root .bg-theme-primary-dark-90-on-hover:hover {
    background-color: rgba(var(--color-primary-dark-rgb), 0.9);
  }
  :root .hover-parent:hover .bg-primary-dark-90-on-hover-parent, :root .hover-parent:hover .bg-theme-primary-dark-90-on-hover-parent {
    background-color: rgba(var(--color-primary-dark-rgb), 0.9);
  }
}
:root .bg-primary-dark-100, :root .bg-theme-primary-dark-100 {
  background-color: rgba(var(--color-primary-dark-rgb), 1);
}
@media (hover: hover) {
  :root .bg-primary-dark-100-on-hover:hover, :root .bg-theme-primary-dark-100-on-hover:hover {
    background-color: rgba(var(--color-primary-dark-rgb), 1);
  }
  :root .hover-parent:hover .bg-primary-dark-100-on-hover-parent, :root .hover-parent:hover .bg-theme-primary-dark-100-on-hover-parent {
    background-color: rgba(var(--color-primary-dark-rgb), 1);
  }
}

@media (hover: hover) {
  :root .bg-primary-dark-on-hover:hover, :root .bg-theme-primary-dark-on-hover:hover {
    background-color: rgb(var(--color-primary-dark-rgb));
  }
  :root .hover-parent:hover .bg-primary-dark-on-hover-parent, :root .hover-parent:hover .bg-theme-primary-dark-on-hover-parent {
    background-color: rgb(var(--color-primary-dark-rgb));
  }
}
:root .bg-primary-500, :root .bg-theme-primary-500 {
  background-color: rgb(var(--color-primary-500-rgb));
}
:root .bg-primary-500-0, :root .bg-theme-primary-500-0 {
  background-color: rgba(var(--color-primary-500-rgb), 0);
}
@media (hover: hover) {
  :root .bg-primary-500-0-on-hover:hover, :root .bg-theme-primary-500-0-on-hover:hover {
    background-color: rgba(var(--color-primary-500-rgb), 0);
  }
  :root .hover-parent:hover .bg-primary-500-0-on-hover-parent, :root .hover-parent:hover .bg-theme-primary-500-0-on-hover-parent {
    background-color: rgba(var(--color-primary-500-rgb), 0);
  }
}
:root .bg-primary-500-10, :root .bg-theme-primary-500-10 {
  background-color: rgba(var(--color-primary-500-rgb), 0.1);
}
@media (hover: hover) {
  :root .bg-primary-500-10-on-hover:hover, :root .bg-theme-primary-500-10-on-hover:hover {
    background-color: rgba(var(--color-primary-500-rgb), 0.1);
  }
  :root .hover-parent:hover .bg-primary-500-10-on-hover-parent, :root .hover-parent:hover .bg-theme-primary-500-10-on-hover-parent {
    background-color: rgba(var(--color-primary-500-rgb), 0.1);
  }
}
:root .bg-primary-500-20, :root .bg-theme-primary-500-20 {
  background-color: rgba(var(--color-primary-500-rgb), 0.2);
}
@media (hover: hover) {
  :root .bg-primary-500-20-on-hover:hover, :root .bg-theme-primary-500-20-on-hover:hover {
    background-color: rgba(var(--color-primary-500-rgb), 0.2);
  }
  :root .hover-parent:hover .bg-primary-500-20-on-hover-parent, :root .hover-parent:hover .bg-theme-primary-500-20-on-hover-parent {
    background-color: rgba(var(--color-primary-500-rgb), 0.2);
  }
}
:root .bg-primary-500-30, :root .bg-theme-primary-500-30 {
  background-color: rgba(var(--color-primary-500-rgb), 0.3);
}
@media (hover: hover) {
  :root .bg-primary-500-30-on-hover:hover, :root .bg-theme-primary-500-30-on-hover:hover {
    background-color: rgba(var(--color-primary-500-rgb), 0.3);
  }
  :root .hover-parent:hover .bg-primary-500-30-on-hover-parent, :root .hover-parent:hover .bg-theme-primary-500-30-on-hover-parent {
    background-color: rgba(var(--color-primary-500-rgb), 0.3);
  }
}
:root .bg-primary-500-40, :root .bg-theme-primary-500-40 {
  background-color: rgba(var(--color-primary-500-rgb), 0.4);
}
@media (hover: hover) {
  :root .bg-primary-500-40-on-hover:hover, :root .bg-theme-primary-500-40-on-hover:hover {
    background-color: rgba(var(--color-primary-500-rgb), 0.4);
  }
  :root .hover-parent:hover .bg-primary-500-40-on-hover-parent, :root .hover-parent:hover .bg-theme-primary-500-40-on-hover-parent {
    background-color: rgba(var(--color-primary-500-rgb), 0.4);
  }
}
:root .bg-primary-500-50, :root .bg-theme-primary-500-50 {
  background-color: rgba(var(--color-primary-500-rgb), 0.5);
}
@media (hover: hover) {
  :root .bg-primary-500-50-on-hover:hover, :root .bg-theme-primary-500-50-on-hover:hover {
    background-color: rgba(var(--color-primary-500-rgb), 0.5);
  }
  :root .hover-parent:hover .bg-primary-500-50-on-hover-parent, :root .hover-parent:hover .bg-theme-primary-500-50-on-hover-parent {
    background-color: rgba(var(--color-primary-500-rgb), 0.5);
  }
}
:root .bg-primary-500-60, :root .bg-theme-primary-500-60 {
  background-color: rgba(var(--color-primary-500-rgb), 0.6);
}
@media (hover: hover) {
  :root .bg-primary-500-60-on-hover:hover, :root .bg-theme-primary-500-60-on-hover:hover {
    background-color: rgba(var(--color-primary-500-rgb), 0.6);
  }
  :root .hover-parent:hover .bg-primary-500-60-on-hover-parent, :root .hover-parent:hover .bg-theme-primary-500-60-on-hover-parent {
    background-color: rgba(var(--color-primary-500-rgb), 0.6);
  }
}
:root .bg-primary-500-70, :root .bg-theme-primary-500-70 {
  background-color: rgba(var(--color-primary-500-rgb), 0.7);
}
@media (hover: hover) {
  :root .bg-primary-500-70-on-hover:hover, :root .bg-theme-primary-500-70-on-hover:hover {
    background-color: rgba(var(--color-primary-500-rgb), 0.7);
  }
  :root .hover-parent:hover .bg-primary-500-70-on-hover-parent, :root .hover-parent:hover .bg-theme-primary-500-70-on-hover-parent {
    background-color: rgba(var(--color-primary-500-rgb), 0.7);
  }
}
:root .bg-primary-500-80, :root .bg-theme-primary-500-80 {
  background-color: rgba(var(--color-primary-500-rgb), 0.8);
}
@media (hover: hover) {
  :root .bg-primary-500-80-on-hover:hover, :root .bg-theme-primary-500-80-on-hover:hover {
    background-color: rgba(var(--color-primary-500-rgb), 0.8);
  }
  :root .hover-parent:hover .bg-primary-500-80-on-hover-parent, :root .hover-parent:hover .bg-theme-primary-500-80-on-hover-parent {
    background-color: rgba(var(--color-primary-500-rgb), 0.8);
  }
}
:root .bg-primary-500-90, :root .bg-theme-primary-500-90 {
  background-color: rgba(var(--color-primary-500-rgb), 0.9);
}
@media (hover: hover) {
  :root .bg-primary-500-90-on-hover:hover, :root .bg-theme-primary-500-90-on-hover:hover {
    background-color: rgba(var(--color-primary-500-rgb), 0.9);
  }
  :root .hover-parent:hover .bg-primary-500-90-on-hover-parent, :root .hover-parent:hover .bg-theme-primary-500-90-on-hover-parent {
    background-color: rgba(var(--color-primary-500-rgb), 0.9);
  }
}
:root .bg-primary-500-100, :root .bg-theme-primary-500-100 {
  background-color: rgba(var(--color-primary-500-rgb), 1);
}
@media (hover: hover) {
  :root .bg-primary-500-100-on-hover:hover, :root .bg-theme-primary-500-100-on-hover:hover {
    background-color: rgba(var(--color-primary-500-rgb), 1);
  }
  :root .hover-parent:hover .bg-primary-500-100-on-hover-parent, :root .hover-parent:hover .bg-theme-primary-500-100-on-hover-parent {
    background-color: rgba(var(--color-primary-500-rgb), 1);
  }
}

@media (hover: hover) {
  :root .bg-primary-500-on-hover:hover, :root .bg-theme-primary-500-on-hover:hover {
    background-color: rgb(var(--color-primary-500-rgb));
  }
  :root .hover-parent:hover .bg-primary-500-on-hover-parent, :root .hover-parent:hover .bg-theme-primary-500-on-hover-parent {
    background-color: rgb(var(--color-primary-500-rgb));
  }
}
:root .bg-primary-700, :root .bg-theme-primary-700 {
  background-color: rgb(var(--color-primary-700-rgb));
}
:root .bg-primary-700-0, :root .bg-theme-primary-700-0 {
  background-color: rgba(var(--color-primary-700-rgb), 0);
}
@media (hover: hover) {
  :root .bg-primary-700-0-on-hover:hover, :root .bg-theme-primary-700-0-on-hover:hover {
    background-color: rgba(var(--color-primary-700-rgb), 0);
  }
  :root .hover-parent:hover .bg-primary-700-0-on-hover-parent, :root .hover-parent:hover .bg-theme-primary-700-0-on-hover-parent {
    background-color: rgba(var(--color-primary-700-rgb), 0);
  }
}
:root .bg-primary-700-10, :root .bg-theme-primary-700-10 {
  background-color: rgba(var(--color-primary-700-rgb), 0.1);
}
@media (hover: hover) {
  :root .bg-primary-700-10-on-hover:hover, :root .bg-theme-primary-700-10-on-hover:hover {
    background-color: rgba(var(--color-primary-700-rgb), 0.1);
  }
  :root .hover-parent:hover .bg-primary-700-10-on-hover-parent, :root .hover-parent:hover .bg-theme-primary-700-10-on-hover-parent {
    background-color: rgba(var(--color-primary-700-rgb), 0.1);
  }
}
:root .bg-primary-700-20, :root .bg-theme-primary-700-20 {
  background-color: rgba(var(--color-primary-700-rgb), 0.2);
}
@media (hover: hover) {
  :root .bg-primary-700-20-on-hover:hover, :root .bg-theme-primary-700-20-on-hover:hover {
    background-color: rgba(var(--color-primary-700-rgb), 0.2);
  }
  :root .hover-parent:hover .bg-primary-700-20-on-hover-parent, :root .hover-parent:hover .bg-theme-primary-700-20-on-hover-parent {
    background-color: rgba(var(--color-primary-700-rgb), 0.2);
  }
}
:root .bg-primary-700-30, :root .bg-theme-primary-700-30 {
  background-color: rgba(var(--color-primary-700-rgb), 0.3);
}
@media (hover: hover) {
  :root .bg-primary-700-30-on-hover:hover, :root .bg-theme-primary-700-30-on-hover:hover {
    background-color: rgba(var(--color-primary-700-rgb), 0.3);
  }
  :root .hover-parent:hover .bg-primary-700-30-on-hover-parent, :root .hover-parent:hover .bg-theme-primary-700-30-on-hover-parent {
    background-color: rgba(var(--color-primary-700-rgb), 0.3);
  }
}
:root .bg-primary-700-40, :root .bg-theme-primary-700-40 {
  background-color: rgba(var(--color-primary-700-rgb), 0.4);
}
@media (hover: hover) {
  :root .bg-primary-700-40-on-hover:hover, :root .bg-theme-primary-700-40-on-hover:hover {
    background-color: rgba(var(--color-primary-700-rgb), 0.4);
  }
  :root .hover-parent:hover .bg-primary-700-40-on-hover-parent, :root .hover-parent:hover .bg-theme-primary-700-40-on-hover-parent {
    background-color: rgba(var(--color-primary-700-rgb), 0.4);
  }
}
:root .bg-primary-700-50, :root .bg-theme-primary-700-50 {
  background-color: rgba(var(--color-primary-700-rgb), 0.5);
}
@media (hover: hover) {
  :root .bg-primary-700-50-on-hover:hover, :root .bg-theme-primary-700-50-on-hover:hover {
    background-color: rgba(var(--color-primary-700-rgb), 0.5);
  }
  :root .hover-parent:hover .bg-primary-700-50-on-hover-parent, :root .hover-parent:hover .bg-theme-primary-700-50-on-hover-parent {
    background-color: rgba(var(--color-primary-700-rgb), 0.5);
  }
}
:root .bg-primary-700-60, :root .bg-theme-primary-700-60 {
  background-color: rgba(var(--color-primary-700-rgb), 0.6);
}
@media (hover: hover) {
  :root .bg-primary-700-60-on-hover:hover, :root .bg-theme-primary-700-60-on-hover:hover {
    background-color: rgba(var(--color-primary-700-rgb), 0.6);
  }
  :root .hover-parent:hover .bg-primary-700-60-on-hover-parent, :root .hover-parent:hover .bg-theme-primary-700-60-on-hover-parent {
    background-color: rgba(var(--color-primary-700-rgb), 0.6);
  }
}
:root .bg-primary-700-70, :root .bg-theme-primary-700-70 {
  background-color: rgba(var(--color-primary-700-rgb), 0.7);
}
@media (hover: hover) {
  :root .bg-primary-700-70-on-hover:hover, :root .bg-theme-primary-700-70-on-hover:hover {
    background-color: rgba(var(--color-primary-700-rgb), 0.7);
  }
  :root .hover-parent:hover .bg-primary-700-70-on-hover-parent, :root .hover-parent:hover .bg-theme-primary-700-70-on-hover-parent {
    background-color: rgba(var(--color-primary-700-rgb), 0.7);
  }
}
:root .bg-primary-700-80, :root .bg-theme-primary-700-80 {
  background-color: rgba(var(--color-primary-700-rgb), 0.8);
}
@media (hover: hover) {
  :root .bg-primary-700-80-on-hover:hover, :root .bg-theme-primary-700-80-on-hover:hover {
    background-color: rgba(var(--color-primary-700-rgb), 0.8);
  }
  :root .hover-parent:hover .bg-primary-700-80-on-hover-parent, :root .hover-parent:hover .bg-theme-primary-700-80-on-hover-parent {
    background-color: rgba(var(--color-primary-700-rgb), 0.8);
  }
}
:root .bg-primary-700-90, :root .bg-theme-primary-700-90 {
  background-color: rgba(var(--color-primary-700-rgb), 0.9);
}
@media (hover: hover) {
  :root .bg-primary-700-90-on-hover:hover, :root .bg-theme-primary-700-90-on-hover:hover {
    background-color: rgba(var(--color-primary-700-rgb), 0.9);
  }
  :root .hover-parent:hover .bg-primary-700-90-on-hover-parent, :root .hover-parent:hover .bg-theme-primary-700-90-on-hover-parent {
    background-color: rgba(var(--color-primary-700-rgb), 0.9);
  }
}
:root .bg-primary-700-100, :root .bg-theme-primary-700-100 {
  background-color: rgba(var(--color-primary-700-rgb), 1);
}
@media (hover: hover) {
  :root .bg-primary-700-100-on-hover:hover, :root .bg-theme-primary-700-100-on-hover:hover {
    background-color: rgba(var(--color-primary-700-rgb), 1);
  }
  :root .hover-parent:hover .bg-primary-700-100-on-hover-parent, :root .hover-parent:hover .bg-theme-primary-700-100-on-hover-parent {
    background-color: rgba(var(--color-primary-700-rgb), 1);
  }
}

@media (hover: hover) {
  :root .bg-primary-700-on-hover:hover, :root .bg-theme-primary-700-on-hover:hover {
    background-color: rgb(var(--color-primary-700-rgb));
  }
  :root .hover-parent:hover .bg-primary-700-on-hover-parent, :root .hover-parent:hover .bg-theme-primary-700-on-hover-parent {
    background-color: rgb(var(--color-primary-700-rgb));
  }
}
:root .bg-warn, :root .bg-theme-warn {
  background-color: rgb(var(--color-warn-rgb));
}
:root .bg-warn-0, :root .bg-theme-warn-0 {
  background-color: rgba(var(--color-warn-rgb), 0);
}
@media (hover: hover) {
  :root .bg-warn-0-on-hover:hover, :root .bg-theme-warn-0-on-hover:hover {
    background-color: rgba(var(--color-warn-rgb), 0);
  }
  :root .hover-parent:hover .bg-warn-0-on-hover-parent, :root .hover-parent:hover .bg-theme-warn-0-on-hover-parent {
    background-color: rgba(var(--color-warn-rgb), 0);
  }
}
:root .bg-warn-10, :root .bg-theme-warn-10 {
  background-color: rgba(var(--color-warn-rgb), 0.1);
}
@media (hover: hover) {
  :root .bg-warn-10-on-hover:hover, :root .bg-theme-warn-10-on-hover:hover {
    background-color: rgba(var(--color-warn-rgb), 0.1);
  }
  :root .hover-parent:hover .bg-warn-10-on-hover-parent, :root .hover-parent:hover .bg-theme-warn-10-on-hover-parent {
    background-color: rgba(var(--color-warn-rgb), 0.1);
  }
}
:root .bg-warn-20, :root .bg-theme-warn-20 {
  background-color: rgba(var(--color-warn-rgb), 0.2);
}
@media (hover: hover) {
  :root .bg-warn-20-on-hover:hover, :root .bg-theme-warn-20-on-hover:hover {
    background-color: rgba(var(--color-warn-rgb), 0.2);
  }
  :root .hover-parent:hover .bg-warn-20-on-hover-parent, :root .hover-parent:hover .bg-theme-warn-20-on-hover-parent {
    background-color: rgba(var(--color-warn-rgb), 0.2);
  }
}
:root .bg-warn-30, :root .bg-theme-warn-30 {
  background-color: rgba(var(--color-warn-rgb), 0.3);
}
@media (hover: hover) {
  :root .bg-warn-30-on-hover:hover, :root .bg-theme-warn-30-on-hover:hover {
    background-color: rgba(var(--color-warn-rgb), 0.3);
  }
  :root .hover-parent:hover .bg-warn-30-on-hover-parent, :root .hover-parent:hover .bg-theme-warn-30-on-hover-parent {
    background-color: rgba(var(--color-warn-rgb), 0.3);
  }
}
:root .bg-warn-40, :root .bg-theme-warn-40 {
  background-color: rgba(var(--color-warn-rgb), 0.4);
}
@media (hover: hover) {
  :root .bg-warn-40-on-hover:hover, :root .bg-theme-warn-40-on-hover:hover {
    background-color: rgba(var(--color-warn-rgb), 0.4);
  }
  :root .hover-parent:hover .bg-warn-40-on-hover-parent, :root .hover-parent:hover .bg-theme-warn-40-on-hover-parent {
    background-color: rgba(var(--color-warn-rgb), 0.4);
  }
}
:root .bg-warn-50, :root .bg-theme-warn-50 {
  background-color: rgba(var(--color-warn-rgb), 0.5);
}
@media (hover: hover) {
  :root .bg-warn-50-on-hover:hover, :root .bg-theme-warn-50-on-hover:hover {
    background-color: rgba(var(--color-warn-rgb), 0.5);
  }
  :root .hover-parent:hover .bg-warn-50-on-hover-parent, :root .hover-parent:hover .bg-theme-warn-50-on-hover-parent {
    background-color: rgba(var(--color-warn-rgb), 0.5);
  }
}
:root .bg-warn-60, :root .bg-theme-warn-60 {
  background-color: rgba(var(--color-warn-rgb), 0.6);
}
@media (hover: hover) {
  :root .bg-warn-60-on-hover:hover, :root .bg-theme-warn-60-on-hover:hover {
    background-color: rgba(var(--color-warn-rgb), 0.6);
  }
  :root .hover-parent:hover .bg-warn-60-on-hover-parent, :root .hover-parent:hover .bg-theme-warn-60-on-hover-parent {
    background-color: rgba(var(--color-warn-rgb), 0.6);
  }
}
:root .bg-warn-70, :root .bg-theme-warn-70 {
  background-color: rgba(var(--color-warn-rgb), 0.7);
}
@media (hover: hover) {
  :root .bg-warn-70-on-hover:hover, :root .bg-theme-warn-70-on-hover:hover {
    background-color: rgba(var(--color-warn-rgb), 0.7);
  }
  :root .hover-parent:hover .bg-warn-70-on-hover-parent, :root .hover-parent:hover .bg-theme-warn-70-on-hover-parent {
    background-color: rgba(var(--color-warn-rgb), 0.7);
  }
}
:root .bg-warn-80, :root .bg-theme-warn-80 {
  background-color: rgba(var(--color-warn-rgb), 0.8);
}
@media (hover: hover) {
  :root .bg-warn-80-on-hover:hover, :root .bg-theme-warn-80-on-hover:hover {
    background-color: rgba(var(--color-warn-rgb), 0.8);
  }
  :root .hover-parent:hover .bg-warn-80-on-hover-parent, :root .hover-parent:hover .bg-theme-warn-80-on-hover-parent {
    background-color: rgba(var(--color-warn-rgb), 0.8);
  }
}
:root .bg-warn-90, :root .bg-theme-warn-90 {
  background-color: rgba(var(--color-warn-rgb), 0.9);
}
@media (hover: hover) {
  :root .bg-warn-90-on-hover:hover, :root .bg-theme-warn-90-on-hover:hover {
    background-color: rgba(var(--color-warn-rgb), 0.9);
  }
  :root .hover-parent:hover .bg-warn-90-on-hover-parent, :root .hover-parent:hover .bg-theme-warn-90-on-hover-parent {
    background-color: rgba(var(--color-warn-rgb), 0.9);
  }
}
:root .bg-warn-100, :root .bg-theme-warn-100 {
  background-color: rgba(var(--color-warn-rgb), 1);
}
@media (hover: hover) {
  :root .bg-warn-100-on-hover:hover, :root .bg-theme-warn-100-on-hover:hover {
    background-color: rgba(var(--color-warn-rgb), 1);
  }
  :root .hover-parent:hover .bg-warn-100-on-hover-parent, :root .hover-parent:hover .bg-theme-warn-100-on-hover-parent {
    background-color: rgba(var(--color-warn-rgb), 1);
  }
}

@media (hover: hover) {
  :root .bg-warn-on-hover:hover, :root .bg-theme-warn-on-hover:hover {
    background-color: rgb(var(--color-warn-rgb));
  }
  :root .hover-parent:hover .bg-warn-on-hover-parent, :root .hover-parent:hover .bg-theme-warn-on-hover-parent {
    background-color: rgb(var(--color-warn-rgb));
  }
}
:root .bg-warn-dark, :root .bg-theme-warn-dark {
  background-color: rgb(var(--color-warn-dark-rgb));
}
:root .bg-warn-dark-0, :root .bg-theme-warn-dark-0 {
  background-color: rgba(var(--color-warn-dark-rgb), 0);
}
@media (hover: hover) {
  :root .bg-warn-dark-0-on-hover:hover, :root .bg-theme-warn-dark-0-on-hover:hover {
    background-color: rgba(var(--color-warn-dark-rgb), 0);
  }
  :root .hover-parent:hover .bg-warn-dark-0-on-hover-parent, :root .hover-parent:hover .bg-theme-warn-dark-0-on-hover-parent {
    background-color: rgba(var(--color-warn-dark-rgb), 0);
  }
}
:root .bg-warn-dark-10, :root .bg-theme-warn-dark-10 {
  background-color: rgba(var(--color-warn-dark-rgb), 0.1);
}
@media (hover: hover) {
  :root .bg-warn-dark-10-on-hover:hover, :root .bg-theme-warn-dark-10-on-hover:hover {
    background-color: rgba(var(--color-warn-dark-rgb), 0.1);
  }
  :root .hover-parent:hover .bg-warn-dark-10-on-hover-parent, :root .hover-parent:hover .bg-theme-warn-dark-10-on-hover-parent {
    background-color: rgba(var(--color-warn-dark-rgb), 0.1);
  }
}
:root .bg-warn-dark-20, :root .bg-theme-warn-dark-20 {
  background-color: rgba(var(--color-warn-dark-rgb), 0.2);
}
@media (hover: hover) {
  :root .bg-warn-dark-20-on-hover:hover, :root .bg-theme-warn-dark-20-on-hover:hover {
    background-color: rgba(var(--color-warn-dark-rgb), 0.2);
  }
  :root .hover-parent:hover .bg-warn-dark-20-on-hover-parent, :root .hover-parent:hover .bg-theme-warn-dark-20-on-hover-parent {
    background-color: rgba(var(--color-warn-dark-rgb), 0.2);
  }
}
:root .bg-warn-dark-30, :root .bg-theme-warn-dark-30 {
  background-color: rgba(var(--color-warn-dark-rgb), 0.3);
}
@media (hover: hover) {
  :root .bg-warn-dark-30-on-hover:hover, :root .bg-theme-warn-dark-30-on-hover:hover {
    background-color: rgba(var(--color-warn-dark-rgb), 0.3);
  }
  :root .hover-parent:hover .bg-warn-dark-30-on-hover-parent, :root .hover-parent:hover .bg-theme-warn-dark-30-on-hover-parent {
    background-color: rgba(var(--color-warn-dark-rgb), 0.3);
  }
}
:root .bg-warn-dark-40, :root .bg-theme-warn-dark-40 {
  background-color: rgba(var(--color-warn-dark-rgb), 0.4);
}
@media (hover: hover) {
  :root .bg-warn-dark-40-on-hover:hover, :root .bg-theme-warn-dark-40-on-hover:hover {
    background-color: rgba(var(--color-warn-dark-rgb), 0.4);
  }
  :root .hover-parent:hover .bg-warn-dark-40-on-hover-parent, :root .hover-parent:hover .bg-theme-warn-dark-40-on-hover-parent {
    background-color: rgba(var(--color-warn-dark-rgb), 0.4);
  }
}
:root .bg-warn-dark-50, :root .bg-theme-warn-dark-50 {
  background-color: rgba(var(--color-warn-dark-rgb), 0.5);
}
@media (hover: hover) {
  :root .bg-warn-dark-50-on-hover:hover, :root .bg-theme-warn-dark-50-on-hover:hover {
    background-color: rgba(var(--color-warn-dark-rgb), 0.5);
  }
  :root .hover-parent:hover .bg-warn-dark-50-on-hover-parent, :root .hover-parent:hover .bg-theme-warn-dark-50-on-hover-parent {
    background-color: rgba(var(--color-warn-dark-rgb), 0.5);
  }
}
:root .bg-warn-dark-60, :root .bg-theme-warn-dark-60 {
  background-color: rgba(var(--color-warn-dark-rgb), 0.6);
}
@media (hover: hover) {
  :root .bg-warn-dark-60-on-hover:hover, :root .bg-theme-warn-dark-60-on-hover:hover {
    background-color: rgba(var(--color-warn-dark-rgb), 0.6);
  }
  :root .hover-parent:hover .bg-warn-dark-60-on-hover-parent, :root .hover-parent:hover .bg-theme-warn-dark-60-on-hover-parent {
    background-color: rgba(var(--color-warn-dark-rgb), 0.6);
  }
}
:root .bg-warn-dark-70, :root .bg-theme-warn-dark-70 {
  background-color: rgba(var(--color-warn-dark-rgb), 0.7);
}
@media (hover: hover) {
  :root .bg-warn-dark-70-on-hover:hover, :root .bg-theme-warn-dark-70-on-hover:hover {
    background-color: rgba(var(--color-warn-dark-rgb), 0.7);
  }
  :root .hover-parent:hover .bg-warn-dark-70-on-hover-parent, :root .hover-parent:hover .bg-theme-warn-dark-70-on-hover-parent {
    background-color: rgba(var(--color-warn-dark-rgb), 0.7);
  }
}
:root .bg-warn-dark-80, :root .bg-theme-warn-dark-80 {
  background-color: rgba(var(--color-warn-dark-rgb), 0.8);
}
@media (hover: hover) {
  :root .bg-warn-dark-80-on-hover:hover, :root .bg-theme-warn-dark-80-on-hover:hover {
    background-color: rgba(var(--color-warn-dark-rgb), 0.8);
  }
  :root .hover-parent:hover .bg-warn-dark-80-on-hover-parent, :root .hover-parent:hover .bg-theme-warn-dark-80-on-hover-parent {
    background-color: rgba(var(--color-warn-dark-rgb), 0.8);
  }
}
:root .bg-warn-dark-90, :root .bg-theme-warn-dark-90 {
  background-color: rgba(var(--color-warn-dark-rgb), 0.9);
}
@media (hover: hover) {
  :root .bg-warn-dark-90-on-hover:hover, :root .bg-theme-warn-dark-90-on-hover:hover {
    background-color: rgba(var(--color-warn-dark-rgb), 0.9);
  }
  :root .hover-parent:hover .bg-warn-dark-90-on-hover-parent, :root .hover-parent:hover .bg-theme-warn-dark-90-on-hover-parent {
    background-color: rgba(var(--color-warn-dark-rgb), 0.9);
  }
}
:root .bg-warn-dark-100, :root .bg-theme-warn-dark-100 {
  background-color: rgba(var(--color-warn-dark-rgb), 1);
}
@media (hover: hover) {
  :root .bg-warn-dark-100-on-hover:hover, :root .bg-theme-warn-dark-100-on-hover:hover {
    background-color: rgba(var(--color-warn-dark-rgb), 1);
  }
  :root .hover-parent:hover .bg-warn-dark-100-on-hover-parent, :root .hover-parent:hover .bg-theme-warn-dark-100-on-hover-parent {
    background-color: rgba(var(--color-warn-dark-rgb), 1);
  }
}

@media (hover: hover) {
  :root .bg-warn-dark-on-hover:hover, :root .bg-theme-warn-dark-on-hover:hover {
    background-color: rgb(var(--color-warn-dark-rgb));
  }
  :root .hover-parent:hover .bg-warn-dark-on-hover-parent, :root .hover-parent:hover .bg-theme-warn-dark-on-hover-parent {
    background-color: rgb(var(--color-warn-dark-rgb));
  }
}
:root .bg-warn-500, :root .bg-theme-warn-500 {
  background-color: rgb(var(--color-warn-500-rgb));
}
:root .bg-warn-500-0, :root .bg-theme-warn-500-0 {
  background-color: rgba(var(--color-warn-500-rgb), 0);
}
@media (hover: hover) {
  :root .bg-warn-500-0-on-hover:hover, :root .bg-theme-warn-500-0-on-hover:hover {
    background-color: rgba(var(--color-warn-500-rgb), 0);
  }
  :root .hover-parent:hover .bg-warn-500-0-on-hover-parent, :root .hover-parent:hover .bg-theme-warn-500-0-on-hover-parent {
    background-color: rgba(var(--color-warn-500-rgb), 0);
  }
}
:root .bg-warn-500-10, :root .bg-theme-warn-500-10 {
  background-color: rgba(var(--color-warn-500-rgb), 0.1);
}
@media (hover: hover) {
  :root .bg-warn-500-10-on-hover:hover, :root .bg-theme-warn-500-10-on-hover:hover {
    background-color: rgba(var(--color-warn-500-rgb), 0.1);
  }
  :root .hover-parent:hover .bg-warn-500-10-on-hover-parent, :root .hover-parent:hover .bg-theme-warn-500-10-on-hover-parent {
    background-color: rgba(var(--color-warn-500-rgb), 0.1);
  }
}
:root .bg-warn-500-20, :root .bg-theme-warn-500-20 {
  background-color: rgba(var(--color-warn-500-rgb), 0.2);
}
@media (hover: hover) {
  :root .bg-warn-500-20-on-hover:hover, :root .bg-theme-warn-500-20-on-hover:hover {
    background-color: rgba(var(--color-warn-500-rgb), 0.2);
  }
  :root .hover-parent:hover .bg-warn-500-20-on-hover-parent, :root .hover-parent:hover .bg-theme-warn-500-20-on-hover-parent {
    background-color: rgba(var(--color-warn-500-rgb), 0.2);
  }
}
:root .bg-warn-500-30, :root .bg-theme-warn-500-30 {
  background-color: rgba(var(--color-warn-500-rgb), 0.3);
}
@media (hover: hover) {
  :root .bg-warn-500-30-on-hover:hover, :root .bg-theme-warn-500-30-on-hover:hover {
    background-color: rgba(var(--color-warn-500-rgb), 0.3);
  }
  :root .hover-parent:hover .bg-warn-500-30-on-hover-parent, :root .hover-parent:hover .bg-theme-warn-500-30-on-hover-parent {
    background-color: rgba(var(--color-warn-500-rgb), 0.3);
  }
}
:root .bg-warn-500-40, :root .bg-theme-warn-500-40 {
  background-color: rgba(var(--color-warn-500-rgb), 0.4);
}
@media (hover: hover) {
  :root .bg-warn-500-40-on-hover:hover, :root .bg-theme-warn-500-40-on-hover:hover {
    background-color: rgba(var(--color-warn-500-rgb), 0.4);
  }
  :root .hover-parent:hover .bg-warn-500-40-on-hover-parent, :root .hover-parent:hover .bg-theme-warn-500-40-on-hover-parent {
    background-color: rgba(var(--color-warn-500-rgb), 0.4);
  }
}
:root .bg-warn-500-50, :root .bg-theme-warn-500-50 {
  background-color: rgba(var(--color-warn-500-rgb), 0.5);
}
@media (hover: hover) {
  :root .bg-warn-500-50-on-hover:hover, :root .bg-theme-warn-500-50-on-hover:hover {
    background-color: rgba(var(--color-warn-500-rgb), 0.5);
  }
  :root .hover-parent:hover .bg-warn-500-50-on-hover-parent, :root .hover-parent:hover .bg-theme-warn-500-50-on-hover-parent {
    background-color: rgba(var(--color-warn-500-rgb), 0.5);
  }
}
:root .bg-warn-500-60, :root .bg-theme-warn-500-60 {
  background-color: rgba(var(--color-warn-500-rgb), 0.6);
}
@media (hover: hover) {
  :root .bg-warn-500-60-on-hover:hover, :root .bg-theme-warn-500-60-on-hover:hover {
    background-color: rgba(var(--color-warn-500-rgb), 0.6);
  }
  :root .hover-parent:hover .bg-warn-500-60-on-hover-parent, :root .hover-parent:hover .bg-theme-warn-500-60-on-hover-parent {
    background-color: rgba(var(--color-warn-500-rgb), 0.6);
  }
}
:root .bg-warn-500-70, :root .bg-theme-warn-500-70 {
  background-color: rgba(var(--color-warn-500-rgb), 0.7);
}
@media (hover: hover) {
  :root .bg-warn-500-70-on-hover:hover, :root .bg-theme-warn-500-70-on-hover:hover {
    background-color: rgba(var(--color-warn-500-rgb), 0.7);
  }
  :root .hover-parent:hover .bg-warn-500-70-on-hover-parent, :root .hover-parent:hover .bg-theme-warn-500-70-on-hover-parent {
    background-color: rgba(var(--color-warn-500-rgb), 0.7);
  }
}
:root .bg-warn-500-80, :root .bg-theme-warn-500-80 {
  background-color: rgba(var(--color-warn-500-rgb), 0.8);
}
@media (hover: hover) {
  :root .bg-warn-500-80-on-hover:hover, :root .bg-theme-warn-500-80-on-hover:hover {
    background-color: rgba(var(--color-warn-500-rgb), 0.8);
  }
  :root .hover-parent:hover .bg-warn-500-80-on-hover-parent, :root .hover-parent:hover .bg-theme-warn-500-80-on-hover-parent {
    background-color: rgba(var(--color-warn-500-rgb), 0.8);
  }
}
:root .bg-warn-500-90, :root .bg-theme-warn-500-90 {
  background-color: rgba(var(--color-warn-500-rgb), 0.9);
}
@media (hover: hover) {
  :root .bg-warn-500-90-on-hover:hover, :root .bg-theme-warn-500-90-on-hover:hover {
    background-color: rgba(var(--color-warn-500-rgb), 0.9);
  }
  :root .hover-parent:hover .bg-warn-500-90-on-hover-parent, :root .hover-parent:hover .bg-theme-warn-500-90-on-hover-parent {
    background-color: rgba(var(--color-warn-500-rgb), 0.9);
  }
}
:root .bg-warn-500-100, :root .bg-theme-warn-500-100 {
  background-color: rgba(var(--color-warn-500-rgb), 1);
}
@media (hover: hover) {
  :root .bg-warn-500-100-on-hover:hover, :root .bg-theme-warn-500-100-on-hover:hover {
    background-color: rgba(var(--color-warn-500-rgb), 1);
  }
  :root .hover-parent:hover .bg-warn-500-100-on-hover-parent, :root .hover-parent:hover .bg-theme-warn-500-100-on-hover-parent {
    background-color: rgba(var(--color-warn-500-rgb), 1);
  }
}

@media (hover: hover) {
  :root .bg-warn-500-on-hover:hover, :root .bg-theme-warn-500-on-hover:hover {
    background-color: rgb(var(--color-warn-500-rgb));
  }
  :root .hover-parent:hover .bg-warn-500-on-hover-parent, :root .hover-parent:hover .bg-theme-warn-500-on-hover-parent {
    background-color: rgb(var(--color-warn-500-rgb));
  }
}
:root .bg-warn-700, :root .bg-theme-warn-700 {
  background-color: rgb(var(--color-warn-700-rgb));
}
:root .bg-warn-700-0, :root .bg-theme-warn-700-0 {
  background-color: rgba(var(--color-warn-700-rgb), 0);
}
@media (hover: hover) {
  :root .bg-warn-700-0-on-hover:hover, :root .bg-theme-warn-700-0-on-hover:hover {
    background-color: rgba(var(--color-warn-700-rgb), 0);
  }
  :root .hover-parent:hover .bg-warn-700-0-on-hover-parent, :root .hover-parent:hover .bg-theme-warn-700-0-on-hover-parent {
    background-color: rgba(var(--color-warn-700-rgb), 0);
  }
}
:root .bg-warn-700-10, :root .bg-theme-warn-700-10 {
  background-color: rgba(var(--color-warn-700-rgb), 0.1);
}
@media (hover: hover) {
  :root .bg-warn-700-10-on-hover:hover, :root .bg-theme-warn-700-10-on-hover:hover {
    background-color: rgba(var(--color-warn-700-rgb), 0.1);
  }
  :root .hover-parent:hover .bg-warn-700-10-on-hover-parent, :root .hover-parent:hover .bg-theme-warn-700-10-on-hover-parent {
    background-color: rgba(var(--color-warn-700-rgb), 0.1);
  }
}
:root .bg-warn-700-20, :root .bg-theme-warn-700-20 {
  background-color: rgba(var(--color-warn-700-rgb), 0.2);
}
@media (hover: hover) {
  :root .bg-warn-700-20-on-hover:hover, :root .bg-theme-warn-700-20-on-hover:hover {
    background-color: rgba(var(--color-warn-700-rgb), 0.2);
  }
  :root .hover-parent:hover .bg-warn-700-20-on-hover-parent, :root .hover-parent:hover .bg-theme-warn-700-20-on-hover-parent {
    background-color: rgba(var(--color-warn-700-rgb), 0.2);
  }
}
:root .bg-warn-700-30, :root .bg-theme-warn-700-30 {
  background-color: rgba(var(--color-warn-700-rgb), 0.3);
}
@media (hover: hover) {
  :root .bg-warn-700-30-on-hover:hover, :root .bg-theme-warn-700-30-on-hover:hover {
    background-color: rgba(var(--color-warn-700-rgb), 0.3);
  }
  :root .hover-parent:hover .bg-warn-700-30-on-hover-parent, :root .hover-parent:hover .bg-theme-warn-700-30-on-hover-parent {
    background-color: rgba(var(--color-warn-700-rgb), 0.3);
  }
}
:root .bg-warn-700-40, :root .bg-theme-warn-700-40 {
  background-color: rgba(var(--color-warn-700-rgb), 0.4);
}
@media (hover: hover) {
  :root .bg-warn-700-40-on-hover:hover, :root .bg-theme-warn-700-40-on-hover:hover {
    background-color: rgba(var(--color-warn-700-rgb), 0.4);
  }
  :root .hover-parent:hover .bg-warn-700-40-on-hover-parent, :root .hover-parent:hover .bg-theme-warn-700-40-on-hover-parent {
    background-color: rgba(var(--color-warn-700-rgb), 0.4);
  }
}
:root .bg-warn-700-50, :root .bg-theme-warn-700-50 {
  background-color: rgba(var(--color-warn-700-rgb), 0.5);
}
@media (hover: hover) {
  :root .bg-warn-700-50-on-hover:hover, :root .bg-theme-warn-700-50-on-hover:hover {
    background-color: rgba(var(--color-warn-700-rgb), 0.5);
  }
  :root .hover-parent:hover .bg-warn-700-50-on-hover-parent, :root .hover-parent:hover .bg-theme-warn-700-50-on-hover-parent {
    background-color: rgba(var(--color-warn-700-rgb), 0.5);
  }
}
:root .bg-warn-700-60, :root .bg-theme-warn-700-60 {
  background-color: rgba(var(--color-warn-700-rgb), 0.6);
}
@media (hover: hover) {
  :root .bg-warn-700-60-on-hover:hover, :root .bg-theme-warn-700-60-on-hover:hover {
    background-color: rgba(var(--color-warn-700-rgb), 0.6);
  }
  :root .hover-parent:hover .bg-warn-700-60-on-hover-parent, :root .hover-parent:hover .bg-theme-warn-700-60-on-hover-parent {
    background-color: rgba(var(--color-warn-700-rgb), 0.6);
  }
}
:root .bg-warn-700-70, :root .bg-theme-warn-700-70 {
  background-color: rgba(var(--color-warn-700-rgb), 0.7);
}
@media (hover: hover) {
  :root .bg-warn-700-70-on-hover:hover, :root .bg-theme-warn-700-70-on-hover:hover {
    background-color: rgba(var(--color-warn-700-rgb), 0.7);
  }
  :root .hover-parent:hover .bg-warn-700-70-on-hover-parent, :root .hover-parent:hover .bg-theme-warn-700-70-on-hover-parent {
    background-color: rgba(var(--color-warn-700-rgb), 0.7);
  }
}
:root .bg-warn-700-80, :root .bg-theme-warn-700-80 {
  background-color: rgba(var(--color-warn-700-rgb), 0.8);
}
@media (hover: hover) {
  :root .bg-warn-700-80-on-hover:hover, :root .bg-theme-warn-700-80-on-hover:hover {
    background-color: rgba(var(--color-warn-700-rgb), 0.8);
  }
  :root .hover-parent:hover .bg-warn-700-80-on-hover-parent, :root .hover-parent:hover .bg-theme-warn-700-80-on-hover-parent {
    background-color: rgba(var(--color-warn-700-rgb), 0.8);
  }
}
:root .bg-warn-700-90, :root .bg-theme-warn-700-90 {
  background-color: rgba(var(--color-warn-700-rgb), 0.9);
}
@media (hover: hover) {
  :root .bg-warn-700-90-on-hover:hover, :root .bg-theme-warn-700-90-on-hover:hover {
    background-color: rgba(var(--color-warn-700-rgb), 0.9);
  }
  :root .hover-parent:hover .bg-warn-700-90-on-hover-parent, :root .hover-parent:hover .bg-theme-warn-700-90-on-hover-parent {
    background-color: rgba(var(--color-warn-700-rgb), 0.9);
  }
}
:root .bg-warn-700-100, :root .bg-theme-warn-700-100 {
  background-color: rgba(var(--color-warn-700-rgb), 1);
}
@media (hover: hover) {
  :root .bg-warn-700-100-on-hover:hover, :root .bg-theme-warn-700-100-on-hover:hover {
    background-color: rgba(var(--color-warn-700-rgb), 1);
  }
  :root .hover-parent:hover .bg-warn-700-100-on-hover-parent, :root .hover-parent:hover .bg-theme-warn-700-100-on-hover-parent {
    background-color: rgba(var(--color-warn-700-rgb), 1);
  }
}

@media (hover: hover) {
  :root .bg-warn-700-on-hover:hover, :root .bg-theme-warn-700-on-hover:hover {
    background-color: rgb(var(--color-warn-700-rgb));
  }
  :root .hover-parent:hover .bg-warn-700-on-hover-parent, :root .hover-parent:hover .bg-theme-warn-700-on-hover-parent {
    background-color: rgb(var(--color-warn-700-rgb));
  }
}
:root .bg-importance-0, :root .bg-imp-0 {
  background-color: rgb(var(--color-imp-bg-0-rgb));
}
:root .bg-importance-0-0, :root .bg-imp-0-0 {
  background-color: rgba(var(--color-imp-bg-0-rgb), 0);
}
@media (hover: hover) {
  :root .bg-importance-0-0-on-hover:hover, :root .bg-imp-0-0-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-0-rgb), 0);
  }
  :root .hover-parent:hover .bg-importance-0-0-on-hover-parent, :root .hover-parent:hover .bg-imp-0-0-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-0-rgb), 0);
  }
}
:root .bg-importance-0-10, :root .bg-imp-0-10 {
  background-color: rgba(var(--color-imp-bg-0-rgb), 0.1);
}
@media (hover: hover) {
  :root .bg-importance-0-10-on-hover:hover, :root .bg-imp-0-10-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-0-rgb), 0.1);
  }
  :root .hover-parent:hover .bg-importance-0-10-on-hover-parent, :root .hover-parent:hover .bg-imp-0-10-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-0-rgb), 0.1);
  }
}
:root .bg-importance-0-20, :root .bg-imp-0-20 {
  background-color: rgba(var(--color-imp-bg-0-rgb), 0.2);
}
@media (hover: hover) {
  :root .bg-importance-0-20-on-hover:hover, :root .bg-imp-0-20-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-0-rgb), 0.2);
  }
  :root .hover-parent:hover .bg-importance-0-20-on-hover-parent, :root .hover-parent:hover .bg-imp-0-20-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-0-rgb), 0.2);
  }
}
:root .bg-importance-0-30, :root .bg-imp-0-30 {
  background-color: rgba(var(--color-imp-bg-0-rgb), 0.3);
}
@media (hover: hover) {
  :root .bg-importance-0-30-on-hover:hover, :root .bg-imp-0-30-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-0-rgb), 0.3);
  }
  :root .hover-parent:hover .bg-importance-0-30-on-hover-parent, :root .hover-parent:hover .bg-imp-0-30-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-0-rgb), 0.3);
  }
}
:root .bg-importance-0-40, :root .bg-imp-0-40 {
  background-color: rgba(var(--color-imp-bg-0-rgb), 0.4);
}
@media (hover: hover) {
  :root .bg-importance-0-40-on-hover:hover, :root .bg-imp-0-40-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-0-rgb), 0.4);
  }
  :root .hover-parent:hover .bg-importance-0-40-on-hover-parent, :root .hover-parent:hover .bg-imp-0-40-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-0-rgb), 0.4);
  }
}
:root .bg-importance-0-50, :root .bg-imp-0-50 {
  background-color: rgba(var(--color-imp-bg-0-rgb), 0.5);
}
@media (hover: hover) {
  :root .bg-importance-0-50-on-hover:hover, :root .bg-imp-0-50-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-0-rgb), 0.5);
  }
  :root .hover-parent:hover .bg-importance-0-50-on-hover-parent, :root .hover-parent:hover .bg-imp-0-50-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-0-rgb), 0.5);
  }
}
:root .bg-importance-0-60, :root .bg-imp-0-60 {
  background-color: rgba(var(--color-imp-bg-0-rgb), 0.6);
}
@media (hover: hover) {
  :root .bg-importance-0-60-on-hover:hover, :root .bg-imp-0-60-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-0-rgb), 0.6);
  }
  :root .hover-parent:hover .bg-importance-0-60-on-hover-parent, :root .hover-parent:hover .bg-imp-0-60-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-0-rgb), 0.6);
  }
}
:root .bg-importance-0-70, :root .bg-imp-0-70 {
  background-color: rgba(var(--color-imp-bg-0-rgb), 0.7);
}
@media (hover: hover) {
  :root .bg-importance-0-70-on-hover:hover, :root .bg-imp-0-70-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-0-rgb), 0.7);
  }
  :root .hover-parent:hover .bg-importance-0-70-on-hover-parent, :root .hover-parent:hover .bg-imp-0-70-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-0-rgb), 0.7);
  }
}
:root .bg-importance-0-80, :root .bg-imp-0-80 {
  background-color: rgba(var(--color-imp-bg-0-rgb), 0.8);
}
@media (hover: hover) {
  :root .bg-importance-0-80-on-hover:hover, :root .bg-imp-0-80-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-0-rgb), 0.8);
  }
  :root .hover-parent:hover .bg-importance-0-80-on-hover-parent, :root .hover-parent:hover .bg-imp-0-80-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-0-rgb), 0.8);
  }
}
:root .bg-importance-0-90, :root .bg-imp-0-90 {
  background-color: rgba(var(--color-imp-bg-0-rgb), 0.9);
}
@media (hover: hover) {
  :root .bg-importance-0-90-on-hover:hover, :root .bg-imp-0-90-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-0-rgb), 0.9);
  }
  :root .hover-parent:hover .bg-importance-0-90-on-hover-parent, :root .hover-parent:hover .bg-imp-0-90-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-0-rgb), 0.9);
  }
}
:root .bg-importance-0-100, :root .bg-imp-0-100 {
  background-color: rgba(var(--color-imp-bg-0-rgb), 1);
}
@media (hover: hover) {
  :root .bg-importance-0-100-on-hover:hover, :root .bg-imp-0-100-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-0-rgb), 1);
  }
  :root .hover-parent:hover .bg-importance-0-100-on-hover-parent, :root .hover-parent:hover .bg-imp-0-100-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-0-rgb), 1);
  }
}

@media (hover: hover) {
  :root .bg-importance-0-on-hover:hover, :root .bg-imp-0-on-hover:hover {
    background-color: rgb(var(--color-imp-bg-0-rgb));
  }
  :root .hover-parent:hover .bg-importance-0-on-hover-parent, :root .hover-parent:hover .bg-imp-0-on-hover-parent {
    background-color: rgb(var(--color-imp-bg-0-rgb));
  }
}
:root .bg-importance-1, :root .bg-imp-1 {
  background-color: rgb(var(--color-imp-bg-1-rgb));
}
:root .bg-importance-1-0, :root .bg-imp-1-0 {
  background-color: rgba(var(--color-imp-bg-1-rgb), 0);
}
@media (hover: hover) {
  :root .bg-importance-1-0-on-hover:hover, :root .bg-imp-1-0-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-1-rgb), 0);
  }
  :root .hover-parent:hover .bg-importance-1-0-on-hover-parent, :root .hover-parent:hover .bg-imp-1-0-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-1-rgb), 0);
  }
}
:root .bg-importance-1-10, :root .bg-imp-1-10 {
  background-color: rgba(var(--color-imp-bg-1-rgb), 0.1);
}
@media (hover: hover) {
  :root .bg-importance-1-10-on-hover:hover, :root .bg-imp-1-10-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-1-rgb), 0.1);
  }
  :root .hover-parent:hover .bg-importance-1-10-on-hover-parent, :root .hover-parent:hover .bg-imp-1-10-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-1-rgb), 0.1);
  }
}
:root .bg-importance-1-20, :root .bg-imp-1-20 {
  background-color: rgba(var(--color-imp-bg-1-rgb), 0.2);
}
@media (hover: hover) {
  :root .bg-importance-1-20-on-hover:hover, :root .bg-imp-1-20-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-1-rgb), 0.2);
  }
  :root .hover-parent:hover .bg-importance-1-20-on-hover-parent, :root .hover-parent:hover .bg-imp-1-20-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-1-rgb), 0.2);
  }
}
:root .bg-importance-1-30, :root .bg-imp-1-30 {
  background-color: rgba(var(--color-imp-bg-1-rgb), 0.3);
}
@media (hover: hover) {
  :root .bg-importance-1-30-on-hover:hover, :root .bg-imp-1-30-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-1-rgb), 0.3);
  }
  :root .hover-parent:hover .bg-importance-1-30-on-hover-parent, :root .hover-parent:hover .bg-imp-1-30-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-1-rgb), 0.3);
  }
}
:root .bg-importance-1-40, :root .bg-imp-1-40 {
  background-color: rgba(var(--color-imp-bg-1-rgb), 0.4);
}
@media (hover: hover) {
  :root .bg-importance-1-40-on-hover:hover, :root .bg-imp-1-40-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-1-rgb), 0.4);
  }
  :root .hover-parent:hover .bg-importance-1-40-on-hover-parent, :root .hover-parent:hover .bg-imp-1-40-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-1-rgb), 0.4);
  }
}
:root .bg-importance-1-50, :root .bg-imp-1-50 {
  background-color: rgba(var(--color-imp-bg-1-rgb), 0.5);
}
@media (hover: hover) {
  :root .bg-importance-1-50-on-hover:hover, :root .bg-imp-1-50-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-1-rgb), 0.5);
  }
  :root .hover-parent:hover .bg-importance-1-50-on-hover-parent, :root .hover-parent:hover .bg-imp-1-50-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-1-rgb), 0.5);
  }
}
:root .bg-importance-1-60, :root .bg-imp-1-60 {
  background-color: rgba(var(--color-imp-bg-1-rgb), 0.6);
}
@media (hover: hover) {
  :root .bg-importance-1-60-on-hover:hover, :root .bg-imp-1-60-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-1-rgb), 0.6);
  }
  :root .hover-parent:hover .bg-importance-1-60-on-hover-parent, :root .hover-parent:hover .bg-imp-1-60-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-1-rgb), 0.6);
  }
}
:root .bg-importance-1-70, :root .bg-imp-1-70 {
  background-color: rgba(var(--color-imp-bg-1-rgb), 0.7);
}
@media (hover: hover) {
  :root .bg-importance-1-70-on-hover:hover, :root .bg-imp-1-70-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-1-rgb), 0.7);
  }
  :root .hover-parent:hover .bg-importance-1-70-on-hover-parent, :root .hover-parent:hover .bg-imp-1-70-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-1-rgb), 0.7);
  }
}
:root .bg-importance-1-80, :root .bg-imp-1-80 {
  background-color: rgba(var(--color-imp-bg-1-rgb), 0.8);
}
@media (hover: hover) {
  :root .bg-importance-1-80-on-hover:hover, :root .bg-imp-1-80-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-1-rgb), 0.8);
  }
  :root .hover-parent:hover .bg-importance-1-80-on-hover-parent, :root .hover-parent:hover .bg-imp-1-80-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-1-rgb), 0.8);
  }
}
:root .bg-importance-1-90, :root .bg-imp-1-90 {
  background-color: rgba(var(--color-imp-bg-1-rgb), 0.9);
}
@media (hover: hover) {
  :root .bg-importance-1-90-on-hover:hover, :root .bg-imp-1-90-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-1-rgb), 0.9);
  }
  :root .hover-parent:hover .bg-importance-1-90-on-hover-parent, :root .hover-parent:hover .bg-imp-1-90-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-1-rgb), 0.9);
  }
}
:root .bg-importance-1-100, :root .bg-imp-1-100 {
  background-color: rgba(var(--color-imp-bg-1-rgb), 1);
}
@media (hover: hover) {
  :root .bg-importance-1-100-on-hover:hover, :root .bg-imp-1-100-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-1-rgb), 1);
  }
  :root .hover-parent:hover .bg-importance-1-100-on-hover-parent, :root .hover-parent:hover .bg-imp-1-100-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-1-rgb), 1);
  }
}

@media (hover: hover) {
  :root .bg-importance-1-on-hover:hover, :root .bg-imp-1-on-hover:hover {
    background-color: rgb(var(--color-imp-bg-1-rgb));
  }
  :root .hover-parent:hover .bg-importance-1-on-hover-parent, :root .hover-parent:hover .bg-imp-1-on-hover-parent {
    background-color: rgb(var(--color-imp-bg-1-rgb));
  }
}
:root .bg-importance-2, :root .bg-imp-2 {
  background-color: rgb(var(--color-imp-bg-2-rgb));
}
:root .bg-importance-2-0, :root .bg-imp-2-0 {
  background-color: rgba(var(--color-imp-bg-2-rgb), 0);
}
@media (hover: hover) {
  :root .bg-importance-2-0-on-hover:hover, :root .bg-imp-2-0-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-2-rgb), 0);
  }
  :root .hover-parent:hover .bg-importance-2-0-on-hover-parent, :root .hover-parent:hover .bg-imp-2-0-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-2-rgb), 0);
  }
}
:root .bg-importance-2-10, :root .bg-imp-2-10 {
  background-color: rgba(var(--color-imp-bg-2-rgb), 0.1);
}
@media (hover: hover) {
  :root .bg-importance-2-10-on-hover:hover, :root .bg-imp-2-10-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-2-rgb), 0.1);
  }
  :root .hover-parent:hover .bg-importance-2-10-on-hover-parent, :root .hover-parent:hover .bg-imp-2-10-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-2-rgb), 0.1);
  }
}
:root .bg-importance-2-20, :root .bg-imp-2-20 {
  background-color: rgba(var(--color-imp-bg-2-rgb), 0.2);
}
@media (hover: hover) {
  :root .bg-importance-2-20-on-hover:hover, :root .bg-imp-2-20-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-2-rgb), 0.2);
  }
  :root .hover-parent:hover .bg-importance-2-20-on-hover-parent, :root .hover-parent:hover .bg-imp-2-20-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-2-rgb), 0.2);
  }
}
:root .bg-importance-2-30, :root .bg-imp-2-30 {
  background-color: rgba(var(--color-imp-bg-2-rgb), 0.3);
}
@media (hover: hover) {
  :root .bg-importance-2-30-on-hover:hover, :root .bg-imp-2-30-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-2-rgb), 0.3);
  }
  :root .hover-parent:hover .bg-importance-2-30-on-hover-parent, :root .hover-parent:hover .bg-imp-2-30-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-2-rgb), 0.3);
  }
}
:root .bg-importance-2-40, :root .bg-imp-2-40 {
  background-color: rgba(var(--color-imp-bg-2-rgb), 0.4);
}
@media (hover: hover) {
  :root .bg-importance-2-40-on-hover:hover, :root .bg-imp-2-40-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-2-rgb), 0.4);
  }
  :root .hover-parent:hover .bg-importance-2-40-on-hover-parent, :root .hover-parent:hover .bg-imp-2-40-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-2-rgb), 0.4);
  }
}
:root .bg-importance-2-50, :root .bg-imp-2-50 {
  background-color: rgba(var(--color-imp-bg-2-rgb), 0.5);
}
@media (hover: hover) {
  :root .bg-importance-2-50-on-hover:hover, :root .bg-imp-2-50-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-2-rgb), 0.5);
  }
  :root .hover-parent:hover .bg-importance-2-50-on-hover-parent, :root .hover-parent:hover .bg-imp-2-50-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-2-rgb), 0.5);
  }
}
:root .bg-importance-2-60, :root .bg-imp-2-60 {
  background-color: rgba(var(--color-imp-bg-2-rgb), 0.6);
}
@media (hover: hover) {
  :root .bg-importance-2-60-on-hover:hover, :root .bg-imp-2-60-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-2-rgb), 0.6);
  }
  :root .hover-parent:hover .bg-importance-2-60-on-hover-parent, :root .hover-parent:hover .bg-imp-2-60-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-2-rgb), 0.6);
  }
}
:root .bg-importance-2-70, :root .bg-imp-2-70 {
  background-color: rgba(var(--color-imp-bg-2-rgb), 0.7);
}
@media (hover: hover) {
  :root .bg-importance-2-70-on-hover:hover, :root .bg-imp-2-70-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-2-rgb), 0.7);
  }
  :root .hover-parent:hover .bg-importance-2-70-on-hover-parent, :root .hover-parent:hover .bg-imp-2-70-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-2-rgb), 0.7);
  }
}
:root .bg-importance-2-80, :root .bg-imp-2-80 {
  background-color: rgba(var(--color-imp-bg-2-rgb), 0.8);
}
@media (hover: hover) {
  :root .bg-importance-2-80-on-hover:hover, :root .bg-imp-2-80-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-2-rgb), 0.8);
  }
  :root .hover-parent:hover .bg-importance-2-80-on-hover-parent, :root .hover-parent:hover .bg-imp-2-80-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-2-rgb), 0.8);
  }
}
:root .bg-importance-2-90, :root .bg-imp-2-90 {
  background-color: rgba(var(--color-imp-bg-2-rgb), 0.9);
}
@media (hover: hover) {
  :root .bg-importance-2-90-on-hover:hover, :root .bg-imp-2-90-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-2-rgb), 0.9);
  }
  :root .hover-parent:hover .bg-importance-2-90-on-hover-parent, :root .hover-parent:hover .bg-imp-2-90-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-2-rgb), 0.9);
  }
}
:root .bg-importance-2-100, :root .bg-imp-2-100 {
  background-color: rgba(var(--color-imp-bg-2-rgb), 1);
}
@media (hover: hover) {
  :root .bg-importance-2-100-on-hover:hover, :root .bg-imp-2-100-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-2-rgb), 1);
  }
  :root .hover-parent:hover .bg-importance-2-100-on-hover-parent, :root .hover-parent:hover .bg-imp-2-100-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-2-rgb), 1);
  }
}

@media (hover: hover) {
  :root .bg-importance-2-on-hover:hover, :root .bg-imp-2-on-hover:hover {
    background-color: rgb(var(--color-imp-bg-2-rgb));
  }
  :root .hover-parent:hover .bg-importance-2-on-hover-parent, :root .hover-parent:hover .bg-imp-2-on-hover-parent {
    background-color: rgb(var(--color-imp-bg-2-rgb));
  }
}
:root .bg-importance-3, :root .bg-imp-3 {
  background-color: rgb(var(--color-imp-bg-3-rgb));
}
:root .bg-importance-3-0, :root .bg-imp-3-0 {
  background-color: rgba(var(--color-imp-bg-3-rgb), 0);
}
@media (hover: hover) {
  :root .bg-importance-3-0-on-hover:hover, :root .bg-imp-3-0-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-3-rgb), 0);
  }
  :root .hover-parent:hover .bg-importance-3-0-on-hover-parent, :root .hover-parent:hover .bg-imp-3-0-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-3-rgb), 0);
  }
}
:root .bg-importance-3-10, :root .bg-imp-3-10 {
  background-color: rgba(var(--color-imp-bg-3-rgb), 0.1);
}
@media (hover: hover) {
  :root .bg-importance-3-10-on-hover:hover, :root .bg-imp-3-10-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-3-rgb), 0.1);
  }
  :root .hover-parent:hover .bg-importance-3-10-on-hover-parent, :root .hover-parent:hover .bg-imp-3-10-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-3-rgb), 0.1);
  }
}
:root .bg-importance-3-20, :root .bg-imp-3-20 {
  background-color: rgba(var(--color-imp-bg-3-rgb), 0.2);
}
@media (hover: hover) {
  :root .bg-importance-3-20-on-hover:hover, :root .bg-imp-3-20-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-3-rgb), 0.2);
  }
  :root .hover-parent:hover .bg-importance-3-20-on-hover-parent, :root .hover-parent:hover .bg-imp-3-20-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-3-rgb), 0.2);
  }
}
:root .bg-importance-3-30, :root .bg-imp-3-30 {
  background-color: rgba(var(--color-imp-bg-3-rgb), 0.3);
}
@media (hover: hover) {
  :root .bg-importance-3-30-on-hover:hover, :root .bg-imp-3-30-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-3-rgb), 0.3);
  }
  :root .hover-parent:hover .bg-importance-3-30-on-hover-parent, :root .hover-parent:hover .bg-imp-3-30-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-3-rgb), 0.3);
  }
}
:root .bg-importance-3-40, :root .bg-imp-3-40 {
  background-color: rgba(var(--color-imp-bg-3-rgb), 0.4);
}
@media (hover: hover) {
  :root .bg-importance-3-40-on-hover:hover, :root .bg-imp-3-40-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-3-rgb), 0.4);
  }
  :root .hover-parent:hover .bg-importance-3-40-on-hover-parent, :root .hover-parent:hover .bg-imp-3-40-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-3-rgb), 0.4);
  }
}
:root .bg-importance-3-50, :root .bg-imp-3-50 {
  background-color: rgba(var(--color-imp-bg-3-rgb), 0.5);
}
@media (hover: hover) {
  :root .bg-importance-3-50-on-hover:hover, :root .bg-imp-3-50-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-3-rgb), 0.5);
  }
  :root .hover-parent:hover .bg-importance-3-50-on-hover-parent, :root .hover-parent:hover .bg-imp-3-50-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-3-rgb), 0.5);
  }
}
:root .bg-importance-3-60, :root .bg-imp-3-60 {
  background-color: rgba(var(--color-imp-bg-3-rgb), 0.6);
}
@media (hover: hover) {
  :root .bg-importance-3-60-on-hover:hover, :root .bg-imp-3-60-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-3-rgb), 0.6);
  }
  :root .hover-parent:hover .bg-importance-3-60-on-hover-parent, :root .hover-parent:hover .bg-imp-3-60-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-3-rgb), 0.6);
  }
}
:root .bg-importance-3-70, :root .bg-imp-3-70 {
  background-color: rgba(var(--color-imp-bg-3-rgb), 0.7);
}
@media (hover: hover) {
  :root .bg-importance-3-70-on-hover:hover, :root .bg-imp-3-70-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-3-rgb), 0.7);
  }
  :root .hover-parent:hover .bg-importance-3-70-on-hover-parent, :root .hover-parent:hover .bg-imp-3-70-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-3-rgb), 0.7);
  }
}
:root .bg-importance-3-80, :root .bg-imp-3-80 {
  background-color: rgba(var(--color-imp-bg-3-rgb), 0.8);
}
@media (hover: hover) {
  :root .bg-importance-3-80-on-hover:hover, :root .bg-imp-3-80-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-3-rgb), 0.8);
  }
  :root .hover-parent:hover .bg-importance-3-80-on-hover-parent, :root .hover-parent:hover .bg-imp-3-80-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-3-rgb), 0.8);
  }
}
:root .bg-importance-3-90, :root .bg-imp-3-90 {
  background-color: rgba(var(--color-imp-bg-3-rgb), 0.9);
}
@media (hover: hover) {
  :root .bg-importance-3-90-on-hover:hover, :root .bg-imp-3-90-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-3-rgb), 0.9);
  }
  :root .hover-parent:hover .bg-importance-3-90-on-hover-parent, :root .hover-parent:hover .bg-imp-3-90-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-3-rgb), 0.9);
  }
}
:root .bg-importance-3-100, :root .bg-imp-3-100 {
  background-color: rgba(var(--color-imp-bg-3-rgb), 1);
}
@media (hover: hover) {
  :root .bg-importance-3-100-on-hover:hover, :root .bg-imp-3-100-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-3-rgb), 1);
  }
  :root .hover-parent:hover .bg-importance-3-100-on-hover-parent, :root .hover-parent:hover .bg-imp-3-100-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-3-rgb), 1);
  }
}

@media (hover: hover) {
  :root .bg-importance-3-on-hover:hover, :root .bg-imp-3-on-hover:hover {
    background-color: rgb(var(--color-imp-bg-3-rgb));
  }
  :root .hover-parent:hover .bg-importance-3-on-hover-parent, :root .hover-parent:hover .bg-imp-3-on-hover-parent {
    background-color: rgb(var(--color-imp-bg-3-rgb));
  }
}
:root .bg-importance-4, :root .bg-imp-4 {
  background-color: rgb(var(--color-imp-bg-4-rgb));
}
:root .bg-importance-4-0, :root .bg-imp-4-0 {
  background-color: rgba(var(--color-imp-bg-4-rgb), 0);
}
@media (hover: hover) {
  :root .bg-importance-4-0-on-hover:hover, :root .bg-imp-4-0-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-4-rgb), 0);
  }
  :root .hover-parent:hover .bg-importance-4-0-on-hover-parent, :root .hover-parent:hover .bg-imp-4-0-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-4-rgb), 0);
  }
}
:root .bg-importance-4-10, :root .bg-imp-4-10 {
  background-color: rgba(var(--color-imp-bg-4-rgb), 0.1);
}
@media (hover: hover) {
  :root .bg-importance-4-10-on-hover:hover, :root .bg-imp-4-10-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-4-rgb), 0.1);
  }
  :root .hover-parent:hover .bg-importance-4-10-on-hover-parent, :root .hover-parent:hover .bg-imp-4-10-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-4-rgb), 0.1);
  }
}
:root .bg-importance-4-20, :root .bg-imp-4-20 {
  background-color: rgba(var(--color-imp-bg-4-rgb), 0.2);
}
@media (hover: hover) {
  :root .bg-importance-4-20-on-hover:hover, :root .bg-imp-4-20-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-4-rgb), 0.2);
  }
  :root .hover-parent:hover .bg-importance-4-20-on-hover-parent, :root .hover-parent:hover .bg-imp-4-20-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-4-rgb), 0.2);
  }
}
:root .bg-importance-4-30, :root .bg-imp-4-30 {
  background-color: rgba(var(--color-imp-bg-4-rgb), 0.3);
}
@media (hover: hover) {
  :root .bg-importance-4-30-on-hover:hover, :root .bg-imp-4-30-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-4-rgb), 0.3);
  }
  :root .hover-parent:hover .bg-importance-4-30-on-hover-parent, :root .hover-parent:hover .bg-imp-4-30-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-4-rgb), 0.3);
  }
}
:root .bg-importance-4-40, :root .bg-imp-4-40 {
  background-color: rgba(var(--color-imp-bg-4-rgb), 0.4);
}
@media (hover: hover) {
  :root .bg-importance-4-40-on-hover:hover, :root .bg-imp-4-40-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-4-rgb), 0.4);
  }
  :root .hover-parent:hover .bg-importance-4-40-on-hover-parent, :root .hover-parent:hover .bg-imp-4-40-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-4-rgb), 0.4);
  }
}
:root .bg-importance-4-50, :root .bg-imp-4-50 {
  background-color: rgba(var(--color-imp-bg-4-rgb), 0.5);
}
@media (hover: hover) {
  :root .bg-importance-4-50-on-hover:hover, :root .bg-imp-4-50-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-4-rgb), 0.5);
  }
  :root .hover-parent:hover .bg-importance-4-50-on-hover-parent, :root .hover-parent:hover .bg-imp-4-50-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-4-rgb), 0.5);
  }
}
:root .bg-importance-4-60, :root .bg-imp-4-60 {
  background-color: rgba(var(--color-imp-bg-4-rgb), 0.6);
}
@media (hover: hover) {
  :root .bg-importance-4-60-on-hover:hover, :root .bg-imp-4-60-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-4-rgb), 0.6);
  }
  :root .hover-parent:hover .bg-importance-4-60-on-hover-parent, :root .hover-parent:hover .bg-imp-4-60-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-4-rgb), 0.6);
  }
}
:root .bg-importance-4-70, :root .bg-imp-4-70 {
  background-color: rgba(var(--color-imp-bg-4-rgb), 0.7);
}
@media (hover: hover) {
  :root .bg-importance-4-70-on-hover:hover, :root .bg-imp-4-70-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-4-rgb), 0.7);
  }
  :root .hover-parent:hover .bg-importance-4-70-on-hover-parent, :root .hover-parent:hover .bg-imp-4-70-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-4-rgb), 0.7);
  }
}
:root .bg-importance-4-80, :root .bg-imp-4-80 {
  background-color: rgba(var(--color-imp-bg-4-rgb), 0.8);
}
@media (hover: hover) {
  :root .bg-importance-4-80-on-hover:hover, :root .bg-imp-4-80-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-4-rgb), 0.8);
  }
  :root .hover-parent:hover .bg-importance-4-80-on-hover-parent, :root .hover-parent:hover .bg-imp-4-80-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-4-rgb), 0.8);
  }
}
:root .bg-importance-4-90, :root .bg-imp-4-90 {
  background-color: rgba(var(--color-imp-bg-4-rgb), 0.9);
}
@media (hover: hover) {
  :root .bg-importance-4-90-on-hover:hover, :root .bg-imp-4-90-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-4-rgb), 0.9);
  }
  :root .hover-parent:hover .bg-importance-4-90-on-hover-parent, :root .hover-parent:hover .bg-imp-4-90-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-4-rgb), 0.9);
  }
}
:root .bg-importance-4-100, :root .bg-imp-4-100 {
  background-color: rgba(var(--color-imp-bg-4-rgb), 1);
}
@media (hover: hover) {
  :root .bg-importance-4-100-on-hover:hover, :root .bg-imp-4-100-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-4-rgb), 1);
  }
  :root .hover-parent:hover .bg-importance-4-100-on-hover-parent, :root .hover-parent:hover .bg-imp-4-100-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-4-rgb), 1);
  }
}

@media (hover: hover) {
  :root .bg-importance-4-on-hover:hover, :root .bg-imp-4-on-hover:hover {
    background-color: rgb(var(--color-imp-bg-4-rgb));
  }
  :root .hover-parent:hover .bg-importance-4-on-hover-parent, :root .hover-parent:hover .bg-imp-4-on-hover-parent {
    background-color: rgb(var(--color-imp-bg-4-rgb));
  }
}
:root .bg-importance-5, :root .bg-imp-5 {
  background-color: rgb(var(--color-imp-bg-5-rgb));
}
:root .bg-importance-5-0, :root .bg-imp-5-0 {
  background-color: rgba(var(--color-imp-bg-5-rgb), 0);
}
@media (hover: hover) {
  :root .bg-importance-5-0-on-hover:hover, :root .bg-imp-5-0-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-5-rgb), 0);
  }
  :root .hover-parent:hover .bg-importance-5-0-on-hover-parent, :root .hover-parent:hover .bg-imp-5-0-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-5-rgb), 0);
  }
}
:root .bg-importance-5-10, :root .bg-imp-5-10 {
  background-color: rgba(var(--color-imp-bg-5-rgb), 0.1);
}
@media (hover: hover) {
  :root .bg-importance-5-10-on-hover:hover, :root .bg-imp-5-10-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-5-rgb), 0.1);
  }
  :root .hover-parent:hover .bg-importance-5-10-on-hover-parent, :root .hover-parent:hover .bg-imp-5-10-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-5-rgb), 0.1);
  }
}
:root .bg-importance-5-20, :root .bg-imp-5-20 {
  background-color: rgba(var(--color-imp-bg-5-rgb), 0.2);
}
@media (hover: hover) {
  :root .bg-importance-5-20-on-hover:hover, :root .bg-imp-5-20-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-5-rgb), 0.2);
  }
  :root .hover-parent:hover .bg-importance-5-20-on-hover-parent, :root .hover-parent:hover .bg-imp-5-20-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-5-rgb), 0.2);
  }
}
:root .bg-importance-5-30, :root .bg-imp-5-30 {
  background-color: rgba(var(--color-imp-bg-5-rgb), 0.3);
}
@media (hover: hover) {
  :root .bg-importance-5-30-on-hover:hover, :root .bg-imp-5-30-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-5-rgb), 0.3);
  }
  :root .hover-parent:hover .bg-importance-5-30-on-hover-parent, :root .hover-parent:hover .bg-imp-5-30-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-5-rgb), 0.3);
  }
}
:root .bg-importance-5-40, :root .bg-imp-5-40 {
  background-color: rgba(var(--color-imp-bg-5-rgb), 0.4);
}
@media (hover: hover) {
  :root .bg-importance-5-40-on-hover:hover, :root .bg-imp-5-40-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-5-rgb), 0.4);
  }
  :root .hover-parent:hover .bg-importance-5-40-on-hover-parent, :root .hover-parent:hover .bg-imp-5-40-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-5-rgb), 0.4);
  }
}
:root .bg-importance-5-50, :root .bg-imp-5-50 {
  background-color: rgba(var(--color-imp-bg-5-rgb), 0.5);
}
@media (hover: hover) {
  :root .bg-importance-5-50-on-hover:hover, :root .bg-imp-5-50-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-5-rgb), 0.5);
  }
  :root .hover-parent:hover .bg-importance-5-50-on-hover-parent, :root .hover-parent:hover .bg-imp-5-50-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-5-rgb), 0.5);
  }
}
:root .bg-importance-5-60, :root .bg-imp-5-60 {
  background-color: rgba(var(--color-imp-bg-5-rgb), 0.6);
}
@media (hover: hover) {
  :root .bg-importance-5-60-on-hover:hover, :root .bg-imp-5-60-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-5-rgb), 0.6);
  }
  :root .hover-parent:hover .bg-importance-5-60-on-hover-parent, :root .hover-parent:hover .bg-imp-5-60-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-5-rgb), 0.6);
  }
}
:root .bg-importance-5-70, :root .bg-imp-5-70 {
  background-color: rgba(var(--color-imp-bg-5-rgb), 0.7);
}
@media (hover: hover) {
  :root .bg-importance-5-70-on-hover:hover, :root .bg-imp-5-70-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-5-rgb), 0.7);
  }
  :root .hover-parent:hover .bg-importance-5-70-on-hover-parent, :root .hover-parent:hover .bg-imp-5-70-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-5-rgb), 0.7);
  }
}
:root .bg-importance-5-80, :root .bg-imp-5-80 {
  background-color: rgba(var(--color-imp-bg-5-rgb), 0.8);
}
@media (hover: hover) {
  :root .bg-importance-5-80-on-hover:hover, :root .bg-imp-5-80-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-5-rgb), 0.8);
  }
  :root .hover-parent:hover .bg-importance-5-80-on-hover-parent, :root .hover-parent:hover .bg-imp-5-80-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-5-rgb), 0.8);
  }
}
:root .bg-importance-5-90, :root .bg-imp-5-90 {
  background-color: rgba(var(--color-imp-bg-5-rgb), 0.9);
}
@media (hover: hover) {
  :root .bg-importance-5-90-on-hover:hover, :root .bg-imp-5-90-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-5-rgb), 0.9);
  }
  :root .hover-parent:hover .bg-importance-5-90-on-hover-parent, :root .hover-parent:hover .bg-imp-5-90-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-5-rgb), 0.9);
  }
}
:root .bg-importance-5-100, :root .bg-imp-5-100 {
  background-color: rgba(var(--color-imp-bg-5-rgb), 1);
}
@media (hover: hover) {
  :root .bg-importance-5-100-on-hover:hover, :root .bg-imp-5-100-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-5-rgb), 1);
  }
  :root .hover-parent:hover .bg-importance-5-100-on-hover-parent, :root .hover-parent:hover .bg-imp-5-100-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-5-rgb), 1);
  }
}

@media (hover: hover) {
  :root .bg-importance-5-on-hover:hover, :root .bg-imp-5-on-hover:hover {
    background-color: rgb(var(--color-imp-bg-5-rgb));
  }
  :root .hover-parent:hover .bg-importance-5-on-hover-parent, :root .hover-parent:hover .bg-imp-5-on-hover-parent {
    background-color: rgb(var(--color-imp-bg-5-rgb));
  }
}
:root .bg-importance-6, :root .bg-imp-6 {
  background-color: rgb(var(--color-imp-bg-6-rgb));
}
:root .bg-importance-6-0, :root .bg-imp-6-0 {
  background-color: rgba(var(--color-imp-bg-6-rgb), 0);
}
@media (hover: hover) {
  :root .bg-importance-6-0-on-hover:hover, :root .bg-imp-6-0-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-6-rgb), 0);
  }
  :root .hover-parent:hover .bg-importance-6-0-on-hover-parent, :root .hover-parent:hover .bg-imp-6-0-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-6-rgb), 0);
  }
}
:root .bg-importance-6-10, :root .bg-imp-6-10 {
  background-color: rgba(var(--color-imp-bg-6-rgb), 0.1);
}
@media (hover: hover) {
  :root .bg-importance-6-10-on-hover:hover, :root .bg-imp-6-10-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-6-rgb), 0.1);
  }
  :root .hover-parent:hover .bg-importance-6-10-on-hover-parent, :root .hover-parent:hover .bg-imp-6-10-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-6-rgb), 0.1);
  }
}
:root .bg-importance-6-20, :root .bg-imp-6-20 {
  background-color: rgba(var(--color-imp-bg-6-rgb), 0.2);
}
@media (hover: hover) {
  :root .bg-importance-6-20-on-hover:hover, :root .bg-imp-6-20-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-6-rgb), 0.2);
  }
  :root .hover-parent:hover .bg-importance-6-20-on-hover-parent, :root .hover-parent:hover .bg-imp-6-20-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-6-rgb), 0.2);
  }
}
:root .bg-importance-6-30, :root .bg-imp-6-30 {
  background-color: rgba(var(--color-imp-bg-6-rgb), 0.3);
}
@media (hover: hover) {
  :root .bg-importance-6-30-on-hover:hover, :root .bg-imp-6-30-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-6-rgb), 0.3);
  }
  :root .hover-parent:hover .bg-importance-6-30-on-hover-parent, :root .hover-parent:hover .bg-imp-6-30-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-6-rgb), 0.3);
  }
}
:root .bg-importance-6-40, :root .bg-imp-6-40 {
  background-color: rgba(var(--color-imp-bg-6-rgb), 0.4);
}
@media (hover: hover) {
  :root .bg-importance-6-40-on-hover:hover, :root .bg-imp-6-40-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-6-rgb), 0.4);
  }
  :root .hover-parent:hover .bg-importance-6-40-on-hover-parent, :root .hover-parent:hover .bg-imp-6-40-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-6-rgb), 0.4);
  }
}
:root .bg-importance-6-50, :root .bg-imp-6-50 {
  background-color: rgba(var(--color-imp-bg-6-rgb), 0.5);
}
@media (hover: hover) {
  :root .bg-importance-6-50-on-hover:hover, :root .bg-imp-6-50-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-6-rgb), 0.5);
  }
  :root .hover-parent:hover .bg-importance-6-50-on-hover-parent, :root .hover-parent:hover .bg-imp-6-50-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-6-rgb), 0.5);
  }
}
:root .bg-importance-6-60, :root .bg-imp-6-60 {
  background-color: rgba(var(--color-imp-bg-6-rgb), 0.6);
}
@media (hover: hover) {
  :root .bg-importance-6-60-on-hover:hover, :root .bg-imp-6-60-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-6-rgb), 0.6);
  }
  :root .hover-parent:hover .bg-importance-6-60-on-hover-parent, :root .hover-parent:hover .bg-imp-6-60-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-6-rgb), 0.6);
  }
}
:root .bg-importance-6-70, :root .bg-imp-6-70 {
  background-color: rgba(var(--color-imp-bg-6-rgb), 0.7);
}
@media (hover: hover) {
  :root .bg-importance-6-70-on-hover:hover, :root .bg-imp-6-70-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-6-rgb), 0.7);
  }
  :root .hover-parent:hover .bg-importance-6-70-on-hover-parent, :root .hover-parent:hover .bg-imp-6-70-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-6-rgb), 0.7);
  }
}
:root .bg-importance-6-80, :root .bg-imp-6-80 {
  background-color: rgba(var(--color-imp-bg-6-rgb), 0.8);
}
@media (hover: hover) {
  :root .bg-importance-6-80-on-hover:hover, :root .bg-imp-6-80-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-6-rgb), 0.8);
  }
  :root .hover-parent:hover .bg-importance-6-80-on-hover-parent, :root .hover-parent:hover .bg-imp-6-80-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-6-rgb), 0.8);
  }
}
:root .bg-importance-6-90, :root .bg-imp-6-90 {
  background-color: rgba(var(--color-imp-bg-6-rgb), 0.9);
}
@media (hover: hover) {
  :root .bg-importance-6-90-on-hover:hover, :root .bg-imp-6-90-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-6-rgb), 0.9);
  }
  :root .hover-parent:hover .bg-importance-6-90-on-hover-parent, :root .hover-parent:hover .bg-imp-6-90-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-6-rgb), 0.9);
  }
}
:root .bg-importance-6-100, :root .bg-imp-6-100 {
  background-color: rgba(var(--color-imp-bg-6-rgb), 1);
}
@media (hover: hover) {
  :root .bg-importance-6-100-on-hover:hover, :root .bg-imp-6-100-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-6-rgb), 1);
  }
  :root .hover-parent:hover .bg-importance-6-100-on-hover-parent, :root .hover-parent:hover .bg-imp-6-100-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-6-rgb), 1);
  }
}

@media (hover: hover) {
  :root .bg-importance-6-on-hover:hover, :root .bg-imp-6-on-hover:hover {
    background-color: rgb(var(--color-imp-bg-6-rgb));
  }
  :root .hover-parent:hover .bg-importance-6-on-hover-parent, :root .hover-parent:hover .bg-imp-6-on-hover-parent {
    background-color: rgb(var(--color-imp-bg-6-rgb));
  }
}
:root .bg-importance-7, :root .bg-imp-7 {
  background-color: rgb(var(--color-imp-bg-7-rgb));
}
:root .bg-importance-7-0, :root .bg-imp-7-0 {
  background-color: rgba(var(--color-imp-bg-7-rgb), 0);
}
@media (hover: hover) {
  :root .bg-importance-7-0-on-hover:hover, :root .bg-imp-7-0-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-7-rgb), 0);
  }
  :root .hover-parent:hover .bg-importance-7-0-on-hover-parent, :root .hover-parent:hover .bg-imp-7-0-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-7-rgb), 0);
  }
}
:root .bg-importance-7-10, :root .bg-imp-7-10 {
  background-color: rgba(var(--color-imp-bg-7-rgb), 0.1);
}
@media (hover: hover) {
  :root .bg-importance-7-10-on-hover:hover, :root .bg-imp-7-10-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-7-rgb), 0.1);
  }
  :root .hover-parent:hover .bg-importance-7-10-on-hover-parent, :root .hover-parent:hover .bg-imp-7-10-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-7-rgb), 0.1);
  }
}
:root .bg-importance-7-20, :root .bg-imp-7-20 {
  background-color: rgba(var(--color-imp-bg-7-rgb), 0.2);
}
@media (hover: hover) {
  :root .bg-importance-7-20-on-hover:hover, :root .bg-imp-7-20-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-7-rgb), 0.2);
  }
  :root .hover-parent:hover .bg-importance-7-20-on-hover-parent, :root .hover-parent:hover .bg-imp-7-20-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-7-rgb), 0.2);
  }
}
:root .bg-importance-7-30, :root .bg-imp-7-30 {
  background-color: rgba(var(--color-imp-bg-7-rgb), 0.3);
}
@media (hover: hover) {
  :root .bg-importance-7-30-on-hover:hover, :root .bg-imp-7-30-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-7-rgb), 0.3);
  }
  :root .hover-parent:hover .bg-importance-7-30-on-hover-parent, :root .hover-parent:hover .bg-imp-7-30-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-7-rgb), 0.3);
  }
}
:root .bg-importance-7-40, :root .bg-imp-7-40 {
  background-color: rgba(var(--color-imp-bg-7-rgb), 0.4);
}
@media (hover: hover) {
  :root .bg-importance-7-40-on-hover:hover, :root .bg-imp-7-40-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-7-rgb), 0.4);
  }
  :root .hover-parent:hover .bg-importance-7-40-on-hover-parent, :root .hover-parent:hover .bg-imp-7-40-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-7-rgb), 0.4);
  }
}
:root .bg-importance-7-50, :root .bg-imp-7-50 {
  background-color: rgba(var(--color-imp-bg-7-rgb), 0.5);
}
@media (hover: hover) {
  :root .bg-importance-7-50-on-hover:hover, :root .bg-imp-7-50-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-7-rgb), 0.5);
  }
  :root .hover-parent:hover .bg-importance-7-50-on-hover-parent, :root .hover-parent:hover .bg-imp-7-50-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-7-rgb), 0.5);
  }
}
:root .bg-importance-7-60, :root .bg-imp-7-60 {
  background-color: rgba(var(--color-imp-bg-7-rgb), 0.6);
}
@media (hover: hover) {
  :root .bg-importance-7-60-on-hover:hover, :root .bg-imp-7-60-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-7-rgb), 0.6);
  }
  :root .hover-parent:hover .bg-importance-7-60-on-hover-parent, :root .hover-parent:hover .bg-imp-7-60-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-7-rgb), 0.6);
  }
}
:root .bg-importance-7-70, :root .bg-imp-7-70 {
  background-color: rgba(var(--color-imp-bg-7-rgb), 0.7);
}
@media (hover: hover) {
  :root .bg-importance-7-70-on-hover:hover, :root .bg-imp-7-70-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-7-rgb), 0.7);
  }
  :root .hover-parent:hover .bg-importance-7-70-on-hover-parent, :root .hover-parent:hover .bg-imp-7-70-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-7-rgb), 0.7);
  }
}
:root .bg-importance-7-80, :root .bg-imp-7-80 {
  background-color: rgba(var(--color-imp-bg-7-rgb), 0.8);
}
@media (hover: hover) {
  :root .bg-importance-7-80-on-hover:hover, :root .bg-imp-7-80-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-7-rgb), 0.8);
  }
  :root .hover-parent:hover .bg-importance-7-80-on-hover-parent, :root .hover-parent:hover .bg-imp-7-80-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-7-rgb), 0.8);
  }
}
:root .bg-importance-7-90, :root .bg-imp-7-90 {
  background-color: rgba(var(--color-imp-bg-7-rgb), 0.9);
}
@media (hover: hover) {
  :root .bg-importance-7-90-on-hover:hover, :root .bg-imp-7-90-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-7-rgb), 0.9);
  }
  :root .hover-parent:hover .bg-importance-7-90-on-hover-parent, :root .hover-parent:hover .bg-imp-7-90-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-7-rgb), 0.9);
  }
}
:root .bg-importance-7-100, :root .bg-imp-7-100 {
  background-color: rgba(var(--color-imp-bg-7-rgb), 1);
}
@media (hover: hover) {
  :root .bg-importance-7-100-on-hover:hover, :root .bg-imp-7-100-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-7-rgb), 1);
  }
  :root .hover-parent:hover .bg-importance-7-100-on-hover-parent, :root .hover-parent:hover .bg-imp-7-100-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-7-rgb), 1);
  }
}

@media (hover: hover) {
  :root .bg-importance-7-on-hover:hover, :root .bg-imp-7-on-hover:hover {
    background-color: rgb(var(--color-imp-bg-7-rgb));
  }
  :root .hover-parent:hover .bg-importance-7-on-hover-parent, :root .hover-parent:hover .bg-imp-7-on-hover-parent {
    background-color: rgb(var(--color-imp-bg-7-rgb));
  }
}
:root .bg-importance-8, :root .bg-imp-8 {
  background-color: rgb(var(--color-imp-bg-8-rgb));
}
:root .bg-importance-8-0, :root .bg-imp-8-0 {
  background-color: rgba(var(--color-imp-bg-8-rgb), 0);
}
@media (hover: hover) {
  :root .bg-importance-8-0-on-hover:hover, :root .bg-imp-8-0-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-8-rgb), 0);
  }
  :root .hover-parent:hover .bg-importance-8-0-on-hover-parent, :root .hover-parent:hover .bg-imp-8-0-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-8-rgb), 0);
  }
}
:root .bg-importance-8-10, :root .bg-imp-8-10 {
  background-color: rgba(var(--color-imp-bg-8-rgb), 0.1);
}
@media (hover: hover) {
  :root .bg-importance-8-10-on-hover:hover, :root .bg-imp-8-10-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-8-rgb), 0.1);
  }
  :root .hover-parent:hover .bg-importance-8-10-on-hover-parent, :root .hover-parent:hover .bg-imp-8-10-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-8-rgb), 0.1);
  }
}
:root .bg-importance-8-20, :root .bg-imp-8-20 {
  background-color: rgba(var(--color-imp-bg-8-rgb), 0.2);
}
@media (hover: hover) {
  :root .bg-importance-8-20-on-hover:hover, :root .bg-imp-8-20-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-8-rgb), 0.2);
  }
  :root .hover-parent:hover .bg-importance-8-20-on-hover-parent, :root .hover-parent:hover .bg-imp-8-20-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-8-rgb), 0.2);
  }
}
:root .bg-importance-8-30, :root .bg-imp-8-30 {
  background-color: rgba(var(--color-imp-bg-8-rgb), 0.3);
}
@media (hover: hover) {
  :root .bg-importance-8-30-on-hover:hover, :root .bg-imp-8-30-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-8-rgb), 0.3);
  }
  :root .hover-parent:hover .bg-importance-8-30-on-hover-parent, :root .hover-parent:hover .bg-imp-8-30-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-8-rgb), 0.3);
  }
}
:root .bg-importance-8-40, :root .bg-imp-8-40 {
  background-color: rgba(var(--color-imp-bg-8-rgb), 0.4);
}
@media (hover: hover) {
  :root .bg-importance-8-40-on-hover:hover, :root .bg-imp-8-40-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-8-rgb), 0.4);
  }
  :root .hover-parent:hover .bg-importance-8-40-on-hover-parent, :root .hover-parent:hover .bg-imp-8-40-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-8-rgb), 0.4);
  }
}
:root .bg-importance-8-50, :root .bg-imp-8-50 {
  background-color: rgba(var(--color-imp-bg-8-rgb), 0.5);
}
@media (hover: hover) {
  :root .bg-importance-8-50-on-hover:hover, :root .bg-imp-8-50-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-8-rgb), 0.5);
  }
  :root .hover-parent:hover .bg-importance-8-50-on-hover-parent, :root .hover-parent:hover .bg-imp-8-50-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-8-rgb), 0.5);
  }
}
:root .bg-importance-8-60, :root .bg-imp-8-60 {
  background-color: rgba(var(--color-imp-bg-8-rgb), 0.6);
}
@media (hover: hover) {
  :root .bg-importance-8-60-on-hover:hover, :root .bg-imp-8-60-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-8-rgb), 0.6);
  }
  :root .hover-parent:hover .bg-importance-8-60-on-hover-parent, :root .hover-parent:hover .bg-imp-8-60-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-8-rgb), 0.6);
  }
}
:root .bg-importance-8-70, :root .bg-imp-8-70 {
  background-color: rgba(var(--color-imp-bg-8-rgb), 0.7);
}
@media (hover: hover) {
  :root .bg-importance-8-70-on-hover:hover, :root .bg-imp-8-70-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-8-rgb), 0.7);
  }
  :root .hover-parent:hover .bg-importance-8-70-on-hover-parent, :root .hover-parent:hover .bg-imp-8-70-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-8-rgb), 0.7);
  }
}
:root .bg-importance-8-80, :root .bg-imp-8-80 {
  background-color: rgba(var(--color-imp-bg-8-rgb), 0.8);
}
@media (hover: hover) {
  :root .bg-importance-8-80-on-hover:hover, :root .bg-imp-8-80-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-8-rgb), 0.8);
  }
  :root .hover-parent:hover .bg-importance-8-80-on-hover-parent, :root .hover-parent:hover .bg-imp-8-80-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-8-rgb), 0.8);
  }
}
:root .bg-importance-8-90, :root .bg-imp-8-90 {
  background-color: rgba(var(--color-imp-bg-8-rgb), 0.9);
}
@media (hover: hover) {
  :root .bg-importance-8-90-on-hover:hover, :root .bg-imp-8-90-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-8-rgb), 0.9);
  }
  :root .hover-parent:hover .bg-importance-8-90-on-hover-parent, :root .hover-parent:hover .bg-imp-8-90-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-8-rgb), 0.9);
  }
}
:root .bg-importance-8-100, :root .bg-imp-8-100 {
  background-color: rgba(var(--color-imp-bg-8-rgb), 1);
}
@media (hover: hover) {
  :root .bg-importance-8-100-on-hover:hover, :root .bg-imp-8-100-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-8-rgb), 1);
  }
  :root .hover-parent:hover .bg-importance-8-100-on-hover-parent, :root .hover-parent:hover .bg-imp-8-100-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-8-rgb), 1);
  }
}

@media (hover: hover) {
  :root .bg-importance-8-on-hover:hover, :root .bg-imp-8-on-hover:hover {
    background-color: rgb(var(--color-imp-bg-8-rgb));
  }
  :root .hover-parent:hover .bg-importance-8-on-hover-parent, :root .hover-parent:hover .bg-imp-8-on-hover-parent {
    background-color: rgb(var(--color-imp-bg-8-rgb));
  }
}
:root .bg-importance-9, :root .bg-imp-9 {
  background-color: rgb(var(--color-imp-bg-9-rgb));
}
:root .bg-importance-9-0, :root .bg-imp-9-0 {
  background-color: rgba(var(--color-imp-bg-9-rgb), 0);
}
@media (hover: hover) {
  :root .bg-importance-9-0-on-hover:hover, :root .bg-imp-9-0-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-9-rgb), 0);
  }
  :root .hover-parent:hover .bg-importance-9-0-on-hover-parent, :root .hover-parent:hover .bg-imp-9-0-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-9-rgb), 0);
  }
}
:root .bg-importance-9-10, :root .bg-imp-9-10 {
  background-color: rgba(var(--color-imp-bg-9-rgb), 0.1);
}
@media (hover: hover) {
  :root .bg-importance-9-10-on-hover:hover, :root .bg-imp-9-10-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-9-rgb), 0.1);
  }
  :root .hover-parent:hover .bg-importance-9-10-on-hover-parent, :root .hover-parent:hover .bg-imp-9-10-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-9-rgb), 0.1);
  }
}
:root .bg-importance-9-20, :root .bg-imp-9-20 {
  background-color: rgba(var(--color-imp-bg-9-rgb), 0.2);
}
@media (hover: hover) {
  :root .bg-importance-9-20-on-hover:hover, :root .bg-imp-9-20-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-9-rgb), 0.2);
  }
  :root .hover-parent:hover .bg-importance-9-20-on-hover-parent, :root .hover-parent:hover .bg-imp-9-20-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-9-rgb), 0.2);
  }
}
:root .bg-importance-9-30, :root .bg-imp-9-30 {
  background-color: rgba(var(--color-imp-bg-9-rgb), 0.3);
}
@media (hover: hover) {
  :root .bg-importance-9-30-on-hover:hover, :root .bg-imp-9-30-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-9-rgb), 0.3);
  }
  :root .hover-parent:hover .bg-importance-9-30-on-hover-parent, :root .hover-parent:hover .bg-imp-9-30-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-9-rgb), 0.3);
  }
}
:root .bg-importance-9-40, :root .bg-imp-9-40 {
  background-color: rgba(var(--color-imp-bg-9-rgb), 0.4);
}
@media (hover: hover) {
  :root .bg-importance-9-40-on-hover:hover, :root .bg-imp-9-40-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-9-rgb), 0.4);
  }
  :root .hover-parent:hover .bg-importance-9-40-on-hover-parent, :root .hover-parent:hover .bg-imp-9-40-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-9-rgb), 0.4);
  }
}
:root .bg-importance-9-50, :root .bg-imp-9-50 {
  background-color: rgba(var(--color-imp-bg-9-rgb), 0.5);
}
@media (hover: hover) {
  :root .bg-importance-9-50-on-hover:hover, :root .bg-imp-9-50-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-9-rgb), 0.5);
  }
  :root .hover-parent:hover .bg-importance-9-50-on-hover-parent, :root .hover-parent:hover .bg-imp-9-50-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-9-rgb), 0.5);
  }
}
:root .bg-importance-9-60, :root .bg-imp-9-60 {
  background-color: rgba(var(--color-imp-bg-9-rgb), 0.6);
}
@media (hover: hover) {
  :root .bg-importance-9-60-on-hover:hover, :root .bg-imp-9-60-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-9-rgb), 0.6);
  }
  :root .hover-parent:hover .bg-importance-9-60-on-hover-parent, :root .hover-parent:hover .bg-imp-9-60-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-9-rgb), 0.6);
  }
}
:root .bg-importance-9-70, :root .bg-imp-9-70 {
  background-color: rgba(var(--color-imp-bg-9-rgb), 0.7);
}
@media (hover: hover) {
  :root .bg-importance-9-70-on-hover:hover, :root .bg-imp-9-70-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-9-rgb), 0.7);
  }
  :root .hover-parent:hover .bg-importance-9-70-on-hover-parent, :root .hover-parent:hover .bg-imp-9-70-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-9-rgb), 0.7);
  }
}
:root .bg-importance-9-80, :root .bg-imp-9-80 {
  background-color: rgba(var(--color-imp-bg-9-rgb), 0.8);
}
@media (hover: hover) {
  :root .bg-importance-9-80-on-hover:hover, :root .bg-imp-9-80-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-9-rgb), 0.8);
  }
  :root .hover-parent:hover .bg-importance-9-80-on-hover-parent, :root .hover-parent:hover .bg-imp-9-80-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-9-rgb), 0.8);
  }
}
:root .bg-importance-9-90, :root .bg-imp-9-90 {
  background-color: rgba(var(--color-imp-bg-9-rgb), 0.9);
}
@media (hover: hover) {
  :root .bg-importance-9-90-on-hover:hover, :root .bg-imp-9-90-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-9-rgb), 0.9);
  }
  :root .hover-parent:hover .bg-importance-9-90-on-hover-parent, :root .hover-parent:hover .bg-imp-9-90-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-9-rgb), 0.9);
  }
}
:root .bg-importance-9-100, :root .bg-imp-9-100 {
  background-color: rgba(var(--color-imp-bg-9-rgb), 1);
}
@media (hover: hover) {
  :root .bg-importance-9-100-on-hover:hover, :root .bg-imp-9-100-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-9-rgb), 1);
  }
  :root .hover-parent:hover .bg-importance-9-100-on-hover-parent, :root .hover-parent:hover .bg-imp-9-100-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-9-rgb), 1);
  }
}

@media (hover: hover) {
  :root .bg-importance-9-on-hover:hover, :root .bg-imp-9-on-hover:hover {
    background-color: rgb(var(--color-imp-bg-9-rgb));
  }
  :root .hover-parent:hover .bg-importance-9-on-hover-parent, :root .hover-parent:hover .bg-imp-9-on-hover-parent {
    background-color: rgb(var(--color-imp-bg-9-rgb));
  }
}
:root .bg-importance-10, :root .bg-imp-10 {
  background-color: rgb(var(--color-imp-bg-10-rgb));
}
:root .bg-importance-10-0, :root .bg-imp-10-0 {
  background-color: rgba(var(--color-imp-bg-10-rgb), 0);
}
@media (hover: hover) {
  :root .bg-importance-10-0-on-hover:hover, :root .bg-imp-10-0-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-10-rgb), 0);
  }
  :root .hover-parent:hover .bg-importance-10-0-on-hover-parent, :root .hover-parent:hover .bg-imp-10-0-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-10-rgb), 0);
  }
}
:root .bg-importance-10-10, :root .bg-imp-10-10 {
  background-color: rgba(var(--color-imp-bg-10-rgb), 0.1);
}
@media (hover: hover) {
  :root .bg-importance-10-10-on-hover:hover, :root .bg-imp-10-10-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-10-rgb), 0.1);
  }
  :root .hover-parent:hover .bg-importance-10-10-on-hover-parent, :root .hover-parent:hover .bg-imp-10-10-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-10-rgb), 0.1);
  }
}
:root .bg-importance-10-20, :root .bg-imp-10-20 {
  background-color: rgba(var(--color-imp-bg-10-rgb), 0.2);
}
@media (hover: hover) {
  :root .bg-importance-10-20-on-hover:hover, :root .bg-imp-10-20-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-10-rgb), 0.2);
  }
  :root .hover-parent:hover .bg-importance-10-20-on-hover-parent, :root .hover-parent:hover .bg-imp-10-20-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-10-rgb), 0.2);
  }
}
:root .bg-importance-10-30, :root .bg-imp-10-30 {
  background-color: rgba(var(--color-imp-bg-10-rgb), 0.3);
}
@media (hover: hover) {
  :root .bg-importance-10-30-on-hover:hover, :root .bg-imp-10-30-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-10-rgb), 0.3);
  }
  :root .hover-parent:hover .bg-importance-10-30-on-hover-parent, :root .hover-parent:hover .bg-imp-10-30-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-10-rgb), 0.3);
  }
}
:root .bg-importance-10-40, :root .bg-imp-10-40 {
  background-color: rgba(var(--color-imp-bg-10-rgb), 0.4);
}
@media (hover: hover) {
  :root .bg-importance-10-40-on-hover:hover, :root .bg-imp-10-40-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-10-rgb), 0.4);
  }
  :root .hover-parent:hover .bg-importance-10-40-on-hover-parent, :root .hover-parent:hover .bg-imp-10-40-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-10-rgb), 0.4);
  }
}
:root .bg-importance-10-50, :root .bg-imp-10-50 {
  background-color: rgba(var(--color-imp-bg-10-rgb), 0.5);
}
@media (hover: hover) {
  :root .bg-importance-10-50-on-hover:hover, :root .bg-imp-10-50-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-10-rgb), 0.5);
  }
  :root .hover-parent:hover .bg-importance-10-50-on-hover-parent, :root .hover-parent:hover .bg-imp-10-50-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-10-rgb), 0.5);
  }
}
:root .bg-importance-10-60, :root .bg-imp-10-60 {
  background-color: rgba(var(--color-imp-bg-10-rgb), 0.6);
}
@media (hover: hover) {
  :root .bg-importance-10-60-on-hover:hover, :root .bg-imp-10-60-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-10-rgb), 0.6);
  }
  :root .hover-parent:hover .bg-importance-10-60-on-hover-parent, :root .hover-parent:hover .bg-imp-10-60-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-10-rgb), 0.6);
  }
}
:root .bg-importance-10-70, :root .bg-imp-10-70 {
  background-color: rgba(var(--color-imp-bg-10-rgb), 0.7);
}
@media (hover: hover) {
  :root .bg-importance-10-70-on-hover:hover, :root .bg-imp-10-70-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-10-rgb), 0.7);
  }
  :root .hover-parent:hover .bg-importance-10-70-on-hover-parent, :root .hover-parent:hover .bg-imp-10-70-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-10-rgb), 0.7);
  }
}
:root .bg-importance-10-80, :root .bg-imp-10-80 {
  background-color: rgba(var(--color-imp-bg-10-rgb), 0.8);
}
@media (hover: hover) {
  :root .bg-importance-10-80-on-hover:hover, :root .bg-imp-10-80-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-10-rgb), 0.8);
  }
  :root .hover-parent:hover .bg-importance-10-80-on-hover-parent, :root .hover-parent:hover .bg-imp-10-80-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-10-rgb), 0.8);
  }
}
:root .bg-importance-10-90, :root .bg-imp-10-90 {
  background-color: rgba(var(--color-imp-bg-10-rgb), 0.9);
}
@media (hover: hover) {
  :root .bg-importance-10-90-on-hover:hover, :root .bg-imp-10-90-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-10-rgb), 0.9);
  }
  :root .hover-parent:hover .bg-importance-10-90-on-hover-parent, :root .hover-parent:hover .bg-imp-10-90-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-10-rgb), 0.9);
  }
}
:root .bg-importance-10-100, :root .bg-imp-10-100 {
  background-color: rgba(var(--color-imp-bg-10-rgb), 1);
}
@media (hover: hover) {
  :root .bg-importance-10-100-on-hover:hover, :root .bg-imp-10-100-on-hover:hover {
    background-color: rgba(var(--color-imp-bg-10-rgb), 1);
  }
  :root .hover-parent:hover .bg-importance-10-100-on-hover-parent, :root .hover-parent:hover .bg-imp-10-100-on-hover-parent {
    background-color: rgba(var(--color-imp-bg-10-rgb), 1);
  }
}

@media (hover: hover) {
  :root .bg-importance-10-on-hover:hover, :root .bg-imp-10-on-hover:hover {
    background-color: rgb(var(--color-imp-bg-10-rgb));
  }
  :root .hover-parent:hover .bg-importance-10-on-hover-parent, :root .hover-parent:hover .bg-imp-10-on-hover-parent {
    background-color: rgb(var(--color-imp-bg-10-rgb));
  }
}

:root .bg-190-blur-50-100 {
  background-color: rgba(var(--color-bg-190-rgb), 1);
}
@supports (backdrop-filter: blur(8px)) or (--webkit-backdrop-filter: blur(8px)) {
  :root .bg-190-blur-50-100 {
    background: linear-gradient(to bottom, rgba(var(--color-bg-190-rgb), 0.5), rgba(var(--color-bg-190-rgb), 1));
    backdrop-filter: blur(8px);
    --webkit-backdrop-filter: blur(8px);
  }
}
:root .bg-190-blur-100-50 {
  background-color: rgba(var(--color-bg-190-rgb), 1);
}
@supports (backdrop-filter: blur(8px)) or (--webkit-backdrop-filter: blur(8px)) {
  :root .bg-190-blur-100-50 {
    background: linear-gradient(to bottom, rgba(var(--color-bg-190-rgb), 1), rgba(var(--color-bg-190-rgb), 0.5));
    backdrop-filter: blur(8px);
    --webkit-backdrop-filter: blur(8px);
  }
}

/* 
	This generates all the permutations of the border colors, resulting in:
    'light': (
        '230': <light value for color '2'>,
        (...)
    ),
    'dark': (
        '230': <dark value for color '3'>,
        (...)
    ),
*/
:root {
  --color-line-230-rgb: 226, 226, 226;
  --color-line-230: rgb(var(--color-line-230-rgb));
  --color-line-250-rgb: 226, 226, 226;
  --color-line-250: rgb(var(--color-line-250-rgb));
  --color-line-330-rgb: 185, 184, 188;
  --color-line-330: rgb(var(--color-line-330-rgb));
  --color-line-350-rgb: 185, 184, 188;
  --color-line-350: rgb(var(--color-line-350-rgb));
  --light-color-line-230-rgb: 226, 226, 226;
  --light-color-line-230: rgb(var(--light-color-line-230-rgb));
  --light-color-line-250-rgb: 226, 226, 226;
  --light-color-line-250: rgb(var(--light-color-line-250-rgb));
  --light-color-line-330-rgb: 185, 184, 188;
  --light-color-line-330: rgb(var(--light-color-line-330-rgb));
  --light-color-line-350-rgb: 185, 184, 188;
  --light-color-line-350: rgb(var(--light-color-line-350-rgb));
  --dark-color-line-230-rgb: 78, 79, 85;
  --dark-color-line-230: rgb(var(--dark-color-line-230-rgb));
  --dark-color-line-250-rgb: 45, 46, 49;
  --dark-color-line-250: rgb(var(--dark-color-line-250-rgb));
  --dark-color-line-330-rgb: 78, 79, 85;
  --dark-color-line-330: rgb(var(--dark-color-line-330-rgb));
  --dark-color-line-350-rgb: 45, 46, 49;
  --dark-color-line-350: rgb(var(--dark-color-line-350-rgb));
}
:root[app-theme=auto][client-theme=dark], :root[app-theme=dark] {
  --color-line-230-rgb: 78, 79, 85;
  --color-line-230: rgb(var(--color-line-230-rgb));
  --color-line-250-rgb: 45, 46, 49;
  --color-line-250: rgb(var(--color-line-250-rgb));
  --color-line-330-rgb: 78, 79, 85;
  --color-line-330: rgb(var(--color-line-330-rgb));
  --color-line-350-rgb: 45, 46, 49;
  --color-line-350: rgb(var(--color-line-350-rgb));
}

:root .bordered {
  border: 1px solid rgb(var(--color-line-250-rgb));
}

:root .line-transparent,
:root .border-transparent {
  border-color: transparent;
}

:root .line-230,
:root .border-230 {
  border: 1px solid rgb(var(--color-line-230-rgb));
}
:root .line-230-t,
:root .border-230-t {
  border-top: 1px solid rgb(var(--color-line-230-rgb));
}
:root .line-230-tr,
:root .border-230-tr {
  border-top: 1px solid rgb(var(--color-line-230-rgb));
}
:root .line-230-trb,
:root .border-230-trb {
  border-top: 1px solid rgb(var(--color-line-230-rgb));
}
:root .line-230-trbl,
:root .border-230-trbl {
  border-top: 1px solid rgb(var(--color-line-230-rgb));
}
:root .line-230-trl,
:root .border-230-trl {
  border-top: 1px solid rgb(var(--color-line-230-rgb));
}
:root .line-230-tb,
:root .border-230-tb {
  border-top: 1px solid rgb(var(--color-line-230-rgb));
}
:root .line-230-tbl,
:root .border-230-tbl {
  border-top: 1px solid rgb(var(--color-line-230-rgb));
}
:root .line-230-tl,
:root .border-230-tl {
  border-top: 1px solid rgb(var(--color-line-230-rgb));
}
:root .line-230-tr,
:root .border-230-tr {
  border-right: 1px solid rgb(var(--color-line-230-rgb));
}
:root .line-230-trb,
:root .border-230-trb {
  border-right: 1px solid rgb(var(--color-line-230-rgb));
}
:root .line-230-trbl,
:root .border-230-trbl {
  border-right: 1px solid rgb(var(--color-line-230-rgb));
}
:root .line-230-trl,
:root .border-230-trl {
  border-right: 1px solid rgb(var(--color-line-230-rgb));
}
:root .line-230-r,
:root .border-230-r {
  border-right: 1px solid rgb(var(--color-line-230-rgb));
}
:root .line-230-rb,
:root .border-230-rb {
  border-right: 1px solid rgb(var(--color-line-230-rgb));
}
:root .line-230-rbl,
:root .border-230-rbl {
  border-right: 1px solid rgb(var(--color-line-230-rgb));
}
:root .line-230-rl,
:root .border-230-rl {
  border-right: 1px solid rgb(var(--color-line-230-rgb));
}
:root .line-230-trb,
:root .border-230-trb {
  border-bottom: 1px solid rgb(var(--color-line-230-rgb));
}
:root .line-230-trbl,
:root .border-230-trbl {
  border-bottom: 1px solid rgb(var(--color-line-230-rgb));
}
:root .line-230-tb,
:root .border-230-tb {
  border-bottom: 1px solid rgb(var(--color-line-230-rgb));
}
:root .line-230-tbl,
:root .border-230-tbl {
  border-bottom: 1px solid rgb(var(--color-line-230-rgb));
}
:root .line-230-rb,
:root .border-230-rb {
  border-bottom: 1px solid rgb(var(--color-line-230-rgb));
}
:root .line-230-rbl,
:root .border-230-rbl {
  border-bottom: 1px solid rgb(var(--color-line-230-rgb));
}
:root .line-230-b,
:root .border-230-b {
  border-bottom: 1px solid rgb(var(--color-line-230-rgb));
}
:root .line-230-bl,
:root .border-230-bl {
  border-bottom: 1px solid rgb(var(--color-line-230-rgb));
}
:root .line-230-trbl,
:root .border-230-trbl {
  border-left: 1px solid rgb(var(--color-line-230-rgb));
}
:root .line-230-trl,
:root .border-230-trl {
  border-left: 1px solid rgb(var(--color-line-230-rgb));
}
:root .line-230-tbl,
:root .border-230-tbl {
  border-left: 1px solid rgb(var(--color-line-230-rgb));
}
:root .line-230-tl,
:root .border-230-tl {
  border-left: 1px solid rgb(var(--color-line-230-rgb));
}
:root .line-230-rbl,
:root .border-230-rbl {
  border-left: 1px solid rgb(var(--color-line-230-rgb));
}
:root .line-230-rl,
:root .border-230-rl {
  border-left: 1px solid rgb(var(--color-line-230-rgb));
}
:root .line-230-bl,
:root .border-230-bl {
  border-left: 1px solid rgb(var(--color-line-230-rgb));
}
:root .line-230-l,
:root .border-230-l {
  border-left: 1px solid rgb(var(--color-line-230-rgb));
}
:root .line-230-as-fc,
:root .border-230-as-fc {
  color: rgb(var(--color-line-230-rgb));
}
:root .line-230-as-bg,
:root .border-230-as-bg {
  background-color: rgb(var(--color-line-230-rgb));
}

@media (hover: hover) {
  :root .line-230-on-hover:hover,
  :root .border-230-on-hover:hover {
    border-color: rgb(var(--color-line-230-rgb));
  }
  :root .hover-parent:hover .line-230-on-hover-parent,
  :root .hover-parent:hover .border-230-on-hover-parent {
    border-color: rgb(var(--color-line-230-rgb));
  }
}
:root .line-250,
:root .border-250 {
  border: 1px solid rgb(var(--color-line-250-rgb));
}
:root .line-250-t,
:root .border-250-t {
  border-top: 1px solid rgb(var(--color-line-250-rgb));
}
:root .line-250-tr,
:root .border-250-tr {
  border-top: 1px solid rgb(var(--color-line-250-rgb));
}
:root .line-250-trb,
:root .border-250-trb {
  border-top: 1px solid rgb(var(--color-line-250-rgb));
}
:root .line-250-trbl,
:root .border-250-trbl {
  border-top: 1px solid rgb(var(--color-line-250-rgb));
}
:root .line-250-trl,
:root .border-250-trl {
  border-top: 1px solid rgb(var(--color-line-250-rgb));
}
:root .line-250-tb,
:root .border-250-tb {
  border-top: 1px solid rgb(var(--color-line-250-rgb));
}
:root .line-250-tbl,
:root .border-250-tbl {
  border-top: 1px solid rgb(var(--color-line-250-rgb));
}
:root .line-250-tl,
:root .border-250-tl {
  border-top: 1px solid rgb(var(--color-line-250-rgb));
}
:root .line-250-tr,
:root .border-250-tr {
  border-right: 1px solid rgb(var(--color-line-250-rgb));
}
:root .line-250-trb,
:root .border-250-trb {
  border-right: 1px solid rgb(var(--color-line-250-rgb));
}
:root .line-250-trbl,
:root .border-250-trbl {
  border-right: 1px solid rgb(var(--color-line-250-rgb));
}
:root .line-250-trl,
:root .border-250-trl {
  border-right: 1px solid rgb(var(--color-line-250-rgb));
}
:root .line-250-r,
:root .border-250-r {
  border-right: 1px solid rgb(var(--color-line-250-rgb));
}
:root .line-250-rb,
:root .border-250-rb {
  border-right: 1px solid rgb(var(--color-line-250-rgb));
}
:root .line-250-rbl,
:root .border-250-rbl {
  border-right: 1px solid rgb(var(--color-line-250-rgb));
}
:root .line-250-rl,
:root .border-250-rl {
  border-right: 1px solid rgb(var(--color-line-250-rgb));
}
:root .line-250-trb,
:root .border-250-trb {
  border-bottom: 1px solid rgb(var(--color-line-250-rgb));
}
:root .line-250-trbl,
:root .border-250-trbl {
  border-bottom: 1px solid rgb(var(--color-line-250-rgb));
}
:root .line-250-tb,
:root .border-250-tb {
  border-bottom: 1px solid rgb(var(--color-line-250-rgb));
}
:root .line-250-tbl,
:root .border-250-tbl {
  border-bottom: 1px solid rgb(var(--color-line-250-rgb));
}
:root .line-250-rb,
:root .border-250-rb {
  border-bottom: 1px solid rgb(var(--color-line-250-rgb));
}
:root .line-250-rbl,
:root .border-250-rbl {
  border-bottom: 1px solid rgb(var(--color-line-250-rgb));
}
:root .line-250-b,
:root .border-250-b {
  border-bottom: 1px solid rgb(var(--color-line-250-rgb));
}
:root .line-250-bl,
:root .border-250-bl {
  border-bottom: 1px solid rgb(var(--color-line-250-rgb));
}
:root .line-250-trbl,
:root .border-250-trbl {
  border-left: 1px solid rgb(var(--color-line-250-rgb));
}
:root .line-250-trl,
:root .border-250-trl {
  border-left: 1px solid rgb(var(--color-line-250-rgb));
}
:root .line-250-tbl,
:root .border-250-tbl {
  border-left: 1px solid rgb(var(--color-line-250-rgb));
}
:root .line-250-tl,
:root .border-250-tl {
  border-left: 1px solid rgb(var(--color-line-250-rgb));
}
:root .line-250-rbl,
:root .border-250-rbl {
  border-left: 1px solid rgb(var(--color-line-250-rgb));
}
:root .line-250-rl,
:root .border-250-rl {
  border-left: 1px solid rgb(var(--color-line-250-rgb));
}
:root .line-250-bl,
:root .border-250-bl {
  border-left: 1px solid rgb(var(--color-line-250-rgb));
}
:root .line-250-l,
:root .border-250-l {
  border-left: 1px solid rgb(var(--color-line-250-rgb));
}
:root .line-250-as-fc,
:root .border-250-as-fc {
  color: rgb(var(--color-line-250-rgb));
}
:root .line-250-as-bg,
:root .border-250-as-bg {
  background-color: rgb(var(--color-line-250-rgb));
}

@media (hover: hover) {
  :root .line-250-on-hover:hover,
  :root .border-250-on-hover:hover {
    border-color: rgb(var(--color-line-250-rgb));
  }
  :root .hover-parent:hover .line-250-on-hover-parent,
  :root .hover-parent:hover .border-250-on-hover-parent {
    border-color: rgb(var(--color-line-250-rgb));
  }
}
:root .line-330,
:root .border-330 {
  border: 1px solid rgb(var(--color-line-330-rgb));
}
:root .line-330-t,
:root .border-330-t {
  border-top: 1px solid rgb(var(--color-line-330-rgb));
}
:root .line-330-tr,
:root .border-330-tr {
  border-top: 1px solid rgb(var(--color-line-330-rgb));
}
:root .line-330-trb,
:root .border-330-trb {
  border-top: 1px solid rgb(var(--color-line-330-rgb));
}
:root .line-330-trbl,
:root .border-330-trbl {
  border-top: 1px solid rgb(var(--color-line-330-rgb));
}
:root .line-330-trl,
:root .border-330-trl {
  border-top: 1px solid rgb(var(--color-line-330-rgb));
}
:root .line-330-tb,
:root .border-330-tb {
  border-top: 1px solid rgb(var(--color-line-330-rgb));
}
:root .line-330-tbl,
:root .border-330-tbl {
  border-top: 1px solid rgb(var(--color-line-330-rgb));
}
:root .line-330-tl,
:root .border-330-tl {
  border-top: 1px solid rgb(var(--color-line-330-rgb));
}
:root .line-330-tr,
:root .border-330-tr {
  border-right: 1px solid rgb(var(--color-line-330-rgb));
}
:root .line-330-trb,
:root .border-330-trb {
  border-right: 1px solid rgb(var(--color-line-330-rgb));
}
:root .line-330-trbl,
:root .border-330-trbl {
  border-right: 1px solid rgb(var(--color-line-330-rgb));
}
:root .line-330-trl,
:root .border-330-trl {
  border-right: 1px solid rgb(var(--color-line-330-rgb));
}
:root .line-330-r,
:root .border-330-r {
  border-right: 1px solid rgb(var(--color-line-330-rgb));
}
:root .line-330-rb,
:root .border-330-rb {
  border-right: 1px solid rgb(var(--color-line-330-rgb));
}
:root .line-330-rbl,
:root .border-330-rbl {
  border-right: 1px solid rgb(var(--color-line-330-rgb));
}
:root .line-330-rl,
:root .border-330-rl {
  border-right: 1px solid rgb(var(--color-line-330-rgb));
}
:root .line-330-trb,
:root .border-330-trb {
  border-bottom: 1px solid rgb(var(--color-line-330-rgb));
}
:root .line-330-trbl,
:root .border-330-trbl {
  border-bottom: 1px solid rgb(var(--color-line-330-rgb));
}
:root .line-330-tb,
:root .border-330-tb {
  border-bottom: 1px solid rgb(var(--color-line-330-rgb));
}
:root .line-330-tbl,
:root .border-330-tbl {
  border-bottom: 1px solid rgb(var(--color-line-330-rgb));
}
:root .line-330-rb,
:root .border-330-rb {
  border-bottom: 1px solid rgb(var(--color-line-330-rgb));
}
:root .line-330-rbl,
:root .border-330-rbl {
  border-bottom: 1px solid rgb(var(--color-line-330-rgb));
}
:root .line-330-b,
:root .border-330-b {
  border-bottom: 1px solid rgb(var(--color-line-330-rgb));
}
:root .line-330-bl,
:root .border-330-bl {
  border-bottom: 1px solid rgb(var(--color-line-330-rgb));
}
:root .line-330-trbl,
:root .border-330-trbl {
  border-left: 1px solid rgb(var(--color-line-330-rgb));
}
:root .line-330-trl,
:root .border-330-trl {
  border-left: 1px solid rgb(var(--color-line-330-rgb));
}
:root .line-330-tbl,
:root .border-330-tbl {
  border-left: 1px solid rgb(var(--color-line-330-rgb));
}
:root .line-330-tl,
:root .border-330-tl {
  border-left: 1px solid rgb(var(--color-line-330-rgb));
}
:root .line-330-rbl,
:root .border-330-rbl {
  border-left: 1px solid rgb(var(--color-line-330-rgb));
}
:root .line-330-rl,
:root .border-330-rl {
  border-left: 1px solid rgb(var(--color-line-330-rgb));
}
:root .line-330-bl,
:root .border-330-bl {
  border-left: 1px solid rgb(var(--color-line-330-rgb));
}
:root .line-330-l,
:root .border-330-l {
  border-left: 1px solid rgb(var(--color-line-330-rgb));
}
:root .line-330-as-fc,
:root .border-330-as-fc {
  color: rgb(var(--color-line-330-rgb));
}
:root .line-330-as-bg,
:root .border-330-as-bg {
  background-color: rgb(var(--color-line-330-rgb));
}

@media (hover: hover) {
  :root .line-330-on-hover:hover,
  :root .border-330-on-hover:hover {
    border-color: rgb(var(--color-line-330-rgb));
  }
  :root .hover-parent:hover .line-330-on-hover-parent,
  :root .hover-parent:hover .border-330-on-hover-parent {
    border-color: rgb(var(--color-line-330-rgb));
  }
}
:root .line-350,
:root .border-350 {
  border: 1px solid rgb(var(--color-line-350-rgb));
}
:root .line-350-t,
:root .border-350-t {
  border-top: 1px solid rgb(var(--color-line-350-rgb));
}
:root .line-350-tr,
:root .border-350-tr {
  border-top: 1px solid rgb(var(--color-line-350-rgb));
}
:root .line-350-trb,
:root .border-350-trb {
  border-top: 1px solid rgb(var(--color-line-350-rgb));
}
:root .line-350-trbl,
:root .border-350-trbl {
  border-top: 1px solid rgb(var(--color-line-350-rgb));
}
:root .line-350-trl,
:root .border-350-trl {
  border-top: 1px solid rgb(var(--color-line-350-rgb));
}
:root .line-350-tb,
:root .border-350-tb {
  border-top: 1px solid rgb(var(--color-line-350-rgb));
}
:root .line-350-tbl,
:root .border-350-tbl {
  border-top: 1px solid rgb(var(--color-line-350-rgb));
}
:root .line-350-tl,
:root .border-350-tl {
  border-top: 1px solid rgb(var(--color-line-350-rgb));
}
:root .line-350-tr,
:root .border-350-tr {
  border-right: 1px solid rgb(var(--color-line-350-rgb));
}
:root .line-350-trb,
:root .border-350-trb {
  border-right: 1px solid rgb(var(--color-line-350-rgb));
}
:root .line-350-trbl,
:root .border-350-trbl {
  border-right: 1px solid rgb(var(--color-line-350-rgb));
}
:root .line-350-trl,
:root .border-350-trl {
  border-right: 1px solid rgb(var(--color-line-350-rgb));
}
:root .line-350-r,
:root .border-350-r {
  border-right: 1px solid rgb(var(--color-line-350-rgb));
}
:root .line-350-rb,
:root .border-350-rb {
  border-right: 1px solid rgb(var(--color-line-350-rgb));
}
:root .line-350-rbl,
:root .border-350-rbl {
  border-right: 1px solid rgb(var(--color-line-350-rgb));
}
:root .line-350-rl,
:root .border-350-rl {
  border-right: 1px solid rgb(var(--color-line-350-rgb));
}
:root .line-350-trb,
:root .border-350-trb {
  border-bottom: 1px solid rgb(var(--color-line-350-rgb));
}
:root .line-350-trbl,
:root .border-350-trbl {
  border-bottom: 1px solid rgb(var(--color-line-350-rgb));
}
:root .line-350-tb,
:root .border-350-tb {
  border-bottom: 1px solid rgb(var(--color-line-350-rgb));
}
:root .line-350-tbl,
:root .border-350-tbl {
  border-bottom: 1px solid rgb(var(--color-line-350-rgb));
}
:root .line-350-rb,
:root .border-350-rb {
  border-bottom: 1px solid rgb(var(--color-line-350-rgb));
}
:root .line-350-rbl,
:root .border-350-rbl {
  border-bottom: 1px solid rgb(var(--color-line-350-rgb));
}
:root .line-350-b,
:root .border-350-b {
  border-bottom: 1px solid rgb(var(--color-line-350-rgb));
}
:root .line-350-bl,
:root .border-350-bl {
  border-bottom: 1px solid rgb(var(--color-line-350-rgb));
}
:root .line-350-trbl,
:root .border-350-trbl {
  border-left: 1px solid rgb(var(--color-line-350-rgb));
}
:root .line-350-trl,
:root .border-350-trl {
  border-left: 1px solid rgb(var(--color-line-350-rgb));
}
:root .line-350-tbl,
:root .border-350-tbl {
  border-left: 1px solid rgb(var(--color-line-350-rgb));
}
:root .line-350-tl,
:root .border-350-tl {
  border-left: 1px solid rgb(var(--color-line-350-rgb));
}
:root .line-350-rbl,
:root .border-350-rbl {
  border-left: 1px solid rgb(var(--color-line-350-rgb));
}
:root .line-350-rl,
:root .border-350-rl {
  border-left: 1px solid rgb(var(--color-line-350-rgb));
}
:root .line-350-bl,
:root .border-350-bl {
  border-left: 1px solid rgb(var(--color-line-350-rgb));
}
:root .line-350-l,
:root .border-350-l {
  border-left: 1px solid rgb(var(--color-line-350-rgb));
}
:root .line-350-as-fc,
:root .border-350-as-fc {
  color: rgb(var(--color-line-350-rgb));
}
:root .line-350-as-bg,
:root .border-350-as-bg {
  background-color: rgb(var(--color-line-350-rgb));
}

@media (hover: hover) {
  :root .line-350-on-hover:hover,
  :root .border-350-on-hover:hover {
    border-color: rgb(var(--color-line-350-rgb));
  }
  :root .hover-parent:hover .line-350-on-hover-parent,
  :root .hover-parent:hover .border-350-on-hover-parent {
    border-color: rgb(var(--color-line-350-rgb));
  }
}
:root .line-primary, :root .line-theme-primary,
:root .border-primary,
:root .border-theme-primary {
  border: 1px solid rgb(var(--color-primary-rgb));
}
:root .line-primary-t, :root .line-theme-primary-t,
:root .border-primary-t,
:root .border-theme-primary-t {
  border-top: 1px solid rgb(var(--color-primary-rgb));
}
:root .line-primary-tr, :root .line-theme-primary-tr,
:root .border-primary-tr,
:root .border-theme-primary-tr {
  border-top: 1px solid rgb(var(--color-primary-rgb));
}
:root .line-primary-trb, :root .line-theme-primary-trb,
:root .border-primary-trb,
:root .border-theme-primary-trb {
  border-top: 1px solid rgb(var(--color-primary-rgb));
}
:root .line-primary-trbl, :root .line-theme-primary-trbl,
:root .border-primary-trbl,
:root .border-theme-primary-trbl {
  border-top: 1px solid rgb(var(--color-primary-rgb));
}
:root .line-primary-trl, :root .line-theme-primary-trl,
:root .border-primary-trl,
:root .border-theme-primary-trl {
  border-top: 1px solid rgb(var(--color-primary-rgb));
}
:root .line-primary-tb, :root .line-theme-primary-tb,
:root .border-primary-tb,
:root .border-theme-primary-tb {
  border-top: 1px solid rgb(var(--color-primary-rgb));
}
:root .line-primary-tbl, :root .line-theme-primary-tbl,
:root .border-primary-tbl,
:root .border-theme-primary-tbl {
  border-top: 1px solid rgb(var(--color-primary-rgb));
}
:root .line-primary-tl, :root .line-theme-primary-tl,
:root .border-primary-tl,
:root .border-theme-primary-tl {
  border-top: 1px solid rgb(var(--color-primary-rgb));
}
:root .line-primary-tr, :root .line-theme-primary-tr,
:root .border-primary-tr,
:root .border-theme-primary-tr {
  border-right: 1px solid rgb(var(--color-primary-rgb));
}
:root .line-primary-trb, :root .line-theme-primary-trb,
:root .border-primary-trb,
:root .border-theme-primary-trb {
  border-right: 1px solid rgb(var(--color-primary-rgb));
}
:root .line-primary-trbl, :root .line-theme-primary-trbl,
:root .border-primary-trbl,
:root .border-theme-primary-trbl {
  border-right: 1px solid rgb(var(--color-primary-rgb));
}
:root .line-primary-trl, :root .line-theme-primary-trl,
:root .border-primary-trl,
:root .border-theme-primary-trl {
  border-right: 1px solid rgb(var(--color-primary-rgb));
}
:root .line-primary-r, :root .line-theme-primary-r,
:root .border-primary-r,
:root .border-theme-primary-r {
  border-right: 1px solid rgb(var(--color-primary-rgb));
}
:root .line-primary-rb, :root .line-theme-primary-rb,
:root .border-primary-rb,
:root .border-theme-primary-rb {
  border-right: 1px solid rgb(var(--color-primary-rgb));
}
:root .line-primary-rbl, :root .line-theme-primary-rbl,
:root .border-primary-rbl,
:root .border-theme-primary-rbl {
  border-right: 1px solid rgb(var(--color-primary-rgb));
}
:root .line-primary-rl, :root .line-theme-primary-rl,
:root .border-primary-rl,
:root .border-theme-primary-rl {
  border-right: 1px solid rgb(var(--color-primary-rgb));
}
:root .line-primary-trb, :root .line-theme-primary-trb,
:root .border-primary-trb,
:root .border-theme-primary-trb {
  border-bottom: 1px solid rgb(var(--color-primary-rgb));
}
:root .line-primary-trbl, :root .line-theme-primary-trbl,
:root .border-primary-trbl,
:root .border-theme-primary-trbl {
  border-bottom: 1px solid rgb(var(--color-primary-rgb));
}
:root .line-primary-tb, :root .line-theme-primary-tb,
:root .border-primary-tb,
:root .border-theme-primary-tb {
  border-bottom: 1px solid rgb(var(--color-primary-rgb));
}
:root .line-primary-tbl, :root .line-theme-primary-tbl,
:root .border-primary-tbl,
:root .border-theme-primary-tbl {
  border-bottom: 1px solid rgb(var(--color-primary-rgb));
}
:root .line-primary-rb, :root .line-theme-primary-rb,
:root .border-primary-rb,
:root .border-theme-primary-rb {
  border-bottom: 1px solid rgb(var(--color-primary-rgb));
}
:root .line-primary-rbl, :root .line-theme-primary-rbl,
:root .border-primary-rbl,
:root .border-theme-primary-rbl {
  border-bottom: 1px solid rgb(var(--color-primary-rgb));
}
:root .line-primary-b, :root .line-theme-primary-b,
:root .border-primary-b,
:root .border-theme-primary-b {
  border-bottom: 1px solid rgb(var(--color-primary-rgb));
}
:root .line-primary-bl, :root .line-theme-primary-bl,
:root .border-primary-bl,
:root .border-theme-primary-bl {
  border-bottom: 1px solid rgb(var(--color-primary-rgb));
}
:root .line-primary-trbl, :root .line-theme-primary-trbl,
:root .border-primary-trbl,
:root .border-theme-primary-trbl {
  border-left: 1px solid rgb(var(--color-primary-rgb));
}
:root .line-primary-trl, :root .line-theme-primary-trl,
:root .border-primary-trl,
:root .border-theme-primary-trl {
  border-left: 1px solid rgb(var(--color-primary-rgb));
}
:root .line-primary-tbl, :root .line-theme-primary-tbl,
:root .border-primary-tbl,
:root .border-theme-primary-tbl {
  border-left: 1px solid rgb(var(--color-primary-rgb));
}
:root .line-primary-tl, :root .line-theme-primary-tl,
:root .border-primary-tl,
:root .border-theme-primary-tl {
  border-left: 1px solid rgb(var(--color-primary-rgb));
}
:root .line-primary-rbl, :root .line-theme-primary-rbl,
:root .border-primary-rbl,
:root .border-theme-primary-rbl {
  border-left: 1px solid rgb(var(--color-primary-rgb));
}
:root .line-primary-rl, :root .line-theme-primary-rl,
:root .border-primary-rl,
:root .border-theme-primary-rl {
  border-left: 1px solid rgb(var(--color-primary-rgb));
}
:root .line-primary-bl, :root .line-theme-primary-bl,
:root .border-primary-bl,
:root .border-theme-primary-bl {
  border-left: 1px solid rgb(var(--color-primary-rgb));
}
:root .line-primary-l, :root .line-theme-primary-l,
:root .border-primary-l,
:root .border-theme-primary-l {
  border-left: 1px solid rgb(var(--color-primary-rgb));
}

@media (hover: hover) {
  :root .line-primary-on-hover:hover, :root .line-theme-primary-on-hover:hover,
  :root .border-primary-on-hover:hover,
  :root .border-theme-primary-on-hover:hover {
    border-color: rgb(var(--color-primary-rgb));
  }
  :root .hover-parent:hover .line-primary-on-hover-parent, :root .hover-parent:hover .line-theme-primary-on-hover-parent,
  :root .hover-parent:hover .border-primary-on-hover-parent,
  :root .hover-parent:hover .border-theme-primary-on-hover-parent {
    border-color: rgb(var(--color-primary-rgb));
  }
}
:root .line-primary-dark, :root .line-theme-primary-dark,
:root .border-primary-dark,
:root .border-theme-primary-dark {
  border: 1px solid rgb(var(--color-primary-dark-rgb));
}
:root .line-primary-dark-t, :root .line-theme-primary-dark-t,
:root .border-primary-dark-t,
:root .border-theme-primary-dark-t {
  border-top: 1px solid rgb(var(--color-primary-dark-rgb));
}
:root .line-primary-dark-tr, :root .line-theme-primary-dark-tr,
:root .border-primary-dark-tr,
:root .border-theme-primary-dark-tr {
  border-top: 1px solid rgb(var(--color-primary-dark-rgb));
}
:root .line-primary-dark-trb, :root .line-theme-primary-dark-trb,
:root .border-primary-dark-trb,
:root .border-theme-primary-dark-trb {
  border-top: 1px solid rgb(var(--color-primary-dark-rgb));
}
:root .line-primary-dark-trbl, :root .line-theme-primary-dark-trbl,
:root .border-primary-dark-trbl,
:root .border-theme-primary-dark-trbl {
  border-top: 1px solid rgb(var(--color-primary-dark-rgb));
}
:root .line-primary-dark-trl, :root .line-theme-primary-dark-trl,
:root .border-primary-dark-trl,
:root .border-theme-primary-dark-trl {
  border-top: 1px solid rgb(var(--color-primary-dark-rgb));
}
:root .line-primary-dark-tb, :root .line-theme-primary-dark-tb,
:root .border-primary-dark-tb,
:root .border-theme-primary-dark-tb {
  border-top: 1px solid rgb(var(--color-primary-dark-rgb));
}
:root .line-primary-dark-tbl, :root .line-theme-primary-dark-tbl,
:root .border-primary-dark-tbl,
:root .border-theme-primary-dark-tbl {
  border-top: 1px solid rgb(var(--color-primary-dark-rgb));
}
:root .line-primary-dark-tl, :root .line-theme-primary-dark-tl,
:root .border-primary-dark-tl,
:root .border-theme-primary-dark-tl {
  border-top: 1px solid rgb(var(--color-primary-dark-rgb));
}
:root .line-primary-dark-tr, :root .line-theme-primary-dark-tr,
:root .border-primary-dark-tr,
:root .border-theme-primary-dark-tr {
  border-right: 1px solid rgb(var(--color-primary-dark-rgb));
}
:root .line-primary-dark-trb, :root .line-theme-primary-dark-trb,
:root .border-primary-dark-trb,
:root .border-theme-primary-dark-trb {
  border-right: 1px solid rgb(var(--color-primary-dark-rgb));
}
:root .line-primary-dark-trbl, :root .line-theme-primary-dark-trbl,
:root .border-primary-dark-trbl,
:root .border-theme-primary-dark-trbl {
  border-right: 1px solid rgb(var(--color-primary-dark-rgb));
}
:root .line-primary-dark-trl, :root .line-theme-primary-dark-trl,
:root .border-primary-dark-trl,
:root .border-theme-primary-dark-trl {
  border-right: 1px solid rgb(var(--color-primary-dark-rgb));
}
:root .line-primary-dark-r, :root .line-theme-primary-dark-r,
:root .border-primary-dark-r,
:root .border-theme-primary-dark-r {
  border-right: 1px solid rgb(var(--color-primary-dark-rgb));
}
:root .line-primary-dark-rb, :root .line-theme-primary-dark-rb,
:root .border-primary-dark-rb,
:root .border-theme-primary-dark-rb {
  border-right: 1px solid rgb(var(--color-primary-dark-rgb));
}
:root .line-primary-dark-rbl, :root .line-theme-primary-dark-rbl,
:root .border-primary-dark-rbl,
:root .border-theme-primary-dark-rbl {
  border-right: 1px solid rgb(var(--color-primary-dark-rgb));
}
:root .line-primary-dark-rl, :root .line-theme-primary-dark-rl,
:root .border-primary-dark-rl,
:root .border-theme-primary-dark-rl {
  border-right: 1px solid rgb(var(--color-primary-dark-rgb));
}
:root .line-primary-dark-trb, :root .line-theme-primary-dark-trb,
:root .border-primary-dark-trb,
:root .border-theme-primary-dark-trb {
  border-bottom: 1px solid rgb(var(--color-primary-dark-rgb));
}
:root .line-primary-dark-trbl, :root .line-theme-primary-dark-trbl,
:root .border-primary-dark-trbl,
:root .border-theme-primary-dark-trbl {
  border-bottom: 1px solid rgb(var(--color-primary-dark-rgb));
}
:root .line-primary-dark-tb, :root .line-theme-primary-dark-tb,
:root .border-primary-dark-tb,
:root .border-theme-primary-dark-tb {
  border-bottom: 1px solid rgb(var(--color-primary-dark-rgb));
}
:root .line-primary-dark-tbl, :root .line-theme-primary-dark-tbl,
:root .border-primary-dark-tbl,
:root .border-theme-primary-dark-tbl {
  border-bottom: 1px solid rgb(var(--color-primary-dark-rgb));
}
:root .line-primary-dark-rb, :root .line-theme-primary-dark-rb,
:root .border-primary-dark-rb,
:root .border-theme-primary-dark-rb {
  border-bottom: 1px solid rgb(var(--color-primary-dark-rgb));
}
:root .line-primary-dark-rbl, :root .line-theme-primary-dark-rbl,
:root .border-primary-dark-rbl,
:root .border-theme-primary-dark-rbl {
  border-bottom: 1px solid rgb(var(--color-primary-dark-rgb));
}
:root .line-primary-dark-b, :root .line-theme-primary-dark-b,
:root .border-primary-dark-b,
:root .border-theme-primary-dark-b {
  border-bottom: 1px solid rgb(var(--color-primary-dark-rgb));
}
:root .line-primary-dark-bl, :root .line-theme-primary-dark-bl,
:root .border-primary-dark-bl,
:root .border-theme-primary-dark-bl {
  border-bottom: 1px solid rgb(var(--color-primary-dark-rgb));
}
:root .line-primary-dark-trbl, :root .line-theme-primary-dark-trbl,
:root .border-primary-dark-trbl,
:root .border-theme-primary-dark-trbl {
  border-left: 1px solid rgb(var(--color-primary-dark-rgb));
}
:root .line-primary-dark-trl, :root .line-theme-primary-dark-trl,
:root .border-primary-dark-trl,
:root .border-theme-primary-dark-trl {
  border-left: 1px solid rgb(var(--color-primary-dark-rgb));
}
:root .line-primary-dark-tbl, :root .line-theme-primary-dark-tbl,
:root .border-primary-dark-tbl,
:root .border-theme-primary-dark-tbl {
  border-left: 1px solid rgb(var(--color-primary-dark-rgb));
}
:root .line-primary-dark-tl, :root .line-theme-primary-dark-tl,
:root .border-primary-dark-tl,
:root .border-theme-primary-dark-tl {
  border-left: 1px solid rgb(var(--color-primary-dark-rgb));
}
:root .line-primary-dark-rbl, :root .line-theme-primary-dark-rbl,
:root .border-primary-dark-rbl,
:root .border-theme-primary-dark-rbl {
  border-left: 1px solid rgb(var(--color-primary-dark-rgb));
}
:root .line-primary-dark-rl, :root .line-theme-primary-dark-rl,
:root .border-primary-dark-rl,
:root .border-theme-primary-dark-rl {
  border-left: 1px solid rgb(var(--color-primary-dark-rgb));
}
:root .line-primary-dark-bl, :root .line-theme-primary-dark-bl,
:root .border-primary-dark-bl,
:root .border-theme-primary-dark-bl {
  border-left: 1px solid rgb(var(--color-primary-dark-rgb));
}
:root .line-primary-dark-l, :root .line-theme-primary-dark-l,
:root .border-primary-dark-l,
:root .border-theme-primary-dark-l {
  border-left: 1px solid rgb(var(--color-primary-dark-rgb));
}

@media (hover: hover) {
  :root .line-primary-dark-on-hover:hover, :root .line-theme-primary-dark-on-hover:hover,
  :root .border-primary-dark-on-hover:hover,
  :root .border-theme-primary-dark-on-hover:hover {
    border-color: rgb(var(--color-primary-dark-rgb));
  }
  :root .hover-parent:hover .line-primary-dark-on-hover-parent, :root .hover-parent:hover .line-theme-primary-dark-on-hover-parent,
  :root .hover-parent:hover .border-primary-dark-on-hover-parent,
  :root .hover-parent:hover .border-theme-primary-dark-on-hover-parent {
    border-color: rgb(var(--color-primary-dark-rgb));
  }
}
:root .line-primary-500, :root .line-theme-primary-500,
:root .border-primary-500,
:root .border-theme-primary-500 {
  border: 1px solid rgb(var(--color-primary-500-rgb));
}
:root .line-primary-500-t, :root .line-theme-primary-500-t,
:root .border-primary-500-t,
:root .border-theme-primary-500-t {
  border-top: 1px solid rgb(var(--color-primary-500-rgb));
}
:root .line-primary-500-tr, :root .line-theme-primary-500-tr,
:root .border-primary-500-tr,
:root .border-theme-primary-500-tr {
  border-top: 1px solid rgb(var(--color-primary-500-rgb));
}
:root .line-primary-500-trb, :root .line-theme-primary-500-trb,
:root .border-primary-500-trb,
:root .border-theme-primary-500-trb {
  border-top: 1px solid rgb(var(--color-primary-500-rgb));
}
:root .line-primary-500-trbl, :root .line-theme-primary-500-trbl,
:root .border-primary-500-trbl,
:root .border-theme-primary-500-trbl {
  border-top: 1px solid rgb(var(--color-primary-500-rgb));
}
:root .line-primary-500-trl, :root .line-theme-primary-500-trl,
:root .border-primary-500-trl,
:root .border-theme-primary-500-trl {
  border-top: 1px solid rgb(var(--color-primary-500-rgb));
}
:root .line-primary-500-tb, :root .line-theme-primary-500-tb,
:root .border-primary-500-tb,
:root .border-theme-primary-500-tb {
  border-top: 1px solid rgb(var(--color-primary-500-rgb));
}
:root .line-primary-500-tbl, :root .line-theme-primary-500-tbl,
:root .border-primary-500-tbl,
:root .border-theme-primary-500-tbl {
  border-top: 1px solid rgb(var(--color-primary-500-rgb));
}
:root .line-primary-500-tl, :root .line-theme-primary-500-tl,
:root .border-primary-500-tl,
:root .border-theme-primary-500-tl {
  border-top: 1px solid rgb(var(--color-primary-500-rgb));
}
:root .line-primary-500-tr, :root .line-theme-primary-500-tr,
:root .border-primary-500-tr,
:root .border-theme-primary-500-tr {
  border-right: 1px solid rgb(var(--color-primary-500-rgb));
}
:root .line-primary-500-trb, :root .line-theme-primary-500-trb,
:root .border-primary-500-trb,
:root .border-theme-primary-500-trb {
  border-right: 1px solid rgb(var(--color-primary-500-rgb));
}
:root .line-primary-500-trbl, :root .line-theme-primary-500-trbl,
:root .border-primary-500-trbl,
:root .border-theme-primary-500-trbl {
  border-right: 1px solid rgb(var(--color-primary-500-rgb));
}
:root .line-primary-500-trl, :root .line-theme-primary-500-trl,
:root .border-primary-500-trl,
:root .border-theme-primary-500-trl {
  border-right: 1px solid rgb(var(--color-primary-500-rgb));
}
:root .line-primary-500-r, :root .line-theme-primary-500-r,
:root .border-primary-500-r,
:root .border-theme-primary-500-r {
  border-right: 1px solid rgb(var(--color-primary-500-rgb));
}
:root .line-primary-500-rb, :root .line-theme-primary-500-rb,
:root .border-primary-500-rb,
:root .border-theme-primary-500-rb {
  border-right: 1px solid rgb(var(--color-primary-500-rgb));
}
:root .line-primary-500-rbl, :root .line-theme-primary-500-rbl,
:root .border-primary-500-rbl,
:root .border-theme-primary-500-rbl {
  border-right: 1px solid rgb(var(--color-primary-500-rgb));
}
:root .line-primary-500-rl, :root .line-theme-primary-500-rl,
:root .border-primary-500-rl,
:root .border-theme-primary-500-rl {
  border-right: 1px solid rgb(var(--color-primary-500-rgb));
}
:root .line-primary-500-trb, :root .line-theme-primary-500-trb,
:root .border-primary-500-trb,
:root .border-theme-primary-500-trb {
  border-bottom: 1px solid rgb(var(--color-primary-500-rgb));
}
:root .line-primary-500-trbl, :root .line-theme-primary-500-trbl,
:root .border-primary-500-trbl,
:root .border-theme-primary-500-trbl {
  border-bottom: 1px solid rgb(var(--color-primary-500-rgb));
}
:root .line-primary-500-tb, :root .line-theme-primary-500-tb,
:root .border-primary-500-tb,
:root .border-theme-primary-500-tb {
  border-bottom: 1px solid rgb(var(--color-primary-500-rgb));
}
:root .line-primary-500-tbl, :root .line-theme-primary-500-tbl,
:root .border-primary-500-tbl,
:root .border-theme-primary-500-tbl {
  border-bottom: 1px solid rgb(var(--color-primary-500-rgb));
}
:root .line-primary-500-rb, :root .line-theme-primary-500-rb,
:root .border-primary-500-rb,
:root .border-theme-primary-500-rb {
  border-bottom: 1px solid rgb(var(--color-primary-500-rgb));
}
:root .line-primary-500-rbl, :root .line-theme-primary-500-rbl,
:root .border-primary-500-rbl,
:root .border-theme-primary-500-rbl {
  border-bottom: 1px solid rgb(var(--color-primary-500-rgb));
}
:root .line-primary-500-b, :root .line-theme-primary-500-b,
:root .border-primary-500-b,
:root .border-theme-primary-500-b {
  border-bottom: 1px solid rgb(var(--color-primary-500-rgb));
}
:root .line-primary-500-bl, :root .line-theme-primary-500-bl,
:root .border-primary-500-bl,
:root .border-theme-primary-500-bl {
  border-bottom: 1px solid rgb(var(--color-primary-500-rgb));
}
:root .line-primary-500-trbl, :root .line-theme-primary-500-trbl,
:root .border-primary-500-trbl,
:root .border-theme-primary-500-trbl {
  border-left: 1px solid rgb(var(--color-primary-500-rgb));
}
:root .line-primary-500-trl, :root .line-theme-primary-500-trl,
:root .border-primary-500-trl,
:root .border-theme-primary-500-trl {
  border-left: 1px solid rgb(var(--color-primary-500-rgb));
}
:root .line-primary-500-tbl, :root .line-theme-primary-500-tbl,
:root .border-primary-500-tbl,
:root .border-theme-primary-500-tbl {
  border-left: 1px solid rgb(var(--color-primary-500-rgb));
}
:root .line-primary-500-tl, :root .line-theme-primary-500-tl,
:root .border-primary-500-tl,
:root .border-theme-primary-500-tl {
  border-left: 1px solid rgb(var(--color-primary-500-rgb));
}
:root .line-primary-500-rbl, :root .line-theme-primary-500-rbl,
:root .border-primary-500-rbl,
:root .border-theme-primary-500-rbl {
  border-left: 1px solid rgb(var(--color-primary-500-rgb));
}
:root .line-primary-500-rl, :root .line-theme-primary-500-rl,
:root .border-primary-500-rl,
:root .border-theme-primary-500-rl {
  border-left: 1px solid rgb(var(--color-primary-500-rgb));
}
:root .line-primary-500-bl, :root .line-theme-primary-500-bl,
:root .border-primary-500-bl,
:root .border-theme-primary-500-bl {
  border-left: 1px solid rgb(var(--color-primary-500-rgb));
}
:root .line-primary-500-l, :root .line-theme-primary-500-l,
:root .border-primary-500-l,
:root .border-theme-primary-500-l {
  border-left: 1px solid rgb(var(--color-primary-500-rgb));
}

@media (hover: hover) {
  :root .line-primary-500-on-hover:hover, :root .line-theme-primary-500-on-hover:hover,
  :root .border-primary-500-on-hover:hover,
  :root .border-theme-primary-500-on-hover:hover {
    border-color: rgb(var(--color-primary-500-rgb));
  }
  :root .hover-parent:hover .line-primary-500-on-hover-parent, :root .hover-parent:hover .line-theme-primary-500-on-hover-parent,
  :root .hover-parent:hover .border-primary-500-on-hover-parent,
  :root .hover-parent:hover .border-theme-primary-500-on-hover-parent {
    border-color: rgb(var(--color-primary-500-rgb));
  }
}
:root .line-primary-700, :root .line-theme-primary-700,
:root .border-primary-700,
:root .border-theme-primary-700 {
  border: 1px solid rgb(var(--color-primary-700-rgb));
}
:root .line-primary-700-t, :root .line-theme-primary-700-t,
:root .border-primary-700-t,
:root .border-theme-primary-700-t {
  border-top: 1px solid rgb(var(--color-primary-700-rgb));
}
:root .line-primary-700-tr, :root .line-theme-primary-700-tr,
:root .border-primary-700-tr,
:root .border-theme-primary-700-tr {
  border-top: 1px solid rgb(var(--color-primary-700-rgb));
}
:root .line-primary-700-trb, :root .line-theme-primary-700-trb,
:root .border-primary-700-trb,
:root .border-theme-primary-700-trb {
  border-top: 1px solid rgb(var(--color-primary-700-rgb));
}
:root .line-primary-700-trbl, :root .line-theme-primary-700-trbl,
:root .border-primary-700-trbl,
:root .border-theme-primary-700-trbl {
  border-top: 1px solid rgb(var(--color-primary-700-rgb));
}
:root .line-primary-700-trl, :root .line-theme-primary-700-trl,
:root .border-primary-700-trl,
:root .border-theme-primary-700-trl {
  border-top: 1px solid rgb(var(--color-primary-700-rgb));
}
:root .line-primary-700-tb, :root .line-theme-primary-700-tb,
:root .border-primary-700-tb,
:root .border-theme-primary-700-tb {
  border-top: 1px solid rgb(var(--color-primary-700-rgb));
}
:root .line-primary-700-tbl, :root .line-theme-primary-700-tbl,
:root .border-primary-700-tbl,
:root .border-theme-primary-700-tbl {
  border-top: 1px solid rgb(var(--color-primary-700-rgb));
}
:root .line-primary-700-tl, :root .line-theme-primary-700-tl,
:root .border-primary-700-tl,
:root .border-theme-primary-700-tl {
  border-top: 1px solid rgb(var(--color-primary-700-rgb));
}
:root .line-primary-700-tr, :root .line-theme-primary-700-tr,
:root .border-primary-700-tr,
:root .border-theme-primary-700-tr {
  border-right: 1px solid rgb(var(--color-primary-700-rgb));
}
:root .line-primary-700-trb, :root .line-theme-primary-700-trb,
:root .border-primary-700-trb,
:root .border-theme-primary-700-trb {
  border-right: 1px solid rgb(var(--color-primary-700-rgb));
}
:root .line-primary-700-trbl, :root .line-theme-primary-700-trbl,
:root .border-primary-700-trbl,
:root .border-theme-primary-700-trbl {
  border-right: 1px solid rgb(var(--color-primary-700-rgb));
}
:root .line-primary-700-trl, :root .line-theme-primary-700-trl,
:root .border-primary-700-trl,
:root .border-theme-primary-700-trl {
  border-right: 1px solid rgb(var(--color-primary-700-rgb));
}
:root .line-primary-700-r, :root .line-theme-primary-700-r,
:root .border-primary-700-r,
:root .border-theme-primary-700-r {
  border-right: 1px solid rgb(var(--color-primary-700-rgb));
}
:root .line-primary-700-rb, :root .line-theme-primary-700-rb,
:root .border-primary-700-rb,
:root .border-theme-primary-700-rb {
  border-right: 1px solid rgb(var(--color-primary-700-rgb));
}
:root .line-primary-700-rbl, :root .line-theme-primary-700-rbl,
:root .border-primary-700-rbl,
:root .border-theme-primary-700-rbl {
  border-right: 1px solid rgb(var(--color-primary-700-rgb));
}
:root .line-primary-700-rl, :root .line-theme-primary-700-rl,
:root .border-primary-700-rl,
:root .border-theme-primary-700-rl {
  border-right: 1px solid rgb(var(--color-primary-700-rgb));
}
:root .line-primary-700-trb, :root .line-theme-primary-700-trb,
:root .border-primary-700-trb,
:root .border-theme-primary-700-trb {
  border-bottom: 1px solid rgb(var(--color-primary-700-rgb));
}
:root .line-primary-700-trbl, :root .line-theme-primary-700-trbl,
:root .border-primary-700-trbl,
:root .border-theme-primary-700-trbl {
  border-bottom: 1px solid rgb(var(--color-primary-700-rgb));
}
:root .line-primary-700-tb, :root .line-theme-primary-700-tb,
:root .border-primary-700-tb,
:root .border-theme-primary-700-tb {
  border-bottom: 1px solid rgb(var(--color-primary-700-rgb));
}
:root .line-primary-700-tbl, :root .line-theme-primary-700-tbl,
:root .border-primary-700-tbl,
:root .border-theme-primary-700-tbl {
  border-bottom: 1px solid rgb(var(--color-primary-700-rgb));
}
:root .line-primary-700-rb, :root .line-theme-primary-700-rb,
:root .border-primary-700-rb,
:root .border-theme-primary-700-rb {
  border-bottom: 1px solid rgb(var(--color-primary-700-rgb));
}
:root .line-primary-700-rbl, :root .line-theme-primary-700-rbl,
:root .border-primary-700-rbl,
:root .border-theme-primary-700-rbl {
  border-bottom: 1px solid rgb(var(--color-primary-700-rgb));
}
:root .line-primary-700-b, :root .line-theme-primary-700-b,
:root .border-primary-700-b,
:root .border-theme-primary-700-b {
  border-bottom: 1px solid rgb(var(--color-primary-700-rgb));
}
:root .line-primary-700-bl, :root .line-theme-primary-700-bl,
:root .border-primary-700-bl,
:root .border-theme-primary-700-bl {
  border-bottom: 1px solid rgb(var(--color-primary-700-rgb));
}
:root .line-primary-700-trbl, :root .line-theme-primary-700-trbl,
:root .border-primary-700-trbl,
:root .border-theme-primary-700-trbl {
  border-left: 1px solid rgb(var(--color-primary-700-rgb));
}
:root .line-primary-700-trl, :root .line-theme-primary-700-trl,
:root .border-primary-700-trl,
:root .border-theme-primary-700-trl {
  border-left: 1px solid rgb(var(--color-primary-700-rgb));
}
:root .line-primary-700-tbl, :root .line-theme-primary-700-tbl,
:root .border-primary-700-tbl,
:root .border-theme-primary-700-tbl {
  border-left: 1px solid rgb(var(--color-primary-700-rgb));
}
:root .line-primary-700-tl, :root .line-theme-primary-700-tl,
:root .border-primary-700-tl,
:root .border-theme-primary-700-tl {
  border-left: 1px solid rgb(var(--color-primary-700-rgb));
}
:root .line-primary-700-rbl, :root .line-theme-primary-700-rbl,
:root .border-primary-700-rbl,
:root .border-theme-primary-700-rbl {
  border-left: 1px solid rgb(var(--color-primary-700-rgb));
}
:root .line-primary-700-rl, :root .line-theme-primary-700-rl,
:root .border-primary-700-rl,
:root .border-theme-primary-700-rl {
  border-left: 1px solid rgb(var(--color-primary-700-rgb));
}
:root .line-primary-700-bl, :root .line-theme-primary-700-bl,
:root .border-primary-700-bl,
:root .border-theme-primary-700-bl {
  border-left: 1px solid rgb(var(--color-primary-700-rgb));
}
:root .line-primary-700-l, :root .line-theme-primary-700-l,
:root .border-primary-700-l,
:root .border-theme-primary-700-l {
  border-left: 1px solid rgb(var(--color-primary-700-rgb));
}

@media (hover: hover) {
  :root .line-primary-700-on-hover:hover, :root .line-theme-primary-700-on-hover:hover,
  :root .border-primary-700-on-hover:hover,
  :root .border-theme-primary-700-on-hover:hover {
    border-color: rgb(var(--color-primary-700-rgb));
  }
  :root .hover-parent:hover .line-primary-700-on-hover-parent, :root .hover-parent:hover .line-theme-primary-700-on-hover-parent,
  :root .hover-parent:hover .border-primary-700-on-hover-parent,
  :root .hover-parent:hover .border-theme-primary-700-on-hover-parent {
    border-color: rgb(var(--color-primary-700-rgb));
  }
}
:root .line-warn, :root .line-theme-warn,
:root .border-warn,
:root .border-theme-warn {
  border: 1px solid rgb(var(--color-warn-rgb));
}
:root .line-warn-t, :root .line-theme-warn-t,
:root .border-warn-t,
:root .border-theme-warn-t {
  border-top: 1px solid rgb(var(--color-warn-rgb));
}
:root .line-warn-tr, :root .line-theme-warn-tr,
:root .border-warn-tr,
:root .border-theme-warn-tr {
  border-top: 1px solid rgb(var(--color-warn-rgb));
}
:root .line-warn-trb, :root .line-theme-warn-trb,
:root .border-warn-trb,
:root .border-theme-warn-trb {
  border-top: 1px solid rgb(var(--color-warn-rgb));
}
:root .line-warn-trbl, :root .line-theme-warn-trbl,
:root .border-warn-trbl,
:root .border-theme-warn-trbl {
  border-top: 1px solid rgb(var(--color-warn-rgb));
}
:root .line-warn-trl, :root .line-theme-warn-trl,
:root .border-warn-trl,
:root .border-theme-warn-trl {
  border-top: 1px solid rgb(var(--color-warn-rgb));
}
:root .line-warn-tb, :root .line-theme-warn-tb,
:root .border-warn-tb,
:root .border-theme-warn-tb {
  border-top: 1px solid rgb(var(--color-warn-rgb));
}
:root .line-warn-tbl, :root .line-theme-warn-tbl,
:root .border-warn-tbl,
:root .border-theme-warn-tbl {
  border-top: 1px solid rgb(var(--color-warn-rgb));
}
:root .line-warn-tl, :root .line-theme-warn-tl,
:root .border-warn-tl,
:root .border-theme-warn-tl {
  border-top: 1px solid rgb(var(--color-warn-rgb));
}
:root .line-warn-tr, :root .line-theme-warn-tr,
:root .border-warn-tr,
:root .border-theme-warn-tr {
  border-right: 1px solid rgb(var(--color-warn-rgb));
}
:root .line-warn-trb, :root .line-theme-warn-trb,
:root .border-warn-trb,
:root .border-theme-warn-trb {
  border-right: 1px solid rgb(var(--color-warn-rgb));
}
:root .line-warn-trbl, :root .line-theme-warn-trbl,
:root .border-warn-trbl,
:root .border-theme-warn-trbl {
  border-right: 1px solid rgb(var(--color-warn-rgb));
}
:root .line-warn-trl, :root .line-theme-warn-trl,
:root .border-warn-trl,
:root .border-theme-warn-trl {
  border-right: 1px solid rgb(var(--color-warn-rgb));
}
:root .line-warn-r, :root .line-theme-warn-r,
:root .border-warn-r,
:root .border-theme-warn-r {
  border-right: 1px solid rgb(var(--color-warn-rgb));
}
:root .line-warn-rb, :root .line-theme-warn-rb,
:root .border-warn-rb,
:root .border-theme-warn-rb {
  border-right: 1px solid rgb(var(--color-warn-rgb));
}
:root .line-warn-rbl, :root .line-theme-warn-rbl,
:root .border-warn-rbl,
:root .border-theme-warn-rbl {
  border-right: 1px solid rgb(var(--color-warn-rgb));
}
:root .line-warn-rl, :root .line-theme-warn-rl,
:root .border-warn-rl,
:root .border-theme-warn-rl {
  border-right: 1px solid rgb(var(--color-warn-rgb));
}
:root .line-warn-trb, :root .line-theme-warn-trb,
:root .border-warn-trb,
:root .border-theme-warn-trb {
  border-bottom: 1px solid rgb(var(--color-warn-rgb));
}
:root .line-warn-trbl, :root .line-theme-warn-trbl,
:root .border-warn-trbl,
:root .border-theme-warn-trbl {
  border-bottom: 1px solid rgb(var(--color-warn-rgb));
}
:root .line-warn-tb, :root .line-theme-warn-tb,
:root .border-warn-tb,
:root .border-theme-warn-tb {
  border-bottom: 1px solid rgb(var(--color-warn-rgb));
}
:root .line-warn-tbl, :root .line-theme-warn-tbl,
:root .border-warn-tbl,
:root .border-theme-warn-tbl {
  border-bottom: 1px solid rgb(var(--color-warn-rgb));
}
:root .line-warn-rb, :root .line-theme-warn-rb,
:root .border-warn-rb,
:root .border-theme-warn-rb {
  border-bottom: 1px solid rgb(var(--color-warn-rgb));
}
:root .line-warn-rbl, :root .line-theme-warn-rbl,
:root .border-warn-rbl,
:root .border-theme-warn-rbl {
  border-bottom: 1px solid rgb(var(--color-warn-rgb));
}
:root .line-warn-b, :root .line-theme-warn-b,
:root .border-warn-b,
:root .border-theme-warn-b {
  border-bottom: 1px solid rgb(var(--color-warn-rgb));
}
:root .line-warn-bl, :root .line-theme-warn-bl,
:root .border-warn-bl,
:root .border-theme-warn-bl {
  border-bottom: 1px solid rgb(var(--color-warn-rgb));
}
:root .line-warn-trbl, :root .line-theme-warn-trbl,
:root .border-warn-trbl,
:root .border-theme-warn-trbl {
  border-left: 1px solid rgb(var(--color-warn-rgb));
}
:root .line-warn-trl, :root .line-theme-warn-trl,
:root .border-warn-trl,
:root .border-theme-warn-trl {
  border-left: 1px solid rgb(var(--color-warn-rgb));
}
:root .line-warn-tbl, :root .line-theme-warn-tbl,
:root .border-warn-tbl,
:root .border-theme-warn-tbl {
  border-left: 1px solid rgb(var(--color-warn-rgb));
}
:root .line-warn-tl, :root .line-theme-warn-tl,
:root .border-warn-tl,
:root .border-theme-warn-tl {
  border-left: 1px solid rgb(var(--color-warn-rgb));
}
:root .line-warn-rbl, :root .line-theme-warn-rbl,
:root .border-warn-rbl,
:root .border-theme-warn-rbl {
  border-left: 1px solid rgb(var(--color-warn-rgb));
}
:root .line-warn-rl, :root .line-theme-warn-rl,
:root .border-warn-rl,
:root .border-theme-warn-rl {
  border-left: 1px solid rgb(var(--color-warn-rgb));
}
:root .line-warn-bl, :root .line-theme-warn-bl,
:root .border-warn-bl,
:root .border-theme-warn-bl {
  border-left: 1px solid rgb(var(--color-warn-rgb));
}
:root .line-warn-l, :root .line-theme-warn-l,
:root .border-warn-l,
:root .border-theme-warn-l {
  border-left: 1px solid rgb(var(--color-warn-rgb));
}

@media (hover: hover) {
  :root .line-warn-on-hover:hover, :root .line-theme-warn-on-hover:hover,
  :root .border-warn-on-hover:hover,
  :root .border-theme-warn-on-hover:hover {
    border-color: rgb(var(--color-warn-rgb));
  }
  :root .hover-parent:hover .line-warn-on-hover-parent, :root .hover-parent:hover .line-theme-warn-on-hover-parent,
  :root .hover-parent:hover .border-warn-on-hover-parent,
  :root .hover-parent:hover .border-theme-warn-on-hover-parent {
    border-color: rgb(var(--color-warn-rgb));
  }
}
:root .line-warn-dark, :root .line-theme-warn-dark,
:root .border-warn-dark,
:root .border-theme-warn-dark {
  border: 1px solid rgb(var(--color-warn-dark-rgb));
}
:root .line-warn-dark-t, :root .line-theme-warn-dark-t,
:root .border-warn-dark-t,
:root .border-theme-warn-dark-t {
  border-top: 1px solid rgb(var(--color-warn-dark-rgb));
}
:root .line-warn-dark-tr, :root .line-theme-warn-dark-tr,
:root .border-warn-dark-tr,
:root .border-theme-warn-dark-tr {
  border-top: 1px solid rgb(var(--color-warn-dark-rgb));
}
:root .line-warn-dark-trb, :root .line-theme-warn-dark-trb,
:root .border-warn-dark-trb,
:root .border-theme-warn-dark-trb {
  border-top: 1px solid rgb(var(--color-warn-dark-rgb));
}
:root .line-warn-dark-trbl, :root .line-theme-warn-dark-trbl,
:root .border-warn-dark-trbl,
:root .border-theme-warn-dark-trbl {
  border-top: 1px solid rgb(var(--color-warn-dark-rgb));
}
:root .line-warn-dark-trl, :root .line-theme-warn-dark-trl,
:root .border-warn-dark-trl,
:root .border-theme-warn-dark-trl {
  border-top: 1px solid rgb(var(--color-warn-dark-rgb));
}
:root .line-warn-dark-tb, :root .line-theme-warn-dark-tb,
:root .border-warn-dark-tb,
:root .border-theme-warn-dark-tb {
  border-top: 1px solid rgb(var(--color-warn-dark-rgb));
}
:root .line-warn-dark-tbl, :root .line-theme-warn-dark-tbl,
:root .border-warn-dark-tbl,
:root .border-theme-warn-dark-tbl {
  border-top: 1px solid rgb(var(--color-warn-dark-rgb));
}
:root .line-warn-dark-tl, :root .line-theme-warn-dark-tl,
:root .border-warn-dark-tl,
:root .border-theme-warn-dark-tl {
  border-top: 1px solid rgb(var(--color-warn-dark-rgb));
}
:root .line-warn-dark-tr, :root .line-theme-warn-dark-tr,
:root .border-warn-dark-tr,
:root .border-theme-warn-dark-tr {
  border-right: 1px solid rgb(var(--color-warn-dark-rgb));
}
:root .line-warn-dark-trb, :root .line-theme-warn-dark-trb,
:root .border-warn-dark-trb,
:root .border-theme-warn-dark-trb {
  border-right: 1px solid rgb(var(--color-warn-dark-rgb));
}
:root .line-warn-dark-trbl, :root .line-theme-warn-dark-trbl,
:root .border-warn-dark-trbl,
:root .border-theme-warn-dark-trbl {
  border-right: 1px solid rgb(var(--color-warn-dark-rgb));
}
:root .line-warn-dark-trl, :root .line-theme-warn-dark-trl,
:root .border-warn-dark-trl,
:root .border-theme-warn-dark-trl {
  border-right: 1px solid rgb(var(--color-warn-dark-rgb));
}
:root .line-warn-dark-r, :root .line-theme-warn-dark-r,
:root .border-warn-dark-r,
:root .border-theme-warn-dark-r {
  border-right: 1px solid rgb(var(--color-warn-dark-rgb));
}
:root .line-warn-dark-rb, :root .line-theme-warn-dark-rb,
:root .border-warn-dark-rb,
:root .border-theme-warn-dark-rb {
  border-right: 1px solid rgb(var(--color-warn-dark-rgb));
}
:root .line-warn-dark-rbl, :root .line-theme-warn-dark-rbl,
:root .border-warn-dark-rbl,
:root .border-theme-warn-dark-rbl {
  border-right: 1px solid rgb(var(--color-warn-dark-rgb));
}
:root .line-warn-dark-rl, :root .line-theme-warn-dark-rl,
:root .border-warn-dark-rl,
:root .border-theme-warn-dark-rl {
  border-right: 1px solid rgb(var(--color-warn-dark-rgb));
}
:root .line-warn-dark-trb, :root .line-theme-warn-dark-trb,
:root .border-warn-dark-trb,
:root .border-theme-warn-dark-trb {
  border-bottom: 1px solid rgb(var(--color-warn-dark-rgb));
}
:root .line-warn-dark-trbl, :root .line-theme-warn-dark-trbl,
:root .border-warn-dark-trbl,
:root .border-theme-warn-dark-trbl {
  border-bottom: 1px solid rgb(var(--color-warn-dark-rgb));
}
:root .line-warn-dark-tb, :root .line-theme-warn-dark-tb,
:root .border-warn-dark-tb,
:root .border-theme-warn-dark-tb {
  border-bottom: 1px solid rgb(var(--color-warn-dark-rgb));
}
:root .line-warn-dark-tbl, :root .line-theme-warn-dark-tbl,
:root .border-warn-dark-tbl,
:root .border-theme-warn-dark-tbl {
  border-bottom: 1px solid rgb(var(--color-warn-dark-rgb));
}
:root .line-warn-dark-rb, :root .line-theme-warn-dark-rb,
:root .border-warn-dark-rb,
:root .border-theme-warn-dark-rb {
  border-bottom: 1px solid rgb(var(--color-warn-dark-rgb));
}
:root .line-warn-dark-rbl, :root .line-theme-warn-dark-rbl,
:root .border-warn-dark-rbl,
:root .border-theme-warn-dark-rbl {
  border-bottom: 1px solid rgb(var(--color-warn-dark-rgb));
}
:root .line-warn-dark-b, :root .line-theme-warn-dark-b,
:root .border-warn-dark-b,
:root .border-theme-warn-dark-b {
  border-bottom: 1px solid rgb(var(--color-warn-dark-rgb));
}
:root .line-warn-dark-bl, :root .line-theme-warn-dark-bl,
:root .border-warn-dark-bl,
:root .border-theme-warn-dark-bl {
  border-bottom: 1px solid rgb(var(--color-warn-dark-rgb));
}
:root .line-warn-dark-trbl, :root .line-theme-warn-dark-trbl,
:root .border-warn-dark-trbl,
:root .border-theme-warn-dark-trbl {
  border-left: 1px solid rgb(var(--color-warn-dark-rgb));
}
:root .line-warn-dark-trl, :root .line-theme-warn-dark-trl,
:root .border-warn-dark-trl,
:root .border-theme-warn-dark-trl {
  border-left: 1px solid rgb(var(--color-warn-dark-rgb));
}
:root .line-warn-dark-tbl, :root .line-theme-warn-dark-tbl,
:root .border-warn-dark-tbl,
:root .border-theme-warn-dark-tbl {
  border-left: 1px solid rgb(var(--color-warn-dark-rgb));
}
:root .line-warn-dark-tl, :root .line-theme-warn-dark-tl,
:root .border-warn-dark-tl,
:root .border-theme-warn-dark-tl {
  border-left: 1px solid rgb(var(--color-warn-dark-rgb));
}
:root .line-warn-dark-rbl, :root .line-theme-warn-dark-rbl,
:root .border-warn-dark-rbl,
:root .border-theme-warn-dark-rbl {
  border-left: 1px solid rgb(var(--color-warn-dark-rgb));
}
:root .line-warn-dark-rl, :root .line-theme-warn-dark-rl,
:root .border-warn-dark-rl,
:root .border-theme-warn-dark-rl {
  border-left: 1px solid rgb(var(--color-warn-dark-rgb));
}
:root .line-warn-dark-bl, :root .line-theme-warn-dark-bl,
:root .border-warn-dark-bl,
:root .border-theme-warn-dark-bl {
  border-left: 1px solid rgb(var(--color-warn-dark-rgb));
}
:root .line-warn-dark-l, :root .line-theme-warn-dark-l,
:root .border-warn-dark-l,
:root .border-theme-warn-dark-l {
  border-left: 1px solid rgb(var(--color-warn-dark-rgb));
}

@media (hover: hover) {
  :root .line-warn-dark-on-hover:hover, :root .line-theme-warn-dark-on-hover:hover,
  :root .border-warn-dark-on-hover:hover,
  :root .border-theme-warn-dark-on-hover:hover {
    border-color: rgb(var(--color-warn-dark-rgb));
  }
  :root .hover-parent:hover .line-warn-dark-on-hover-parent, :root .hover-parent:hover .line-theme-warn-dark-on-hover-parent,
  :root .hover-parent:hover .border-warn-dark-on-hover-parent,
  :root .hover-parent:hover .border-theme-warn-dark-on-hover-parent {
    border-color: rgb(var(--color-warn-dark-rgb));
  }
}
:root .line-warn-500, :root .line-theme-warn-500,
:root .border-warn-500,
:root .border-theme-warn-500 {
  border: 1px solid rgb(var(--color-warn-500-rgb));
}
:root .line-warn-500-t, :root .line-theme-warn-500-t,
:root .border-warn-500-t,
:root .border-theme-warn-500-t {
  border-top: 1px solid rgb(var(--color-warn-500-rgb));
}
:root .line-warn-500-tr, :root .line-theme-warn-500-tr,
:root .border-warn-500-tr,
:root .border-theme-warn-500-tr {
  border-top: 1px solid rgb(var(--color-warn-500-rgb));
}
:root .line-warn-500-trb, :root .line-theme-warn-500-trb,
:root .border-warn-500-trb,
:root .border-theme-warn-500-trb {
  border-top: 1px solid rgb(var(--color-warn-500-rgb));
}
:root .line-warn-500-trbl, :root .line-theme-warn-500-trbl,
:root .border-warn-500-trbl,
:root .border-theme-warn-500-trbl {
  border-top: 1px solid rgb(var(--color-warn-500-rgb));
}
:root .line-warn-500-trl, :root .line-theme-warn-500-trl,
:root .border-warn-500-trl,
:root .border-theme-warn-500-trl {
  border-top: 1px solid rgb(var(--color-warn-500-rgb));
}
:root .line-warn-500-tb, :root .line-theme-warn-500-tb,
:root .border-warn-500-tb,
:root .border-theme-warn-500-tb {
  border-top: 1px solid rgb(var(--color-warn-500-rgb));
}
:root .line-warn-500-tbl, :root .line-theme-warn-500-tbl,
:root .border-warn-500-tbl,
:root .border-theme-warn-500-tbl {
  border-top: 1px solid rgb(var(--color-warn-500-rgb));
}
:root .line-warn-500-tl, :root .line-theme-warn-500-tl,
:root .border-warn-500-tl,
:root .border-theme-warn-500-tl {
  border-top: 1px solid rgb(var(--color-warn-500-rgb));
}
:root .line-warn-500-tr, :root .line-theme-warn-500-tr,
:root .border-warn-500-tr,
:root .border-theme-warn-500-tr {
  border-right: 1px solid rgb(var(--color-warn-500-rgb));
}
:root .line-warn-500-trb, :root .line-theme-warn-500-trb,
:root .border-warn-500-trb,
:root .border-theme-warn-500-trb {
  border-right: 1px solid rgb(var(--color-warn-500-rgb));
}
:root .line-warn-500-trbl, :root .line-theme-warn-500-trbl,
:root .border-warn-500-trbl,
:root .border-theme-warn-500-trbl {
  border-right: 1px solid rgb(var(--color-warn-500-rgb));
}
:root .line-warn-500-trl, :root .line-theme-warn-500-trl,
:root .border-warn-500-trl,
:root .border-theme-warn-500-trl {
  border-right: 1px solid rgb(var(--color-warn-500-rgb));
}
:root .line-warn-500-r, :root .line-theme-warn-500-r,
:root .border-warn-500-r,
:root .border-theme-warn-500-r {
  border-right: 1px solid rgb(var(--color-warn-500-rgb));
}
:root .line-warn-500-rb, :root .line-theme-warn-500-rb,
:root .border-warn-500-rb,
:root .border-theme-warn-500-rb {
  border-right: 1px solid rgb(var(--color-warn-500-rgb));
}
:root .line-warn-500-rbl, :root .line-theme-warn-500-rbl,
:root .border-warn-500-rbl,
:root .border-theme-warn-500-rbl {
  border-right: 1px solid rgb(var(--color-warn-500-rgb));
}
:root .line-warn-500-rl, :root .line-theme-warn-500-rl,
:root .border-warn-500-rl,
:root .border-theme-warn-500-rl {
  border-right: 1px solid rgb(var(--color-warn-500-rgb));
}
:root .line-warn-500-trb, :root .line-theme-warn-500-trb,
:root .border-warn-500-trb,
:root .border-theme-warn-500-trb {
  border-bottom: 1px solid rgb(var(--color-warn-500-rgb));
}
:root .line-warn-500-trbl, :root .line-theme-warn-500-trbl,
:root .border-warn-500-trbl,
:root .border-theme-warn-500-trbl {
  border-bottom: 1px solid rgb(var(--color-warn-500-rgb));
}
:root .line-warn-500-tb, :root .line-theme-warn-500-tb,
:root .border-warn-500-tb,
:root .border-theme-warn-500-tb {
  border-bottom: 1px solid rgb(var(--color-warn-500-rgb));
}
:root .line-warn-500-tbl, :root .line-theme-warn-500-tbl,
:root .border-warn-500-tbl,
:root .border-theme-warn-500-tbl {
  border-bottom: 1px solid rgb(var(--color-warn-500-rgb));
}
:root .line-warn-500-rb, :root .line-theme-warn-500-rb,
:root .border-warn-500-rb,
:root .border-theme-warn-500-rb {
  border-bottom: 1px solid rgb(var(--color-warn-500-rgb));
}
:root .line-warn-500-rbl, :root .line-theme-warn-500-rbl,
:root .border-warn-500-rbl,
:root .border-theme-warn-500-rbl {
  border-bottom: 1px solid rgb(var(--color-warn-500-rgb));
}
:root .line-warn-500-b, :root .line-theme-warn-500-b,
:root .border-warn-500-b,
:root .border-theme-warn-500-b {
  border-bottom: 1px solid rgb(var(--color-warn-500-rgb));
}
:root .line-warn-500-bl, :root .line-theme-warn-500-bl,
:root .border-warn-500-bl,
:root .border-theme-warn-500-bl {
  border-bottom: 1px solid rgb(var(--color-warn-500-rgb));
}
:root .line-warn-500-trbl, :root .line-theme-warn-500-trbl,
:root .border-warn-500-trbl,
:root .border-theme-warn-500-trbl {
  border-left: 1px solid rgb(var(--color-warn-500-rgb));
}
:root .line-warn-500-trl, :root .line-theme-warn-500-trl,
:root .border-warn-500-trl,
:root .border-theme-warn-500-trl {
  border-left: 1px solid rgb(var(--color-warn-500-rgb));
}
:root .line-warn-500-tbl, :root .line-theme-warn-500-tbl,
:root .border-warn-500-tbl,
:root .border-theme-warn-500-tbl {
  border-left: 1px solid rgb(var(--color-warn-500-rgb));
}
:root .line-warn-500-tl, :root .line-theme-warn-500-tl,
:root .border-warn-500-tl,
:root .border-theme-warn-500-tl {
  border-left: 1px solid rgb(var(--color-warn-500-rgb));
}
:root .line-warn-500-rbl, :root .line-theme-warn-500-rbl,
:root .border-warn-500-rbl,
:root .border-theme-warn-500-rbl {
  border-left: 1px solid rgb(var(--color-warn-500-rgb));
}
:root .line-warn-500-rl, :root .line-theme-warn-500-rl,
:root .border-warn-500-rl,
:root .border-theme-warn-500-rl {
  border-left: 1px solid rgb(var(--color-warn-500-rgb));
}
:root .line-warn-500-bl, :root .line-theme-warn-500-bl,
:root .border-warn-500-bl,
:root .border-theme-warn-500-bl {
  border-left: 1px solid rgb(var(--color-warn-500-rgb));
}
:root .line-warn-500-l, :root .line-theme-warn-500-l,
:root .border-warn-500-l,
:root .border-theme-warn-500-l {
  border-left: 1px solid rgb(var(--color-warn-500-rgb));
}

@media (hover: hover) {
  :root .line-warn-500-on-hover:hover, :root .line-theme-warn-500-on-hover:hover,
  :root .border-warn-500-on-hover:hover,
  :root .border-theme-warn-500-on-hover:hover {
    border-color: rgb(var(--color-warn-500-rgb));
  }
  :root .hover-parent:hover .line-warn-500-on-hover-parent, :root .hover-parent:hover .line-theme-warn-500-on-hover-parent,
  :root .hover-parent:hover .border-warn-500-on-hover-parent,
  :root .hover-parent:hover .border-theme-warn-500-on-hover-parent {
    border-color: rgb(var(--color-warn-500-rgb));
  }
}
:root .line-warn-700, :root .line-theme-warn-700,
:root .border-warn-700,
:root .border-theme-warn-700 {
  border: 1px solid rgb(var(--color-warn-700-rgb));
}
:root .line-warn-700-t, :root .line-theme-warn-700-t,
:root .border-warn-700-t,
:root .border-theme-warn-700-t {
  border-top: 1px solid rgb(var(--color-warn-700-rgb));
}
:root .line-warn-700-tr, :root .line-theme-warn-700-tr,
:root .border-warn-700-tr,
:root .border-theme-warn-700-tr {
  border-top: 1px solid rgb(var(--color-warn-700-rgb));
}
:root .line-warn-700-trb, :root .line-theme-warn-700-trb,
:root .border-warn-700-trb,
:root .border-theme-warn-700-trb {
  border-top: 1px solid rgb(var(--color-warn-700-rgb));
}
:root .line-warn-700-trbl, :root .line-theme-warn-700-trbl,
:root .border-warn-700-trbl,
:root .border-theme-warn-700-trbl {
  border-top: 1px solid rgb(var(--color-warn-700-rgb));
}
:root .line-warn-700-trl, :root .line-theme-warn-700-trl,
:root .border-warn-700-trl,
:root .border-theme-warn-700-trl {
  border-top: 1px solid rgb(var(--color-warn-700-rgb));
}
:root .line-warn-700-tb, :root .line-theme-warn-700-tb,
:root .border-warn-700-tb,
:root .border-theme-warn-700-tb {
  border-top: 1px solid rgb(var(--color-warn-700-rgb));
}
:root .line-warn-700-tbl, :root .line-theme-warn-700-tbl,
:root .border-warn-700-tbl,
:root .border-theme-warn-700-tbl {
  border-top: 1px solid rgb(var(--color-warn-700-rgb));
}
:root .line-warn-700-tl, :root .line-theme-warn-700-tl,
:root .border-warn-700-tl,
:root .border-theme-warn-700-tl {
  border-top: 1px solid rgb(var(--color-warn-700-rgb));
}
:root .line-warn-700-tr, :root .line-theme-warn-700-tr,
:root .border-warn-700-tr,
:root .border-theme-warn-700-tr {
  border-right: 1px solid rgb(var(--color-warn-700-rgb));
}
:root .line-warn-700-trb, :root .line-theme-warn-700-trb,
:root .border-warn-700-trb,
:root .border-theme-warn-700-trb {
  border-right: 1px solid rgb(var(--color-warn-700-rgb));
}
:root .line-warn-700-trbl, :root .line-theme-warn-700-trbl,
:root .border-warn-700-trbl,
:root .border-theme-warn-700-trbl {
  border-right: 1px solid rgb(var(--color-warn-700-rgb));
}
:root .line-warn-700-trl, :root .line-theme-warn-700-trl,
:root .border-warn-700-trl,
:root .border-theme-warn-700-trl {
  border-right: 1px solid rgb(var(--color-warn-700-rgb));
}
:root .line-warn-700-r, :root .line-theme-warn-700-r,
:root .border-warn-700-r,
:root .border-theme-warn-700-r {
  border-right: 1px solid rgb(var(--color-warn-700-rgb));
}
:root .line-warn-700-rb, :root .line-theme-warn-700-rb,
:root .border-warn-700-rb,
:root .border-theme-warn-700-rb {
  border-right: 1px solid rgb(var(--color-warn-700-rgb));
}
:root .line-warn-700-rbl, :root .line-theme-warn-700-rbl,
:root .border-warn-700-rbl,
:root .border-theme-warn-700-rbl {
  border-right: 1px solid rgb(var(--color-warn-700-rgb));
}
:root .line-warn-700-rl, :root .line-theme-warn-700-rl,
:root .border-warn-700-rl,
:root .border-theme-warn-700-rl {
  border-right: 1px solid rgb(var(--color-warn-700-rgb));
}
:root .line-warn-700-trb, :root .line-theme-warn-700-trb,
:root .border-warn-700-trb,
:root .border-theme-warn-700-trb {
  border-bottom: 1px solid rgb(var(--color-warn-700-rgb));
}
:root .line-warn-700-trbl, :root .line-theme-warn-700-trbl,
:root .border-warn-700-trbl,
:root .border-theme-warn-700-trbl {
  border-bottom: 1px solid rgb(var(--color-warn-700-rgb));
}
:root .line-warn-700-tb, :root .line-theme-warn-700-tb,
:root .border-warn-700-tb,
:root .border-theme-warn-700-tb {
  border-bottom: 1px solid rgb(var(--color-warn-700-rgb));
}
:root .line-warn-700-tbl, :root .line-theme-warn-700-tbl,
:root .border-warn-700-tbl,
:root .border-theme-warn-700-tbl {
  border-bottom: 1px solid rgb(var(--color-warn-700-rgb));
}
:root .line-warn-700-rb, :root .line-theme-warn-700-rb,
:root .border-warn-700-rb,
:root .border-theme-warn-700-rb {
  border-bottom: 1px solid rgb(var(--color-warn-700-rgb));
}
:root .line-warn-700-rbl, :root .line-theme-warn-700-rbl,
:root .border-warn-700-rbl,
:root .border-theme-warn-700-rbl {
  border-bottom: 1px solid rgb(var(--color-warn-700-rgb));
}
:root .line-warn-700-b, :root .line-theme-warn-700-b,
:root .border-warn-700-b,
:root .border-theme-warn-700-b {
  border-bottom: 1px solid rgb(var(--color-warn-700-rgb));
}
:root .line-warn-700-bl, :root .line-theme-warn-700-bl,
:root .border-warn-700-bl,
:root .border-theme-warn-700-bl {
  border-bottom: 1px solid rgb(var(--color-warn-700-rgb));
}
:root .line-warn-700-trbl, :root .line-theme-warn-700-trbl,
:root .border-warn-700-trbl,
:root .border-theme-warn-700-trbl {
  border-left: 1px solid rgb(var(--color-warn-700-rgb));
}
:root .line-warn-700-trl, :root .line-theme-warn-700-trl,
:root .border-warn-700-trl,
:root .border-theme-warn-700-trl {
  border-left: 1px solid rgb(var(--color-warn-700-rgb));
}
:root .line-warn-700-tbl, :root .line-theme-warn-700-tbl,
:root .border-warn-700-tbl,
:root .border-theme-warn-700-tbl {
  border-left: 1px solid rgb(var(--color-warn-700-rgb));
}
:root .line-warn-700-tl, :root .line-theme-warn-700-tl,
:root .border-warn-700-tl,
:root .border-theme-warn-700-tl {
  border-left: 1px solid rgb(var(--color-warn-700-rgb));
}
:root .line-warn-700-rbl, :root .line-theme-warn-700-rbl,
:root .border-warn-700-rbl,
:root .border-theme-warn-700-rbl {
  border-left: 1px solid rgb(var(--color-warn-700-rgb));
}
:root .line-warn-700-rl, :root .line-theme-warn-700-rl,
:root .border-warn-700-rl,
:root .border-theme-warn-700-rl {
  border-left: 1px solid rgb(var(--color-warn-700-rgb));
}
:root .line-warn-700-bl, :root .line-theme-warn-700-bl,
:root .border-warn-700-bl,
:root .border-theme-warn-700-bl {
  border-left: 1px solid rgb(var(--color-warn-700-rgb));
}
:root .line-warn-700-l, :root .line-theme-warn-700-l,
:root .border-warn-700-l,
:root .border-theme-warn-700-l {
  border-left: 1px solid rgb(var(--color-warn-700-rgb));
}

@media (hover: hover) {
  :root .line-warn-700-on-hover:hover, :root .line-theme-warn-700-on-hover:hover,
  :root .border-warn-700-on-hover:hover,
  :root .border-theme-warn-700-on-hover:hover {
    border-color: rgb(var(--color-warn-700-rgb));
  }
  :root .hover-parent:hover .line-warn-700-on-hover-parent, :root .hover-parent:hover .line-theme-warn-700-on-hover-parent,
  :root .hover-parent:hover .border-warn-700-on-hover-parent,
  :root .hover-parent:hover .border-theme-warn-700-on-hover-parent {
    border-color: rgb(var(--color-warn-700-rgb));
  }
}
.line-primary-on-focus:focus,
.border-primary-on-focus:focus {
  border: 1px solid rgb(var(--color-primary-rgb));
}
.line-primary-on-focus-within:focus-within,
.border-primary-on-focus-within:focus-within {
  border: 1px solid rgb(var(--color-primary-rgb));
}

:root {
  --color-text-white-rgb: 255, 255, 255;
  --color-text-white: rgb(var(--color-text-white-rgb));
  --color-text-100-rgb: 255, 255, 255;
  --color-text-100: rgb(var(--color-text-100-rgb));
  --color-text-200-rgb: 195, 194, 198;
  --color-text-200: rgb(var(--color-text-200-rgb));
  --color-text-400-rgb: 139, 146, 162;
  --color-text-400: rgb(var(--color-text-400-rgb));
  --color-text-500-rgb: 98, 102, 113;
  --color-text-500: rgb(var(--color-text-500-rgb));
  --color-text-800-rgb: 27, 42, 74;
  --color-text-800: rgb(var(--color-text-800-rgb));
  --light-color-text-white-rgb: 255, 255, 255;
  --light-color-text-white: rgb(var(--light-color-text-white-rgb));
  --light-color-text-100-rgb: 255, 255, 255;
  --light-color-text-100: rgb(var(--light-color-text-100-rgb));
  --light-color-text-200-rgb: 195, 194, 198;
  --light-color-text-200: rgb(var(--light-color-text-200-rgb));
  --light-color-text-400-rgb: 139, 146, 162;
  --light-color-text-400: rgb(var(--light-color-text-400-rgb));
  --light-color-text-500-rgb: 98, 102, 113;
  --light-color-text-500: rgb(var(--light-color-text-500-rgb));
  --light-color-text-800-rgb: 27, 42, 74;
  --light-color-text-800: rgb(var(--light-color-text-800-rgb));
  --dark-color-text-white-rgb: 255, 255, 255;
  --dark-color-text-white: rgb(var(--dark-color-text-white-rgb));
  --dark-color-text-100-rgb: 255, 255, 255;
  --dark-color-text-100: rgb(var(--dark-color-text-100-rgb));
  --dark-color-text-200-rgb: 86, 86, 91;
  --dark-color-text-200: rgb(var(--dark-color-text-200-rgb));
  --dark-color-text-400-rgb: 139, 146, 162;
  --dark-color-text-400: rgb(var(--dark-color-text-400-rgb));
  --dark-color-text-500-rgb: 175, 182, 198;
  --dark-color-text-500: rgb(var(--dark-color-text-500-rgb));
  --dark-color-text-800-rgb: 227, 227, 227;
  --dark-color-text-800: rgb(var(--dark-color-text-800-rgb));
  --color-code-html-tag-rgb: 194, 255, 0;
  --color-code-html-tag: rgb(var(--color-code-html-tag-rgb));
  --color-code-html-attribute-name-rgb: 81, 210, 243;
  --color-code-html-attribute-name: rgb(var(--color-code-html-attribute-name-rgb));
  --color-code-html-attribute-value-rgb: 171, 220, 255;
  --color-code-html-attribute-value: rgb(var(--color-code-html-attribute-value-rgb));
}
:root[app-theme=auto][client-theme=dark], :root[app-theme=dark] {
  --color-text-white-rgb: 255, 255, 255;
  --color-text-white: rgb(var(--color-text-white-rgb));
  --color-text-100-rgb: 255, 255, 255;
  --color-text-100: rgb(var(--color-text-100-rgb));
  --color-text-200-rgb: 86, 86, 91;
  --color-text-200: rgb(var(--color-text-200-rgb));
  --color-text-400-rgb: 139, 146, 162;
  --color-text-400: rgb(var(--color-text-400-rgb));
  --color-text-500-rgb: 175, 182, 198;
  --color-text-500: rgb(var(--color-text-500-rgb));
  --color-text-800-rgb: 227, 227, 227;
  --color-text-800: rgb(var(--color-text-800-rgb));
}

body {
  color: rgb(var(--color-text-800-rgb));
}

.text-code-html-tag,
.fc-code-html-tag {
  color: var(--color-code-html-tag);
}
.text-code-html-attribute-name,
.fc-code-html-attribute-name {
  color: var(--color-code-html-attribute-name);
}
.text-code-html-attribute-value,
.fc-code-html-attribute-value {
  color: var(--color-code-html-attribute-value);
}
:root .text-white,
:root .fc-white {
  color: rgb(var(--color-text-white-rgb));
}
:root .text-white-0,
:root .fc-white-0 {
  color: rgba(var(--color-text-white-rgb), 0);
}
@media (hover: hover) {
  :root .text-white-0-on-hover:hover,
  :root .fc-white-0-on-hover:hover {
    color: rgba(var(--color-text-white-rgb), 0);
  }
  :root .hover-parent:hover .text-white-0-on-hover-parent, :root .hover-parent:hover .fc-white-0-on-hover-parent {
    color: rgba(var(--color-text-white-rgb), 0);
  }
}
:root .text-white-10,
:root .fc-white-10 {
  color: rgba(var(--color-text-white-rgb), 0.1);
}
@media (hover: hover) {
  :root .text-white-10-on-hover:hover,
  :root .fc-white-10-on-hover:hover {
    color: rgba(var(--color-text-white-rgb), 0.1);
  }
  :root .hover-parent:hover .text-white-10-on-hover-parent, :root .hover-parent:hover .fc-white-10-on-hover-parent {
    color: rgba(var(--color-text-white-rgb), 0.1);
  }
}
:root .text-white-20,
:root .fc-white-20 {
  color: rgba(var(--color-text-white-rgb), 0.2);
}
@media (hover: hover) {
  :root .text-white-20-on-hover:hover,
  :root .fc-white-20-on-hover:hover {
    color: rgba(var(--color-text-white-rgb), 0.2);
  }
  :root .hover-parent:hover .text-white-20-on-hover-parent, :root .hover-parent:hover .fc-white-20-on-hover-parent {
    color: rgba(var(--color-text-white-rgb), 0.2);
  }
}
:root .text-white-30,
:root .fc-white-30 {
  color: rgba(var(--color-text-white-rgb), 0.3);
}
@media (hover: hover) {
  :root .text-white-30-on-hover:hover,
  :root .fc-white-30-on-hover:hover {
    color: rgba(var(--color-text-white-rgb), 0.3);
  }
  :root .hover-parent:hover .text-white-30-on-hover-parent, :root .hover-parent:hover .fc-white-30-on-hover-parent {
    color: rgba(var(--color-text-white-rgb), 0.3);
  }
}
:root .text-white-40,
:root .fc-white-40 {
  color: rgba(var(--color-text-white-rgb), 0.4);
}
@media (hover: hover) {
  :root .text-white-40-on-hover:hover,
  :root .fc-white-40-on-hover:hover {
    color: rgba(var(--color-text-white-rgb), 0.4);
  }
  :root .hover-parent:hover .text-white-40-on-hover-parent, :root .hover-parent:hover .fc-white-40-on-hover-parent {
    color: rgba(var(--color-text-white-rgb), 0.4);
  }
}
:root .text-white-50,
:root .fc-white-50 {
  color: rgba(var(--color-text-white-rgb), 0.5);
}
@media (hover: hover) {
  :root .text-white-50-on-hover:hover,
  :root .fc-white-50-on-hover:hover {
    color: rgba(var(--color-text-white-rgb), 0.5);
  }
  :root .hover-parent:hover .text-white-50-on-hover-parent, :root .hover-parent:hover .fc-white-50-on-hover-parent {
    color: rgba(var(--color-text-white-rgb), 0.5);
  }
}
:root .text-white-60,
:root .fc-white-60 {
  color: rgba(var(--color-text-white-rgb), 0.6);
}
@media (hover: hover) {
  :root .text-white-60-on-hover:hover,
  :root .fc-white-60-on-hover:hover {
    color: rgba(var(--color-text-white-rgb), 0.6);
  }
  :root .hover-parent:hover .text-white-60-on-hover-parent, :root .hover-parent:hover .fc-white-60-on-hover-parent {
    color: rgba(var(--color-text-white-rgb), 0.6);
  }
}
:root .text-white-70,
:root .fc-white-70 {
  color: rgba(var(--color-text-white-rgb), 0.7);
}
@media (hover: hover) {
  :root .text-white-70-on-hover:hover,
  :root .fc-white-70-on-hover:hover {
    color: rgba(var(--color-text-white-rgb), 0.7);
  }
  :root .hover-parent:hover .text-white-70-on-hover-parent, :root .hover-parent:hover .fc-white-70-on-hover-parent {
    color: rgba(var(--color-text-white-rgb), 0.7);
  }
}
:root .text-white-80,
:root .fc-white-80 {
  color: rgba(var(--color-text-white-rgb), 0.8);
}
@media (hover: hover) {
  :root .text-white-80-on-hover:hover,
  :root .fc-white-80-on-hover:hover {
    color: rgba(var(--color-text-white-rgb), 0.8);
  }
  :root .hover-parent:hover .text-white-80-on-hover-parent, :root .hover-parent:hover .fc-white-80-on-hover-parent {
    color: rgba(var(--color-text-white-rgb), 0.8);
  }
}
:root .text-white-90,
:root .fc-white-90 {
  color: rgba(var(--color-text-white-rgb), 0.9);
}
@media (hover: hover) {
  :root .text-white-90-on-hover:hover,
  :root .fc-white-90-on-hover:hover {
    color: rgba(var(--color-text-white-rgb), 0.9);
  }
  :root .hover-parent:hover .text-white-90-on-hover-parent, :root .hover-parent:hover .fc-white-90-on-hover-parent {
    color: rgba(var(--color-text-white-rgb), 0.9);
  }
}
:root .text-white-100,
:root .fc-white-100 {
  color: rgba(var(--color-text-white-rgb), 1);
}
@media (hover: hover) {
  :root .text-white-100-on-hover:hover,
  :root .fc-white-100-on-hover:hover {
    color: rgba(var(--color-text-white-rgb), 1);
  }
  :root .hover-parent:hover .text-white-100-on-hover-parent, :root .hover-parent:hover .fc-white-100-on-hover-parent {
    color: rgba(var(--color-text-white-rgb), 1);
  }
}
:root .text-white-as-bg,
:root .fc-white-as-bg {
  background-color: rgb(var(--color-text-white-rgb));
}
:root .text-white-as-border, :root .text-white-as-line,
:root .fc-white-as-border,
:root .fc-white-as-line {
  border-color: rgb(var(--color-text-white-rgb));
}

@media (hover: hover) {
  :root .text-white-on-hover:hover,
  :root .fc-white-on-hover:hover {
    color: rgb(var(--color-text-white-rgb));
  }
  :root .hover-parent:hover .text-white-on-hover-parent,
  :root .hover-parent:hover .fc-white-on-hover-parent {
    color: rgb(var(--color-text-white-rgb));
  }
}
:root .text-100,
:root .fc-100 {
  color: rgb(var(--color-text-100-rgb));
}
:root .text-100-0,
:root .fc-100-0 {
  color: rgba(var(--color-text-100-rgb), 0);
}
@media (hover: hover) {
  :root .text-100-0-on-hover:hover,
  :root .fc-100-0-on-hover:hover {
    color: rgba(var(--color-text-100-rgb), 0);
  }
  :root .hover-parent:hover .text-100-0-on-hover-parent, :root .hover-parent:hover .fc-100-0-on-hover-parent {
    color: rgba(var(--color-text-100-rgb), 0);
  }
}
:root .text-100-10,
:root .fc-100-10 {
  color: rgba(var(--color-text-100-rgb), 0.1);
}
@media (hover: hover) {
  :root .text-100-10-on-hover:hover,
  :root .fc-100-10-on-hover:hover {
    color: rgba(var(--color-text-100-rgb), 0.1);
  }
  :root .hover-parent:hover .text-100-10-on-hover-parent, :root .hover-parent:hover .fc-100-10-on-hover-parent {
    color: rgba(var(--color-text-100-rgb), 0.1);
  }
}
:root .text-100-20,
:root .fc-100-20 {
  color: rgba(var(--color-text-100-rgb), 0.2);
}
@media (hover: hover) {
  :root .text-100-20-on-hover:hover,
  :root .fc-100-20-on-hover:hover {
    color: rgba(var(--color-text-100-rgb), 0.2);
  }
  :root .hover-parent:hover .text-100-20-on-hover-parent, :root .hover-parent:hover .fc-100-20-on-hover-parent {
    color: rgba(var(--color-text-100-rgb), 0.2);
  }
}
:root .text-100-30,
:root .fc-100-30 {
  color: rgba(var(--color-text-100-rgb), 0.3);
}
@media (hover: hover) {
  :root .text-100-30-on-hover:hover,
  :root .fc-100-30-on-hover:hover {
    color: rgba(var(--color-text-100-rgb), 0.3);
  }
  :root .hover-parent:hover .text-100-30-on-hover-parent, :root .hover-parent:hover .fc-100-30-on-hover-parent {
    color: rgba(var(--color-text-100-rgb), 0.3);
  }
}
:root .text-100-40,
:root .fc-100-40 {
  color: rgba(var(--color-text-100-rgb), 0.4);
}
@media (hover: hover) {
  :root .text-100-40-on-hover:hover,
  :root .fc-100-40-on-hover:hover {
    color: rgba(var(--color-text-100-rgb), 0.4);
  }
  :root .hover-parent:hover .text-100-40-on-hover-parent, :root .hover-parent:hover .fc-100-40-on-hover-parent {
    color: rgba(var(--color-text-100-rgb), 0.4);
  }
}
:root .text-100-50,
:root .fc-100-50 {
  color: rgba(var(--color-text-100-rgb), 0.5);
}
@media (hover: hover) {
  :root .text-100-50-on-hover:hover,
  :root .fc-100-50-on-hover:hover {
    color: rgba(var(--color-text-100-rgb), 0.5);
  }
  :root .hover-parent:hover .text-100-50-on-hover-parent, :root .hover-parent:hover .fc-100-50-on-hover-parent {
    color: rgba(var(--color-text-100-rgb), 0.5);
  }
}
:root .text-100-60,
:root .fc-100-60 {
  color: rgba(var(--color-text-100-rgb), 0.6);
}
@media (hover: hover) {
  :root .text-100-60-on-hover:hover,
  :root .fc-100-60-on-hover:hover {
    color: rgba(var(--color-text-100-rgb), 0.6);
  }
  :root .hover-parent:hover .text-100-60-on-hover-parent, :root .hover-parent:hover .fc-100-60-on-hover-parent {
    color: rgba(var(--color-text-100-rgb), 0.6);
  }
}
:root .text-100-70,
:root .fc-100-70 {
  color: rgba(var(--color-text-100-rgb), 0.7);
}
@media (hover: hover) {
  :root .text-100-70-on-hover:hover,
  :root .fc-100-70-on-hover:hover {
    color: rgba(var(--color-text-100-rgb), 0.7);
  }
  :root .hover-parent:hover .text-100-70-on-hover-parent, :root .hover-parent:hover .fc-100-70-on-hover-parent {
    color: rgba(var(--color-text-100-rgb), 0.7);
  }
}
:root .text-100-80,
:root .fc-100-80 {
  color: rgba(var(--color-text-100-rgb), 0.8);
}
@media (hover: hover) {
  :root .text-100-80-on-hover:hover,
  :root .fc-100-80-on-hover:hover {
    color: rgba(var(--color-text-100-rgb), 0.8);
  }
  :root .hover-parent:hover .text-100-80-on-hover-parent, :root .hover-parent:hover .fc-100-80-on-hover-parent {
    color: rgba(var(--color-text-100-rgb), 0.8);
  }
}
:root .text-100-90,
:root .fc-100-90 {
  color: rgba(var(--color-text-100-rgb), 0.9);
}
@media (hover: hover) {
  :root .text-100-90-on-hover:hover,
  :root .fc-100-90-on-hover:hover {
    color: rgba(var(--color-text-100-rgb), 0.9);
  }
  :root .hover-parent:hover .text-100-90-on-hover-parent, :root .hover-parent:hover .fc-100-90-on-hover-parent {
    color: rgba(var(--color-text-100-rgb), 0.9);
  }
}
:root .text-100-100,
:root .fc-100-100 {
  color: rgba(var(--color-text-100-rgb), 1);
}
@media (hover: hover) {
  :root .text-100-100-on-hover:hover,
  :root .fc-100-100-on-hover:hover {
    color: rgba(var(--color-text-100-rgb), 1);
  }
  :root .hover-parent:hover .text-100-100-on-hover-parent, :root .hover-parent:hover .fc-100-100-on-hover-parent {
    color: rgba(var(--color-text-100-rgb), 1);
  }
}
:root .text-100-as-bg,
:root .fc-100-as-bg {
  background-color: rgb(var(--color-text-100-rgb));
}
:root .text-100-as-border, :root .text-100-as-line,
:root .fc-100-as-border,
:root .fc-100-as-line {
  border-color: rgb(var(--color-text-100-rgb));
}

@media (hover: hover) {
  :root .text-100-on-hover:hover,
  :root .fc-100-on-hover:hover {
    color: rgb(var(--color-text-100-rgb));
  }
  :root .hover-parent:hover .text-100-on-hover-parent,
  :root .hover-parent:hover .fc-100-on-hover-parent {
    color: rgb(var(--color-text-100-rgb));
  }
}
:root .text-200,
:root .fc-200 {
  color: rgb(var(--color-text-200-rgb));
}
:root .text-200-0,
:root .fc-200-0 {
  color: rgba(var(--color-text-200-rgb), 0);
}
@media (hover: hover) {
  :root .text-200-0-on-hover:hover,
  :root .fc-200-0-on-hover:hover {
    color: rgba(var(--color-text-200-rgb), 0);
  }
  :root .hover-parent:hover .text-200-0-on-hover-parent, :root .hover-parent:hover .fc-200-0-on-hover-parent {
    color: rgba(var(--color-text-200-rgb), 0);
  }
}
:root .text-200-10,
:root .fc-200-10 {
  color: rgba(var(--color-text-200-rgb), 0.1);
}
@media (hover: hover) {
  :root .text-200-10-on-hover:hover,
  :root .fc-200-10-on-hover:hover {
    color: rgba(var(--color-text-200-rgb), 0.1);
  }
  :root .hover-parent:hover .text-200-10-on-hover-parent, :root .hover-parent:hover .fc-200-10-on-hover-parent {
    color: rgba(var(--color-text-200-rgb), 0.1);
  }
}
:root .text-200-20,
:root .fc-200-20 {
  color: rgba(var(--color-text-200-rgb), 0.2);
}
@media (hover: hover) {
  :root .text-200-20-on-hover:hover,
  :root .fc-200-20-on-hover:hover {
    color: rgba(var(--color-text-200-rgb), 0.2);
  }
  :root .hover-parent:hover .text-200-20-on-hover-parent, :root .hover-parent:hover .fc-200-20-on-hover-parent {
    color: rgba(var(--color-text-200-rgb), 0.2);
  }
}
:root .text-200-30,
:root .fc-200-30 {
  color: rgba(var(--color-text-200-rgb), 0.3);
}
@media (hover: hover) {
  :root .text-200-30-on-hover:hover,
  :root .fc-200-30-on-hover:hover {
    color: rgba(var(--color-text-200-rgb), 0.3);
  }
  :root .hover-parent:hover .text-200-30-on-hover-parent, :root .hover-parent:hover .fc-200-30-on-hover-parent {
    color: rgba(var(--color-text-200-rgb), 0.3);
  }
}
:root .text-200-40,
:root .fc-200-40 {
  color: rgba(var(--color-text-200-rgb), 0.4);
}
@media (hover: hover) {
  :root .text-200-40-on-hover:hover,
  :root .fc-200-40-on-hover:hover {
    color: rgba(var(--color-text-200-rgb), 0.4);
  }
  :root .hover-parent:hover .text-200-40-on-hover-parent, :root .hover-parent:hover .fc-200-40-on-hover-parent {
    color: rgba(var(--color-text-200-rgb), 0.4);
  }
}
:root .text-200-50,
:root .fc-200-50 {
  color: rgba(var(--color-text-200-rgb), 0.5);
}
@media (hover: hover) {
  :root .text-200-50-on-hover:hover,
  :root .fc-200-50-on-hover:hover {
    color: rgba(var(--color-text-200-rgb), 0.5);
  }
  :root .hover-parent:hover .text-200-50-on-hover-parent, :root .hover-parent:hover .fc-200-50-on-hover-parent {
    color: rgba(var(--color-text-200-rgb), 0.5);
  }
}
:root .text-200-60,
:root .fc-200-60 {
  color: rgba(var(--color-text-200-rgb), 0.6);
}
@media (hover: hover) {
  :root .text-200-60-on-hover:hover,
  :root .fc-200-60-on-hover:hover {
    color: rgba(var(--color-text-200-rgb), 0.6);
  }
  :root .hover-parent:hover .text-200-60-on-hover-parent, :root .hover-parent:hover .fc-200-60-on-hover-parent {
    color: rgba(var(--color-text-200-rgb), 0.6);
  }
}
:root .text-200-70,
:root .fc-200-70 {
  color: rgba(var(--color-text-200-rgb), 0.7);
}
@media (hover: hover) {
  :root .text-200-70-on-hover:hover,
  :root .fc-200-70-on-hover:hover {
    color: rgba(var(--color-text-200-rgb), 0.7);
  }
  :root .hover-parent:hover .text-200-70-on-hover-parent, :root .hover-parent:hover .fc-200-70-on-hover-parent {
    color: rgba(var(--color-text-200-rgb), 0.7);
  }
}
:root .text-200-80,
:root .fc-200-80 {
  color: rgba(var(--color-text-200-rgb), 0.8);
}
@media (hover: hover) {
  :root .text-200-80-on-hover:hover,
  :root .fc-200-80-on-hover:hover {
    color: rgba(var(--color-text-200-rgb), 0.8);
  }
  :root .hover-parent:hover .text-200-80-on-hover-parent, :root .hover-parent:hover .fc-200-80-on-hover-parent {
    color: rgba(var(--color-text-200-rgb), 0.8);
  }
}
:root .text-200-90,
:root .fc-200-90 {
  color: rgba(var(--color-text-200-rgb), 0.9);
}
@media (hover: hover) {
  :root .text-200-90-on-hover:hover,
  :root .fc-200-90-on-hover:hover {
    color: rgba(var(--color-text-200-rgb), 0.9);
  }
  :root .hover-parent:hover .text-200-90-on-hover-parent, :root .hover-parent:hover .fc-200-90-on-hover-parent {
    color: rgba(var(--color-text-200-rgb), 0.9);
  }
}
:root .text-200-100,
:root .fc-200-100 {
  color: rgba(var(--color-text-200-rgb), 1);
}
@media (hover: hover) {
  :root .text-200-100-on-hover:hover,
  :root .fc-200-100-on-hover:hover {
    color: rgba(var(--color-text-200-rgb), 1);
  }
  :root .hover-parent:hover .text-200-100-on-hover-parent, :root .hover-parent:hover .fc-200-100-on-hover-parent {
    color: rgba(var(--color-text-200-rgb), 1);
  }
}
:root .text-200-as-bg,
:root .fc-200-as-bg {
  background-color: rgb(var(--color-text-200-rgb));
}
:root .text-200-as-border, :root .text-200-as-line,
:root .fc-200-as-border,
:root .fc-200-as-line {
  border-color: rgb(var(--color-text-200-rgb));
}

@media (hover: hover) {
  :root .text-200-on-hover:hover,
  :root .fc-200-on-hover:hover {
    color: rgb(var(--color-text-200-rgb));
  }
  :root .hover-parent:hover .text-200-on-hover-parent,
  :root .hover-parent:hover .fc-200-on-hover-parent {
    color: rgb(var(--color-text-200-rgb));
  }
}
:root .text-400,
:root .fc-400 {
  color: rgb(var(--color-text-400-rgb));
}
:root .text-400-0,
:root .fc-400-0 {
  color: rgba(var(--color-text-400-rgb), 0);
}
@media (hover: hover) {
  :root .text-400-0-on-hover:hover,
  :root .fc-400-0-on-hover:hover {
    color: rgba(var(--color-text-400-rgb), 0);
  }
  :root .hover-parent:hover .text-400-0-on-hover-parent, :root .hover-parent:hover .fc-400-0-on-hover-parent {
    color: rgba(var(--color-text-400-rgb), 0);
  }
}
:root .text-400-10,
:root .fc-400-10 {
  color: rgba(var(--color-text-400-rgb), 0.1);
}
@media (hover: hover) {
  :root .text-400-10-on-hover:hover,
  :root .fc-400-10-on-hover:hover {
    color: rgba(var(--color-text-400-rgb), 0.1);
  }
  :root .hover-parent:hover .text-400-10-on-hover-parent, :root .hover-parent:hover .fc-400-10-on-hover-parent {
    color: rgba(var(--color-text-400-rgb), 0.1);
  }
}
:root .text-400-20,
:root .fc-400-20 {
  color: rgba(var(--color-text-400-rgb), 0.2);
}
@media (hover: hover) {
  :root .text-400-20-on-hover:hover,
  :root .fc-400-20-on-hover:hover {
    color: rgba(var(--color-text-400-rgb), 0.2);
  }
  :root .hover-parent:hover .text-400-20-on-hover-parent, :root .hover-parent:hover .fc-400-20-on-hover-parent {
    color: rgba(var(--color-text-400-rgb), 0.2);
  }
}
:root .text-400-30,
:root .fc-400-30 {
  color: rgba(var(--color-text-400-rgb), 0.3);
}
@media (hover: hover) {
  :root .text-400-30-on-hover:hover,
  :root .fc-400-30-on-hover:hover {
    color: rgba(var(--color-text-400-rgb), 0.3);
  }
  :root .hover-parent:hover .text-400-30-on-hover-parent, :root .hover-parent:hover .fc-400-30-on-hover-parent {
    color: rgba(var(--color-text-400-rgb), 0.3);
  }
}
:root .text-400-40,
:root .fc-400-40 {
  color: rgba(var(--color-text-400-rgb), 0.4);
}
@media (hover: hover) {
  :root .text-400-40-on-hover:hover,
  :root .fc-400-40-on-hover:hover {
    color: rgba(var(--color-text-400-rgb), 0.4);
  }
  :root .hover-parent:hover .text-400-40-on-hover-parent, :root .hover-parent:hover .fc-400-40-on-hover-parent {
    color: rgba(var(--color-text-400-rgb), 0.4);
  }
}
:root .text-400-50,
:root .fc-400-50 {
  color: rgba(var(--color-text-400-rgb), 0.5);
}
@media (hover: hover) {
  :root .text-400-50-on-hover:hover,
  :root .fc-400-50-on-hover:hover {
    color: rgba(var(--color-text-400-rgb), 0.5);
  }
  :root .hover-parent:hover .text-400-50-on-hover-parent, :root .hover-parent:hover .fc-400-50-on-hover-parent {
    color: rgba(var(--color-text-400-rgb), 0.5);
  }
}
:root .text-400-60,
:root .fc-400-60 {
  color: rgba(var(--color-text-400-rgb), 0.6);
}
@media (hover: hover) {
  :root .text-400-60-on-hover:hover,
  :root .fc-400-60-on-hover:hover {
    color: rgba(var(--color-text-400-rgb), 0.6);
  }
  :root .hover-parent:hover .text-400-60-on-hover-parent, :root .hover-parent:hover .fc-400-60-on-hover-parent {
    color: rgba(var(--color-text-400-rgb), 0.6);
  }
}
:root .text-400-70,
:root .fc-400-70 {
  color: rgba(var(--color-text-400-rgb), 0.7);
}
@media (hover: hover) {
  :root .text-400-70-on-hover:hover,
  :root .fc-400-70-on-hover:hover {
    color: rgba(var(--color-text-400-rgb), 0.7);
  }
  :root .hover-parent:hover .text-400-70-on-hover-parent, :root .hover-parent:hover .fc-400-70-on-hover-parent {
    color: rgba(var(--color-text-400-rgb), 0.7);
  }
}
:root .text-400-80,
:root .fc-400-80 {
  color: rgba(var(--color-text-400-rgb), 0.8);
}
@media (hover: hover) {
  :root .text-400-80-on-hover:hover,
  :root .fc-400-80-on-hover:hover {
    color: rgba(var(--color-text-400-rgb), 0.8);
  }
  :root .hover-parent:hover .text-400-80-on-hover-parent, :root .hover-parent:hover .fc-400-80-on-hover-parent {
    color: rgba(var(--color-text-400-rgb), 0.8);
  }
}
:root .text-400-90,
:root .fc-400-90 {
  color: rgba(var(--color-text-400-rgb), 0.9);
}
@media (hover: hover) {
  :root .text-400-90-on-hover:hover,
  :root .fc-400-90-on-hover:hover {
    color: rgba(var(--color-text-400-rgb), 0.9);
  }
  :root .hover-parent:hover .text-400-90-on-hover-parent, :root .hover-parent:hover .fc-400-90-on-hover-parent {
    color: rgba(var(--color-text-400-rgb), 0.9);
  }
}
:root .text-400-100,
:root .fc-400-100 {
  color: rgba(var(--color-text-400-rgb), 1);
}
@media (hover: hover) {
  :root .text-400-100-on-hover:hover,
  :root .fc-400-100-on-hover:hover {
    color: rgba(var(--color-text-400-rgb), 1);
  }
  :root .hover-parent:hover .text-400-100-on-hover-parent, :root .hover-parent:hover .fc-400-100-on-hover-parent {
    color: rgba(var(--color-text-400-rgb), 1);
  }
}
:root .text-400-as-bg,
:root .fc-400-as-bg {
  background-color: rgb(var(--color-text-400-rgb));
}
:root .text-400-as-border, :root .text-400-as-line,
:root .fc-400-as-border,
:root .fc-400-as-line {
  border-color: rgb(var(--color-text-400-rgb));
}

@media (hover: hover) {
  :root .text-400-on-hover:hover,
  :root .fc-400-on-hover:hover {
    color: rgb(var(--color-text-400-rgb));
  }
  :root .hover-parent:hover .text-400-on-hover-parent,
  :root .hover-parent:hover .fc-400-on-hover-parent {
    color: rgb(var(--color-text-400-rgb));
  }
}
:root .text-500,
:root .fc-500 {
  color: rgb(var(--color-text-500-rgb));
}
:root .text-500-0,
:root .fc-500-0 {
  color: rgba(var(--color-text-500-rgb), 0);
}
@media (hover: hover) {
  :root .text-500-0-on-hover:hover,
  :root .fc-500-0-on-hover:hover {
    color: rgba(var(--color-text-500-rgb), 0);
  }
  :root .hover-parent:hover .text-500-0-on-hover-parent, :root .hover-parent:hover .fc-500-0-on-hover-parent {
    color: rgba(var(--color-text-500-rgb), 0);
  }
}
:root .text-500-10,
:root .fc-500-10 {
  color: rgba(var(--color-text-500-rgb), 0.1);
}
@media (hover: hover) {
  :root .text-500-10-on-hover:hover,
  :root .fc-500-10-on-hover:hover {
    color: rgba(var(--color-text-500-rgb), 0.1);
  }
  :root .hover-parent:hover .text-500-10-on-hover-parent, :root .hover-parent:hover .fc-500-10-on-hover-parent {
    color: rgba(var(--color-text-500-rgb), 0.1);
  }
}
:root .text-500-20,
:root .fc-500-20 {
  color: rgba(var(--color-text-500-rgb), 0.2);
}
@media (hover: hover) {
  :root .text-500-20-on-hover:hover,
  :root .fc-500-20-on-hover:hover {
    color: rgba(var(--color-text-500-rgb), 0.2);
  }
  :root .hover-parent:hover .text-500-20-on-hover-parent, :root .hover-parent:hover .fc-500-20-on-hover-parent {
    color: rgba(var(--color-text-500-rgb), 0.2);
  }
}
:root .text-500-30,
:root .fc-500-30 {
  color: rgba(var(--color-text-500-rgb), 0.3);
}
@media (hover: hover) {
  :root .text-500-30-on-hover:hover,
  :root .fc-500-30-on-hover:hover {
    color: rgba(var(--color-text-500-rgb), 0.3);
  }
  :root .hover-parent:hover .text-500-30-on-hover-parent, :root .hover-parent:hover .fc-500-30-on-hover-parent {
    color: rgba(var(--color-text-500-rgb), 0.3);
  }
}
:root .text-500-40,
:root .fc-500-40 {
  color: rgba(var(--color-text-500-rgb), 0.4);
}
@media (hover: hover) {
  :root .text-500-40-on-hover:hover,
  :root .fc-500-40-on-hover:hover {
    color: rgba(var(--color-text-500-rgb), 0.4);
  }
  :root .hover-parent:hover .text-500-40-on-hover-parent, :root .hover-parent:hover .fc-500-40-on-hover-parent {
    color: rgba(var(--color-text-500-rgb), 0.4);
  }
}
:root .text-500-50,
:root .fc-500-50 {
  color: rgba(var(--color-text-500-rgb), 0.5);
}
@media (hover: hover) {
  :root .text-500-50-on-hover:hover,
  :root .fc-500-50-on-hover:hover {
    color: rgba(var(--color-text-500-rgb), 0.5);
  }
  :root .hover-parent:hover .text-500-50-on-hover-parent, :root .hover-parent:hover .fc-500-50-on-hover-parent {
    color: rgba(var(--color-text-500-rgb), 0.5);
  }
}
:root .text-500-60,
:root .fc-500-60 {
  color: rgba(var(--color-text-500-rgb), 0.6);
}
@media (hover: hover) {
  :root .text-500-60-on-hover:hover,
  :root .fc-500-60-on-hover:hover {
    color: rgba(var(--color-text-500-rgb), 0.6);
  }
  :root .hover-parent:hover .text-500-60-on-hover-parent, :root .hover-parent:hover .fc-500-60-on-hover-parent {
    color: rgba(var(--color-text-500-rgb), 0.6);
  }
}
:root .text-500-70,
:root .fc-500-70 {
  color: rgba(var(--color-text-500-rgb), 0.7);
}
@media (hover: hover) {
  :root .text-500-70-on-hover:hover,
  :root .fc-500-70-on-hover:hover {
    color: rgba(var(--color-text-500-rgb), 0.7);
  }
  :root .hover-parent:hover .text-500-70-on-hover-parent, :root .hover-parent:hover .fc-500-70-on-hover-parent {
    color: rgba(var(--color-text-500-rgb), 0.7);
  }
}
:root .text-500-80,
:root .fc-500-80 {
  color: rgba(var(--color-text-500-rgb), 0.8);
}
@media (hover: hover) {
  :root .text-500-80-on-hover:hover,
  :root .fc-500-80-on-hover:hover {
    color: rgba(var(--color-text-500-rgb), 0.8);
  }
  :root .hover-parent:hover .text-500-80-on-hover-parent, :root .hover-parent:hover .fc-500-80-on-hover-parent {
    color: rgba(var(--color-text-500-rgb), 0.8);
  }
}
:root .text-500-90,
:root .fc-500-90 {
  color: rgba(var(--color-text-500-rgb), 0.9);
}
@media (hover: hover) {
  :root .text-500-90-on-hover:hover,
  :root .fc-500-90-on-hover:hover {
    color: rgba(var(--color-text-500-rgb), 0.9);
  }
  :root .hover-parent:hover .text-500-90-on-hover-parent, :root .hover-parent:hover .fc-500-90-on-hover-parent {
    color: rgba(var(--color-text-500-rgb), 0.9);
  }
}
:root .text-500-100,
:root .fc-500-100 {
  color: rgba(var(--color-text-500-rgb), 1);
}
@media (hover: hover) {
  :root .text-500-100-on-hover:hover,
  :root .fc-500-100-on-hover:hover {
    color: rgba(var(--color-text-500-rgb), 1);
  }
  :root .hover-parent:hover .text-500-100-on-hover-parent, :root .hover-parent:hover .fc-500-100-on-hover-parent {
    color: rgba(var(--color-text-500-rgb), 1);
  }
}
:root .text-500-as-bg,
:root .fc-500-as-bg {
  background-color: rgb(var(--color-text-500-rgb));
}
:root .text-500-as-border, :root .text-500-as-line,
:root .fc-500-as-border,
:root .fc-500-as-line {
  border-color: rgb(var(--color-text-500-rgb));
}

@media (hover: hover) {
  :root .text-500-on-hover:hover,
  :root .fc-500-on-hover:hover {
    color: rgb(var(--color-text-500-rgb));
  }
  :root .hover-parent:hover .text-500-on-hover-parent,
  :root .hover-parent:hover .fc-500-on-hover-parent {
    color: rgb(var(--color-text-500-rgb));
  }
}
:root .text-800,
:root .fc-800 {
  color: rgb(var(--color-text-800-rgb));
}
:root .text-800-0,
:root .fc-800-0 {
  color: rgba(var(--color-text-800-rgb), 0);
}
@media (hover: hover) {
  :root .text-800-0-on-hover:hover,
  :root .fc-800-0-on-hover:hover {
    color: rgba(var(--color-text-800-rgb), 0);
  }
  :root .hover-parent:hover .text-800-0-on-hover-parent, :root .hover-parent:hover .fc-800-0-on-hover-parent {
    color: rgba(var(--color-text-800-rgb), 0);
  }
}
:root .text-800-10,
:root .fc-800-10 {
  color: rgba(var(--color-text-800-rgb), 0.1);
}
@media (hover: hover) {
  :root .text-800-10-on-hover:hover,
  :root .fc-800-10-on-hover:hover {
    color: rgba(var(--color-text-800-rgb), 0.1);
  }
  :root .hover-parent:hover .text-800-10-on-hover-parent, :root .hover-parent:hover .fc-800-10-on-hover-parent {
    color: rgba(var(--color-text-800-rgb), 0.1);
  }
}
:root .text-800-20,
:root .fc-800-20 {
  color: rgba(var(--color-text-800-rgb), 0.2);
}
@media (hover: hover) {
  :root .text-800-20-on-hover:hover,
  :root .fc-800-20-on-hover:hover {
    color: rgba(var(--color-text-800-rgb), 0.2);
  }
  :root .hover-parent:hover .text-800-20-on-hover-parent, :root .hover-parent:hover .fc-800-20-on-hover-parent {
    color: rgba(var(--color-text-800-rgb), 0.2);
  }
}
:root .text-800-30,
:root .fc-800-30 {
  color: rgba(var(--color-text-800-rgb), 0.3);
}
@media (hover: hover) {
  :root .text-800-30-on-hover:hover,
  :root .fc-800-30-on-hover:hover {
    color: rgba(var(--color-text-800-rgb), 0.3);
  }
  :root .hover-parent:hover .text-800-30-on-hover-parent, :root .hover-parent:hover .fc-800-30-on-hover-parent {
    color: rgba(var(--color-text-800-rgb), 0.3);
  }
}
:root .text-800-40,
:root .fc-800-40 {
  color: rgba(var(--color-text-800-rgb), 0.4);
}
@media (hover: hover) {
  :root .text-800-40-on-hover:hover,
  :root .fc-800-40-on-hover:hover {
    color: rgba(var(--color-text-800-rgb), 0.4);
  }
  :root .hover-parent:hover .text-800-40-on-hover-parent, :root .hover-parent:hover .fc-800-40-on-hover-parent {
    color: rgba(var(--color-text-800-rgb), 0.4);
  }
}
:root .text-800-50,
:root .fc-800-50 {
  color: rgba(var(--color-text-800-rgb), 0.5);
}
@media (hover: hover) {
  :root .text-800-50-on-hover:hover,
  :root .fc-800-50-on-hover:hover {
    color: rgba(var(--color-text-800-rgb), 0.5);
  }
  :root .hover-parent:hover .text-800-50-on-hover-parent, :root .hover-parent:hover .fc-800-50-on-hover-parent {
    color: rgba(var(--color-text-800-rgb), 0.5);
  }
}
:root .text-800-60,
:root .fc-800-60 {
  color: rgba(var(--color-text-800-rgb), 0.6);
}
@media (hover: hover) {
  :root .text-800-60-on-hover:hover,
  :root .fc-800-60-on-hover:hover {
    color: rgba(var(--color-text-800-rgb), 0.6);
  }
  :root .hover-parent:hover .text-800-60-on-hover-parent, :root .hover-parent:hover .fc-800-60-on-hover-parent {
    color: rgba(var(--color-text-800-rgb), 0.6);
  }
}
:root .text-800-70,
:root .fc-800-70 {
  color: rgba(var(--color-text-800-rgb), 0.7);
}
@media (hover: hover) {
  :root .text-800-70-on-hover:hover,
  :root .fc-800-70-on-hover:hover {
    color: rgba(var(--color-text-800-rgb), 0.7);
  }
  :root .hover-parent:hover .text-800-70-on-hover-parent, :root .hover-parent:hover .fc-800-70-on-hover-parent {
    color: rgba(var(--color-text-800-rgb), 0.7);
  }
}
:root .text-800-80,
:root .fc-800-80 {
  color: rgba(var(--color-text-800-rgb), 0.8);
}
@media (hover: hover) {
  :root .text-800-80-on-hover:hover,
  :root .fc-800-80-on-hover:hover {
    color: rgba(var(--color-text-800-rgb), 0.8);
  }
  :root .hover-parent:hover .text-800-80-on-hover-parent, :root .hover-parent:hover .fc-800-80-on-hover-parent {
    color: rgba(var(--color-text-800-rgb), 0.8);
  }
}
:root .text-800-90,
:root .fc-800-90 {
  color: rgba(var(--color-text-800-rgb), 0.9);
}
@media (hover: hover) {
  :root .text-800-90-on-hover:hover,
  :root .fc-800-90-on-hover:hover {
    color: rgba(var(--color-text-800-rgb), 0.9);
  }
  :root .hover-parent:hover .text-800-90-on-hover-parent, :root .hover-parent:hover .fc-800-90-on-hover-parent {
    color: rgba(var(--color-text-800-rgb), 0.9);
  }
}
:root .text-800-100,
:root .fc-800-100 {
  color: rgba(var(--color-text-800-rgb), 1);
}
@media (hover: hover) {
  :root .text-800-100-on-hover:hover,
  :root .fc-800-100-on-hover:hover {
    color: rgba(var(--color-text-800-rgb), 1);
  }
  :root .hover-parent:hover .text-800-100-on-hover-parent, :root .hover-parent:hover .fc-800-100-on-hover-parent {
    color: rgba(var(--color-text-800-rgb), 1);
  }
}
:root .text-800-as-bg,
:root .fc-800-as-bg {
  background-color: rgb(var(--color-text-800-rgb));
}
:root .text-800-as-border, :root .text-800-as-line,
:root .fc-800-as-border,
:root .fc-800-as-line {
  border-color: rgb(var(--color-text-800-rgb));
}

@media (hover: hover) {
  :root .text-800-on-hover:hover,
  :root .fc-800-on-hover:hover {
    color: rgb(var(--color-text-800-rgb));
  }
  :root .hover-parent:hover .text-800-on-hover-parent,
  :root .hover-parent:hover .fc-800-on-hover-parent {
    color: rgb(var(--color-text-800-rgb));
  }
}
:root .text-primary, :root .text-theme-primary,
:root .fc-primary,
:root .fc-theme-primary {
  color: rgb(var(--color-primary-rgb));
}
:root .text-primary-0, :root .text-theme-primary-0,
:root .fc-primary-0,
:root .fc-theme-primary-0 {
  color: rgba(var(--color-primary-rgb), 0);
}
@media (hover: hover) {
  :root .text-primary-0-on-hover:hover, :root .text-theme-primary-0-on-hover:hover,
  :root .fc-primary-0-on-hover:hover,
  :root .fc-theme-primary-0-on-hover:hover {
    color: rgba(var(--color-primary-rgb), 0);
  }
  :root .hover-parent:hover .text-primary-0-on-hover-parent, :root .hover-parent:hover .text-theme-primary-0-on-hover-parent, :root .hover-parent:hover .fc-primary-0-on-hover-parent, :root .hover-parent:hover .fc-theme-primary-0-on-hover-parent {
    color: rgba(var(--color-primary-rgb), 0);
  }
}
:root .text-primary-10, :root .text-theme-primary-10,
:root .fc-primary-10,
:root .fc-theme-primary-10 {
  color: rgba(var(--color-primary-rgb), 0.1);
}
@media (hover: hover) {
  :root .text-primary-10-on-hover:hover, :root .text-theme-primary-10-on-hover:hover,
  :root .fc-primary-10-on-hover:hover,
  :root .fc-theme-primary-10-on-hover:hover {
    color: rgba(var(--color-primary-rgb), 0.1);
  }
  :root .hover-parent:hover .text-primary-10-on-hover-parent, :root .hover-parent:hover .text-theme-primary-10-on-hover-parent, :root .hover-parent:hover .fc-primary-10-on-hover-parent, :root .hover-parent:hover .fc-theme-primary-10-on-hover-parent {
    color: rgba(var(--color-primary-rgb), 0.1);
  }
}
:root .text-primary-20, :root .text-theme-primary-20,
:root .fc-primary-20,
:root .fc-theme-primary-20 {
  color: rgba(var(--color-primary-rgb), 0.2);
}
@media (hover: hover) {
  :root .text-primary-20-on-hover:hover, :root .text-theme-primary-20-on-hover:hover,
  :root .fc-primary-20-on-hover:hover,
  :root .fc-theme-primary-20-on-hover:hover {
    color: rgba(var(--color-primary-rgb), 0.2);
  }
  :root .hover-parent:hover .text-primary-20-on-hover-parent, :root .hover-parent:hover .text-theme-primary-20-on-hover-parent, :root .hover-parent:hover .fc-primary-20-on-hover-parent, :root .hover-parent:hover .fc-theme-primary-20-on-hover-parent {
    color: rgba(var(--color-primary-rgb), 0.2);
  }
}
:root .text-primary-30, :root .text-theme-primary-30,
:root .fc-primary-30,
:root .fc-theme-primary-30 {
  color: rgba(var(--color-primary-rgb), 0.3);
}
@media (hover: hover) {
  :root .text-primary-30-on-hover:hover, :root .text-theme-primary-30-on-hover:hover,
  :root .fc-primary-30-on-hover:hover,
  :root .fc-theme-primary-30-on-hover:hover {
    color: rgba(var(--color-primary-rgb), 0.3);
  }
  :root .hover-parent:hover .text-primary-30-on-hover-parent, :root .hover-parent:hover .text-theme-primary-30-on-hover-parent, :root .hover-parent:hover .fc-primary-30-on-hover-parent, :root .hover-parent:hover .fc-theme-primary-30-on-hover-parent {
    color: rgba(var(--color-primary-rgb), 0.3);
  }
}
:root .text-primary-40, :root .text-theme-primary-40,
:root .fc-primary-40,
:root .fc-theme-primary-40 {
  color: rgba(var(--color-primary-rgb), 0.4);
}
@media (hover: hover) {
  :root .text-primary-40-on-hover:hover, :root .text-theme-primary-40-on-hover:hover,
  :root .fc-primary-40-on-hover:hover,
  :root .fc-theme-primary-40-on-hover:hover {
    color: rgba(var(--color-primary-rgb), 0.4);
  }
  :root .hover-parent:hover .text-primary-40-on-hover-parent, :root .hover-parent:hover .text-theme-primary-40-on-hover-parent, :root .hover-parent:hover .fc-primary-40-on-hover-parent, :root .hover-parent:hover .fc-theme-primary-40-on-hover-parent {
    color: rgba(var(--color-primary-rgb), 0.4);
  }
}
:root .text-primary-50, :root .text-theme-primary-50,
:root .fc-primary-50,
:root .fc-theme-primary-50 {
  color: rgba(var(--color-primary-rgb), 0.5);
}
@media (hover: hover) {
  :root .text-primary-50-on-hover:hover, :root .text-theme-primary-50-on-hover:hover,
  :root .fc-primary-50-on-hover:hover,
  :root .fc-theme-primary-50-on-hover:hover {
    color: rgba(var(--color-primary-rgb), 0.5);
  }
  :root .hover-parent:hover .text-primary-50-on-hover-parent, :root .hover-parent:hover .text-theme-primary-50-on-hover-parent, :root .hover-parent:hover .fc-primary-50-on-hover-parent, :root .hover-parent:hover .fc-theme-primary-50-on-hover-parent {
    color: rgba(var(--color-primary-rgb), 0.5);
  }
}
:root .text-primary-60, :root .text-theme-primary-60,
:root .fc-primary-60,
:root .fc-theme-primary-60 {
  color: rgba(var(--color-primary-rgb), 0.6);
}
@media (hover: hover) {
  :root .text-primary-60-on-hover:hover, :root .text-theme-primary-60-on-hover:hover,
  :root .fc-primary-60-on-hover:hover,
  :root .fc-theme-primary-60-on-hover:hover {
    color: rgba(var(--color-primary-rgb), 0.6);
  }
  :root .hover-parent:hover .text-primary-60-on-hover-parent, :root .hover-parent:hover .text-theme-primary-60-on-hover-parent, :root .hover-parent:hover .fc-primary-60-on-hover-parent, :root .hover-parent:hover .fc-theme-primary-60-on-hover-parent {
    color: rgba(var(--color-primary-rgb), 0.6);
  }
}
:root .text-primary-70, :root .text-theme-primary-70,
:root .fc-primary-70,
:root .fc-theme-primary-70 {
  color: rgba(var(--color-primary-rgb), 0.7);
}
@media (hover: hover) {
  :root .text-primary-70-on-hover:hover, :root .text-theme-primary-70-on-hover:hover,
  :root .fc-primary-70-on-hover:hover,
  :root .fc-theme-primary-70-on-hover:hover {
    color: rgba(var(--color-primary-rgb), 0.7);
  }
  :root .hover-parent:hover .text-primary-70-on-hover-parent, :root .hover-parent:hover .text-theme-primary-70-on-hover-parent, :root .hover-parent:hover .fc-primary-70-on-hover-parent, :root .hover-parent:hover .fc-theme-primary-70-on-hover-parent {
    color: rgba(var(--color-primary-rgb), 0.7);
  }
}
:root .text-primary-80, :root .text-theme-primary-80,
:root .fc-primary-80,
:root .fc-theme-primary-80 {
  color: rgba(var(--color-primary-rgb), 0.8);
}
@media (hover: hover) {
  :root .text-primary-80-on-hover:hover, :root .text-theme-primary-80-on-hover:hover,
  :root .fc-primary-80-on-hover:hover,
  :root .fc-theme-primary-80-on-hover:hover {
    color: rgba(var(--color-primary-rgb), 0.8);
  }
  :root .hover-parent:hover .text-primary-80-on-hover-parent, :root .hover-parent:hover .text-theme-primary-80-on-hover-parent, :root .hover-parent:hover .fc-primary-80-on-hover-parent, :root .hover-parent:hover .fc-theme-primary-80-on-hover-parent {
    color: rgba(var(--color-primary-rgb), 0.8);
  }
}
:root .text-primary-90, :root .text-theme-primary-90,
:root .fc-primary-90,
:root .fc-theme-primary-90 {
  color: rgba(var(--color-primary-rgb), 0.9);
}
@media (hover: hover) {
  :root .text-primary-90-on-hover:hover, :root .text-theme-primary-90-on-hover:hover,
  :root .fc-primary-90-on-hover:hover,
  :root .fc-theme-primary-90-on-hover:hover {
    color: rgba(var(--color-primary-rgb), 0.9);
  }
  :root .hover-parent:hover .text-primary-90-on-hover-parent, :root .hover-parent:hover .text-theme-primary-90-on-hover-parent, :root .hover-parent:hover .fc-primary-90-on-hover-parent, :root .hover-parent:hover .fc-theme-primary-90-on-hover-parent {
    color: rgba(var(--color-primary-rgb), 0.9);
  }
}
:root .text-primary-100, :root .text-theme-primary-100,
:root .fc-primary-100,
:root .fc-theme-primary-100 {
  color: rgba(var(--color-primary-rgb), 1);
}
@media (hover: hover) {
  :root .text-primary-100-on-hover:hover, :root .text-theme-primary-100-on-hover:hover,
  :root .fc-primary-100-on-hover:hover,
  :root .fc-theme-primary-100-on-hover:hover {
    color: rgba(var(--color-primary-rgb), 1);
  }
  :root .hover-parent:hover .text-primary-100-on-hover-parent, :root .hover-parent:hover .text-theme-primary-100-on-hover-parent, :root .hover-parent:hover .fc-primary-100-on-hover-parent, :root .hover-parent:hover .fc-theme-primary-100-on-hover-parent {
    color: rgba(var(--color-primary-rgb), 1);
  }
}

@media (hover: hover) {
  :root .text-primary-on-hover:hover, :root .text-theme-primary-on-hover:hover,
  :root .fc-primary-on-hover:hover,
  :root .fc-theme-primary-on-hover:hover {
    color: rgb(var(--color-primary-rgb));
  }
  :root .hover-parent:hover .text-primary-on-hover-parent, :root .hover-parent:hover .text-theme-primary-on-hover-parent,
  :root .hover-parent:hover .fc-primary-on-hover-parent,
  :root .hover-parent:hover .fc-theme-primary-on-hover-parent {
    color: rgb(var(--color-primary-rgb));
  }
}
:root .text-primary-dark, :root .text-theme-primary-dark,
:root .fc-primary-dark,
:root .fc-theme-primary-dark {
  color: rgb(var(--color-primary-dark-rgb));
}
:root .text-primary-dark-0, :root .text-theme-primary-dark-0,
:root .fc-primary-dark-0,
:root .fc-theme-primary-dark-0 {
  color: rgba(var(--color-primary-dark-rgb), 0);
}
@media (hover: hover) {
  :root .text-primary-dark-0-on-hover:hover, :root .text-theme-primary-dark-0-on-hover:hover,
  :root .fc-primary-dark-0-on-hover:hover,
  :root .fc-theme-primary-dark-0-on-hover:hover {
    color: rgba(var(--color-primary-dark-rgb), 0);
  }
  :root .hover-parent:hover .text-primary-dark-0-on-hover-parent, :root .hover-parent:hover .text-theme-primary-dark-0-on-hover-parent, :root .hover-parent:hover .fc-primary-dark-0-on-hover-parent, :root .hover-parent:hover .fc-theme-primary-dark-0-on-hover-parent {
    color: rgba(var(--color-primary-dark-rgb), 0);
  }
}
:root .text-primary-dark-10, :root .text-theme-primary-dark-10,
:root .fc-primary-dark-10,
:root .fc-theme-primary-dark-10 {
  color: rgba(var(--color-primary-dark-rgb), 0.1);
}
@media (hover: hover) {
  :root .text-primary-dark-10-on-hover:hover, :root .text-theme-primary-dark-10-on-hover:hover,
  :root .fc-primary-dark-10-on-hover:hover,
  :root .fc-theme-primary-dark-10-on-hover:hover {
    color: rgba(var(--color-primary-dark-rgb), 0.1);
  }
  :root .hover-parent:hover .text-primary-dark-10-on-hover-parent, :root .hover-parent:hover .text-theme-primary-dark-10-on-hover-parent, :root .hover-parent:hover .fc-primary-dark-10-on-hover-parent, :root .hover-parent:hover .fc-theme-primary-dark-10-on-hover-parent {
    color: rgba(var(--color-primary-dark-rgb), 0.1);
  }
}
:root .text-primary-dark-20, :root .text-theme-primary-dark-20,
:root .fc-primary-dark-20,
:root .fc-theme-primary-dark-20 {
  color: rgba(var(--color-primary-dark-rgb), 0.2);
}
@media (hover: hover) {
  :root .text-primary-dark-20-on-hover:hover, :root .text-theme-primary-dark-20-on-hover:hover,
  :root .fc-primary-dark-20-on-hover:hover,
  :root .fc-theme-primary-dark-20-on-hover:hover {
    color: rgba(var(--color-primary-dark-rgb), 0.2);
  }
  :root .hover-parent:hover .text-primary-dark-20-on-hover-parent, :root .hover-parent:hover .text-theme-primary-dark-20-on-hover-parent, :root .hover-parent:hover .fc-primary-dark-20-on-hover-parent, :root .hover-parent:hover .fc-theme-primary-dark-20-on-hover-parent {
    color: rgba(var(--color-primary-dark-rgb), 0.2);
  }
}
:root .text-primary-dark-30, :root .text-theme-primary-dark-30,
:root .fc-primary-dark-30,
:root .fc-theme-primary-dark-30 {
  color: rgba(var(--color-primary-dark-rgb), 0.3);
}
@media (hover: hover) {
  :root .text-primary-dark-30-on-hover:hover, :root .text-theme-primary-dark-30-on-hover:hover,
  :root .fc-primary-dark-30-on-hover:hover,
  :root .fc-theme-primary-dark-30-on-hover:hover {
    color: rgba(var(--color-primary-dark-rgb), 0.3);
  }
  :root .hover-parent:hover .text-primary-dark-30-on-hover-parent, :root .hover-parent:hover .text-theme-primary-dark-30-on-hover-parent, :root .hover-parent:hover .fc-primary-dark-30-on-hover-parent, :root .hover-parent:hover .fc-theme-primary-dark-30-on-hover-parent {
    color: rgba(var(--color-primary-dark-rgb), 0.3);
  }
}
:root .text-primary-dark-40, :root .text-theme-primary-dark-40,
:root .fc-primary-dark-40,
:root .fc-theme-primary-dark-40 {
  color: rgba(var(--color-primary-dark-rgb), 0.4);
}
@media (hover: hover) {
  :root .text-primary-dark-40-on-hover:hover, :root .text-theme-primary-dark-40-on-hover:hover,
  :root .fc-primary-dark-40-on-hover:hover,
  :root .fc-theme-primary-dark-40-on-hover:hover {
    color: rgba(var(--color-primary-dark-rgb), 0.4);
  }
  :root .hover-parent:hover .text-primary-dark-40-on-hover-parent, :root .hover-parent:hover .text-theme-primary-dark-40-on-hover-parent, :root .hover-parent:hover .fc-primary-dark-40-on-hover-parent, :root .hover-parent:hover .fc-theme-primary-dark-40-on-hover-parent {
    color: rgba(var(--color-primary-dark-rgb), 0.4);
  }
}
:root .text-primary-dark-50, :root .text-theme-primary-dark-50,
:root .fc-primary-dark-50,
:root .fc-theme-primary-dark-50 {
  color: rgba(var(--color-primary-dark-rgb), 0.5);
}
@media (hover: hover) {
  :root .text-primary-dark-50-on-hover:hover, :root .text-theme-primary-dark-50-on-hover:hover,
  :root .fc-primary-dark-50-on-hover:hover,
  :root .fc-theme-primary-dark-50-on-hover:hover {
    color: rgba(var(--color-primary-dark-rgb), 0.5);
  }
  :root .hover-parent:hover .text-primary-dark-50-on-hover-parent, :root .hover-parent:hover .text-theme-primary-dark-50-on-hover-parent, :root .hover-parent:hover .fc-primary-dark-50-on-hover-parent, :root .hover-parent:hover .fc-theme-primary-dark-50-on-hover-parent {
    color: rgba(var(--color-primary-dark-rgb), 0.5);
  }
}
:root .text-primary-dark-60, :root .text-theme-primary-dark-60,
:root .fc-primary-dark-60,
:root .fc-theme-primary-dark-60 {
  color: rgba(var(--color-primary-dark-rgb), 0.6);
}
@media (hover: hover) {
  :root .text-primary-dark-60-on-hover:hover, :root .text-theme-primary-dark-60-on-hover:hover,
  :root .fc-primary-dark-60-on-hover:hover,
  :root .fc-theme-primary-dark-60-on-hover:hover {
    color: rgba(var(--color-primary-dark-rgb), 0.6);
  }
  :root .hover-parent:hover .text-primary-dark-60-on-hover-parent, :root .hover-parent:hover .text-theme-primary-dark-60-on-hover-parent, :root .hover-parent:hover .fc-primary-dark-60-on-hover-parent, :root .hover-parent:hover .fc-theme-primary-dark-60-on-hover-parent {
    color: rgba(var(--color-primary-dark-rgb), 0.6);
  }
}
:root .text-primary-dark-70, :root .text-theme-primary-dark-70,
:root .fc-primary-dark-70,
:root .fc-theme-primary-dark-70 {
  color: rgba(var(--color-primary-dark-rgb), 0.7);
}
@media (hover: hover) {
  :root .text-primary-dark-70-on-hover:hover, :root .text-theme-primary-dark-70-on-hover:hover,
  :root .fc-primary-dark-70-on-hover:hover,
  :root .fc-theme-primary-dark-70-on-hover:hover {
    color: rgba(var(--color-primary-dark-rgb), 0.7);
  }
  :root .hover-parent:hover .text-primary-dark-70-on-hover-parent, :root .hover-parent:hover .text-theme-primary-dark-70-on-hover-parent, :root .hover-parent:hover .fc-primary-dark-70-on-hover-parent, :root .hover-parent:hover .fc-theme-primary-dark-70-on-hover-parent {
    color: rgba(var(--color-primary-dark-rgb), 0.7);
  }
}
:root .text-primary-dark-80, :root .text-theme-primary-dark-80,
:root .fc-primary-dark-80,
:root .fc-theme-primary-dark-80 {
  color: rgba(var(--color-primary-dark-rgb), 0.8);
}
@media (hover: hover) {
  :root .text-primary-dark-80-on-hover:hover, :root .text-theme-primary-dark-80-on-hover:hover,
  :root .fc-primary-dark-80-on-hover:hover,
  :root .fc-theme-primary-dark-80-on-hover:hover {
    color: rgba(var(--color-primary-dark-rgb), 0.8);
  }
  :root .hover-parent:hover .text-primary-dark-80-on-hover-parent, :root .hover-parent:hover .text-theme-primary-dark-80-on-hover-parent, :root .hover-parent:hover .fc-primary-dark-80-on-hover-parent, :root .hover-parent:hover .fc-theme-primary-dark-80-on-hover-parent {
    color: rgba(var(--color-primary-dark-rgb), 0.8);
  }
}
:root .text-primary-dark-90, :root .text-theme-primary-dark-90,
:root .fc-primary-dark-90,
:root .fc-theme-primary-dark-90 {
  color: rgba(var(--color-primary-dark-rgb), 0.9);
}
@media (hover: hover) {
  :root .text-primary-dark-90-on-hover:hover, :root .text-theme-primary-dark-90-on-hover:hover,
  :root .fc-primary-dark-90-on-hover:hover,
  :root .fc-theme-primary-dark-90-on-hover:hover {
    color: rgba(var(--color-primary-dark-rgb), 0.9);
  }
  :root .hover-parent:hover .text-primary-dark-90-on-hover-parent, :root .hover-parent:hover .text-theme-primary-dark-90-on-hover-parent, :root .hover-parent:hover .fc-primary-dark-90-on-hover-parent, :root .hover-parent:hover .fc-theme-primary-dark-90-on-hover-parent {
    color: rgba(var(--color-primary-dark-rgb), 0.9);
  }
}
:root .text-primary-dark-100, :root .text-theme-primary-dark-100,
:root .fc-primary-dark-100,
:root .fc-theme-primary-dark-100 {
  color: rgba(var(--color-primary-dark-rgb), 1);
}
@media (hover: hover) {
  :root .text-primary-dark-100-on-hover:hover, :root .text-theme-primary-dark-100-on-hover:hover,
  :root .fc-primary-dark-100-on-hover:hover,
  :root .fc-theme-primary-dark-100-on-hover:hover {
    color: rgba(var(--color-primary-dark-rgb), 1);
  }
  :root .hover-parent:hover .text-primary-dark-100-on-hover-parent, :root .hover-parent:hover .text-theme-primary-dark-100-on-hover-parent, :root .hover-parent:hover .fc-primary-dark-100-on-hover-parent, :root .hover-parent:hover .fc-theme-primary-dark-100-on-hover-parent {
    color: rgba(var(--color-primary-dark-rgb), 1);
  }
}

@media (hover: hover) {
  :root .text-primary-dark-on-hover:hover, :root .text-theme-primary-dark-on-hover:hover,
  :root .fc-primary-dark-on-hover:hover,
  :root .fc-theme-primary-dark-on-hover:hover {
    color: rgb(var(--color-primary-dark-rgb));
  }
  :root .hover-parent:hover .text-primary-dark-on-hover-parent, :root .hover-parent:hover .text-theme-primary-dark-on-hover-parent,
  :root .hover-parent:hover .fc-primary-dark-on-hover-parent,
  :root .hover-parent:hover .fc-theme-primary-dark-on-hover-parent {
    color: rgb(var(--color-primary-dark-rgb));
  }
}
:root .text-primary-500, :root .text-theme-primary-500,
:root .fc-primary-500,
:root .fc-theme-primary-500 {
  color: rgb(var(--color-primary-500-rgb));
}
:root .text-primary-500-0, :root .text-theme-primary-500-0,
:root .fc-primary-500-0,
:root .fc-theme-primary-500-0 {
  color: rgba(var(--color-primary-500-rgb), 0);
}
@media (hover: hover) {
  :root .text-primary-500-0-on-hover:hover, :root .text-theme-primary-500-0-on-hover:hover,
  :root .fc-primary-500-0-on-hover:hover,
  :root .fc-theme-primary-500-0-on-hover:hover {
    color: rgba(var(--color-primary-500-rgb), 0);
  }
  :root .hover-parent:hover .text-primary-500-0-on-hover-parent, :root .hover-parent:hover .text-theme-primary-500-0-on-hover-parent, :root .hover-parent:hover .fc-primary-500-0-on-hover-parent, :root .hover-parent:hover .fc-theme-primary-500-0-on-hover-parent {
    color: rgba(var(--color-primary-500-rgb), 0);
  }
}
:root .text-primary-500-10, :root .text-theme-primary-500-10,
:root .fc-primary-500-10,
:root .fc-theme-primary-500-10 {
  color: rgba(var(--color-primary-500-rgb), 0.1);
}
@media (hover: hover) {
  :root .text-primary-500-10-on-hover:hover, :root .text-theme-primary-500-10-on-hover:hover,
  :root .fc-primary-500-10-on-hover:hover,
  :root .fc-theme-primary-500-10-on-hover:hover {
    color: rgba(var(--color-primary-500-rgb), 0.1);
  }
  :root .hover-parent:hover .text-primary-500-10-on-hover-parent, :root .hover-parent:hover .text-theme-primary-500-10-on-hover-parent, :root .hover-parent:hover .fc-primary-500-10-on-hover-parent, :root .hover-parent:hover .fc-theme-primary-500-10-on-hover-parent {
    color: rgba(var(--color-primary-500-rgb), 0.1);
  }
}
:root .text-primary-500-20, :root .text-theme-primary-500-20,
:root .fc-primary-500-20,
:root .fc-theme-primary-500-20 {
  color: rgba(var(--color-primary-500-rgb), 0.2);
}
@media (hover: hover) {
  :root .text-primary-500-20-on-hover:hover, :root .text-theme-primary-500-20-on-hover:hover,
  :root .fc-primary-500-20-on-hover:hover,
  :root .fc-theme-primary-500-20-on-hover:hover {
    color: rgba(var(--color-primary-500-rgb), 0.2);
  }
  :root .hover-parent:hover .text-primary-500-20-on-hover-parent, :root .hover-parent:hover .text-theme-primary-500-20-on-hover-parent, :root .hover-parent:hover .fc-primary-500-20-on-hover-parent, :root .hover-parent:hover .fc-theme-primary-500-20-on-hover-parent {
    color: rgba(var(--color-primary-500-rgb), 0.2);
  }
}
:root .text-primary-500-30, :root .text-theme-primary-500-30,
:root .fc-primary-500-30,
:root .fc-theme-primary-500-30 {
  color: rgba(var(--color-primary-500-rgb), 0.3);
}
@media (hover: hover) {
  :root .text-primary-500-30-on-hover:hover, :root .text-theme-primary-500-30-on-hover:hover,
  :root .fc-primary-500-30-on-hover:hover,
  :root .fc-theme-primary-500-30-on-hover:hover {
    color: rgba(var(--color-primary-500-rgb), 0.3);
  }
  :root .hover-parent:hover .text-primary-500-30-on-hover-parent, :root .hover-parent:hover .text-theme-primary-500-30-on-hover-parent, :root .hover-parent:hover .fc-primary-500-30-on-hover-parent, :root .hover-parent:hover .fc-theme-primary-500-30-on-hover-parent {
    color: rgba(var(--color-primary-500-rgb), 0.3);
  }
}
:root .text-primary-500-40, :root .text-theme-primary-500-40,
:root .fc-primary-500-40,
:root .fc-theme-primary-500-40 {
  color: rgba(var(--color-primary-500-rgb), 0.4);
}
@media (hover: hover) {
  :root .text-primary-500-40-on-hover:hover, :root .text-theme-primary-500-40-on-hover:hover,
  :root .fc-primary-500-40-on-hover:hover,
  :root .fc-theme-primary-500-40-on-hover:hover {
    color: rgba(var(--color-primary-500-rgb), 0.4);
  }
  :root .hover-parent:hover .text-primary-500-40-on-hover-parent, :root .hover-parent:hover .text-theme-primary-500-40-on-hover-parent, :root .hover-parent:hover .fc-primary-500-40-on-hover-parent, :root .hover-parent:hover .fc-theme-primary-500-40-on-hover-parent {
    color: rgba(var(--color-primary-500-rgb), 0.4);
  }
}
:root .text-primary-500-50, :root .text-theme-primary-500-50,
:root .fc-primary-500-50,
:root .fc-theme-primary-500-50 {
  color: rgba(var(--color-primary-500-rgb), 0.5);
}
@media (hover: hover) {
  :root .text-primary-500-50-on-hover:hover, :root .text-theme-primary-500-50-on-hover:hover,
  :root .fc-primary-500-50-on-hover:hover,
  :root .fc-theme-primary-500-50-on-hover:hover {
    color: rgba(var(--color-primary-500-rgb), 0.5);
  }
  :root .hover-parent:hover .text-primary-500-50-on-hover-parent, :root .hover-parent:hover .text-theme-primary-500-50-on-hover-parent, :root .hover-parent:hover .fc-primary-500-50-on-hover-parent, :root .hover-parent:hover .fc-theme-primary-500-50-on-hover-parent {
    color: rgba(var(--color-primary-500-rgb), 0.5);
  }
}
:root .text-primary-500-60, :root .text-theme-primary-500-60,
:root .fc-primary-500-60,
:root .fc-theme-primary-500-60 {
  color: rgba(var(--color-primary-500-rgb), 0.6);
}
@media (hover: hover) {
  :root .text-primary-500-60-on-hover:hover, :root .text-theme-primary-500-60-on-hover:hover,
  :root .fc-primary-500-60-on-hover:hover,
  :root .fc-theme-primary-500-60-on-hover:hover {
    color: rgba(var(--color-primary-500-rgb), 0.6);
  }
  :root .hover-parent:hover .text-primary-500-60-on-hover-parent, :root .hover-parent:hover .text-theme-primary-500-60-on-hover-parent, :root .hover-parent:hover .fc-primary-500-60-on-hover-parent, :root .hover-parent:hover .fc-theme-primary-500-60-on-hover-parent {
    color: rgba(var(--color-primary-500-rgb), 0.6);
  }
}
:root .text-primary-500-70, :root .text-theme-primary-500-70,
:root .fc-primary-500-70,
:root .fc-theme-primary-500-70 {
  color: rgba(var(--color-primary-500-rgb), 0.7);
}
@media (hover: hover) {
  :root .text-primary-500-70-on-hover:hover, :root .text-theme-primary-500-70-on-hover:hover,
  :root .fc-primary-500-70-on-hover:hover,
  :root .fc-theme-primary-500-70-on-hover:hover {
    color: rgba(var(--color-primary-500-rgb), 0.7);
  }
  :root .hover-parent:hover .text-primary-500-70-on-hover-parent, :root .hover-parent:hover .text-theme-primary-500-70-on-hover-parent, :root .hover-parent:hover .fc-primary-500-70-on-hover-parent, :root .hover-parent:hover .fc-theme-primary-500-70-on-hover-parent {
    color: rgba(var(--color-primary-500-rgb), 0.7);
  }
}
:root .text-primary-500-80, :root .text-theme-primary-500-80,
:root .fc-primary-500-80,
:root .fc-theme-primary-500-80 {
  color: rgba(var(--color-primary-500-rgb), 0.8);
}
@media (hover: hover) {
  :root .text-primary-500-80-on-hover:hover, :root .text-theme-primary-500-80-on-hover:hover,
  :root .fc-primary-500-80-on-hover:hover,
  :root .fc-theme-primary-500-80-on-hover:hover {
    color: rgba(var(--color-primary-500-rgb), 0.8);
  }
  :root .hover-parent:hover .text-primary-500-80-on-hover-parent, :root .hover-parent:hover .text-theme-primary-500-80-on-hover-parent, :root .hover-parent:hover .fc-primary-500-80-on-hover-parent, :root .hover-parent:hover .fc-theme-primary-500-80-on-hover-parent {
    color: rgba(var(--color-primary-500-rgb), 0.8);
  }
}
:root .text-primary-500-90, :root .text-theme-primary-500-90,
:root .fc-primary-500-90,
:root .fc-theme-primary-500-90 {
  color: rgba(var(--color-primary-500-rgb), 0.9);
}
@media (hover: hover) {
  :root .text-primary-500-90-on-hover:hover, :root .text-theme-primary-500-90-on-hover:hover,
  :root .fc-primary-500-90-on-hover:hover,
  :root .fc-theme-primary-500-90-on-hover:hover {
    color: rgba(var(--color-primary-500-rgb), 0.9);
  }
  :root .hover-parent:hover .text-primary-500-90-on-hover-parent, :root .hover-parent:hover .text-theme-primary-500-90-on-hover-parent, :root .hover-parent:hover .fc-primary-500-90-on-hover-parent, :root .hover-parent:hover .fc-theme-primary-500-90-on-hover-parent {
    color: rgba(var(--color-primary-500-rgb), 0.9);
  }
}
:root .text-primary-500-100, :root .text-theme-primary-500-100,
:root .fc-primary-500-100,
:root .fc-theme-primary-500-100 {
  color: rgba(var(--color-primary-500-rgb), 1);
}
@media (hover: hover) {
  :root .text-primary-500-100-on-hover:hover, :root .text-theme-primary-500-100-on-hover:hover,
  :root .fc-primary-500-100-on-hover:hover,
  :root .fc-theme-primary-500-100-on-hover:hover {
    color: rgba(var(--color-primary-500-rgb), 1);
  }
  :root .hover-parent:hover .text-primary-500-100-on-hover-parent, :root .hover-parent:hover .text-theme-primary-500-100-on-hover-parent, :root .hover-parent:hover .fc-primary-500-100-on-hover-parent, :root .hover-parent:hover .fc-theme-primary-500-100-on-hover-parent {
    color: rgba(var(--color-primary-500-rgb), 1);
  }
}

@media (hover: hover) {
  :root .text-primary-500-on-hover:hover, :root .text-theme-primary-500-on-hover:hover,
  :root .fc-primary-500-on-hover:hover,
  :root .fc-theme-primary-500-on-hover:hover {
    color: rgb(var(--color-primary-500-rgb));
  }
  :root .hover-parent:hover .text-primary-500-on-hover-parent, :root .hover-parent:hover .text-theme-primary-500-on-hover-parent,
  :root .hover-parent:hover .fc-primary-500-on-hover-parent,
  :root .hover-parent:hover .fc-theme-primary-500-on-hover-parent {
    color: rgb(var(--color-primary-500-rgb));
  }
}
:root .text-primary-700, :root .text-theme-primary-700,
:root .fc-primary-700,
:root .fc-theme-primary-700 {
  color: rgb(var(--color-primary-700-rgb));
}
:root .text-primary-700-0, :root .text-theme-primary-700-0,
:root .fc-primary-700-0,
:root .fc-theme-primary-700-0 {
  color: rgba(var(--color-primary-700-rgb), 0);
}
@media (hover: hover) {
  :root .text-primary-700-0-on-hover:hover, :root .text-theme-primary-700-0-on-hover:hover,
  :root .fc-primary-700-0-on-hover:hover,
  :root .fc-theme-primary-700-0-on-hover:hover {
    color: rgba(var(--color-primary-700-rgb), 0);
  }
  :root .hover-parent:hover .text-primary-700-0-on-hover-parent, :root .hover-parent:hover .text-theme-primary-700-0-on-hover-parent, :root .hover-parent:hover .fc-primary-700-0-on-hover-parent, :root .hover-parent:hover .fc-theme-primary-700-0-on-hover-parent {
    color: rgba(var(--color-primary-700-rgb), 0);
  }
}
:root .text-primary-700-10, :root .text-theme-primary-700-10,
:root .fc-primary-700-10,
:root .fc-theme-primary-700-10 {
  color: rgba(var(--color-primary-700-rgb), 0.1);
}
@media (hover: hover) {
  :root .text-primary-700-10-on-hover:hover, :root .text-theme-primary-700-10-on-hover:hover,
  :root .fc-primary-700-10-on-hover:hover,
  :root .fc-theme-primary-700-10-on-hover:hover {
    color: rgba(var(--color-primary-700-rgb), 0.1);
  }
  :root .hover-parent:hover .text-primary-700-10-on-hover-parent, :root .hover-parent:hover .text-theme-primary-700-10-on-hover-parent, :root .hover-parent:hover .fc-primary-700-10-on-hover-parent, :root .hover-parent:hover .fc-theme-primary-700-10-on-hover-parent {
    color: rgba(var(--color-primary-700-rgb), 0.1);
  }
}
:root .text-primary-700-20, :root .text-theme-primary-700-20,
:root .fc-primary-700-20,
:root .fc-theme-primary-700-20 {
  color: rgba(var(--color-primary-700-rgb), 0.2);
}
@media (hover: hover) {
  :root .text-primary-700-20-on-hover:hover, :root .text-theme-primary-700-20-on-hover:hover,
  :root .fc-primary-700-20-on-hover:hover,
  :root .fc-theme-primary-700-20-on-hover:hover {
    color: rgba(var(--color-primary-700-rgb), 0.2);
  }
  :root .hover-parent:hover .text-primary-700-20-on-hover-parent, :root .hover-parent:hover .text-theme-primary-700-20-on-hover-parent, :root .hover-parent:hover .fc-primary-700-20-on-hover-parent, :root .hover-parent:hover .fc-theme-primary-700-20-on-hover-parent {
    color: rgba(var(--color-primary-700-rgb), 0.2);
  }
}
:root .text-primary-700-30, :root .text-theme-primary-700-30,
:root .fc-primary-700-30,
:root .fc-theme-primary-700-30 {
  color: rgba(var(--color-primary-700-rgb), 0.3);
}
@media (hover: hover) {
  :root .text-primary-700-30-on-hover:hover, :root .text-theme-primary-700-30-on-hover:hover,
  :root .fc-primary-700-30-on-hover:hover,
  :root .fc-theme-primary-700-30-on-hover:hover {
    color: rgba(var(--color-primary-700-rgb), 0.3);
  }
  :root .hover-parent:hover .text-primary-700-30-on-hover-parent, :root .hover-parent:hover .text-theme-primary-700-30-on-hover-parent, :root .hover-parent:hover .fc-primary-700-30-on-hover-parent, :root .hover-parent:hover .fc-theme-primary-700-30-on-hover-parent {
    color: rgba(var(--color-primary-700-rgb), 0.3);
  }
}
:root .text-primary-700-40, :root .text-theme-primary-700-40,
:root .fc-primary-700-40,
:root .fc-theme-primary-700-40 {
  color: rgba(var(--color-primary-700-rgb), 0.4);
}
@media (hover: hover) {
  :root .text-primary-700-40-on-hover:hover, :root .text-theme-primary-700-40-on-hover:hover,
  :root .fc-primary-700-40-on-hover:hover,
  :root .fc-theme-primary-700-40-on-hover:hover {
    color: rgba(var(--color-primary-700-rgb), 0.4);
  }
  :root .hover-parent:hover .text-primary-700-40-on-hover-parent, :root .hover-parent:hover .text-theme-primary-700-40-on-hover-parent, :root .hover-parent:hover .fc-primary-700-40-on-hover-parent, :root .hover-parent:hover .fc-theme-primary-700-40-on-hover-parent {
    color: rgba(var(--color-primary-700-rgb), 0.4);
  }
}
:root .text-primary-700-50, :root .text-theme-primary-700-50,
:root .fc-primary-700-50,
:root .fc-theme-primary-700-50 {
  color: rgba(var(--color-primary-700-rgb), 0.5);
}
@media (hover: hover) {
  :root .text-primary-700-50-on-hover:hover, :root .text-theme-primary-700-50-on-hover:hover,
  :root .fc-primary-700-50-on-hover:hover,
  :root .fc-theme-primary-700-50-on-hover:hover {
    color: rgba(var(--color-primary-700-rgb), 0.5);
  }
  :root .hover-parent:hover .text-primary-700-50-on-hover-parent, :root .hover-parent:hover .text-theme-primary-700-50-on-hover-parent, :root .hover-parent:hover .fc-primary-700-50-on-hover-parent, :root .hover-parent:hover .fc-theme-primary-700-50-on-hover-parent {
    color: rgba(var(--color-primary-700-rgb), 0.5);
  }
}
:root .text-primary-700-60, :root .text-theme-primary-700-60,
:root .fc-primary-700-60,
:root .fc-theme-primary-700-60 {
  color: rgba(var(--color-primary-700-rgb), 0.6);
}
@media (hover: hover) {
  :root .text-primary-700-60-on-hover:hover, :root .text-theme-primary-700-60-on-hover:hover,
  :root .fc-primary-700-60-on-hover:hover,
  :root .fc-theme-primary-700-60-on-hover:hover {
    color: rgba(var(--color-primary-700-rgb), 0.6);
  }
  :root .hover-parent:hover .text-primary-700-60-on-hover-parent, :root .hover-parent:hover .text-theme-primary-700-60-on-hover-parent, :root .hover-parent:hover .fc-primary-700-60-on-hover-parent, :root .hover-parent:hover .fc-theme-primary-700-60-on-hover-parent {
    color: rgba(var(--color-primary-700-rgb), 0.6);
  }
}
:root .text-primary-700-70, :root .text-theme-primary-700-70,
:root .fc-primary-700-70,
:root .fc-theme-primary-700-70 {
  color: rgba(var(--color-primary-700-rgb), 0.7);
}
@media (hover: hover) {
  :root .text-primary-700-70-on-hover:hover, :root .text-theme-primary-700-70-on-hover:hover,
  :root .fc-primary-700-70-on-hover:hover,
  :root .fc-theme-primary-700-70-on-hover:hover {
    color: rgba(var(--color-primary-700-rgb), 0.7);
  }
  :root .hover-parent:hover .text-primary-700-70-on-hover-parent, :root .hover-parent:hover .text-theme-primary-700-70-on-hover-parent, :root .hover-parent:hover .fc-primary-700-70-on-hover-parent, :root .hover-parent:hover .fc-theme-primary-700-70-on-hover-parent {
    color: rgba(var(--color-primary-700-rgb), 0.7);
  }
}
:root .text-primary-700-80, :root .text-theme-primary-700-80,
:root .fc-primary-700-80,
:root .fc-theme-primary-700-80 {
  color: rgba(var(--color-primary-700-rgb), 0.8);
}
@media (hover: hover) {
  :root .text-primary-700-80-on-hover:hover, :root .text-theme-primary-700-80-on-hover:hover,
  :root .fc-primary-700-80-on-hover:hover,
  :root .fc-theme-primary-700-80-on-hover:hover {
    color: rgba(var(--color-primary-700-rgb), 0.8);
  }
  :root .hover-parent:hover .text-primary-700-80-on-hover-parent, :root .hover-parent:hover .text-theme-primary-700-80-on-hover-parent, :root .hover-parent:hover .fc-primary-700-80-on-hover-parent, :root .hover-parent:hover .fc-theme-primary-700-80-on-hover-parent {
    color: rgba(var(--color-primary-700-rgb), 0.8);
  }
}
:root .text-primary-700-90, :root .text-theme-primary-700-90,
:root .fc-primary-700-90,
:root .fc-theme-primary-700-90 {
  color: rgba(var(--color-primary-700-rgb), 0.9);
}
@media (hover: hover) {
  :root .text-primary-700-90-on-hover:hover, :root .text-theme-primary-700-90-on-hover:hover,
  :root .fc-primary-700-90-on-hover:hover,
  :root .fc-theme-primary-700-90-on-hover:hover {
    color: rgba(var(--color-primary-700-rgb), 0.9);
  }
  :root .hover-parent:hover .text-primary-700-90-on-hover-parent, :root .hover-parent:hover .text-theme-primary-700-90-on-hover-parent, :root .hover-parent:hover .fc-primary-700-90-on-hover-parent, :root .hover-parent:hover .fc-theme-primary-700-90-on-hover-parent {
    color: rgba(var(--color-primary-700-rgb), 0.9);
  }
}
:root .text-primary-700-100, :root .text-theme-primary-700-100,
:root .fc-primary-700-100,
:root .fc-theme-primary-700-100 {
  color: rgba(var(--color-primary-700-rgb), 1);
}
@media (hover: hover) {
  :root .text-primary-700-100-on-hover:hover, :root .text-theme-primary-700-100-on-hover:hover,
  :root .fc-primary-700-100-on-hover:hover,
  :root .fc-theme-primary-700-100-on-hover:hover {
    color: rgba(var(--color-primary-700-rgb), 1);
  }
  :root .hover-parent:hover .text-primary-700-100-on-hover-parent, :root .hover-parent:hover .text-theme-primary-700-100-on-hover-parent, :root .hover-parent:hover .fc-primary-700-100-on-hover-parent, :root .hover-parent:hover .fc-theme-primary-700-100-on-hover-parent {
    color: rgba(var(--color-primary-700-rgb), 1);
  }
}

@media (hover: hover) {
  :root .text-primary-700-on-hover:hover, :root .text-theme-primary-700-on-hover:hover,
  :root .fc-primary-700-on-hover:hover,
  :root .fc-theme-primary-700-on-hover:hover {
    color: rgb(var(--color-primary-700-rgb));
  }
  :root .hover-parent:hover .text-primary-700-on-hover-parent, :root .hover-parent:hover .text-theme-primary-700-on-hover-parent,
  :root .hover-parent:hover .fc-primary-700-on-hover-parent,
  :root .hover-parent:hover .fc-theme-primary-700-on-hover-parent {
    color: rgb(var(--color-primary-700-rgb));
  }
}
:root .text-warn, :root .text-theme-warn,
:root .fc-warn,
:root .fc-theme-warn {
  color: rgb(var(--color-warn-rgb));
}
:root .text-warn-0, :root .text-theme-warn-0,
:root .fc-warn-0,
:root .fc-theme-warn-0 {
  color: rgba(var(--color-warn-rgb), 0);
}
@media (hover: hover) {
  :root .text-warn-0-on-hover:hover, :root .text-theme-warn-0-on-hover:hover,
  :root .fc-warn-0-on-hover:hover,
  :root .fc-theme-warn-0-on-hover:hover {
    color: rgba(var(--color-warn-rgb), 0);
  }
  :root .hover-parent:hover .text-warn-0-on-hover-parent, :root .hover-parent:hover .text-theme-warn-0-on-hover-parent, :root .hover-parent:hover .fc-warn-0-on-hover-parent, :root .hover-parent:hover .fc-theme-warn-0-on-hover-parent {
    color: rgba(var(--color-warn-rgb), 0);
  }
}
:root .text-warn-10, :root .text-theme-warn-10,
:root .fc-warn-10,
:root .fc-theme-warn-10 {
  color: rgba(var(--color-warn-rgb), 0.1);
}
@media (hover: hover) {
  :root .text-warn-10-on-hover:hover, :root .text-theme-warn-10-on-hover:hover,
  :root .fc-warn-10-on-hover:hover,
  :root .fc-theme-warn-10-on-hover:hover {
    color: rgba(var(--color-warn-rgb), 0.1);
  }
  :root .hover-parent:hover .text-warn-10-on-hover-parent, :root .hover-parent:hover .text-theme-warn-10-on-hover-parent, :root .hover-parent:hover .fc-warn-10-on-hover-parent, :root .hover-parent:hover .fc-theme-warn-10-on-hover-parent {
    color: rgba(var(--color-warn-rgb), 0.1);
  }
}
:root .text-warn-20, :root .text-theme-warn-20,
:root .fc-warn-20,
:root .fc-theme-warn-20 {
  color: rgba(var(--color-warn-rgb), 0.2);
}
@media (hover: hover) {
  :root .text-warn-20-on-hover:hover, :root .text-theme-warn-20-on-hover:hover,
  :root .fc-warn-20-on-hover:hover,
  :root .fc-theme-warn-20-on-hover:hover {
    color: rgba(var(--color-warn-rgb), 0.2);
  }
  :root .hover-parent:hover .text-warn-20-on-hover-parent, :root .hover-parent:hover .text-theme-warn-20-on-hover-parent, :root .hover-parent:hover .fc-warn-20-on-hover-parent, :root .hover-parent:hover .fc-theme-warn-20-on-hover-parent {
    color: rgba(var(--color-warn-rgb), 0.2);
  }
}
:root .text-warn-30, :root .text-theme-warn-30,
:root .fc-warn-30,
:root .fc-theme-warn-30 {
  color: rgba(var(--color-warn-rgb), 0.3);
}
@media (hover: hover) {
  :root .text-warn-30-on-hover:hover, :root .text-theme-warn-30-on-hover:hover,
  :root .fc-warn-30-on-hover:hover,
  :root .fc-theme-warn-30-on-hover:hover {
    color: rgba(var(--color-warn-rgb), 0.3);
  }
  :root .hover-parent:hover .text-warn-30-on-hover-parent, :root .hover-parent:hover .text-theme-warn-30-on-hover-parent, :root .hover-parent:hover .fc-warn-30-on-hover-parent, :root .hover-parent:hover .fc-theme-warn-30-on-hover-parent {
    color: rgba(var(--color-warn-rgb), 0.3);
  }
}
:root .text-warn-40, :root .text-theme-warn-40,
:root .fc-warn-40,
:root .fc-theme-warn-40 {
  color: rgba(var(--color-warn-rgb), 0.4);
}
@media (hover: hover) {
  :root .text-warn-40-on-hover:hover, :root .text-theme-warn-40-on-hover:hover,
  :root .fc-warn-40-on-hover:hover,
  :root .fc-theme-warn-40-on-hover:hover {
    color: rgba(var(--color-warn-rgb), 0.4);
  }
  :root .hover-parent:hover .text-warn-40-on-hover-parent, :root .hover-parent:hover .text-theme-warn-40-on-hover-parent, :root .hover-parent:hover .fc-warn-40-on-hover-parent, :root .hover-parent:hover .fc-theme-warn-40-on-hover-parent {
    color: rgba(var(--color-warn-rgb), 0.4);
  }
}
:root .text-warn-50, :root .text-theme-warn-50,
:root .fc-warn-50,
:root .fc-theme-warn-50 {
  color: rgba(var(--color-warn-rgb), 0.5);
}
@media (hover: hover) {
  :root .text-warn-50-on-hover:hover, :root .text-theme-warn-50-on-hover:hover,
  :root .fc-warn-50-on-hover:hover,
  :root .fc-theme-warn-50-on-hover:hover {
    color: rgba(var(--color-warn-rgb), 0.5);
  }
  :root .hover-parent:hover .text-warn-50-on-hover-parent, :root .hover-parent:hover .text-theme-warn-50-on-hover-parent, :root .hover-parent:hover .fc-warn-50-on-hover-parent, :root .hover-parent:hover .fc-theme-warn-50-on-hover-parent {
    color: rgba(var(--color-warn-rgb), 0.5);
  }
}
:root .text-warn-60, :root .text-theme-warn-60,
:root .fc-warn-60,
:root .fc-theme-warn-60 {
  color: rgba(var(--color-warn-rgb), 0.6);
}
@media (hover: hover) {
  :root .text-warn-60-on-hover:hover, :root .text-theme-warn-60-on-hover:hover,
  :root .fc-warn-60-on-hover:hover,
  :root .fc-theme-warn-60-on-hover:hover {
    color: rgba(var(--color-warn-rgb), 0.6);
  }
  :root .hover-parent:hover .text-warn-60-on-hover-parent, :root .hover-parent:hover .text-theme-warn-60-on-hover-parent, :root .hover-parent:hover .fc-warn-60-on-hover-parent, :root .hover-parent:hover .fc-theme-warn-60-on-hover-parent {
    color: rgba(var(--color-warn-rgb), 0.6);
  }
}
:root .text-warn-70, :root .text-theme-warn-70,
:root .fc-warn-70,
:root .fc-theme-warn-70 {
  color: rgba(var(--color-warn-rgb), 0.7);
}
@media (hover: hover) {
  :root .text-warn-70-on-hover:hover, :root .text-theme-warn-70-on-hover:hover,
  :root .fc-warn-70-on-hover:hover,
  :root .fc-theme-warn-70-on-hover:hover {
    color: rgba(var(--color-warn-rgb), 0.7);
  }
  :root .hover-parent:hover .text-warn-70-on-hover-parent, :root .hover-parent:hover .text-theme-warn-70-on-hover-parent, :root .hover-parent:hover .fc-warn-70-on-hover-parent, :root .hover-parent:hover .fc-theme-warn-70-on-hover-parent {
    color: rgba(var(--color-warn-rgb), 0.7);
  }
}
:root .text-warn-80, :root .text-theme-warn-80,
:root .fc-warn-80,
:root .fc-theme-warn-80 {
  color: rgba(var(--color-warn-rgb), 0.8);
}
@media (hover: hover) {
  :root .text-warn-80-on-hover:hover, :root .text-theme-warn-80-on-hover:hover,
  :root .fc-warn-80-on-hover:hover,
  :root .fc-theme-warn-80-on-hover:hover {
    color: rgba(var(--color-warn-rgb), 0.8);
  }
  :root .hover-parent:hover .text-warn-80-on-hover-parent, :root .hover-parent:hover .text-theme-warn-80-on-hover-parent, :root .hover-parent:hover .fc-warn-80-on-hover-parent, :root .hover-parent:hover .fc-theme-warn-80-on-hover-parent {
    color: rgba(var(--color-warn-rgb), 0.8);
  }
}
:root .text-warn-90, :root .text-theme-warn-90,
:root .fc-warn-90,
:root .fc-theme-warn-90 {
  color: rgba(var(--color-warn-rgb), 0.9);
}
@media (hover: hover) {
  :root .text-warn-90-on-hover:hover, :root .text-theme-warn-90-on-hover:hover,
  :root .fc-warn-90-on-hover:hover,
  :root .fc-theme-warn-90-on-hover:hover {
    color: rgba(var(--color-warn-rgb), 0.9);
  }
  :root .hover-parent:hover .text-warn-90-on-hover-parent, :root .hover-parent:hover .text-theme-warn-90-on-hover-parent, :root .hover-parent:hover .fc-warn-90-on-hover-parent, :root .hover-parent:hover .fc-theme-warn-90-on-hover-parent {
    color: rgba(var(--color-warn-rgb), 0.9);
  }
}
:root .text-warn-100, :root .text-theme-warn-100,
:root .fc-warn-100,
:root .fc-theme-warn-100 {
  color: rgba(var(--color-warn-rgb), 1);
}
@media (hover: hover) {
  :root .text-warn-100-on-hover:hover, :root .text-theme-warn-100-on-hover:hover,
  :root .fc-warn-100-on-hover:hover,
  :root .fc-theme-warn-100-on-hover:hover {
    color: rgba(var(--color-warn-rgb), 1);
  }
  :root .hover-parent:hover .text-warn-100-on-hover-parent, :root .hover-parent:hover .text-theme-warn-100-on-hover-parent, :root .hover-parent:hover .fc-warn-100-on-hover-parent, :root .hover-parent:hover .fc-theme-warn-100-on-hover-parent {
    color: rgba(var(--color-warn-rgb), 1);
  }
}

@media (hover: hover) {
  :root .text-warn-on-hover:hover, :root .text-theme-warn-on-hover:hover,
  :root .fc-warn-on-hover:hover,
  :root .fc-theme-warn-on-hover:hover {
    color: rgb(var(--color-warn-rgb));
  }
  :root .hover-parent:hover .text-warn-on-hover-parent, :root .hover-parent:hover .text-theme-warn-on-hover-parent,
  :root .hover-parent:hover .fc-warn-on-hover-parent,
  :root .hover-parent:hover .fc-theme-warn-on-hover-parent {
    color: rgb(var(--color-warn-rgb));
  }
}
:root .text-warn-dark, :root .text-theme-warn-dark,
:root .fc-warn-dark,
:root .fc-theme-warn-dark {
  color: rgb(var(--color-warn-dark-rgb));
}
:root .text-warn-dark-0, :root .text-theme-warn-dark-0,
:root .fc-warn-dark-0,
:root .fc-theme-warn-dark-0 {
  color: rgba(var(--color-warn-dark-rgb), 0);
}
@media (hover: hover) {
  :root .text-warn-dark-0-on-hover:hover, :root .text-theme-warn-dark-0-on-hover:hover,
  :root .fc-warn-dark-0-on-hover:hover,
  :root .fc-theme-warn-dark-0-on-hover:hover {
    color: rgba(var(--color-warn-dark-rgb), 0);
  }
  :root .hover-parent:hover .text-warn-dark-0-on-hover-parent, :root .hover-parent:hover .text-theme-warn-dark-0-on-hover-parent, :root .hover-parent:hover .fc-warn-dark-0-on-hover-parent, :root .hover-parent:hover .fc-theme-warn-dark-0-on-hover-parent {
    color: rgba(var(--color-warn-dark-rgb), 0);
  }
}
:root .text-warn-dark-10, :root .text-theme-warn-dark-10,
:root .fc-warn-dark-10,
:root .fc-theme-warn-dark-10 {
  color: rgba(var(--color-warn-dark-rgb), 0.1);
}
@media (hover: hover) {
  :root .text-warn-dark-10-on-hover:hover, :root .text-theme-warn-dark-10-on-hover:hover,
  :root .fc-warn-dark-10-on-hover:hover,
  :root .fc-theme-warn-dark-10-on-hover:hover {
    color: rgba(var(--color-warn-dark-rgb), 0.1);
  }
  :root .hover-parent:hover .text-warn-dark-10-on-hover-parent, :root .hover-parent:hover .text-theme-warn-dark-10-on-hover-parent, :root .hover-parent:hover .fc-warn-dark-10-on-hover-parent, :root .hover-parent:hover .fc-theme-warn-dark-10-on-hover-parent {
    color: rgba(var(--color-warn-dark-rgb), 0.1);
  }
}
:root .text-warn-dark-20, :root .text-theme-warn-dark-20,
:root .fc-warn-dark-20,
:root .fc-theme-warn-dark-20 {
  color: rgba(var(--color-warn-dark-rgb), 0.2);
}
@media (hover: hover) {
  :root .text-warn-dark-20-on-hover:hover, :root .text-theme-warn-dark-20-on-hover:hover,
  :root .fc-warn-dark-20-on-hover:hover,
  :root .fc-theme-warn-dark-20-on-hover:hover {
    color: rgba(var(--color-warn-dark-rgb), 0.2);
  }
  :root .hover-parent:hover .text-warn-dark-20-on-hover-parent, :root .hover-parent:hover .text-theme-warn-dark-20-on-hover-parent, :root .hover-parent:hover .fc-warn-dark-20-on-hover-parent, :root .hover-parent:hover .fc-theme-warn-dark-20-on-hover-parent {
    color: rgba(var(--color-warn-dark-rgb), 0.2);
  }
}
:root .text-warn-dark-30, :root .text-theme-warn-dark-30,
:root .fc-warn-dark-30,
:root .fc-theme-warn-dark-30 {
  color: rgba(var(--color-warn-dark-rgb), 0.3);
}
@media (hover: hover) {
  :root .text-warn-dark-30-on-hover:hover, :root .text-theme-warn-dark-30-on-hover:hover,
  :root .fc-warn-dark-30-on-hover:hover,
  :root .fc-theme-warn-dark-30-on-hover:hover {
    color: rgba(var(--color-warn-dark-rgb), 0.3);
  }
  :root .hover-parent:hover .text-warn-dark-30-on-hover-parent, :root .hover-parent:hover .text-theme-warn-dark-30-on-hover-parent, :root .hover-parent:hover .fc-warn-dark-30-on-hover-parent, :root .hover-parent:hover .fc-theme-warn-dark-30-on-hover-parent {
    color: rgba(var(--color-warn-dark-rgb), 0.3);
  }
}
:root .text-warn-dark-40, :root .text-theme-warn-dark-40,
:root .fc-warn-dark-40,
:root .fc-theme-warn-dark-40 {
  color: rgba(var(--color-warn-dark-rgb), 0.4);
}
@media (hover: hover) {
  :root .text-warn-dark-40-on-hover:hover, :root .text-theme-warn-dark-40-on-hover:hover,
  :root .fc-warn-dark-40-on-hover:hover,
  :root .fc-theme-warn-dark-40-on-hover:hover {
    color: rgba(var(--color-warn-dark-rgb), 0.4);
  }
  :root .hover-parent:hover .text-warn-dark-40-on-hover-parent, :root .hover-parent:hover .text-theme-warn-dark-40-on-hover-parent, :root .hover-parent:hover .fc-warn-dark-40-on-hover-parent, :root .hover-parent:hover .fc-theme-warn-dark-40-on-hover-parent {
    color: rgba(var(--color-warn-dark-rgb), 0.4);
  }
}
:root .text-warn-dark-50, :root .text-theme-warn-dark-50,
:root .fc-warn-dark-50,
:root .fc-theme-warn-dark-50 {
  color: rgba(var(--color-warn-dark-rgb), 0.5);
}
@media (hover: hover) {
  :root .text-warn-dark-50-on-hover:hover, :root .text-theme-warn-dark-50-on-hover:hover,
  :root .fc-warn-dark-50-on-hover:hover,
  :root .fc-theme-warn-dark-50-on-hover:hover {
    color: rgba(var(--color-warn-dark-rgb), 0.5);
  }
  :root .hover-parent:hover .text-warn-dark-50-on-hover-parent, :root .hover-parent:hover .text-theme-warn-dark-50-on-hover-parent, :root .hover-parent:hover .fc-warn-dark-50-on-hover-parent, :root .hover-parent:hover .fc-theme-warn-dark-50-on-hover-parent {
    color: rgba(var(--color-warn-dark-rgb), 0.5);
  }
}
:root .text-warn-dark-60, :root .text-theme-warn-dark-60,
:root .fc-warn-dark-60,
:root .fc-theme-warn-dark-60 {
  color: rgba(var(--color-warn-dark-rgb), 0.6);
}
@media (hover: hover) {
  :root .text-warn-dark-60-on-hover:hover, :root .text-theme-warn-dark-60-on-hover:hover,
  :root .fc-warn-dark-60-on-hover:hover,
  :root .fc-theme-warn-dark-60-on-hover:hover {
    color: rgba(var(--color-warn-dark-rgb), 0.6);
  }
  :root .hover-parent:hover .text-warn-dark-60-on-hover-parent, :root .hover-parent:hover .text-theme-warn-dark-60-on-hover-parent, :root .hover-parent:hover .fc-warn-dark-60-on-hover-parent, :root .hover-parent:hover .fc-theme-warn-dark-60-on-hover-parent {
    color: rgba(var(--color-warn-dark-rgb), 0.6);
  }
}
:root .text-warn-dark-70, :root .text-theme-warn-dark-70,
:root .fc-warn-dark-70,
:root .fc-theme-warn-dark-70 {
  color: rgba(var(--color-warn-dark-rgb), 0.7);
}
@media (hover: hover) {
  :root .text-warn-dark-70-on-hover:hover, :root .text-theme-warn-dark-70-on-hover:hover,
  :root .fc-warn-dark-70-on-hover:hover,
  :root .fc-theme-warn-dark-70-on-hover:hover {
    color: rgba(var(--color-warn-dark-rgb), 0.7);
  }
  :root .hover-parent:hover .text-warn-dark-70-on-hover-parent, :root .hover-parent:hover .text-theme-warn-dark-70-on-hover-parent, :root .hover-parent:hover .fc-warn-dark-70-on-hover-parent, :root .hover-parent:hover .fc-theme-warn-dark-70-on-hover-parent {
    color: rgba(var(--color-warn-dark-rgb), 0.7);
  }
}
:root .text-warn-dark-80, :root .text-theme-warn-dark-80,
:root .fc-warn-dark-80,
:root .fc-theme-warn-dark-80 {
  color: rgba(var(--color-warn-dark-rgb), 0.8);
}
@media (hover: hover) {
  :root .text-warn-dark-80-on-hover:hover, :root .text-theme-warn-dark-80-on-hover:hover,
  :root .fc-warn-dark-80-on-hover:hover,
  :root .fc-theme-warn-dark-80-on-hover:hover {
    color: rgba(var(--color-warn-dark-rgb), 0.8);
  }
  :root .hover-parent:hover .text-warn-dark-80-on-hover-parent, :root .hover-parent:hover .text-theme-warn-dark-80-on-hover-parent, :root .hover-parent:hover .fc-warn-dark-80-on-hover-parent, :root .hover-parent:hover .fc-theme-warn-dark-80-on-hover-parent {
    color: rgba(var(--color-warn-dark-rgb), 0.8);
  }
}
:root .text-warn-dark-90, :root .text-theme-warn-dark-90,
:root .fc-warn-dark-90,
:root .fc-theme-warn-dark-90 {
  color: rgba(var(--color-warn-dark-rgb), 0.9);
}
@media (hover: hover) {
  :root .text-warn-dark-90-on-hover:hover, :root .text-theme-warn-dark-90-on-hover:hover,
  :root .fc-warn-dark-90-on-hover:hover,
  :root .fc-theme-warn-dark-90-on-hover:hover {
    color: rgba(var(--color-warn-dark-rgb), 0.9);
  }
  :root .hover-parent:hover .text-warn-dark-90-on-hover-parent, :root .hover-parent:hover .text-theme-warn-dark-90-on-hover-parent, :root .hover-parent:hover .fc-warn-dark-90-on-hover-parent, :root .hover-parent:hover .fc-theme-warn-dark-90-on-hover-parent {
    color: rgba(var(--color-warn-dark-rgb), 0.9);
  }
}
:root .text-warn-dark-100, :root .text-theme-warn-dark-100,
:root .fc-warn-dark-100,
:root .fc-theme-warn-dark-100 {
  color: rgba(var(--color-warn-dark-rgb), 1);
}
@media (hover: hover) {
  :root .text-warn-dark-100-on-hover:hover, :root .text-theme-warn-dark-100-on-hover:hover,
  :root .fc-warn-dark-100-on-hover:hover,
  :root .fc-theme-warn-dark-100-on-hover:hover {
    color: rgba(var(--color-warn-dark-rgb), 1);
  }
  :root .hover-parent:hover .text-warn-dark-100-on-hover-parent, :root .hover-parent:hover .text-theme-warn-dark-100-on-hover-parent, :root .hover-parent:hover .fc-warn-dark-100-on-hover-parent, :root .hover-parent:hover .fc-theme-warn-dark-100-on-hover-parent {
    color: rgba(var(--color-warn-dark-rgb), 1);
  }
}

@media (hover: hover) {
  :root .text-warn-dark-on-hover:hover, :root .text-theme-warn-dark-on-hover:hover,
  :root .fc-warn-dark-on-hover:hover,
  :root .fc-theme-warn-dark-on-hover:hover {
    color: rgb(var(--color-warn-dark-rgb));
  }
  :root .hover-parent:hover .text-warn-dark-on-hover-parent, :root .hover-parent:hover .text-theme-warn-dark-on-hover-parent,
  :root .hover-parent:hover .fc-warn-dark-on-hover-parent,
  :root .hover-parent:hover .fc-theme-warn-dark-on-hover-parent {
    color: rgb(var(--color-warn-dark-rgb));
  }
}
:root .text-warn-500, :root .text-theme-warn-500,
:root .fc-warn-500,
:root .fc-theme-warn-500 {
  color: rgb(var(--color-warn-500-rgb));
}
:root .text-warn-500-0, :root .text-theme-warn-500-0,
:root .fc-warn-500-0,
:root .fc-theme-warn-500-0 {
  color: rgba(var(--color-warn-500-rgb), 0);
}
@media (hover: hover) {
  :root .text-warn-500-0-on-hover:hover, :root .text-theme-warn-500-0-on-hover:hover,
  :root .fc-warn-500-0-on-hover:hover,
  :root .fc-theme-warn-500-0-on-hover:hover {
    color: rgba(var(--color-warn-500-rgb), 0);
  }
  :root .hover-parent:hover .text-warn-500-0-on-hover-parent, :root .hover-parent:hover .text-theme-warn-500-0-on-hover-parent, :root .hover-parent:hover .fc-warn-500-0-on-hover-parent, :root .hover-parent:hover .fc-theme-warn-500-0-on-hover-parent {
    color: rgba(var(--color-warn-500-rgb), 0);
  }
}
:root .text-warn-500-10, :root .text-theme-warn-500-10,
:root .fc-warn-500-10,
:root .fc-theme-warn-500-10 {
  color: rgba(var(--color-warn-500-rgb), 0.1);
}
@media (hover: hover) {
  :root .text-warn-500-10-on-hover:hover, :root .text-theme-warn-500-10-on-hover:hover,
  :root .fc-warn-500-10-on-hover:hover,
  :root .fc-theme-warn-500-10-on-hover:hover {
    color: rgba(var(--color-warn-500-rgb), 0.1);
  }
  :root .hover-parent:hover .text-warn-500-10-on-hover-parent, :root .hover-parent:hover .text-theme-warn-500-10-on-hover-parent, :root .hover-parent:hover .fc-warn-500-10-on-hover-parent, :root .hover-parent:hover .fc-theme-warn-500-10-on-hover-parent {
    color: rgba(var(--color-warn-500-rgb), 0.1);
  }
}
:root .text-warn-500-20, :root .text-theme-warn-500-20,
:root .fc-warn-500-20,
:root .fc-theme-warn-500-20 {
  color: rgba(var(--color-warn-500-rgb), 0.2);
}
@media (hover: hover) {
  :root .text-warn-500-20-on-hover:hover, :root .text-theme-warn-500-20-on-hover:hover,
  :root .fc-warn-500-20-on-hover:hover,
  :root .fc-theme-warn-500-20-on-hover:hover {
    color: rgba(var(--color-warn-500-rgb), 0.2);
  }
  :root .hover-parent:hover .text-warn-500-20-on-hover-parent, :root .hover-parent:hover .text-theme-warn-500-20-on-hover-parent, :root .hover-parent:hover .fc-warn-500-20-on-hover-parent, :root .hover-parent:hover .fc-theme-warn-500-20-on-hover-parent {
    color: rgba(var(--color-warn-500-rgb), 0.2);
  }
}
:root .text-warn-500-30, :root .text-theme-warn-500-30,
:root .fc-warn-500-30,
:root .fc-theme-warn-500-30 {
  color: rgba(var(--color-warn-500-rgb), 0.3);
}
@media (hover: hover) {
  :root .text-warn-500-30-on-hover:hover, :root .text-theme-warn-500-30-on-hover:hover,
  :root .fc-warn-500-30-on-hover:hover,
  :root .fc-theme-warn-500-30-on-hover:hover {
    color: rgba(var(--color-warn-500-rgb), 0.3);
  }
  :root .hover-parent:hover .text-warn-500-30-on-hover-parent, :root .hover-parent:hover .text-theme-warn-500-30-on-hover-parent, :root .hover-parent:hover .fc-warn-500-30-on-hover-parent, :root .hover-parent:hover .fc-theme-warn-500-30-on-hover-parent {
    color: rgba(var(--color-warn-500-rgb), 0.3);
  }
}
:root .text-warn-500-40, :root .text-theme-warn-500-40,
:root .fc-warn-500-40,
:root .fc-theme-warn-500-40 {
  color: rgba(var(--color-warn-500-rgb), 0.4);
}
@media (hover: hover) {
  :root .text-warn-500-40-on-hover:hover, :root .text-theme-warn-500-40-on-hover:hover,
  :root .fc-warn-500-40-on-hover:hover,
  :root .fc-theme-warn-500-40-on-hover:hover {
    color: rgba(var(--color-warn-500-rgb), 0.4);
  }
  :root .hover-parent:hover .text-warn-500-40-on-hover-parent, :root .hover-parent:hover .text-theme-warn-500-40-on-hover-parent, :root .hover-parent:hover .fc-warn-500-40-on-hover-parent, :root .hover-parent:hover .fc-theme-warn-500-40-on-hover-parent {
    color: rgba(var(--color-warn-500-rgb), 0.4);
  }
}
:root .text-warn-500-50, :root .text-theme-warn-500-50,
:root .fc-warn-500-50,
:root .fc-theme-warn-500-50 {
  color: rgba(var(--color-warn-500-rgb), 0.5);
}
@media (hover: hover) {
  :root .text-warn-500-50-on-hover:hover, :root .text-theme-warn-500-50-on-hover:hover,
  :root .fc-warn-500-50-on-hover:hover,
  :root .fc-theme-warn-500-50-on-hover:hover {
    color: rgba(var(--color-warn-500-rgb), 0.5);
  }
  :root .hover-parent:hover .text-warn-500-50-on-hover-parent, :root .hover-parent:hover .text-theme-warn-500-50-on-hover-parent, :root .hover-parent:hover .fc-warn-500-50-on-hover-parent, :root .hover-parent:hover .fc-theme-warn-500-50-on-hover-parent {
    color: rgba(var(--color-warn-500-rgb), 0.5);
  }
}
:root .text-warn-500-60, :root .text-theme-warn-500-60,
:root .fc-warn-500-60,
:root .fc-theme-warn-500-60 {
  color: rgba(var(--color-warn-500-rgb), 0.6);
}
@media (hover: hover) {
  :root .text-warn-500-60-on-hover:hover, :root .text-theme-warn-500-60-on-hover:hover,
  :root .fc-warn-500-60-on-hover:hover,
  :root .fc-theme-warn-500-60-on-hover:hover {
    color: rgba(var(--color-warn-500-rgb), 0.6);
  }
  :root .hover-parent:hover .text-warn-500-60-on-hover-parent, :root .hover-parent:hover .text-theme-warn-500-60-on-hover-parent, :root .hover-parent:hover .fc-warn-500-60-on-hover-parent, :root .hover-parent:hover .fc-theme-warn-500-60-on-hover-parent {
    color: rgba(var(--color-warn-500-rgb), 0.6);
  }
}
:root .text-warn-500-70, :root .text-theme-warn-500-70,
:root .fc-warn-500-70,
:root .fc-theme-warn-500-70 {
  color: rgba(var(--color-warn-500-rgb), 0.7);
}
@media (hover: hover) {
  :root .text-warn-500-70-on-hover:hover, :root .text-theme-warn-500-70-on-hover:hover,
  :root .fc-warn-500-70-on-hover:hover,
  :root .fc-theme-warn-500-70-on-hover:hover {
    color: rgba(var(--color-warn-500-rgb), 0.7);
  }
  :root .hover-parent:hover .text-warn-500-70-on-hover-parent, :root .hover-parent:hover .text-theme-warn-500-70-on-hover-parent, :root .hover-parent:hover .fc-warn-500-70-on-hover-parent, :root .hover-parent:hover .fc-theme-warn-500-70-on-hover-parent {
    color: rgba(var(--color-warn-500-rgb), 0.7);
  }
}
:root .text-warn-500-80, :root .text-theme-warn-500-80,
:root .fc-warn-500-80,
:root .fc-theme-warn-500-80 {
  color: rgba(var(--color-warn-500-rgb), 0.8);
}
@media (hover: hover) {
  :root .text-warn-500-80-on-hover:hover, :root .text-theme-warn-500-80-on-hover:hover,
  :root .fc-warn-500-80-on-hover:hover,
  :root .fc-theme-warn-500-80-on-hover:hover {
    color: rgba(var(--color-warn-500-rgb), 0.8);
  }
  :root .hover-parent:hover .text-warn-500-80-on-hover-parent, :root .hover-parent:hover .text-theme-warn-500-80-on-hover-parent, :root .hover-parent:hover .fc-warn-500-80-on-hover-parent, :root .hover-parent:hover .fc-theme-warn-500-80-on-hover-parent {
    color: rgba(var(--color-warn-500-rgb), 0.8);
  }
}
:root .text-warn-500-90, :root .text-theme-warn-500-90,
:root .fc-warn-500-90,
:root .fc-theme-warn-500-90 {
  color: rgba(var(--color-warn-500-rgb), 0.9);
}
@media (hover: hover) {
  :root .text-warn-500-90-on-hover:hover, :root .text-theme-warn-500-90-on-hover:hover,
  :root .fc-warn-500-90-on-hover:hover,
  :root .fc-theme-warn-500-90-on-hover:hover {
    color: rgba(var(--color-warn-500-rgb), 0.9);
  }
  :root .hover-parent:hover .text-warn-500-90-on-hover-parent, :root .hover-parent:hover .text-theme-warn-500-90-on-hover-parent, :root .hover-parent:hover .fc-warn-500-90-on-hover-parent, :root .hover-parent:hover .fc-theme-warn-500-90-on-hover-parent {
    color: rgba(var(--color-warn-500-rgb), 0.9);
  }
}
:root .text-warn-500-100, :root .text-theme-warn-500-100,
:root .fc-warn-500-100,
:root .fc-theme-warn-500-100 {
  color: rgba(var(--color-warn-500-rgb), 1);
}
@media (hover: hover) {
  :root .text-warn-500-100-on-hover:hover, :root .text-theme-warn-500-100-on-hover:hover,
  :root .fc-warn-500-100-on-hover:hover,
  :root .fc-theme-warn-500-100-on-hover:hover {
    color: rgba(var(--color-warn-500-rgb), 1);
  }
  :root .hover-parent:hover .text-warn-500-100-on-hover-parent, :root .hover-parent:hover .text-theme-warn-500-100-on-hover-parent, :root .hover-parent:hover .fc-warn-500-100-on-hover-parent, :root .hover-parent:hover .fc-theme-warn-500-100-on-hover-parent {
    color: rgba(var(--color-warn-500-rgb), 1);
  }
}

@media (hover: hover) {
  :root .text-warn-500-on-hover:hover, :root .text-theme-warn-500-on-hover:hover,
  :root .fc-warn-500-on-hover:hover,
  :root .fc-theme-warn-500-on-hover:hover {
    color: rgb(var(--color-warn-500-rgb));
  }
  :root .hover-parent:hover .text-warn-500-on-hover-parent, :root .hover-parent:hover .text-theme-warn-500-on-hover-parent,
  :root .hover-parent:hover .fc-warn-500-on-hover-parent,
  :root .hover-parent:hover .fc-theme-warn-500-on-hover-parent {
    color: rgb(var(--color-warn-500-rgb));
  }
}
:root .text-warn-700, :root .text-theme-warn-700,
:root .fc-warn-700,
:root .fc-theme-warn-700 {
  color: rgb(var(--color-warn-700-rgb));
}
:root .text-warn-700-0, :root .text-theme-warn-700-0,
:root .fc-warn-700-0,
:root .fc-theme-warn-700-0 {
  color: rgba(var(--color-warn-700-rgb), 0);
}
@media (hover: hover) {
  :root .text-warn-700-0-on-hover:hover, :root .text-theme-warn-700-0-on-hover:hover,
  :root .fc-warn-700-0-on-hover:hover,
  :root .fc-theme-warn-700-0-on-hover:hover {
    color: rgba(var(--color-warn-700-rgb), 0);
  }
  :root .hover-parent:hover .text-warn-700-0-on-hover-parent, :root .hover-parent:hover .text-theme-warn-700-0-on-hover-parent, :root .hover-parent:hover .fc-warn-700-0-on-hover-parent, :root .hover-parent:hover .fc-theme-warn-700-0-on-hover-parent {
    color: rgba(var(--color-warn-700-rgb), 0);
  }
}
:root .text-warn-700-10, :root .text-theme-warn-700-10,
:root .fc-warn-700-10,
:root .fc-theme-warn-700-10 {
  color: rgba(var(--color-warn-700-rgb), 0.1);
}
@media (hover: hover) {
  :root .text-warn-700-10-on-hover:hover, :root .text-theme-warn-700-10-on-hover:hover,
  :root .fc-warn-700-10-on-hover:hover,
  :root .fc-theme-warn-700-10-on-hover:hover {
    color: rgba(var(--color-warn-700-rgb), 0.1);
  }
  :root .hover-parent:hover .text-warn-700-10-on-hover-parent, :root .hover-parent:hover .text-theme-warn-700-10-on-hover-parent, :root .hover-parent:hover .fc-warn-700-10-on-hover-parent, :root .hover-parent:hover .fc-theme-warn-700-10-on-hover-parent {
    color: rgba(var(--color-warn-700-rgb), 0.1);
  }
}
:root .text-warn-700-20, :root .text-theme-warn-700-20,
:root .fc-warn-700-20,
:root .fc-theme-warn-700-20 {
  color: rgba(var(--color-warn-700-rgb), 0.2);
}
@media (hover: hover) {
  :root .text-warn-700-20-on-hover:hover, :root .text-theme-warn-700-20-on-hover:hover,
  :root .fc-warn-700-20-on-hover:hover,
  :root .fc-theme-warn-700-20-on-hover:hover {
    color: rgba(var(--color-warn-700-rgb), 0.2);
  }
  :root .hover-parent:hover .text-warn-700-20-on-hover-parent, :root .hover-parent:hover .text-theme-warn-700-20-on-hover-parent, :root .hover-parent:hover .fc-warn-700-20-on-hover-parent, :root .hover-parent:hover .fc-theme-warn-700-20-on-hover-parent {
    color: rgba(var(--color-warn-700-rgb), 0.2);
  }
}
:root .text-warn-700-30, :root .text-theme-warn-700-30,
:root .fc-warn-700-30,
:root .fc-theme-warn-700-30 {
  color: rgba(var(--color-warn-700-rgb), 0.3);
}
@media (hover: hover) {
  :root .text-warn-700-30-on-hover:hover, :root .text-theme-warn-700-30-on-hover:hover,
  :root .fc-warn-700-30-on-hover:hover,
  :root .fc-theme-warn-700-30-on-hover:hover {
    color: rgba(var(--color-warn-700-rgb), 0.3);
  }
  :root .hover-parent:hover .text-warn-700-30-on-hover-parent, :root .hover-parent:hover .text-theme-warn-700-30-on-hover-parent, :root .hover-parent:hover .fc-warn-700-30-on-hover-parent, :root .hover-parent:hover .fc-theme-warn-700-30-on-hover-parent {
    color: rgba(var(--color-warn-700-rgb), 0.3);
  }
}
:root .text-warn-700-40, :root .text-theme-warn-700-40,
:root .fc-warn-700-40,
:root .fc-theme-warn-700-40 {
  color: rgba(var(--color-warn-700-rgb), 0.4);
}
@media (hover: hover) {
  :root .text-warn-700-40-on-hover:hover, :root .text-theme-warn-700-40-on-hover:hover,
  :root .fc-warn-700-40-on-hover:hover,
  :root .fc-theme-warn-700-40-on-hover:hover {
    color: rgba(var(--color-warn-700-rgb), 0.4);
  }
  :root .hover-parent:hover .text-warn-700-40-on-hover-parent, :root .hover-parent:hover .text-theme-warn-700-40-on-hover-parent, :root .hover-parent:hover .fc-warn-700-40-on-hover-parent, :root .hover-parent:hover .fc-theme-warn-700-40-on-hover-parent {
    color: rgba(var(--color-warn-700-rgb), 0.4);
  }
}
:root .text-warn-700-50, :root .text-theme-warn-700-50,
:root .fc-warn-700-50,
:root .fc-theme-warn-700-50 {
  color: rgba(var(--color-warn-700-rgb), 0.5);
}
@media (hover: hover) {
  :root .text-warn-700-50-on-hover:hover, :root .text-theme-warn-700-50-on-hover:hover,
  :root .fc-warn-700-50-on-hover:hover,
  :root .fc-theme-warn-700-50-on-hover:hover {
    color: rgba(var(--color-warn-700-rgb), 0.5);
  }
  :root .hover-parent:hover .text-warn-700-50-on-hover-parent, :root .hover-parent:hover .text-theme-warn-700-50-on-hover-parent, :root .hover-parent:hover .fc-warn-700-50-on-hover-parent, :root .hover-parent:hover .fc-theme-warn-700-50-on-hover-parent {
    color: rgba(var(--color-warn-700-rgb), 0.5);
  }
}
:root .text-warn-700-60, :root .text-theme-warn-700-60,
:root .fc-warn-700-60,
:root .fc-theme-warn-700-60 {
  color: rgba(var(--color-warn-700-rgb), 0.6);
}
@media (hover: hover) {
  :root .text-warn-700-60-on-hover:hover, :root .text-theme-warn-700-60-on-hover:hover,
  :root .fc-warn-700-60-on-hover:hover,
  :root .fc-theme-warn-700-60-on-hover:hover {
    color: rgba(var(--color-warn-700-rgb), 0.6);
  }
  :root .hover-parent:hover .text-warn-700-60-on-hover-parent, :root .hover-parent:hover .text-theme-warn-700-60-on-hover-parent, :root .hover-parent:hover .fc-warn-700-60-on-hover-parent, :root .hover-parent:hover .fc-theme-warn-700-60-on-hover-parent {
    color: rgba(var(--color-warn-700-rgb), 0.6);
  }
}
:root .text-warn-700-70, :root .text-theme-warn-700-70,
:root .fc-warn-700-70,
:root .fc-theme-warn-700-70 {
  color: rgba(var(--color-warn-700-rgb), 0.7);
}
@media (hover: hover) {
  :root .text-warn-700-70-on-hover:hover, :root .text-theme-warn-700-70-on-hover:hover,
  :root .fc-warn-700-70-on-hover:hover,
  :root .fc-theme-warn-700-70-on-hover:hover {
    color: rgba(var(--color-warn-700-rgb), 0.7);
  }
  :root .hover-parent:hover .text-warn-700-70-on-hover-parent, :root .hover-parent:hover .text-theme-warn-700-70-on-hover-parent, :root .hover-parent:hover .fc-warn-700-70-on-hover-parent, :root .hover-parent:hover .fc-theme-warn-700-70-on-hover-parent {
    color: rgba(var(--color-warn-700-rgb), 0.7);
  }
}
:root .text-warn-700-80, :root .text-theme-warn-700-80,
:root .fc-warn-700-80,
:root .fc-theme-warn-700-80 {
  color: rgba(var(--color-warn-700-rgb), 0.8);
}
@media (hover: hover) {
  :root .text-warn-700-80-on-hover:hover, :root .text-theme-warn-700-80-on-hover:hover,
  :root .fc-warn-700-80-on-hover:hover,
  :root .fc-theme-warn-700-80-on-hover:hover {
    color: rgba(var(--color-warn-700-rgb), 0.8);
  }
  :root .hover-parent:hover .text-warn-700-80-on-hover-parent, :root .hover-parent:hover .text-theme-warn-700-80-on-hover-parent, :root .hover-parent:hover .fc-warn-700-80-on-hover-parent, :root .hover-parent:hover .fc-theme-warn-700-80-on-hover-parent {
    color: rgba(var(--color-warn-700-rgb), 0.8);
  }
}
:root .text-warn-700-90, :root .text-theme-warn-700-90,
:root .fc-warn-700-90,
:root .fc-theme-warn-700-90 {
  color: rgba(var(--color-warn-700-rgb), 0.9);
}
@media (hover: hover) {
  :root .text-warn-700-90-on-hover:hover, :root .text-theme-warn-700-90-on-hover:hover,
  :root .fc-warn-700-90-on-hover:hover,
  :root .fc-theme-warn-700-90-on-hover:hover {
    color: rgba(var(--color-warn-700-rgb), 0.9);
  }
  :root .hover-parent:hover .text-warn-700-90-on-hover-parent, :root .hover-parent:hover .text-theme-warn-700-90-on-hover-parent, :root .hover-parent:hover .fc-warn-700-90-on-hover-parent, :root .hover-parent:hover .fc-theme-warn-700-90-on-hover-parent {
    color: rgba(var(--color-warn-700-rgb), 0.9);
  }
}
:root .text-warn-700-100, :root .text-theme-warn-700-100,
:root .fc-warn-700-100,
:root .fc-theme-warn-700-100 {
  color: rgba(var(--color-warn-700-rgb), 1);
}
@media (hover: hover) {
  :root .text-warn-700-100-on-hover:hover, :root .text-theme-warn-700-100-on-hover:hover,
  :root .fc-warn-700-100-on-hover:hover,
  :root .fc-theme-warn-700-100-on-hover:hover {
    color: rgba(var(--color-warn-700-rgb), 1);
  }
  :root .hover-parent:hover .text-warn-700-100-on-hover-parent, :root .hover-parent:hover .text-theme-warn-700-100-on-hover-parent, :root .hover-parent:hover .fc-warn-700-100-on-hover-parent, :root .hover-parent:hover .fc-theme-warn-700-100-on-hover-parent {
    color: rgba(var(--color-warn-700-rgb), 1);
  }
}

@media (hover: hover) {
  :root .text-warn-700-on-hover:hover, :root .text-theme-warn-700-on-hover:hover,
  :root .fc-warn-700-on-hover:hover,
  :root .fc-theme-warn-700-on-hover:hover {
    color: rgb(var(--color-warn-700-rgb));
  }
  :root .hover-parent:hover .text-warn-700-on-hover-parent, :root .hover-parent:hover .text-theme-warn-700-on-hover-parent,
  :root .hover-parent:hover .fc-warn-700-on-hover-parent,
  :root .hover-parent:hover .fc-theme-warn-700-on-hover-parent {
    color: rgb(var(--color-warn-700-rgb));
  }
}
.text-placeholder::placeholder,
.fc-placeholder::placeholder {
  color: var(--color-text-400);
  opacity: 1;
}

:root {
  --color-highlight-text-rgb: 48, 107, 159;
  --color-highlight-text: rgb(var(--color-highlight-text-rgb));
  --color-highlight-background: rgba(var(--color-primary-rgb), 0.25);
}
:root[app-theme=auto][client-theme=dark], :root[app-theme=dark] {
  --color-highlight-text-rgb: var(--color-primary-rgb);
  --color-highlight-text: rgb(var(--color-highlight-text-rgb));
}

:root {
  --color-bg-modal-rgb: 255, 255, 255;
  --color-bg-modal-search-rgb: 244, 245, 247;
  --color-bg-modal: rgb(var(--color-bg-modal-rgb));
  --color-bg-modal-search: rgb(var(--color-bg-modal-search-rgb));
  --color-bg-dark-modal: rgb(var(--color-bg-290-rgb));
}
:root[app-theme=auto][client-theme=dark], :root[app-theme=dark] {
  --color-bg-modal-rgb: 27, 28, 31;
  --color-bg-modal-search-rgb: 37, 39, 44;
}

.bg-modal,
.bg-m {
  background-color: var(--color-bg-modal);
}
.bg-modal-search,
.bg-m-search {
  background-color: var(--color-bg-modal-search);
}

.bg-dark-modal,
.bg-d-m {
  background-color: var(--color-bg-dark-modal);
}

:root {
  --__fg-to-bg-opacity: 0.3;
  --color-node-note-rgb: 241, 89, 42;
  --color-node-note: rgb(var(--color-node-note-rgb));
  --color-node-note-bg: rgba(var(--color-node-note-rgb), var(--__fg-to-bg-opacity));
  --color-node-tag-rgb: 72, 161, 238;
  --color-node-tag: rgb(var(--color-node-tag-rgb));
  --color-node-tag-bg: rgba(var(--color-node-tag-rgb), var(--__fg-to-bg-opacity));
  --color-node-website-rgb: 87, 192, 66;
  --color-node-website: rgb(var(--color-node-website-rgb));
  --color-node-website-bg: rgba(var(--color-node-website-rgb), var(--__fg-to-bg-opacity));
  --color-node-file-rgb: 129, 129, 129;
  --color-node-file: rgb(var(--color-node-file-rgb));
  --color-node-file-bg: rgba(var(--color-node-file-rgb), var(--__fg-to-bg-opacity));
  --color-node-workspace-rgb: 250, 169, 48;
  --color-node-workspace: rgb(var(--color-node-workspace-rgb));
  --color-node-workspace-bg: rgba(var(--color-node-workspace-rgb), var(--__fg-to-bg-opacity));
  --color-node-album-rgb: 252, 198, 0;
  --color-node-album: rgb(var(--color-node-album-rgb));
  --color-node-album-bg: rgba(var(--color-node-album-rgb), var(--__fg-to-bg-opacity));
}

.icon-note,
.note-color,
.color-note,
.node-type-note-color {
  --color-node-rgb: var(--color-node-note-rgb);
  --color-node: var(--color-node-note);
  --color-node-bg: var(--color-node-note-bg);
}

.icon-tag,
.tag-color,
.color-tag,
.node-type-tag-color {
  --color-node-rgb: var(--color-node-tag-rgb);
  --color-node: var(--color-node-tag);
  --color-node-bg: var(--color-node-tag-bg);
}

.icon-website,
.website-color,
.color-website,
.node-type-website-color {
  --color-node-rgb: var(--color-node-website-rgb);
  --color-node: var(--color-node-website);
  --color-node-bg: var(--color-node-website-bg);
}

.icon-document,
.file-color,
.color-file,
.node-type-file-color {
  --color-node-rgb: var(--color-node-file-rgb);
  --color-node: var(--color-node-file);
  --color-node-bg: var(--color-node-file-bg);
}

.icon-workspace,
.workspace-color,
.color-workspace,
.node-type-workspace-color {
  --color-node-rgb: var(--color-node-workspace-rgb);
  --color-node: var(--color-node-workspace);
  --color-node-bg: var(--color-node-workspace-bg);
}

.icon-album,
.album-color,
.color-album,
.node-type-album-color {
  --color-node-rgb: var(--color-node-album-rgb);
  --color-node: var(--color-node-album);
  --color-node-bg: var(--color-node-album-bg);
}

:root .bg-node-type {
  background-color: var(--color-node-bg);
}
:root .bg-node-type-10 {
  background-color: rgba(var(--color-node-rgb), 0.1);
}
:root .bg-node-type-20 {
  background-color: rgba(var(--color-node-rgb), 0.2);
}
:root .bg-node-type-30 {
  background-color: rgba(var(--color-node-rgb), 0.3);
}
:root .bg-node-type-40 {
  background-color: rgba(var(--color-node-rgb), 0.4);
}
:root .bg-node-type-50 {
  background-color: rgba(var(--color-node-rgb), 0.5);
}
:root .bg-node-type-60 {
  background-color: rgba(var(--color-node-rgb), 0.6);
}
:root .bg-node-type-70 {
  background-color: rgba(var(--color-node-rgb), 0.7);
}
:root .bg-node-type-80 {
  background-color: rgba(var(--color-node-rgb), 0.8);
}
:root .bg-node-type-90 {
  background-color: rgba(var(--color-node-rgb), 0.9);
}
:root .bg-node-type-100 {
  background-color: rgba(var(--color-node-rgb), 1);
}
:root .fc-node-type {
  color: var(--color-node);
}
:root .border-node-type {
  border-color: var(--color-node);
}

:root {
  --color-font-notifications-badge: var(--color-text-white);
  --color-border-notifications-badge: var(--color-text-white);
}
:root[app-theme=auto][client-theme=dark], :root[app-theme=dark] {
  --color-font-notifications-badge: rgb(var(--color-text-800-rgb));
  --color-border-notifications-badge: rgb(var(--color-text-800-rgb));
}

:root {
  --color-shadow-rgb: 0, 0, 0;
  --color-shadow-alpha: 0.25;
  --color-shadow: rgba(var(--color-shadow-rgb), var(--color-shadow-alpha));
  --shadow-1: 0px 2px 2px rgba(0, 0, 0, 0.2), 0 0 3px 1px rgba(0, 0, 0, 0.1);
  --shadow-2: 0px 14px 14px rgba(0, 0, 0, 0.1), 0px 8px 8px rgba(0, 0, 0, 0.2),
  	0 0 4px 3px rgba(0, 0, 0, 0.1);
  --shadow-menu: 4px 10px 16px 0px rgba(12, 30, 70, 0.08), 2px 6px 12px 0px rgba(17, 42, 96, 0.06),
  	0px 2px 6px 0px rgba(13, 34, 80, 0.02), 0px 0px 4px 0px rgba(9, 22, 70, 0.02),
  	0px 1px 2px 0px rgba(0, 0, 0, 0.02);
}
:root[app-theme=auto][client-theme=dark], :root[app-theme=dark] {
  --shadow-menu: 4px 10px 16px 0px rgba(0, 0, 0, 0.25), 2px 6px 12px 0px rgba(0, 0, 0, 0.25),
  	0px 2px 6px 0px rgba(0, 0, 0, 0.2), 0px 0px 4px 0px rgba(14, 17, 28, 0.15),
  	0px 1px 2px 0px rgba(0, 0, 0, 0.15);
}

.shadow-1, .shadow-z1 {
  box-shadow: var(--shadow-1);
}
.shadow-2, .shadow-z2 {
  box-shadow: var(--shadow-2);
}
.shadow-menu {
  box-shadow: var(--shadow-menu);
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 100;
  src: url("../../assets/fonts/roboto-v19-latin/roboto-v19-latin-100.eot");
  src: local("Roboto Thin"), local("Roboto-Thin"), url("../../assets/fonts/roboto-v19-latin/roboto-v19-latin-100.woff2") format("woff2"), url("../../assets/fonts/roboto-v19-latin/roboto-v19-latin-100.woff") format("woff"), url("../../assets/fonts/roboto-v19-latin/roboto-v19-latin-100.ttf") format("truetype");
}
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 100;
  src: url("../../assets/fonts/roboto-v19-latin/roboto-v19-latin-100italic.eot");
  src: local("Roboto Thin Italic"), local("Roboto-ThinItalic"), url("../../assets/fonts/roboto-v19-latin/roboto-v19-latin-100italic.woff2") format("woff2"), url("../../assets/fonts/roboto-v19-latin/roboto-v19-latin-100italic.woff") format("woff"), url("../../assets/fonts/roboto-v19-latin/roboto-v19-latin-100italic.ttf") format("truetype");
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  src: url("../../assets/fonts/roboto-v19-latin/roboto-v19-latin-300.eot");
  src: local("Roboto Light"), local("Roboto-Light"), url("../../assets/fonts/roboto-v19-latin/roboto-v19-latin-300.woff2") format("woff2"), url("../../assets/fonts/roboto-v19-latin/roboto-v19-latin-300.woff") format("woff"), url("../../assets/fonts/roboto-v19-latin/roboto-v19-latin-300.ttf") format("truetype");
}
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 300;
  src: url("../../assets/fonts/roboto-v19-latin/roboto-v19-latin-300italic.eot");
  src: local("Roboto Light Italic"), local("Roboto-LightItalic"), url("../../assets/fonts/roboto-v19-latin/roboto-v19-latin-300italic.woff2") format("woff2"), url("../../assets/fonts/roboto-v19-latin/roboto-v19-latin-300italic.woff") format("woff"), url("../../assets/fonts/roboto-v19-latin/roboto-v19-latin-300italic.ttf") format("truetype");
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url("../../assets/fonts/roboto-v19-latin/roboto-v19-latin-regular.eot");
  src: local("Roboto"), local("Roboto-Regular"), url("../../assets/fonts/roboto-v19-latin/roboto-v19-latin-regular.woff2") format("woff2"), url("../../assets/fonts/roboto-v19-latin/roboto-v19-latin-regular.woff") format("woff"), url("../../assets/fonts/roboto-v19-latin/roboto-v19-latin-regular.ttf") format("truetype");
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  src: url("../../assets/fonts/roboto-v19-latin/roboto-v19-latin-500.eot");
  src: local("Roboto Medium"), local("Roboto-Medium"), url("../../assets/fonts/roboto-v19-latin/roboto-v19-latin-500.woff2") format("woff2"), url("../../assets/fonts/roboto-v19-latin/roboto-v19-latin-500.woff") format("woff"), url("../../assets/fonts/roboto-v19-latin/roboto-v19-latin-500.ttf") format("truetype");
}
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 500;
  src: url("../../assets/fonts/roboto-v19-latin/roboto-v19-latin-500italic.eot");
  src: local("Roboto Medium Italic"), local("Roboto-MediumItalic"), url("../../assets/fonts/roboto-v19-latin/roboto-v19-latin-500italic.woff2") format("woff2"), url("../../assets/fonts/roboto-v19-latin/roboto-v19-latin-500italic.woff") format("woff"), url("../../assets/fonts/roboto-v19-latin/roboto-v19-latin-500italic.ttf") format("truetype");
}
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 400;
  src: url("../../assets/fonts/roboto-v19-latin/roboto-v19-latin-italic.eot");
  src: local("Roboto Italic"), local("Roboto-Italic"), url("../../assets/fonts/roboto-v19-latin/roboto-v19-latin-italic.woff2") format("woff2"), url("../../assets/fonts/roboto-v19-latin/roboto-v19-latin-italic.woff") format("woff"), url("../../assets/fonts/roboto-v19-latin/roboto-v19-latin-italic.ttf") format("truetype");
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: url("../../assets/fonts/roboto-v19-latin/roboto-v19-latin-700.eot");
  src: local("Roboto Bold"), local("Roboto-Bold"), url("../../assets/fonts/roboto-v19-latin/roboto-v19-latin-700.woff2") format("woff2"), url("../../assets/fonts/roboto-v19-latin/roboto-v19-latin-700.woff") format("woff"), url("../../assets/fonts/roboto-v19-latin/roboto-v19-latin-700.ttf") format("truetype");
}
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 700;
  src: url("../../assets/fonts/roboto-v19-latin/roboto-v19-latin-700italic.eot");
  src: local("Roboto Bold Italic"), local("Roboto-BoldItalic"), url("../../assets/fonts/roboto-v19-latin/roboto-v19-latin-700italic.woff2") format("woff2"), url("../../assets/fonts/roboto-v19-latin/roboto-v19-latin-700italic.woff") format("woff"), url("../../assets/fonts/roboto-v19-latin/roboto-v19-latin-700italic.ttf") format("truetype");
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  src: url("../../assets/fonts/roboto-v19-latin/roboto-v19-latin-900.eot");
  src: local("Roboto Black"), local("Roboto-Black"), url("../../assets/fonts/roboto-v19-latin/roboto-v19-latin-900.woff2") format("woff2"), url("../../assets/fonts/roboto-v19-latin/roboto-v19-latin-900.woff") format("woff"), url("../../assets/fonts/roboto-v19-latin/roboto-v19-latin-900.ttf") format("truetype");
}
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 900;
  src: url("../../assets/fonts/roboto-v19-latin/roboto-v19-latin-900italic.eot");
  src: local("Roboto Black Italic"), local("Roboto-BlackItalic"), url("../../assets/fonts/roboto-v19-latin/roboto-v19-latin-900italic.woff2") format("woff2"), url("../../assets/fonts/roboto-v19-latin/roboto-v19-latin-900italic.woff") format("woff"), url("../../assets/fonts/roboto-v19-latin/roboto-v19-latin-900italic.ttf") format("truetype");
}
:root {
  --font-family-base: "Helvetica Neue", "Roboto", Arial, sans-serif;
  --font-family-serif: Georgia, Times New Roman, serif;
  --font-family-monospace: Monaco, Courier New, monospace;
}

html {
  font-family: "Helvetica Neue", "Roboto", Arial, sans-serif;
  font-family: var(--font-family-base);
}

input,
textarea,
button {
  font-family: inherit;
}

.font-family-default, .font-family-base {
  font-family: var(--font-family-base);
}
.font-family-serif {
  font-family: var(--font-family-serif);
}
.font-family-monospace {
  font-family: var(--font-family-monospace);
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ls-l,
.l-spacing-l,
.letter-spacing-l {
  letter-spacing: 3px;
}

.it,
.italic {
  font-style: italic;
}

.underline {
  text-decoration: underline;
}
.underline-on-hover:hover {
  text-decoration: underline;
}

.strike,
.strikethrough,
.strike-through,
.linethrough,
.line-through {
  text-decoration: line-through;
}

.fa-l, .fa-left,
.ta-l,
.ta-left,
.falign-l,
.falign-left,
.talign-l,
.talign-left,
.text-align-l,
.text-align-left {
  text-align: left;
}
.fa-c, .fa-center,
.ta-c,
.ta-center,
.falign-c,
.falign-center,
.talign-c,
.talign-center,
.text-align-c,
.text-align-center {
  text-align: center;
}
.fa-r, .fa-right,
.ta-r,
.ta-right,
.falign-r,
.falign-right,
.talign-r,
.talign-right,
.text-align-r,
.text-align-right {
  text-align: right;
}

.ws-pl, .ws-pre-line, .ws-preline,
.white-space-pl,
.white-space-pre-line,
.white-space-preline {
  white-space: pre-line;
}
.ws-nw, .ws-nowrap, .ws-no-wrap,
.white-space-nw,
.white-space-nowrap,
.white-space-no-wrap {
  white-space: nowrap;
}

.wb-break-all, .wb-ba,
.word-break-break-all,
.word-break-ba {
  word-break: break-all;
}
.wb-break-word, .wb-bw,
.word-break-break-word,
.word-break-bw {
  word-break: break-word;
}

:root {
  --fw-thin: 200;
  --fw-light: 300;
  --fw-regular: 400;
  --fw-medium: 500;
  --fw-semi-bold: 600;
  --fw-bold: 700;
  --fw-black: 900;
}

.fw-t, .fw-thin, .fw-200 {
  font-weight: var(--fw-thin);
}
.fw-l, .fw-light, .fw-300 {
  font-weight: var(--fw-light);
}
.fw-r, .fw-regular, .fw-400 {
  font-weight: var(--fw-regular);
}
.fw-m, .fw-medium, .fw-500 {
  font-weight: var(--fw-medium);
}
.fw-sb, .fw-semi-bold, .fw-semibold, .fw-600 {
  font-weight: var(--fw-semi-bold);
}
.fw-b, .fw-bold, .fw-700 {
  font-weight: var(--fw-bold);
}
.fw-xb, .fw-bl, .fw-black, .fw-900 {
  font-weight: var(--fw-black);
}

a,
reach-anchor {
  text-decoration: none;
  color: inherit;
}

button {
  border: none;
  background: none;
  color: inherit;
  font-size: inherit;
  font-weight: inherit;
  text-align: inherit;
  font-family: inherit;
  letter-spacing: inherit;
  text-transform: inherit;
  line-height: inherit;
  border-radius: 0;
  outline: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
}
button[disabled=""], button[disabled=true] {
  cursor: not-allowed;
}

.cursor-default {
  cursor: default;
}
.cursor-pointer {
  cursor: pointer;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0px;
}

img:not(.left-as-is),
object.as-img:not(.left-as-is) {
  display: inline-block;
  max-height: 100%;
  color: transparent;
  width: auto;
  height: auto;
}
img:not(.left-as-is):not(.keep-size).use-width,
object.as-img:not(.left-as-is):not(.keep-size).use-width {
  max-height: unset;
  width: 100%;
  max-width: 100%;
}
img:not(.left-as-is):not(.keep-size).use-height,
object.as-img:not(.left-as-is):not(.keep-size).use-height {
  height: 100%;
  max-height: 100%;
  max-width: unset;
}

img {
  -webkit-touch-callout: default !important;
}

input,
textarea,
[contenteditable] {
  caret-color: var(--color-primary);
}

input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
  display: none;
}

textarea,
input {
  outline: none;
  color: var(--color-text-800);
  font-family: inherit;
  font-size: var(--fs-m);
  border: none;
  box-sizing: border-box;
  background-color: transparent;
  padding: 0px;
  margin: 0px;
}
textarea::placeholder,
input::placeholder {
  color: var(--color-text-400);
  opacity: 1;
}

.input-with-label-wrapper > input:not(:placeholder-shown) + .label {
  opacity: 0;
}
.input-with-label-wrapper .label {
  position: absolute;
  top: 50%;
  left: 1rem;
  transform: translateY(-50%);
  opacity: 1;
  pointer-events: none;
}

.icon-input-icon {
  border-radius: 8px;
  padding: 0px 8px;
  box-sizing: border-box;
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  background-color: rgb(var(--color-bg-180-rgb));
  color: var(--color-text-400);
}
.icon-input-icon > .icon {
  display: flex;
  align-items: center;
  justify-content: center;
}
.icon-input-icon > .icon:first-child {
  padding-right: 8px;
}
.icon-input-icon > .icon:last-child {
  padding-left: 8px;
}
.icon-input-icon > input {
  flex: 1;
  padding: 8px 0px;
  box-sizing: border-box;
  color: var(--color-text-800);
}
.icon-input-icon > input::placeholder, .icon-input-icon > input::ms-input-placeholder {
  color: var(--color-text-400);
  opacity: 1;
}

ul.clear,
ol.clear {
  list-style: none;
}

select {
  width: 100%;
  padding: 0.5em 1em;
  background-color: var(--color-background-100, transparent);
  border-radius: 0.5em;
  border: 1px solid var(--color-line-230);
  caret-color: transparent;
  font-weight: var(--fw-regular, 400);
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: 0;
  font-size: inherit;
}
select:not([disabled]) {
  cursor: pointer;
  color: var(--color-text-800);
}

/*
    `br` is for border-radius.

	Follows the same structure as `padding.scss`, but for border-radius.

	The clock-wise starts both at `tr` (for top-right) or at `tl` (for top-left).
*/
:root .br-circle {
  border-radius: 50%;
}
:root .br-0 {
  border-top-right-radius: calc((0 + 0) * var(--spacing));
}
:root .br-0-tr {
  border-top-right-radius: calc((0 + 0) * var(--spacing));
}
:root .br-0-trbr {
  border-top-right-radius: calc((0 + 0) * var(--spacing));
}
:root .br-0-trbrbl {
  border-top-right-radius: calc((0 + 0) * var(--spacing));
}
:root .br-0-trbrbltl {
  border-top-right-radius: calc((0 + 0) * var(--spacing));
}
:root .br-0-tltrbrbl {
  border-top-right-radius: calc((0 + 0) * var(--spacing));
}
:root .br-0-trbrtl {
  border-top-right-radius: calc((0 + 0) * var(--spacing));
}
:root .br-0-tltrbr {
  border-top-right-radius: calc((0 + 0) * var(--spacing));
}
:root .br-0-trbl {
  border-top-right-radius: calc((0 + 0) * var(--spacing));
}
:root .br-0-trbltl {
  border-top-right-radius: calc((0 + 0) * var(--spacing));
}
:root .br-0-tltrbl {
  border-top-right-radius: calc((0 + 0) * var(--spacing));
}
:root .br-0-trtl {
  border-top-right-radius: calc((0 + 0) * var(--spacing));
}
:root .br-0-tltr {
  border-top-right-radius: calc((0 + 0) * var(--spacing));
}
:root .br-0 {
  border-bottom-right-radius: calc((0 + 0) * var(--spacing));
}
:root .br-0-trbr {
  border-bottom-right-radius: calc((0 + 0) * var(--spacing));
}
:root .br-0-trbrbl {
  border-bottom-right-radius: calc((0 + 0) * var(--spacing));
}
:root .br-0-trbrbltl {
  border-bottom-right-radius: calc((0 + 0) * var(--spacing));
}
:root .br-0-tltrbrbl {
  border-bottom-right-radius: calc((0 + 0) * var(--spacing));
}
:root .br-0-trbrtl {
  border-bottom-right-radius: calc((0 + 0) * var(--spacing));
}
:root .br-0-tltrbr {
  border-bottom-right-radius: calc((0 + 0) * var(--spacing));
}
:root .br-0-br {
  border-bottom-right-radius: calc((0 + 0) * var(--spacing));
}
:root .br-0-brbl {
  border-bottom-right-radius: calc((0 + 0) * var(--spacing));
}
:root .br-0-brbltl {
  border-bottom-right-radius: calc((0 + 0) * var(--spacing));
}
:root .br-0-tlbrbl {
  border-bottom-right-radius: calc((0 + 0) * var(--spacing));
}
:root .br-0-brtl {
  border-bottom-right-radius: calc((0 + 0) * var(--spacing));
}
:root .br-0-tlbr {
  border-bottom-right-radius: calc((0 + 0) * var(--spacing));
}
:root .br-0 {
  border-bottom-left-radius: calc((0 + 0) * var(--spacing));
}
:root .br-0-trbrbl {
  border-bottom-left-radius: calc((0 + 0) * var(--spacing));
}
:root .br-0-trbrbltl {
  border-bottom-left-radius: calc((0 + 0) * var(--spacing));
}
:root .br-0-tltrbrbl {
  border-bottom-left-radius: calc((0 + 0) * var(--spacing));
}
:root .br-0-trbl {
  border-bottom-left-radius: calc((0 + 0) * var(--spacing));
}
:root .br-0-trbltl {
  border-bottom-left-radius: calc((0 + 0) * var(--spacing));
}
:root .br-0-tltrbl {
  border-bottom-left-radius: calc((0 + 0) * var(--spacing));
}
:root .br-0-brbl {
  border-bottom-left-radius: calc((0 + 0) * var(--spacing));
}
:root .br-0-brbltl {
  border-bottom-left-radius: calc((0 + 0) * var(--spacing));
}
:root .br-0-tlbrbl {
  border-bottom-left-radius: calc((0 + 0) * var(--spacing));
}
:root .br-0-bl {
  border-bottom-left-radius: calc((0 + 0) * var(--spacing));
}
:root .br-0-bltl {
  border-bottom-left-radius: calc((0 + 0) * var(--spacing));
}
:root .br-0-tlbl {
  border-bottom-left-radius: calc((0 + 0) * var(--spacing));
}
:root .br-0 {
  border-top-left-radius: calc((0 + 0) * var(--spacing));
}
:root .br-0-trbrbltl {
  border-top-left-radius: calc((0 + 0) * var(--spacing));
}
:root .br-0-tltrbrbl {
  border-top-left-radius: calc((0 + 0) * var(--spacing));
}
:root .br-0-trbrtl {
  border-top-left-radius: calc((0 + 0) * var(--spacing));
}
:root .br-0-tltrbr {
  border-top-left-radius: calc((0 + 0) * var(--spacing));
}
:root .br-0-trbltl {
  border-top-left-radius: calc((0 + 0) * var(--spacing));
}
:root .br-0-tltrbl {
  border-top-left-radius: calc((0 + 0) * var(--spacing));
}
:root .br-0-trtl {
  border-top-left-radius: calc((0 + 0) * var(--spacing));
}
:root .br-0-tltr {
  border-top-left-radius: calc((0 + 0) * var(--spacing));
}
:root .br-0-brbltl {
  border-top-left-radius: calc((0 + 0) * var(--spacing));
}
:root .br-0-tlbrbl {
  border-top-left-radius: calc((0 + 0) * var(--spacing));
}
:root .br-0-brtl {
  border-top-left-radius: calc((0 + 0) * var(--spacing));
}
:root .br-0-tlbr {
  border-top-left-radius: calc((0 + 0) * var(--spacing));
}
:root .br-0-bltl {
  border-top-left-radius: calc((0 + 0) * var(--spacing));
}
:root .br-0-tlbl {
  border-top-left-radius: calc((0 + 0) * var(--spacing));
}
:root .br-0-tl {
  border-top-left-radius: calc((0 + 0) * var(--spacing));
}
:root .br-0q {
  border-top-right-radius: calc((0 + 0.25) * var(--spacing));
}
:root .br-0q-tr {
  border-top-right-radius: calc((0 + 0.25) * var(--spacing));
}
:root .br-0q-trbr {
  border-top-right-radius: calc((0 + 0.25) * var(--spacing));
}
:root .br-0q-trbrbl {
  border-top-right-radius: calc((0 + 0.25) * var(--spacing));
}
:root .br-0q-trbrbltl {
  border-top-right-radius: calc((0 + 0.25) * var(--spacing));
}
:root .br-0q-tltrbrbl {
  border-top-right-radius: calc((0 + 0.25) * var(--spacing));
}
:root .br-0q-trbrtl {
  border-top-right-radius: calc((0 + 0.25) * var(--spacing));
}
:root .br-0q-tltrbr {
  border-top-right-radius: calc((0 + 0.25) * var(--spacing));
}
:root .br-0q-trbl {
  border-top-right-radius: calc((0 + 0.25) * var(--spacing));
}
:root .br-0q-trbltl {
  border-top-right-radius: calc((0 + 0.25) * var(--spacing));
}
:root .br-0q-tltrbl {
  border-top-right-radius: calc((0 + 0.25) * var(--spacing));
}
:root .br-0q-trtl {
  border-top-right-radius: calc((0 + 0.25) * var(--spacing));
}
:root .br-0q-tltr {
  border-top-right-radius: calc((0 + 0.25) * var(--spacing));
}
:root .br-0q {
  border-bottom-right-radius: calc((0 + 0.25) * var(--spacing));
}
:root .br-0q-trbr {
  border-bottom-right-radius: calc((0 + 0.25) * var(--spacing));
}
:root .br-0q-trbrbl {
  border-bottom-right-radius: calc((0 + 0.25) * var(--spacing));
}
:root .br-0q-trbrbltl {
  border-bottom-right-radius: calc((0 + 0.25) * var(--spacing));
}
:root .br-0q-tltrbrbl {
  border-bottom-right-radius: calc((0 + 0.25) * var(--spacing));
}
:root .br-0q-trbrtl {
  border-bottom-right-radius: calc((0 + 0.25) * var(--spacing));
}
:root .br-0q-tltrbr {
  border-bottom-right-radius: calc((0 + 0.25) * var(--spacing));
}
:root .br-0q-br {
  border-bottom-right-radius: calc((0 + 0.25) * var(--spacing));
}
:root .br-0q-brbl {
  border-bottom-right-radius: calc((0 + 0.25) * var(--spacing));
}
:root .br-0q-brbltl {
  border-bottom-right-radius: calc((0 + 0.25) * var(--spacing));
}
:root .br-0q-tlbrbl {
  border-bottom-right-radius: calc((0 + 0.25) * var(--spacing));
}
:root .br-0q-brtl {
  border-bottom-right-radius: calc((0 + 0.25) * var(--spacing));
}
:root .br-0q-tlbr {
  border-bottom-right-radius: calc((0 + 0.25) * var(--spacing));
}
:root .br-0q {
  border-bottom-left-radius: calc((0 + 0.25) * var(--spacing));
}
:root .br-0q-trbrbl {
  border-bottom-left-radius: calc((0 + 0.25) * var(--spacing));
}
:root .br-0q-trbrbltl {
  border-bottom-left-radius: calc((0 + 0.25) * var(--spacing));
}
:root .br-0q-tltrbrbl {
  border-bottom-left-radius: calc((0 + 0.25) * var(--spacing));
}
:root .br-0q-trbl {
  border-bottom-left-radius: calc((0 + 0.25) * var(--spacing));
}
:root .br-0q-trbltl {
  border-bottom-left-radius: calc((0 + 0.25) * var(--spacing));
}
:root .br-0q-tltrbl {
  border-bottom-left-radius: calc((0 + 0.25) * var(--spacing));
}
:root .br-0q-brbl {
  border-bottom-left-radius: calc((0 + 0.25) * var(--spacing));
}
:root .br-0q-brbltl {
  border-bottom-left-radius: calc((0 + 0.25) * var(--spacing));
}
:root .br-0q-tlbrbl {
  border-bottom-left-radius: calc((0 + 0.25) * var(--spacing));
}
:root .br-0q-bl {
  border-bottom-left-radius: calc((0 + 0.25) * var(--spacing));
}
:root .br-0q-bltl {
  border-bottom-left-radius: calc((0 + 0.25) * var(--spacing));
}
:root .br-0q-tlbl {
  border-bottom-left-radius: calc((0 + 0.25) * var(--spacing));
}
:root .br-0q {
  border-top-left-radius: calc((0 + 0.25) * var(--spacing));
}
:root .br-0q-trbrbltl {
  border-top-left-radius: calc((0 + 0.25) * var(--spacing));
}
:root .br-0q-tltrbrbl {
  border-top-left-radius: calc((0 + 0.25) * var(--spacing));
}
:root .br-0q-trbrtl {
  border-top-left-radius: calc((0 + 0.25) * var(--spacing));
}
:root .br-0q-tltrbr {
  border-top-left-radius: calc((0 + 0.25) * var(--spacing));
}
:root .br-0q-trbltl {
  border-top-left-radius: calc((0 + 0.25) * var(--spacing));
}
:root .br-0q-tltrbl {
  border-top-left-radius: calc((0 + 0.25) * var(--spacing));
}
:root .br-0q-trtl {
  border-top-left-radius: calc((0 + 0.25) * var(--spacing));
}
:root .br-0q-tltr {
  border-top-left-radius: calc((0 + 0.25) * var(--spacing));
}
:root .br-0q-brbltl {
  border-top-left-radius: calc((0 + 0.25) * var(--spacing));
}
:root .br-0q-tlbrbl {
  border-top-left-radius: calc((0 + 0.25) * var(--spacing));
}
:root .br-0q-brtl {
  border-top-left-radius: calc((0 + 0.25) * var(--spacing));
}
:root .br-0q-tlbr {
  border-top-left-radius: calc((0 + 0.25) * var(--spacing));
}
:root .br-0q-bltl {
  border-top-left-radius: calc((0 + 0.25) * var(--spacing));
}
:root .br-0q-tlbl {
  border-top-left-radius: calc((0 + 0.25) * var(--spacing));
}
:root .br-0q-tl {
  border-top-left-radius: calc((0 + 0.25) * var(--spacing));
}
:root .br-0h {
  border-top-right-radius: calc((0 + 0.5) * var(--spacing));
}
:root .br-0h-tr {
  border-top-right-radius: calc((0 + 0.5) * var(--spacing));
}
:root .br-0h-trbr {
  border-top-right-radius: calc((0 + 0.5) * var(--spacing));
}
:root .br-0h-trbrbl {
  border-top-right-radius: calc((0 + 0.5) * var(--spacing));
}
:root .br-0h-trbrbltl {
  border-top-right-radius: calc((0 + 0.5) * var(--spacing));
}
:root .br-0h-tltrbrbl {
  border-top-right-radius: calc((0 + 0.5) * var(--spacing));
}
:root .br-0h-trbrtl {
  border-top-right-radius: calc((0 + 0.5) * var(--spacing));
}
:root .br-0h-tltrbr {
  border-top-right-radius: calc((0 + 0.5) * var(--spacing));
}
:root .br-0h-trbl {
  border-top-right-radius: calc((0 + 0.5) * var(--spacing));
}
:root .br-0h-trbltl {
  border-top-right-radius: calc((0 + 0.5) * var(--spacing));
}
:root .br-0h-tltrbl {
  border-top-right-radius: calc((0 + 0.5) * var(--spacing));
}
:root .br-0h-trtl {
  border-top-right-radius: calc((0 + 0.5) * var(--spacing));
}
:root .br-0h-tltr {
  border-top-right-radius: calc((0 + 0.5) * var(--spacing));
}
:root .br-0h {
  border-bottom-right-radius: calc((0 + 0.5) * var(--spacing));
}
:root .br-0h-trbr {
  border-bottom-right-radius: calc((0 + 0.5) * var(--spacing));
}
:root .br-0h-trbrbl {
  border-bottom-right-radius: calc((0 + 0.5) * var(--spacing));
}
:root .br-0h-trbrbltl {
  border-bottom-right-radius: calc((0 + 0.5) * var(--spacing));
}
:root .br-0h-tltrbrbl {
  border-bottom-right-radius: calc((0 + 0.5) * var(--spacing));
}
:root .br-0h-trbrtl {
  border-bottom-right-radius: calc((0 + 0.5) * var(--spacing));
}
:root .br-0h-tltrbr {
  border-bottom-right-radius: calc((0 + 0.5) * var(--spacing));
}
:root .br-0h-br {
  border-bottom-right-radius: calc((0 + 0.5) * var(--spacing));
}
:root .br-0h-brbl {
  border-bottom-right-radius: calc((0 + 0.5) * var(--spacing));
}
:root .br-0h-brbltl {
  border-bottom-right-radius: calc((0 + 0.5) * var(--spacing));
}
:root .br-0h-tlbrbl {
  border-bottom-right-radius: calc((0 + 0.5) * var(--spacing));
}
:root .br-0h-brtl {
  border-bottom-right-radius: calc((0 + 0.5) * var(--spacing));
}
:root .br-0h-tlbr {
  border-bottom-right-radius: calc((0 + 0.5) * var(--spacing));
}
:root .br-0h {
  border-bottom-left-radius: calc((0 + 0.5) * var(--spacing));
}
:root .br-0h-trbrbl {
  border-bottom-left-radius: calc((0 + 0.5) * var(--spacing));
}
:root .br-0h-trbrbltl {
  border-bottom-left-radius: calc((0 + 0.5) * var(--spacing));
}
:root .br-0h-tltrbrbl {
  border-bottom-left-radius: calc((0 + 0.5) * var(--spacing));
}
:root .br-0h-trbl {
  border-bottom-left-radius: calc((0 + 0.5) * var(--spacing));
}
:root .br-0h-trbltl {
  border-bottom-left-radius: calc((0 + 0.5) * var(--spacing));
}
:root .br-0h-tltrbl {
  border-bottom-left-radius: calc((0 + 0.5) * var(--spacing));
}
:root .br-0h-brbl {
  border-bottom-left-radius: calc((0 + 0.5) * var(--spacing));
}
:root .br-0h-brbltl {
  border-bottom-left-radius: calc((0 + 0.5) * var(--spacing));
}
:root .br-0h-tlbrbl {
  border-bottom-left-radius: calc((0 + 0.5) * var(--spacing));
}
:root .br-0h-bl {
  border-bottom-left-radius: calc((0 + 0.5) * var(--spacing));
}
:root .br-0h-bltl {
  border-bottom-left-radius: calc((0 + 0.5) * var(--spacing));
}
:root .br-0h-tlbl {
  border-bottom-left-radius: calc((0 + 0.5) * var(--spacing));
}
:root .br-0h {
  border-top-left-radius: calc((0 + 0.5) * var(--spacing));
}
:root .br-0h-trbrbltl {
  border-top-left-radius: calc((0 + 0.5) * var(--spacing));
}
:root .br-0h-tltrbrbl {
  border-top-left-radius: calc((0 + 0.5) * var(--spacing));
}
:root .br-0h-trbrtl {
  border-top-left-radius: calc((0 + 0.5) * var(--spacing));
}
:root .br-0h-tltrbr {
  border-top-left-radius: calc((0 + 0.5) * var(--spacing));
}
:root .br-0h-trbltl {
  border-top-left-radius: calc((0 + 0.5) * var(--spacing));
}
:root .br-0h-tltrbl {
  border-top-left-radius: calc((0 + 0.5) * var(--spacing));
}
:root .br-0h-trtl {
  border-top-left-radius: calc((0 + 0.5) * var(--spacing));
}
:root .br-0h-tltr {
  border-top-left-radius: calc((0 + 0.5) * var(--spacing));
}
:root .br-0h-brbltl {
  border-top-left-radius: calc((0 + 0.5) * var(--spacing));
}
:root .br-0h-tlbrbl {
  border-top-left-radius: calc((0 + 0.5) * var(--spacing));
}
:root .br-0h-brtl {
  border-top-left-radius: calc((0 + 0.5) * var(--spacing));
}
:root .br-0h-tlbr {
  border-top-left-radius: calc((0 + 0.5) * var(--spacing));
}
:root .br-0h-bltl {
  border-top-left-radius: calc((0 + 0.5) * var(--spacing));
}
:root .br-0h-tlbl {
  border-top-left-radius: calc((0 + 0.5) * var(--spacing));
}
:root .br-0h-tl {
  border-top-left-radius: calc((0 + 0.5) * var(--spacing));
}
:root .br-0t {
  border-top-right-radius: calc((0 + 0.75) * var(--spacing));
}
:root .br-0t-tr {
  border-top-right-radius: calc((0 + 0.75) * var(--spacing));
}
:root .br-0t-trbr {
  border-top-right-radius: calc((0 + 0.75) * var(--spacing));
}
:root .br-0t-trbrbl {
  border-top-right-radius: calc((0 + 0.75) * var(--spacing));
}
:root .br-0t-trbrbltl {
  border-top-right-radius: calc((0 + 0.75) * var(--spacing));
}
:root .br-0t-tltrbrbl {
  border-top-right-radius: calc((0 + 0.75) * var(--spacing));
}
:root .br-0t-trbrtl {
  border-top-right-radius: calc((0 + 0.75) * var(--spacing));
}
:root .br-0t-tltrbr {
  border-top-right-radius: calc((0 + 0.75) * var(--spacing));
}
:root .br-0t-trbl {
  border-top-right-radius: calc((0 + 0.75) * var(--spacing));
}
:root .br-0t-trbltl {
  border-top-right-radius: calc((0 + 0.75) * var(--spacing));
}
:root .br-0t-tltrbl {
  border-top-right-radius: calc((0 + 0.75) * var(--spacing));
}
:root .br-0t-trtl {
  border-top-right-radius: calc((0 + 0.75) * var(--spacing));
}
:root .br-0t-tltr {
  border-top-right-radius: calc((0 + 0.75) * var(--spacing));
}
:root .br-0t {
  border-bottom-right-radius: calc((0 + 0.75) * var(--spacing));
}
:root .br-0t-trbr {
  border-bottom-right-radius: calc((0 + 0.75) * var(--spacing));
}
:root .br-0t-trbrbl {
  border-bottom-right-radius: calc((0 + 0.75) * var(--spacing));
}
:root .br-0t-trbrbltl {
  border-bottom-right-radius: calc((0 + 0.75) * var(--spacing));
}
:root .br-0t-tltrbrbl {
  border-bottom-right-radius: calc((0 + 0.75) * var(--spacing));
}
:root .br-0t-trbrtl {
  border-bottom-right-radius: calc((0 + 0.75) * var(--spacing));
}
:root .br-0t-tltrbr {
  border-bottom-right-radius: calc((0 + 0.75) * var(--spacing));
}
:root .br-0t-br {
  border-bottom-right-radius: calc((0 + 0.75) * var(--spacing));
}
:root .br-0t-brbl {
  border-bottom-right-radius: calc((0 + 0.75) * var(--spacing));
}
:root .br-0t-brbltl {
  border-bottom-right-radius: calc((0 + 0.75) * var(--spacing));
}
:root .br-0t-tlbrbl {
  border-bottom-right-radius: calc((0 + 0.75) * var(--spacing));
}
:root .br-0t-brtl {
  border-bottom-right-radius: calc((0 + 0.75) * var(--spacing));
}
:root .br-0t-tlbr {
  border-bottom-right-radius: calc((0 + 0.75) * var(--spacing));
}
:root .br-0t {
  border-bottom-left-radius: calc((0 + 0.75) * var(--spacing));
}
:root .br-0t-trbrbl {
  border-bottom-left-radius: calc((0 + 0.75) * var(--spacing));
}
:root .br-0t-trbrbltl {
  border-bottom-left-radius: calc((0 + 0.75) * var(--spacing));
}
:root .br-0t-tltrbrbl {
  border-bottom-left-radius: calc((0 + 0.75) * var(--spacing));
}
:root .br-0t-trbl {
  border-bottom-left-radius: calc((0 + 0.75) * var(--spacing));
}
:root .br-0t-trbltl {
  border-bottom-left-radius: calc((0 + 0.75) * var(--spacing));
}
:root .br-0t-tltrbl {
  border-bottom-left-radius: calc((0 + 0.75) * var(--spacing));
}
:root .br-0t-brbl {
  border-bottom-left-radius: calc((0 + 0.75) * var(--spacing));
}
:root .br-0t-brbltl {
  border-bottom-left-radius: calc((0 + 0.75) * var(--spacing));
}
:root .br-0t-tlbrbl {
  border-bottom-left-radius: calc((0 + 0.75) * var(--spacing));
}
:root .br-0t-bl {
  border-bottom-left-radius: calc((0 + 0.75) * var(--spacing));
}
:root .br-0t-bltl {
  border-bottom-left-radius: calc((0 + 0.75) * var(--spacing));
}
:root .br-0t-tlbl {
  border-bottom-left-radius: calc((0 + 0.75) * var(--spacing));
}
:root .br-0t {
  border-top-left-radius: calc((0 + 0.75) * var(--spacing));
}
:root .br-0t-trbrbltl {
  border-top-left-radius: calc((0 + 0.75) * var(--spacing));
}
:root .br-0t-tltrbrbl {
  border-top-left-radius: calc((0 + 0.75) * var(--spacing));
}
:root .br-0t-trbrtl {
  border-top-left-radius: calc((0 + 0.75) * var(--spacing));
}
:root .br-0t-tltrbr {
  border-top-left-radius: calc((0 + 0.75) * var(--spacing));
}
:root .br-0t-trbltl {
  border-top-left-radius: calc((0 + 0.75) * var(--spacing));
}
:root .br-0t-tltrbl {
  border-top-left-radius: calc((0 + 0.75) * var(--spacing));
}
:root .br-0t-trtl {
  border-top-left-radius: calc((0 + 0.75) * var(--spacing));
}
:root .br-0t-tltr {
  border-top-left-radius: calc((0 + 0.75) * var(--spacing));
}
:root .br-0t-brbltl {
  border-top-left-radius: calc((0 + 0.75) * var(--spacing));
}
:root .br-0t-tlbrbl {
  border-top-left-radius: calc((0 + 0.75) * var(--spacing));
}
:root .br-0t-brtl {
  border-top-left-radius: calc((0 + 0.75) * var(--spacing));
}
:root .br-0t-tlbr {
  border-top-left-radius: calc((0 + 0.75) * var(--spacing));
}
:root .br-0t-bltl {
  border-top-left-radius: calc((0 + 0.75) * var(--spacing));
}
:root .br-0t-tlbl {
  border-top-left-radius: calc((0 + 0.75) * var(--spacing));
}
:root .br-0t-tl {
  border-top-left-radius: calc((0 + 0.75) * var(--spacing));
}
:root .br-1 {
  border-top-right-radius: calc((1 + 0) * var(--spacing));
}
:root .br-1-tr {
  border-top-right-radius: calc((1 + 0) * var(--spacing));
}
:root .br-1-trbr {
  border-top-right-radius: calc((1 + 0) * var(--spacing));
}
:root .br-1-trbrbl {
  border-top-right-radius: calc((1 + 0) * var(--spacing));
}
:root .br-1-trbrbltl {
  border-top-right-radius: calc((1 + 0) * var(--spacing));
}
:root .br-1-tltrbrbl {
  border-top-right-radius: calc((1 + 0) * var(--spacing));
}
:root .br-1-trbrtl {
  border-top-right-radius: calc((1 + 0) * var(--spacing));
}
:root .br-1-tltrbr {
  border-top-right-radius: calc((1 + 0) * var(--spacing));
}
:root .br-1-trbl {
  border-top-right-radius: calc((1 + 0) * var(--spacing));
}
:root .br-1-trbltl {
  border-top-right-radius: calc((1 + 0) * var(--spacing));
}
:root .br-1-tltrbl {
  border-top-right-radius: calc((1 + 0) * var(--spacing));
}
:root .br-1-trtl {
  border-top-right-radius: calc((1 + 0) * var(--spacing));
}
:root .br-1-tltr {
  border-top-right-radius: calc((1 + 0) * var(--spacing));
}
:root .br-1 {
  border-bottom-right-radius: calc((1 + 0) * var(--spacing));
}
:root .br-1-trbr {
  border-bottom-right-radius: calc((1 + 0) * var(--spacing));
}
:root .br-1-trbrbl {
  border-bottom-right-radius: calc((1 + 0) * var(--spacing));
}
:root .br-1-trbrbltl {
  border-bottom-right-radius: calc((1 + 0) * var(--spacing));
}
:root .br-1-tltrbrbl {
  border-bottom-right-radius: calc((1 + 0) * var(--spacing));
}
:root .br-1-trbrtl {
  border-bottom-right-radius: calc((1 + 0) * var(--spacing));
}
:root .br-1-tltrbr {
  border-bottom-right-radius: calc((1 + 0) * var(--spacing));
}
:root .br-1-br {
  border-bottom-right-radius: calc((1 + 0) * var(--spacing));
}
:root .br-1-brbl {
  border-bottom-right-radius: calc((1 + 0) * var(--spacing));
}
:root .br-1-brbltl {
  border-bottom-right-radius: calc((1 + 0) * var(--spacing));
}
:root .br-1-tlbrbl {
  border-bottom-right-radius: calc((1 + 0) * var(--spacing));
}
:root .br-1-brtl {
  border-bottom-right-radius: calc((1 + 0) * var(--spacing));
}
:root .br-1-tlbr {
  border-bottom-right-radius: calc((1 + 0) * var(--spacing));
}
:root .br-1 {
  border-bottom-left-radius: calc((1 + 0) * var(--spacing));
}
:root .br-1-trbrbl {
  border-bottom-left-radius: calc((1 + 0) * var(--spacing));
}
:root .br-1-trbrbltl {
  border-bottom-left-radius: calc((1 + 0) * var(--spacing));
}
:root .br-1-tltrbrbl {
  border-bottom-left-radius: calc((1 + 0) * var(--spacing));
}
:root .br-1-trbl {
  border-bottom-left-radius: calc((1 + 0) * var(--spacing));
}
:root .br-1-trbltl {
  border-bottom-left-radius: calc((1 + 0) * var(--spacing));
}
:root .br-1-tltrbl {
  border-bottom-left-radius: calc((1 + 0) * var(--spacing));
}
:root .br-1-brbl {
  border-bottom-left-radius: calc((1 + 0) * var(--spacing));
}
:root .br-1-brbltl {
  border-bottom-left-radius: calc((1 + 0) * var(--spacing));
}
:root .br-1-tlbrbl {
  border-bottom-left-radius: calc((1 + 0) * var(--spacing));
}
:root .br-1-bl {
  border-bottom-left-radius: calc((1 + 0) * var(--spacing));
}
:root .br-1-bltl {
  border-bottom-left-radius: calc((1 + 0) * var(--spacing));
}
:root .br-1-tlbl {
  border-bottom-left-radius: calc((1 + 0) * var(--spacing));
}
:root .br-1 {
  border-top-left-radius: calc((1 + 0) * var(--spacing));
}
:root .br-1-trbrbltl {
  border-top-left-radius: calc((1 + 0) * var(--spacing));
}
:root .br-1-tltrbrbl {
  border-top-left-radius: calc((1 + 0) * var(--spacing));
}
:root .br-1-trbrtl {
  border-top-left-radius: calc((1 + 0) * var(--spacing));
}
:root .br-1-tltrbr {
  border-top-left-radius: calc((1 + 0) * var(--spacing));
}
:root .br-1-trbltl {
  border-top-left-radius: calc((1 + 0) * var(--spacing));
}
:root .br-1-tltrbl {
  border-top-left-radius: calc((1 + 0) * var(--spacing));
}
:root .br-1-trtl {
  border-top-left-radius: calc((1 + 0) * var(--spacing));
}
:root .br-1-tltr {
  border-top-left-radius: calc((1 + 0) * var(--spacing));
}
:root .br-1-brbltl {
  border-top-left-radius: calc((1 + 0) * var(--spacing));
}
:root .br-1-tlbrbl {
  border-top-left-radius: calc((1 + 0) * var(--spacing));
}
:root .br-1-brtl {
  border-top-left-radius: calc((1 + 0) * var(--spacing));
}
:root .br-1-tlbr {
  border-top-left-radius: calc((1 + 0) * var(--spacing));
}
:root .br-1-bltl {
  border-top-left-radius: calc((1 + 0) * var(--spacing));
}
:root .br-1-tlbl {
  border-top-left-radius: calc((1 + 0) * var(--spacing));
}
:root .br-1-tl {
  border-top-left-radius: calc((1 + 0) * var(--spacing));
}
:root .br-1q {
  border-top-right-radius: calc((1 + 0.25) * var(--spacing));
}
:root .br-1q-tr {
  border-top-right-radius: calc((1 + 0.25) * var(--spacing));
}
:root .br-1q-trbr {
  border-top-right-radius: calc((1 + 0.25) * var(--spacing));
}
:root .br-1q-trbrbl {
  border-top-right-radius: calc((1 + 0.25) * var(--spacing));
}
:root .br-1q-trbrbltl {
  border-top-right-radius: calc((1 + 0.25) * var(--spacing));
}
:root .br-1q-tltrbrbl {
  border-top-right-radius: calc((1 + 0.25) * var(--spacing));
}
:root .br-1q-trbrtl {
  border-top-right-radius: calc((1 + 0.25) * var(--spacing));
}
:root .br-1q-tltrbr {
  border-top-right-radius: calc((1 + 0.25) * var(--spacing));
}
:root .br-1q-trbl {
  border-top-right-radius: calc((1 + 0.25) * var(--spacing));
}
:root .br-1q-trbltl {
  border-top-right-radius: calc((1 + 0.25) * var(--spacing));
}
:root .br-1q-tltrbl {
  border-top-right-radius: calc((1 + 0.25) * var(--spacing));
}
:root .br-1q-trtl {
  border-top-right-radius: calc((1 + 0.25) * var(--spacing));
}
:root .br-1q-tltr {
  border-top-right-radius: calc((1 + 0.25) * var(--spacing));
}
:root .br-1q {
  border-bottom-right-radius: calc((1 + 0.25) * var(--spacing));
}
:root .br-1q-trbr {
  border-bottom-right-radius: calc((1 + 0.25) * var(--spacing));
}
:root .br-1q-trbrbl {
  border-bottom-right-radius: calc((1 + 0.25) * var(--spacing));
}
:root .br-1q-trbrbltl {
  border-bottom-right-radius: calc((1 + 0.25) * var(--spacing));
}
:root .br-1q-tltrbrbl {
  border-bottom-right-radius: calc((1 + 0.25) * var(--spacing));
}
:root .br-1q-trbrtl {
  border-bottom-right-radius: calc((1 + 0.25) * var(--spacing));
}
:root .br-1q-tltrbr {
  border-bottom-right-radius: calc((1 + 0.25) * var(--spacing));
}
:root .br-1q-br {
  border-bottom-right-radius: calc((1 + 0.25) * var(--spacing));
}
:root .br-1q-brbl {
  border-bottom-right-radius: calc((1 + 0.25) * var(--spacing));
}
:root .br-1q-brbltl {
  border-bottom-right-radius: calc((1 + 0.25) * var(--spacing));
}
:root .br-1q-tlbrbl {
  border-bottom-right-radius: calc((1 + 0.25) * var(--spacing));
}
:root .br-1q-brtl {
  border-bottom-right-radius: calc((1 + 0.25) * var(--spacing));
}
:root .br-1q-tlbr {
  border-bottom-right-radius: calc((1 + 0.25) * var(--spacing));
}
:root .br-1q {
  border-bottom-left-radius: calc((1 + 0.25) * var(--spacing));
}
:root .br-1q-trbrbl {
  border-bottom-left-radius: calc((1 + 0.25) * var(--spacing));
}
:root .br-1q-trbrbltl {
  border-bottom-left-radius: calc((1 + 0.25) * var(--spacing));
}
:root .br-1q-tltrbrbl {
  border-bottom-left-radius: calc((1 + 0.25) * var(--spacing));
}
:root .br-1q-trbl {
  border-bottom-left-radius: calc((1 + 0.25) * var(--spacing));
}
:root .br-1q-trbltl {
  border-bottom-left-radius: calc((1 + 0.25) * var(--spacing));
}
:root .br-1q-tltrbl {
  border-bottom-left-radius: calc((1 + 0.25) * var(--spacing));
}
:root .br-1q-brbl {
  border-bottom-left-radius: calc((1 + 0.25) * var(--spacing));
}
:root .br-1q-brbltl {
  border-bottom-left-radius: calc((1 + 0.25) * var(--spacing));
}
:root .br-1q-tlbrbl {
  border-bottom-left-radius: calc((1 + 0.25) * var(--spacing));
}
:root .br-1q-bl {
  border-bottom-left-radius: calc((1 + 0.25) * var(--spacing));
}
:root .br-1q-bltl {
  border-bottom-left-radius: calc((1 + 0.25) * var(--spacing));
}
:root .br-1q-tlbl {
  border-bottom-left-radius: calc((1 + 0.25) * var(--spacing));
}
:root .br-1q {
  border-top-left-radius: calc((1 + 0.25) * var(--spacing));
}
:root .br-1q-trbrbltl {
  border-top-left-radius: calc((1 + 0.25) * var(--spacing));
}
:root .br-1q-tltrbrbl {
  border-top-left-radius: calc((1 + 0.25) * var(--spacing));
}
:root .br-1q-trbrtl {
  border-top-left-radius: calc((1 + 0.25) * var(--spacing));
}
:root .br-1q-tltrbr {
  border-top-left-radius: calc((1 + 0.25) * var(--spacing));
}
:root .br-1q-trbltl {
  border-top-left-radius: calc((1 + 0.25) * var(--spacing));
}
:root .br-1q-tltrbl {
  border-top-left-radius: calc((1 + 0.25) * var(--spacing));
}
:root .br-1q-trtl {
  border-top-left-radius: calc((1 + 0.25) * var(--spacing));
}
:root .br-1q-tltr {
  border-top-left-radius: calc((1 + 0.25) * var(--spacing));
}
:root .br-1q-brbltl {
  border-top-left-radius: calc((1 + 0.25) * var(--spacing));
}
:root .br-1q-tlbrbl {
  border-top-left-radius: calc((1 + 0.25) * var(--spacing));
}
:root .br-1q-brtl {
  border-top-left-radius: calc((1 + 0.25) * var(--spacing));
}
:root .br-1q-tlbr {
  border-top-left-radius: calc((1 + 0.25) * var(--spacing));
}
:root .br-1q-bltl {
  border-top-left-radius: calc((1 + 0.25) * var(--spacing));
}
:root .br-1q-tlbl {
  border-top-left-radius: calc((1 + 0.25) * var(--spacing));
}
:root .br-1q-tl {
  border-top-left-radius: calc((1 + 0.25) * var(--spacing));
}
:root .br-1h {
  border-top-right-radius: calc((1 + 0.5) * var(--spacing));
}
:root .br-1h-tr {
  border-top-right-radius: calc((1 + 0.5) * var(--spacing));
}
:root .br-1h-trbr {
  border-top-right-radius: calc((1 + 0.5) * var(--spacing));
}
:root .br-1h-trbrbl {
  border-top-right-radius: calc((1 + 0.5) * var(--spacing));
}
:root .br-1h-trbrbltl {
  border-top-right-radius: calc((1 + 0.5) * var(--spacing));
}
:root .br-1h-tltrbrbl {
  border-top-right-radius: calc((1 + 0.5) * var(--spacing));
}
:root .br-1h-trbrtl {
  border-top-right-radius: calc((1 + 0.5) * var(--spacing));
}
:root .br-1h-tltrbr {
  border-top-right-radius: calc((1 + 0.5) * var(--spacing));
}
:root .br-1h-trbl {
  border-top-right-radius: calc((1 + 0.5) * var(--spacing));
}
:root .br-1h-trbltl {
  border-top-right-radius: calc((1 + 0.5) * var(--spacing));
}
:root .br-1h-tltrbl {
  border-top-right-radius: calc((1 + 0.5) * var(--spacing));
}
:root .br-1h-trtl {
  border-top-right-radius: calc((1 + 0.5) * var(--spacing));
}
:root .br-1h-tltr {
  border-top-right-radius: calc((1 + 0.5) * var(--spacing));
}
:root .br-1h {
  border-bottom-right-radius: calc((1 + 0.5) * var(--spacing));
}
:root .br-1h-trbr {
  border-bottom-right-radius: calc((1 + 0.5) * var(--spacing));
}
:root .br-1h-trbrbl {
  border-bottom-right-radius: calc((1 + 0.5) * var(--spacing));
}
:root .br-1h-trbrbltl {
  border-bottom-right-radius: calc((1 + 0.5) * var(--spacing));
}
:root .br-1h-tltrbrbl {
  border-bottom-right-radius: calc((1 + 0.5) * var(--spacing));
}
:root .br-1h-trbrtl {
  border-bottom-right-radius: calc((1 + 0.5) * var(--spacing));
}
:root .br-1h-tltrbr {
  border-bottom-right-radius: calc((1 + 0.5) * var(--spacing));
}
:root .br-1h-br {
  border-bottom-right-radius: calc((1 + 0.5) * var(--spacing));
}
:root .br-1h-brbl {
  border-bottom-right-radius: calc((1 + 0.5) * var(--spacing));
}
:root .br-1h-brbltl {
  border-bottom-right-radius: calc((1 + 0.5) * var(--spacing));
}
:root .br-1h-tlbrbl {
  border-bottom-right-radius: calc((1 + 0.5) * var(--spacing));
}
:root .br-1h-brtl {
  border-bottom-right-radius: calc((1 + 0.5) * var(--spacing));
}
:root .br-1h-tlbr {
  border-bottom-right-radius: calc((1 + 0.5) * var(--spacing));
}
:root .br-1h {
  border-bottom-left-radius: calc((1 + 0.5) * var(--spacing));
}
:root .br-1h-trbrbl {
  border-bottom-left-radius: calc((1 + 0.5) * var(--spacing));
}
:root .br-1h-trbrbltl {
  border-bottom-left-radius: calc((1 + 0.5) * var(--spacing));
}
:root .br-1h-tltrbrbl {
  border-bottom-left-radius: calc((1 + 0.5) * var(--spacing));
}
:root .br-1h-trbl {
  border-bottom-left-radius: calc((1 + 0.5) * var(--spacing));
}
:root .br-1h-trbltl {
  border-bottom-left-radius: calc((1 + 0.5) * var(--spacing));
}
:root .br-1h-tltrbl {
  border-bottom-left-radius: calc((1 + 0.5) * var(--spacing));
}
:root .br-1h-brbl {
  border-bottom-left-radius: calc((1 + 0.5) * var(--spacing));
}
:root .br-1h-brbltl {
  border-bottom-left-radius: calc((1 + 0.5) * var(--spacing));
}
:root .br-1h-tlbrbl {
  border-bottom-left-radius: calc((1 + 0.5) * var(--spacing));
}
:root .br-1h-bl {
  border-bottom-left-radius: calc((1 + 0.5) * var(--spacing));
}
:root .br-1h-bltl {
  border-bottom-left-radius: calc((1 + 0.5) * var(--spacing));
}
:root .br-1h-tlbl {
  border-bottom-left-radius: calc((1 + 0.5) * var(--spacing));
}
:root .br-1h {
  border-top-left-radius: calc((1 + 0.5) * var(--spacing));
}
:root .br-1h-trbrbltl {
  border-top-left-radius: calc((1 + 0.5) * var(--spacing));
}
:root .br-1h-tltrbrbl {
  border-top-left-radius: calc((1 + 0.5) * var(--spacing));
}
:root .br-1h-trbrtl {
  border-top-left-radius: calc((1 + 0.5) * var(--spacing));
}
:root .br-1h-tltrbr {
  border-top-left-radius: calc((1 + 0.5) * var(--spacing));
}
:root .br-1h-trbltl {
  border-top-left-radius: calc((1 + 0.5) * var(--spacing));
}
:root .br-1h-tltrbl {
  border-top-left-radius: calc((1 + 0.5) * var(--spacing));
}
:root .br-1h-trtl {
  border-top-left-radius: calc((1 + 0.5) * var(--spacing));
}
:root .br-1h-tltr {
  border-top-left-radius: calc((1 + 0.5) * var(--spacing));
}
:root .br-1h-brbltl {
  border-top-left-radius: calc((1 + 0.5) * var(--spacing));
}
:root .br-1h-tlbrbl {
  border-top-left-radius: calc((1 + 0.5) * var(--spacing));
}
:root .br-1h-brtl {
  border-top-left-radius: calc((1 + 0.5) * var(--spacing));
}
:root .br-1h-tlbr {
  border-top-left-radius: calc((1 + 0.5) * var(--spacing));
}
:root .br-1h-bltl {
  border-top-left-radius: calc((1 + 0.5) * var(--spacing));
}
:root .br-1h-tlbl {
  border-top-left-radius: calc((1 + 0.5) * var(--spacing));
}
:root .br-1h-tl {
  border-top-left-radius: calc((1 + 0.5) * var(--spacing));
}
:root .br-1t {
  border-top-right-radius: calc((1 + 0.75) * var(--spacing));
}
:root .br-1t-tr {
  border-top-right-radius: calc((1 + 0.75) * var(--spacing));
}
:root .br-1t-trbr {
  border-top-right-radius: calc((1 + 0.75) * var(--spacing));
}
:root .br-1t-trbrbl {
  border-top-right-radius: calc((1 + 0.75) * var(--spacing));
}
:root .br-1t-trbrbltl {
  border-top-right-radius: calc((1 + 0.75) * var(--spacing));
}
:root .br-1t-tltrbrbl {
  border-top-right-radius: calc((1 + 0.75) * var(--spacing));
}
:root .br-1t-trbrtl {
  border-top-right-radius: calc((1 + 0.75) * var(--spacing));
}
:root .br-1t-tltrbr {
  border-top-right-radius: calc((1 + 0.75) * var(--spacing));
}
:root .br-1t-trbl {
  border-top-right-radius: calc((1 + 0.75) * var(--spacing));
}
:root .br-1t-trbltl {
  border-top-right-radius: calc((1 + 0.75) * var(--spacing));
}
:root .br-1t-tltrbl {
  border-top-right-radius: calc((1 + 0.75) * var(--spacing));
}
:root .br-1t-trtl {
  border-top-right-radius: calc((1 + 0.75) * var(--spacing));
}
:root .br-1t-tltr {
  border-top-right-radius: calc((1 + 0.75) * var(--spacing));
}
:root .br-1t {
  border-bottom-right-radius: calc((1 + 0.75) * var(--spacing));
}
:root .br-1t-trbr {
  border-bottom-right-radius: calc((1 + 0.75) * var(--spacing));
}
:root .br-1t-trbrbl {
  border-bottom-right-radius: calc((1 + 0.75) * var(--spacing));
}
:root .br-1t-trbrbltl {
  border-bottom-right-radius: calc((1 + 0.75) * var(--spacing));
}
:root .br-1t-tltrbrbl {
  border-bottom-right-radius: calc((1 + 0.75) * var(--spacing));
}
:root .br-1t-trbrtl {
  border-bottom-right-radius: calc((1 + 0.75) * var(--spacing));
}
:root .br-1t-tltrbr {
  border-bottom-right-radius: calc((1 + 0.75) * var(--spacing));
}
:root .br-1t-br {
  border-bottom-right-radius: calc((1 + 0.75) * var(--spacing));
}
:root .br-1t-brbl {
  border-bottom-right-radius: calc((1 + 0.75) * var(--spacing));
}
:root .br-1t-brbltl {
  border-bottom-right-radius: calc((1 + 0.75) * var(--spacing));
}
:root .br-1t-tlbrbl {
  border-bottom-right-radius: calc((1 + 0.75) * var(--spacing));
}
:root .br-1t-brtl {
  border-bottom-right-radius: calc((1 + 0.75) * var(--spacing));
}
:root .br-1t-tlbr {
  border-bottom-right-radius: calc((1 + 0.75) * var(--spacing));
}
:root .br-1t {
  border-bottom-left-radius: calc((1 + 0.75) * var(--spacing));
}
:root .br-1t-trbrbl {
  border-bottom-left-radius: calc((1 + 0.75) * var(--spacing));
}
:root .br-1t-trbrbltl {
  border-bottom-left-radius: calc((1 + 0.75) * var(--spacing));
}
:root .br-1t-tltrbrbl {
  border-bottom-left-radius: calc((1 + 0.75) * var(--spacing));
}
:root .br-1t-trbl {
  border-bottom-left-radius: calc((1 + 0.75) * var(--spacing));
}
:root .br-1t-trbltl {
  border-bottom-left-radius: calc((1 + 0.75) * var(--spacing));
}
:root .br-1t-tltrbl {
  border-bottom-left-radius: calc((1 + 0.75) * var(--spacing));
}
:root .br-1t-brbl {
  border-bottom-left-radius: calc((1 + 0.75) * var(--spacing));
}
:root .br-1t-brbltl {
  border-bottom-left-radius: calc((1 + 0.75) * var(--spacing));
}
:root .br-1t-tlbrbl {
  border-bottom-left-radius: calc((1 + 0.75) * var(--spacing));
}
:root .br-1t-bl {
  border-bottom-left-radius: calc((1 + 0.75) * var(--spacing));
}
:root .br-1t-bltl {
  border-bottom-left-radius: calc((1 + 0.75) * var(--spacing));
}
:root .br-1t-tlbl {
  border-bottom-left-radius: calc((1 + 0.75) * var(--spacing));
}
:root .br-1t {
  border-top-left-radius: calc((1 + 0.75) * var(--spacing));
}
:root .br-1t-trbrbltl {
  border-top-left-radius: calc((1 + 0.75) * var(--spacing));
}
:root .br-1t-tltrbrbl {
  border-top-left-radius: calc((1 + 0.75) * var(--spacing));
}
:root .br-1t-trbrtl {
  border-top-left-radius: calc((1 + 0.75) * var(--spacing));
}
:root .br-1t-tltrbr {
  border-top-left-radius: calc((1 + 0.75) * var(--spacing));
}
:root .br-1t-trbltl {
  border-top-left-radius: calc((1 + 0.75) * var(--spacing));
}
:root .br-1t-tltrbl {
  border-top-left-radius: calc((1 + 0.75) * var(--spacing));
}
:root .br-1t-trtl {
  border-top-left-radius: calc((1 + 0.75) * var(--spacing));
}
:root .br-1t-tltr {
  border-top-left-radius: calc((1 + 0.75) * var(--spacing));
}
:root .br-1t-brbltl {
  border-top-left-radius: calc((1 + 0.75) * var(--spacing));
}
:root .br-1t-tlbrbl {
  border-top-left-radius: calc((1 + 0.75) * var(--spacing));
}
:root .br-1t-brtl {
  border-top-left-radius: calc((1 + 0.75) * var(--spacing));
}
:root .br-1t-tlbr {
  border-top-left-radius: calc((1 + 0.75) * var(--spacing));
}
:root .br-1t-bltl {
  border-top-left-radius: calc((1 + 0.75) * var(--spacing));
}
:root .br-1t-tlbl {
  border-top-left-radius: calc((1 + 0.75) * var(--spacing));
}
:root .br-1t-tl {
  border-top-left-radius: calc((1 + 0.75) * var(--spacing));
}
:root .br-2 {
  border-top-right-radius: calc((2 + 0) * var(--spacing));
}
:root .br-2-tr {
  border-top-right-radius: calc((2 + 0) * var(--spacing));
}
:root .br-2-trbr {
  border-top-right-radius: calc((2 + 0) * var(--spacing));
}
:root .br-2-trbrbl {
  border-top-right-radius: calc((2 + 0) * var(--spacing));
}
:root .br-2-trbrbltl {
  border-top-right-radius: calc((2 + 0) * var(--spacing));
}
:root .br-2-tltrbrbl {
  border-top-right-radius: calc((2 + 0) * var(--spacing));
}
:root .br-2-trbrtl {
  border-top-right-radius: calc((2 + 0) * var(--spacing));
}
:root .br-2-tltrbr {
  border-top-right-radius: calc((2 + 0) * var(--spacing));
}
:root .br-2-trbl {
  border-top-right-radius: calc((2 + 0) * var(--spacing));
}
:root .br-2-trbltl {
  border-top-right-radius: calc((2 + 0) * var(--spacing));
}
:root .br-2-tltrbl {
  border-top-right-radius: calc((2 + 0) * var(--spacing));
}
:root .br-2-trtl {
  border-top-right-radius: calc((2 + 0) * var(--spacing));
}
:root .br-2-tltr {
  border-top-right-radius: calc((2 + 0) * var(--spacing));
}
:root .br-2 {
  border-bottom-right-radius: calc((2 + 0) * var(--spacing));
}
:root .br-2-trbr {
  border-bottom-right-radius: calc((2 + 0) * var(--spacing));
}
:root .br-2-trbrbl {
  border-bottom-right-radius: calc((2 + 0) * var(--spacing));
}
:root .br-2-trbrbltl {
  border-bottom-right-radius: calc((2 + 0) * var(--spacing));
}
:root .br-2-tltrbrbl {
  border-bottom-right-radius: calc((2 + 0) * var(--spacing));
}
:root .br-2-trbrtl {
  border-bottom-right-radius: calc((2 + 0) * var(--spacing));
}
:root .br-2-tltrbr {
  border-bottom-right-radius: calc((2 + 0) * var(--spacing));
}
:root .br-2-br {
  border-bottom-right-radius: calc((2 + 0) * var(--spacing));
}
:root .br-2-brbl {
  border-bottom-right-radius: calc((2 + 0) * var(--spacing));
}
:root .br-2-brbltl {
  border-bottom-right-radius: calc((2 + 0) * var(--spacing));
}
:root .br-2-tlbrbl {
  border-bottom-right-radius: calc((2 + 0) * var(--spacing));
}
:root .br-2-brtl {
  border-bottom-right-radius: calc((2 + 0) * var(--spacing));
}
:root .br-2-tlbr {
  border-bottom-right-radius: calc((2 + 0) * var(--spacing));
}
:root .br-2 {
  border-bottom-left-radius: calc((2 + 0) * var(--spacing));
}
:root .br-2-trbrbl {
  border-bottom-left-radius: calc((2 + 0) * var(--spacing));
}
:root .br-2-trbrbltl {
  border-bottom-left-radius: calc((2 + 0) * var(--spacing));
}
:root .br-2-tltrbrbl {
  border-bottom-left-radius: calc((2 + 0) * var(--spacing));
}
:root .br-2-trbl {
  border-bottom-left-radius: calc((2 + 0) * var(--spacing));
}
:root .br-2-trbltl {
  border-bottom-left-radius: calc((2 + 0) * var(--spacing));
}
:root .br-2-tltrbl {
  border-bottom-left-radius: calc((2 + 0) * var(--spacing));
}
:root .br-2-brbl {
  border-bottom-left-radius: calc((2 + 0) * var(--spacing));
}
:root .br-2-brbltl {
  border-bottom-left-radius: calc((2 + 0) * var(--spacing));
}
:root .br-2-tlbrbl {
  border-bottom-left-radius: calc((2 + 0) * var(--spacing));
}
:root .br-2-bl {
  border-bottom-left-radius: calc((2 + 0) * var(--spacing));
}
:root .br-2-bltl {
  border-bottom-left-radius: calc((2 + 0) * var(--spacing));
}
:root .br-2-tlbl {
  border-bottom-left-radius: calc((2 + 0) * var(--spacing));
}
:root .br-2 {
  border-top-left-radius: calc((2 + 0) * var(--spacing));
}
:root .br-2-trbrbltl {
  border-top-left-radius: calc((2 + 0) * var(--spacing));
}
:root .br-2-tltrbrbl {
  border-top-left-radius: calc((2 + 0) * var(--spacing));
}
:root .br-2-trbrtl {
  border-top-left-radius: calc((2 + 0) * var(--spacing));
}
:root .br-2-tltrbr {
  border-top-left-radius: calc((2 + 0) * var(--spacing));
}
:root .br-2-trbltl {
  border-top-left-radius: calc((2 + 0) * var(--spacing));
}
:root .br-2-tltrbl {
  border-top-left-radius: calc((2 + 0) * var(--spacing));
}
:root .br-2-trtl {
  border-top-left-radius: calc((2 + 0) * var(--spacing));
}
:root .br-2-tltr {
  border-top-left-radius: calc((2 + 0) * var(--spacing));
}
:root .br-2-brbltl {
  border-top-left-radius: calc((2 + 0) * var(--spacing));
}
:root .br-2-tlbrbl {
  border-top-left-radius: calc((2 + 0) * var(--spacing));
}
:root .br-2-brtl {
  border-top-left-radius: calc((2 + 0) * var(--spacing));
}
:root .br-2-tlbr {
  border-top-left-radius: calc((2 + 0) * var(--spacing));
}
:root .br-2-bltl {
  border-top-left-radius: calc((2 + 0) * var(--spacing));
}
:root .br-2-tlbl {
  border-top-left-radius: calc((2 + 0) * var(--spacing));
}
:root .br-2-tl {
  border-top-left-radius: calc((2 + 0) * var(--spacing));
}
:root .br-2q {
  border-top-right-radius: calc((2 + 0.25) * var(--spacing));
}
:root .br-2q-tr {
  border-top-right-radius: calc((2 + 0.25) * var(--spacing));
}
:root .br-2q-trbr {
  border-top-right-radius: calc((2 + 0.25) * var(--spacing));
}
:root .br-2q-trbrbl {
  border-top-right-radius: calc((2 + 0.25) * var(--spacing));
}
:root .br-2q-trbrbltl {
  border-top-right-radius: calc((2 + 0.25) * var(--spacing));
}
:root .br-2q-tltrbrbl {
  border-top-right-radius: calc((2 + 0.25) * var(--spacing));
}
:root .br-2q-trbrtl {
  border-top-right-radius: calc((2 + 0.25) * var(--spacing));
}
:root .br-2q-tltrbr {
  border-top-right-radius: calc((2 + 0.25) * var(--spacing));
}
:root .br-2q-trbl {
  border-top-right-radius: calc((2 + 0.25) * var(--spacing));
}
:root .br-2q-trbltl {
  border-top-right-radius: calc((2 + 0.25) * var(--spacing));
}
:root .br-2q-tltrbl {
  border-top-right-radius: calc((2 + 0.25) * var(--spacing));
}
:root .br-2q-trtl {
  border-top-right-radius: calc((2 + 0.25) * var(--spacing));
}
:root .br-2q-tltr {
  border-top-right-radius: calc((2 + 0.25) * var(--spacing));
}
:root .br-2q {
  border-bottom-right-radius: calc((2 + 0.25) * var(--spacing));
}
:root .br-2q-trbr {
  border-bottom-right-radius: calc((2 + 0.25) * var(--spacing));
}
:root .br-2q-trbrbl {
  border-bottom-right-radius: calc((2 + 0.25) * var(--spacing));
}
:root .br-2q-trbrbltl {
  border-bottom-right-radius: calc((2 + 0.25) * var(--spacing));
}
:root .br-2q-tltrbrbl {
  border-bottom-right-radius: calc((2 + 0.25) * var(--spacing));
}
:root .br-2q-trbrtl {
  border-bottom-right-radius: calc((2 + 0.25) * var(--spacing));
}
:root .br-2q-tltrbr {
  border-bottom-right-radius: calc((2 + 0.25) * var(--spacing));
}
:root .br-2q-br {
  border-bottom-right-radius: calc((2 + 0.25) * var(--spacing));
}
:root .br-2q-brbl {
  border-bottom-right-radius: calc((2 + 0.25) * var(--spacing));
}
:root .br-2q-brbltl {
  border-bottom-right-radius: calc((2 + 0.25) * var(--spacing));
}
:root .br-2q-tlbrbl {
  border-bottom-right-radius: calc((2 + 0.25) * var(--spacing));
}
:root .br-2q-brtl {
  border-bottom-right-radius: calc((2 + 0.25) * var(--spacing));
}
:root .br-2q-tlbr {
  border-bottom-right-radius: calc((2 + 0.25) * var(--spacing));
}
:root .br-2q {
  border-bottom-left-radius: calc((2 + 0.25) * var(--spacing));
}
:root .br-2q-trbrbl {
  border-bottom-left-radius: calc((2 + 0.25) * var(--spacing));
}
:root .br-2q-trbrbltl {
  border-bottom-left-radius: calc((2 + 0.25) * var(--spacing));
}
:root .br-2q-tltrbrbl {
  border-bottom-left-radius: calc((2 + 0.25) * var(--spacing));
}
:root .br-2q-trbl {
  border-bottom-left-radius: calc((2 + 0.25) * var(--spacing));
}
:root .br-2q-trbltl {
  border-bottom-left-radius: calc((2 + 0.25) * var(--spacing));
}
:root .br-2q-tltrbl {
  border-bottom-left-radius: calc((2 + 0.25) * var(--spacing));
}
:root .br-2q-brbl {
  border-bottom-left-radius: calc((2 + 0.25) * var(--spacing));
}
:root .br-2q-brbltl {
  border-bottom-left-radius: calc((2 + 0.25) * var(--spacing));
}
:root .br-2q-tlbrbl {
  border-bottom-left-radius: calc((2 + 0.25) * var(--spacing));
}
:root .br-2q-bl {
  border-bottom-left-radius: calc((2 + 0.25) * var(--spacing));
}
:root .br-2q-bltl {
  border-bottom-left-radius: calc((2 + 0.25) * var(--spacing));
}
:root .br-2q-tlbl {
  border-bottom-left-radius: calc((2 + 0.25) * var(--spacing));
}
:root .br-2q {
  border-top-left-radius: calc((2 + 0.25) * var(--spacing));
}
:root .br-2q-trbrbltl {
  border-top-left-radius: calc((2 + 0.25) * var(--spacing));
}
:root .br-2q-tltrbrbl {
  border-top-left-radius: calc((2 + 0.25) * var(--spacing));
}
:root .br-2q-trbrtl {
  border-top-left-radius: calc((2 + 0.25) * var(--spacing));
}
:root .br-2q-tltrbr {
  border-top-left-radius: calc((2 + 0.25) * var(--spacing));
}
:root .br-2q-trbltl {
  border-top-left-radius: calc((2 + 0.25) * var(--spacing));
}
:root .br-2q-tltrbl {
  border-top-left-radius: calc((2 + 0.25) * var(--spacing));
}
:root .br-2q-trtl {
  border-top-left-radius: calc((2 + 0.25) * var(--spacing));
}
:root .br-2q-tltr {
  border-top-left-radius: calc((2 + 0.25) * var(--spacing));
}
:root .br-2q-brbltl {
  border-top-left-radius: calc((2 + 0.25) * var(--spacing));
}
:root .br-2q-tlbrbl {
  border-top-left-radius: calc((2 + 0.25) * var(--spacing));
}
:root .br-2q-brtl {
  border-top-left-radius: calc((2 + 0.25) * var(--spacing));
}
:root .br-2q-tlbr {
  border-top-left-radius: calc((2 + 0.25) * var(--spacing));
}
:root .br-2q-bltl {
  border-top-left-radius: calc((2 + 0.25) * var(--spacing));
}
:root .br-2q-tlbl {
  border-top-left-radius: calc((2 + 0.25) * var(--spacing));
}
:root .br-2q-tl {
  border-top-left-radius: calc((2 + 0.25) * var(--spacing));
}
:root .br-2h {
  border-top-right-radius: calc((2 + 0.5) * var(--spacing));
}
:root .br-2h-tr {
  border-top-right-radius: calc((2 + 0.5) * var(--spacing));
}
:root .br-2h-trbr {
  border-top-right-radius: calc((2 + 0.5) * var(--spacing));
}
:root .br-2h-trbrbl {
  border-top-right-radius: calc((2 + 0.5) * var(--spacing));
}
:root .br-2h-trbrbltl {
  border-top-right-radius: calc((2 + 0.5) * var(--spacing));
}
:root .br-2h-tltrbrbl {
  border-top-right-radius: calc((2 + 0.5) * var(--spacing));
}
:root .br-2h-trbrtl {
  border-top-right-radius: calc((2 + 0.5) * var(--spacing));
}
:root .br-2h-tltrbr {
  border-top-right-radius: calc((2 + 0.5) * var(--spacing));
}
:root .br-2h-trbl {
  border-top-right-radius: calc((2 + 0.5) * var(--spacing));
}
:root .br-2h-trbltl {
  border-top-right-radius: calc((2 + 0.5) * var(--spacing));
}
:root .br-2h-tltrbl {
  border-top-right-radius: calc((2 + 0.5) * var(--spacing));
}
:root .br-2h-trtl {
  border-top-right-radius: calc((2 + 0.5) * var(--spacing));
}
:root .br-2h-tltr {
  border-top-right-radius: calc((2 + 0.5) * var(--spacing));
}
:root .br-2h {
  border-bottom-right-radius: calc((2 + 0.5) * var(--spacing));
}
:root .br-2h-trbr {
  border-bottom-right-radius: calc((2 + 0.5) * var(--spacing));
}
:root .br-2h-trbrbl {
  border-bottom-right-radius: calc((2 + 0.5) * var(--spacing));
}
:root .br-2h-trbrbltl {
  border-bottom-right-radius: calc((2 + 0.5) * var(--spacing));
}
:root .br-2h-tltrbrbl {
  border-bottom-right-radius: calc((2 + 0.5) * var(--spacing));
}
:root .br-2h-trbrtl {
  border-bottom-right-radius: calc((2 + 0.5) * var(--spacing));
}
:root .br-2h-tltrbr {
  border-bottom-right-radius: calc((2 + 0.5) * var(--spacing));
}
:root .br-2h-br {
  border-bottom-right-radius: calc((2 + 0.5) * var(--spacing));
}
:root .br-2h-brbl {
  border-bottom-right-radius: calc((2 + 0.5) * var(--spacing));
}
:root .br-2h-brbltl {
  border-bottom-right-radius: calc((2 + 0.5) * var(--spacing));
}
:root .br-2h-tlbrbl {
  border-bottom-right-radius: calc((2 + 0.5) * var(--spacing));
}
:root .br-2h-brtl {
  border-bottom-right-radius: calc((2 + 0.5) * var(--spacing));
}
:root .br-2h-tlbr {
  border-bottom-right-radius: calc((2 + 0.5) * var(--spacing));
}
:root .br-2h {
  border-bottom-left-radius: calc((2 + 0.5) * var(--spacing));
}
:root .br-2h-trbrbl {
  border-bottom-left-radius: calc((2 + 0.5) * var(--spacing));
}
:root .br-2h-trbrbltl {
  border-bottom-left-radius: calc((2 + 0.5) * var(--spacing));
}
:root .br-2h-tltrbrbl {
  border-bottom-left-radius: calc((2 + 0.5) * var(--spacing));
}
:root .br-2h-trbl {
  border-bottom-left-radius: calc((2 + 0.5) * var(--spacing));
}
:root .br-2h-trbltl {
  border-bottom-left-radius: calc((2 + 0.5) * var(--spacing));
}
:root .br-2h-tltrbl {
  border-bottom-left-radius: calc((2 + 0.5) * var(--spacing));
}
:root .br-2h-brbl {
  border-bottom-left-radius: calc((2 + 0.5) * var(--spacing));
}
:root .br-2h-brbltl {
  border-bottom-left-radius: calc((2 + 0.5) * var(--spacing));
}
:root .br-2h-tlbrbl {
  border-bottom-left-radius: calc((2 + 0.5) * var(--spacing));
}
:root .br-2h-bl {
  border-bottom-left-radius: calc((2 + 0.5) * var(--spacing));
}
:root .br-2h-bltl {
  border-bottom-left-radius: calc((2 + 0.5) * var(--spacing));
}
:root .br-2h-tlbl {
  border-bottom-left-radius: calc((2 + 0.5) * var(--spacing));
}
:root .br-2h {
  border-top-left-radius: calc((2 + 0.5) * var(--spacing));
}
:root .br-2h-trbrbltl {
  border-top-left-radius: calc((2 + 0.5) * var(--spacing));
}
:root .br-2h-tltrbrbl {
  border-top-left-radius: calc((2 + 0.5) * var(--spacing));
}
:root .br-2h-trbrtl {
  border-top-left-radius: calc((2 + 0.5) * var(--spacing));
}
:root .br-2h-tltrbr {
  border-top-left-radius: calc((2 + 0.5) * var(--spacing));
}
:root .br-2h-trbltl {
  border-top-left-radius: calc((2 + 0.5) * var(--spacing));
}
:root .br-2h-tltrbl {
  border-top-left-radius: calc((2 + 0.5) * var(--spacing));
}
:root .br-2h-trtl {
  border-top-left-radius: calc((2 + 0.5) * var(--spacing));
}
:root .br-2h-tltr {
  border-top-left-radius: calc((2 + 0.5) * var(--spacing));
}
:root .br-2h-brbltl {
  border-top-left-radius: calc((2 + 0.5) * var(--spacing));
}
:root .br-2h-tlbrbl {
  border-top-left-radius: calc((2 + 0.5) * var(--spacing));
}
:root .br-2h-brtl {
  border-top-left-radius: calc((2 + 0.5) * var(--spacing));
}
:root .br-2h-tlbr {
  border-top-left-radius: calc((2 + 0.5) * var(--spacing));
}
:root .br-2h-bltl {
  border-top-left-radius: calc((2 + 0.5) * var(--spacing));
}
:root .br-2h-tlbl {
  border-top-left-radius: calc((2 + 0.5) * var(--spacing));
}
:root .br-2h-tl {
  border-top-left-radius: calc((2 + 0.5) * var(--spacing));
}
:root .br-2t {
  border-top-right-radius: calc((2 + 0.75) * var(--spacing));
}
:root .br-2t-tr {
  border-top-right-radius: calc((2 + 0.75) * var(--spacing));
}
:root .br-2t-trbr {
  border-top-right-radius: calc((2 + 0.75) * var(--spacing));
}
:root .br-2t-trbrbl {
  border-top-right-radius: calc((2 + 0.75) * var(--spacing));
}
:root .br-2t-trbrbltl {
  border-top-right-radius: calc((2 + 0.75) * var(--spacing));
}
:root .br-2t-tltrbrbl {
  border-top-right-radius: calc((2 + 0.75) * var(--spacing));
}
:root .br-2t-trbrtl {
  border-top-right-radius: calc((2 + 0.75) * var(--spacing));
}
:root .br-2t-tltrbr {
  border-top-right-radius: calc((2 + 0.75) * var(--spacing));
}
:root .br-2t-trbl {
  border-top-right-radius: calc((2 + 0.75) * var(--spacing));
}
:root .br-2t-trbltl {
  border-top-right-radius: calc((2 + 0.75) * var(--spacing));
}
:root .br-2t-tltrbl {
  border-top-right-radius: calc((2 + 0.75) * var(--spacing));
}
:root .br-2t-trtl {
  border-top-right-radius: calc((2 + 0.75) * var(--spacing));
}
:root .br-2t-tltr {
  border-top-right-radius: calc((2 + 0.75) * var(--spacing));
}
:root .br-2t {
  border-bottom-right-radius: calc((2 + 0.75) * var(--spacing));
}
:root .br-2t-trbr {
  border-bottom-right-radius: calc((2 + 0.75) * var(--spacing));
}
:root .br-2t-trbrbl {
  border-bottom-right-radius: calc((2 + 0.75) * var(--spacing));
}
:root .br-2t-trbrbltl {
  border-bottom-right-radius: calc((2 + 0.75) * var(--spacing));
}
:root .br-2t-tltrbrbl {
  border-bottom-right-radius: calc((2 + 0.75) * var(--spacing));
}
:root .br-2t-trbrtl {
  border-bottom-right-radius: calc((2 + 0.75) * var(--spacing));
}
:root .br-2t-tltrbr {
  border-bottom-right-radius: calc((2 + 0.75) * var(--spacing));
}
:root .br-2t-br {
  border-bottom-right-radius: calc((2 + 0.75) * var(--spacing));
}
:root .br-2t-brbl {
  border-bottom-right-radius: calc((2 + 0.75) * var(--spacing));
}
:root .br-2t-brbltl {
  border-bottom-right-radius: calc((2 + 0.75) * var(--spacing));
}
:root .br-2t-tlbrbl {
  border-bottom-right-radius: calc((2 + 0.75) * var(--spacing));
}
:root .br-2t-brtl {
  border-bottom-right-radius: calc((2 + 0.75) * var(--spacing));
}
:root .br-2t-tlbr {
  border-bottom-right-radius: calc((2 + 0.75) * var(--spacing));
}
:root .br-2t {
  border-bottom-left-radius: calc((2 + 0.75) * var(--spacing));
}
:root .br-2t-trbrbl {
  border-bottom-left-radius: calc((2 + 0.75) * var(--spacing));
}
:root .br-2t-trbrbltl {
  border-bottom-left-radius: calc((2 + 0.75) * var(--spacing));
}
:root .br-2t-tltrbrbl {
  border-bottom-left-radius: calc((2 + 0.75) * var(--spacing));
}
:root .br-2t-trbl {
  border-bottom-left-radius: calc((2 + 0.75) * var(--spacing));
}
:root .br-2t-trbltl {
  border-bottom-left-radius: calc((2 + 0.75) * var(--spacing));
}
:root .br-2t-tltrbl {
  border-bottom-left-radius: calc((2 + 0.75) * var(--spacing));
}
:root .br-2t-brbl {
  border-bottom-left-radius: calc((2 + 0.75) * var(--spacing));
}
:root .br-2t-brbltl {
  border-bottom-left-radius: calc((2 + 0.75) * var(--spacing));
}
:root .br-2t-tlbrbl {
  border-bottom-left-radius: calc((2 + 0.75) * var(--spacing));
}
:root .br-2t-bl {
  border-bottom-left-radius: calc((2 + 0.75) * var(--spacing));
}
:root .br-2t-bltl {
  border-bottom-left-radius: calc((2 + 0.75) * var(--spacing));
}
:root .br-2t-tlbl {
  border-bottom-left-radius: calc((2 + 0.75) * var(--spacing));
}
:root .br-2t {
  border-top-left-radius: calc((2 + 0.75) * var(--spacing));
}
:root .br-2t-trbrbltl {
  border-top-left-radius: calc((2 + 0.75) * var(--spacing));
}
:root .br-2t-tltrbrbl {
  border-top-left-radius: calc((2 + 0.75) * var(--spacing));
}
:root .br-2t-trbrtl {
  border-top-left-radius: calc((2 + 0.75) * var(--spacing));
}
:root .br-2t-tltrbr {
  border-top-left-radius: calc((2 + 0.75) * var(--spacing));
}
:root .br-2t-trbltl {
  border-top-left-radius: calc((2 + 0.75) * var(--spacing));
}
:root .br-2t-tltrbl {
  border-top-left-radius: calc((2 + 0.75) * var(--spacing));
}
:root .br-2t-trtl {
  border-top-left-radius: calc((2 + 0.75) * var(--spacing));
}
:root .br-2t-tltr {
  border-top-left-radius: calc((2 + 0.75) * var(--spacing));
}
:root .br-2t-brbltl {
  border-top-left-radius: calc((2 + 0.75) * var(--spacing));
}
:root .br-2t-tlbrbl {
  border-top-left-radius: calc((2 + 0.75) * var(--spacing));
}
:root .br-2t-brtl {
  border-top-left-radius: calc((2 + 0.75) * var(--spacing));
}
:root .br-2t-tlbr {
  border-top-left-radius: calc((2 + 0.75) * var(--spacing));
}
:root .br-2t-bltl {
  border-top-left-radius: calc((2 + 0.75) * var(--spacing));
}
:root .br-2t-tlbl {
  border-top-left-radius: calc((2 + 0.75) * var(--spacing));
}
:root .br-2t-tl {
  border-top-left-radius: calc((2 + 0.75) * var(--spacing));
}
:root .br-3 {
  border-top-right-radius: calc((3 + 0) * var(--spacing));
}
:root .br-3-tr {
  border-top-right-radius: calc((3 + 0) * var(--spacing));
}
:root .br-3-trbr {
  border-top-right-radius: calc((3 + 0) * var(--spacing));
}
:root .br-3-trbrbl {
  border-top-right-radius: calc((3 + 0) * var(--spacing));
}
:root .br-3-trbrbltl {
  border-top-right-radius: calc((3 + 0) * var(--spacing));
}
:root .br-3-tltrbrbl {
  border-top-right-radius: calc((3 + 0) * var(--spacing));
}
:root .br-3-trbrtl {
  border-top-right-radius: calc((3 + 0) * var(--spacing));
}
:root .br-3-tltrbr {
  border-top-right-radius: calc((3 + 0) * var(--spacing));
}
:root .br-3-trbl {
  border-top-right-radius: calc((3 + 0) * var(--spacing));
}
:root .br-3-trbltl {
  border-top-right-radius: calc((3 + 0) * var(--spacing));
}
:root .br-3-tltrbl {
  border-top-right-radius: calc((3 + 0) * var(--spacing));
}
:root .br-3-trtl {
  border-top-right-radius: calc((3 + 0) * var(--spacing));
}
:root .br-3-tltr {
  border-top-right-radius: calc((3 + 0) * var(--spacing));
}
:root .br-3 {
  border-bottom-right-radius: calc((3 + 0) * var(--spacing));
}
:root .br-3-trbr {
  border-bottom-right-radius: calc((3 + 0) * var(--spacing));
}
:root .br-3-trbrbl {
  border-bottom-right-radius: calc((3 + 0) * var(--spacing));
}
:root .br-3-trbrbltl {
  border-bottom-right-radius: calc((3 + 0) * var(--spacing));
}
:root .br-3-tltrbrbl {
  border-bottom-right-radius: calc((3 + 0) * var(--spacing));
}
:root .br-3-trbrtl {
  border-bottom-right-radius: calc((3 + 0) * var(--spacing));
}
:root .br-3-tltrbr {
  border-bottom-right-radius: calc((3 + 0) * var(--spacing));
}
:root .br-3-br {
  border-bottom-right-radius: calc((3 + 0) * var(--spacing));
}
:root .br-3-brbl {
  border-bottom-right-radius: calc((3 + 0) * var(--spacing));
}
:root .br-3-brbltl {
  border-bottom-right-radius: calc((3 + 0) * var(--spacing));
}
:root .br-3-tlbrbl {
  border-bottom-right-radius: calc((3 + 0) * var(--spacing));
}
:root .br-3-brtl {
  border-bottom-right-radius: calc((3 + 0) * var(--spacing));
}
:root .br-3-tlbr {
  border-bottom-right-radius: calc((3 + 0) * var(--spacing));
}
:root .br-3 {
  border-bottom-left-radius: calc((3 + 0) * var(--spacing));
}
:root .br-3-trbrbl {
  border-bottom-left-radius: calc((3 + 0) * var(--spacing));
}
:root .br-3-trbrbltl {
  border-bottom-left-radius: calc((3 + 0) * var(--spacing));
}
:root .br-3-tltrbrbl {
  border-bottom-left-radius: calc((3 + 0) * var(--spacing));
}
:root .br-3-trbl {
  border-bottom-left-radius: calc((3 + 0) * var(--spacing));
}
:root .br-3-trbltl {
  border-bottom-left-radius: calc((3 + 0) * var(--spacing));
}
:root .br-3-tltrbl {
  border-bottom-left-radius: calc((3 + 0) * var(--spacing));
}
:root .br-3-brbl {
  border-bottom-left-radius: calc((3 + 0) * var(--spacing));
}
:root .br-3-brbltl {
  border-bottom-left-radius: calc((3 + 0) * var(--spacing));
}
:root .br-3-tlbrbl {
  border-bottom-left-radius: calc((3 + 0) * var(--spacing));
}
:root .br-3-bl {
  border-bottom-left-radius: calc((3 + 0) * var(--spacing));
}
:root .br-3-bltl {
  border-bottom-left-radius: calc((3 + 0) * var(--spacing));
}
:root .br-3-tlbl {
  border-bottom-left-radius: calc((3 + 0) * var(--spacing));
}
:root .br-3 {
  border-top-left-radius: calc((3 + 0) * var(--spacing));
}
:root .br-3-trbrbltl {
  border-top-left-radius: calc((3 + 0) * var(--spacing));
}
:root .br-3-tltrbrbl {
  border-top-left-radius: calc((3 + 0) * var(--spacing));
}
:root .br-3-trbrtl {
  border-top-left-radius: calc((3 + 0) * var(--spacing));
}
:root .br-3-tltrbr {
  border-top-left-radius: calc((3 + 0) * var(--spacing));
}
:root .br-3-trbltl {
  border-top-left-radius: calc((3 + 0) * var(--spacing));
}
:root .br-3-tltrbl {
  border-top-left-radius: calc((3 + 0) * var(--spacing));
}
:root .br-3-trtl {
  border-top-left-radius: calc((3 + 0) * var(--spacing));
}
:root .br-3-tltr {
  border-top-left-radius: calc((3 + 0) * var(--spacing));
}
:root .br-3-brbltl {
  border-top-left-radius: calc((3 + 0) * var(--spacing));
}
:root .br-3-tlbrbl {
  border-top-left-radius: calc((3 + 0) * var(--spacing));
}
:root .br-3-brtl {
  border-top-left-radius: calc((3 + 0) * var(--spacing));
}
:root .br-3-tlbr {
  border-top-left-radius: calc((3 + 0) * var(--spacing));
}
:root .br-3-bltl {
  border-top-left-radius: calc((3 + 0) * var(--spacing));
}
:root .br-3-tlbl {
  border-top-left-radius: calc((3 + 0) * var(--spacing));
}
:root .br-3-tl {
  border-top-left-radius: calc((3 + 0) * var(--spacing));
}
:root .br-3q {
  border-top-right-radius: calc((3 + 0.25) * var(--spacing));
}
:root .br-3q-tr {
  border-top-right-radius: calc((3 + 0.25) * var(--spacing));
}
:root .br-3q-trbr {
  border-top-right-radius: calc((3 + 0.25) * var(--spacing));
}
:root .br-3q-trbrbl {
  border-top-right-radius: calc((3 + 0.25) * var(--spacing));
}
:root .br-3q-trbrbltl {
  border-top-right-radius: calc((3 + 0.25) * var(--spacing));
}
:root .br-3q-tltrbrbl {
  border-top-right-radius: calc((3 + 0.25) * var(--spacing));
}
:root .br-3q-trbrtl {
  border-top-right-radius: calc((3 + 0.25) * var(--spacing));
}
:root .br-3q-tltrbr {
  border-top-right-radius: calc((3 + 0.25) * var(--spacing));
}
:root .br-3q-trbl {
  border-top-right-radius: calc((3 + 0.25) * var(--spacing));
}
:root .br-3q-trbltl {
  border-top-right-radius: calc((3 + 0.25) * var(--spacing));
}
:root .br-3q-tltrbl {
  border-top-right-radius: calc((3 + 0.25) * var(--spacing));
}
:root .br-3q-trtl {
  border-top-right-radius: calc((3 + 0.25) * var(--spacing));
}
:root .br-3q-tltr {
  border-top-right-radius: calc((3 + 0.25) * var(--spacing));
}
:root .br-3q {
  border-bottom-right-radius: calc((3 + 0.25) * var(--spacing));
}
:root .br-3q-trbr {
  border-bottom-right-radius: calc((3 + 0.25) * var(--spacing));
}
:root .br-3q-trbrbl {
  border-bottom-right-radius: calc((3 + 0.25) * var(--spacing));
}
:root .br-3q-trbrbltl {
  border-bottom-right-radius: calc((3 + 0.25) * var(--spacing));
}
:root .br-3q-tltrbrbl {
  border-bottom-right-radius: calc((3 + 0.25) * var(--spacing));
}
:root .br-3q-trbrtl {
  border-bottom-right-radius: calc((3 + 0.25) * var(--spacing));
}
:root .br-3q-tltrbr {
  border-bottom-right-radius: calc((3 + 0.25) * var(--spacing));
}
:root .br-3q-br {
  border-bottom-right-radius: calc((3 + 0.25) * var(--spacing));
}
:root .br-3q-brbl {
  border-bottom-right-radius: calc((3 + 0.25) * var(--spacing));
}
:root .br-3q-brbltl {
  border-bottom-right-radius: calc((3 + 0.25) * var(--spacing));
}
:root .br-3q-tlbrbl {
  border-bottom-right-radius: calc((3 + 0.25) * var(--spacing));
}
:root .br-3q-brtl {
  border-bottom-right-radius: calc((3 + 0.25) * var(--spacing));
}
:root .br-3q-tlbr {
  border-bottom-right-radius: calc((3 + 0.25) * var(--spacing));
}
:root .br-3q {
  border-bottom-left-radius: calc((3 + 0.25) * var(--spacing));
}
:root .br-3q-trbrbl {
  border-bottom-left-radius: calc((3 + 0.25) * var(--spacing));
}
:root .br-3q-trbrbltl {
  border-bottom-left-radius: calc((3 + 0.25) * var(--spacing));
}
:root .br-3q-tltrbrbl {
  border-bottom-left-radius: calc((3 + 0.25) * var(--spacing));
}
:root .br-3q-trbl {
  border-bottom-left-radius: calc((3 + 0.25) * var(--spacing));
}
:root .br-3q-trbltl {
  border-bottom-left-radius: calc((3 + 0.25) * var(--spacing));
}
:root .br-3q-tltrbl {
  border-bottom-left-radius: calc((3 + 0.25) * var(--spacing));
}
:root .br-3q-brbl {
  border-bottom-left-radius: calc((3 + 0.25) * var(--spacing));
}
:root .br-3q-brbltl {
  border-bottom-left-radius: calc((3 + 0.25) * var(--spacing));
}
:root .br-3q-tlbrbl {
  border-bottom-left-radius: calc((3 + 0.25) * var(--spacing));
}
:root .br-3q-bl {
  border-bottom-left-radius: calc((3 + 0.25) * var(--spacing));
}
:root .br-3q-bltl {
  border-bottom-left-radius: calc((3 + 0.25) * var(--spacing));
}
:root .br-3q-tlbl {
  border-bottom-left-radius: calc((3 + 0.25) * var(--spacing));
}
:root .br-3q {
  border-top-left-radius: calc((3 + 0.25) * var(--spacing));
}
:root .br-3q-trbrbltl {
  border-top-left-radius: calc((3 + 0.25) * var(--spacing));
}
:root .br-3q-tltrbrbl {
  border-top-left-radius: calc((3 + 0.25) * var(--spacing));
}
:root .br-3q-trbrtl {
  border-top-left-radius: calc((3 + 0.25) * var(--spacing));
}
:root .br-3q-tltrbr {
  border-top-left-radius: calc((3 + 0.25) * var(--spacing));
}
:root .br-3q-trbltl {
  border-top-left-radius: calc((3 + 0.25) * var(--spacing));
}
:root .br-3q-tltrbl {
  border-top-left-radius: calc((3 + 0.25) * var(--spacing));
}
:root .br-3q-trtl {
  border-top-left-radius: calc((3 + 0.25) * var(--spacing));
}
:root .br-3q-tltr {
  border-top-left-radius: calc((3 + 0.25) * var(--spacing));
}
:root .br-3q-brbltl {
  border-top-left-radius: calc((3 + 0.25) * var(--spacing));
}
:root .br-3q-tlbrbl {
  border-top-left-radius: calc((3 + 0.25) * var(--spacing));
}
:root .br-3q-brtl {
  border-top-left-radius: calc((3 + 0.25) * var(--spacing));
}
:root .br-3q-tlbr {
  border-top-left-radius: calc((3 + 0.25) * var(--spacing));
}
:root .br-3q-bltl {
  border-top-left-radius: calc((3 + 0.25) * var(--spacing));
}
:root .br-3q-tlbl {
  border-top-left-radius: calc((3 + 0.25) * var(--spacing));
}
:root .br-3q-tl {
  border-top-left-radius: calc((3 + 0.25) * var(--spacing));
}
:root .br-3h {
  border-top-right-radius: calc((3 + 0.5) * var(--spacing));
}
:root .br-3h-tr {
  border-top-right-radius: calc((3 + 0.5) * var(--spacing));
}
:root .br-3h-trbr {
  border-top-right-radius: calc((3 + 0.5) * var(--spacing));
}
:root .br-3h-trbrbl {
  border-top-right-radius: calc((3 + 0.5) * var(--spacing));
}
:root .br-3h-trbrbltl {
  border-top-right-radius: calc((3 + 0.5) * var(--spacing));
}
:root .br-3h-tltrbrbl {
  border-top-right-radius: calc((3 + 0.5) * var(--spacing));
}
:root .br-3h-trbrtl {
  border-top-right-radius: calc((3 + 0.5) * var(--spacing));
}
:root .br-3h-tltrbr {
  border-top-right-radius: calc((3 + 0.5) * var(--spacing));
}
:root .br-3h-trbl {
  border-top-right-radius: calc((3 + 0.5) * var(--spacing));
}
:root .br-3h-trbltl {
  border-top-right-radius: calc((3 + 0.5) * var(--spacing));
}
:root .br-3h-tltrbl {
  border-top-right-radius: calc((3 + 0.5) * var(--spacing));
}
:root .br-3h-trtl {
  border-top-right-radius: calc((3 + 0.5) * var(--spacing));
}
:root .br-3h-tltr {
  border-top-right-radius: calc((3 + 0.5) * var(--spacing));
}
:root .br-3h {
  border-bottom-right-radius: calc((3 + 0.5) * var(--spacing));
}
:root .br-3h-trbr {
  border-bottom-right-radius: calc((3 + 0.5) * var(--spacing));
}
:root .br-3h-trbrbl {
  border-bottom-right-radius: calc((3 + 0.5) * var(--spacing));
}
:root .br-3h-trbrbltl {
  border-bottom-right-radius: calc((3 + 0.5) * var(--spacing));
}
:root .br-3h-tltrbrbl {
  border-bottom-right-radius: calc((3 + 0.5) * var(--spacing));
}
:root .br-3h-trbrtl {
  border-bottom-right-radius: calc((3 + 0.5) * var(--spacing));
}
:root .br-3h-tltrbr {
  border-bottom-right-radius: calc((3 + 0.5) * var(--spacing));
}
:root .br-3h-br {
  border-bottom-right-radius: calc((3 + 0.5) * var(--spacing));
}
:root .br-3h-brbl {
  border-bottom-right-radius: calc((3 + 0.5) * var(--spacing));
}
:root .br-3h-brbltl {
  border-bottom-right-radius: calc((3 + 0.5) * var(--spacing));
}
:root .br-3h-tlbrbl {
  border-bottom-right-radius: calc((3 + 0.5) * var(--spacing));
}
:root .br-3h-brtl {
  border-bottom-right-radius: calc((3 + 0.5) * var(--spacing));
}
:root .br-3h-tlbr {
  border-bottom-right-radius: calc((3 + 0.5) * var(--spacing));
}
:root .br-3h {
  border-bottom-left-radius: calc((3 + 0.5) * var(--spacing));
}
:root .br-3h-trbrbl {
  border-bottom-left-radius: calc((3 + 0.5) * var(--spacing));
}
:root .br-3h-trbrbltl {
  border-bottom-left-radius: calc((3 + 0.5) * var(--spacing));
}
:root .br-3h-tltrbrbl {
  border-bottom-left-radius: calc((3 + 0.5) * var(--spacing));
}
:root .br-3h-trbl {
  border-bottom-left-radius: calc((3 + 0.5) * var(--spacing));
}
:root .br-3h-trbltl {
  border-bottom-left-radius: calc((3 + 0.5) * var(--spacing));
}
:root .br-3h-tltrbl {
  border-bottom-left-radius: calc((3 + 0.5) * var(--spacing));
}
:root .br-3h-brbl {
  border-bottom-left-radius: calc((3 + 0.5) * var(--spacing));
}
:root .br-3h-brbltl {
  border-bottom-left-radius: calc((3 + 0.5) * var(--spacing));
}
:root .br-3h-tlbrbl {
  border-bottom-left-radius: calc((3 + 0.5) * var(--spacing));
}
:root .br-3h-bl {
  border-bottom-left-radius: calc((3 + 0.5) * var(--spacing));
}
:root .br-3h-bltl {
  border-bottom-left-radius: calc((3 + 0.5) * var(--spacing));
}
:root .br-3h-tlbl {
  border-bottom-left-radius: calc((3 + 0.5) * var(--spacing));
}
:root .br-3h {
  border-top-left-radius: calc((3 + 0.5) * var(--spacing));
}
:root .br-3h-trbrbltl {
  border-top-left-radius: calc((3 + 0.5) * var(--spacing));
}
:root .br-3h-tltrbrbl {
  border-top-left-radius: calc((3 + 0.5) * var(--spacing));
}
:root .br-3h-trbrtl {
  border-top-left-radius: calc((3 + 0.5) * var(--spacing));
}
:root .br-3h-tltrbr {
  border-top-left-radius: calc((3 + 0.5) * var(--spacing));
}
:root .br-3h-trbltl {
  border-top-left-radius: calc((3 + 0.5) * var(--spacing));
}
:root .br-3h-tltrbl {
  border-top-left-radius: calc((3 + 0.5) * var(--spacing));
}
:root .br-3h-trtl {
  border-top-left-radius: calc((3 + 0.5) * var(--spacing));
}
:root .br-3h-tltr {
  border-top-left-radius: calc((3 + 0.5) * var(--spacing));
}
:root .br-3h-brbltl {
  border-top-left-radius: calc((3 + 0.5) * var(--spacing));
}
:root .br-3h-tlbrbl {
  border-top-left-radius: calc((3 + 0.5) * var(--spacing));
}
:root .br-3h-brtl {
  border-top-left-radius: calc((3 + 0.5) * var(--spacing));
}
:root .br-3h-tlbr {
  border-top-left-radius: calc((3 + 0.5) * var(--spacing));
}
:root .br-3h-bltl {
  border-top-left-radius: calc((3 + 0.5) * var(--spacing));
}
:root .br-3h-tlbl {
  border-top-left-radius: calc((3 + 0.5) * var(--spacing));
}
:root .br-3h-tl {
  border-top-left-radius: calc((3 + 0.5) * var(--spacing));
}
:root .br-3t {
  border-top-right-radius: calc((3 + 0.75) * var(--spacing));
}
:root .br-3t-tr {
  border-top-right-radius: calc((3 + 0.75) * var(--spacing));
}
:root .br-3t-trbr {
  border-top-right-radius: calc((3 + 0.75) * var(--spacing));
}
:root .br-3t-trbrbl {
  border-top-right-radius: calc((3 + 0.75) * var(--spacing));
}
:root .br-3t-trbrbltl {
  border-top-right-radius: calc((3 + 0.75) * var(--spacing));
}
:root .br-3t-tltrbrbl {
  border-top-right-radius: calc((3 + 0.75) * var(--spacing));
}
:root .br-3t-trbrtl {
  border-top-right-radius: calc((3 + 0.75) * var(--spacing));
}
:root .br-3t-tltrbr {
  border-top-right-radius: calc((3 + 0.75) * var(--spacing));
}
:root .br-3t-trbl {
  border-top-right-radius: calc((3 + 0.75) * var(--spacing));
}
:root .br-3t-trbltl {
  border-top-right-radius: calc((3 + 0.75) * var(--spacing));
}
:root .br-3t-tltrbl {
  border-top-right-radius: calc((3 + 0.75) * var(--spacing));
}
:root .br-3t-trtl {
  border-top-right-radius: calc((3 + 0.75) * var(--spacing));
}
:root .br-3t-tltr {
  border-top-right-radius: calc((3 + 0.75) * var(--spacing));
}
:root .br-3t {
  border-bottom-right-radius: calc((3 + 0.75) * var(--spacing));
}
:root .br-3t-trbr {
  border-bottom-right-radius: calc((3 + 0.75) * var(--spacing));
}
:root .br-3t-trbrbl {
  border-bottom-right-radius: calc((3 + 0.75) * var(--spacing));
}
:root .br-3t-trbrbltl {
  border-bottom-right-radius: calc((3 + 0.75) * var(--spacing));
}
:root .br-3t-tltrbrbl {
  border-bottom-right-radius: calc((3 + 0.75) * var(--spacing));
}
:root .br-3t-trbrtl {
  border-bottom-right-radius: calc((3 + 0.75) * var(--spacing));
}
:root .br-3t-tltrbr {
  border-bottom-right-radius: calc((3 + 0.75) * var(--spacing));
}
:root .br-3t-br {
  border-bottom-right-radius: calc((3 + 0.75) * var(--spacing));
}
:root .br-3t-brbl {
  border-bottom-right-radius: calc((3 + 0.75) * var(--spacing));
}
:root .br-3t-brbltl {
  border-bottom-right-radius: calc((3 + 0.75) * var(--spacing));
}
:root .br-3t-tlbrbl {
  border-bottom-right-radius: calc((3 + 0.75) * var(--spacing));
}
:root .br-3t-brtl {
  border-bottom-right-radius: calc((3 + 0.75) * var(--spacing));
}
:root .br-3t-tlbr {
  border-bottom-right-radius: calc((3 + 0.75) * var(--spacing));
}
:root .br-3t {
  border-bottom-left-radius: calc((3 + 0.75) * var(--spacing));
}
:root .br-3t-trbrbl {
  border-bottom-left-radius: calc((3 + 0.75) * var(--spacing));
}
:root .br-3t-trbrbltl {
  border-bottom-left-radius: calc((3 + 0.75) * var(--spacing));
}
:root .br-3t-tltrbrbl {
  border-bottom-left-radius: calc((3 + 0.75) * var(--spacing));
}
:root .br-3t-trbl {
  border-bottom-left-radius: calc((3 + 0.75) * var(--spacing));
}
:root .br-3t-trbltl {
  border-bottom-left-radius: calc((3 + 0.75) * var(--spacing));
}
:root .br-3t-tltrbl {
  border-bottom-left-radius: calc((3 + 0.75) * var(--spacing));
}
:root .br-3t-brbl {
  border-bottom-left-radius: calc((3 + 0.75) * var(--spacing));
}
:root .br-3t-brbltl {
  border-bottom-left-radius: calc((3 + 0.75) * var(--spacing));
}
:root .br-3t-tlbrbl {
  border-bottom-left-radius: calc((3 + 0.75) * var(--spacing));
}
:root .br-3t-bl {
  border-bottom-left-radius: calc((3 + 0.75) * var(--spacing));
}
:root .br-3t-bltl {
  border-bottom-left-radius: calc((3 + 0.75) * var(--spacing));
}
:root .br-3t-tlbl {
  border-bottom-left-radius: calc((3 + 0.75) * var(--spacing));
}
:root .br-3t {
  border-top-left-radius: calc((3 + 0.75) * var(--spacing));
}
:root .br-3t-trbrbltl {
  border-top-left-radius: calc((3 + 0.75) * var(--spacing));
}
:root .br-3t-tltrbrbl {
  border-top-left-radius: calc((3 + 0.75) * var(--spacing));
}
:root .br-3t-trbrtl {
  border-top-left-radius: calc((3 + 0.75) * var(--spacing));
}
:root .br-3t-tltrbr {
  border-top-left-radius: calc((3 + 0.75) * var(--spacing));
}
:root .br-3t-trbltl {
  border-top-left-radius: calc((3 + 0.75) * var(--spacing));
}
:root .br-3t-tltrbl {
  border-top-left-radius: calc((3 + 0.75) * var(--spacing));
}
:root .br-3t-trtl {
  border-top-left-radius: calc((3 + 0.75) * var(--spacing));
}
:root .br-3t-tltr {
  border-top-left-radius: calc((3 + 0.75) * var(--spacing));
}
:root .br-3t-brbltl {
  border-top-left-radius: calc((3 + 0.75) * var(--spacing));
}
:root .br-3t-tlbrbl {
  border-top-left-radius: calc((3 + 0.75) * var(--spacing));
}
:root .br-3t-brtl {
  border-top-left-radius: calc((3 + 0.75) * var(--spacing));
}
:root .br-3t-tlbr {
  border-top-left-radius: calc((3 + 0.75) * var(--spacing));
}
:root .br-3t-bltl {
  border-top-left-radius: calc((3 + 0.75) * var(--spacing));
}
:root .br-3t-tlbl {
  border-top-left-radius: calc((3 + 0.75) * var(--spacing));
}
:root .br-3t-tl {
  border-top-left-radius: calc((3 + 0.75) * var(--spacing));
}

/*
    `m` is for margin.

	It follows the same rules as `padding.scss`, but for margin.
*/
:root .m-safe-t {
  margin-top: var(--safe-area-inset-top);
}
:root .m-safe-tr {
  margin-top: var(--safe-area-inset-top);
}
:root .m-safe-trb {
  margin-top: var(--safe-area-inset-top);
}
:root .m-safe-trbl {
  margin-top: var(--safe-area-inset-top);
}
:root .m-safe-trl {
  margin-top: var(--safe-area-inset-top);
}
:root .m-safe-tb {
  margin-top: var(--safe-area-inset-top);
}
:root .m-safe-tbl {
  margin-top: var(--safe-area-inset-top);
}
:root .m-safe-tl {
  margin-top: var(--safe-area-inset-top);
}
:root .m-safe-tr {
  margin-right: var(--safe-area-inset-right);
}
:root .m-safe-trb {
  margin-right: var(--safe-area-inset-right);
}
:root .m-safe-trbl {
  margin-right: var(--safe-area-inset-right);
}
:root .m-safe-trl {
  margin-right: var(--safe-area-inset-right);
}
:root .m-safe-r {
  margin-right: var(--safe-area-inset-right);
}
:root .m-safe-rb {
  margin-right: var(--safe-area-inset-right);
}
:root .m-safe-rbl {
  margin-right: var(--safe-area-inset-right);
}
:root .m-safe-rl {
  margin-right: var(--safe-area-inset-right);
}
:root .m-safe-trb {
  margin-bottom: var(--safe-area-inset-bottom);
}
:root .m-safe-trbl {
  margin-bottom: var(--safe-area-inset-bottom);
}
:root .m-safe-tb {
  margin-bottom: var(--safe-area-inset-bottom);
}
:root .m-safe-tbl {
  margin-bottom: var(--safe-area-inset-bottom);
}
:root .m-safe-rb {
  margin-bottom: var(--safe-area-inset-bottom);
}
:root .m-safe-rbl {
  margin-bottom: var(--safe-area-inset-bottom);
}
:root .m-safe-b {
  margin-bottom: var(--safe-area-inset-bottom);
}
:root .m-safe-bl {
  margin-bottom: var(--safe-area-inset-bottom);
}
:root .m-safe-trbl {
  margin-left: var(--safe-area-inset-left);
}
:root .m-safe-trl {
  margin-left: var(--safe-area-inset-left);
}
:root .m-safe-tbl {
  margin-left: var(--safe-area-inset-left);
}
:root .m-safe-tl {
  margin-left: var(--safe-area-inset-left);
}
:root .m-safe-rbl {
  margin-left: var(--safe-area-inset-left);
}
:root .m-safe-rl {
  margin-left: var(--safe-area-inset-left);
}
:root .m-safe-bl {
  margin-left: var(--safe-area-inset-left);
}
:root .m-safe-l {
  margin-left: var(--safe-area-inset-left);
}
:root .m-auto {
  margin: auto;
}
:root .m-auto-t {
  margin-top: auto;
}
:root .m-auto-tr {
  margin-top: auto;
}
:root .m-auto-trb {
  margin-top: auto;
}
:root .m-auto-trbl {
  margin-top: auto;
}
:root .m-auto-trl {
  margin-top: auto;
}
:root .m-auto-tb {
  margin-top: auto;
}
:root .m-auto-tbl {
  margin-top: auto;
}
:root .m-auto-tl {
  margin-top: auto;
}
:root .m-auto-tr {
  margin-right: auto;
}
:root .m-auto-trb {
  margin-right: auto;
}
:root .m-auto-trbl {
  margin-right: auto;
}
:root .m-auto-trl {
  margin-right: auto;
}
:root .m-auto-r {
  margin-right: auto;
}
:root .m-auto-rb {
  margin-right: auto;
}
:root .m-auto-rbl {
  margin-right: auto;
}
:root .m-auto-rl {
  margin-right: auto;
}
:root .m-auto-trb {
  margin-bottom: auto;
}
:root .m-auto-trbl {
  margin-bottom: auto;
}
:root .m-auto-tb {
  margin-bottom: auto;
}
:root .m-auto-tbl {
  margin-bottom: auto;
}
:root .m-auto-rb {
  margin-bottom: auto;
}
:root .m-auto-rbl {
  margin-bottom: auto;
}
:root .m-auto-b {
  margin-bottom: auto;
}
:root .m-auto-bl {
  margin-bottom: auto;
}
:root .m-auto-trbl {
  margin-left: auto;
}
:root .m-auto-trl {
  margin-left: auto;
}
:root .m-auto-tbl {
  margin-left: auto;
}
:root .m-auto-tl {
  margin-left: auto;
}
:root .m-auto-rbl {
  margin-left: auto;
}
:root .m-auto-rl {
  margin-left: auto;
}
:root .m-auto-bl {
  margin-left: auto;
}
:root .m-auto-l {
  margin-left: auto;
}
:root .m-0 {
  margin-top: calc((0 + 0) * var(--spacing));
}
:root .m-0-t {
  margin-top: calc((0 + 0) * var(--spacing));
}
:root .m-0-tr {
  margin-top: calc((0 + 0) * var(--spacing));
}
:root .m-0-trb {
  margin-top: calc((0 + 0) * var(--spacing));
}
:root .m-0-trbl {
  margin-top: calc((0 + 0) * var(--spacing));
}
:root .m-0-trl {
  margin-top: calc((0 + 0) * var(--spacing));
}
:root .m-0-tb {
  margin-top: calc((0 + 0) * var(--spacing));
}
:root .m-0-tbl {
  margin-top: calc((0 + 0) * var(--spacing));
}
:root .m-0-tl {
  margin-top: calc((0 + 0) * var(--spacing));
}
:root .m-0 {
  margin-right: calc((0 + 0) * var(--spacing));
}
:root .m-0-tr {
  margin-right: calc((0 + 0) * var(--spacing));
}
:root .m-0-trb {
  margin-right: calc((0 + 0) * var(--spacing));
}
:root .m-0-trbl {
  margin-right: calc((0 + 0) * var(--spacing));
}
:root .m-0-trl {
  margin-right: calc((0 + 0) * var(--spacing));
}
:root .m-0-r {
  margin-right: calc((0 + 0) * var(--spacing));
}
:root .m-0-rb {
  margin-right: calc((0 + 0) * var(--spacing));
}
:root .m-0-rbl {
  margin-right: calc((0 + 0) * var(--spacing));
}
:root .m-0-rl {
  margin-right: calc((0 + 0) * var(--spacing));
}
:root .m-0 {
  margin-bottom: calc((0 + 0) * var(--spacing));
}
:root .m-0-trb {
  margin-bottom: calc((0 + 0) * var(--spacing));
}
:root .m-0-trbl {
  margin-bottom: calc((0 + 0) * var(--spacing));
}
:root .m-0-tb {
  margin-bottom: calc((0 + 0) * var(--spacing));
}
:root .m-0-tbl {
  margin-bottom: calc((0 + 0) * var(--spacing));
}
:root .m-0-rb {
  margin-bottom: calc((0 + 0) * var(--spacing));
}
:root .m-0-rbl {
  margin-bottom: calc((0 + 0) * var(--spacing));
}
:root .m-0-b {
  margin-bottom: calc((0 + 0) * var(--spacing));
}
:root .m-0-bl {
  margin-bottom: calc((0 + 0) * var(--spacing));
}
:root .m-0 {
  margin-left: calc((0 + 0) * var(--spacing));
}
:root .m-0-trbl {
  margin-left: calc((0 + 0) * var(--spacing));
}
:root .m-0-trl {
  margin-left: calc((0 + 0) * var(--spacing));
}
:root .m-0-tbl {
  margin-left: calc((0 + 0) * var(--spacing));
}
:root .m-0-tl {
  margin-left: calc((0 + 0) * var(--spacing));
}
:root .m-0-rbl {
  margin-left: calc((0 + 0) * var(--spacing));
}
:root .m-0-rl {
  margin-left: calc((0 + 0) * var(--spacing));
}
:root .m-0-bl {
  margin-left: calc((0 + 0) * var(--spacing));
}
:root .m-0-l {
  margin-left: calc((0 + 0) * var(--spacing));
}
:root .m-0q {
  margin-top: calc((0 + 0.25) * var(--spacing));
}
:root .m-0q-t {
  margin-top: calc((0 + 0.25) * var(--spacing));
}
:root .m-0q-tr {
  margin-top: calc((0 + 0.25) * var(--spacing));
}
:root .m-0q-trb {
  margin-top: calc((0 + 0.25) * var(--spacing));
}
:root .m-0q-trbl {
  margin-top: calc((0 + 0.25) * var(--spacing));
}
:root .m-0q-trl {
  margin-top: calc((0 + 0.25) * var(--spacing));
}
:root .m-0q-tb {
  margin-top: calc((0 + 0.25) * var(--spacing));
}
:root .m-0q-tbl {
  margin-top: calc((0 + 0.25) * var(--spacing));
}
:root .m-0q-tl {
  margin-top: calc((0 + 0.25) * var(--spacing));
}
:root .m-0q {
  margin-right: calc((0 + 0.25) * var(--spacing));
}
:root .m-0q-tr {
  margin-right: calc((0 + 0.25) * var(--spacing));
}
:root .m-0q-trb {
  margin-right: calc((0 + 0.25) * var(--spacing));
}
:root .m-0q-trbl {
  margin-right: calc((0 + 0.25) * var(--spacing));
}
:root .m-0q-trl {
  margin-right: calc((0 + 0.25) * var(--spacing));
}
:root .m-0q-r {
  margin-right: calc((0 + 0.25) * var(--spacing));
}
:root .m-0q-rb {
  margin-right: calc((0 + 0.25) * var(--spacing));
}
:root .m-0q-rbl {
  margin-right: calc((0 + 0.25) * var(--spacing));
}
:root .m-0q-rl {
  margin-right: calc((0 + 0.25) * var(--spacing));
}
:root .m-0q {
  margin-bottom: calc((0 + 0.25) * var(--spacing));
}
:root .m-0q-trb {
  margin-bottom: calc((0 + 0.25) * var(--spacing));
}
:root .m-0q-trbl {
  margin-bottom: calc((0 + 0.25) * var(--spacing));
}
:root .m-0q-tb {
  margin-bottom: calc((0 + 0.25) * var(--spacing));
}
:root .m-0q-tbl {
  margin-bottom: calc((0 + 0.25) * var(--spacing));
}
:root .m-0q-rb {
  margin-bottom: calc((0 + 0.25) * var(--spacing));
}
:root .m-0q-rbl {
  margin-bottom: calc((0 + 0.25) * var(--spacing));
}
:root .m-0q-b {
  margin-bottom: calc((0 + 0.25) * var(--spacing));
}
:root .m-0q-bl {
  margin-bottom: calc((0 + 0.25) * var(--spacing));
}
:root .m-0q {
  margin-left: calc((0 + 0.25) * var(--spacing));
}
:root .m-0q-trbl {
  margin-left: calc((0 + 0.25) * var(--spacing));
}
:root .m-0q-trl {
  margin-left: calc((0 + 0.25) * var(--spacing));
}
:root .m-0q-tbl {
  margin-left: calc((0 + 0.25) * var(--spacing));
}
:root .m-0q-tl {
  margin-left: calc((0 + 0.25) * var(--spacing));
}
:root .m-0q-rbl {
  margin-left: calc((0 + 0.25) * var(--spacing));
}
:root .m-0q-rl {
  margin-left: calc((0 + 0.25) * var(--spacing));
}
:root .m-0q-bl {
  margin-left: calc((0 + 0.25) * var(--spacing));
}
:root .m-0q-l {
  margin-left: calc((0 + 0.25) * var(--spacing));
}
:root .m-0h {
  margin-top: calc((0 + 0.5) * var(--spacing));
}
:root .m-0h-t {
  margin-top: calc((0 + 0.5) * var(--spacing));
}
:root .m-0h-tr {
  margin-top: calc((0 + 0.5) * var(--spacing));
}
:root .m-0h-trb {
  margin-top: calc((0 + 0.5) * var(--spacing));
}
:root .m-0h-trbl {
  margin-top: calc((0 + 0.5) * var(--spacing));
}
:root .m-0h-trl {
  margin-top: calc((0 + 0.5) * var(--spacing));
}
:root .m-0h-tb {
  margin-top: calc((0 + 0.5) * var(--spacing));
}
:root .m-0h-tbl {
  margin-top: calc((0 + 0.5) * var(--spacing));
}
:root .m-0h-tl {
  margin-top: calc((0 + 0.5) * var(--spacing));
}
:root .m-0h {
  margin-right: calc((0 + 0.5) * var(--spacing));
}
:root .m-0h-tr {
  margin-right: calc((0 + 0.5) * var(--spacing));
}
:root .m-0h-trb {
  margin-right: calc((0 + 0.5) * var(--spacing));
}
:root .m-0h-trbl {
  margin-right: calc((0 + 0.5) * var(--spacing));
}
:root .m-0h-trl {
  margin-right: calc((0 + 0.5) * var(--spacing));
}
:root .m-0h-r {
  margin-right: calc((0 + 0.5) * var(--spacing));
}
:root .m-0h-rb {
  margin-right: calc((0 + 0.5) * var(--spacing));
}
:root .m-0h-rbl {
  margin-right: calc((0 + 0.5) * var(--spacing));
}
:root .m-0h-rl {
  margin-right: calc((0 + 0.5) * var(--spacing));
}
:root .m-0h {
  margin-bottom: calc((0 + 0.5) * var(--spacing));
}
:root .m-0h-trb {
  margin-bottom: calc((0 + 0.5) * var(--spacing));
}
:root .m-0h-trbl {
  margin-bottom: calc((0 + 0.5) * var(--spacing));
}
:root .m-0h-tb {
  margin-bottom: calc((0 + 0.5) * var(--spacing));
}
:root .m-0h-tbl {
  margin-bottom: calc((0 + 0.5) * var(--spacing));
}
:root .m-0h-rb {
  margin-bottom: calc((0 + 0.5) * var(--spacing));
}
:root .m-0h-rbl {
  margin-bottom: calc((0 + 0.5) * var(--spacing));
}
:root .m-0h-b {
  margin-bottom: calc((0 + 0.5) * var(--spacing));
}
:root .m-0h-bl {
  margin-bottom: calc((0 + 0.5) * var(--spacing));
}
:root .m-0h {
  margin-left: calc((0 + 0.5) * var(--spacing));
}
:root .m-0h-trbl {
  margin-left: calc((0 + 0.5) * var(--spacing));
}
:root .m-0h-trl {
  margin-left: calc((0 + 0.5) * var(--spacing));
}
:root .m-0h-tbl {
  margin-left: calc((0 + 0.5) * var(--spacing));
}
:root .m-0h-tl {
  margin-left: calc((0 + 0.5) * var(--spacing));
}
:root .m-0h-rbl {
  margin-left: calc((0 + 0.5) * var(--spacing));
}
:root .m-0h-rl {
  margin-left: calc((0 + 0.5) * var(--spacing));
}
:root .m-0h-bl {
  margin-left: calc((0 + 0.5) * var(--spacing));
}
:root .m-0h-l {
  margin-left: calc((0 + 0.5) * var(--spacing));
}
:root .m-0t {
  margin-top: calc((0 + 0.75) * var(--spacing));
}
:root .m-0t-t {
  margin-top: calc((0 + 0.75) * var(--spacing));
}
:root .m-0t-tr {
  margin-top: calc((0 + 0.75) * var(--spacing));
}
:root .m-0t-trb {
  margin-top: calc((0 + 0.75) * var(--spacing));
}
:root .m-0t-trbl {
  margin-top: calc((0 + 0.75) * var(--spacing));
}
:root .m-0t-trl {
  margin-top: calc((0 + 0.75) * var(--spacing));
}
:root .m-0t-tb {
  margin-top: calc((0 + 0.75) * var(--spacing));
}
:root .m-0t-tbl {
  margin-top: calc((0 + 0.75) * var(--spacing));
}
:root .m-0t-tl {
  margin-top: calc((0 + 0.75) * var(--spacing));
}
:root .m-0t {
  margin-right: calc((0 + 0.75) * var(--spacing));
}
:root .m-0t-tr {
  margin-right: calc((0 + 0.75) * var(--spacing));
}
:root .m-0t-trb {
  margin-right: calc((0 + 0.75) * var(--spacing));
}
:root .m-0t-trbl {
  margin-right: calc((0 + 0.75) * var(--spacing));
}
:root .m-0t-trl {
  margin-right: calc((0 + 0.75) * var(--spacing));
}
:root .m-0t-r {
  margin-right: calc((0 + 0.75) * var(--spacing));
}
:root .m-0t-rb {
  margin-right: calc((0 + 0.75) * var(--spacing));
}
:root .m-0t-rbl {
  margin-right: calc((0 + 0.75) * var(--spacing));
}
:root .m-0t-rl {
  margin-right: calc((0 + 0.75) * var(--spacing));
}
:root .m-0t {
  margin-bottom: calc((0 + 0.75) * var(--spacing));
}
:root .m-0t-trb {
  margin-bottom: calc((0 + 0.75) * var(--spacing));
}
:root .m-0t-trbl {
  margin-bottom: calc((0 + 0.75) * var(--spacing));
}
:root .m-0t-tb {
  margin-bottom: calc((0 + 0.75) * var(--spacing));
}
:root .m-0t-tbl {
  margin-bottom: calc((0 + 0.75) * var(--spacing));
}
:root .m-0t-rb {
  margin-bottom: calc((0 + 0.75) * var(--spacing));
}
:root .m-0t-rbl {
  margin-bottom: calc((0 + 0.75) * var(--spacing));
}
:root .m-0t-b {
  margin-bottom: calc((0 + 0.75) * var(--spacing));
}
:root .m-0t-bl {
  margin-bottom: calc((0 + 0.75) * var(--spacing));
}
:root .m-0t {
  margin-left: calc((0 + 0.75) * var(--spacing));
}
:root .m-0t-trbl {
  margin-left: calc((0 + 0.75) * var(--spacing));
}
:root .m-0t-trl {
  margin-left: calc((0 + 0.75) * var(--spacing));
}
:root .m-0t-tbl {
  margin-left: calc((0 + 0.75) * var(--spacing));
}
:root .m-0t-tl {
  margin-left: calc((0 + 0.75) * var(--spacing));
}
:root .m-0t-rbl {
  margin-left: calc((0 + 0.75) * var(--spacing));
}
:root .m-0t-rl {
  margin-left: calc((0 + 0.75) * var(--spacing));
}
:root .m-0t-bl {
  margin-left: calc((0 + 0.75) * var(--spacing));
}
:root .m-0t-l {
  margin-left: calc((0 + 0.75) * var(--spacing));
}
:root .m-1 {
  margin-top: calc((1 + 0) * var(--spacing));
}
:root .m-1-t {
  margin-top: calc((1 + 0) * var(--spacing));
}
:root .m-1-tr {
  margin-top: calc((1 + 0) * var(--spacing));
}
:root .m-1-trb {
  margin-top: calc((1 + 0) * var(--spacing));
}
:root .m-1-trbl {
  margin-top: calc((1 + 0) * var(--spacing));
}
:root .m-1-trl {
  margin-top: calc((1 + 0) * var(--spacing));
}
:root .m-1-tb {
  margin-top: calc((1 + 0) * var(--spacing));
}
:root .m-1-tbl {
  margin-top: calc((1 + 0) * var(--spacing));
}
:root .m-1-tl {
  margin-top: calc((1 + 0) * var(--spacing));
}
:root .m-1 {
  margin-right: calc((1 + 0) * var(--spacing));
}
:root .m-1-tr {
  margin-right: calc((1 + 0) * var(--spacing));
}
:root .m-1-trb {
  margin-right: calc((1 + 0) * var(--spacing));
}
:root .m-1-trbl {
  margin-right: calc((1 + 0) * var(--spacing));
}
:root .m-1-trl {
  margin-right: calc((1 + 0) * var(--spacing));
}
:root .m-1-r {
  margin-right: calc((1 + 0) * var(--spacing));
}
:root .m-1-rb {
  margin-right: calc((1 + 0) * var(--spacing));
}
:root .m-1-rbl {
  margin-right: calc((1 + 0) * var(--spacing));
}
:root .m-1-rl {
  margin-right: calc((1 + 0) * var(--spacing));
}
:root .m-1 {
  margin-bottom: calc((1 + 0) * var(--spacing));
}
:root .m-1-trb {
  margin-bottom: calc((1 + 0) * var(--spacing));
}
:root .m-1-trbl {
  margin-bottom: calc((1 + 0) * var(--spacing));
}
:root .m-1-tb {
  margin-bottom: calc((1 + 0) * var(--spacing));
}
:root .m-1-tbl {
  margin-bottom: calc((1 + 0) * var(--spacing));
}
:root .m-1-rb {
  margin-bottom: calc((1 + 0) * var(--spacing));
}
:root .m-1-rbl {
  margin-bottom: calc((1 + 0) * var(--spacing));
}
:root .m-1-b {
  margin-bottom: calc((1 + 0) * var(--spacing));
}
:root .m-1-bl {
  margin-bottom: calc((1 + 0) * var(--spacing));
}
:root .m-1 {
  margin-left: calc((1 + 0) * var(--spacing));
}
:root .m-1-trbl {
  margin-left: calc((1 + 0) * var(--spacing));
}
:root .m-1-trl {
  margin-left: calc((1 + 0) * var(--spacing));
}
:root .m-1-tbl {
  margin-left: calc((1 + 0) * var(--spacing));
}
:root .m-1-tl {
  margin-left: calc((1 + 0) * var(--spacing));
}
:root .m-1-rbl {
  margin-left: calc((1 + 0) * var(--spacing));
}
:root .m-1-rl {
  margin-left: calc((1 + 0) * var(--spacing));
}
:root .m-1-bl {
  margin-left: calc((1 + 0) * var(--spacing));
}
:root .m-1-l {
  margin-left: calc((1 + 0) * var(--spacing));
}
:root .m-1q {
  margin-top: calc((1 + 0.25) * var(--spacing));
}
:root .m-1q-t {
  margin-top: calc((1 + 0.25) * var(--spacing));
}
:root .m-1q-tr {
  margin-top: calc((1 + 0.25) * var(--spacing));
}
:root .m-1q-trb {
  margin-top: calc((1 + 0.25) * var(--spacing));
}
:root .m-1q-trbl {
  margin-top: calc((1 + 0.25) * var(--spacing));
}
:root .m-1q-trl {
  margin-top: calc((1 + 0.25) * var(--spacing));
}
:root .m-1q-tb {
  margin-top: calc((1 + 0.25) * var(--spacing));
}
:root .m-1q-tbl {
  margin-top: calc((1 + 0.25) * var(--spacing));
}
:root .m-1q-tl {
  margin-top: calc((1 + 0.25) * var(--spacing));
}
:root .m-1q {
  margin-right: calc((1 + 0.25) * var(--spacing));
}
:root .m-1q-tr {
  margin-right: calc((1 + 0.25) * var(--spacing));
}
:root .m-1q-trb {
  margin-right: calc((1 + 0.25) * var(--spacing));
}
:root .m-1q-trbl {
  margin-right: calc((1 + 0.25) * var(--spacing));
}
:root .m-1q-trl {
  margin-right: calc((1 + 0.25) * var(--spacing));
}
:root .m-1q-r {
  margin-right: calc((1 + 0.25) * var(--spacing));
}
:root .m-1q-rb {
  margin-right: calc((1 + 0.25) * var(--spacing));
}
:root .m-1q-rbl {
  margin-right: calc((1 + 0.25) * var(--spacing));
}
:root .m-1q-rl {
  margin-right: calc((1 + 0.25) * var(--spacing));
}
:root .m-1q {
  margin-bottom: calc((1 + 0.25) * var(--spacing));
}
:root .m-1q-trb {
  margin-bottom: calc((1 + 0.25) * var(--spacing));
}
:root .m-1q-trbl {
  margin-bottom: calc((1 + 0.25) * var(--spacing));
}
:root .m-1q-tb {
  margin-bottom: calc((1 + 0.25) * var(--spacing));
}
:root .m-1q-tbl {
  margin-bottom: calc((1 + 0.25) * var(--spacing));
}
:root .m-1q-rb {
  margin-bottom: calc((1 + 0.25) * var(--spacing));
}
:root .m-1q-rbl {
  margin-bottom: calc((1 + 0.25) * var(--spacing));
}
:root .m-1q-b {
  margin-bottom: calc((1 + 0.25) * var(--spacing));
}
:root .m-1q-bl {
  margin-bottom: calc((1 + 0.25) * var(--spacing));
}
:root .m-1q {
  margin-left: calc((1 + 0.25) * var(--spacing));
}
:root .m-1q-trbl {
  margin-left: calc((1 + 0.25) * var(--spacing));
}
:root .m-1q-trl {
  margin-left: calc((1 + 0.25) * var(--spacing));
}
:root .m-1q-tbl {
  margin-left: calc((1 + 0.25) * var(--spacing));
}
:root .m-1q-tl {
  margin-left: calc((1 + 0.25) * var(--spacing));
}
:root .m-1q-rbl {
  margin-left: calc((1 + 0.25) * var(--spacing));
}
:root .m-1q-rl {
  margin-left: calc((1 + 0.25) * var(--spacing));
}
:root .m-1q-bl {
  margin-left: calc((1 + 0.25) * var(--spacing));
}
:root .m-1q-l {
  margin-left: calc((1 + 0.25) * var(--spacing));
}
:root .m-1h {
  margin-top: calc((1 + 0.5) * var(--spacing));
}
:root .m-1h-t {
  margin-top: calc((1 + 0.5) * var(--spacing));
}
:root .m-1h-tr {
  margin-top: calc((1 + 0.5) * var(--spacing));
}
:root .m-1h-trb {
  margin-top: calc((1 + 0.5) * var(--spacing));
}
:root .m-1h-trbl {
  margin-top: calc((1 + 0.5) * var(--spacing));
}
:root .m-1h-trl {
  margin-top: calc((1 + 0.5) * var(--spacing));
}
:root .m-1h-tb {
  margin-top: calc((1 + 0.5) * var(--spacing));
}
:root .m-1h-tbl {
  margin-top: calc((1 + 0.5) * var(--spacing));
}
:root .m-1h-tl {
  margin-top: calc((1 + 0.5) * var(--spacing));
}
:root .m-1h {
  margin-right: calc((1 + 0.5) * var(--spacing));
}
:root .m-1h-tr {
  margin-right: calc((1 + 0.5) * var(--spacing));
}
:root .m-1h-trb {
  margin-right: calc((1 + 0.5) * var(--spacing));
}
:root .m-1h-trbl {
  margin-right: calc((1 + 0.5) * var(--spacing));
}
:root .m-1h-trl {
  margin-right: calc((1 + 0.5) * var(--spacing));
}
:root .m-1h-r {
  margin-right: calc((1 + 0.5) * var(--spacing));
}
:root .m-1h-rb {
  margin-right: calc((1 + 0.5) * var(--spacing));
}
:root .m-1h-rbl {
  margin-right: calc((1 + 0.5) * var(--spacing));
}
:root .m-1h-rl {
  margin-right: calc((1 + 0.5) * var(--spacing));
}
:root .m-1h {
  margin-bottom: calc((1 + 0.5) * var(--spacing));
}
:root .m-1h-trb {
  margin-bottom: calc((1 + 0.5) * var(--spacing));
}
:root .m-1h-trbl {
  margin-bottom: calc((1 + 0.5) * var(--spacing));
}
:root .m-1h-tb {
  margin-bottom: calc((1 + 0.5) * var(--spacing));
}
:root .m-1h-tbl {
  margin-bottom: calc((1 + 0.5) * var(--spacing));
}
:root .m-1h-rb {
  margin-bottom: calc((1 + 0.5) * var(--spacing));
}
:root .m-1h-rbl {
  margin-bottom: calc((1 + 0.5) * var(--spacing));
}
:root .m-1h-b {
  margin-bottom: calc((1 + 0.5) * var(--spacing));
}
:root .m-1h-bl {
  margin-bottom: calc((1 + 0.5) * var(--spacing));
}
:root .m-1h {
  margin-left: calc((1 + 0.5) * var(--spacing));
}
:root .m-1h-trbl {
  margin-left: calc((1 + 0.5) * var(--spacing));
}
:root .m-1h-trl {
  margin-left: calc((1 + 0.5) * var(--spacing));
}
:root .m-1h-tbl {
  margin-left: calc((1 + 0.5) * var(--spacing));
}
:root .m-1h-tl {
  margin-left: calc((1 + 0.5) * var(--spacing));
}
:root .m-1h-rbl {
  margin-left: calc((1 + 0.5) * var(--spacing));
}
:root .m-1h-rl {
  margin-left: calc((1 + 0.5) * var(--spacing));
}
:root .m-1h-bl {
  margin-left: calc((1 + 0.5) * var(--spacing));
}
:root .m-1h-l {
  margin-left: calc((1 + 0.5) * var(--spacing));
}
:root .m-1t {
  margin-top: calc((1 + 0.75) * var(--spacing));
}
:root .m-1t-t {
  margin-top: calc((1 + 0.75) * var(--spacing));
}
:root .m-1t-tr {
  margin-top: calc((1 + 0.75) * var(--spacing));
}
:root .m-1t-trb {
  margin-top: calc((1 + 0.75) * var(--spacing));
}
:root .m-1t-trbl {
  margin-top: calc((1 + 0.75) * var(--spacing));
}
:root .m-1t-trl {
  margin-top: calc((1 + 0.75) * var(--spacing));
}
:root .m-1t-tb {
  margin-top: calc((1 + 0.75) * var(--spacing));
}
:root .m-1t-tbl {
  margin-top: calc((1 + 0.75) * var(--spacing));
}
:root .m-1t-tl {
  margin-top: calc((1 + 0.75) * var(--spacing));
}
:root .m-1t {
  margin-right: calc((1 + 0.75) * var(--spacing));
}
:root .m-1t-tr {
  margin-right: calc((1 + 0.75) * var(--spacing));
}
:root .m-1t-trb {
  margin-right: calc((1 + 0.75) * var(--spacing));
}
:root .m-1t-trbl {
  margin-right: calc((1 + 0.75) * var(--spacing));
}
:root .m-1t-trl {
  margin-right: calc((1 + 0.75) * var(--spacing));
}
:root .m-1t-r {
  margin-right: calc((1 + 0.75) * var(--spacing));
}
:root .m-1t-rb {
  margin-right: calc((1 + 0.75) * var(--spacing));
}
:root .m-1t-rbl {
  margin-right: calc((1 + 0.75) * var(--spacing));
}
:root .m-1t-rl {
  margin-right: calc((1 + 0.75) * var(--spacing));
}
:root .m-1t {
  margin-bottom: calc((1 + 0.75) * var(--spacing));
}
:root .m-1t-trb {
  margin-bottom: calc((1 + 0.75) * var(--spacing));
}
:root .m-1t-trbl {
  margin-bottom: calc((1 + 0.75) * var(--spacing));
}
:root .m-1t-tb {
  margin-bottom: calc((1 + 0.75) * var(--spacing));
}
:root .m-1t-tbl {
  margin-bottom: calc((1 + 0.75) * var(--spacing));
}
:root .m-1t-rb {
  margin-bottom: calc((1 + 0.75) * var(--spacing));
}
:root .m-1t-rbl {
  margin-bottom: calc((1 + 0.75) * var(--spacing));
}
:root .m-1t-b {
  margin-bottom: calc((1 + 0.75) * var(--spacing));
}
:root .m-1t-bl {
  margin-bottom: calc((1 + 0.75) * var(--spacing));
}
:root .m-1t {
  margin-left: calc((1 + 0.75) * var(--spacing));
}
:root .m-1t-trbl {
  margin-left: calc((1 + 0.75) * var(--spacing));
}
:root .m-1t-trl {
  margin-left: calc((1 + 0.75) * var(--spacing));
}
:root .m-1t-tbl {
  margin-left: calc((1 + 0.75) * var(--spacing));
}
:root .m-1t-tl {
  margin-left: calc((1 + 0.75) * var(--spacing));
}
:root .m-1t-rbl {
  margin-left: calc((1 + 0.75) * var(--spacing));
}
:root .m-1t-rl {
  margin-left: calc((1 + 0.75) * var(--spacing));
}
:root .m-1t-bl {
  margin-left: calc((1 + 0.75) * var(--spacing));
}
:root .m-1t-l {
  margin-left: calc((1 + 0.75) * var(--spacing));
}
:root .m-2 {
  margin-top: calc((2 + 0) * var(--spacing));
}
:root .m-2-t {
  margin-top: calc((2 + 0) * var(--spacing));
}
:root .m-2-tr {
  margin-top: calc((2 + 0) * var(--spacing));
}
:root .m-2-trb {
  margin-top: calc((2 + 0) * var(--spacing));
}
:root .m-2-trbl {
  margin-top: calc((2 + 0) * var(--spacing));
}
:root .m-2-trl {
  margin-top: calc((2 + 0) * var(--spacing));
}
:root .m-2-tb {
  margin-top: calc((2 + 0) * var(--spacing));
}
:root .m-2-tbl {
  margin-top: calc((2 + 0) * var(--spacing));
}
:root .m-2-tl {
  margin-top: calc((2 + 0) * var(--spacing));
}
:root .m-2 {
  margin-right: calc((2 + 0) * var(--spacing));
}
:root .m-2-tr {
  margin-right: calc((2 + 0) * var(--spacing));
}
:root .m-2-trb {
  margin-right: calc((2 + 0) * var(--spacing));
}
:root .m-2-trbl {
  margin-right: calc((2 + 0) * var(--spacing));
}
:root .m-2-trl {
  margin-right: calc((2 + 0) * var(--spacing));
}
:root .m-2-r {
  margin-right: calc((2 + 0) * var(--spacing));
}
:root .m-2-rb {
  margin-right: calc((2 + 0) * var(--spacing));
}
:root .m-2-rbl {
  margin-right: calc((2 + 0) * var(--spacing));
}
:root .m-2-rl {
  margin-right: calc((2 + 0) * var(--spacing));
}
:root .m-2 {
  margin-bottom: calc((2 + 0) * var(--spacing));
}
:root .m-2-trb {
  margin-bottom: calc((2 + 0) * var(--spacing));
}
:root .m-2-trbl {
  margin-bottom: calc((2 + 0) * var(--spacing));
}
:root .m-2-tb {
  margin-bottom: calc((2 + 0) * var(--spacing));
}
:root .m-2-tbl {
  margin-bottom: calc((2 + 0) * var(--spacing));
}
:root .m-2-rb {
  margin-bottom: calc((2 + 0) * var(--spacing));
}
:root .m-2-rbl {
  margin-bottom: calc((2 + 0) * var(--spacing));
}
:root .m-2-b {
  margin-bottom: calc((2 + 0) * var(--spacing));
}
:root .m-2-bl {
  margin-bottom: calc((2 + 0) * var(--spacing));
}
:root .m-2 {
  margin-left: calc((2 + 0) * var(--spacing));
}
:root .m-2-trbl {
  margin-left: calc((2 + 0) * var(--spacing));
}
:root .m-2-trl {
  margin-left: calc((2 + 0) * var(--spacing));
}
:root .m-2-tbl {
  margin-left: calc((2 + 0) * var(--spacing));
}
:root .m-2-tl {
  margin-left: calc((2 + 0) * var(--spacing));
}
:root .m-2-rbl {
  margin-left: calc((2 + 0) * var(--spacing));
}
:root .m-2-rl {
  margin-left: calc((2 + 0) * var(--spacing));
}
:root .m-2-bl {
  margin-left: calc((2 + 0) * var(--spacing));
}
:root .m-2-l {
  margin-left: calc((2 + 0) * var(--spacing));
}
:root .m-2q {
  margin-top: calc((2 + 0.25) * var(--spacing));
}
:root .m-2q-t {
  margin-top: calc((2 + 0.25) * var(--spacing));
}
:root .m-2q-tr {
  margin-top: calc((2 + 0.25) * var(--spacing));
}
:root .m-2q-trb {
  margin-top: calc((2 + 0.25) * var(--spacing));
}
:root .m-2q-trbl {
  margin-top: calc((2 + 0.25) * var(--spacing));
}
:root .m-2q-trl {
  margin-top: calc((2 + 0.25) * var(--spacing));
}
:root .m-2q-tb {
  margin-top: calc((2 + 0.25) * var(--spacing));
}
:root .m-2q-tbl {
  margin-top: calc((2 + 0.25) * var(--spacing));
}
:root .m-2q-tl {
  margin-top: calc((2 + 0.25) * var(--spacing));
}
:root .m-2q {
  margin-right: calc((2 + 0.25) * var(--spacing));
}
:root .m-2q-tr {
  margin-right: calc((2 + 0.25) * var(--spacing));
}
:root .m-2q-trb {
  margin-right: calc((2 + 0.25) * var(--spacing));
}
:root .m-2q-trbl {
  margin-right: calc((2 + 0.25) * var(--spacing));
}
:root .m-2q-trl {
  margin-right: calc((2 + 0.25) * var(--spacing));
}
:root .m-2q-r {
  margin-right: calc((2 + 0.25) * var(--spacing));
}
:root .m-2q-rb {
  margin-right: calc((2 + 0.25) * var(--spacing));
}
:root .m-2q-rbl {
  margin-right: calc((2 + 0.25) * var(--spacing));
}
:root .m-2q-rl {
  margin-right: calc((2 + 0.25) * var(--spacing));
}
:root .m-2q {
  margin-bottom: calc((2 + 0.25) * var(--spacing));
}
:root .m-2q-trb {
  margin-bottom: calc((2 + 0.25) * var(--spacing));
}
:root .m-2q-trbl {
  margin-bottom: calc((2 + 0.25) * var(--spacing));
}
:root .m-2q-tb {
  margin-bottom: calc((2 + 0.25) * var(--spacing));
}
:root .m-2q-tbl {
  margin-bottom: calc((2 + 0.25) * var(--spacing));
}
:root .m-2q-rb {
  margin-bottom: calc((2 + 0.25) * var(--spacing));
}
:root .m-2q-rbl {
  margin-bottom: calc((2 + 0.25) * var(--spacing));
}
:root .m-2q-b {
  margin-bottom: calc((2 + 0.25) * var(--spacing));
}
:root .m-2q-bl {
  margin-bottom: calc((2 + 0.25) * var(--spacing));
}
:root .m-2q {
  margin-left: calc((2 + 0.25) * var(--spacing));
}
:root .m-2q-trbl {
  margin-left: calc((2 + 0.25) * var(--spacing));
}
:root .m-2q-trl {
  margin-left: calc((2 + 0.25) * var(--spacing));
}
:root .m-2q-tbl {
  margin-left: calc((2 + 0.25) * var(--spacing));
}
:root .m-2q-tl {
  margin-left: calc((2 + 0.25) * var(--spacing));
}
:root .m-2q-rbl {
  margin-left: calc((2 + 0.25) * var(--spacing));
}
:root .m-2q-rl {
  margin-left: calc((2 + 0.25) * var(--spacing));
}
:root .m-2q-bl {
  margin-left: calc((2 + 0.25) * var(--spacing));
}
:root .m-2q-l {
  margin-left: calc((2 + 0.25) * var(--spacing));
}
:root .m-2h {
  margin-top: calc((2 + 0.5) * var(--spacing));
}
:root .m-2h-t {
  margin-top: calc((2 + 0.5) * var(--spacing));
}
:root .m-2h-tr {
  margin-top: calc((2 + 0.5) * var(--spacing));
}
:root .m-2h-trb {
  margin-top: calc((2 + 0.5) * var(--spacing));
}
:root .m-2h-trbl {
  margin-top: calc((2 + 0.5) * var(--spacing));
}
:root .m-2h-trl {
  margin-top: calc((2 + 0.5) * var(--spacing));
}
:root .m-2h-tb {
  margin-top: calc((2 + 0.5) * var(--spacing));
}
:root .m-2h-tbl {
  margin-top: calc((2 + 0.5) * var(--spacing));
}
:root .m-2h-tl {
  margin-top: calc((2 + 0.5) * var(--spacing));
}
:root .m-2h {
  margin-right: calc((2 + 0.5) * var(--spacing));
}
:root .m-2h-tr {
  margin-right: calc((2 + 0.5) * var(--spacing));
}
:root .m-2h-trb {
  margin-right: calc((2 + 0.5) * var(--spacing));
}
:root .m-2h-trbl {
  margin-right: calc((2 + 0.5) * var(--spacing));
}
:root .m-2h-trl {
  margin-right: calc((2 + 0.5) * var(--spacing));
}
:root .m-2h-r {
  margin-right: calc((2 + 0.5) * var(--spacing));
}
:root .m-2h-rb {
  margin-right: calc((2 + 0.5) * var(--spacing));
}
:root .m-2h-rbl {
  margin-right: calc((2 + 0.5) * var(--spacing));
}
:root .m-2h-rl {
  margin-right: calc((2 + 0.5) * var(--spacing));
}
:root .m-2h {
  margin-bottom: calc((2 + 0.5) * var(--spacing));
}
:root .m-2h-trb {
  margin-bottom: calc((2 + 0.5) * var(--spacing));
}
:root .m-2h-trbl {
  margin-bottom: calc((2 + 0.5) * var(--spacing));
}
:root .m-2h-tb {
  margin-bottom: calc((2 + 0.5) * var(--spacing));
}
:root .m-2h-tbl {
  margin-bottom: calc((2 + 0.5) * var(--spacing));
}
:root .m-2h-rb {
  margin-bottom: calc((2 + 0.5) * var(--spacing));
}
:root .m-2h-rbl {
  margin-bottom: calc((2 + 0.5) * var(--spacing));
}
:root .m-2h-b {
  margin-bottom: calc((2 + 0.5) * var(--spacing));
}
:root .m-2h-bl {
  margin-bottom: calc((2 + 0.5) * var(--spacing));
}
:root .m-2h {
  margin-left: calc((2 + 0.5) * var(--spacing));
}
:root .m-2h-trbl {
  margin-left: calc((2 + 0.5) * var(--spacing));
}
:root .m-2h-trl {
  margin-left: calc((2 + 0.5) * var(--spacing));
}
:root .m-2h-tbl {
  margin-left: calc((2 + 0.5) * var(--spacing));
}
:root .m-2h-tl {
  margin-left: calc((2 + 0.5) * var(--spacing));
}
:root .m-2h-rbl {
  margin-left: calc((2 + 0.5) * var(--spacing));
}
:root .m-2h-rl {
  margin-left: calc((2 + 0.5) * var(--spacing));
}
:root .m-2h-bl {
  margin-left: calc((2 + 0.5) * var(--spacing));
}
:root .m-2h-l {
  margin-left: calc((2 + 0.5) * var(--spacing));
}
:root .m-2t {
  margin-top: calc((2 + 0.75) * var(--spacing));
}
:root .m-2t-t {
  margin-top: calc((2 + 0.75) * var(--spacing));
}
:root .m-2t-tr {
  margin-top: calc((2 + 0.75) * var(--spacing));
}
:root .m-2t-trb {
  margin-top: calc((2 + 0.75) * var(--spacing));
}
:root .m-2t-trbl {
  margin-top: calc((2 + 0.75) * var(--spacing));
}
:root .m-2t-trl {
  margin-top: calc((2 + 0.75) * var(--spacing));
}
:root .m-2t-tb {
  margin-top: calc((2 + 0.75) * var(--spacing));
}
:root .m-2t-tbl {
  margin-top: calc((2 + 0.75) * var(--spacing));
}
:root .m-2t-tl {
  margin-top: calc((2 + 0.75) * var(--spacing));
}
:root .m-2t {
  margin-right: calc((2 + 0.75) * var(--spacing));
}
:root .m-2t-tr {
  margin-right: calc((2 + 0.75) * var(--spacing));
}
:root .m-2t-trb {
  margin-right: calc((2 + 0.75) * var(--spacing));
}
:root .m-2t-trbl {
  margin-right: calc((2 + 0.75) * var(--spacing));
}
:root .m-2t-trl {
  margin-right: calc((2 + 0.75) * var(--spacing));
}
:root .m-2t-r {
  margin-right: calc((2 + 0.75) * var(--spacing));
}
:root .m-2t-rb {
  margin-right: calc((2 + 0.75) * var(--spacing));
}
:root .m-2t-rbl {
  margin-right: calc((2 + 0.75) * var(--spacing));
}
:root .m-2t-rl {
  margin-right: calc((2 + 0.75) * var(--spacing));
}
:root .m-2t {
  margin-bottom: calc((2 + 0.75) * var(--spacing));
}
:root .m-2t-trb {
  margin-bottom: calc((2 + 0.75) * var(--spacing));
}
:root .m-2t-trbl {
  margin-bottom: calc((2 + 0.75) * var(--spacing));
}
:root .m-2t-tb {
  margin-bottom: calc((2 + 0.75) * var(--spacing));
}
:root .m-2t-tbl {
  margin-bottom: calc((2 + 0.75) * var(--spacing));
}
:root .m-2t-rb {
  margin-bottom: calc((2 + 0.75) * var(--spacing));
}
:root .m-2t-rbl {
  margin-bottom: calc((2 + 0.75) * var(--spacing));
}
:root .m-2t-b {
  margin-bottom: calc((2 + 0.75) * var(--spacing));
}
:root .m-2t-bl {
  margin-bottom: calc((2 + 0.75) * var(--spacing));
}
:root .m-2t {
  margin-left: calc((2 + 0.75) * var(--spacing));
}
:root .m-2t-trbl {
  margin-left: calc((2 + 0.75) * var(--spacing));
}
:root .m-2t-trl {
  margin-left: calc((2 + 0.75) * var(--spacing));
}
:root .m-2t-tbl {
  margin-left: calc((2 + 0.75) * var(--spacing));
}
:root .m-2t-tl {
  margin-left: calc((2 + 0.75) * var(--spacing));
}
:root .m-2t-rbl {
  margin-left: calc((2 + 0.75) * var(--spacing));
}
:root .m-2t-rl {
  margin-left: calc((2 + 0.75) * var(--spacing));
}
:root .m-2t-bl {
  margin-left: calc((2 + 0.75) * var(--spacing));
}
:root .m-2t-l {
  margin-left: calc((2 + 0.75) * var(--spacing));
}
:root .m-3 {
  margin-top: calc((3 + 0) * var(--spacing));
}
:root .m-3-t {
  margin-top: calc((3 + 0) * var(--spacing));
}
:root .m-3-tr {
  margin-top: calc((3 + 0) * var(--spacing));
}
:root .m-3-trb {
  margin-top: calc((3 + 0) * var(--spacing));
}
:root .m-3-trbl {
  margin-top: calc((3 + 0) * var(--spacing));
}
:root .m-3-trl {
  margin-top: calc((3 + 0) * var(--spacing));
}
:root .m-3-tb {
  margin-top: calc((3 + 0) * var(--spacing));
}
:root .m-3-tbl {
  margin-top: calc((3 + 0) * var(--spacing));
}
:root .m-3-tl {
  margin-top: calc((3 + 0) * var(--spacing));
}
:root .m-3 {
  margin-right: calc((3 + 0) * var(--spacing));
}
:root .m-3-tr {
  margin-right: calc((3 + 0) * var(--spacing));
}
:root .m-3-trb {
  margin-right: calc((3 + 0) * var(--spacing));
}
:root .m-3-trbl {
  margin-right: calc((3 + 0) * var(--spacing));
}
:root .m-3-trl {
  margin-right: calc((3 + 0) * var(--spacing));
}
:root .m-3-r {
  margin-right: calc((3 + 0) * var(--spacing));
}
:root .m-3-rb {
  margin-right: calc((3 + 0) * var(--spacing));
}
:root .m-3-rbl {
  margin-right: calc((3 + 0) * var(--spacing));
}
:root .m-3-rl {
  margin-right: calc((3 + 0) * var(--spacing));
}
:root .m-3 {
  margin-bottom: calc((3 + 0) * var(--spacing));
}
:root .m-3-trb {
  margin-bottom: calc((3 + 0) * var(--spacing));
}
:root .m-3-trbl {
  margin-bottom: calc((3 + 0) * var(--spacing));
}
:root .m-3-tb {
  margin-bottom: calc((3 + 0) * var(--spacing));
}
:root .m-3-tbl {
  margin-bottom: calc((3 + 0) * var(--spacing));
}
:root .m-3-rb {
  margin-bottom: calc((3 + 0) * var(--spacing));
}
:root .m-3-rbl {
  margin-bottom: calc((3 + 0) * var(--spacing));
}
:root .m-3-b {
  margin-bottom: calc((3 + 0) * var(--spacing));
}
:root .m-3-bl {
  margin-bottom: calc((3 + 0) * var(--spacing));
}
:root .m-3 {
  margin-left: calc((3 + 0) * var(--spacing));
}
:root .m-3-trbl {
  margin-left: calc((3 + 0) * var(--spacing));
}
:root .m-3-trl {
  margin-left: calc((3 + 0) * var(--spacing));
}
:root .m-3-tbl {
  margin-left: calc((3 + 0) * var(--spacing));
}
:root .m-3-tl {
  margin-left: calc((3 + 0) * var(--spacing));
}
:root .m-3-rbl {
  margin-left: calc((3 + 0) * var(--spacing));
}
:root .m-3-rl {
  margin-left: calc((3 + 0) * var(--spacing));
}
:root .m-3-bl {
  margin-left: calc((3 + 0) * var(--spacing));
}
:root .m-3-l {
  margin-left: calc((3 + 0) * var(--spacing));
}
:root .m-3q {
  margin-top: calc((3 + 0.25) * var(--spacing));
}
:root .m-3q-t {
  margin-top: calc((3 + 0.25) * var(--spacing));
}
:root .m-3q-tr {
  margin-top: calc((3 + 0.25) * var(--spacing));
}
:root .m-3q-trb {
  margin-top: calc((3 + 0.25) * var(--spacing));
}
:root .m-3q-trbl {
  margin-top: calc((3 + 0.25) * var(--spacing));
}
:root .m-3q-trl {
  margin-top: calc((3 + 0.25) * var(--spacing));
}
:root .m-3q-tb {
  margin-top: calc((3 + 0.25) * var(--spacing));
}
:root .m-3q-tbl {
  margin-top: calc((3 + 0.25) * var(--spacing));
}
:root .m-3q-tl {
  margin-top: calc((3 + 0.25) * var(--spacing));
}
:root .m-3q {
  margin-right: calc((3 + 0.25) * var(--spacing));
}
:root .m-3q-tr {
  margin-right: calc((3 + 0.25) * var(--spacing));
}
:root .m-3q-trb {
  margin-right: calc((3 + 0.25) * var(--spacing));
}
:root .m-3q-trbl {
  margin-right: calc((3 + 0.25) * var(--spacing));
}
:root .m-3q-trl {
  margin-right: calc((3 + 0.25) * var(--spacing));
}
:root .m-3q-r {
  margin-right: calc((3 + 0.25) * var(--spacing));
}
:root .m-3q-rb {
  margin-right: calc((3 + 0.25) * var(--spacing));
}
:root .m-3q-rbl {
  margin-right: calc((3 + 0.25) * var(--spacing));
}
:root .m-3q-rl {
  margin-right: calc((3 + 0.25) * var(--spacing));
}
:root .m-3q {
  margin-bottom: calc((3 + 0.25) * var(--spacing));
}
:root .m-3q-trb {
  margin-bottom: calc((3 + 0.25) * var(--spacing));
}
:root .m-3q-trbl {
  margin-bottom: calc((3 + 0.25) * var(--spacing));
}
:root .m-3q-tb {
  margin-bottom: calc((3 + 0.25) * var(--spacing));
}
:root .m-3q-tbl {
  margin-bottom: calc((3 + 0.25) * var(--spacing));
}
:root .m-3q-rb {
  margin-bottom: calc((3 + 0.25) * var(--spacing));
}
:root .m-3q-rbl {
  margin-bottom: calc((3 + 0.25) * var(--spacing));
}
:root .m-3q-b {
  margin-bottom: calc((3 + 0.25) * var(--spacing));
}
:root .m-3q-bl {
  margin-bottom: calc((3 + 0.25) * var(--spacing));
}
:root .m-3q {
  margin-left: calc((3 + 0.25) * var(--spacing));
}
:root .m-3q-trbl {
  margin-left: calc((3 + 0.25) * var(--spacing));
}
:root .m-3q-trl {
  margin-left: calc((3 + 0.25) * var(--spacing));
}
:root .m-3q-tbl {
  margin-left: calc((3 + 0.25) * var(--spacing));
}
:root .m-3q-tl {
  margin-left: calc((3 + 0.25) * var(--spacing));
}
:root .m-3q-rbl {
  margin-left: calc((3 + 0.25) * var(--spacing));
}
:root .m-3q-rl {
  margin-left: calc((3 + 0.25) * var(--spacing));
}
:root .m-3q-bl {
  margin-left: calc((3 + 0.25) * var(--spacing));
}
:root .m-3q-l {
  margin-left: calc((3 + 0.25) * var(--spacing));
}
:root .m-3h {
  margin-top: calc((3 + 0.5) * var(--spacing));
}
:root .m-3h-t {
  margin-top: calc((3 + 0.5) * var(--spacing));
}
:root .m-3h-tr {
  margin-top: calc((3 + 0.5) * var(--spacing));
}
:root .m-3h-trb {
  margin-top: calc((3 + 0.5) * var(--spacing));
}
:root .m-3h-trbl {
  margin-top: calc((3 + 0.5) * var(--spacing));
}
:root .m-3h-trl {
  margin-top: calc((3 + 0.5) * var(--spacing));
}
:root .m-3h-tb {
  margin-top: calc((3 + 0.5) * var(--spacing));
}
:root .m-3h-tbl {
  margin-top: calc((3 + 0.5) * var(--spacing));
}
:root .m-3h-tl {
  margin-top: calc((3 + 0.5) * var(--spacing));
}
:root .m-3h {
  margin-right: calc((3 + 0.5) * var(--spacing));
}
:root .m-3h-tr {
  margin-right: calc((3 + 0.5) * var(--spacing));
}
:root .m-3h-trb {
  margin-right: calc((3 + 0.5) * var(--spacing));
}
:root .m-3h-trbl {
  margin-right: calc((3 + 0.5) * var(--spacing));
}
:root .m-3h-trl {
  margin-right: calc((3 + 0.5) * var(--spacing));
}
:root .m-3h-r {
  margin-right: calc((3 + 0.5) * var(--spacing));
}
:root .m-3h-rb {
  margin-right: calc((3 + 0.5) * var(--spacing));
}
:root .m-3h-rbl {
  margin-right: calc((3 + 0.5) * var(--spacing));
}
:root .m-3h-rl {
  margin-right: calc((3 + 0.5) * var(--spacing));
}
:root .m-3h {
  margin-bottom: calc((3 + 0.5) * var(--spacing));
}
:root .m-3h-trb {
  margin-bottom: calc((3 + 0.5) * var(--spacing));
}
:root .m-3h-trbl {
  margin-bottom: calc((3 + 0.5) * var(--spacing));
}
:root .m-3h-tb {
  margin-bottom: calc((3 + 0.5) * var(--spacing));
}
:root .m-3h-tbl {
  margin-bottom: calc((3 + 0.5) * var(--spacing));
}
:root .m-3h-rb {
  margin-bottom: calc((3 + 0.5) * var(--spacing));
}
:root .m-3h-rbl {
  margin-bottom: calc((3 + 0.5) * var(--spacing));
}
:root .m-3h-b {
  margin-bottom: calc((3 + 0.5) * var(--spacing));
}
:root .m-3h-bl {
  margin-bottom: calc((3 + 0.5) * var(--spacing));
}
:root .m-3h {
  margin-left: calc((3 + 0.5) * var(--spacing));
}
:root .m-3h-trbl {
  margin-left: calc((3 + 0.5) * var(--spacing));
}
:root .m-3h-trl {
  margin-left: calc((3 + 0.5) * var(--spacing));
}
:root .m-3h-tbl {
  margin-left: calc((3 + 0.5) * var(--spacing));
}
:root .m-3h-tl {
  margin-left: calc((3 + 0.5) * var(--spacing));
}
:root .m-3h-rbl {
  margin-left: calc((3 + 0.5) * var(--spacing));
}
:root .m-3h-rl {
  margin-left: calc((3 + 0.5) * var(--spacing));
}
:root .m-3h-bl {
  margin-left: calc((3 + 0.5) * var(--spacing));
}
:root .m-3h-l {
  margin-left: calc((3 + 0.5) * var(--spacing));
}
:root .m-3t {
  margin-top: calc((3 + 0.75) * var(--spacing));
}
:root .m-3t-t {
  margin-top: calc((3 + 0.75) * var(--spacing));
}
:root .m-3t-tr {
  margin-top: calc((3 + 0.75) * var(--spacing));
}
:root .m-3t-trb {
  margin-top: calc((3 + 0.75) * var(--spacing));
}
:root .m-3t-trbl {
  margin-top: calc((3 + 0.75) * var(--spacing));
}
:root .m-3t-trl {
  margin-top: calc((3 + 0.75) * var(--spacing));
}
:root .m-3t-tb {
  margin-top: calc((3 + 0.75) * var(--spacing));
}
:root .m-3t-tbl {
  margin-top: calc((3 + 0.75) * var(--spacing));
}
:root .m-3t-tl {
  margin-top: calc((3 + 0.75) * var(--spacing));
}
:root .m-3t {
  margin-right: calc((3 + 0.75) * var(--spacing));
}
:root .m-3t-tr {
  margin-right: calc((3 + 0.75) * var(--spacing));
}
:root .m-3t-trb {
  margin-right: calc((3 + 0.75) * var(--spacing));
}
:root .m-3t-trbl {
  margin-right: calc((3 + 0.75) * var(--spacing));
}
:root .m-3t-trl {
  margin-right: calc((3 + 0.75) * var(--spacing));
}
:root .m-3t-r {
  margin-right: calc((3 + 0.75) * var(--spacing));
}
:root .m-3t-rb {
  margin-right: calc((3 + 0.75) * var(--spacing));
}
:root .m-3t-rbl {
  margin-right: calc((3 + 0.75) * var(--spacing));
}
:root .m-3t-rl {
  margin-right: calc((3 + 0.75) * var(--spacing));
}
:root .m-3t {
  margin-bottom: calc((3 + 0.75) * var(--spacing));
}
:root .m-3t-trb {
  margin-bottom: calc((3 + 0.75) * var(--spacing));
}
:root .m-3t-trbl {
  margin-bottom: calc((3 + 0.75) * var(--spacing));
}
:root .m-3t-tb {
  margin-bottom: calc((3 + 0.75) * var(--spacing));
}
:root .m-3t-tbl {
  margin-bottom: calc((3 + 0.75) * var(--spacing));
}
:root .m-3t-rb {
  margin-bottom: calc((3 + 0.75) * var(--spacing));
}
:root .m-3t-rbl {
  margin-bottom: calc((3 + 0.75) * var(--spacing));
}
:root .m-3t-b {
  margin-bottom: calc((3 + 0.75) * var(--spacing));
}
:root .m-3t-bl {
  margin-bottom: calc((3 + 0.75) * var(--spacing));
}
:root .m-3t {
  margin-left: calc((3 + 0.75) * var(--spacing));
}
:root .m-3t-trbl {
  margin-left: calc((3 + 0.75) * var(--spacing));
}
:root .m-3t-trl {
  margin-left: calc((3 + 0.75) * var(--spacing));
}
:root .m-3t-tbl {
  margin-left: calc((3 + 0.75) * var(--spacing));
}
:root .m-3t-tl {
  margin-left: calc((3 + 0.75) * var(--spacing));
}
:root .m-3t-rbl {
  margin-left: calc((3 + 0.75) * var(--spacing));
}
:root .m-3t-rl {
  margin-left: calc((3 + 0.75) * var(--spacing));
}
:root .m-3t-bl {
  margin-left: calc((3 + 0.75) * var(--spacing));
}
:root .m-3t-l {
  margin-left: calc((3 + 0.75) * var(--spacing));
}
:root .m-4 {
  margin-top: calc((4 + 0) * var(--spacing));
}
:root .m-4-t {
  margin-top: calc((4 + 0) * var(--spacing));
}
:root .m-4-tr {
  margin-top: calc((4 + 0) * var(--spacing));
}
:root .m-4-trb {
  margin-top: calc((4 + 0) * var(--spacing));
}
:root .m-4-trbl {
  margin-top: calc((4 + 0) * var(--spacing));
}
:root .m-4-trl {
  margin-top: calc((4 + 0) * var(--spacing));
}
:root .m-4-tb {
  margin-top: calc((4 + 0) * var(--spacing));
}
:root .m-4-tbl {
  margin-top: calc((4 + 0) * var(--spacing));
}
:root .m-4-tl {
  margin-top: calc((4 + 0) * var(--spacing));
}
:root .m-4 {
  margin-right: calc((4 + 0) * var(--spacing));
}
:root .m-4-tr {
  margin-right: calc((4 + 0) * var(--spacing));
}
:root .m-4-trb {
  margin-right: calc((4 + 0) * var(--spacing));
}
:root .m-4-trbl {
  margin-right: calc((4 + 0) * var(--spacing));
}
:root .m-4-trl {
  margin-right: calc((4 + 0) * var(--spacing));
}
:root .m-4-r {
  margin-right: calc((4 + 0) * var(--spacing));
}
:root .m-4-rb {
  margin-right: calc((4 + 0) * var(--spacing));
}
:root .m-4-rbl {
  margin-right: calc((4 + 0) * var(--spacing));
}
:root .m-4-rl {
  margin-right: calc((4 + 0) * var(--spacing));
}
:root .m-4 {
  margin-bottom: calc((4 + 0) * var(--spacing));
}
:root .m-4-trb {
  margin-bottom: calc((4 + 0) * var(--spacing));
}
:root .m-4-trbl {
  margin-bottom: calc((4 + 0) * var(--spacing));
}
:root .m-4-tb {
  margin-bottom: calc((4 + 0) * var(--spacing));
}
:root .m-4-tbl {
  margin-bottom: calc((4 + 0) * var(--spacing));
}
:root .m-4-rb {
  margin-bottom: calc((4 + 0) * var(--spacing));
}
:root .m-4-rbl {
  margin-bottom: calc((4 + 0) * var(--spacing));
}
:root .m-4-b {
  margin-bottom: calc((4 + 0) * var(--spacing));
}
:root .m-4-bl {
  margin-bottom: calc((4 + 0) * var(--spacing));
}
:root .m-4 {
  margin-left: calc((4 + 0) * var(--spacing));
}
:root .m-4-trbl {
  margin-left: calc((4 + 0) * var(--spacing));
}
:root .m-4-trl {
  margin-left: calc((4 + 0) * var(--spacing));
}
:root .m-4-tbl {
  margin-left: calc((4 + 0) * var(--spacing));
}
:root .m-4-tl {
  margin-left: calc((4 + 0) * var(--spacing));
}
:root .m-4-rbl {
  margin-left: calc((4 + 0) * var(--spacing));
}
:root .m-4-rl {
  margin-left: calc((4 + 0) * var(--spacing));
}
:root .m-4-bl {
  margin-left: calc((4 + 0) * var(--spacing));
}
:root .m-4-l {
  margin-left: calc((4 + 0) * var(--spacing));
}
:root .m-4q {
  margin-top: calc((4 + 0.25) * var(--spacing));
}
:root .m-4q-t {
  margin-top: calc((4 + 0.25) * var(--spacing));
}
:root .m-4q-tr {
  margin-top: calc((4 + 0.25) * var(--spacing));
}
:root .m-4q-trb {
  margin-top: calc((4 + 0.25) * var(--spacing));
}
:root .m-4q-trbl {
  margin-top: calc((4 + 0.25) * var(--spacing));
}
:root .m-4q-trl {
  margin-top: calc((4 + 0.25) * var(--spacing));
}
:root .m-4q-tb {
  margin-top: calc((4 + 0.25) * var(--spacing));
}
:root .m-4q-tbl {
  margin-top: calc((4 + 0.25) * var(--spacing));
}
:root .m-4q-tl {
  margin-top: calc((4 + 0.25) * var(--spacing));
}
:root .m-4q {
  margin-right: calc((4 + 0.25) * var(--spacing));
}
:root .m-4q-tr {
  margin-right: calc((4 + 0.25) * var(--spacing));
}
:root .m-4q-trb {
  margin-right: calc((4 + 0.25) * var(--spacing));
}
:root .m-4q-trbl {
  margin-right: calc((4 + 0.25) * var(--spacing));
}
:root .m-4q-trl {
  margin-right: calc((4 + 0.25) * var(--spacing));
}
:root .m-4q-r {
  margin-right: calc((4 + 0.25) * var(--spacing));
}
:root .m-4q-rb {
  margin-right: calc((4 + 0.25) * var(--spacing));
}
:root .m-4q-rbl {
  margin-right: calc((4 + 0.25) * var(--spacing));
}
:root .m-4q-rl {
  margin-right: calc((4 + 0.25) * var(--spacing));
}
:root .m-4q {
  margin-bottom: calc((4 + 0.25) * var(--spacing));
}
:root .m-4q-trb {
  margin-bottom: calc((4 + 0.25) * var(--spacing));
}
:root .m-4q-trbl {
  margin-bottom: calc((4 + 0.25) * var(--spacing));
}
:root .m-4q-tb {
  margin-bottom: calc((4 + 0.25) * var(--spacing));
}
:root .m-4q-tbl {
  margin-bottom: calc((4 + 0.25) * var(--spacing));
}
:root .m-4q-rb {
  margin-bottom: calc((4 + 0.25) * var(--spacing));
}
:root .m-4q-rbl {
  margin-bottom: calc((4 + 0.25) * var(--spacing));
}
:root .m-4q-b {
  margin-bottom: calc((4 + 0.25) * var(--spacing));
}
:root .m-4q-bl {
  margin-bottom: calc((4 + 0.25) * var(--spacing));
}
:root .m-4q {
  margin-left: calc((4 + 0.25) * var(--spacing));
}
:root .m-4q-trbl {
  margin-left: calc((4 + 0.25) * var(--spacing));
}
:root .m-4q-trl {
  margin-left: calc((4 + 0.25) * var(--spacing));
}
:root .m-4q-tbl {
  margin-left: calc((4 + 0.25) * var(--spacing));
}
:root .m-4q-tl {
  margin-left: calc((4 + 0.25) * var(--spacing));
}
:root .m-4q-rbl {
  margin-left: calc((4 + 0.25) * var(--spacing));
}
:root .m-4q-rl {
  margin-left: calc((4 + 0.25) * var(--spacing));
}
:root .m-4q-bl {
  margin-left: calc((4 + 0.25) * var(--spacing));
}
:root .m-4q-l {
  margin-left: calc((4 + 0.25) * var(--spacing));
}
:root .m-4h {
  margin-top: calc((4 + 0.5) * var(--spacing));
}
:root .m-4h-t {
  margin-top: calc((4 + 0.5) * var(--spacing));
}
:root .m-4h-tr {
  margin-top: calc((4 + 0.5) * var(--spacing));
}
:root .m-4h-trb {
  margin-top: calc((4 + 0.5) * var(--spacing));
}
:root .m-4h-trbl {
  margin-top: calc((4 + 0.5) * var(--spacing));
}
:root .m-4h-trl {
  margin-top: calc((4 + 0.5) * var(--spacing));
}
:root .m-4h-tb {
  margin-top: calc((4 + 0.5) * var(--spacing));
}
:root .m-4h-tbl {
  margin-top: calc((4 + 0.5) * var(--spacing));
}
:root .m-4h-tl {
  margin-top: calc((4 + 0.5) * var(--spacing));
}
:root .m-4h {
  margin-right: calc((4 + 0.5) * var(--spacing));
}
:root .m-4h-tr {
  margin-right: calc((4 + 0.5) * var(--spacing));
}
:root .m-4h-trb {
  margin-right: calc((4 + 0.5) * var(--spacing));
}
:root .m-4h-trbl {
  margin-right: calc((4 + 0.5) * var(--spacing));
}
:root .m-4h-trl {
  margin-right: calc((4 + 0.5) * var(--spacing));
}
:root .m-4h-r {
  margin-right: calc((4 + 0.5) * var(--spacing));
}
:root .m-4h-rb {
  margin-right: calc((4 + 0.5) * var(--spacing));
}
:root .m-4h-rbl {
  margin-right: calc((4 + 0.5) * var(--spacing));
}
:root .m-4h-rl {
  margin-right: calc((4 + 0.5) * var(--spacing));
}
:root .m-4h {
  margin-bottom: calc((4 + 0.5) * var(--spacing));
}
:root .m-4h-trb {
  margin-bottom: calc((4 + 0.5) * var(--spacing));
}
:root .m-4h-trbl {
  margin-bottom: calc((4 + 0.5) * var(--spacing));
}
:root .m-4h-tb {
  margin-bottom: calc((4 + 0.5) * var(--spacing));
}
:root .m-4h-tbl {
  margin-bottom: calc((4 + 0.5) * var(--spacing));
}
:root .m-4h-rb {
  margin-bottom: calc((4 + 0.5) * var(--spacing));
}
:root .m-4h-rbl {
  margin-bottom: calc((4 + 0.5) * var(--spacing));
}
:root .m-4h-b {
  margin-bottom: calc((4 + 0.5) * var(--spacing));
}
:root .m-4h-bl {
  margin-bottom: calc((4 + 0.5) * var(--spacing));
}
:root .m-4h {
  margin-left: calc((4 + 0.5) * var(--spacing));
}
:root .m-4h-trbl {
  margin-left: calc((4 + 0.5) * var(--spacing));
}
:root .m-4h-trl {
  margin-left: calc((4 + 0.5) * var(--spacing));
}
:root .m-4h-tbl {
  margin-left: calc((4 + 0.5) * var(--spacing));
}
:root .m-4h-tl {
  margin-left: calc((4 + 0.5) * var(--spacing));
}
:root .m-4h-rbl {
  margin-left: calc((4 + 0.5) * var(--spacing));
}
:root .m-4h-rl {
  margin-left: calc((4 + 0.5) * var(--spacing));
}
:root .m-4h-bl {
  margin-left: calc((4 + 0.5) * var(--spacing));
}
:root .m-4h-l {
  margin-left: calc((4 + 0.5) * var(--spacing));
}
:root .m-4t {
  margin-top: calc((4 + 0.75) * var(--spacing));
}
:root .m-4t-t {
  margin-top: calc((4 + 0.75) * var(--spacing));
}
:root .m-4t-tr {
  margin-top: calc((4 + 0.75) * var(--spacing));
}
:root .m-4t-trb {
  margin-top: calc((4 + 0.75) * var(--spacing));
}
:root .m-4t-trbl {
  margin-top: calc((4 + 0.75) * var(--spacing));
}
:root .m-4t-trl {
  margin-top: calc((4 + 0.75) * var(--spacing));
}
:root .m-4t-tb {
  margin-top: calc((4 + 0.75) * var(--spacing));
}
:root .m-4t-tbl {
  margin-top: calc((4 + 0.75) * var(--spacing));
}
:root .m-4t-tl {
  margin-top: calc((4 + 0.75) * var(--spacing));
}
:root .m-4t {
  margin-right: calc((4 + 0.75) * var(--spacing));
}
:root .m-4t-tr {
  margin-right: calc((4 + 0.75) * var(--spacing));
}
:root .m-4t-trb {
  margin-right: calc((4 + 0.75) * var(--spacing));
}
:root .m-4t-trbl {
  margin-right: calc((4 + 0.75) * var(--spacing));
}
:root .m-4t-trl {
  margin-right: calc((4 + 0.75) * var(--spacing));
}
:root .m-4t-r {
  margin-right: calc((4 + 0.75) * var(--spacing));
}
:root .m-4t-rb {
  margin-right: calc((4 + 0.75) * var(--spacing));
}
:root .m-4t-rbl {
  margin-right: calc((4 + 0.75) * var(--spacing));
}
:root .m-4t-rl {
  margin-right: calc((4 + 0.75) * var(--spacing));
}
:root .m-4t {
  margin-bottom: calc((4 + 0.75) * var(--spacing));
}
:root .m-4t-trb {
  margin-bottom: calc((4 + 0.75) * var(--spacing));
}
:root .m-4t-trbl {
  margin-bottom: calc((4 + 0.75) * var(--spacing));
}
:root .m-4t-tb {
  margin-bottom: calc((4 + 0.75) * var(--spacing));
}
:root .m-4t-tbl {
  margin-bottom: calc((4 + 0.75) * var(--spacing));
}
:root .m-4t-rb {
  margin-bottom: calc((4 + 0.75) * var(--spacing));
}
:root .m-4t-rbl {
  margin-bottom: calc((4 + 0.75) * var(--spacing));
}
:root .m-4t-b {
  margin-bottom: calc((4 + 0.75) * var(--spacing));
}
:root .m-4t-bl {
  margin-bottom: calc((4 + 0.75) * var(--spacing));
}
:root .m-4t {
  margin-left: calc((4 + 0.75) * var(--spacing));
}
:root .m-4t-trbl {
  margin-left: calc((4 + 0.75) * var(--spacing));
}
:root .m-4t-trl {
  margin-left: calc((4 + 0.75) * var(--spacing));
}
:root .m-4t-tbl {
  margin-left: calc((4 + 0.75) * var(--spacing));
}
:root .m-4t-tl {
  margin-left: calc((4 + 0.75) * var(--spacing));
}
:root .m-4t-rbl {
  margin-left: calc((4 + 0.75) * var(--spacing));
}
:root .m-4t-rl {
  margin-left: calc((4 + 0.75) * var(--spacing));
}
:root .m-4t-bl {
  margin-left: calc((4 + 0.75) * var(--spacing));
}
:root .m-4t-l {
  margin-left: calc((4 + 0.75) * var(--spacing));
}
:root .m-5 {
  margin-top: calc(5 * var(--spacing));
}
:root .m-5-t {
  margin-top: calc(5 * var(--spacing));
}
:root .m-5-tr {
  margin-top: calc(5 * var(--spacing));
}
:root .m-5-trb {
  margin-top: calc(5 * var(--spacing));
}
:root .m-5-trbl {
  margin-top: calc(5 * var(--spacing));
}
:root .m-5-trl {
  margin-top: calc(5 * var(--spacing));
}
:root .m-5-tb {
  margin-top: calc(5 * var(--spacing));
}
:root .m-5-tbl {
  margin-top: calc(5 * var(--spacing));
}
:root .m-5-tl {
  margin-top: calc(5 * var(--spacing));
}
:root .m-5 {
  margin-right: calc(5 * var(--spacing));
}
:root .m-5-tr {
  margin-right: calc(5 * var(--spacing));
}
:root .m-5-trb {
  margin-right: calc(5 * var(--spacing));
}
:root .m-5-trbl {
  margin-right: calc(5 * var(--spacing));
}
:root .m-5-trl {
  margin-right: calc(5 * var(--spacing));
}
:root .m-5-r {
  margin-right: calc(5 * var(--spacing));
}
:root .m-5-rb {
  margin-right: calc(5 * var(--spacing));
}
:root .m-5-rbl {
  margin-right: calc(5 * var(--spacing));
}
:root .m-5-rl {
  margin-right: calc(5 * var(--spacing));
}
:root .m-5 {
  margin-bottom: calc(5 * var(--spacing));
}
:root .m-5-trb {
  margin-bottom: calc(5 * var(--spacing));
}
:root .m-5-trbl {
  margin-bottom: calc(5 * var(--spacing));
}
:root .m-5-tb {
  margin-bottom: calc(5 * var(--spacing));
}
:root .m-5-tbl {
  margin-bottom: calc(5 * var(--spacing));
}
:root .m-5-rb {
  margin-bottom: calc(5 * var(--spacing));
}
:root .m-5-rbl {
  margin-bottom: calc(5 * var(--spacing));
}
:root .m-5-b {
  margin-bottom: calc(5 * var(--spacing));
}
:root .m-5-bl {
  margin-bottom: calc(5 * var(--spacing));
}
:root .m-5 {
  margin-left: calc(5 * var(--spacing));
}
:root .m-5-trbl {
  margin-left: calc(5 * var(--spacing));
}
:root .m-5-trl {
  margin-left: calc(5 * var(--spacing));
}
:root .m-5-tbl {
  margin-left: calc(5 * var(--spacing));
}
:root .m-5-tl {
  margin-left: calc(5 * var(--spacing));
}
:root .m-5-rbl {
  margin-left: calc(5 * var(--spacing));
}
:root .m-5-rl {
  margin-left: calc(5 * var(--spacing));
}
:root .m-5-bl {
  margin-left: calc(5 * var(--spacing));
}
:root .m-5-l {
  margin-left: calc(5 * var(--spacing));
}
:root .m-6 {
  margin-top: calc(6 * var(--spacing));
}
:root .m-6-t {
  margin-top: calc(6 * var(--spacing));
}
:root .m-6-tr {
  margin-top: calc(6 * var(--spacing));
}
:root .m-6-trb {
  margin-top: calc(6 * var(--spacing));
}
:root .m-6-trbl {
  margin-top: calc(6 * var(--spacing));
}
:root .m-6-trl {
  margin-top: calc(6 * var(--spacing));
}
:root .m-6-tb {
  margin-top: calc(6 * var(--spacing));
}
:root .m-6-tbl {
  margin-top: calc(6 * var(--spacing));
}
:root .m-6-tl {
  margin-top: calc(6 * var(--spacing));
}
:root .m-6 {
  margin-right: calc(6 * var(--spacing));
}
:root .m-6-tr {
  margin-right: calc(6 * var(--spacing));
}
:root .m-6-trb {
  margin-right: calc(6 * var(--spacing));
}
:root .m-6-trbl {
  margin-right: calc(6 * var(--spacing));
}
:root .m-6-trl {
  margin-right: calc(6 * var(--spacing));
}
:root .m-6-r {
  margin-right: calc(6 * var(--spacing));
}
:root .m-6-rb {
  margin-right: calc(6 * var(--spacing));
}
:root .m-6-rbl {
  margin-right: calc(6 * var(--spacing));
}
:root .m-6-rl {
  margin-right: calc(6 * var(--spacing));
}
:root .m-6 {
  margin-bottom: calc(6 * var(--spacing));
}
:root .m-6-trb {
  margin-bottom: calc(6 * var(--spacing));
}
:root .m-6-trbl {
  margin-bottom: calc(6 * var(--spacing));
}
:root .m-6-tb {
  margin-bottom: calc(6 * var(--spacing));
}
:root .m-6-tbl {
  margin-bottom: calc(6 * var(--spacing));
}
:root .m-6-rb {
  margin-bottom: calc(6 * var(--spacing));
}
:root .m-6-rbl {
  margin-bottom: calc(6 * var(--spacing));
}
:root .m-6-b {
  margin-bottom: calc(6 * var(--spacing));
}
:root .m-6-bl {
  margin-bottom: calc(6 * var(--spacing));
}
:root .m-6 {
  margin-left: calc(6 * var(--spacing));
}
:root .m-6-trbl {
  margin-left: calc(6 * var(--spacing));
}
:root .m-6-trl {
  margin-left: calc(6 * var(--spacing));
}
:root .m-6-tbl {
  margin-left: calc(6 * var(--spacing));
}
:root .m-6-tl {
  margin-left: calc(6 * var(--spacing));
}
:root .m-6-rbl {
  margin-left: calc(6 * var(--spacing));
}
:root .m-6-rl {
  margin-left: calc(6 * var(--spacing));
}
:root .m-6-bl {
  margin-left: calc(6 * var(--spacing));
}
:root .m-6-l {
  margin-left: calc(6 * var(--spacing));
}
:root .m-7 {
  margin-top: calc(7 * var(--spacing));
}
:root .m-7-t {
  margin-top: calc(7 * var(--spacing));
}
:root .m-7-tr {
  margin-top: calc(7 * var(--spacing));
}
:root .m-7-trb {
  margin-top: calc(7 * var(--spacing));
}
:root .m-7-trbl {
  margin-top: calc(7 * var(--spacing));
}
:root .m-7-trl {
  margin-top: calc(7 * var(--spacing));
}
:root .m-7-tb {
  margin-top: calc(7 * var(--spacing));
}
:root .m-7-tbl {
  margin-top: calc(7 * var(--spacing));
}
:root .m-7-tl {
  margin-top: calc(7 * var(--spacing));
}
:root .m-7 {
  margin-right: calc(7 * var(--spacing));
}
:root .m-7-tr {
  margin-right: calc(7 * var(--spacing));
}
:root .m-7-trb {
  margin-right: calc(7 * var(--spacing));
}
:root .m-7-trbl {
  margin-right: calc(7 * var(--spacing));
}
:root .m-7-trl {
  margin-right: calc(7 * var(--spacing));
}
:root .m-7-r {
  margin-right: calc(7 * var(--spacing));
}
:root .m-7-rb {
  margin-right: calc(7 * var(--spacing));
}
:root .m-7-rbl {
  margin-right: calc(7 * var(--spacing));
}
:root .m-7-rl {
  margin-right: calc(7 * var(--spacing));
}
:root .m-7 {
  margin-bottom: calc(7 * var(--spacing));
}
:root .m-7-trb {
  margin-bottom: calc(7 * var(--spacing));
}
:root .m-7-trbl {
  margin-bottom: calc(7 * var(--spacing));
}
:root .m-7-tb {
  margin-bottom: calc(7 * var(--spacing));
}
:root .m-7-tbl {
  margin-bottom: calc(7 * var(--spacing));
}
:root .m-7-rb {
  margin-bottom: calc(7 * var(--spacing));
}
:root .m-7-rbl {
  margin-bottom: calc(7 * var(--spacing));
}
:root .m-7-b {
  margin-bottom: calc(7 * var(--spacing));
}
:root .m-7-bl {
  margin-bottom: calc(7 * var(--spacing));
}
:root .m-7 {
  margin-left: calc(7 * var(--spacing));
}
:root .m-7-trbl {
  margin-left: calc(7 * var(--spacing));
}
:root .m-7-trl {
  margin-left: calc(7 * var(--spacing));
}
:root .m-7-tbl {
  margin-left: calc(7 * var(--spacing));
}
:root .m-7-tl {
  margin-left: calc(7 * var(--spacing));
}
:root .m-7-rbl {
  margin-left: calc(7 * var(--spacing));
}
:root .m-7-rl {
  margin-left: calc(7 * var(--spacing));
}
:root .m-7-bl {
  margin-left: calc(7 * var(--spacing));
}
:root .m-7-l {
  margin-left: calc(7 * var(--spacing));
}
:root .m-8 {
  margin-top: calc(8 * var(--spacing));
}
:root .m-8-t {
  margin-top: calc(8 * var(--spacing));
}
:root .m-8-tr {
  margin-top: calc(8 * var(--spacing));
}
:root .m-8-trb {
  margin-top: calc(8 * var(--spacing));
}
:root .m-8-trbl {
  margin-top: calc(8 * var(--spacing));
}
:root .m-8-trl {
  margin-top: calc(8 * var(--spacing));
}
:root .m-8-tb {
  margin-top: calc(8 * var(--spacing));
}
:root .m-8-tbl {
  margin-top: calc(8 * var(--spacing));
}
:root .m-8-tl {
  margin-top: calc(8 * var(--spacing));
}
:root .m-8 {
  margin-right: calc(8 * var(--spacing));
}
:root .m-8-tr {
  margin-right: calc(8 * var(--spacing));
}
:root .m-8-trb {
  margin-right: calc(8 * var(--spacing));
}
:root .m-8-trbl {
  margin-right: calc(8 * var(--spacing));
}
:root .m-8-trl {
  margin-right: calc(8 * var(--spacing));
}
:root .m-8-r {
  margin-right: calc(8 * var(--spacing));
}
:root .m-8-rb {
  margin-right: calc(8 * var(--spacing));
}
:root .m-8-rbl {
  margin-right: calc(8 * var(--spacing));
}
:root .m-8-rl {
  margin-right: calc(8 * var(--spacing));
}
:root .m-8 {
  margin-bottom: calc(8 * var(--spacing));
}
:root .m-8-trb {
  margin-bottom: calc(8 * var(--spacing));
}
:root .m-8-trbl {
  margin-bottom: calc(8 * var(--spacing));
}
:root .m-8-tb {
  margin-bottom: calc(8 * var(--spacing));
}
:root .m-8-tbl {
  margin-bottom: calc(8 * var(--spacing));
}
:root .m-8-rb {
  margin-bottom: calc(8 * var(--spacing));
}
:root .m-8-rbl {
  margin-bottom: calc(8 * var(--spacing));
}
:root .m-8-b {
  margin-bottom: calc(8 * var(--spacing));
}
:root .m-8-bl {
  margin-bottom: calc(8 * var(--spacing));
}
:root .m-8 {
  margin-left: calc(8 * var(--spacing));
}
:root .m-8-trbl {
  margin-left: calc(8 * var(--spacing));
}
:root .m-8-trl {
  margin-left: calc(8 * var(--spacing));
}
:root .m-8-tbl {
  margin-left: calc(8 * var(--spacing));
}
:root .m-8-tl {
  margin-left: calc(8 * var(--spacing));
}
:root .m-8-rbl {
  margin-left: calc(8 * var(--spacing));
}
:root .m-8-rl {
  margin-left: calc(8 * var(--spacing));
}
:root .m-8-bl {
  margin-left: calc(8 * var(--spacing));
}
:root .m-8-l {
  margin-left: calc(8 * var(--spacing));
}
:root .m-9 {
  margin-top: calc(9 * var(--spacing));
}
:root .m-9-t {
  margin-top: calc(9 * var(--spacing));
}
:root .m-9-tr {
  margin-top: calc(9 * var(--spacing));
}
:root .m-9-trb {
  margin-top: calc(9 * var(--spacing));
}
:root .m-9-trbl {
  margin-top: calc(9 * var(--spacing));
}
:root .m-9-trl {
  margin-top: calc(9 * var(--spacing));
}
:root .m-9-tb {
  margin-top: calc(9 * var(--spacing));
}
:root .m-9-tbl {
  margin-top: calc(9 * var(--spacing));
}
:root .m-9-tl {
  margin-top: calc(9 * var(--spacing));
}
:root .m-9 {
  margin-right: calc(9 * var(--spacing));
}
:root .m-9-tr {
  margin-right: calc(9 * var(--spacing));
}
:root .m-9-trb {
  margin-right: calc(9 * var(--spacing));
}
:root .m-9-trbl {
  margin-right: calc(9 * var(--spacing));
}
:root .m-9-trl {
  margin-right: calc(9 * var(--spacing));
}
:root .m-9-r {
  margin-right: calc(9 * var(--spacing));
}
:root .m-9-rb {
  margin-right: calc(9 * var(--spacing));
}
:root .m-9-rbl {
  margin-right: calc(9 * var(--spacing));
}
:root .m-9-rl {
  margin-right: calc(9 * var(--spacing));
}
:root .m-9 {
  margin-bottom: calc(9 * var(--spacing));
}
:root .m-9-trb {
  margin-bottom: calc(9 * var(--spacing));
}
:root .m-9-trbl {
  margin-bottom: calc(9 * var(--spacing));
}
:root .m-9-tb {
  margin-bottom: calc(9 * var(--spacing));
}
:root .m-9-tbl {
  margin-bottom: calc(9 * var(--spacing));
}
:root .m-9-rb {
  margin-bottom: calc(9 * var(--spacing));
}
:root .m-9-rbl {
  margin-bottom: calc(9 * var(--spacing));
}
:root .m-9-b {
  margin-bottom: calc(9 * var(--spacing));
}
:root .m-9-bl {
  margin-bottom: calc(9 * var(--spacing));
}
:root .m-9 {
  margin-left: calc(9 * var(--spacing));
}
:root .m-9-trbl {
  margin-left: calc(9 * var(--spacing));
}
:root .m-9-trl {
  margin-left: calc(9 * var(--spacing));
}
:root .m-9-tbl {
  margin-left: calc(9 * var(--spacing));
}
:root .m-9-tl {
  margin-left: calc(9 * var(--spacing));
}
:root .m-9-rbl {
  margin-left: calc(9 * var(--spacing));
}
:root .m-9-rl {
  margin-left: calc(9 * var(--spacing));
}
:root .m-9-bl {
  margin-left: calc(9 * var(--spacing));
}
:root .m-9-l {
  margin-left: calc(9 * var(--spacing));
}
:root .m-10 {
  margin-top: calc(10 * var(--spacing));
}
:root .m-10-t {
  margin-top: calc(10 * var(--spacing));
}
:root .m-10-tr {
  margin-top: calc(10 * var(--spacing));
}
:root .m-10-trb {
  margin-top: calc(10 * var(--spacing));
}
:root .m-10-trbl {
  margin-top: calc(10 * var(--spacing));
}
:root .m-10-trl {
  margin-top: calc(10 * var(--spacing));
}
:root .m-10-tb {
  margin-top: calc(10 * var(--spacing));
}
:root .m-10-tbl {
  margin-top: calc(10 * var(--spacing));
}
:root .m-10-tl {
  margin-top: calc(10 * var(--spacing));
}
:root .m-10 {
  margin-right: calc(10 * var(--spacing));
}
:root .m-10-tr {
  margin-right: calc(10 * var(--spacing));
}
:root .m-10-trb {
  margin-right: calc(10 * var(--spacing));
}
:root .m-10-trbl {
  margin-right: calc(10 * var(--spacing));
}
:root .m-10-trl {
  margin-right: calc(10 * var(--spacing));
}
:root .m-10-r {
  margin-right: calc(10 * var(--spacing));
}
:root .m-10-rb {
  margin-right: calc(10 * var(--spacing));
}
:root .m-10-rbl {
  margin-right: calc(10 * var(--spacing));
}
:root .m-10-rl {
  margin-right: calc(10 * var(--spacing));
}
:root .m-10 {
  margin-bottom: calc(10 * var(--spacing));
}
:root .m-10-trb {
  margin-bottom: calc(10 * var(--spacing));
}
:root .m-10-trbl {
  margin-bottom: calc(10 * var(--spacing));
}
:root .m-10-tb {
  margin-bottom: calc(10 * var(--spacing));
}
:root .m-10-tbl {
  margin-bottom: calc(10 * var(--spacing));
}
:root .m-10-rb {
  margin-bottom: calc(10 * var(--spacing));
}
:root .m-10-rbl {
  margin-bottom: calc(10 * var(--spacing));
}
:root .m-10-b {
  margin-bottom: calc(10 * var(--spacing));
}
:root .m-10-bl {
  margin-bottom: calc(10 * var(--spacing));
}
:root .m-10 {
  margin-left: calc(10 * var(--spacing));
}
:root .m-10-trbl {
  margin-left: calc(10 * var(--spacing));
}
:root .m-10-trl {
  margin-left: calc(10 * var(--spacing));
}
:root .m-10-tbl {
  margin-left: calc(10 * var(--spacing));
}
:root .m-10-tl {
  margin-left: calc(10 * var(--spacing));
}
:root .m-10-rbl {
  margin-left: calc(10 * var(--spacing));
}
:root .m-10-rl {
  margin-left: calc(10 * var(--spacing));
}
:root .m-10-bl {
  margin-left: calc(10 * var(--spacing));
}
:root .m-10-l {
  margin-left: calc(10 * var(--spacing));
}

/*
    `p` is for padding.
    `p-x` is for padding with x times --spacing.
    `p-xh` is for padding with x.5 times --spacing (`h` is for half`, but you can use `.5` also).
    `p-x-y` is for padding-Y with x times --spacing.
    Where y:
        - can be 't', 'r', 'b' or 'l' (top, right, bottom, left).
        - can be combined with other "y's", but following a clock-wise order starting at top (t -> r -> b -> l).
            e.g.: `p-1-trl` means "padding 1 at top, right and left".
            e.g.: `p-1-ltr` is invalid, because it is not clock-wise starting at top.
        - if not specified (e.g.: `p-2`, it assumes all sides).
*/
:root .p-safe {
  padding: var(--safe-area-inset-top) var(--safe-area-inset-right) var(--safe-area-inset-bottom) var(--safe-area-inset-left);
}
:root .p-safe-t {
  padding-top: var(--safe-area-inset-top);
}
:root .p-safe-tr {
  padding-top: var(--safe-area-inset-top);
}
:root .p-safe-trb {
  padding-top: var(--safe-area-inset-top);
}
:root .p-safe-trbl {
  padding-top: var(--safe-area-inset-top);
}
:root .p-safe-trl {
  padding-top: var(--safe-area-inset-top);
}
:root .p-safe-tb {
  padding-top: var(--safe-area-inset-top);
}
:root .p-safe-tbl {
  padding-top: var(--safe-area-inset-top);
}
:root .p-safe-tl {
  padding-top: var(--safe-area-inset-top);
}
:root .p-safe-tr {
  padding-right: var(--safe-area-inset-right);
}
:root .p-safe-trb {
  padding-right: var(--safe-area-inset-right);
}
:root .p-safe-trbl {
  padding-right: var(--safe-area-inset-right);
}
:root .p-safe-trl {
  padding-right: var(--safe-area-inset-right);
}
:root .p-safe-r {
  padding-right: var(--safe-area-inset-right);
}
:root .p-safe-rb {
  padding-right: var(--safe-area-inset-right);
}
:root .p-safe-rbl {
  padding-right: var(--safe-area-inset-right);
}
:root .p-safe-rl {
  padding-right: var(--safe-area-inset-right);
}
:root .p-safe-trb {
  padding-bottom: var(--safe-area-inset-bottom);
}
:root .p-safe-trbl {
  padding-bottom: var(--safe-area-inset-bottom);
}
:root .p-safe-tb {
  padding-bottom: var(--safe-area-inset-bottom);
}
:root .p-safe-tbl {
  padding-bottom: var(--safe-area-inset-bottom);
}
:root .p-safe-rb {
  padding-bottom: var(--safe-area-inset-bottom);
}
:root .p-safe-rbl {
  padding-bottom: var(--safe-area-inset-bottom);
}
:root .p-safe-b {
  padding-bottom: var(--safe-area-inset-bottom);
}
:root .p-safe-bl {
  padding-bottom: var(--safe-area-inset-bottom);
}
:root .p-safe-trbl {
  padding-left: var(--safe-area-inset-left);
}
:root .p-safe-trl {
  padding-left: var(--safe-area-inset-left);
}
:root .p-safe-tbl {
  padding-left: var(--safe-area-inset-left);
}
:root .p-safe-tl {
  padding-left: var(--safe-area-inset-left);
}
:root .p-safe-rbl {
  padding-left: var(--safe-area-inset-left);
}
:root .p-safe-rl {
  padding-left: var(--safe-area-inset-left);
}
:root .p-safe-bl {
  padding-left: var(--safe-area-inset-left);
}
:root .p-safe-l {
  padding-left: var(--safe-area-inset-left);
}
:root .p-0 {
  padding-top: calc((0 + 0) * var(--spacing));
}
:root .p-0-t {
  padding-top: calc((0 + 0) * var(--spacing));
}
:root .p-0-tr {
  padding-top: calc((0 + 0) * var(--spacing));
}
:root .p-0-trb {
  padding-top: calc((0 + 0) * var(--spacing));
}
:root .p-0-trbl {
  padding-top: calc((0 + 0) * var(--spacing));
}
:root .p-0-trl {
  padding-top: calc((0 + 0) * var(--spacing));
}
:root .p-0-tb {
  padding-top: calc((0 + 0) * var(--spacing));
}
:root .p-0-tbl {
  padding-top: calc((0 + 0) * var(--spacing));
}
:root .p-0-tl {
  padding-top: calc((0 + 0) * var(--spacing));
}
:root .p-0 {
  padding-right: calc((0 + 0) * var(--spacing));
}
:root .p-0-tr {
  padding-right: calc((0 + 0) * var(--spacing));
}
:root .p-0-trb {
  padding-right: calc((0 + 0) * var(--spacing));
}
:root .p-0-trbl {
  padding-right: calc((0 + 0) * var(--spacing));
}
:root .p-0-trl {
  padding-right: calc((0 + 0) * var(--spacing));
}
:root .p-0-r {
  padding-right: calc((0 + 0) * var(--spacing));
}
:root .p-0-rb {
  padding-right: calc((0 + 0) * var(--spacing));
}
:root .p-0-rbl {
  padding-right: calc((0 + 0) * var(--spacing));
}
:root .p-0-rl {
  padding-right: calc((0 + 0) * var(--spacing));
}
:root .p-0 {
  padding-bottom: calc((0 + 0) * var(--spacing));
}
:root .p-0-trb {
  padding-bottom: calc((0 + 0) * var(--spacing));
}
:root .p-0-trbl {
  padding-bottom: calc((0 + 0) * var(--spacing));
}
:root .p-0-tb {
  padding-bottom: calc((0 + 0) * var(--spacing));
}
:root .p-0-tbl {
  padding-bottom: calc((0 + 0) * var(--spacing));
}
:root .p-0-rb {
  padding-bottom: calc((0 + 0) * var(--spacing));
}
:root .p-0-rbl {
  padding-bottom: calc((0 + 0) * var(--spacing));
}
:root .p-0-b {
  padding-bottom: calc((0 + 0) * var(--spacing));
}
:root .p-0-bl {
  padding-bottom: calc((0 + 0) * var(--spacing));
}
:root .p-0 {
  padding-left: calc((0 + 0) * var(--spacing));
}
:root .p-0-trbl {
  padding-left: calc((0 + 0) * var(--spacing));
}
:root .p-0-trl {
  padding-left: calc((0 + 0) * var(--spacing));
}
:root .p-0-tbl {
  padding-left: calc((0 + 0) * var(--spacing));
}
:root .p-0-tl {
  padding-left: calc((0 + 0) * var(--spacing));
}
:root .p-0-rbl {
  padding-left: calc((0 + 0) * var(--spacing));
}
:root .p-0-rl {
  padding-left: calc((0 + 0) * var(--spacing));
}
:root .p-0-bl {
  padding-left: calc((0 + 0) * var(--spacing));
}
:root .p-0-l {
  padding-left: calc((0 + 0) * var(--spacing));
}
:root .p-0q {
  padding-top: calc((0 + 0.25) * var(--spacing));
}
:root .p-0q-t {
  padding-top: calc((0 + 0.25) * var(--spacing));
}
:root .p-0q-tr {
  padding-top: calc((0 + 0.25) * var(--spacing));
}
:root .p-0q-trb {
  padding-top: calc((0 + 0.25) * var(--spacing));
}
:root .p-0q-trbl {
  padding-top: calc((0 + 0.25) * var(--spacing));
}
:root .p-0q-trl {
  padding-top: calc((0 + 0.25) * var(--spacing));
}
:root .p-0q-tb {
  padding-top: calc((0 + 0.25) * var(--spacing));
}
:root .p-0q-tbl {
  padding-top: calc((0 + 0.25) * var(--spacing));
}
:root .p-0q-tl {
  padding-top: calc((0 + 0.25) * var(--spacing));
}
:root .p-0q {
  padding-right: calc((0 + 0.25) * var(--spacing));
}
:root .p-0q-tr {
  padding-right: calc((0 + 0.25) * var(--spacing));
}
:root .p-0q-trb {
  padding-right: calc((0 + 0.25) * var(--spacing));
}
:root .p-0q-trbl {
  padding-right: calc((0 + 0.25) * var(--spacing));
}
:root .p-0q-trl {
  padding-right: calc((0 + 0.25) * var(--spacing));
}
:root .p-0q-r {
  padding-right: calc((0 + 0.25) * var(--spacing));
}
:root .p-0q-rb {
  padding-right: calc((0 + 0.25) * var(--spacing));
}
:root .p-0q-rbl {
  padding-right: calc((0 + 0.25) * var(--spacing));
}
:root .p-0q-rl {
  padding-right: calc((0 + 0.25) * var(--spacing));
}
:root .p-0q {
  padding-bottom: calc((0 + 0.25) * var(--spacing));
}
:root .p-0q-trb {
  padding-bottom: calc((0 + 0.25) * var(--spacing));
}
:root .p-0q-trbl {
  padding-bottom: calc((0 + 0.25) * var(--spacing));
}
:root .p-0q-tb {
  padding-bottom: calc((0 + 0.25) * var(--spacing));
}
:root .p-0q-tbl {
  padding-bottom: calc((0 + 0.25) * var(--spacing));
}
:root .p-0q-rb {
  padding-bottom: calc((0 + 0.25) * var(--spacing));
}
:root .p-0q-rbl {
  padding-bottom: calc((0 + 0.25) * var(--spacing));
}
:root .p-0q-b {
  padding-bottom: calc((0 + 0.25) * var(--spacing));
}
:root .p-0q-bl {
  padding-bottom: calc((0 + 0.25) * var(--spacing));
}
:root .p-0q {
  padding-left: calc((0 + 0.25) * var(--spacing));
}
:root .p-0q-trbl {
  padding-left: calc((0 + 0.25) * var(--spacing));
}
:root .p-0q-trl {
  padding-left: calc((0 + 0.25) * var(--spacing));
}
:root .p-0q-tbl {
  padding-left: calc((0 + 0.25) * var(--spacing));
}
:root .p-0q-tl {
  padding-left: calc((0 + 0.25) * var(--spacing));
}
:root .p-0q-rbl {
  padding-left: calc((0 + 0.25) * var(--spacing));
}
:root .p-0q-rl {
  padding-left: calc((0 + 0.25) * var(--spacing));
}
:root .p-0q-bl {
  padding-left: calc((0 + 0.25) * var(--spacing));
}
:root .p-0q-l {
  padding-left: calc((0 + 0.25) * var(--spacing));
}
:root .p-0h {
  padding-top: calc((0 + 0.5) * var(--spacing));
}
:root .p-0h-t {
  padding-top: calc((0 + 0.5) * var(--spacing));
}
:root .p-0h-tr {
  padding-top: calc((0 + 0.5) * var(--spacing));
}
:root .p-0h-trb {
  padding-top: calc((0 + 0.5) * var(--spacing));
}
:root .p-0h-trbl {
  padding-top: calc((0 + 0.5) * var(--spacing));
}
:root .p-0h-trl {
  padding-top: calc((0 + 0.5) * var(--spacing));
}
:root .p-0h-tb {
  padding-top: calc((0 + 0.5) * var(--spacing));
}
:root .p-0h-tbl {
  padding-top: calc((0 + 0.5) * var(--spacing));
}
:root .p-0h-tl {
  padding-top: calc((0 + 0.5) * var(--spacing));
}
:root .p-0h {
  padding-right: calc((0 + 0.5) * var(--spacing));
}
:root .p-0h-tr {
  padding-right: calc((0 + 0.5) * var(--spacing));
}
:root .p-0h-trb {
  padding-right: calc((0 + 0.5) * var(--spacing));
}
:root .p-0h-trbl {
  padding-right: calc((0 + 0.5) * var(--spacing));
}
:root .p-0h-trl {
  padding-right: calc((0 + 0.5) * var(--spacing));
}
:root .p-0h-r {
  padding-right: calc((0 + 0.5) * var(--spacing));
}
:root .p-0h-rb {
  padding-right: calc((0 + 0.5) * var(--spacing));
}
:root .p-0h-rbl {
  padding-right: calc((0 + 0.5) * var(--spacing));
}
:root .p-0h-rl {
  padding-right: calc((0 + 0.5) * var(--spacing));
}
:root .p-0h {
  padding-bottom: calc((0 + 0.5) * var(--spacing));
}
:root .p-0h-trb {
  padding-bottom: calc((0 + 0.5) * var(--spacing));
}
:root .p-0h-trbl {
  padding-bottom: calc((0 + 0.5) * var(--spacing));
}
:root .p-0h-tb {
  padding-bottom: calc((0 + 0.5) * var(--spacing));
}
:root .p-0h-tbl {
  padding-bottom: calc((0 + 0.5) * var(--spacing));
}
:root .p-0h-rb {
  padding-bottom: calc((0 + 0.5) * var(--spacing));
}
:root .p-0h-rbl {
  padding-bottom: calc((0 + 0.5) * var(--spacing));
}
:root .p-0h-b {
  padding-bottom: calc((0 + 0.5) * var(--spacing));
}
:root .p-0h-bl {
  padding-bottom: calc((0 + 0.5) * var(--spacing));
}
:root .p-0h {
  padding-left: calc((0 + 0.5) * var(--spacing));
}
:root .p-0h-trbl {
  padding-left: calc((0 + 0.5) * var(--spacing));
}
:root .p-0h-trl {
  padding-left: calc((0 + 0.5) * var(--spacing));
}
:root .p-0h-tbl {
  padding-left: calc((0 + 0.5) * var(--spacing));
}
:root .p-0h-tl {
  padding-left: calc((0 + 0.5) * var(--spacing));
}
:root .p-0h-rbl {
  padding-left: calc((0 + 0.5) * var(--spacing));
}
:root .p-0h-rl {
  padding-left: calc((0 + 0.5) * var(--spacing));
}
:root .p-0h-bl {
  padding-left: calc((0 + 0.5) * var(--spacing));
}
:root .p-0h-l {
  padding-left: calc((0 + 0.5) * var(--spacing));
}
:root .p-0t {
  padding-top: calc((0 + 0.75) * var(--spacing));
}
:root .p-0t-t {
  padding-top: calc((0 + 0.75) * var(--spacing));
}
:root .p-0t-tr {
  padding-top: calc((0 + 0.75) * var(--spacing));
}
:root .p-0t-trb {
  padding-top: calc((0 + 0.75) * var(--spacing));
}
:root .p-0t-trbl {
  padding-top: calc((0 + 0.75) * var(--spacing));
}
:root .p-0t-trl {
  padding-top: calc((0 + 0.75) * var(--spacing));
}
:root .p-0t-tb {
  padding-top: calc((0 + 0.75) * var(--spacing));
}
:root .p-0t-tbl {
  padding-top: calc((0 + 0.75) * var(--spacing));
}
:root .p-0t-tl {
  padding-top: calc((0 + 0.75) * var(--spacing));
}
:root .p-0t {
  padding-right: calc((0 + 0.75) * var(--spacing));
}
:root .p-0t-tr {
  padding-right: calc((0 + 0.75) * var(--spacing));
}
:root .p-0t-trb {
  padding-right: calc((0 + 0.75) * var(--spacing));
}
:root .p-0t-trbl {
  padding-right: calc((0 + 0.75) * var(--spacing));
}
:root .p-0t-trl {
  padding-right: calc((0 + 0.75) * var(--spacing));
}
:root .p-0t-r {
  padding-right: calc((0 + 0.75) * var(--spacing));
}
:root .p-0t-rb {
  padding-right: calc((0 + 0.75) * var(--spacing));
}
:root .p-0t-rbl {
  padding-right: calc((0 + 0.75) * var(--spacing));
}
:root .p-0t-rl {
  padding-right: calc((0 + 0.75) * var(--spacing));
}
:root .p-0t {
  padding-bottom: calc((0 + 0.75) * var(--spacing));
}
:root .p-0t-trb {
  padding-bottom: calc((0 + 0.75) * var(--spacing));
}
:root .p-0t-trbl {
  padding-bottom: calc((0 + 0.75) * var(--spacing));
}
:root .p-0t-tb {
  padding-bottom: calc((0 + 0.75) * var(--spacing));
}
:root .p-0t-tbl {
  padding-bottom: calc((0 + 0.75) * var(--spacing));
}
:root .p-0t-rb {
  padding-bottom: calc((0 + 0.75) * var(--spacing));
}
:root .p-0t-rbl {
  padding-bottom: calc((0 + 0.75) * var(--spacing));
}
:root .p-0t-b {
  padding-bottom: calc((0 + 0.75) * var(--spacing));
}
:root .p-0t-bl {
  padding-bottom: calc((0 + 0.75) * var(--spacing));
}
:root .p-0t {
  padding-left: calc((0 + 0.75) * var(--spacing));
}
:root .p-0t-trbl {
  padding-left: calc((0 + 0.75) * var(--spacing));
}
:root .p-0t-trl {
  padding-left: calc((0 + 0.75) * var(--spacing));
}
:root .p-0t-tbl {
  padding-left: calc((0 + 0.75) * var(--spacing));
}
:root .p-0t-tl {
  padding-left: calc((0 + 0.75) * var(--spacing));
}
:root .p-0t-rbl {
  padding-left: calc((0 + 0.75) * var(--spacing));
}
:root .p-0t-rl {
  padding-left: calc((0 + 0.75) * var(--spacing));
}
:root .p-0t-bl {
  padding-left: calc((0 + 0.75) * var(--spacing));
}
:root .p-0t-l {
  padding-left: calc((0 + 0.75) * var(--spacing));
}
:root .p-1 {
  padding-top: calc((1 + 0) * var(--spacing));
}
:root .p-1-t {
  padding-top: calc((1 + 0) * var(--spacing));
}
:root .p-1-tr {
  padding-top: calc((1 + 0) * var(--spacing));
}
:root .p-1-trb {
  padding-top: calc((1 + 0) * var(--spacing));
}
:root .p-1-trbl {
  padding-top: calc((1 + 0) * var(--spacing));
}
:root .p-1-trl {
  padding-top: calc((1 + 0) * var(--spacing));
}
:root .p-1-tb {
  padding-top: calc((1 + 0) * var(--spacing));
}
:root .p-1-tbl {
  padding-top: calc((1 + 0) * var(--spacing));
}
:root .p-1-tl {
  padding-top: calc((1 + 0) * var(--spacing));
}
:root .p-1 {
  padding-right: calc((1 + 0) * var(--spacing));
}
:root .p-1-tr {
  padding-right: calc((1 + 0) * var(--spacing));
}
:root .p-1-trb {
  padding-right: calc((1 + 0) * var(--spacing));
}
:root .p-1-trbl {
  padding-right: calc((1 + 0) * var(--spacing));
}
:root .p-1-trl {
  padding-right: calc((1 + 0) * var(--spacing));
}
:root .p-1-r {
  padding-right: calc((1 + 0) * var(--spacing));
}
:root .p-1-rb {
  padding-right: calc((1 + 0) * var(--spacing));
}
:root .p-1-rbl {
  padding-right: calc((1 + 0) * var(--spacing));
}
:root .p-1-rl {
  padding-right: calc((1 + 0) * var(--spacing));
}
:root .p-1 {
  padding-bottom: calc((1 + 0) * var(--spacing));
}
:root .p-1-trb {
  padding-bottom: calc((1 + 0) * var(--spacing));
}
:root .p-1-trbl {
  padding-bottom: calc((1 + 0) * var(--spacing));
}
:root .p-1-tb {
  padding-bottom: calc((1 + 0) * var(--spacing));
}
:root .p-1-tbl {
  padding-bottom: calc((1 + 0) * var(--spacing));
}
:root .p-1-rb {
  padding-bottom: calc((1 + 0) * var(--spacing));
}
:root .p-1-rbl {
  padding-bottom: calc((1 + 0) * var(--spacing));
}
:root .p-1-b {
  padding-bottom: calc((1 + 0) * var(--spacing));
}
:root .p-1-bl {
  padding-bottom: calc((1 + 0) * var(--spacing));
}
:root .p-1 {
  padding-left: calc((1 + 0) * var(--spacing));
}
:root .p-1-trbl {
  padding-left: calc((1 + 0) * var(--spacing));
}
:root .p-1-trl {
  padding-left: calc((1 + 0) * var(--spacing));
}
:root .p-1-tbl {
  padding-left: calc((1 + 0) * var(--spacing));
}
:root .p-1-tl {
  padding-left: calc((1 + 0) * var(--spacing));
}
:root .p-1-rbl {
  padding-left: calc((1 + 0) * var(--spacing));
}
:root .p-1-rl {
  padding-left: calc((1 + 0) * var(--spacing));
}
:root .p-1-bl {
  padding-left: calc((1 + 0) * var(--spacing));
}
:root .p-1-l {
  padding-left: calc((1 + 0) * var(--spacing));
}
:root .p-1q {
  padding-top: calc((1 + 0.25) * var(--spacing));
}
:root .p-1q-t {
  padding-top: calc((1 + 0.25) * var(--spacing));
}
:root .p-1q-tr {
  padding-top: calc((1 + 0.25) * var(--spacing));
}
:root .p-1q-trb {
  padding-top: calc((1 + 0.25) * var(--spacing));
}
:root .p-1q-trbl {
  padding-top: calc((1 + 0.25) * var(--spacing));
}
:root .p-1q-trl {
  padding-top: calc((1 + 0.25) * var(--spacing));
}
:root .p-1q-tb {
  padding-top: calc((1 + 0.25) * var(--spacing));
}
:root .p-1q-tbl {
  padding-top: calc((1 + 0.25) * var(--spacing));
}
:root .p-1q-tl {
  padding-top: calc((1 + 0.25) * var(--spacing));
}
:root .p-1q {
  padding-right: calc((1 + 0.25) * var(--spacing));
}
:root .p-1q-tr {
  padding-right: calc((1 + 0.25) * var(--spacing));
}
:root .p-1q-trb {
  padding-right: calc((1 + 0.25) * var(--spacing));
}
:root .p-1q-trbl {
  padding-right: calc((1 + 0.25) * var(--spacing));
}
:root .p-1q-trl {
  padding-right: calc((1 + 0.25) * var(--spacing));
}
:root .p-1q-r {
  padding-right: calc((1 + 0.25) * var(--spacing));
}
:root .p-1q-rb {
  padding-right: calc((1 + 0.25) * var(--spacing));
}
:root .p-1q-rbl {
  padding-right: calc((1 + 0.25) * var(--spacing));
}
:root .p-1q-rl {
  padding-right: calc((1 + 0.25) * var(--spacing));
}
:root .p-1q {
  padding-bottom: calc((1 + 0.25) * var(--spacing));
}
:root .p-1q-trb {
  padding-bottom: calc((1 + 0.25) * var(--spacing));
}
:root .p-1q-trbl {
  padding-bottom: calc((1 + 0.25) * var(--spacing));
}
:root .p-1q-tb {
  padding-bottom: calc((1 + 0.25) * var(--spacing));
}
:root .p-1q-tbl {
  padding-bottom: calc((1 + 0.25) * var(--spacing));
}
:root .p-1q-rb {
  padding-bottom: calc((1 + 0.25) * var(--spacing));
}
:root .p-1q-rbl {
  padding-bottom: calc((1 + 0.25) * var(--spacing));
}
:root .p-1q-b {
  padding-bottom: calc((1 + 0.25) * var(--spacing));
}
:root .p-1q-bl {
  padding-bottom: calc((1 + 0.25) * var(--spacing));
}
:root .p-1q {
  padding-left: calc((1 + 0.25) * var(--spacing));
}
:root .p-1q-trbl {
  padding-left: calc((1 + 0.25) * var(--spacing));
}
:root .p-1q-trl {
  padding-left: calc((1 + 0.25) * var(--spacing));
}
:root .p-1q-tbl {
  padding-left: calc((1 + 0.25) * var(--spacing));
}
:root .p-1q-tl {
  padding-left: calc((1 + 0.25) * var(--spacing));
}
:root .p-1q-rbl {
  padding-left: calc((1 + 0.25) * var(--spacing));
}
:root .p-1q-rl {
  padding-left: calc((1 + 0.25) * var(--spacing));
}
:root .p-1q-bl {
  padding-left: calc((1 + 0.25) * var(--spacing));
}
:root .p-1q-l {
  padding-left: calc((1 + 0.25) * var(--spacing));
}
:root .p-1h {
  padding-top: calc((1 + 0.5) * var(--spacing));
}
:root .p-1h-t {
  padding-top: calc((1 + 0.5) * var(--spacing));
}
:root .p-1h-tr {
  padding-top: calc((1 + 0.5) * var(--spacing));
}
:root .p-1h-trb {
  padding-top: calc((1 + 0.5) * var(--spacing));
}
:root .p-1h-trbl {
  padding-top: calc((1 + 0.5) * var(--spacing));
}
:root .p-1h-trl {
  padding-top: calc((1 + 0.5) * var(--spacing));
}
:root .p-1h-tb {
  padding-top: calc((1 + 0.5) * var(--spacing));
}
:root .p-1h-tbl {
  padding-top: calc((1 + 0.5) * var(--spacing));
}
:root .p-1h-tl {
  padding-top: calc((1 + 0.5) * var(--spacing));
}
:root .p-1h {
  padding-right: calc((1 + 0.5) * var(--spacing));
}
:root .p-1h-tr {
  padding-right: calc((1 + 0.5) * var(--spacing));
}
:root .p-1h-trb {
  padding-right: calc((1 + 0.5) * var(--spacing));
}
:root .p-1h-trbl {
  padding-right: calc((1 + 0.5) * var(--spacing));
}
:root .p-1h-trl {
  padding-right: calc((1 + 0.5) * var(--spacing));
}
:root .p-1h-r {
  padding-right: calc((1 + 0.5) * var(--spacing));
}
:root .p-1h-rb {
  padding-right: calc((1 + 0.5) * var(--spacing));
}
:root .p-1h-rbl {
  padding-right: calc((1 + 0.5) * var(--spacing));
}
:root .p-1h-rl {
  padding-right: calc((1 + 0.5) * var(--spacing));
}
:root .p-1h {
  padding-bottom: calc((1 + 0.5) * var(--spacing));
}
:root .p-1h-trb {
  padding-bottom: calc((1 + 0.5) * var(--spacing));
}
:root .p-1h-trbl {
  padding-bottom: calc((1 + 0.5) * var(--spacing));
}
:root .p-1h-tb {
  padding-bottom: calc((1 + 0.5) * var(--spacing));
}
:root .p-1h-tbl {
  padding-bottom: calc((1 + 0.5) * var(--spacing));
}
:root .p-1h-rb {
  padding-bottom: calc((1 + 0.5) * var(--spacing));
}
:root .p-1h-rbl {
  padding-bottom: calc((1 + 0.5) * var(--spacing));
}
:root .p-1h-b {
  padding-bottom: calc((1 + 0.5) * var(--spacing));
}
:root .p-1h-bl {
  padding-bottom: calc((1 + 0.5) * var(--spacing));
}
:root .p-1h {
  padding-left: calc((1 + 0.5) * var(--spacing));
}
:root .p-1h-trbl {
  padding-left: calc((1 + 0.5) * var(--spacing));
}
:root .p-1h-trl {
  padding-left: calc((1 + 0.5) * var(--spacing));
}
:root .p-1h-tbl {
  padding-left: calc((1 + 0.5) * var(--spacing));
}
:root .p-1h-tl {
  padding-left: calc((1 + 0.5) * var(--spacing));
}
:root .p-1h-rbl {
  padding-left: calc((1 + 0.5) * var(--spacing));
}
:root .p-1h-rl {
  padding-left: calc((1 + 0.5) * var(--spacing));
}
:root .p-1h-bl {
  padding-left: calc((1 + 0.5) * var(--spacing));
}
:root .p-1h-l {
  padding-left: calc((1 + 0.5) * var(--spacing));
}
:root .p-1t {
  padding-top: calc((1 + 0.75) * var(--spacing));
}
:root .p-1t-t {
  padding-top: calc((1 + 0.75) * var(--spacing));
}
:root .p-1t-tr {
  padding-top: calc((1 + 0.75) * var(--spacing));
}
:root .p-1t-trb {
  padding-top: calc((1 + 0.75) * var(--spacing));
}
:root .p-1t-trbl {
  padding-top: calc((1 + 0.75) * var(--spacing));
}
:root .p-1t-trl {
  padding-top: calc((1 + 0.75) * var(--spacing));
}
:root .p-1t-tb {
  padding-top: calc((1 + 0.75) * var(--spacing));
}
:root .p-1t-tbl {
  padding-top: calc((1 + 0.75) * var(--spacing));
}
:root .p-1t-tl {
  padding-top: calc((1 + 0.75) * var(--spacing));
}
:root .p-1t {
  padding-right: calc((1 + 0.75) * var(--spacing));
}
:root .p-1t-tr {
  padding-right: calc((1 + 0.75) * var(--spacing));
}
:root .p-1t-trb {
  padding-right: calc((1 + 0.75) * var(--spacing));
}
:root .p-1t-trbl {
  padding-right: calc((1 + 0.75) * var(--spacing));
}
:root .p-1t-trl {
  padding-right: calc((1 + 0.75) * var(--spacing));
}
:root .p-1t-r {
  padding-right: calc((1 + 0.75) * var(--spacing));
}
:root .p-1t-rb {
  padding-right: calc((1 + 0.75) * var(--spacing));
}
:root .p-1t-rbl {
  padding-right: calc((1 + 0.75) * var(--spacing));
}
:root .p-1t-rl {
  padding-right: calc((1 + 0.75) * var(--spacing));
}
:root .p-1t {
  padding-bottom: calc((1 + 0.75) * var(--spacing));
}
:root .p-1t-trb {
  padding-bottom: calc((1 + 0.75) * var(--spacing));
}
:root .p-1t-trbl {
  padding-bottom: calc((1 + 0.75) * var(--spacing));
}
:root .p-1t-tb {
  padding-bottom: calc((1 + 0.75) * var(--spacing));
}
:root .p-1t-tbl {
  padding-bottom: calc((1 + 0.75) * var(--spacing));
}
:root .p-1t-rb {
  padding-bottom: calc((1 + 0.75) * var(--spacing));
}
:root .p-1t-rbl {
  padding-bottom: calc((1 + 0.75) * var(--spacing));
}
:root .p-1t-b {
  padding-bottom: calc((1 + 0.75) * var(--spacing));
}
:root .p-1t-bl {
  padding-bottom: calc((1 + 0.75) * var(--spacing));
}
:root .p-1t {
  padding-left: calc((1 + 0.75) * var(--spacing));
}
:root .p-1t-trbl {
  padding-left: calc((1 + 0.75) * var(--spacing));
}
:root .p-1t-trl {
  padding-left: calc((1 + 0.75) * var(--spacing));
}
:root .p-1t-tbl {
  padding-left: calc((1 + 0.75) * var(--spacing));
}
:root .p-1t-tl {
  padding-left: calc((1 + 0.75) * var(--spacing));
}
:root .p-1t-rbl {
  padding-left: calc((1 + 0.75) * var(--spacing));
}
:root .p-1t-rl {
  padding-left: calc((1 + 0.75) * var(--spacing));
}
:root .p-1t-bl {
  padding-left: calc((1 + 0.75) * var(--spacing));
}
:root .p-1t-l {
  padding-left: calc((1 + 0.75) * var(--spacing));
}
:root .p-2 {
  padding-top: calc((2 + 0) * var(--spacing));
}
:root .p-2-t {
  padding-top: calc((2 + 0) * var(--spacing));
}
:root .p-2-tr {
  padding-top: calc((2 + 0) * var(--spacing));
}
:root .p-2-trb {
  padding-top: calc((2 + 0) * var(--spacing));
}
:root .p-2-trbl {
  padding-top: calc((2 + 0) * var(--spacing));
}
:root .p-2-trl {
  padding-top: calc((2 + 0) * var(--spacing));
}
:root .p-2-tb {
  padding-top: calc((2 + 0) * var(--spacing));
}
:root .p-2-tbl {
  padding-top: calc((2 + 0) * var(--spacing));
}
:root .p-2-tl {
  padding-top: calc((2 + 0) * var(--spacing));
}
:root .p-2 {
  padding-right: calc((2 + 0) * var(--spacing));
}
:root .p-2-tr {
  padding-right: calc((2 + 0) * var(--spacing));
}
:root .p-2-trb {
  padding-right: calc((2 + 0) * var(--spacing));
}
:root .p-2-trbl {
  padding-right: calc((2 + 0) * var(--spacing));
}
:root .p-2-trl {
  padding-right: calc((2 + 0) * var(--spacing));
}
:root .p-2-r {
  padding-right: calc((2 + 0) * var(--spacing));
}
:root .p-2-rb {
  padding-right: calc((2 + 0) * var(--spacing));
}
:root .p-2-rbl {
  padding-right: calc((2 + 0) * var(--spacing));
}
:root .p-2-rl {
  padding-right: calc((2 + 0) * var(--spacing));
}
:root .p-2 {
  padding-bottom: calc((2 + 0) * var(--spacing));
}
:root .p-2-trb {
  padding-bottom: calc((2 + 0) * var(--spacing));
}
:root .p-2-trbl {
  padding-bottom: calc((2 + 0) * var(--spacing));
}
:root .p-2-tb {
  padding-bottom: calc((2 + 0) * var(--spacing));
}
:root .p-2-tbl {
  padding-bottom: calc((2 + 0) * var(--spacing));
}
:root .p-2-rb {
  padding-bottom: calc((2 + 0) * var(--spacing));
}
:root .p-2-rbl {
  padding-bottom: calc((2 + 0) * var(--spacing));
}
:root .p-2-b {
  padding-bottom: calc((2 + 0) * var(--spacing));
}
:root .p-2-bl {
  padding-bottom: calc((2 + 0) * var(--spacing));
}
:root .p-2 {
  padding-left: calc((2 + 0) * var(--spacing));
}
:root .p-2-trbl {
  padding-left: calc((2 + 0) * var(--spacing));
}
:root .p-2-trl {
  padding-left: calc((2 + 0) * var(--spacing));
}
:root .p-2-tbl {
  padding-left: calc((2 + 0) * var(--spacing));
}
:root .p-2-tl {
  padding-left: calc((2 + 0) * var(--spacing));
}
:root .p-2-rbl {
  padding-left: calc((2 + 0) * var(--spacing));
}
:root .p-2-rl {
  padding-left: calc((2 + 0) * var(--spacing));
}
:root .p-2-bl {
  padding-left: calc((2 + 0) * var(--spacing));
}
:root .p-2-l {
  padding-left: calc((2 + 0) * var(--spacing));
}
:root .p-2q {
  padding-top: calc((2 + 0.25) * var(--spacing));
}
:root .p-2q-t {
  padding-top: calc((2 + 0.25) * var(--spacing));
}
:root .p-2q-tr {
  padding-top: calc((2 + 0.25) * var(--spacing));
}
:root .p-2q-trb {
  padding-top: calc((2 + 0.25) * var(--spacing));
}
:root .p-2q-trbl {
  padding-top: calc((2 + 0.25) * var(--spacing));
}
:root .p-2q-trl {
  padding-top: calc((2 + 0.25) * var(--spacing));
}
:root .p-2q-tb {
  padding-top: calc((2 + 0.25) * var(--spacing));
}
:root .p-2q-tbl {
  padding-top: calc((2 + 0.25) * var(--spacing));
}
:root .p-2q-tl {
  padding-top: calc((2 + 0.25) * var(--spacing));
}
:root .p-2q {
  padding-right: calc((2 + 0.25) * var(--spacing));
}
:root .p-2q-tr {
  padding-right: calc((2 + 0.25) * var(--spacing));
}
:root .p-2q-trb {
  padding-right: calc((2 + 0.25) * var(--spacing));
}
:root .p-2q-trbl {
  padding-right: calc((2 + 0.25) * var(--spacing));
}
:root .p-2q-trl {
  padding-right: calc((2 + 0.25) * var(--spacing));
}
:root .p-2q-r {
  padding-right: calc((2 + 0.25) * var(--spacing));
}
:root .p-2q-rb {
  padding-right: calc((2 + 0.25) * var(--spacing));
}
:root .p-2q-rbl {
  padding-right: calc((2 + 0.25) * var(--spacing));
}
:root .p-2q-rl {
  padding-right: calc((2 + 0.25) * var(--spacing));
}
:root .p-2q {
  padding-bottom: calc((2 + 0.25) * var(--spacing));
}
:root .p-2q-trb {
  padding-bottom: calc((2 + 0.25) * var(--spacing));
}
:root .p-2q-trbl {
  padding-bottom: calc((2 + 0.25) * var(--spacing));
}
:root .p-2q-tb {
  padding-bottom: calc((2 + 0.25) * var(--spacing));
}
:root .p-2q-tbl {
  padding-bottom: calc((2 + 0.25) * var(--spacing));
}
:root .p-2q-rb {
  padding-bottom: calc((2 + 0.25) * var(--spacing));
}
:root .p-2q-rbl {
  padding-bottom: calc((2 + 0.25) * var(--spacing));
}
:root .p-2q-b {
  padding-bottom: calc((2 + 0.25) * var(--spacing));
}
:root .p-2q-bl {
  padding-bottom: calc((2 + 0.25) * var(--spacing));
}
:root .p-2q {
  padding-left: calc((2 + 0.25) * var(--spacing));
}
:root .p-2q-trbl {
  padding-left: calc((2 + 0.25) * var(--spacing));
}
:root .p-2q-trl {
  padding-left: calc((2 + 0.25) * var(--spacing));
}
:root .p-2q-tbl {
  padding-left: calc((2 + 0.25) * var(--spacing));
}
:root .p-2q-tl {
  padding-left: calc((2 + 0.25) * var(--spacing));
}
:root .p-2q-rbl {
  padding-left: calc((2 + 0.25) * var(--spacing));
}
:root .p-2q-rl {
  padding-left: calc((2 + 0.25) * var(--spacing));
}
:root .p-2q-bl {
  padding-left: calc((2 + 0.25) * var(--spacing));
}
:root .p-2q-l {
  padding-left: calc((2 + 0.25) * var(--spacing));
}
:root .p-2h {
  padding-top: calc((2 + 0.5) * var(--spacing));
}
:root .p-2h-t {
  padding-top: calc((2 + 0.5) * var(--spacing));
}
:root .p-2h-tr {
  padding-top: calc((2 + 0.5) * var(--spacing));
}
:root .p-2h-trb {
  padding-top: calc((2 + 0.5) * var(--spacing));
}
:root .p-2h-trbl {
  padding-top: calc((2 + 0.5) * var(--spacing));
}
:root .p-2h-trl {
  padding-top: calc((2 + 0.5) * var(--spacing));
}
:root .p-2h-tb {
  padding-top: calc((2 + 0.5) * var(--spacing));
}
:root .p-2h-tbl {
  padding-top: calc((2 + 0.5) * var(--spacing));
}
:root .p-2h-tl {
  padding-top: calc((2 + 0.5) * var(--spacing));
}
:root .p-2h {
  padding-right: calc((2 + 0.5) * var(--spacing));
}
:root .p-2h-tr {
  padding-right: calc((2 + 0.5) * var(--spacing));
}
:root .p-2h-trb {
  padding-right: calc((2 + 0.5) * var(--spacing));
}
:root .p-2h-trbl {
  padding-right: calc((2 + 0.5) * var(--spacing));
}
:root .p-2h-trl {
  padding-right: calc((2 + 0.5) * var(--spacing));
}
:root .p-2h-r {
  padding-right: calc((2 + 0.5) * var(--spacing));
}
:root .p-2h-rb {
  padding-right: calc((2 + 0.5) * var(--spacing));
}
:root .p-2h-rbl {
  padding-right: calc((2 + 0.5) * var(--spacing));
}
:root .p-2h-rl {
  padding-right: calc((2 + 0.5) * var(--spacing));
}
:root .p-2h {
  padding-bottom: calc((2 + 0.5) * var(--spacing));
}
:root .p-2h-trb {
  padding-bottom: calc((2 + 0.5) * var(--spacing));
}
:root .p-2h-trbl {
  padding-bottom: calc((2 + 0.5) * var(--spacing));
}
:root .p-2h-tb {
  padding-bottom: calc((2 + 0.5) * var(--spacing));
}
:root .p-2h-tbl {
  padding-bottom: calc((2 + 0.5) * var(--spacing));
}
:root .p-2h-rb {
  padding-bottom: calc((2 + 0.5) * var(--spacing));
}
:root .p-2h-rbl {
  padding-bottom: calc((2 + 0.5) * var(--spacing));
}
:root .p-2h-b {
  padding-bottom: calc((2 + 0.5) * var(--spacing));
}
:root .p-2h-bl {
  padding-bottom: calc((2 + 0.5) * var(--spacing));
}
:root .p-2h {
  padding-left: calc((2 + 0.5) * var(--spacing));
}
:root .p-2h-trbl {
  padding-left: calc((2 + 0.5) * var(--spacing));
}
:root .p-2h-trl {
  padding-left: calc((2 + 0.5) * var(--spacing));
}
:root .p-2h-tbl {
  padding-left: calc((2 + 0.5) * var(--spacing));
}
:root .p-2h-tl {
  padding-left: calc((2 + 0.5) * var(--spacing));
}
:root .p-2h-rbl {
  padding-left: calc((2 + 0.5) * var(--spacing));
}
:root .p-2h-rl {
  padding-left: calc((2 + 0.5) * var(--spacing));
}
:root .p-2h-bl {
  padding-left: calc((2 + 0.5) * var(--spacing));
}
:root .p-2h-l {
  padding-left: calc((2 + 0.5) * var(--spacing));
}
:root .p-2t {
  padding-top: calc((2 + 0.75) * var(--spacing));
}
:root .p-2t-t {
  padding-top: calc((2 + 0.75) * var(--spacing));
}
:root .p-2t-tr {
  padding-top: calc((2 + 0.75) * var(--spacing));
}
:root .p-2t-trb {
  padding-top: calc((2 + 0.75) * var(--spacing));
}
:root .p-2t-trbl {
  padding-top: calc((2 + 0.75) * var(--spacing));
}
:root .p-2t-trl {
  padding-top: calc((2 + 0.75) * var(--spacing));
}
:root .p-2t-tb {
  padding-top: calc((2 + 0.75) * var(--spacing));
}
:root .p-2t-tbl {
  padding-top: calc((2 + 0.75) * var(--spacing));
}
:root .p-2t-tl {
  padding-top: calc((2 + 0.75) * var(--spacing));
}
:root .p-2t {
  padding-right: calc((2 + 0.75) * var(--spacing));
}
:root .p-2t-tr {
  padding-right: calc((2 + 0.75) * var(--spacing));
}
:root .p-2t-trb {
  padding-right: calc((2 + 0.75) * var(--spacing));
}
:root .p-2t-trbl {
  padding-right: calc((2 + 0.75) * var(--spacing));
}
:root .p-2t-trl {
  padding-right: calc((2 + 0.75) * var(--spacing));
}
:root .p-2t-r {
  padding-right: calc((2 + 0.75) * var(--spacing));
}
:root .p-2t-rb {
  padding-right: calc((2 + 0.75) * var(--spacing));
}
:root .p-2t-rbl {
  padding-right: calc((2 + 0.75) * var(--spacing));
}
:root .p-2t-rl {
  padding-right: calc((2 + 0.75) * var(--spacing));
}
:root .p-2t {
  padding-bottom: calc((2 + 0.75) * var(--spacing));
}
:root .p-2t-trb {
  padding-bottom: calc((2 + 0.75) * var(--spacing));
}
:root .p-2t-trbl {
  padding-bottom: calc((2 + 0.75) * var(--spacing));
}
:root .p-2t-tb {
  padding-bottom: calc((2 + 0.75) * var(--spacing));
}
:root .p-2t-tbl {
  padding-bottom: calc((2 + 0.75) * var(--spacing));
}
:root .p-2t-rb {
  padding-bottom: calc((2 + 0.75) * var(--spacing));
}
:root .p-2t-rbl {
  padding-bottom: calc((2 + 0.75) * var(--spacing));
}
:root .p-2t-b {
  padding-bottom: calc((2 + 0.75) * var(--spacing));
}
:root .p-2t-bl {
  padding-bottom: calc((2 + 0.75) * var(--spacing));
}
:root .p-2t {
  padding-left: calc((2 + 0.75) * var(--spacing));
}
:root .p-2t-trbl {
  padding-left: calc((2 + 0.75) * var(--spacing));
}
:root .p-2t-trl {
  padding-left: calc((2 + 0.75) * var(--spacing));
}
:root .p-2t-tbl {
  padding-left: calc((2 + 0.75) * var(--spacing));
}
:root .p-2t-tl {
  padding-left: calc((2 + 0.75) * var(--spacing));
}
:root .p-2t-rbl {
  padding-left: calc((2 + 0.75) * var(--spacing));
}
:root .p-2t-rl {
  padding-left: calc((2 + 0.75) * var(--spacing));
}
:root .p-2t-bl {
  padding-left: calc((2 + 0.75) * var(--spacing));
}
:root .p-2t-l {
  padding-left: calc((2 + 0.75) * var(--spacing));
}
:root .p-3 {
  padding-top: calc((3 + 0) * var(--spacing));
}
:root .p-3-t {
  padding-top: calc((3 + 0) * var(--spacing));
}
:root .p-3-tr {
  padding-top: calc((3 + 0) * var(--spacing));
}
:root .p-3-trb {
  padding-top: calc((3 + 0) * var(--spacing));
}
:root .p-3-trbl {
  padding-top: calc((3 + 0) * var(--spacing));
}
:root .p-3-trl {
  padding-top: calc((3 + 0) * var(--spacing));
}
:root .p-3-tb {
  padding-top: calc((3 + 0) * var(--spacing));
}
:root .p-3-tbl {
  padding-top: calc((3 + 0) * var(--spacing));
}
:root .p-3-tl {
  padding-top: calc((3 + 0) * var(--spacing));
}
:root .p-3 {
  padding-right: calc((3 + 0) * var(--spacing));
}
:root .p-3-tr {
  padding-right: calc((3 + 0) * var(--spacing));
}
:root .p-3-trb {
  padding-right: calc((3 + 0) * var(--spacing));
}
:root .p-3-trbl {
  padding-right: calc((3 + 0) * var(--spacing));
}
:root .p-3-trl {
  padding-right: calc((3 + 0) * var(--spacing));
}
:root .p-3-r {
  padding-right: calc((3 + 0) * var(--spacing));
}
:root .p-3-rb {
  padding-right: calc((3 + 0) * var(--spacing));
}
:root .p-3-rbl {
  padding-right: calc((3 + 0) * var(--spacing));
}
:root .p-3-rl {
  padding-right: calc((3 + 0) * var(--spacing));
}
:root .p-3 {
  padding-bottom: calc((3 + 0) * var(--spacing));
}
:root .p-3-trb {
  padding-bottom: calc((3 + 0) * var(--spacing));
}
:root .p-3-trbl {
  padding-bottom: calc((3 + 0) * var(--spacing));
}
:root .p-3-tb {
  padding-bottom: calc((3 + 0) * var(--spacing));
}
:root .p-3-tbl {
  padding-bottom: calc((3 + 0) * var(--spacing));
}
:root .p-3-rb {
  padding-bottom: calc((3 + 0) * var(--spacing));
}
:root .p-3-rbl {
  padding-bottom: calc((3 + 0) * var(--spacing));
}
:root .p-3-b {
  padding-bottom: calc((3 + 0) * var(--spacing));
}
:root .p-3-bl {
  padding-bottom: calc((3 + 0) * var(--spacing));
}
:root .p-3 {
  padding-left: calc((3 + 0) * var(--spacing));
}
:root .p-3-trbl {
  padding-left: calc((3 + 0) * var(--spacing));
}
:root .p-3-trl {
  padding-left: calc((3 + 0) * var(--spacing));
}
:root .p-3-tbl {
  padding-left: calc((3 + 0) * var(--spacing));
}
:root .p-3-tl {
  padding-left: calc((3 + 0) * var(--spacing));
}
:root .p-3-rbl {
  padding-left: calc((3 + 0) * var(--spacing));
}
:root .p-3-rl {
  padding-left: calc((3 + 0) * var(--spacing));
}
:root .p-3-bl {
  padding-left: calc((3 + 0) * var(--spacing));
}
:root .p-3-l {
  padding-left: calc((3 + 0) * var(--spacing));
}
:root .p-3q {
  padding-top: calc((3 + 0.25) * var(--spacing));
}
:root .p-3q-t {
  padding-top: calc((3 + 0.25) * var(--spacing));
}
:root .p-3q-tr {
  padding-top: calc((3 + 0.25) * var(--spacing));
}
:root .p-3q-trb {
  padding-top: calc((3 + 0.25) * var(--spacing));
}
:root .p-3q-trbl {
  padding-top: calc((3 + 0.25) * var(--spacing));
}
:root .p-3q-trl {
  padding-top: calc((3 + 0.25) * var(--spacing));
}
:root .p-3q-tb {
  padding-top: calc((3 + 0.25) * var(--spacing));
}
:root .p-3q-tbl {
  padding-top: calc((3 + 0.25) * var(--spacing));
}
:root .p-3q-tl {
  padding-top: calc((3 + 0.25) * var(--spacing));
}
:root .p-3q {
  padding-right: calc((3 + 0.25) * var(--spacing));
}
:root .p-3q-tr {
  padding-right: calc((3 + 0.25) * var(--spacing));
}
:root .p-3q-trb {
  padding-right: calc((3 + 0.25) * var(--spacing));
}
:root .p-3q-trbl {
  padding-right: calc((3 + 0.25) * var(--spacing));
}
:root .p-3q-trl {
  padding-right: calc((3 + 0.25) * var(--spacing));
}
:root .p-3q-r {
  padding-right: calc((3 + 0.25) * var(--spacing));
}
:root .p-3q-rb {
  padding-right: calc((3 + 0.25) * var(--spacing));
}
:root .p-3q-rbl {
  padding-right: calc((3 + 0.25) * var(--spacing));
}
:root .p-3q-rl {
  padding-right: calc((3 + 0.25) * var(--spacing));
}
:root .p-3q {
  padding-bottom: calc((3 + 0.25) * var(--spacing));
}
:root .p-3q-trb {
  padding-bottom: calc((3 + 0.25) * var(--spacing));
}
:root .p-3q-trbl {
  padding-bottom: calc((3 + 0.25) * var(--spacing));
}
:root .p-3q-tb {
  padding-bottom: calc((3 + 0.25) * var(--spacing));
}
:root .p-3q-tbl {
  padding-bottom: calc((3 + 0.25) * var(--spacing));
}
:root .p-3q-rb {
  padding-bottom: calc((3 + 0.25) * var(--spacing));
}
:root .p-3q-rbl {
  padding-bottom: calc((3 + 0.25) * var(--spacing));
}
:root .p-3q-b {
  padding-bottom: calc((3 + 0.25) * var(--spacing));
}
:root .p-3q-bl {
  padding-bottom: calc((3 + 0.25) * var(--spacing));
}
:root .p-3q {
  padding-left: calc((3 + 0.25) * var(--spacing));
}
:root .p-3q-trbl {
  padding-left: calc((3 + 0.25) * var(--spacing));
}
:root .p-3q-trl {
  padding-left: calc((3 + 0.25) * var(--spacing));
}
:root .p-3q-tbl {
  padding-left: calc((3 + 0.25) * var(--spacing));
}
:root .p-3q-tl {
  padding-left: calc((3 + 0.25) * var(--spacing));
}
:root .p-3q-rbl {
  padding-left: calc((3 + 0.25) * var(--spacing));
}
:root .p-3q-rl {
  padding-left: calc((3 + 0.25) * var(--spacing));
}
:root .p-3q-bl {
  padding-left: calc((3 + 0.25) * var(--spacing));
}
:root .p-3q-l {
  padding-left: calc((3 + 0.25) * var(--spacing));
}
:root .p-3h {
  padding-top: calc((3 + 0.5) * var(--spacing));
}
:root .p-3h-t {
  padding-top: calc((3 + 0.5) * var(--spacing));
}
:root .p-3h-tr {
  padding-top: calc((3 + 0.5) * var(--spacing));
}
:root .p-3h-trb {
  padding-top: calc((3 + 0.5) * var(--spacing));
}
:root .p-3h-trbl {
  padding-top: calc((3 + 0.5) * var(--spacing));
}
:root .p-3h-trl {
  padding-top: calc((3 + 0.5) * var(--spacing));
}
:root .p-3h-tb {
  padding-top: calc((3 + 0.5) * var(--spacing));
}
:root .p-3h-tbl {
  padding-top: calc((3 + 0.5) * var(--spacing));
}
:root .p-3h-tl {
  padding-top: calc((3 + 0.5) * var(--spacing));
}
:root .p-3h {
  padding-right: calc((3 + 0.5) * var(--spacing));
}
:root .p-3h-tr {
  padding-right: calc((3 + 0.5) * var(--spacing));
}
:root .p-3h-trb {
  padding-right: calc((3 + 0.5) * var(--spacing));
}
:root .p-3h-trbl {
  padding-right: calc((3 + 0.5) * var(--spacing));
}
:root .p-3h-trl {
  padding-right: calc((3 + 0.5) * var(--spacing));
}
:root .p-3h-r {
  padding-right: calc((3 + 0.5) * var(--spacing));
}
:root .p-3h-rb {
  padding-right: calc((3 + 0.5) * var(--spacing));
}
:root .p-3h-rbl {
  padding-right: calc((3 + 0.5) * var(--spacing));
}
:root .p-3h-rl {
  padding-right: calc((3 + 0.5) * var(--spacing));
}
:root .p-3h {
  padding-bottom: calc((3 + 0.5) * var(--spacing));
}
:root .p-3h-trb {
  padding-bottom: calc((3 + 0.5) * var(--spacing));
}
:root .p-3h-trbl {
  padding-bottom: calc((3 + 0.5) * var(--spacing));
}
:root .p-3h-tb {
  padding-bottom: calc((3 + 0.5) * var(--spacing));
}
:root .p-3h-tbl {
  padding-bottom: calc((3 + 0.5) * var(--spacing));
}
:root .p-3h-rb {
  padding-bottom: calc((3 + 0.5) * var(--spacing));
}
:root .p-3h-rbl {
  padding-bottom: calc((3 + 0.5) * var(--spacing));
}
:root .p-3h-b {
  padding-bottom: calc((3 + 0.5) * var(--spacing));
}
:root .p-3h-bl {
  padding-bottom: calc((3 + 0.5) * var(--spacing));
}
:root .p-3h {
  padding-left: calc((3 + 0.5) * var(--spacing));
}
:root .p-3h-trbl {
  padding-left: calc((3 + 0.5) * var(--spacing));
}
:root .p-3h-trl {
  padding-left: calc((3 + 0.5) * var(--spacing));
}
:root .p-3h-tbl {
  padding-left: calc((3 + 0.5) * var(--spacing));
}
:root .p-3h-tl {
  padding-left: calc((3 + 0.5) * var(--spacing));
}
:root .p-3h-rbl {
  padding-left: calc((3 + 0.5) * var(--spacing));
}
:root .p-3h-rl {
  padding-left: calc((3 + 0.5) * var(--spacing));
}
:root .p-3h-bl {
  padding-left: calc((3 + 0.5) * var(--spacing));
}
:root .p-3h-l {
  padding-left: calc((3 + 0.5) * var(--spacing));
}
:root .p-3t {
  padding-top: calc((3 + 0.75) * var(--spacing));
}
:root .p-3t-t {
  padding-top: calc((3 + 0.75) * var(--spacing));
}
:root .p-3t-tr {
  padding-top: calc((3 + 0.75) * var(--spacing));
}
:root .p-3t-trb {
  padding-top: calc((3 + 0.75) * var(--spacing));
}
:root .p-3t-trbl {
  padding-top: calc((3 + 0.75) * var(--spacing));
}
:root .p-3t-trl {
  padding-top: calc((3 + 0.75) * var(--spacing));
}
:root .p-3t-tb {
  padding-top: calc((3 + 0.75) * var(--spacing));
}
:root .p-3t-tbl {
  padding-top: calc((3 + 0.75) * var(--spacing));
}
:root .p-3t-tl {
  padding-top: calc((3 + 0.75) * var(--spacing));
}
:root .p-3t {
  padding-right: calc((3 + 0.75) * var(--spacing));
}
:root .p-3t-tr {
  padding-right: calc((3 + 0.75) * var(--spacing));
}
:root .p-3t-trb {
  padding-right: calc((3 + 0.75) * var(--spacing));
}
:root .p-3t-trbl {
  padding-right: calc((3 + 0.75) * var(--spacing));
}
:root .p-3t-trl {
  padding-right: calc((3 + 0.75) * var(--spacing));
}
:root .p-3t-r {
  padding-right: calc((3 + 0.75) * var(--spacing));
}
:root .p-3t-rb {
  padding-right: calc((3 + 0.75) * var(--spacing));
}
:root .p-3t-rbl {
  padding-right: calc((3 + 0.75) * var(--spacing));
}
:root .p-3t-rl {
  padding-right: calc((3 + 0.75) * var(--spacing));
}
:root .p-3t {
  padding-bottom: calc((3 + 0.75) * var(--spacing));
}
:root .p-3t-trb {
  padding-bottom: calc((3 + 0.75) * var(--spacing));
}
:root .p-3t-trbl {
  padding-bottom: calc((3 + 0.75) * var(--spacing));
}
:root .p-3t-tb {
  padding-bottom: calc((3 + 0.75) * var(--spacing));
}
:root .p-3t-tbl {
  padding-bottom: calc((3 + 0.75) * var(--spacing));
}
:root .p-3t-rb {
  padding-bottom: calc((3 + 0.75) * var(--spacing));
}
:root .p-3t-rbl {
  padding-bottom: calc((3 + 0.75) * var(--spacing));
}
:root .p-3t-b {
  padding-bottom: calc((3 + 0.75) * var(--spacing));
}
:root .p-3t-bl {
  padding-bottom: calc((3 + 0.75) * var(--spacing));
}
:root .p-3t {
  padding-left: calc((3 + 0.75) * var(--spacing));
}
:root .p-3t-trbl {
  padding-left: calc((3 + 0.75) * var(--spacing));
}
:root .p-3t-trl {
  padding-left: calc((3 + 0.75) * var(--spacing));
}
:root .p-3t-tbl {
  padding-left: calc((3 + 0.75) * var(--spacing));
}
:root .p-3t-tl {
  padding-left: calc((3 + 0.75) * var(--spacing));
}
:root .p-3t-rbl {
  padding-left: calc((3 + 0.75) * var(--spacing));
}
:root .p-3t-rl {
  padding-left: calc((3 + 0.75) * var(--spacing));
}
:root .p-3t-bl {
  padding-left: calc((3 + 0.75) * var(--spacing));
}
:root .p-3t-l {
  padding-left: calc((3 + 0.75) * var(--spacing));
}
:root .p-4 {
  padding-top: calc((4 + 0) * var(--spacing));
}
:root .p-4-t {
  padding-top: calc((4 + 0) * var(--spacing));
}
:root .p-4-tr {
  padding-top: calc((4 + 0) * var(--spacing));
}
:root .p-4-trb {
  padding-top: calc((4 + 0) * var(--spacing));
}
:root .p-4-trbl {
  padding-top: calc((4 + 0) * var(--spacing));
}
:root .p-4-trl {
  padding-top: calc((4 + 0) * var(--spacing));
}
:root .p-4-tb {
  padding-top: calc((4 + 0) * var(--spacing));
}
:root .p-4-tbl {
  padding-top: calc((4 + 0) * var(--spacing));
}
:root .p-4-tl {
  padding-top: calc((4 + 0) * var(--spacing));
}
:root .p-4 {
  padding-right: calc((4 + 0) * var(--spacing));
}
:root .p-4-tr {
  padding-right: calc((4 + 0) * var(--spacing));
}
:root .p-4-trb {
  padding-right: calc((4 + 0) * var(--spacing));
}
:root .p-4-trbl {
  padding-right: calc((4 + 0) * var(--spacing));
}
:root .p-4-trl {
  padding-right: calc((4 + 0) * var(--spacing));
}
:root .p-4-r {
  padding-right: calc((4 + 0) * var(--spacing));
}
:root .p-4-rb {
  padding-right: calc((4 + 0) * var(--spacing));
}
:root .p-4-rbl {
  padding-right: calc((4 + 0) * var(--spacing));
}
:root .p-4-rl {
  padding-right: calc((4 + 0) * var(--spacing));
}
:root .p-4 {
  padding-bottom: calc((4 + 0) * var(--spacing));
}
:root .p-4-trb {
  padding-bottom: calc((4 + 0) * var(--spacing));
}
:root .p-4-trbl {
  padding-bottom: calc((4 + 0) * var(--spacing));
}
:root .p-4-tb {
  padding-bottom: calc((4 + 0) * var(--spacing));
}
:root .p-4-tbl {
  padding-bottom: calc((4 + 0) * var(--spacing));
}
:root .p-4-rb {
  padding-bottom: calc((4 + 0) * var(--spacing));
}
:root .p-4-rbl {
  padding-bottom: calc((4 + 0) * var(--spacing));
}
:root .p-4-b {
  padding-bottom: calc((4 + 0) * var(--spacing));
}
:root .p-4-bl {
  padding-bottom: calc((4 + 0) * var(--spacing));
}
:root .p-4 {
  padding-left: calc((4 + 0) * var(--spacing));
}
:root .p-4-trbl {
  padding-left: calc((4 + 0) * var(--spacing));
}
:root .p-4-trl {
  padding-left: calc((4 + 0) * var(--spacing));
}
:root .p-4-tbl {
  padding-left: calc((4 + 0) * var(--spacing));
}
:root .p-4-tl {
  padding-left: calc((4 + 0) * var(--spacing));
}
:root .p-4-rbl {
  padding-left: calc((4 + 0) * var(--spacing));
}
:root .p-4-rl {
  padding-left: calc((4 + 0) * var(--spacing));
}
:root .p-4-bl {
  padding-left: calc((4 + 0) * var(--spacing));
}
:root .p-4-l {
  padding-left: calc((4 + 0) * var(--spacing));
}
:root .p-4q {
  padding-top: calc((4 + 0.25) * var(--spacing));
}
:root .p-4q-t {
  padding-top: calc((4 + 0.25) * var(--spacing));
}
:root .p-4q-tr {
  padding-top: calc((4 + 0.25) * var(--spacing));
}
:root .p-4q-trb {
  padding-top: calc((4 + 0.25) * var(--spacing));
}
:root .p-4q-trbl {
  padding-top: calc((4 + 0.25) * var(--spacing));
}
:root .p-4q-trl {
  padding-top: calc((4 + 0.25) * var(--spacing));
}
:root .p-4q-tb {
  padding-top: calc((4 + 0.25) * var(--spacing));
}
:root .p-4q-tbl {
  padding-top: calc((4 + 0.25) * var(--spacing));
}
:root .p-4q-tl {
  padding-top: calc((4 + 0.25) * var(--spacing));
}
:root .p-4q {
  padding-right: calc((4 + 0.25) * var(--spacing));
}
:root .p-4q-tr {
  padding-right: calc((4 + 0.25) * var(--spacing));
}
:root .p-4q-trb {
  padding-right: calc((4 + 0.25) * var(--spacing));
}
:root .p-4q-trbl {
  padding-right: calc((4 + 0.25) * var(--spacing));
}
:root .p-4q-trl {
  padding-right: calc((4 + 0.25) * var(--spacing));
}
:root .p-4q-r {
  padding-right: calc((4 + 0.25) * var(--spacing));
}
:root .p-4q-rb {
  padding-right: calc((4 + 0.25) * var(--spacing));
}
:root .p-4q-rbl {
  padding-right: calc((4 + 0.25) * var(--spacing));
}
:root .p-4q-rl {
  padding-right: calc((4 + 0.25) * var(--spacing));
}
:root .p-4q {
  padding-bottom: calc((4 + 0.25) * var(--spacing));
}
:root .p-4q-trb {
  padding-bottom: calc((4 + 0.25) * var(--spacing));
}
:root .p-4q-trbl {
  padding-bottom: calc((4 + 0.25) * var(--spacing));
}
:root .p-4q-tb {
  padding-bottom: calc((4 + 0.25) * var(--spacing));
}
:root .p-4q-tbl {
  padding-bottom: calc((4 + 0.25) * var(--spacing));
}
:root .p-4q-rb {
  padding-bottom: calc((4 + 0.25) * var(--spacing));
}
:root .p-4q-rbl {
  padding-bottom: calc((4 + 0.25) * var(--spacing));
}
:root .p-4q-b {
  padding-bottom: calc((4 + 0.25) * var(--spacing));
}
:root .p-4q-bl {
  padding-bottom: calc((4 + 0.25) * var(--spacing));
}
:root .p-4q {
  padding-left: calc((4 + 0.25) * var(--spacing));
}
:root .p-4q-trbl {
  padding-left: calc((4 + 0.25) * var(--spacing));
}
:root .p-4q-trl {
  padding-left: calc((4 + 0.25) * var(--spacing));
}
:root .p-4q-tbl {
  padding-left: calc((4 + 0.25) * var(--spacing));
}
:root .p-4q-tl {
  padding-left: calc((4 + 0.25) * var(--spacing));
}
:root .p-4q-rbl {
  padding-left: calc((4 + 0.25) * var(--spacing));
}
:root .p-4q-rl {
  padding-left: calc((4 + 0.25) * var(--spacing));
}
:root .p-4q-bl {
  padding-left: calc((4 + 0.25) * var(--spacing));
}
:root .p-4q-l {
  padding-left: calc((4 + 0.25) * var(--spacing));
}
:root .p-4h {
  padding-top: calc((4 + 0.5) * var(--spacing));
}
:root .p-4h-t {
  padding-top: calc((4 + 0.5) * var(--spacing));
}
:root .p-4h-tr {
  padding-top: calc((4 + 0.5) * var(--spacing));
}
:root .p-4h-trb {
  padding-top: calc((4 + 0.5) * var(--spacing));
}
:root .p-4h-trbl {
  padding-top: calc((4 + 0.5) * var(--spacing));
}
:root .p-4h-trl {
  padding-top: calc((4 + 0.5) * var(--spacing));
}
:root .p-4h-tb {
  padding-top: calc((4 + 0.5) * var(--spacing));
}
:root .p-4h-tbl {
  padding-top: calc((4 + 0.5) * var(--spacing));
}
:root .p-4h-tl {
  padding-top: calc((4 + 0.5) * var(--spacing));
}
:root .p-4h {
  padding-right: calc((4 + 0.5) * var(--spacing));
}
:root .p-4h-tr {
  padding-right: calc((4 + 0.5) * var(--spacing));
}
:root .p-4h-trb {
  padding-right: calc((4 + 0.5) * var(--spacing));
}
:root .p-4h-trbl {
  padding-right: calc((4 + 0.5) * var(--spacing));
}
:root .p-4h-trl {
  padding-right: calc((4 + 0.5) * var(--spacing));
}
:root .p-4h-r {
  padding-right: calc((4 + 0.5) * var(--spacing));
}
:root .p-4h-rb {
  padding-right: calc((4 + 0.5) * var(--spacing));
}
:root .p-4h-rbl {
  padding-right: calc((4 + 0.5) * var(--spacing));
}
:root .p-4h-rl {
  padding-right: calc((4 + 0.5) * var(--spacing));
}
:root .p-4h {
  padding-bottom: calc((4 + 0.5) * var(--spacing));
}
:root .p-4h-trb {
  padding-bottom: calc((4 + 0.5) * var(--spacing));
}
:root .p-4h-trbl {
  padding-bottom: calc((4 + 0.5) * var(--spacing));
}
:root .p-4h-tb {
  padding-bottom: calc((4 + 0.5) * var(--spacing));
}
:root .p-4h-tbl {
  padding-bottom: calc((4 + 0.5) * var(--spacing));
}
:root .p-4h-rb {
  padding-bottom: calc((4 + 0.5) * var(--spacing));
}
:root .p-4h-rbl {
  padding-bottom: calc((4 + 0.5) * var(--spacing));
}
:root .p-4h-b {
  padding-bottom: calc((4 + 0.5) * var(--spacing));
}
:root .p-4h-bl {
  padding-bottom: calc((4 + 0.5) * var(--spacing));
}
:root .p-4h {
  padding-left: calc((4 + 0.5) * var(--spacing));
}
:root .p-4h-trbl {
  padding-left: calc((4 + 0.5) * var(--spacing));
}
:root .p-4h-trl {
  padding-left: calc((4 + 0.5) * var(--spacing));
}
:root .p-4h-tbl {
  padding-left: calc((4 + 0.5) * var(--spacing));
}
:root .p-4h-tl {
  padding-left: calc((4 + 0.5) * var(--spacing));
}
:root .p-4h-rbl {
  padding-left: calc((4 + 0.5) * var(--spacing));
}
:root .p-4h-rl {
  padding-left: calc((4 + 0.5) * var(--spacing));
}
:root .p-4h-bl {
  padding-left: calc((4 + 0.5) * var(--spacing));
}
:root .p-4h-l {
  padding-left: calc((4 + 0.5) * var(--spacing));
}
:root .p-4t {
  padding-top: calc((4 + 0.75) * var(--spacing));
}
:root .p-4t-t {
  padding-top: calc((4 + 0.75) * var(--spacing));
}
:root .p-4t-tr {
  padding-top: calc((4 + 0.75) * var(--spacing));
}
:root .p-4t-trb {
  padding-top: calc((4 + 0.75) * var(--spacing));
}
:root .p-4t-trbl {
  padding-top: calc((4 + 0.75) * var(--spacing));
}
:root .p-4t-trl {
  padding-top: calc((4 + 0.75) * var(--spacing));
}
:root .p-4t-tb {
  padding-top: calc((4 + 0.75) * var(--spacing));
}
:root .p-4t-tbl {
  padding-top: calc((4 + 0.75) * var(--spacing));
}
:root .p-4t-tl {
  padding-top: calc((4 + 0.75) * var(--spacing));
}
:root .p-4t {
  padding-right: calc((4 + 0.75) * var(--spacing));
}
:root .p-4t-tr {
  padding-right: calc((4 + 0.75) * var(--spacing));
}
:root .p-4t-trb {
  padding-right: calc((4 + 0.75) * var(--spacing));
}
:root .p-4t-trbl {
  padding-right: calc((4 + 0.75) * var(--spacing));
}
:root .p-4t-trl {
  padding-right: calc((4 + 0.75) * var(--spacing));
}
:root .p-4t-r {
  padding-right: calc((4 + 0.75) * var(--spacing));
}
:root .p-4t-rb {
  padding-right: calc((4 + 0.75) * var(--spacing));
}
:root .p-4t-rbl {
  padding-right: calc((4 + 0.75) * var(--spacing));
}
:root .p-4t-rl {
  padding-right: calc((4 + 0.75) * var(--spacing));
}
:root .p-4t {
  padding-bottom: calc((4 + 0.75) * var(--spacing));
}
:root .p-4t-trb {
  padding-bottom: calc((4 + 0.75) * var(--spacing));
}
:root .p-4t-trbl {
  padding-bottom: calc((4 + 0.75) * var(--spacing));
}
:root .p-4t-tb {
  padding-bottom: calc((4 + 0.75) * var(--spacing));
}
:root .p-4t-tbl {
  padding-bottom: calc((4 + 0.75) * var(--spacing));
}
:root .p-4t-rb {
  padding-bottom: calc((4 + 0.75) * var(--spacing));
}
:root .p-4t-rbl {
  padding-bottom: calc((4 + 0.75) * var(--spacing));
}
:root .p-4t-b {
  padding-bottom: calc((4 + 0.75) * var(--spacing));
}
:root .p-4t-bl {
  padding-bottom: calc((4 + 0.75) * var(--spacing));
}
:root .p-4t {
  padding-left: calc((4 + 0.75) * var(--spacing));
}
:root .p-4t-trbl {
  padding-left: calc((4 + 0.75) * var(--spacing));
}
:root .p-4t-trl {
  padding-left: calc((4 + 0.75) * var(--spacing));
}
:root .p-4t-tbl {
  padding-left: calc((4 + 0.75) * var(--spacing));
}
:root .p-4t-tl {
  padding-left: calc((4 + 0.75) * var(--spacing));
}
:root .p-4t-rbl {
  padding-left: calc((4 + 0.75) * var(--spacing));
}
:root .p-4t-rl {
  padding-left: calc((4 + 0.75) * var(--spacing));
}
:root .p-4t-bl {
  padding-left: calc((4 + 0.75) * var(--spacing));
}
:root .p-4t-l {
  padding-left: calc((4 + 0.75) * var(--spacing));
}
:root .p-5 {
  padding-top: calc((5 + 0) * var(--spacing));
}
:root .p-5-t {
  padding-top: calc((5 + 0) * var(--spacing));
}
:root .p-5-tr {
  padding-top: calc((5 + 0) * var(--spacing));
}
:root .p-5-trb {
  padding-top: calc((5 + 0) * var(--spacing));
}
:root .p-5-trbl {
  padding-top: calc((5 + 0) * var(--spacing));
}
:root .p-5-trl {
  padding-top: calc((5 + 0) * var(--spacing));
}
:root .p-5-tb {
  padding-top: calc((5 + 0) * var(--spacing));
}
:root .p-5-tbl {
  padding-top: calc((5 + 0) * var(--spacing));
}
:root .p-5-tl {
  padding-top: calc((5 + 0) * var(--spacing));
}
:root .p-5 {
  padding-right: calc((5 + 0) * var(--spacing));
}
:root .p-5-tr {
  padding-right: calc((5 + 0) * var(--spacing));
}
:root .p-5-trb {
  padding-right: calc((5 + 0) * var(--spacing));
}
:root .p-5-trbl {
  padding-right: calc((5 + 0) * var(--spacing));
}
:root .p-5-trl {
  padding-right: calc((5 + 0) * var(--spacing));
}
:root .p-5-r {
  padding-right: calc((5 + 0) * var(--spacing));
}
:root .p-5-rb {
  padding-right: calc((5 + 0) * var(--spacing));
}
:root .p-5-rbl {
  padding-right: calc((5 + 0) * var(--spacing));
}
:root .p-5-rl {
  padding-right: calc((5 + 0) * var(--spacing));
}
:root .p-5 {
  padding-bottom: calc((5 + 0) * var(--spacing));
}
:root .p-5-trb {
  padding-bottom: calc((5 + 0) * var(--spacing));
}
:root .p-5-trbl {
  padding-bottom: calc((5 + 0) * var(--spacing));
}
:root .p-5-tb {
  padding-bottom: calc((5 + 0) * var(--spacing));
}
:root .p-5-tbl {
  padding-bottom: calc((5 + 0) * var(--spacing));
}
:root .p-5-rb {
  padding-bottom: calc((5 + 0) * var(--spacing));
}
:root .p-5-rbl {
  padding-bottom: calc((5 + 0) * var(--spacing));
}
:root .p-5-b {
  padding-bottom: calc((5 + 0) * var(--spacing));
}
:root .p-5-bl {
  padding-bottom: calc((5 + 0) * var(--spacing));
}
:root .p-5 {
  padding-left: calc((5 + 0) * var(--spacing));
}
:root .p-5-trbl {
  padding-left: calc((5 + 0) * var(--spacing));
}
:root .p-5-trl {
  padding-left: calc((5 + 0) * var(--spacing));
}
:root .p-5-tbl {
  padding-left: calc((5 + 0) * var(--spacing));
}
:root .p-5-tl {
  padding-left: calc((5 + 0) * var(--spacing));
}
:root .p-5-rbl {
  padding-left: calc((5 + 0) * var(--spacing));
}
:root .p-5-rl {
  padding-left: calc((5 + 0) * var(--spacing));
}
:root .p-5-bl {
  padding-left: calc((5 + 0) * var(--spacing));
}
:root .p-5-l {
  padding-left: calc((5 + 0) * var(--spacing));
}
:root .p-5q {
  padding-top: calc((5 + 0.25) * var(--spacing));
}
:root .p-5q-t {
  padding-top: calc((5 + 0.25) * var(--spacing));
}
:root .p-5q-tr {
  padding-top: calc((5 + 0.25) * var(--spacing));
}
:root .p-5q-trb {
  padding-top: calc((5 + 0.25) * var(--spacing));
}
:root .p-5q-trbl {
  padding-top: calc((5 + 0.25) * var(--spacing));
}
:root .p-5q-trl {
  padding-top: calc((5 + 0.25) * var(--spacing));
}
:root .p-5q-tb {
  padding-top: calc((5 + 0.25) * var(--spacing));
}
:root .p-5q-tbl {
  padding-top: calc((5 + 0.25) * var(--spacing));
}
:root .p-5q-tl {
  padding-top: calc((5 + 0.25) * var(--spacing));
}
:root .p-5q {
  padding-right: calc((5 + 0.25) * var(--spacing));
}
:root .p-5q-tr {
  padding-right: calc((5 + 0.25) * var(--spacing));
}
:root .p-5q-trb {
  padding-right: calc((5 + 0.25) * var(--spacing));
}
:root .p-5q-trbl {
  padding-right: calc((5 + 0.25) * var(--spacing));
}
:root .p-5q-trl {
  padding-right: calc((5 + 0.25) * var(--spacing));
}
:root .p-5q-r {
  padding-right: calc((5 + 0.25) * var(--spacing));
}
:root .p-5q-rb {
  padding-right: calc((5 + 0.25) * var(--spacing));
}
:root .p-5q-rbl {
  padding-right: calc((5 + 0.25) * var(--spacing));
}
:root .p-5q-rl {
  padding-right: calc((5 + 0.25) * var(--spacing));
}
:root .p-5q {
  padding-bottom: calc((5 + 0.25) * var(--spacing));
}
:root .p-5q-trb {
  padding-bottom: calc((5 + 0.25) * var(--spacing));
}
:root .p-5q-trbl {
  padding-bottom: calc((5 + 0.25) * var(--spacing));
}
:root .p-5q-tb {
  padding-bottom: calc((5 + 0.25) * var(--spacing));
}
:root .p-5q-tbl {
  padding-bottom: calc((5 + 0.25) * var(--spacing));
}
:root .p-5q-rb {
  padding-bottom: calc((5 + 0.25) * var(--spacing));
}
:root .p-5q-rbl {
  padding-bottom: calc((5 + 0.25) * var(--spacing));
}
:root .p-5q-b {
  padding-bottom: calc((5 + 0.25) * var(--spacing));
}
:root .p-5q-bl {
  padding-bottom: calc((5 + 0.25) * var(--spacing));
}
:root .p-5q {
  padding-left: calc((5 + 0.25) * var(--spacing));
}
:root .p-5q-trbl {
  padding-left: calc((5 + 0.25) * var(--spacing));
}
:root .p-5q-trl {
  padding-left: calc((5 + 0.25) * var(--spacing));
}
:root .p-5q-tbl {
  padding-left: calc((5 + 0.25) * var(--spacing));
}
:root .p-5q-tl {
  padding-left: calc((5 + 0.25) * var(--spacing));
}
:root .p-5q-rbl {
  padding-left: calc((5 + 0.25) * var(--spacing));
}
:root .p-5q-rl {
  padding-left: calc((5 + 0.25) * var(--spacing));
}
:root .p-5q-bl {
  padding-left: calc((5 + 0.25) * var(--spacing));
}
:root .p-5q-l {
  padding-left: calc((5 + 0.25) * var(--spacing));
}
:root .p-5h {
  padding-top: calc((5 + 0.5) * var(--spacing));
}
:root .p-5h-t {
  padding-top: calc((5 + 0.5) * var(--spacing));
}
:root .p-5h-tr {
  padding-top: calc((5 + 0.5) * var(--spacing));
}
:root .p-5h-trb {
  padding-top: calc((5 + 0.5) * var(--spacing));
}
:root .p-5h-trbl {
  padding-top: calc((5 + 0.5) * var(--spacing));
}
:root .p-5h-trl {
  padding-top: calc((5 + 0.5) * var(--spacing));
}
:root .p-5h-tb {
  padding-top: calc((5 + 0.5) * var(--spacing));
}
:root .p-5h-tbl {
  padding-top: calc((5 + 0.5) * var(--spacing));
}
:root .p-5h-tl {
  padding-top: calc((5 + 0.5) * var(--spacing));
}
:root .p-5h {
  padding-right: calc((5 + 0.5) * var(--spacing));
}
:root .p-5h-tr {
  padding-right: calc((5 + 0.5) * var(--spacing));
}
:root .p-5h-trb {
  padding-right: calc((5 + 0.5) * var(--spacing));
}
:root .p-5h-trbl {
  padding-right: calc((5 + 0.5) * var(--spacing));
}
:root .p-5h-trl {
  padding-right: calc((5 + 0.5) * var(--spacing));
}
:root .p-5h-r {
  padding-right: calc((5 + 0.5) * var(--spacing));
}
:root .p-5h-rb {
  padding-right: calc((5 + 0.5) * var(--spacing));
}
:root .p-5h-rbl {
  padding-right: calc((5 + 0.5) * var(--spacing));
}
:root .p-5h-rl {
  padding-right: calc((5 + 0.5) * var(--spacing));
}
:root .p-5h {
  padding-bottom: calc((5 + 0.5) * var(--spacing));
}
:root .p-5h-trb {
  padding-bottom: calc((5 + 0.5) * var(--spacing));
}
:root .p-5h-trbl {
  padding-bottom: calc((5 + 0.5) * var(--spacing));
}
:root .p-5h-tb {
  padding-bottom: calc((5 + 0.5) * var(--spacing));
}
:root .p-5h-tbl {
  padding-bottom: calc((5 + 0.5) * var(--spacing));
}
:root .p-5h-rb {
  padding-bottom: calc((5 + 0.5) * var(--spacing));
}
:root .p-5h-rbl {
  padding-bottom: calc((5 + 0.5) * var(--spacing));
}
:root .p-5h-b {
  padding-bottom: calc((5 + 0.5) * var(--spacing));
}
:root .p-5h-bl {
  padding-bottom: calc((5 + 0.5) * var(--spacing));
}
:root .p-5h {
  padding-left: calc((5 + 0.5) * var(--spacing));
}
:root .p-5h-trbl {
  padding-left: calc((5 + 0.5) * var(--spacing));
}
:root .p-5h-trl {
  padding-left: calc((5 + 0.5) * var(--spacing));
}
:root .p-5h-tbl {
  padding-left: calc((5 + 0.5) * var(--spacing));
}
:root .p-5h-tl {
  padding-left: calc((5 + 0.5) * var(--spacing));
}
:root .p-5h-rbl {
  padding-left: calc((5 + 0.5) * var(--spacing));
}
:root .p-5h-rl {
  padding-left: calc((5 + 0.5) * var(--spacing));
}
:root .p-5h-bl {
  padding-left: calc((5 + 0.5) * var(--spacing));
}
:root .p-5h-l {
  padding-left: calc((5 + 0.5) * var(--spacing));
}
:root .p-5t {
  padding-top: calc((5 + 0.75) * var(--spacing));
}
:root .p-5t-t {
  padding-top: calc((5 + 0.75) * var(--spacing));
}
:root .p-5t-tr {
  padding-top: calc((5 + 0.75) * var(--spacing));
}
:root .p-5t-trb {
  padding-top: calc((5 + 0.75) * var(--spacing));
}
:root .p-5t-trbl {
  padding-top: calc((5 + 0.75) * var(--spacing));
}
:root .p-5t-trl {
  padding-top: calc((5 + 0.75) * var(--spacing));
}
:root .p-5t-tb {
  padding-top: calc((5 + 0.75) * var(--spacing));
}
:root .p-5t-tbl {
  padding-top: calc((5 + 0.75) * var(--spacing));
}
:root .p-5t-tl {
  padding-top: calc((5 + 0.75) * var(--spacing));
}
:root .p-5t {
  padding-right: calc((5 + 0.75) * var(--spacing));
}
:root .p-5t-tr {
  padding-right: calc((5 + 0.75) * var(--spacing));
}
:root .p-5t-trb {
  padding-right: calc((5 + 0.75) * var(--spacing));
}
:root .p-5t-trbl {
  padding-right: calc((5 + 0.75) * var(--spacing));
}
:root .p-5t-trl {
  padding-right: calc((5 + 0.75) * var(--spacing));
}
:root .p-5t-r {
  padding-right: calc((5 + 0.75) * var(--spacing));
}
:root .p-5t-rb {
  padding-right: calc((5 + 0.75) * var(--spacing));
}
:root .p-5t-rbl {
  padding-right: calc((5 + 0.75) * var(--spacing));
}
:root .p-5t-rl {
  padding-right: calc((5 + 0.75) * var(--spacing));
}
:root .p-5t {
  padding-bottom: calc((5 + 0.75) * var(--spacing));
}
:root .p-5t-trb {
  padding-bottom: calc((5 + 0.75) * var(--spacing));
}
:root .p-5t-trbl {
  padding-bottom: calc((5 + 0.75) * var(--spacing));
}
:root .p-5t-tb {
  padding-bottom: calc((5 + 0.75) * var(--spacing));
}
:root .p-5t-tbl {
  padding-bottom: calc((5 + 0.75) * var(--spacing));
}
:root .p-5t-rb {
  padding-bottom: calc((5 + 0.75) * var(--spacing));
}
:root .p-5t-rbl {
  padding-bottom: calc((5 + 0.75) * var(--spacing));
}
:root .p-5t-b {
  padding-bottom: calc((5 + 0.75) * var(--spacing));
}
:root .p-5t-bl {
  padding-bottom: calc((5 + 0.75) * var(--spacing));
}
:root .p-5t {
  padding-left: calc((5 + 0.75) * var(--spacing));
}
:root .p-5t-trbl {
  padding-left: calc((5 + 0.75) * var(--spacing));
}
:root .p-5t-trl {
  padding-left: calc((5 + 0.75) * var(--spacing));
}
:root .p-5t-tbl {
  padding-left: calc((5 + 0.75) * var(--spacing));
}
:root .p-5t-tl {
  padding-left: calc((5 + 0.75) * var(--spacing));
}
:root .p-5t-rbl {
  padding-left: calc((5 + 0.75) * var(--spacing));
}
:root .p-5t-rl {
  padding-left: calc((5 + 0.75) * var(--spacing));
}
:root .p-5t-bl {
  padding-left: calc((5 + 0.75) * var(--spacing));
}
:root .p-5t-l {
  padding-left: calc((5 + 0.75) * var(--spacing));
}
:root .p-6 {
  padding-top: calc(6 * var(--spacing));
}
:root .p-6-t {
  padding-top: calc(6 * var(--spacing));
}
:root .p-6-tr {
  padding-top: calc(6 * var(--spacing));
}
:root .p-6-trb {
  padding-top: calc(6 * var(--spacing));
}
:root .p-6-trbl {
  padding-top: calc(6 * var(--spacing));
}
:root .p-6-trl {
  padding-top: calc(6 * var(--spacing));
}
:root .p-6-tb {
  padding-top: calc(6 * var(--spacing));
}
:root .p-6-tbl {
  padding-top: calc(6 * var(--spacing));
}
:root .p-6-tl {
  padding-top: calc(6 * var(--spacing));
}
:root .p-6 {
  padding-right: calc(6 * var(--spacing));
}
:root .p-6-tr {
  padding-right: calc(6 * var(--spacing));
}
:root .p-6-trb {
  padding-right: calc(6 * var(--spacing));
}
:root .p-6-trbl {
  padding-right: calc(6 * var(--spacing));
}
:root .p-6-trl {
  padding-right: calc(6 * var(--spacing));
}
:root .p-6-r {
  padding-right: calc(6 * var(--spacing));
}
:root .p-6-rb {
  padding-right: calc(6 * var(--spacing));
}
:root .p-6-rbl {
  padding-right: calc(6 * var(--spacing));
}
:root .p-6-rl {
  padding-right: calc(6 * var(--spacing));
}
:root .p-6 {
  padding-bottom: calc(6 * var(--spacing));
}
:root .p-6-trb {
  padding-bottom: calc(6 * var(--spacing));
}
:root .p-6-trbl {
  padding-bottom: calc(6 * var(--spacing));
}
:root .p-6-tb {
  padding-bottom: calc(6 * var(--spacing));
}
:root .p-6-tbl {
  padding-bottom: calc(6 * var(--spacing));
}
:root .p-6-rb {
  padding-bottom: calc(6 * var(--spacing));
}
:root .p-6-rbl {
  padding-bottom: calc(6 * var(--spacing));
}
:root .p-6-b {
  padding-bottom: calc(6 * var(--spacing));
}
:root .p-6-bl {
  padding-bottom: calc(6 * var(--spacing));
}
:root .p-6 {
  padding-left: calc(6 * var(--spacing));
}
:root .p-6-trbl {
  padding-left: calc(6 * var(--spacing));
}
:root .p-6-trl {
  padding-left: calc(6 * var(--spacing));
}
:root .p-6-tbl {
  padding-left: calc(6 * var(--spacing));
}
:root .p-6-tl {
  padding-left: calc(6 * var(--spacing));
}
:root .p-6-rbl {
  padding-left: calc(6 * var(--spacing));
}
:root .p-6-rl {
  padding-left: calc(6 * var(--spacing));
}
:root .p-6-bl {
  padding-left: calc(6 * var(--spacing));
}
:root .p-6-l {
  padding-left: calc(6 * var(--spacing));
}
:root .p-7 {
  padding-top: calc(7 * var(--spacing));
}
:root .p-7-t {
  padding-top: calc(7 * var(--spacing));
}
:root .p-7-tr {
  padding-top: calc(7 * var(--spacing));
}
:root .p-7-trb {
  padding-top: calc(7 * var(--spacing));
}
:root .p-7-trbl {
  padding-top: calc(7 * var(--spacing));
}
:root .p-7-trl {
  padding-top: calc(7 * var(--spacing));
}
:root .p-7-tb {
  padding-top: calc(7 * var(--spacing));
}
:root .p-7-tbl {
  padding-top: calc(7 * var(--spacing));
}
:root .p-7-tl {
  padding-top: calc(7 * var(--spacing));
}
:root .p-7 {
  padding-right: calc(7 * var(--spacing));
}
:root .p-7-tr {
  padding-right: calc(7 * var(--spacing));
}
:root .p-7-trb {
  padding-right: calc(7 * var(--spacing));
}
:root .p-7-trbl {
  padding-right: calc(7 * var(--spacing));
}
:root .p-7-trl {
  padding-right: calc(7 * var(--spacing));
}
:root .p-7-r {
  padding-right: calc(7 * var(--spacing));
}
:root .p-7-rb {
  padding-right: calc(7 * var(--spacing));
}
:root .p-7-rbl {
  padding-right: calc(7 * var(--spacing));
}
:root .p-7-rl {
  padding-right: calc(7 * var(--spacing));
}
:root .p-7 {
  padding-bottom: calc(7 * var(--spacing));
}
:root .p-7-trb {
  padding-bottom: calc(7 * var(--spacing));
}
:root .p-7-trbl {
  padding-bottom: calc(7 * var(--spacing));
}
:root .p-7-tb {
  padding-bottom: calc(7 * var(--spacing));
}
:root .p-7-tbl {
  padding-bottom: calc(7 * var(--spacing));
}
:root .p-7-rb {
  padding-bottom: calc(7 * var(--spacing));
}
:root .p-7-rbl {
  padding-bottom: calc(7 * var(--spacing));
}
:root .p-7-b {
  padding-bottom: calc(7 * var(--spacing));
}
:root .p-7-bl {
  padding-bottom: calc(7 * var(--spacing));
}
:root .p-7 {
  padding-left: calc(7 * var(--spacing));
}
:root .p-7-trbl {
  padding-left: calc(7 * var(--spacing));
}
:root .p-7-trl {
  padding-left: calc(7 * var(--spacing));
}
:root .p-7-tbl {
  padding-left: calc(7 * var(--spacing));
}
:root .p-7-tl {
  padding-left: calc(7 * var(--spacing));
}
:root .p-7-rbl {
  padding-left: calc(7 * var(--spacing));
}
:root .p-7-rl {
  padding-left: calc(7 * var(--spacing));
}
:root .p-7-bl {
  padding-left: calc(7 * var(--spacing));
}
:root .p-7-l {
  padding-left: calc(7 * var(--spacing));
}
:root .p-8 {
  padding-top: calc(8 * var(--spacing));
}
:root .p-8-t {
  padding-top: calc(8 * var(--spacing));
}
:root .p-8-tr {
  padding-top: calc(8 * var(--spacing));
}
:root .p-8-trb {
  padding-top: calc(8 * var(--spacing));
}
:root .p-8-trbl {
  padding-top: calc(8 * var(--spacing));
}
:root .p-8-trl {
  padding-top: calc(8 * var(--spacing));
}
:root .p-8-tb {
  padding-top: calc(8 * var(--spacing));
}
:root .p-8-tbl {
  padding-top: calc(8 * var(--spacing));
}
:root .p-8-tl {
  padding-top: calc(8 * var(--spacing));
}
:root .p-8 {
  padding-right: calc(8 * var(--spacing));
}
:root .p-8-tr {
  padding-right: calc(8 * var(--spacing));
}
:root .p-8-trb {
  padding-right: calc(8 * var(--spacing));
}
:root .p-8-trbl {
  padding-right: calc(8 * var(--spacing));
}
:root .p-8-trl {
  padding-right: calc(8 * var(--spacing));
}
:root .p-8-r {
  padding-right: calc(8 * var(--spacing));
}
:root .p-8-rb {
  padding-right: calc(8 * var(--spacing));
}
:root .p-8-rbl {
  padding-right: calc(8 * var(--spacing));
}
:root .p-8-rl {
  padding-right: calc(8 * var(--spacing));
}
:root .p-8 {
  padding-bottom: calc(8 * var(--spacing));
}
:root .p-8-trb {
  padding-bottom: calc(8 * var(--spacing));
}
:root .p-8-trbl {
  padding-bottom: calc(8 * var(--spacing));
}
:root .p-8-tb {
  padding-bottom: calc(8 * var(--spacing));
}
:root .p-8-tbl {
  padding-bottom: calc(8 * var(--spacing));
}
:root .p-8-rb {
  padding-bottom: calc(8 * var(--spacing));
}
:root .p-8-rbl {
  padding-bottom: calc(8 * var(--spacing));
}
:root .p-8-b {
  padding-bottom: calc(8 * var(--spacing));
}
:root .p-8-bl {
  padding-bottom: calc(8 * var(--spacing));
}
:root .p-8 {
  padding-left: calc(8 * var(--spacing));
}
:root .p-8-trbl {
  padding-left: calc(8 * var(--spacing));
}
:root .p-8-trl {
  padding-left: calc(8 * var(--spacing));
}
:root .p-8-tbl {
  padding-left: calc(8 * var(--spacing));
}
:root .p-8-tl {
  padding-left: calc(8 * var(--spacing));
}
:root .p-8-rbl {
  padding-left: calc(8 * var(--spacing));
}
:root .p-8-rl {
  padding-left: calc(8 * var(--spacing));
}
:root .p-8-bl {
  padding-left: calc(8 * var(--spacing));
}
:root .p-8-l {
  padding-left: calc(8 * var(--spacing));
}
:root .p-9 {
  padding-top: calc(9 * var(--spacing));
}
:root .p-9-t {
  padding-top: calc(9 * var(--spacing));
}
:root .p-9-tr {
  padding-top: calc(9 * var(--spacing));
}
:root .p-9-trb {
  padding-top: calc(9 * var(--spacing));
}
:root .p-9-trbl {
  padding-top: calc(9 * var(--spacing));
}
:root .p-9-trl {
  padding-top: calc(9 * var(--spacing));
}
:root .p-9-tb {
  padding-top: calc(9 * var(--spacing));
}
:root .p-9-tbl {
  padding-top: calc(9 * var(--spacing));
}
:root .p-9-tl {
  padding-top: calc(9 * var(--spacing));
}
:root .p-9 {
  padding-right: calc(9 * var(--spacing));
}
:root .p-9-tr {
  padding-right: calc(9 * var(--spacing));
}
:root .p-9-trb {
  padding-right: calc(9 * var(--spacing));
}
:root .p-9-trbl {
  padding-right: calc(9 * var(--spacing));
}
:root .p-9-trl {
  padding-right: calc(9 * var(--spacing));
}
:root .p-9-r {
  padding-right: calc(9 * var(--spacing));
}
:root .p-9-rb {
  padding-right: calc(9 * var(--spacing));
}
:root .p-9-rbl {
  padding-right: calc(9 * var(--spacing));
}
:root .p-9-rl {
  padding-right: calc(9 * var(--spacing));
}
:root .p-9 {
  padding-bottom: calc(9 * var(--spacing));
}
:root .p-9-trb {
  padding-bottom: calc(9 * var(--spacing));
}
:root .p-9-trbl {
  padding-bottom: calc(9 * var(--spacing));
}
:root .p-9-tb {
  padding-bottom: calc(9 * var(--spacing));
}
:root .p-9-tbl {
  padding-bottom: calc(9 * var(--spacing));
}
:root .p-9-rb {
  padding-bottom: calc(9 * var(--spacing));
}
:root .p-9-rbl {
  padding-bottom: calc(9 * var(--spacing));
}
:root .p-9-b {
  padding-bottom: calc(9 * var(--spacing));
}
:root .p-9-bl {
  padding-bottom: calc(9 * var(--spacing));
}
:root .p-9 {
  padding-left: calc(9 * var(--spacing));
}
:root .p-9-trbl {
  padding-left: calc(9 * var(--spacing));
}
:root .p-9-trl {
  padding-left: calc(9 * var(--spacing));
}
:root .p-9-tbl {
  padding-left: calc(9 * var(--spacing));
}
:root .p-9-tl {
  padding-left: calc(9 * var(--spacing));
}
:root .p-9-rbl {
  padding-left: calc(9 * var(--spacing));
}
:root .p-9-rl {
  padding-left: calc(9 * var(--spacing));
}
:root .p-9-bl {
  padding-left: calc(9 * var(--spacing));
}
:root .p-9-l {
  padding-left: calc(9 * var(--spacing));
}
:root .p-10 {
  padding-top: calc(10 * var(--spacing));
}
:root .p-10-t {
  padding-top: calc(10 * var(--spacing));
}
:root .p-10-tr {
  padding-top: calc(10 * var(--spacing));
}
:root .p-10-trb {
  padding-top: calc(10 * var(--spacing));
}
:root .p-10-trbl {
  padding-top: calc(10 * var(--spacing));
}
:root .p-10-trl {
  padding-top: calc(10 * var(--spacing));
}
:root .p-10-tb {
  padding-top: calc(10 * var(--spacing));
}
:root .p-10-tbl {
  padding-top: calc(10 * var(--spacing));
}
:root .p-10-tl {
  padding-top: calc(10 * var(--spacing));
}
:root .p-10 {
  padding-right: calc(10 * var(--spacing));
}
:root .p-10-tr {
  padding-right: calc(10 * var(--spacing));
}
:root .p-10-trb {
  padding-right: calc(10 * var(--spacing));
}
:root .p-10-trbl {
  padding-right: calc(10 * var(--spacing));
}
:root .p-10-trl {
  padding-right: calc(10 * var(--spacing));
}
:root .p-10-r {
  padding-right: calc(10 * var(--spacing));
}
:root .p-10-rb {
  padding-right: calc(10 * var(--spacing));
}
:root .p-10-rbl {
  padding-right: calc(10 * var(--spacing));
}
:root .p-10-rl {
  padding-right: calc(10 * var(--spacing));
}
:root .p-10 {
  padding-bottom: calc(10 * var(--spacing));
}
:root .p-10-trb {
  padding-bottom: calc(10 * var(--spacing));
}
:root .p-10-trbl {
  padding-bottom: calc(10 * var(--spacing));
}
:root .p-10-tb {
  padding-bottom: calc(10 * var(--spacing));
}
:root .p-10-tbl {
  padding-bottom: calc(10 * var(--spacing));
}
:root .p-10-rb {
  padding-bottom: calc(10 * var(--spacing));
}
:root .p-10-rbl {
  padding-bottom: calc(10 * var(--spacing));
}
:root .p-10-b {
  padding-bottom: calc(10 * var(--spacing));
}
:root .p-10-bl {
  padding-bottom: calc(10 * var(--spacing));
}
:root .p-10 {
  padding-left: calc(10 * var(--spacing));
}
:root .p-10-trbl {
  padding-left: calc(10 * var(--spacing));
}
:root .p-10-trl {
  padding-left: calc(10 * var(--spacing));
}
:root .p-10-tbl {
  padding-left: calc(10 * var(--spacing));
}
:root .p-10-tl {
  padding-left: calc(10 * var(--spacing));
}
:root .p-10-rbl {
  padding-left: calc(10 * var(--spacing));
}
:root .p-10-rl {
  padding-left: calc(10 * var(--spacing));
}
:root .p-10-bl {
  padding-left: calc(10 * var(--spacing));
}
:root .p-10-l {
  padding-left: calc(10 * var(--spacing));
}
:root .p-8h-t {
  padding-top: calc(8.5 * var(--spacing));
}
:root .p-8h-tr {
  padding-top: calc(8.5 * var(--spacing));
}
:root .p-8h-trb {
  padding-top: calc(8.5 * var(--spacing));
}
:root .p-8h-trbl {
  padding-top: calc(8.5 * var(--spacing));
}
:root .p-8h-trl {
  padding-top: calc(8.5 * var(--spacing));
}
:root .p-8h-tb {
  padding-top: calc(8.5 * var(--spacing));
}
:root .p-8h-tbl {
  padding-top: calc(8.5 * var(--spacing));
}
:root .p-8h-tl {
  padding-top: calc(8.5 * var(--spacing));
}
:root .p-8h-tr {
  padding-right: calc(8.5 * var(--spacing));
}
:root .p-8h-trb {
  padding-right: calc(8.5 * var(--spacing));
}
:root .p-8h-trbl {
  padding-right: calc(8.5 * var(--spacing));
}
:root .p-8h-trl {
  padding-right: calc(8.5 * var(--spacing));
}
:root .p-8h-r {
  padding-right: calc(8.5 * var(--spacing));
}
:root .p-8h-rb {
  padding-right: calc(8.5 * var(--spacing));
}
:root .p-8h-rbl {
  padding-right: calc(8.5 * var(--spacing));
}
:root .p-8h-rl {
  padding-right: calc(8.5 * var(--spacing));
}
:root .p-8h-trb {
  padding-bottom: calc(8.5 * var(--spacing));
}
:root .p-8h-trbl {
  padding-bottom: calc(8.5 * var(--spacing));
}
:root .p-8h-tb {
  padding-bottom: calc(8.5 * var(--spacing));
}
:root .p-8h-tbl {
  padding-bottom: calc(8.5 * var(--spacing));
}
:root .p-8h-rb {
  padding-bottom: calc(8.5 * var(--spacing));
}
:root .p-8h-rbl {
  padding-bottom: calc(8.5 * var(--spacing));
}
:root .p-8h-b {
  padding-bottom: calc(8.5 * var(--spacing));
}
:root .p-8h-bl {
  padding-bottom: calc(8.5 * var(--spacing));
}
:root .p-8h-trbl {
  padding-left: calc(8.5 * var(--spacing));
}
:root .p-8h-trl {
  padding-left: calc(8.5 * var(--spacing));
}
:root .p-8h-tbl {
  padding-left: calc(8.5 * var(--spacing));
}
:root .p-8h-tl {
  padding-left: calc(8.5 * var(--spacing));
}
:root .p-8h-rbl {
  padding-left: calc(8.5 * var(--spacing));
}
:root .p-8h-rl {
  padding-left: calc(8.5 * var(--spacing));
}
:root .p-8h-bl {
  padding-left: calc(8.5 * var(--spacing));
}
:root .p-8h-l {
  padding-left: calc(8.5 * var(--spacing));
}

:root {
  --safe-area-inset-top: env(safe-area-inset-top, 0px);
  --safe-area-inset-right: env(safe-area-inset-right, 0px);
  --safe-area-inset-bottom: env(safe-area-inset-bottom, 0px);
  --safe-area-inset-left: env(safe-area-inset-left, 0px);
}

:root {
  --spacing: 8px;
}

.border-box {
  box-sizing: border-box;
}

body {
  --hot-toast-bg: rgb(var(--color-bg-280-rgb));
  --hot-toast-shadow: var(--shadow-2);
  --hot-toast-color: var(--color-text-800);
}

.hot-toast-error-icon {
  --error-primary: var(--color-warn) !important;
  --error-secondary: var(--hot-toast-bg) !important;
}

hot-toast-container > div {
  padding: var(--safe-area-inset-top) var(--safe-area-inset-right) var(--safe-area-inset-bottom) var(--safe-area-inset-left) !important;
  box-sizing: border-box;
}

markdown {
  --spacing: 8px;
  line-height: 1.5;
}
markdown h1 {
  font-size: 26px;
  line-height: 30px;
  font-weight: 600;
}
markdown h2 {
  font-size: 22px;
  line-height: 26px;
  font-weight: 600;
}
markdown h3 {
  font-size: 22px;
  line-height: 26px;
  font-weight: 400;
}
markdown h4 {
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
}
markdown h5 {
  font-size: 18px;
  line-height: 22px;
  font-weight: 600;
}
markdown h6 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
}
markdown ul,
markdown ol {
  margin: 0;
  padding: 0;
  list-style-position: inside;
  white-space: nowrap;
}
markdown ul > ul,
markdown ul > li > ul,
markdown ul > ol,
markdown ul > li > ol,
markdown ol > ul,
markdown ol > li > ul,
markdown ol > ol,
markdown ol > li > ol {
  padding-left: calc(2 * var(--spacing));
}
markdown ul > li,
markdown ol > li {
  margin: calc(1.25 * var(--spacing)) 0;
  white-space: pre-line;
}
markdown ul > li::marker,
markdown ol > li::marker {
  color: rgb(var(--color-text-400-rgb));
}
markdown ul > li > p,
markdown ol > li > p {
  display: contents;
  vertical-align: text-top;
}
markdown table {
  border-collapse: collapse;
}
markdown th {
  background-color: rgb(var(--color-line-230-rgb));
  color: rgb(var(--color-text-800-rgb));
  font-weight: var(--fw-bold);
}
markdown th:first-of-type {
  border-top-left-radius: 8px;
}
markdown th:last-of-type {
  border-top-right-radius: 8px;
}
markdown tr:last-child td:first-of-type {
  border-bottom-left-radius: 8px;
}
markdown tr:last-child td:last-of-type {
  border-bottom-right-radius: 8px;
}
markdown th,
markdown td {
  box-shadow: 0 0 0 1px rgb(var(--color-line-230-rgb));
  padding: var(--spacing);
  box-sizing: border-box;
}
markdown blockquote {
  white-space: wrap;
  margin: 0;
  padding: 0;
  padding-left: calc(2 * var(--spacing));
  box-sizing: border-box;
  color: rgb(var(--color-text-800-rgb));
  position: relative;
}
markdown blockquote::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: var(--spacing);
  height: 100%;
  border-radius: 2px;
  background-color: rgba(var(--color-primary-rgb), 0.2);
}
markdown blockquote > p {
  white-space: pre-line;
}
markdown code[class*=language-],
markdown pre[class*=language-] {
  background-color: rgb(var(--color-code-bg-rgb));
  color: rgb(var(--color-text-white-rgb));
}
markdown code[class*=language-].line-numbers .line-numbers-rows,
markdown pre[class*=language-].line-numbers .line-numbers-rows {
  border-color: rgb(var(--color-line-230-rgb));
}
markdown code[class*=language-].line-numbers .line-numbers-rows > span::before,
markdown pre[class*=language-].line-numbers .line-numbers-rows > span::before {
  color: rgb(var(--color-text-400-rgb));
}
markdown p {
  white-space: pre-line;
}
markdown a {
  color: rgb(var(--color-primary-rgb));
}
markdown a.source-message-chip {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: calc(2 * var(--spacing));
  height: calc(2 * var(--spacing));
  border-radius: var(--spacing);
  margin: 0px 2px;
  font-size: var(--fs-s);
  color: rgb(var(--color-text-100-rgb));
  background-color: rgb(var(--color-line-330-rgb));
  cursor: pointer;
}
markdown a.source-message-chip.expanded {
  padding: 0px 4px;
}
markdown a.source-message-chip:hover {
  background-color: rgb(var(--color-primary-rgb));
}
markdown a.source-message-chip.hidden {
  display: none;
}

ngx-extended-pdf-viewer div.zoom .border-box-wrapper,
ngx-extended-pdf-viewer div.zoom .border-box-wrapper * {
  box-sizing: border-box !important;
}
ngx-extended-pdf-viewer button:focus,
ngx-extended-pdf-viewer a:focus,
ngx-extended-pdf-viewer input:focus,
ngx-extended-pdf-viewer select:focus {
  border: none !important;
}
ngx-extended-pdf-viewer ::selection {
  background-color: rgba(var(--color-primary-rgb), 0.2) !important;
}
ngx-extended-pdf-viewer .toolbar {
  border: none !important;
}
ngx-extended-pdf-viewer #sidebarContent {
  top: 0px !important;
  padding-left: 2px;
}
ngx-extended-pdf-viewer .toolbar {
  border-color: var(--color-line-230) !important;
}
ngx-extended-pdf-viewer #sidebarContainer {
  width: calc(100px + 2 * var(--spacing)) !important;
}
ngx-extended-pdf-viewer .html #thumbnailView {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: calc(5.55 * var(--spacing));
  padding: 0 !important;
  padding-right: calc(4 * var(--spacing)) !important;
  width: calc(100% - 2 * var(--spacing)) !important;
  counter-reset: page;
  overflow: scroll !important;
  scrollbar-width: none !important;
  -ms-overflow-style: none !important;
}
ngx-extended-pdf-viewer .html #thumbnailView::-webkit-scrollbar {
  display: none;
}
ngx-extended-pdf-viewer #outerContainer.sidebarOpen #viewerContainer:not(.pdfPresentationMode) {
  left: calc(100px + 2 * var(--spacing)) !important;
}
ngx-extended-pdf-viewer .html #thumbnailView a {
  border: none !important;
  outline: none;
  position: relative;
  counter-increment: page;
  position: relative;
}
ngx-extended-pdf-viewer .html #thumbnailView a::before {
  content: counter(page);
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  padding-top: var(--spacing);
  font-weight: var(--fw-bold);
  font-size: var(--fs-xxs);
  color: var(--color-text-500);
  white-space: nowrap;
}
ngx-extended-pdf-viewer .html #thumbnailView a:has(.thumbnail.selected)::before {
  color: var(--color-text-800);
}
ngx-extended-pdf-viewer .html #thumbnailView .thumbnail {
  border-radius: calc(1.5 * var(--spacing));
  outline: none;
  margin: 0 !important;
  padding: 0 !important;
  border-width: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
ngx-extended-pdf-viewer #thumbnailView .thumbnail.selected {
  border-color: var(--color-primary) !important;
}
ngx-extended-pdf-viewer #loadingBar {
  background-color: transparent !important;
  border: none !important;
}
ngx-extended-pdf-viewer .annotationEditorLayer {
  pointer-events: none !important;
}
ngx-extended-pdf-viewer #viewerContainer {
  border-radius: calc(3 * var(--spacing)) !important;
}
ngx-extended-pdf-viewer .body {
  background-color: unset !important;
}
ngx-extended-pdf-viewer .pdfViewer .page {
  border-image: none !important;
}
ngx-extended-pdf-viewer #toolbarSidebar,
ngx-extended-pdf-viewer #sidebarContent,
ngx-extended-pdf-viewer #toolbarContainer,
ngx-extended-pdf-viewer .findbar,
ngx-extended-pdf-viewer .secondaryToolbar,
ngx-extended-pdf-viewer .editorParamsToolbar {
  background-color: var(--color-bg-280) !important;
  color: var(--color-text-500) !important;
}
ngx-extended-pdf-viewer .html #scaleSelect,
ngx-extended-pdf-viewer .html select,
ngx-extended-pdf-viewer .html .toolbarField {
  background-color: var(--color-bg-160) !important;
  color: var(--color-text-500) !important;
  border-color: var(--color-line-230) !important;
  caret-color: var(--color-primary) !important;
}
ngx-extended-pdf-viewer .html .toolbarButton,
ngx-extended-pdf-viewer .html .dropdownToolbarButton,
ngx-extended-pdf-viewer .html .secondaryToolbarButton,
ngx-extended-pdf-viewer .html .toolbarLabel {
  color: var(--color-text-500) !important;
}

.cdk-virtual-scroll-content-wrapper {
  min-height: 100%;
}

[class^=icon-],
[class*=" icon-"] {
  position: relative;
  font-family: inherit !important;
}
[class^=icon-][hidden],
[class*=" icon-"][hidden] {
  display: none;
}
[class^=icon-]::before,
[class*=" icon-"]::before {
  display: flex;
  font-family: "icomoon" !important;
  width: 1em;
  height: 1em;
}

i {
  display: flex;
  width: 1em;
  height: 1em;
}

[class^=icon-][hidden],
[class*=" icon-"][hidden],
[class^=iconff-][hidden],
[class*=" iconff-"][hidden],
[class^=ns-icon-][hidden],
[class*=" ns-icon-"][hidden] {
  display: none;
}